import { Icon } from '@chakra-ui/react';

export const SelfScore1 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.5 16.0059C6.5 14.0729 8.067 12.5059 10 12.5059H14C15.933 12.5059 17.5 14.0729 17.5 16.0059V18.9412C17.5 19.2503 17.2494 19.501 16.9402 19.501H7.05981C6.75063 19.501 6.5 19.2503 6.5 18.9412V16.0059Z"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M14.7859 7.42648C14.7859 9.04244 13.475 10.353 11.8573 10.353C10.2396 10.353 8.92871 9.04244 8.92871 7.42648C8.92871 5.81052 10.2396 4.5 11.8573 4.5C13.475 4.5 14.7859 5.81052 14.7859 7.42648Z"
      stroke="currentColor"
      fill="transparent"
    />
  </Icon>
);

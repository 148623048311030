import { Icon } from '@chakra-ui/react';

export const GoalOverview = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12 7L10.9999 6H7L7.00012 16H8V11H10.0001L11 12H14.9999V7H12ZM14 11H11.5L10.5 10H7.99988V7H10.3333L11.5 8H14V11Z"
      fill="currentColor"
    />
    <path d="M6 17V20H20V6H17V7H19V19H7V17H6Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18 4H4V18H18V4ZM17 5H5V17H17V5Z" fill="currentColor" />
    <path d="M10 13H15V14H10V13Z" fill="currentColor" />
    <path d="M10 15H15V16H10V15Z" fill="currentColor" />
  </Icon>
);

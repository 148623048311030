import { Icon } from '@chakra-ui/react';

export const Disconnect = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.46 5.67031V3.58031C9.8 3.75031 9.17 4.01031 8.58 4.33031L10.08 5.83031C10.21 5.78031 10.33 5.72031 10.46 5.67031ZM20.46 11.3203C20.46 9.11031 19.55 7.12031 18.1 5.68031L20.46 3.32031H14.46V9.32031L16.7 7.08031C17.78 8.17031 18.46 9.66031 18.46 11.3203C18.46 12.1703 18.27 12.9703 17.95 13.7003L19.45 15.2003C20.09 14.0603 20.46 12.7303 20.46 11.3203ZM4.73 3.32031L3.32 4.73031L5.68 7.09031C4.91 8.31031 4.46 9.76031 4.46 11.3203C4.46 13.5303 5.37 15.5203 6.82 16.9603L4.46 19.3203H10.46V13.3203L8.22 15.5603C7.14 14.4703 6.46 12.9803 6.46 11.3203C6.46 10.3203 6.71 9.38031 7.14 8.55031L15.22 16.6303C14.97 16.7603 14.72 16.8703 14.46 16.9703V19.0603C15.26 18.8503 16.01 18.5203 16.69 18.1003L19.27 20.6803L20.68 19.2703L4.73 3.32031Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const AccessControl = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.21374 9.1922L10.1923 8.2136C10.4334 7.97253 10.8589 7.92995 11.1425 8.1143L12.4757 8.95105C12.7735 9.1354 12.8444 9.50416 12.6459 9.78781L12.3622 10.114L12.8302 10.5962L12.816 11.05L13.0997 11.3337L13.0145 11.6031L13.2556 11.8442L13.4825 11.7166L13.6385 11.8726L13.5534 12.1421L13.7944 12.3831L14.0214 12.2555L15 13.2341V14L14.2341 14L12.2627 12.0286L11.8089 12.057L11.114 11.3621L10.7878 11.6457C10.5041 11.8443 10.1354 11.7733 9.95101 11.4755L9.11426 10.1424C8.92991 9.85877 8.97258 9.43327 9.21374 9.1922ZM11.5001 9.74992C11.5001 10.1642 11.1643 10.4999 10.75 10.4999C10.3359 10.4999 10.0001 10.1642 10.0001 9.74992C10.0001 9.33573 10.3359 8.99996 10.75 8.99996C11.1643 8.99996 11.5001 9.33573 11.5001 9.74992Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5.5C5 4.67157 5.67157 4 6.5 4H17.5C18.3284 4 19 4.67157 19 5.5V17H5V5.5ZM7.5 6H16.5C16.7761 6 17 6.22386 17 6.5V15H7V6.5C7 6.22386 7.22386 6 7.5 6Z"
      fill="currentColor"
    />
    <path d="M18.5 20C19.3284 20 20 18.8284 20 18H4C4 18.8284 4.67157 20 5.5 20H18.5Z" fill="currentColor" />
  </Icon>
);

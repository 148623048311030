import { Icon } from '@chakra-ui/react';

export const ReceiverManager1 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.90271 12.3262C6.90271 11.5862 7.50259 10.9863 8.24257 10.9863C8.36833 10.9863 8.48041 11.0657 8.52221 11.1843L11.096 18.4878C11.3466 19.1991 12.3503 19.2056 12.6102 18.4976L15.3101 11.1426C15.3445 11.0487 15.4339 10.9863 15.534 10.9863C16.3706 10.9863 17.0488 11.6646 17.0488 12.5012V18.1635C17.0488 18.8967 16.4544 19.4911 15.7212 19.4911H8.23036C7.49712 19.4911 6.90271 18.8967 6.90271 18.1635V12.3262Z"
      stroke="currentColor"
      fill="transparent"
    />
    <path d="M10.9333 13.1323L12.0317 16.5608L13.1301 13.1323L12.0317 11.418L10.9333 13.1323Z" fill="currentColor" />
    <path
      d="M14.8269 7.41881C14.8269 9.05511 13.557 10.3474 12.0318 10.3474C10.5066 10.3474 9.23669 9.05511 9.23669 7.41881C9.23669 5.7825 10.5066 4.49023 12.0318 4.49023C13.557 4.49023 14.8269 5.7825 14.8269 7.41881Z"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M17.786 11.6855V11.6855C19.0089 11.6855 20.0002 12.6763 20.0002 13.8991C20.0002 14.8484 20.0002 16.0061 20.0002 17.1723C20.0002 18.1817 19.1819 19 18.1725 19H17.9362"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M15.5331 5.4993C16.0136 4.9993 17.1221 5.06396 17.4136 5.1896C17.7051 5.31523 17.97 5.49938 18.1931 5.73153C18.4162 5.96368 18.5932 6.23927 18.714 6.54259C18.8347 6.8459 18.8968 7.17099 18.8968 7.4993C18.8968 7.8276 18.8347 8.15269 18.714 8.45601C18.5932 8.75932 18.4162 9.03492 18.1931 9.26706C17.97 9.49921 17.7051 9.68336 17.4136 9.809C17.1221 9.93463 16.0136 10 15.5331 9.49974"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M6.21436 11.6855V11.6855C4.99151 11.6855 4.00019 12.6763 4.00019 13.8991C4.00019 14.8484 4.00019 16.0061 4.00019 17.1723C4.00019 18.1817 4.81845 19 5.82784 19H6.06419"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M8.46729 5.49899C7.98675 4.99899 6.87824 5.06365 6.58673 5.18929C6.29522 5.31492 6.03035 5.49907 5.80725 5.73122C5.58414 5.96337 5.40716 6.23896 5.28641 6.54228C5.16566 6.84559 5.10352 7.17068 5.10352 7.49899C5.10352 7.82729 5.16566 8.15238 5.28641 8.45569C5.40716 8.75901 5.58414 9.03461 5.80725 9.26675C6.88349 10.3868 7.84456 10 8.3251 9.50113"
      stroke="currentColor"
      fill="transparent"
    />
  </Icon>
);

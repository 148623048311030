import {
  attributesPredictionApi,
  createPostApi,
  createCommentApi,
  getCommentsApi,
  uploadPostMediaApi,
  editCommentApi,
  editPostApi,
  visibilityPredictionApi,
} from 'api/post';
import FeedReactions from 'models/feedReactions';
import HashTag from 'models/hashtag';
import Media from 'models/media';
import Post from 'models/post';
import PostFeedItemData from 'models/postFeedItemData';
import { debounce } from 'throttle-debounce';

import { ADD_POST_MODAL_TYPES, POST_VISIBILITY_TYPES } from 'constants/ProjectConstants';
import { camelCase } from 'constants/helper';

import { getApiBodyFromEditorStateAndMedia } from './helpers/post';

// export const predictAttributesForPost = text => {
//   // TODO: Make sure that the api returns an object and not an array
//   return attributesPredictionApi(text).then(res => {
//     const result = {};
//     result['DEVELOP'] = converDataToSelectRecognizedData(res['entity']['DEVELOP'] || []);
//     result['DRIVE'] = converDataToSelectRecognizedData(res['entity']['DRIVE'] || []);
//     result['DELIVER'] = converDataToSelectRecognizedData(res['entity']['DELIVER'] || []);
//     result['SENTIMENT'] = res['entity']['SENTIMENT'];

//     return result;
//   });
// };

export const predictAttributesForPost = ({ text, macros }) =>
  // TODO: Make sure that the api returns an object and not an array
  attributesPredictionApi(text, null, macros).then(
    (res) =>
      // const result = {};
      res.entity.orgTag.map((tagRes) => {
        const hashtag = new HashTag();
        hashtag.fromJSON(tagRes);
        // hashtag.label = capitalize(camelCase(hashtag.name));
        // hashtag.value = hashtag.id;
        return hashtag;
      })
    // result['DEVELOP'] = tags;
    // result['DEVELOP'] = converDataToSelectRecognizedData(res['entity']['orgTag'] || []);
    // result['SENTIMENT'] = res['entity']['SENTIMENT'];

    // return result;
  );

export const debouncedAttributePrediction = debounce(100, ({ text, macros, onSuccess, onError }) => {
  predictAttributesForPost({ text, macros })
    .then((tags) => typeof onSuccess === 'function' && onSuccess(tags))
    .catch((err) => {
      typeof onError === 'function' && onError(err);
    });
});

export const debouncedVisibilityPrediction = debounce(200, ({ onSuccess, text, onError }) => {
  // const text = getTextFromEditorState(editorState);
  if (!text) return;
  visibilityPredictionApi({ text })
    .then((res) => {
      if (res.entity && res.entity.visibilityType && typeof onSuccess === 'function')
        onSuccess(res.entity.visibilityType);
    })
    .catch((err) => {
      typeof onError === 'function' && onError(err);
    });
});

export const dropdownDataForDevelop = (text) =>
  // TODO: Make sure that the api returns an object and not an array
  attributesPredictionApi(text, 'DEVELOP').then((res) => converDataToSelectRecognizedData(res.entity.DEVELOP || []));

export const dropdownDataForDeliver = (text) =>
  // TODO: Make sure that the api returns an object and not an array
  attributesPredictionApi(text, 'DELIVER').then((res) => converDataToSelectRecognizedData(res.entity.DELIVER || []));

export const dropdownDataForDrive = (text) =>
  // TODO: Make sure that the api returns an object and not an array
  attributesPredictionApi(text, 'DRIVE').then((res) => converDataToSelectRecognizedData(res.entity.DRIVE || []));

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const converDataToSelectRecognizedData = (data) =>
  data.map((object) => ({
    label: capitalize(camelCase(object.name)),
    value: object.id,
  }));
// media is an array of media object
export function createPost({
  editorState,
  media,
  visibilityType,
  useMoney = false,
  sentiment,
  feedbackType,
  feedback_visibility,
}) {
  const body = getApiBodyFromEditorStateAndMedia(editorState, media, useMoney, sentiment);
  body.visibilityType = visibilityType;
  // body.contentState = getStringifiedRawContentStateFromEditorState({ editorState });

  switch (feedbackType) {
    case ADD_POST_MODAL_TYPES.GIVE_FEEDBACK:
      feedbackType = 'FEEDBACK';
      break;
    case ADD_POST_MODAL_TYPES.GIVE_KUDOS:
      feedbackType = 'KUDOS';
      break;
    default:
      feedbackType = null;
  }
  return createPostApi(body, feedbackType, feedback_visibility).then((res) => {
    // TODO: get the post in this res and then add it as the first item in the feed
  });
}

export function editPost({
  postId,
  editorState,
  media,
  visibilityType,
  useMoney = false,
  sentiment,
  feedbackType,
  feedback_visibility,
}) {
  if (!postId) throw new Error('Please provide a post id to update');
  const body = getApiBodyFromEditorStateAndMedia(editorState, media, useMoney, sentiment);
  body.visibilityType = visibilityType;
  // body.contentState = getStringifiedRawContentStateFromEditorState({ editorState });

  switch (feedbackType) {
    case ADD_POST_MODAL_TYPES.GIVE_FEEDBACK:
      feedbackType = 'FEEDBACK';
      break;
    case ADD_POST_MODAL_TYPES.GIVE_KUDOS:
      feedbackType = 'KUDOS';
      break;
    default:
      feedbackType = null;
  }

  return editPostApi(postId, body, feedbackType, feedback_visibility).then((res) => {
    const post = Post.fromJSON(res.entity);
    return post;
  });
}

export function getDetailsFromCommentState({ commentEditorState, media, sentiment }) {
  const body = getApiBodyFromEditorStateAndMedia(commentEditorState, media, true, sentiment);
  // const contentState = getStringifiedRawContentStateFromEditorState({
  //   editorState: commentEditorState
  // });
  // body.contentState = contentState;
  return body;
}

export function createComment({
  feedId,
  commentEditorState,
  media,
  feedType = 'POST',
  visibilityType,
  talkingPointQuestionId = null,
  sentiment,
  isKudos = false,
}) {
  const body = getDetailsFromCommentState({ commentEditorState, media, sentiment });
  body.visibilityType = visibilityType || POST_VISIBILITY_TYPES.global.name;
  return createCommentApi({
    feedId,
    commentBody: body,
    feedType,
    talkingPointQuestionId,
    isKudos,
  }).then((res) => {
    const comment = new PostFeedItemData();
    comment.fromJSON(res.entity.comment);
    const reactions = new FeedReactions();
    reactions.fromJSON(res.entity.reaction);
    return { comment, reactions };
  });
}

export function editComment({
  commentId,
  feedId,
  commentEditorState,
  media,
  feedType = 'POST',
  visibilityType,
  talkingPointQuestionId = null,
  sentiment,
  isKudos = false,
}) {
  if (!commentId) throw new Error("Comment id doesn't exist");
  const body = getDetailsFromCommentState({ commentEditorState, media, sentiment });
  body.type = 'COMMENT';
  body.uuid = commentId;
  body.visibilityType = visibilityType || POST_VISIBILITY_TYPES.global.name;
  return editCommentApi({
    commentId,
    feedId,
    commentBody: body,
    feedType,
    talkingPointQuestionId,
    isKudos,
  }).then((res) => {
    const comment = new PostFeedItemData();
    comment.fromJSON(res.entity.comment);
    // const reactions = new FeedReactions();
    // reactions.fromJSON(res.entity.reaction);
    return { comment };
  });
}

// type here is the type of feed, for example "POST"
export function getComments({ feedId, limit, offset, type }) {
  return getCommentsApi({ feedId, limit, offset, type }).then((res) =>
    res.entity.map((commentJSON) => {
      const comment = new PostFeedItemData();
      comment.fromJSON(commentJSON);
      return comment;
    })
  );
}

export function uploadPostMedia({ attachment, type }) {
  return uploadPostMediaApi({ attachment, type }).then((res) => Media.fromJSON(res.entity));
}

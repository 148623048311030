import { Icon } from '@chakra-ui/react';

export const Realign = (props) => (
  <Icon viewBox="0 0 20 20" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.1098 5.5513L7.99302 3.4421C7.84127 3.29795 7.60608 3.29795 7.45433 3.4421L5.33754 5.5513C5.09476 5.7865 5.26926 6.1962 5.60309 6.1962L6.96876 6.1962L6.96876 10.756C6.96876 11.1733 7.31018 11.5147 7.72747 11.5147C8.14476 11.5147 8.48617 11.1733 8.48617 10.756L8.48617 6.1962L9.84426 6.1962C10.1857 6.1962 10.3526 5.7865 10.1098 5.5513ZM12.5453 16.5601L14.662 14.4509C14.9048 14.2157 14.7303 13.806 14.3965 13.806L13.0384 13.806L13.0384 9.23862C13.0384 8.82133 12.697 8.47991 12.2797 8.47991C11.8624 8.47991 11.521 8.82133 11.521 9.23862L11.521 13.7984L10.1629 13.7984C9.8215 13.7984 9.65458 14.2081 9.89737 14.4433L12.0142 16.5525C12.1583 16.7043 12.4011 16.7043 12.5453 16.5601Z"
      fill="currentColor"
    />
  </Icon>
);

import mixpanel from 'mixpanel-browser';

export const initMixpanel = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
    debug: process.env.NODE_ENV !== 'production',
    ignore_dnt: true,
  });
};

export const sendMixpanelData = (eventType, eventProperties) => {
  mixpanel.track(eventType, eventProperties);
};

export const setMixpanelUserId = (userId) => {
  mixpanel.identify(userId);
  mixpanel.people.set({ $distinct_id: userId });
};

export const setMixpanelUserProperties = (properties) => {
  mixpanel.people.set({ ...properties, $email: properties.email, $name: properties.fullName });
};

export function getUsefulTrackingDataFromLoggedInEmployee(loggedInEmployee) {
  loggedInEmployee = loggedInEmployee || {};
  const { organization = {}, fullName = '', email = '', id = '', employeeAccesses, enabledFeatures } = loggedInEmployee;

  const { name: organizationName, id: organizationId, organizationStatus, planType, country } = organization;

  return {
    organizationId,
    organizationName,
    organizationStatus,
    planType,
    country,
    loggedInEmployeeEmail: email,
    fullName,
    loggedInEmployeeId: id,
    employeeAccesses,
    enabledFeatures,
  };
}

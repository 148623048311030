import { UPDATE_ENTER_COMMENT_STATE, UPDATE_ENTER_COMMENT_IMAGE } from 'actions/actionTypes';
import { EditorState } from 'draft-js';
import { combineReducers } from 'redux';

const initialEditorState = EditorState.createEmpty();

export function isActive(state = false, action) {
  switch (action.type) {
    default:
      return state;
  }
}

// The file object of image or gif will come inside action.data
export function image(state = null, action) {
  switch (action.type) {
    case UPDATE_ENTER_COMMENT_IMAGE:
      const newState = Object.create(action.data);
      return newState;
    default:
      return state;
  }
}

export function editorState(state = initialEditorState, action) {
  switch (action.type) {
    case UPDATE_ENTER_COMMENT_STATE:
      return action.data;
    default:
      return state;
  }
}

const enterComment = combineReducers({ editorState, isActive });

export default enterComment;

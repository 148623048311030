import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { useSelector } from 'react-redux';

import { CompetencyDrawerState, PrimaryDrawerScreens, ProgressKeys } from './types/slice';

const initialState: CompetencyDrawerState = {
  isOpen: false,
  progressScore: 0.0,
  progressLabel: ProgressKeys.STARTING,
  comment: EditorState.createEmpty(),
  uploadedImage: null,
  uploadedGif: null,
  feedbackType: 'praise',
  praiseOffset: 0,
  adviceOffset: 0,
  praisePosts: [],
  advicePosts: [],
  commentsOffset: 0,
  comments: [],
  isAllCommentsFetched: false,
  isAllPraiseFetched: false,
  isAllAdviceFetched: false,
  isSecondaryPanelOpen: false,
  selectedPrimaryScreen: PrimaryDrawerScreens.COMPETENCY,
  selectedSecondaryScreen: null,
};

const competencyDrawerSlice = createSlice({
  name: 'competencyDrawer',
  initialState,
  reducers: {
    setIsOpen(state, action: PayloadAction<CompetencyDrawerState['isOpen']>) {
      state.isOpen = action.payload;
    },
    setProgressScore(state, action: PayloadAction<CompetencyDrawerState['progressScore']>) {
      state.progressScore = action.payload;
    },
    setProgressLabel(state, action: PayloadAction<CompetencyDrawerState['progressLabel']>) {
      state.progressLabel = action.payload;
    },
    setComment(state, action: PayloadAction<CompetencyDrawerState['comment']>) {
      state.comment = action.payload;
    },
    setUploadedImage(state, action: PayloadAction<CompetencyDrawerState['uploadedImage']>) {
      state.uploadedImage = action.payload;
    },
    setUploadedGif(state, action: PayloadAction<CompetencyDrawerState['uploadedGif']>) {
      state.uploadedGif = action.payload;
    },
    setFeedbackType(state, action: PayloadAction<CompetencyDrawerState['feedbackType']>) {
      state.feedbackType = action.payload;
    },
    setOffset(
      state,
      action: PayloadAction<{
        offset: CompetencyDrawerState['praiseOffset'];
        feedbackType: CompetencyDrawerState['feedbackType'];
      }>
    ) {
      const { feedbackType, offset } = action.payload;
      state.feedbackType = feedbackType;
      if (feedbackType === 'praise') {
        state.praiseOffset = offset;
      } else {
        state.adviceOffset = offset;
      }
    },
    incrementOffset(
      state,
      action: PayloadAction<{
        offset: CompetencyDrawerState['praiseOffset'];
        feedbackType: CompetencyDrawerState['feedbackType'];
      }>
    ) {
      const { feedbackType, offset } = action.payload;
      state.feedbackType = feedbackType;
      if (feedbackType === 'praise') {
        state.praiseOffset += offset;
      } else {
        state.adviceOffset += offset;
      }
    },
    setPosts(
      state,
      action: PayloadAction<{
        posts: CompetencyDrawerState['praisePosts'];
        feedbackType: CompetencyDrawerState['feedbackType'];
      }>
    ) {
      const { feedbackType, posts } = action.payload;
      state.feedbackType = feedbackType;
      if (feedbackType === 'praise') {
        state.praisePosts = posts;
      } else {
        state.advicePosts = posts;
      }
    },
    pushToPosts(
      state,
      action: PayloadAction<{
        posts: CompetencyDrawerState['praisePosts'];
        feedbackType: CompetencyDrawerState['feedbackType'];
      }>
    ) {
      const { feedbackType, posts } = action.payload;
      state.feedbackType = feedbackType;
      if (feedbackType === 'praise') {
        state.praisePosts.push(...posts);
      } else {
        state.advicePosts.push(...posts);
      }
    },
    setCommentOffset(state, action: PayloadAction<CompetencyDrawerState['commentsOffset']>) {
      state.commentsOffset = action.payload;
    },
    incrementCommentOffset(state, action: PayloadAction<CompetencyDrawerState['commentsOffset']>) {
      state.commentsOffset += action.payload;
    },
    setComments(state, action: PayloadAction<CompetencyDrawerState['comments']>) {
      state.comments = action.payload;
    },
    pushToComments(state, action: PayloadAction<CompetencyDrawerState['comments']>) {
      state.comments.push(...action.payload);
    },
    setSecondaryPanelOpen(state, action: PayloadAction<CompetencyDrawerState['isSecondaryPanelOpen']>) {
      state.isSecondaryPanelOpen = action.payload;
    },
    setPrimaryScreen(state, action: PayloadAction<CompetencyDrawerState['selectedPrimaryScreen']>) {
      state.selectedPrimaryScreen = action.payload;
    },
    setSecondaryScreen(state, action: PayloadAction<CompetencyDrawerState['selectedSecondaryScreen']>) {
      state.selectedSecondaryScreen = action.payload;
    },
    setAllCommentsFetched(state, action: PayloadAction<CompetencyDrawerState['isAllCommentsFetched']>) {
      state.isAllCommentsFetched = action.payload;
    },
    setAllPostsFetched(
      state,
      action: PayloadAction<{
        isAllPostsFetched: CompetencyDrawerState['isAllPraiseFetched'];
        feedbackType: CompetencyDrawerState['feedbackType'];
      }>
    ) {
      const { feedbackType, isAllPostsFetched } = action.payload;
      state.feedbackType = feedbackType;
      if (feedbackType === 'praise') {
        state.isAllPraiseFetched = isAllPostsFetched;
      } else {
        state.isAllAdviceFetched = isAllPostsFetched;
      }
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setIsOpen,
  setProgressScore,
  setProgressLabel,
  setFeedbackType,
  setPosts,
  setOffset,
  incrementOffset,
  pushToPosts,
  setComment,
  setUploadedImage,
  setUploadedGif,
  incrementCommentOffset,
  setCommentOffset,
  setComments,
  pushToComments,
  setSecondaryPanelOpen,
  setPrimaryScreen,
  setSecondaryScreen,
  setAllCommentsFetched,
  setAllPostsFetched,
  resetState,
} = competencyDrawerSlice.actions;
export default competencyDrawerSlice.reducer;

export const useProgressLabel = () =>
  useSelector<{ competencyDrawer: CompetencyDrawerState }, ProgressKeys>(
    (state) => state.competencyDrawer.progressLabel
  );

export const useOffset = () =>
  useSelector<{ competencyDrawer: CompetencyDrawerState }, number>((state) =>
    state.competencyDrawer.feedbackType === 'praise'
      ? state.competencyDrawer.praiseOffset
      : state.competencyDrawer.adviceOffset
  );

export const usePosts = () =>
  useSelector<{ competencyDrawer: CompetencyDrawerState }, CompetencyDrawerState['praisePosts']>((state) =>
    state.competencyDrawer.feedbackType === 'praise'
      ? state.competencyDrawer.praisePosts
      : state.competencyDrawer.advicePosts
  );

export const useAllPostsFetched = () =>
  useSelector<{ competencyDrawer: CompetencyDrawerState }, boolean>((state) =>
    state.competencyDrawer.feedbackType === 'praise'
      ? state.competencyDrawer.isAllPraiseFetched
      : state.competencyDrawer.isAllAdviceFetched
  );

export const useCompetencyDrawerState = () =>
  useSelector<{ competencyDrawer: CompetencyDrawerState }, CompetencyDrawerState>((state) => state.competencyDrawer);

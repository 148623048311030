import { endpoints } from 'constants/endpoints';

import { getDuration } from 'services/task';

import { sendRequest } from './sendRequest';

// this api is also used to create as well as update task
export function createOrUpdateTaskApi({
  id,
  text,
  to = null,
  dueTS,
  macros,
  startingProgressValue,
  goalProgressLevel,
  goalClosureStatus,
  // lastEditedBy,
  completionTS,
  contentState,
  description,
  teams,
  removedTeam,
  uiKey, // A unique key generated at frontend to prevent task duplication at the backend
  from = null,
  toIds,
  recurrenceDetails,
  taskType,
  recurrentSubTaskType,
  parentPost,
  type,
  target,
  measure,
  actual,
  visibilityType,
  startTS,
  tags,
  isOnBoarding,
  weightage,
  status,
  parentPosts, // for having multiple parents like in case of tasks that are linked to goal
  source,
  metricCalculationExpression,
  goalName,
  trackedByPlugin,
  metricId,
  measureType,
}) {
  const url = id ? endpoints.tasks.detail(id) : endpoints.tasks.index;
  const bodyObj = {
    uiKey,
    text,
    // Not needed to set in requests anymore. Backend sets default value of logged In employee
    to: null,
    macros,
    // lastEditedBy,
    // Not needed to set in requests anymore. Backend sets default value of logged In employee
    from: null,
    dueDate: dueTS,
    completionDate: completionTS,
    dueTS,
    completionTS,
    contentState,
    teams,
    description,
    toIds,
    recurrenceDetails,
    taskType,
    recurrentSubTaskType,
    parentPost,
    parentPosts,
    type,
    target,
    measure,
    actual,
    visibilityType,
    startTS,
    tags,
    startingProgressValue,
    goalProgressLevel,
    goalClosureStatus,
    weightage,
    status,
    source,
    metricCalculationExpression,
    goalName,
    removedTeam,
    trackedByPlugin,
    metricId,
    measureType,
  };

  if (id) bodyObj.isOnBoarding = isOnBoarding;

  return sendRequest({
    url,
    method: id ? 'PUT' : 'POST',
    credentials: 'include',
    body: bodyObj,
  });
}

export function getGoalTasksApi({ goalId, offset, limit }) {
  const url = endpoints.tasks.goalTasks(goalId);
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      offset,
      limit,
    },
  });
}

export function getSubTasksApi({ taskId, offset, limit }) {
  const url = endpoints.tasks.subTasks(taskId);
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      offset,
      limit,
    },
  });
}

export function deleteTaskApi({ id }) {
  const url = endpoints.tasks.detail(id);
  return sendRequest({
    url,
    method: 'DELETE',
    credentials: 'include',
  });
}

export function getProgressGraphDataApi(goalId) {
  const url = endpoints.tasks.progressGraph(goalId);
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
  });
}

// returns all the tasks stats of a particular team of all the employees
// duration = {startTS:41432324231, endTS:1241234124324132}
export function getTeamTasksStatsApi({
  duration,
  filters,
  tagId,
  tagType,
  cohort,
  dateRange,
  // only in case of goals
  timeBounds,
  selectedCohort = null,
}) {
  const url = selectedCohort ? endpoints.tasks.statsV1 : endpoints.tasks.stats;
  duration = duration || {};
  const body = {
    ...(timeBounds && { timeBounds }),
    filter: filters,
    dateRange,
  };
  if (selectedCohort) {
    body.cohortId = selectedCohort?.id;
    if (selectedCohort?.teamId) {
      body.teamId = selectedCohort?.teamId;
    }
  } else {
    body.cohortId = cohort?.id;
    if (cohort?.teamId) {
      body.teamId = cohort?.teamId;
    }

    body.pageType = {
      uuid: tagId,
      filterTagType: tagType,
    };
  }
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams: {
      ...duration,
    },
    body,
  });
}

/* api to get a team's performance: growth, reliability
 */
export function getTeamPerformanceApi({ durationKey, duration, teamId }) {
  const url = endpoints.tasks.team.performance(teamId);
  duration = duration || {};
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      durationKey,
      ...duration,
    },
  });
}

/* this returns all the team tasks
    completed and active, along with their stats
*/
// This one is called for each card level stats and tasks
export function getTeamTasksAndStatsApi({
  durationKey,
  duration,
  resourceType,
  limit,
  offset,
  paginationKey,
  orderBy,
  filters,
  tagId = -1,
  tagType,
  cohort,
  dateRange,
  pageType = null,
  timeBoundPeriod = null,
  objectiveTimeboundFilter = null,
  isPrivateGoalRequired = false,
  selectedCohort = null,
}) {
  const url = endpoints.tasks.indexV1;
  const queryParams = {
    // durationKey,
    limit,
    offset,
    resourceType,
    isPrivateGoalRequired,
    ...duration,
  };

  if (pageType && pageType !== 'null') {
    queryParams.pagetype = pageType;
  }

  if (paginationKey) queryParams.paginationKey = paginationKey;
  duration = duration || {};

  const body = {
    ...(timeBoundPeriod && { timeBounds: [timeBoundPeriod] }),
    orderBy,
    filter: objectiveTimeboundFilter ? [...filters, objectiveTimeboundFilter] : [...filters],

    dateRange,
  };

  if (selectedCohort) {
    body.cohortId = selectedCohort?.id;
    if (selectedCohort?.teamId) {
      body.teamId = selectedCohort?.teamId;
    }
  } else if (pageType === 'null') {
    // need this to send page type as null without breaking the functionality of
    // existing pageType null usage at multiple places
    body.pageType = null;
  } else {
    body.pageType = tagType
      ? {
          uuid: tagId,
          filterTagType: tagType,
        }
      : null;

    body.cohortId = cohort?.id;
    if (cohort?.teamId) {
      body.teamId = cohort?.teamId;
    }
  }

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams,
    body,
  });
}

/* this returns all the team tasks
    completed and active, along with their stats
*/
export function getTeamTasksAndStatsApiNew({
  durationKey,
  duration,
  resourceType,
  limit,
  offset,
  orderBy,
  filters,
  tagId,
  tagType,
  cohort,
  dateRange,
  // only in case of goals
  timeBoundPeriod = null,
  selectedCohort = null,
}) {
  const url = endpoints.tasks.indexCards;
  duration = duration || {};
  const body = {
    ...(timeBoundPeriod && { timeBounds: [timeBoundPeriod] }),
    orderBy,
    filter: filters,
    dateRange,
  };

  if (selectedCohort) {
    body.cohortId = selectedCohort?.id;
    if (selectedCohort?.teamId) {
      body.teamId = selectedCohort?.teamId;
    }
  } else {
    body.pageType = tagType
      ? {
          uuid: tagId,
          filterTagType: tagType,
        }
      : null;
    body.cohortId = cohort?.id;
    if (cohort?.teamId) {
      body.teamId = cohort?.teamId;
    }
  }

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams: {
      // durationKey,
      limit,
      offset,
      resourceType,
      ...duration,
    },
    body,
  });
}

export function getCheckInTasksApi({ checkInTaskId, limit = 8, offset = 0 }) {
  const url = endpoints.tasks.checkIn(checkInTaskId);
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      limit,
      offset,
    },
  });
}

// this returns count of completed and active tasks of a employee for a particular team
export function getEmployeeTasksCountForTeamApi({ teamId, employeeId }) {
  const url = endpoints.tasks.count;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      teamId,
      employeeId,
    },
  });
}

// taskDetails by taskId
export const getTaskDetailsApi = ({ taskId }) => {
  const url = endpoints.tasks.detail(taskId);
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
  });
};

export const saveDiscussionApi = ({ taskId, text, macros }) => {
  const url = endpoints.tasks.discussion(taskId);
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams: {
      type: 'POST',
    },
    body: {
      text,
      macros,
    },
  });
};

// task comments by taskId
export const getTaskCommentsApi = ({ taskId, offset, limit }) => {
  const url = endpoints.tasks.comments(taskId);
  return sendRequest({
    url,
    method: 'GET',
    queryParams: {
      taskId,
      offset,
      limit,
    },
    credentials: 'include',
  });
};

export function refreshMetricApi({ goalId }) {
  const url = endpoints.tasks.refreshMetric(goalId);
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
  });
}

export function updateCheckInDateTimeApi({ checkInInstanceId, dueDate, startDate }) {
  const url = endpoints.checkIn.updateDueDate(checkInInstanceId);
  return sendRequest({
    url,
    method: 'PUT',
    queryParams: { dueTS: dueDate, startTS: startDate },
    credentials: 'include',
  });
}

export function getTalkingPointCommentsApi({ checkInId, talkingPointQuestionId, offset = 0, limit = 10 }) {
  const url = endpoints.tasks.comments(checkInId);
  return sendRequest({
    url,
    method: 'GET',
    queryParams: {
      talkingPointQuestionId,
      offset,
      limit,
    },
    credentials: 'include',
  });
}

// this is deprecated now. TODO: remove by end of june.
export function recordCheckInApi({ checkInId }) {
  const url = endpoints.checkIn.recordCheckIn(checkInId);
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
  });
}

export function saveOneOnOneRatingApi({ checkInId, rating }) {
  const url = endpoints.checkIn.timelineStatus(checkInId);
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
    queryParams: {
      rating,
    },
  });
}

export function acknowledgeOneOnOneApi({ checkInId, status }) {
  const url = endpoints.checkIn.timelineStatus(checkInId);
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
    queryParams: {
      checkInState: status,
    },
  });
}

export const getGoalsTimelineListApi = ({ employeeId }) => {
  const url = endpoints.tasks.timelineList;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      empId: employeeId,
    },
  });
};

export const getGoalWeightagesApi = ({ employeeId, startTS, endTS, offset = 0, limit = 100, body }) => {
  const url = endpoints.tasks.weightage;
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams: {
      empId: employeeId,
      startTS,
      endTS,
      offset,
      limit,
    },
    body,
  });
};

export function updateGoalsWeightageApi({ employeeId, body }) {
  const url = endpoints.tasks.weightage;
  return sendRequest({
    url,
    method: 'PUT',
    queryParams: {
      empId: employeeId,
    },
    body,
    credentials: 'include',
  });
}

export function getObjectiveHeirArchyApi({ objectiveTimeBoundId }) {
  const url = endpoints.tasks.objectiveHeirarchy;
  return sendRequest({
    url,
    queryParams: {
      objectiveTimeBoundId,
    },
    credentials: 'include',
  });
}

export function getInitiativeStatsApi({ dateRange, filters, cohort }) {
  const url = endpoints.tasks.statsV1;

  const duration = getDuration({
    startTS: dateRange.startDate,
    endTS: dateRange.endDate,
    durationKey: 'LASTYEAR',
  });
  // const duration = {
  //   startTS: dateRange.startDate,
  //   endTS: dateRange.endDate
  // };
  const body = {
    filter: filters,
  };
  body.cohortId = cohort?.id;
  if (cohort?.teamId) {
    body.teamId = cohort?.teamId;
  }

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams: {
      ...duration,
    },
    body,
  });
}

export function getInitiativesTableDataApi({ cohort, dateRange, resourceType, attributeFilter }) {
  const duration = getDuration({
    startTS: dateRange.startDate,
    endTS: dateRange.endDate,
    durationKey: 'LASTYEAR',
  });

  const url = endpoints.analytics.initiative.list;

  const queryParams = {
    pageType: 'TEAM_HUB',
    resourceType,
  };

  const body = {
    dateRange: dateRange.startDate ? duration : null,
    attributeFilter,
  };
  body.cohortId = cohort?.id;

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams,
    body,
  });
}

export const getMyInitiativesTableDataApi = ({ dateRange, type }) => {
  const duration = getDuration({
    startTS: dateRange.startDate,
    endTS: dateRange.endDate,
    durationKey: dateRange?.label || 'LASTYEAR',
  });

  const url = endpoints.analytics.initiative.myInitiatives;

  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      type,
    },
    // body: {
    //   dateRange: dateRange.startDate ? duration : null,
    // },
  });
};
export function getInitiativesTableDataApiForProfileAndSummary({
  resourceType,
  attributeFilter,
  pageType,
  limit,
  offset,
}) {
  const url = endpoints.analytics.initiative.list;

  const queryParams = {
    pageType,
    resourceType,
  };

  const body = {
    attributeFilter,
    limit,
    offset,
  };

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams,
    body,
  });
}

export function getReviewPeriodSummaryInitiativesApi({ limit, offset, resourceType, filters, isPrivateGoalRequired }) {
  const url = endpoints.tasks.indexV1;
  const queryParams = {
    limit,
    offset,
    resourceType,
    isPrivateGoalRequired,
    startTS: 0,
    endTS: new Date().getTime(),
  };

  const body = {
    filter: filters,
  };
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams,
    body,
  });
}

export function getInitiativesCardsDataApi({ resourceType, filters, cohort, dateRange, limit, offset }) {
  const url = endpoints.tasks.indexCards;
  const duration = getDuration({
    startTS: dateRange.startDate,
    endTS: dateRange.endDate,
    durationKey: 'LASTYEAR',
  });
  // const duration = {
  //   startTS: dateRange.startDate,
  //   endTS: dateRange.endDate
  // };
  const body = {
    filter: filters,
  };
  body.cohortId = cohort?.id;

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams: {
      // durationKey,
      limit,
      offset,
      resourceType,
      ...duration,
    },
    body,
  });
}

import {
  apiStatusSetLoadingAction,
  apiStatusSetLoadingMoreAction,
  apiStatusSetCompletedAction,
} from 'actions/apiStatus';
import { TASK_VIEW_TYPES } from 'components/MeshList/TabHeader';
import { EditorState } from 'draft-js';
import Task1 from 'models/task1';
import { batch } from 'react-redux';
import { findTaskIndex } from 'reducers/task';
import { getStartTSEndTSFromDateFilters } from 'screens/Team/TeamPageFilters/helper';
import { getMySummaryInitiativesTableData } from 'screens/home/summary/components/Cards/Initiatives/services';

import { GOAL_VISIBILITY_OPTIONS } from 'constants/ProjectConstants';
import { generateKey } from 'constants/helper';

import { convertTextMacrosToEditorState } from 'services/helpers/post';
import { clearTeamsAndRemovedTeam, updateToIds } from 'services/helpers/task';
import { getPrimaryPod } from 'services/helpers/team';
import { constructFiltersObject, createOrUpdateTask, getTeamTasksForListView } from 'services/task';

import store from '../store';
import { apiMetaUpdateOffsetAction, apiMetaUpdateHasMoreAction } from './apiPaginationMeta';
import { addGoalsToGlobalGoalsMap } from './globalGoalsMapActions';

export const TASK_OWNERS_UPDATE = 'TASK_OWNERS_UPDATE';
export const TASK_OWNERS_REMOVE = 'TASK_OWNERS_';
export const TASKS_REDUCER_ADD = 'TASKS_REDUCER_ADD';
export const TASKS_REDUCER_UPDATE = 'TASKS_REDUCER_UPDATE';
export const TASKS_REDUCER_REMOVE = 'TASKS_REDUCER_REMOVE';
export const TASKS_REDUCER_REMOVE_NEW_TASK = 'TASKS_REDUCER_REMOVE_NEW_TASK';
export const TASK_UPDATE = 'TASK_UPDATE';
export const TASK_ADD_TO = 'TASK_ADD_TO';
export const TASK_REMOVE_TO = 'TASK_REMOVE_TO';
export const TASK_ENABLE_EDITING = 'TASK_ENABLE_EDITING';
export const TASK_DISABLE_EDITING = 'TASK_DISABLE_EDITING';
export const TASK_UPDATE_TEAM = 'TASK_UPDATE_TEAM';
export const TASK_TOGGLE_COMPLETION = 'TASK_TOGGLE_COMPLETION';
export const TASK_SET_EDITING = 'TASK_SET_EDITING';

export function buildEmployeeFilters(selectedEmployees) {
  const ids = Object.entries(selectedEmployees).reduce((employeeIds, [employeeId, isSelected]) => {
    if (isSelected) employeeIds.push(employeeId);
    return employeeIds;
  }, []);
  const g = ids.map((id) => ({ type: 'NAME', value: id, operator: 'IN' }));
  return g;
}

function getFetchTasksApiBodyFromState({ state, type, globalFilters = {}, includeEmployeeFilters = false }) {
  const { order = {}, filters = {}, tabs, tasksFilters } = state;
  // mesh list is usually for per person, so exclude any employee filters, unless includeEmployeeFilters is true
  let globalFilters1 = [];
  if (includeEmployeeFilters) {
    globalFilters1 = globalFilters.filters;
  } else {
    globalFilters1 = globalFilters.filters.filter((item) => item.type !== 'NAME');
  }

  // here employee filter is added for meshlist person and not added if includeEmployeeFilters is already true

  const employeeFiltersForApi = includeEmployeeFilters ? [] : buildEmployeeFilters(filters?.employeeFilter);
  const { limit = 5, offset = 0, paginationKey = '' } = state.apiPaginationMeta;
  const { startTS, endTS } = getStartTSEndTSFromDateFilters(filters.completedOnFilter);
  const { orderBy, ascDesc } = order;
  const isCheckInSelected = tabs.selected === -5;
  const filterTypeObject = { type: 'TYPE', value: TASK_VIEW_TYPES[tabs.selected], operator: 'IN' };
  if (type) {
    filterTypeObject.value = type;
  }
  const filtersForApi = constructFiltersObject([...employeeFiltersForApi, ...globalFilters1, filterTypeObject]);
  const filters1 = [...filtersForApi];

  return {
    startTS,
    endTS,
    limit,
    offset,
    paginationKey,
    filters1,
    isCheckInSelected,
    tagId: globalFilters.teamId || globalFilters.tagId || -1,
    tagType: isCheckInSelected ? null : globalFilters?.tagId ? 'ORGTAG' : 'TEAM',
  };
}

export function fetchGoalsAction({
  type = null,
  globalFilters = {},
  pageType = null,
  timeBoundPeriod = null,
  includeEmployeeFilters = false,
  resourceType = null,
  selectedCohort = null,
  limitV1 = null,
} = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { startTS, endTS, limit, paginationKey, filters1, isCheckInSelected, tagId, tagType } =
      getFetchTasksApiBodyFromState({ state, type, globalFilters, includeEmployeeFilters });
    const offset = 0;
    dispatch(apiStatusSetLoadingAction());
    return getTeamTasksForListView({
      startTS,
      endTS,
      limit: limitV1 ?? limit,
      offset,
      paginationKey,
      filters1,
      tagType,
      tagId,
      pageType,
      timeBoundPeriod,
      selectedCohort,
      ...(resourceType && { resourceType }),
    })
      .then((tasks) => {
        // const normalizedTasks = normalize(tasks, tasksSchema);
        batch(() => {
          dispatch(updateTasksAction(tasks));
          dispatch(apiStatusSetCompletedAction());
          dispatch(apiMetaUpdateOffsetAction(tasks.length < limit ? 0 : offset + limit));
          dispatch(apiMetaUpdateHasMoreAction(!(tasks.length < limit)));
        });
      })
      .catch((err) =>
        batch(() => {
          dispatch(apiStatusSetCompletedAction());
          dispatch(apiMetaUpdateHasMoreAction(false));
        })
      );
  };
}

// Passing a function called buildApiBody so that fetchTasksAction function is modular
// and is not dependent on params
export function fetchTasksAction({
  type = null,
  globalFilters = {},
  pageType = null,
  timeBoundPeriod = null,
  includeEmployeeFilters = false,
  resourceType = null,
  selectedCohort = null,
  limitV1 = null,
  employeeId,
} = {}) {
  return (dispatch, getState) => {
    // const { startTS, endTS, limit, paginationKey, filters1, isCheckInSelected, tagId, tagType } =
    //   getFetchTasksApiBodyFromState({ state, type, globalFilters, includeEmployeeFilters });
    // const offset = 0;
    dispatch(apiStatusSetLoadingAction());

    if (!employeeId) {
      return;
    }
    const attributeFilter = {
      filterConditions: [
        {
          type: 'NAME',
          operatorType: 'IN',
          data: [employeeId],
        },
        {
          type: 'TYPE',
          operatorType: 'IN',
          data: ['TASK'],
        },
      ],
    };
    return getMySummaryInitiativesTableData({
      attributeFilter,
      pageType: 'PROFILE_PAGE',
      resourceType: 'LIST',
    })
      .then((res) => {
        // const computedData = res?.computedData ?? [];
        // const tasks = computedData?.map((taskJSON) => Task1.fromJSON(taskJSON)) ?? [];
        const computedData = res?.computedData ?? [];
        const sortedDataOnCreatedTS = computedData.sort((a, b) => b.createdTS - a.createdTS);
        const tasks =
          sortedDataOnCreatedTS?.map((taskJSON) => ({
            ...taskJSON,
            editorState: convertTextMacrosToEditorState(taskJSON?.title, taskJSON?.macros),
          })) ?? [];
        batch(() => {
          dispatch(updateTasksAction(tasks));
          dispatch(apiStatusSetCompletedAction());
          // dispatch(apiMetaUpdateOffsetAction(tasks.length < limit ? 0 : offset + limit));
          // dispatch(apiMetaUpdateHasMoreAction(tasks.length < limit ? false : true));
        });
      })
      .catch((err) => {
        console.error('Error in fetchTasksAction', err);
        batch(() => {
          dispatch(apiStatusSetCompletedAction());
          dispatch(apiMetaUpdateHasMoreAction(false));
        });
      });
  };
}

export function fetchMoreTasksAction({
  type = null,
  globalFilters = {},
  pageType = null,
  timeBoundPeriod = null,
} = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { startTS, endTS, limit, paginationKey, offset, filters1, isCheckInSelected, tagId, tagType } =
      getFetchTasksApiBodyFromState({ state, type, globalFilters });
    dispatch(apiStatusSetLoadingMoreAction());
    return getTeamTasksForListView({
      startTS,
      endTS,
      limit,
      offset,
      paginationKey,
      filters1,
      tagType,
      tagId,
      pageType,
      timeBoundPeriod,
    })
      .then((tasks) => {
        // const normalizedTasks = normalize(tasks, tasksSchema);
        batch(() => {
          dispatch(addTasksAction(tasks));
          dispatch(apiStatusSetCompletedAction());
          dispatch(apiMetaUpdateOffsetAction(tasks.length < limit ? 0 : offset + limit));
          dispatch(apiMetaUpdateHasMoreAction(!(tasks.length < limit)));
        });
      })
      .catch((err) =>
        batch(() => {
          dispatch(apiStatusSetCompletedAction());
          dispatch(apiMetaUpdateHasMoreAction(false));
        })
      );
  };
}

export function addTaskAction(task, addType = 'prepend') {
  return addTasksAction([task], addType);
}

export function addTasksAction(tasks, addType = 'append') {
  store.dispatch(addGoalsToGlobalGoalsMap({ goals: tasks }));
  return { type: TASKS_REDUCER_ADD, tasks, addType };
}

export function addNewTaskAction(task, addType) {
  const { employee, orgTag, selectedTeamId, visibilityType = GOAL_VISIBILITY_OPTIONS[1].value, key, ...other } = task;

  if (task.key) {
    // this will receive prepared subtask
    task.isEditing = true;
    return addTaskAction(task, addType);
  }
  const taskTeamId = [-1, null, undefined].includes(selectedTeamId) // if selectedTeamId is all or not present, we select employee's primary pod
    ? getPrimaryPod(employee.teams)?.id || employee.teams[0]?.id
    : selectedTeamId;

  let teams = [];

  if (Array.isArray(employee.teams)) {
    const matchedTeam = employee.teams.find((team) => team.id === taskTeamId);

    if (matchedTeam) {
      teams = [matchedTeam];
    }
  }
  const newTask = new Task1({
    lastEditedBy: employee,
    to: { ...employee },
    from: { ...employee },
    toIds: [{ ...employee }],
    teamId: taskTeamId,
    editorState: EditorState.createEmpty(),
    macros: orgTag ? { attributes: { orgTag: { [orgTag]: { uuid: orgTag } } } } : undefined,
    teams,
    visibilityType,
    ...other,
  });
  newTask.isEditing = true;
  // need key to remove task because id will be undefined in that case
  newTask.key = generateKey(7);
  return addTaskAction(newTask, addType);
}

export function addNewGoalAction(task) {
  return addTaskAction(task);
}

export function updateTasksAction(tasks) {
  store.dispatch(addGoalsToGlobalGoalsMap({ goals: tasks }));
  return { type: TASKS_REDUCER_UPDATE, tasks };
}

export function removeTaskAction(taskId, key) {
  return { type: TASKS_REDUCER_REMOVE, data: { taskId, key } };
}

export function discardNewTaskAction() {
  return { type: TASKS_REDUCER_REMOVE_NEW_TASK };
}

export function updateTaskAction(task) {
  if (task.id) {
    const taskObj = store.getState().globalGoals?.[task.id]?.data;
    if (taskObj) store.dispatch(addGoalsToGlobalGoalsMap({ goals: [{ ...taskObj, ...task }] }));
  }

  return { type: TASK_UPDATE, data: { taskId: task.id, key: task.key, task } };
}

export function updateTaskEditorStateAction({ taskId, key, editorState }) {
  return updateTaskAction({
    id: taskId,
    key,
    editorState,
  });
  // return { type: TASK_UPDATE, data: { taskId, key, task: { editorState } } };
}

// export function updateTaskToIdsAction({ taskId, key, toIds }) {
//   return { type: TASK_UPDATE, data: { taskId, key, task: { toIds } } };
// }

export function updateTaskSubObjectiveAction({ taskId, key, subObjective }) {
  return updateTaskAction({
    id: taskId,
    key,
    subObjective,
  });
  // return { type: TASK_UPDATE, data: { taskId, key, task: { subObjective } } };
}

export function updateTaskDueDateAction({ taskId, key, dueDate, coloredDueDate }) {
  return updateTaskAction({
    id: taskId,
    key,
    dueDate,
    coloredDueDate,
  });
  // return {
  //   type: TASK_UPDATE,
  //   data: {
  //     taskId,
  //     key,
  //     task: {
  //       dueDate,
  //       coloredDueDate
  //     }
  //   }
  // };
}

export function updateTaskStartDateAction({ taskId, key, startDate, coloredDueDate }) {
  return updateTaskAction({
    id: taskId,
    key,
    startDate,
    coloredDueDate,
  });
}

export function updateTaskDescriptionAction({ taskId, key, description }) {
  return { type: TASK_UPDATE, data: { taskId, key, task: { description } } };
}

// export function updateTaskTeamIdAction({ taskId, key, teamId }) {
//   return { type: TASK_UPDATE, data: { taskId, key, task: { teamId } } };
// }

// export function markTaskCompleteAction({ taskId, key }) {
//   return { type: TASK_TOGGLE_COMPLETION, data: { taskId, key } };
// }

// export function toggleCompletionAction({ taskId, key }) {
//   return { type: TASK_TOGGLE_COMPLETION, data: { taskId, key } };
// }

// export function markTaskIncompleteAction({ taskId, key }) {
//   // return { type: TASK_UPDATE, data: { taskId, key, task: { completionDate: null } } };
//   return { type: TASK_TOGGLE_COMPLETION, data: { taskId, key } };
// }

// export function updateTaskCompanyGoalAction({ taskId, key, companyGoal }) {
//   return { type: TASK_UPDATE, data: { taskId, key, task: { companyGoal } } };
// }

export function enableTaskEditingAction({ taskId, key }) {
  return { type: TASK_ENABLE_EDITING, data: { taskId, key } };
}
// task is optional here
export function disableTaskEditingAction({ taskId, key, task }) {
  return { type: TASK_DISABLE_EDITING, data: { taskId, key, task } };
}

export function enableTaskUpdatingAction({ taskId, key }) {
  /* commenting updateTaskAction because we don't want to put isUpdating in globalGoals */
  // return updateTaskAction({
  //   id: taskId,
  //   key,
  //   isUpdating: true
  // });
  return { type: TASK_UPDATE, data: { taskId, key, task: { isUpdating: true } } };
}
// task is optional here
export function disableTaskUpdatingAction({ taskId, key, task }) {
  /* commenting updateTaskAction because we don't want to put isUpdating in globalGoals */
  // return updateTaskAction({
  //   id: taskId,
  //   key,
  //   isUpdating: false
  // });
  return {
    type: TASK_UPDATE,
    data: {
      taskId,
      key,
      task: { isUpdating: false },
    },
  };
}

// export function taskAddOwnerAction({ taskId, key, newOwner }) {
//   return { type: TASK_ADD_TO, data: { taskId, key, to: newOwner } };
// }

// export function taskRemoveOwnerAction({ taskId, key, ownerId }) {
//   return { type: TASK_REMOVE_TO, data: { taskId, key, toId: ownerId } };
// }

export function updateToIdsAction({ id, key, toIds }) {
  return updateTaskAction({ id, key, toIds });
}

function updateGoalProgress(newGoalProgress) {
  return { actual: newGoalProgress };
}

export function updateGoalProgressAction({ id, goalKey, goalProgressValue }) {
  return updateTaskAction({ id, key: goalKey, ...updateGoalProgress(goalProgressValue) });
}

function updateGoalProgressLevel({ progressLevel, goalClosureStatus }) {
  const goalUpdatedAttributes = {};
  goalUpdatedAttributes.goalProgressLevel = progressLevel;
  if (progressLevel === 'CLOSED') {
    goalUpdatedAttributes.goalClosureStatus = goalClosureStatus;
  }
  return goalUpdatedAttributes;
}

// export function updateGoalProgressLevelAction({ id, key, progressLevel, goalClosureStatus }) {
//   const goalUpdatedAttributes = updateGoalProgressLevel({ progressLevel, goalClosureStatus });
//   return updateTaskAction({ id, key, ...goalUpdatedAttributes });
// }

function getTaskFromState({ state, id, key }) {
  const { tasks } = state;
  const taskIndex = findTaskIndex({ tasks, id, key });
  const task = tasks?.[taskIndex];
  if (!task) {
    console.error(state, id, key);
    throw new Error("Couldn't find task in state");
  }
  return task;
}

export function updateTaskTeamAction({ id, key, team }) {
  return { type: TASK_UPDATE_TEAM, data: { taskId: id, key, team } };
}

export function updateTaskOnServerAction({ id, key, action, updatedData = null, employeeId, onNewTaskCreate }) {
  return (dispatch, getState) => {
    const task = getTaskFromState({ state: getState(), id, key });
    dispatch(enableTaskUpdatingAction({ taskId: id, key }));
    let newTask = task;
    if (typeof action === 'function') newTask = action(newTask);
    if (updatedData) {
      newTask = { ...newTask, ...updatedData };
    }
    // newTask = addTaggedEmployeesUpdateMacrosDeleteMentions(newTask);

    if (!newTask.editorState.getCurrentContent().hasText()) return dispatch(discardNewTaskAction());

    if (newTask.editorState.getCurrentContent().getPlainText().trim().length === 0)
      return dispatch(discardNewTaskAction());

    if (task?.macros?.attributes?.orgTag) {
      newTask = {
        ...newTask,
        macros: {
          ...newTask.macros,
          attributes: {
            ...newTask.macros.attributes,
            orgTag: { ...newTask.macros.attributes.orgTag, ...task.macros.attributes.orgTag },
          },
        },
      };
    }

    return createOrUpdateTask(newTask)
      .then((updatedTask) => {
        batch(() => {
          if (employeeId) {
            dispatch({
              type: TASK_SET_EDITING,
              data: { taskId: id, key, isEditing: false },
            });
            onNewTaskCreate?.();
          }
          if (!employeeId) {
            dispatch(disableTaskUpdatingAction({ taskId: id, key }));
            dispatch(updateTaskAction({ key, ...updatedTask }));
          }
        });
        return updatedTask;
      })
      .catch((err) => {
        console.error('Error in updateTaskOnServerAction', err);
        return dispatch(disableTaskUpdatingAction({ taskId: id, key }));
      });
  };
}

export function updateGoalProgressLevelOnServerAction({ id, key, progressLevel, goalClosureStatus }) {
  return (dispatch, getState) => {
    const task = getTaskFromState({ state: getState(), id, key });
    const goalUpdatedAttributes = updateGoalProgressLevel({ progressLevel, goalClosureStatus });
    const newTask = clearTeamsAndRemovedTeam({ ...task, ...goalUpdatedAttributes });
    dispatch(enableTaskUpdatingAction({ taskId: id, key }));
    return createOrUpdateTask(newTask)
      .then((updatedTask) => {
        batch(() => {
          dispatch(disableTaskUpdatingAction({ taskId: id, key }));
          dispatch(updateTaskAction({ id, key, ...goalUpdatedAttributes }));
        });
        return updatedTask;
      })
      .catch((err) => dispatch(disableTaskUpdatingAction({ taskId: id, key })));
  };
}

export function updateGoalProgressOnServerAction({ id, key, goalProgressValue }) {
  return (dispatch, getState) => {
    const task = getTaskFromState({ state: getState(), id, key });
    const goalUpdatedAttributes = updateGoalProgress(goalProgressValue);
    const newTask = clearTeamsAndRemovedTeam({ ...task, ...goalUpdatedAttributes });
    dispatch(enableTaskUpdatingAction({ taskId: id, key }));
    return createOrUpdateTask(newTask)
      .then((updatedTask) => {
        batch(() => {
          dispatch(disableTaskUpdatingAction({ taskId: id, key }));
          dispatch(updateTaskAction({ id, key, ...goalUpdatedAttributes }));
        });
        return updatedTask;
      })
      .catch((err) => dispatch(disableTaskUpdatingAction({ taskId: id, key })));
  };
}

export function updateToIdsOnServerAction({ id, key, toIds }) {
  return (dispatch, getState) => {
    const task = getTaskFromState({ state: getState(), id, key });
    let newTask = updateToIds(task, toIds);
    newTask = clearTeamsAndRemovedTeam(newTask);
    dispatch(enableTaskUpdatingAction({ taskId: id, key }));
    return createOrUpdateTask(newTask)
      .then((updatedTask) => {
        batch(() => {
          dispatch(disableTaskUpdatingAction({ taskId: id, key }));
          dispatch(updateTaskAction({ id, key, toIds }));
        });
        return updatedTask;
      })
      .catch((err) => dispatch(disableTaskUpdatingAction({ taskId: id, key })));
  };
}

// export function updateDueDateOnServerAction({ id, key, dueDate }) {
//   return (dispatch, getState) => {
//     const task = getTaskFromState({ state: getState(), id, key });
//     const newTask = clearTeamsAndRemovedTeam({ ...task, dueDate });
//     dispatch(enableTaskUpdatingAction({ taskId: id, key }));
//     return createOrUpdateTask(newTask)
//       .then(updatedTask => {
//         batch(() => {
//           dispatch(disableTaskUpdatingAction({ taskId: id, key }));
//           dispatch(updateTaskAction({ ...updatedTask, taskId: id, key }));
//         });
//         return updatedTask;
//       })
//       .catch(err => {
//         return dispatch(disableTaskUpdatingAction({ taskId: id, key }));
//       });
//   };
// }

import { FILTER_MENU_OPEN } from 'actions/actionTypes';

const initialState = {
  filtersMenuOpened: {},
};
export default function filtersMenu(state = initialState, action) {
  switch (action.type) {
    case FILTER_MENU_OPEN: {
      return {
        ...state,
        filtersMenuOpened: {
          ...state.filtersMenuOpened,
          [action.menuType]: action.value,
        },
      };
    }

    default:
      return state;
  }
}

import { Icon } from '@chakra-ui/react';

export const ArrowRight = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M14.5 11.9889C14.5 12.2275 14.4123 12.4564 14.2564 12.6252L10.9299 16.2251H10.9297C10.7748 16.3989 10.5619 16.4979 10.3389 16.5C10.1158 16.5021 9.90128 16.407 9.74353 16.2364C9.58579 16.0657 9.49808 15.8336 9.50003 15.5922C9.50198 15.3508 9.59345 15.1203 9.75384 14.9526L13.0803 11.3526H13.0805C13.2364 11.1838 13.4479 11.0891 13.6684 11.0891C13.8889 11.0891 14.1004 11.1838 14.2564 11.3526C14.4123 11.5213 14.5 11.7502 14.5 11.9889H14.5Z"
      fill="currentColor"
    />
    <path
      d="M14.5001 11.989C14.5001 12.2277 14.4124 12.4565 14.2565 12.6253C14.1005 12.7941 13.8891 12.8888 13.6685 12.8888C13.448 12.8888 13.2365 12.7941 13.0806 12.6253L9.7541 9.02532C9.55013 8.79675 9.47245 8.46857 9.55013 8.16212C9.62782 7.8555 9.84905 7.61609 10.1322 7.53201C10.4156 7.44808 10.7186 7.53186 10.9299 7.75274L14.2563 11.3527C14.4123 11.5215 14.5001 11.7504 14.5001 11.989Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Reorder = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.25 16.5C11.25 17.325 10.575 18 9.75 18C8.925 18 8.25 17.325 8.25 16.5C8.25 15.675 8.925 15 9.75 15C10.575 15 11.25 15.675 11.25 16.5ZM9.75 10.5C8.925 10.5 8.25 11.175 8.25 12C8.25 12.825 8.925 13.5 9.75 13.5C10.575 13.5 11.25 12.825 11.25 12C11.25 11.175 10.575 10.5 9.75 10.5ZM9.75 6C8.925 6 8.25 6.675 8.25 7.5C8.25 8.325 8.925 9 9.75 9C10.575 9 11.25 8.325 11.25 7.5C11.25 6.675 10.575 6 9.75 6ZM14.25 9C15.075 9 15.75 8.325 15.75 7.5C15.75 6.675 15.075 6 14.25 6C13.425 6 12.75 6.675 12.75 7.5C12.75 8.325 13.425 9 14.25 9ZM14.25 10.5C13.425 10.5 12.75 11.175 12.75 12C12.75 12.825 13.425 13.5 14.25 13.5C15.075 13.5 15.75 12.825 15.75 12C15.75 11.175 15.075 10.5 14.25 10.5ZM14.25 15C13.425 15 12.75 15.675 12.75 16.5C12.75 17.325 13.425 18 14.25 18C15.075 18 15.75 17.325 15.75 16.5C15.75 15.675 15.075 15 14.25 15Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const PlaneExpandLess = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M10 14L4.5 19.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M4 14L10 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M10 14L10 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M14 10L19.5 4.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M20 10L14 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    <path d="M14 10L14 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CalibrationGridIcon = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_3844_47846)">
      <rect x="4.5" y="4.5" width="3" height="3" stroke="currentColor" fill="transparent" />
      <rect x="10.5" y="4.5" width="3" height="3" stroke="currentColor" fill="transparent" />
      <rect x="16.5" y="4.5" width="3" height="3" stroke="currentColor" fill="transparent" />
      <rect x="16.5" y="10.5" width="3" height="3" stroke="currentColor" fill="transparent" />
      <rect x="10.5" y="10.5" width="3" height="3" stroke="currentColor" fill="transparent" />
      <rect x="4.5" y="10.5" width="3" height="3" stroke="currentColor" fill="transparent" />
      <rect x="4.5" y="16.5" width="3" height="3" stroke="currentColor" fill="transparent" />
      <rect x="10.5" y="16.5" width="3" height="3" stroke="currentColor" fill="transparent" />
      <rect x="16.5" y="16.5" width="3" height="3" stroke="currentColor" fill="transparent" />
    </g>
    <defs>
      <clipPath id="clip0_3844_47846">
        <rect width="24" height="24" rx="8" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

import { SHOW_CREATE_SUB_OBJECTIVE_MODAL, HIDE_CREATE_SUB_OBJECTIVE_MODAL } from 'actions/actionTypes';

export const initialState = { display: false };

const createSubObjectiveModal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CREATE_SUB_OBJECTIVE_MODAL:
      return {
        ...initialState,
        display: true,
      };

    case HIDE_CREATE_SUB_OBJECTIVE_MODAL:
      return {
        ...initialState,
        display: false,
      };

    default:
      return state;
  }
};

export default createSubObjectiveModal;

import { Icon } from '@chakra-ui/react';

export const GoalSettings = (props) => (
  <Icon viewBox="0 0 32 26" boxSize="16px" color="icon.g1" {...props}>
    <g id="Group 1278">
      <g id="Group 1277">
        <path
          id="Rectangle 1062"
          d="M0 23H32C32 24.6569 30.6569 26 29 26H3C1.34315 26 0 24.6569 0 23Z"
          fill="currentColor"
        />
        <g id="Group 1276">
          <path
            id="Rectangle 1061"
            d="M3 3C3 1.89543 3.89543 1 5 1H27C28.1046 1 29 1.89543 29 3V21H3V3Z"
            stroke="currentColor"
            strokeWidth="2"
          />
          <g id="Group 1275">
            <path
              id="Vector"
              d="M22.9014 11.2871L22.9006 11.2881C21.0966 13.6834 18.5716 15 16 15C13.4284 15 10.9032 13.6832 9.09934 11.2882L9.0986 11.2873C9.03224 11.1994 9 11.0988 9 11.0003C9 10.9015 9.03227 10.8009 9.09861 10.7131L9.09936 10.7121C10.9034 8.31677 13.4284 7 16 7C18.5716 7 21.0968 8.31689 22.9006 10.7121L22.9014 10.7131C22.9678 10.801 23 10.9014 23 11.0001C23 11.0988 22.9678 11.1992 22.9014 11.2871Z"
              stroke="currentColor"
              strokeWidth="2"
            />
            <circle id="Ellipse 264" cx="16" cy="11" r="2" fill="currentColor" />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Underline = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12.5266 15.3C14.5466 15.04 16 13.2267 16 11.1933V6.83333C16 6.37333 15.6266 6 15.1666 6C14.7066 6 14.3333 6.37333 14.3333 6.83333V11.2667C14.3333 12.38 13.58 13.3933 12.4866 13.6133C10.9866 13.9267 9.66665 12.78 9.66665 11.3333V6.83333C9.66665 6.37333 9.29331 6 8.83331 6C8.37331 6 7.99998 6.37333 7.99998 6.83333V11.3333C7.99998 13.7133 10.0866 15.6133 12.5266 15.3ZM7.33331 17.3333C7.33331 17.7 7.63331 18 7.99998 18H16C16.3666 18 16.6666 17.7 16.6666 17.3333C16.6666 16.9667 16.3666 16.6667 16 16.6667H7.99998C7.63331 16.6667 7.33331 16.9667 7.33331 17.3333Z"
      fill="currentColor"
    />
  </Icon>
);

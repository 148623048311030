import { Icon } from '@chakra-ui/react';

export const ArrowUp = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.9901 9.5C12.2023 9.5 12.4057 9.58771 12.5557 9.74364L15.7557 13.0701C15.9101 13.2251 15.9981 13.4381 16 13.6611C16.0018 13.8842 15.9173 14.0987 15.7657 14.2565C15.614 14.4142 15.4076 14.5019 15.193 14.5C14.9785 14.498 14.7736 14.4065 14.6245 14.2462L11.4245 10.9197C11.2745 10.7637 11.1903 10.5521 11.1903 10.3316C11.1903 10.1111 11.2745 9.89957 11.4245 9.74364C11.5745 9.58771 11.778 9.5 11.9901 9.5Z"
      fill="currentColor"
    />
    <path
      d="M11.9901 9.5C12.2023 9.5 12.4057 9.58771 12.5557 9.74364C12.7057 9.89957 12.79 10.111 12.79 10.3315C12.79 10.552 12.7058 10.7635 12.5558 10.9195L9.35582 14.246C9.15265 14.4499 8.86094 14.5276 8.58854 14.4499C8.31599 14.3722 8.10318 14.151 8.02844 13.8678C7.95384 13.5845 8.02831 13.2814 8.22465 13.0702L11.4245 9.74364C11.5745 9.58771 11.778 9.5 11.9901 9.5Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const MyCompanyEngagements = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_413_475)">
      <path
        d="M13.2106 10.1981L12.2406 8.6781C12.1506 8.5381 12.0206 8.5081 11.9506 8.5081C11.9106 8.5081 11.7406 8.5281 11.6406 8.6781L11.1806 9.4481H8.55062L8.35062 9.0181C8.10062 8.5181 7.99062 7.9881 8.00062 7.4481C8.03062 6.1381 8.83062 4.9181 10.0406 4.3381C11.3406 3.7181 12.8206 3.9581 14.0006 4.9981C15.4006 3.7781 17.1906 3.6681 18.5806 4.7181C19.9306 5.7381 20.3706 7.5081 19.6506 9.0081L19.4506 9.4381H16.4606L15.2406 7.1881C15.1506 7.0181 14.9906 7.0081 14.9206 6.9981C14.8406 6.9981 14.6906 7.0281 14.6106 7.1981L13.1906 10.1881L13.2106 10.1981ZM17.3606 7.9481H18.4506C18.6506 7.1081 18.2406 6.3481 17.6806 5.9181C17.2006 5.5481 16.4806 5.3481 15.7106 5.6681C16.0706 5.8381 16.3806 6.1081 16.5706 6.4781L17.3606 7.9381V7.9481ZM9.55062 7.9481H10.3206L10.3406 7.9081C10.6706 7.3481 11.2606 7.0181 11.9106 7.0081C12.2606 7.0081 12.6206 7.0981 12.9206 7.2981L13.2706 6.5781C13.2906 6.5281 13.3206 6.4781 13.3506 6.4281L13.0206 6.1381C12.0306 5.2681 11.1406 5.4881 10.6906 5.6981C9.98062 6.0381 9.52062 6.7381 9.50062 7.4781C9.50062 7.6381 9.52062 7.7881 9.55062 7.9481Z"
        fill="currentColor"
      />
      <path
        d="M18.8606 10.1581L14.2406 14.7481C14.1006 14.8881 13.8906 14.8881 13.7406 14.7481L9.14062 10.1581H11.3706C11.4306 10.1581 11.4806 10.1581 11.5306 10.1181L12.1306 9.8281L12.9606 11.1181C13.0606 11.2781 13.2106 11.2881 13.2806 11.2881C13.3506 11.2881 13.5006 11.2581 13.5806 11.0881L14.9706 8.1481L15.9806 10.0381C16.0206 10.1181 16.0906 10.1581 16.1806 10.1581H18.8706H18.8606Z"
        fill="currentColor"
      />
      <path
        d="M14.95 15.4481C14.7 15.6981 14.36 15.8381 14 15.8381C13.64 15.8381 13.32 15.7081 13.04 15.4481L12.6 15.0081H12V14.4081L8.58 11.0081H8.3C7.65 11.0081 7.18 11.4881 7.04 12.0081H5.3C4.55 12.0081 4 12.5581 4 13.3081V20.0081H15V15.3981L14.95 15.4481ZM7 18.0081H6V17.0081H7V18.0081ZM7 15.0081H6V14.0081H7V15.0081ZM10 18.0081H9V17.0081H10V18.0081ZM10 15.0081H9V14.0081H10V15.0081ZM13 18.0081H12V17.0081H13V18.0081Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_413_475">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

import { LANDING_PAGE_TYPES, REACTIONS } from 'constants/ProjectConstants';

import { FEED_TYPES, replaceCorrectTimeInTemplate } from 'services/helpers/feed';

import FeedReactions from './feedReactions';
import Post from './post';
import PostReason from './postReason';

class PostAction {
  constructor({ canPerform = true, reason = '' } = {}) {
    this.canPerform = canPerform;
    this.reason = !canPerform && reason; // making sure if canPerform is false, then only showing the reason
  }
}

export class PostActions {
  constructor({ reactions, edit, deleteAction, editWeightage } = {}) {
    this.reactions = reactions;
    this.edit = edit;
    this.delete = deleteAction;
    this.editWeightage = editWeightage;
  }

  // type = post or comment
  static fromJSON({ actionsJSON = {}, type, isContentStatePresent }) {
    //
    actionsJSON = actionsJSON || {};
    actionsJSON.reactions = actionsJSON.reactions || {};
    type = type || '';
    const edit = actionsJSON.edit || {};
    edit.canPerform = edit.canPerform && isContentStatePresent;
    edit.reason =
      !edit.canPerform &&
      (edit?.reason
        ? edit?.reason === 'Cannot edit system generated activity_card'
          ? 'System generated activity cards cannot be edited'
          : edit?.reason
        : `This ${type.toLowerCase()} is not editable`);
    const applauseRes = actionsJSON.reactions[REACTIONS.applause] || {};
    const applause = new PostAction(applauseRes);

    // adding a delete action. this is to be only used for goals.

    const deleteAction = actionsJSON.delete || {
      canPerform: false,
      reason: '',
    };

    const editWeightage = actionsJSON.editWeightage || {
      canPerform: false,
    };

    return new PostActions({
      reactions: { applause },
      edit,
      deleteAction,
      editWeightage,
    });
  }
}

// TODO: make a class named feedItemData and parse all the common feed data there and then inherit that class
export default class PostFeedItemData {
  constructor(props, json, parsePostTextAsJson = false) {
    if (props) this.fromProps(props);
    else if (json) this.fromJSON(json, parsePostTextAsJson);
  }

  fromProps({
    header,
    fromEmployee,
    toEmployee,
    text,
    media,
    id,
    createdDate,
    updatedDate,
    macrosRes,
    // postRes,
    reactions,
    isLiked,
    type = 'POST',
    landingPageIds,
    reason,
    comments,
    landingPage,
    contentState,
    isMergedTaskFeedItem,
    actions,
    visibilityType,
    subType,
    parsePostTextAsJson = false,
    feedback = false,
  }) {
    this.header = header;
    this.fromEmployee = fromEmployee;
    this.toEmployee = toEmployee;
    this.text = text;
    this.postItemData = parsePostTextAsJson ? JSON.parse(text) : {};
    this.media = media;
    this.id = id;
    this.createdDate = createdDate;
    this.updatedDate = updatedDate;
    this.macrosRes = macrosRes;
    // this.postRes = postRes;
    this.reactions = reactions;
    this.isLiked = isLiked;
    this.type = type;
    this.landingPageIds = landingPageIds;
    this.landingPage = landingPage || {
      data: {},
      ids: [],
    };
    this.reason = reason;
    this.comments = comments;
    this.contentState = contentState;
    this.isMergedTaskFeedItem = isMergedTaskFeedItem;
    this.actions = actions;
    this.visibilityType = visibilityType;
    this.subType = subType;
    this.feedback = feedback;
  }

  // parsePostTextAsJson for the types which received html markup as text earlier but now receive json in 'text' key
  fromJSON(json = {}, parsePostTextAsJson = false) {
    const postRes = json.post;
    if (parsePostTextAsJson && json?.type === FEED_TYPES.activityCardHtmlPost) {
      // this is a bad hack and should've been fixed from BE. Macros are to be used when data is embedded in some content such as title to display,
      // but here the value of header is in format of a macro,
      // all logic related to parsing content is encapsulated in Post text with macros text related components,
      // so to support this as working I have to rewrite that logic here with limitations.
      // the header must always exactly be of format `{{employees.7867.metadata.displayName}}`, where the numeric employee id only can be different

      let headerString = JSON.parse(json?.post?.text)?.header;
      headerString = headerString.slice(2, -2);
      const [macroKey, headerEmployeeId] = headerString.split('.');
      const postFromEmployee = postRes.macros[macroKey][headerEmployeeId];
      postRes.from = postFromEmployee;
    }
    if (json.modifications && json.modifications.replaceDate && !parsePostTextAsJson) {
      postRes.text = replaceCorrectTimeInTemplate(postRes.text);
    }

    const post = Post.fromJSON(postRes);
    const reason = PostReason.fromJSON(json.reason);
    const reactions = new FeedReactions();
    reactions.fromJSON(json.reactions || {});
    const type = post ? post.type : 'POST';
    let isMergedTaskFeedItem = false;
    if (json.landingPage) {
      if (json.landingPage.type === LANDING_PAGE_TYPES.task && json.landingPage.ids.length > 1)
        isMergedTaskFeedItem = true;
    }
    const actions = PostActions.fromJSON({
      actionsJSON: json.actions,
      type,
      isContentStatePresent: Boolean(post.contentState),
    });
    this.fromProps({
      header: json.header,
      id: json.uuid,
      ...post,
      landingPageIds: json.landingPageIds, // this is being used at some places and it shouldn't be, this is old logic. Problematic places include transactions table
      landingPage: json.landingPage,
      reactions,
      comments: json.comments ? json.comments.map((comment) => new PostFeedItemData(null, comment)) : [],
      type,
      reason,
      actions,
      isMergedTaskFeedItem,
      parsePostTextAsJson,
    });
  }
}

import { Icon } from '@chakra-ui/react';

export const Collaborators = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M16 10C16 10.1673 15.9932 10.333 15.9797 10.4969L14.9883 10.3444C14.9961 10.2306 15 10.1157 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C10.1157 15 10.2306 14.9961 10.3444 14.9883L10.4969 15.9797C10.333 15.9932 10.1673 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10Z"
      fill="currentColor"
    />
    <path
      d="M10 13L10.0384 12.9998L9.88411 11.9967C8.83347 11.9367 8 11.0657 8 10C8 8.89543 8.89543 8 10 8C11.0657 8 11.9367 8.83347 11.9967 9.88411L12.9998 10.0384L13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
      fill="currentColor"
    />
    <path
      d="M10 10L11.0861 15L11.5783 11.9926C11.6134 11.778 11.7809 11.61 11.9945 11.575L15 11.0824L10 10Z"
      fill="currentColor"
    />
    <path
      d="M14.0151 18.336C14.0151 17.6303 14.5872 17.0582 15.2929 17.0582H17.0707C17.7764 17.0582 18.3484 17.6303 18.3484 18.336V19.6116H14.0151V18.336Z"
      stroke="currentColor"
    />
    <path
      d="M17.1426 14.523C17.1426 15.0876 16.6845 15.5459 16.1188 15.5459C15.5531 15.5459 15.095 15.0876 15.095 14.523C15.095 13.9584 15.5531 13.5001 16.1188 13.5001C16.6845 13.5001 17.1426 13.9584 17.1426 14.523Z"
      stroke="currentColor"
    />
    <path
      d="M13.649 16.4161V16.4161C13.0835 16.4161 12.6251 16.874 12.6251 17.4395C12.6251 17.937 12.6251 18.5561 12.6251 19.157C12.6251 19.4387 12.8535 19.667 13.1352 19.667V19.667"
      stroke="currentColor"
    />
    <path
      d="M14.4033 13.6675C14.1811 13.4453 13.8216 13.5292 13.8216 13.5292C13.6868 13.585 13.5644 13.6668 13.4612 13.77C13.358 13.8732 13.2762 13.9957 13.2203 14.1305C13.1645 14.2653 13.1357 14.4098 13.1357 14.5557C13.1357 14.7016 13.1645 14.8461 13.2203 14.9809C13.2762 15.1157 13.358 15.2382 13.4612 15.3414C13.6378 15.5183 14.1811 15.6666 14.4033 15.4449"
      stroke="currentColor"
    />
    <path
      d="M18.7134 16.4161V16.4161C19.2789 16.4161 19.7373 16.874 19.7373 17.4395C19.7373 17.937 19.7373 18.5561 19.7373 19.157C19.7373 19.4387 19.509 19.667 19.2273 19.667V19.667"
      stroke="currentColor"
    />
    <path
      d="M17.959 13.6675C18.1812 13.4453 18.5407 13.5292 18.5407 13.5292C18.6755 13.585 18.798 13.6668 18.9011 13.77C19.0043 13.8732 19.0861 13.9957 19.142 14.1305C19.1978 14.2653 19.2266 14.4098 19.2266 14.5557C19.2266 14.7016 19.1978 14.8461 19.142 14.9809C19.0861 15.1157 19.0043 15.2382 18.9011 15.3414C18.7245 15.5183 18.1812 15.6666 17.959 15.4449"
      stroke="currentColor"
    />
  </Icon>
);

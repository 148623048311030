import {
  ADD_FILTER,
  REMOVE_FILTER,
  RESET_FILTERS,
  SET_FILTERS,
  RESET_ALL,
  SET_TEAM,
  SET_TAG_ID,
} from 'actions/actionTypes';

const initialState = {
  teamPage: {
    filters: [],
    teamId: null,
    tagId: null,
  },
  // homepage, profile page meshlist
  meshList: {
    filters: [],
    teamId: null,
  },
};

export default function globalFilters(state = initialState, action) {
  const sourcePage = action.sourcePage || 'teamPage';

  switch (action.type) {
    case ADD_FILTER: {
      const oldPageData = { ...state[sourcePage] };
      console.log(oldPageData, 'oldPageData');
      const newPageData = {
        ...oldPageData,
        filters: [
          ...oldPageData.filters,
          {
            type: action.data.type,
            value: action.data.value,
            displayValue: action.data.displayValue,
            operator: action.data.operator,
          },
        ],
      };
      return {
        ...state,
        [sourcePage]: newPageData,
      };
    }

    case REMOVE_FILTER: {
      const oldPageData = { ...state[sourcePage] };
      const newPageData = {
        ...oldPageData,
        filters: oldPageData.filters.filter(
          (f) => f.type !== action.data.type || f.value !== action.data.value || f.operator !== action.data.operator
        ),
      };
      return {
        ...state,
        [sourcePage]: newPageData,
      };
    }

    case RESET_FILTERS: {
      return {
        teamPage: { ...state.teamPage, filters: [] },
        meshList: { ...state.meshList, filters: [] },
      };
    }

    case RESET_ALL: {
      return {
        ...initialState,
      };
    }

    case SET_FILTERS: {
      return { ...state, [sourcePage]: { ...state[sourcePage], filters: action.data } };
    }

    case SET_TEAM: {
      return { ...state, [sourcePage]: { ...state[sourcePage], teamId: action.data } };
    }

    case SET_TAG_ID: {
      return { ...state, teamPage: { ...state.teamPage, tagId: action.data } };
    }

    default:
      return state;
  }
}

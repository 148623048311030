import { Icon } from '@chakra-ui/react';

export const PerformanceIconDualTone = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.82254 15.5373C4.29591 14.4708 4 13.2699 4 12C4 7.58172 7.58172 4 12 4C14.837 4 17.3291 5.47672 18.7495 7.70342L15.1362 9.73589L14.5 8.63397L16.1595 7.67584C15.3085 6.857 14.2163 6.28701 13 6.08296V8H11V6.08296C8.16229 6.55904 6 9.027 6 12C6 12.2511 6.01543 12.4987 6.0454 12.7417L7.09808 12.134L8.02347 13.7368L4.82254 15.5373Z"
      fill="#999999"
    />
    <circle cx="12" cy="12" r="2" fill="#999999" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 15V11H16L17.2488 12.2488L12.1387 16.5726L10.7809 14.8753L10.2248 14.1802L9.47 14.652L5.47 17.152L6.53 18.848L9.77518 16.8198L11.2191 18.6247L11.8613 19.4274L12.6459 18.7634L18.6679 13.6679L20 15Z"
      fill="#666666"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const MeshMavenGreen = (props) => (
  <Icon viewBox="0 0 80 80" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M64.7849 23.9491C65.0492 23.6664 65.2853 23.3603 65.4902 23.0337C65.7976 22.6163 65.9194 22.0949 65.8288 21.5867C65.7605 21.1312 65.3299 20.8163 64.8651 20.8823C64.8443 20.8852 64.8235 20.8881 64.8027 20.8925C64.5265 20.9555 64.2979 21.1415 64.182 21.3934C63.9652 21.2132 63.6757 21.14 63.3965 21.1971C62.9466 21.2923 62.6615 21.7273 62.7595 22.1667C62.7655 22.1931 62.7729 22.2209 62.7803 22.2458C62.9273 22.7423 63.2718 23.1626 63.7351 23.413C64.0677 23.6225 64.4181 23.8026 64.7834 23.9491H64.7849Z"
      fill="#5CC461"
    />
    <path
      d="M56.8376 55.5857C57.9037 55.5857 58.7679 54.7334 58.7679 53.6818C58.7679 52.6302 57.9037 51.7778 56.8376 51.7778C55.7714 51.7778 54.9072 52.6302 54.9072 53.6818C54.9072 54.7334 55.7714 55.5857 56.8376 55.5857Z"
      fill="#5CC461"
    />
    <path
      d="M11.8568 28.1388C12.4315 28.1388 12.8962 27.6804 12.8962 27.1136C12.8962 26.5468 12.4315 26.0884 11.8568 26.0884C11.2822 26.0884 10.8174 26.5468 10.8174 27.1136C10.8174 27.6804 11.2822 28.1388 11.8568 28.1388Z"
      fill="#5CC461"
    />
    <path
      d="M17.4525 58.0753C18.0272 58.0753 18.4919 57.6169 18.4919 57.0501C18.4919 56.4833 18.0272 56.0249 17.4525 56.0249C16.8779 56.0249 16.4131 56.4833 16.4131 57.0501C16.4131 57.6169 16.8779 58.0753 17.4525 58.0753Z"
      fill="#17C553"
    />
    <path
      d="M47.4486 12.6754C48.0233 12.6754 48.488 12.217 48.488 11.6502C48.488 11.0834 48.0233 10.625 47.4486 10.625C46.8739 10.625 46.4092 11.0834 46.4092 11.6502C46.4092 12.217 46.8739 12.6754 47.4486 12.6754Z"
      fill="#5CC461"
    />
    <path
      d="M58.0189 18.5295C58.0857 18.4358 58.0575 18.3098 57.9536 18.2483C57.8764 18.2029 57.7769 18.2044 57.6997 18.2527C57.4383 18.4226 56.9171 18.6555 56.3499 18.3054C56.2549 18.2469 56.1227 18.2688 56.0574 18.3552C56.0069 18.4211 56.0084 18.509 56.0603 18.5749C56.4613 19.0802 56.2148 19.5606 56.0336 19.8052C55.9742 19.8843 55.9846 19.9897 56.0574 20.0571C56.1004 20.0981 56.1583 20.1201 56.2207 20.1201C56.2667 20.1201 56.3098 20.1083 56.3484 20.0849C56.7271 19.8447 57.1577 19.6734 57.7383 20.0556C57.8318 20.1171 57.9625 20.0996 58.0323 20.0146C58.0872 19.9458 58.0857 19.852 58.0293 19.7861C57.5898 19.2706 57.7635 18.8766 58.0189 18.5295Z"
      fill="#5CC461"
    />
    <path
      d="M29.4685 13.5477C29.4536 13.6766 29.5472 13.7937 29.6764 13.8069C29.7729 13.8172 29.8649 13.7688 29.911 13.6839C30.1248 13.2753 30.5688 12.4243 31.8591 12.6235C31.9764 12.6426 32.0848 12.5635 32.1027 12.4463C32.1175 12.3511 32.0685 12.2588 31.9824 12.2193C30.796 11.6642 30.8999 10.7152 30.9489 10.2597C30.9608 10.144 30.8865 10.0371 30.7737 10.0078C30.6668 9.97996 30.5554 10.0283 30.5049 10.1279C30.2748 10.5848 29.7224 11.3318 28.6325 11.1985C28.5152 11.1838 28.4083 11.2673 28.3934 11.383C28.3815 11.4724 28.429 11.5588 28.5092 11.5998C29.4878 12.0977 29.5294 13.0336 29.4685 13.5462V13.5477Z"
      fill="#5CC461"
    />
    <path
      d="M64.5168 49.9289C64.5985 49.8058 64.5643 49.6418 64.4381 49.5613C64.3431 49.5027 64.2213 49.5041 64.1307 49.5671C63.8145 49.7897 63.1804 50.0929 62.4899 49.6374C62.3741 49.5598 62.2138 49.5891 62.1351 49.7004C62.0742 49.7868 62.0757 49.901 62.1395 49.9874C62.6266 50.6465 62.3281 51.2777 62.1068 51.5956C62.0356 51.6981 62.0474 51.8357 62.1365 51.9251C62.1885 51.9778 62.2598 52.0086 62.3355 52.0086C62.3905 52.0086 62.4454 51.9925 62.4914 51.9617C62.9517 51.6468 63.4744 51.4227 64.1797 51.9236C64.2941 52.0056 64.4544 51.9807 64.5361 51.8709C64.6029 51.7816 64.6015 51.6585 64.5317 51.5721C63.9986 50.8955 64.2109 50.3829 64.5183 49.9303L64.5168 49.9289Z"
      fill="#5CC461"
    />
    <path
      d="M11.5559 44.327C11.5381 44.4764 11.6465 44.6097 11.7965 44.6273C11.9079 44.6405 12.0148 44.5833 12.0682 44.4852C12.3147 44.0136 12.8315 43.0309 14.3282 43.2623C14.4619 43.2843 14.5896 43.192 14.6118 43.0587C14.6297 42.9489 14.5717 42.8434 14.4723 42.7966C13.0972 42.1551 13.2175 41.061 13.274 40.5367C13.2873 40.4034 13.2012 40.2804 13.072 40.2467C12.9488 40.2145 12.8196 40.2716 12.7617 40.3844C12.4929 40.9102 11.8529 41.7713 10.5878 41.619C10.4527 41.6015 10.3279 41.6981 10.3116 41.8329C10.2982 41.9368 10.3517 42.0364 10.4452 42.0833C11.5812 42.6589 11.6302 43.7368 11.5574 44.3285L11.5559 44.327Z"
      fill="#5CC461"
    />
    <path
      d="M13.6654 19.52C14.1955 19.2506 14.6974 18.9313 15.1651 18.5681C15.8288 18.1258 16.2817 17.4389 16.4228 16.6612C16.5683 15.9699 16.1199 15.2947 15.4175 15.1512C15.3849 15.1453 15.3552 15.1395 15.324 15.1351C14.8963 15.0824 14.4687 15.2303 14.1672 15.5349C13.9534 15.1644 13.5763 14.9095 13.1471 14.8466C12.4537 14.7514 11.8122 15.2288 11.7157 15.9128C11.7098 15.9552 11.7068 15.9963 11.7053 16.0387C11.6504 16.8311 11.9176 17.6117 12.4492 18.2078C12.8115 18.6823 13.2184 19.1217 13.6654 19.5186V19.52Z"
      fill="#5CC461"
    />
    <path
      d="M43.6553 19.8901C42.0299 17.6511 40.2236 15.5777 38.2321 13.6474C39.1984 13.1999 40.1647 12.7524 41.1325 12.3048C41.3637 13.5724 42.7033 14.6586 43.2346 15.8326C43.8448 17.1809 44.0315 18.8718 42.6631 19.8066C42.1606 20.1507 41.457 20.1521 40.9243 19.8788C39.2903 19.0375 38.5623 16.8622 36.7933 16.2334C37.4653 15.5041 38.1387 14.7733 38.8107 14.044C39.4942 15.5508 41.3565 18.7741 38.5264 19.3803C36.453 19.8236 35.3345 17.2814 33.6732 16.5903C34.4902 15.9134 35.3086 15.235 36.1257 14.558L36.1199 14.5198C37.484 16.2575 34.5606 18.0816 33.2066 16.3566C33.0285 16.13 32.9151 15.8808 32.8347 15.606C32.3967 14.0992 33.913 13.0016 35.2871 13.5738C36.0079 13.874 36.6296 14.3343 37.204 14.8554C37.4883 15.1132 37.7496 15.3936 38.0325 15.6514L38.3168 15.9077C38.7662 16.2065 38.703 16.2433 38.1244 16.0153L36.9441 16.562L37.0905 16.3821C36.9383 16.7971 36.7861 17.2135 36.6339 17.6284C36.6325 16.9373 35.8026 15.7193 35.5197 15.0934C35.0157 13.9831 36.3496 12.4819 37.5371 12.9039C38.6384 13.2962 39.5645 13.8287 40.4102 14.6345C41.0765 15.269 41.7255 16.4444 42.5382 16.8622C41.9581 16.8863 41.3794 16.9104 40.7994 16.9345L40.9745 16.8155C40.7218 17.1724 40.4691 17.5293 40.2178 17.8862C40.426 17.1412 39.352 16.0649 38.9615 15.5069C38.301 14.5637 37.8831 13.6148 37.6778 12.4861C37.405 10.9877 39.5774 10.1748 40.5782 11.1435C42.7751 13.2721 44.7752 15.5862 46.5686 18.0561C47.8666 19.8448 44.9475 21.6746 43.6553 19.8929V19.8901Z"
      fill="#009CFC"
    />
    <path
      d="M44.6973 47.0223C51.0366 48.6891 59.4794 48.7854 65.3018 45.4715C69.4112 43.1333 71.1184 38.4286 69.2375 34.0369C68.554 32.4408 66.2021 29.0773 64.0469 29.5518C63.0949 29.7614 62.7517 30.7768 62.3066 31.5061C61.7107 32.4819 60.9756 33.4081 60.1456 34.1998C56.9078 37.29 54.9923 37.079 52.3935 38.5263"
      fill="url(#paint0_linear_304_44234)"
    />
    <path
      d="M18.9181 15.0267C21.4223 12.3712 24.5108 15.7872 26.7809 16.6935C27.6496 17.0405 28.4407 16.7757 29.331 16.7162C30.415 16.644 31.502 16.5675 32.5789 16.4315C35.6128 16.0463 38.7933 17.0476 41.7224 17.7798C42.4087 17.8478 43.0979 17.8945 43.7857 17.9157C44.8439 17.9483 45.9122 17.9299 46.9604 17.767C48.1737 17.5773 49.1486 17.0023 50.2657 16.5278C51.1186 16.1653 52.3492 15.8976 53.0829 16.6511C54.2014 17.801 53.1274 19.9296 52.9436 21.2495C52.7297 22.7875 52.5243 24.3468 52.6866 25.9032C52.6866 25.9032 51.6341 29.077 51.5264 31.4732C51.2593 32.2295 51.7562 32.1119 52.6636 36.6665C53.4821 40.7693 54.0995 45.0463 53.0915 50.6743C51.8366 57.6776 47.8966 62.7235 41.0921 66.5785C39.8314 67.2923 38.3122 67.3603 36.9913 66.7598L33.6041 65.2189C27.3179 62.3596 20.8494 56.0985 19.9031 49.1491L19.3187 31.1078C18.9957 28.7385 19.3518 26.2785 19.3518 26.2785C19.3733 24.715 19.0287 22.3301 18.6755 20.8162C18.3338 19.3475 17.7709 17.032 18.5348 15.5719C18.6439 15.3637 18.7731 15.1839 18.921 15.0267H18.9181Z"
      fill="url(#paint1_linear_304_44234)"
    />
    <path
      d="M24.7606 31.9253C27.4858 38.5985 29.2935 48.5546 25.1999 54.8794C23.5114 57.4881 20.6023 59.4849 17.3759 59.3886C13.772 59.2824 11.0194 56.4132 10.0775 53.1559C9.74438 52.003 10.5384 51.2652 11.443 50.7341C13.6915 49.4128 15.9329 48.1807 17.3831 45.9572C18.8405 43.7238 19.916 41.2554 20.7746 38.743"
      fill="url(#paint2_linear_304_44234)"
    />
    <path
      d="M41.7888 70.0002C41.4169 70.0002 41.1398 69.6646 41.2116 69.3034C41.5404 67.6691 43.0006 66.437 44.7538 66.437C46.507 66.437 47.9687 67.6691 48.2961 69.3034C48.3679 69.6632 48.0908 70.0002 47.7189 70.0002H41.7873H41.7888Z"
      fill="#1A1A1A"
    />
    <path
      d="M24.9199 66.2853C24.5681 66.1663 24.4174 65.7599 24.604 65.4427C25.4526 64.0024 27.2403 63.3056 28.8987 63.8678C30.5571 64.4301 31.532 66.063 31.3037 67.7143C31.2535 68.0782 30.8802 68.3077 30.5284 68.1873L24.9185 66.2853H24.9199Z"
      fill="#1A1A1A"
    />
    <path
      d="M48.441 47.1536C48.2041 46.6437 47.9342 46.1551 47.6298 45.6949C43.4816 39.4139 33.8398 40.6389 30.7757 47.2938C27.8566 53.6342 31.8899 62.0026 37.6879 65.2571C39.4038 66.2201 40.8856 65.0999 42.3444 64.1581C45.5262 62.1032 48.286 58.8147 49.2465 55.1028C49.9114 52.5352 49.5797 49.6065 48.4425 47.155H48.441V47.1536Z"
      fill="url(#paint3_linear_304_44234)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.8031 51.8911L40.5065 50.6123C40.2595 50.6817 39.9838 50.6194 39.79 50.4282C39.5043 50.1464 39.5043 49.6903 39.79 49.4085C40.0757 49.1267 40.5381 49.1267 40.8238 49.4085C41.0177 49.5997 41.0794 49.8716 41.0105 50.1152L42.3071 51.3941C42.712 51.2 43.2145 51.2694 43.5505 51.6008C43.9755 52.02 43.9755 52.6984 43.5505 53.1176C43.1255 53.5368 42.4377 53.5368 42.0127 53.1176C41.6767 52.7862 41.6064 52.2905 41.8031 51.8911ZM38.5997 48.2345C38.3139 48.5163 37.8516 48.5163 37.5659 48.2345C37.2801 47.9526 37.2801 47.4966 37.5659 47.2148C37.8516 46.933 38.3139 46.933 38.5997 47.2148C38.8854 47.4966 38.8854 47.9526 38.5997 48.2345Z"
      fill="#FFBD00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.2966 49.2202L39.5932 47.9413C39.5242 47.6977 39.586 47.4258 39.7798 47.2346C40.0656 46.9528 40.5279 46.9528 40.8136 47.2346C41.0994 47.5165 41.0994 47.9725 40.8136 48.2529C40.6198 48.4441 40.3441 48.505 40.0971 48.437L38.8006 49.7159C38.9973 50.1152 38.9269 50.6109 38.5909 50.9423C38.1659 51.3615 37.4781 51.3615 37.0531 50.9423C36.6281 50.5231 36.6281 49.8447 37.0531 49.4255C37.3891 49.0941 37.8917 49.0247 38.2966 49.2188M42.004 46.0592C41.7182 45.7773 41.7182 45.3213 42.004 45.0395C42.2897 44.7577 42.752 44.7577 43.0378 45.0395C43.3235 45.3213 43.3235 45.7773 43.0378 46.0592C42.752 46.341 42.2897 46.341 42.004 46.0592Z"
      fill="#17C553"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0213 45.7601L42.3179 47.0389C42.5648 46.971 42.8405 47.0319 43.0343 47.223C43.3201 47.5049 43.3201 47.9609 43.0343 48.2427C42.7486 48.5245 42.2863 48.5245 42.0005 48.2427C41.8067 48.0515 41.7449 47.7796 41.8139 47.536L40.5173 46.2572C40.1124 46.4512 39.6098 46.3818 39.2738 46.0504C38.8488 45.6312 38.8488 44.9528 39.2738 44.5336C39.6989 44.1144 40.3866 44.1144 40.8116 44.5336C41.1476 44.865 41.218 45.3607 41.0213 45.7601ZM44.2247 49.4168C44.5104 49.1349 44.9727 49.1349 45.257 49.4168C45.5428 49.6986 45.5428 50.1546 45.257 50.435C44.9713 50.7169 44.509 50.7169 44.2247 50.435C43.9389 50.1532 43.9389 49.6972 44.2247 49.4168Z"
      fill="#008FF6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.8204 51.6009C41.1061 51.8827 41.1061 52.3387 40.8204 52.6192C40.5346 52.901 40.0723 52.901 39.7866 52.6192C39.5008 52.3373 39.5008 51.8813 39.7866 51.6009C40.0723 51.3191 40.5346 51.3191 40.8204 51.6009ZM44.5278 48.4413L43.2312 49.7202C43.3001 49.9637 43.2384 50.2357 43.0445 50.4269C42.7588 50.7087 42.2964 50.7087 42.0107 50.4269C41.725 50.145 41.725 49.689 42.0107 49.4086C42.2045 49.2174 42.4802 49.1565 42.7272 49.2245L44.0238 47.9456C43.8271 47.5463 43.8974 47.0506 44.2334 46.7192C44.6584 46.3 45.3462 46.3 45.7712 46.7192C46.1962 47.1384 46.1962 47.8168 45.7712 48.236C45.4352 48.5674 44.9327 48.6367 44.5278 48.4427V48.4413Z"
      fill="#FF1A61"
    />
    <path
      opacity="0.3"
      d="M15.0122 28.0856L14.9619 26.9031C14.9777 26.3054 15.3754 25.7899 15.9412 25.6384C18.0404 25.0762 24.4386 23.8809 31.8016 25.0917C31.8016 25.0917 35.4544 25.9585 38.5746 25.8268L38.754 25.848C41.8756 25.7305 45.4336 24.5748 45.4336 24.5748C52.6574 22.7805 56.8616 23.1345 59.0053 23.5268C59.584 23.633 60.0262 24.1146 60.098 24.7094L60.1569 25.8919C60.1827 26.4173 60.0349 26.9342 59.7405 27.3634C59.2983 28.0092 58.9163 28.7102 58.6464 29.4523C58.385 30.1717 58.51 30.9691 58.4353 31.7211C58.006 36.0859 55.8867 37.8491 55.8867 37.8491C55.8867 37.8491 53.9641 39.4352 50.2667 39.8049C48.2407 40.0074 46.7417 39.8233 45.402 39.1775C45.3863 39.169 45.369 39.1619 45.3532 39.1534C43.2382 38.1238 41.6128 36.2686 40.7987 34.0281L40.1454 32.2309C39.9487 31.6913 39.4174 31.3571 38.8603 31.4222V31.3911C38.3506 31.4265 37.9141 31.7848 37.7791 32.2961L37.2938 34.14C36.6893 36.4371 35.2419 38.4155 33.2303 39.6108C33.2145 39.6208 33.1987 39.6292 33.1829 39.6392C31.9079 40.3883 30.2136 40.8897 28.1775 40.9463C22.7442 41.095 20.3722 39.6604 20.3722 39.6604C20.3722 39.6604 18.0978 38.0728 17.265 33.759C17.1214 33.0155 17.1731 32.2125 16.8458 31.5157C16.5083 30.7991 16.0632 30.1307 15.5621 29.5217C15.229 29.1181 15.0337 28.6139 15.0107 28.0885V28.0856H15.0122Z"
      fill="#0092FF"
    />
    <path
      d="M57.7332 29.0816C57.7332 29.0816 57.5939 25.4603 54.8141 25.3485C52.0343 25.2366 47.3261 25.6756 44.9512 26.7902C42.5778 27.9047 42.5533 29.2431 42.9152 31.3886C43.3445 33.935 45.2226 37.4996 49.4857 37.5903C53.6999 37.6795 55.8135 37.0535 56.8301 34.4618C57.9802 31.5303 57.7332 29.083 57.7332 29.083V29.0816Z"
      fill="#0092FF"
    />
    <path
      d="M19.3158 31.1065C19.3158 31.1065 19.1378 27.4866 21.8975 27.1396C24.6572 26.7912 31.7101 26.3125 34.1726 27.2203C36.6351 28.1281 36.7758 29.4608 36.6035 31.6276C36.3982 34.2009 34.8388 37.9128 30.6002 38.3646C26.4089 38.8121 21.9248 38.8843 20.6856 36.389C19.2842 33.565 19.3158 31.1065 19.3158 31.1065Z"
      fill="#0092FF"
    />
    <path
      d="M40.9011 31.9693L39.0675 29.9902L36.4629 31.5246L40.802 37.6922C40.8552 37.7772 40.9873 37.7489 41.0016 37.6497L41.4424 34.5241C41.5674 33.6362 41.3764 32.7326 40.8997 31.9693H40.9011Z"
      fill="#FFBD00"
      stroke="#FFBD00"
      strokeWidth="6.88"
      strokeMiterlimit="10"
    />
    <path
      d="M14.9595 27.0775L14.9121 25.8949C14.9308 25.2959 15.3443 24.7789 15.9316 24.626C18.1069 24.0553 24.7362 22.8331 32.3534 24.0142C32.3534 24.0142 36.1312 24.8667 39.3619 24.7223L39.5471 24.7435C42.7792 24.6118 46.4679 23.442 46.4679 23.442C53.953 21.6179 57.3215 22.057 59.5385 22.4422C60.1358 22.5456 60.5938 23.0257 60.6642 23.6205L60.7202 24.803C60.7446 25.3284 60.5895 25.8468 60.2837 26.2773C59.8228 26.9259 59.4251 27.6284 59.1422 28.3719C58.868 29.0942 58.9943 29.8901 58.9153 30.6421C58.4516 35.0111 56.2504 36.7842 56.2504 36.7842C56.2504 36.7842 55.2367 38.2755 51.4072 38.6593C49.3095 38.8703 47.7587 38.6919 46.3746 38.0518C46.3573 38.0433 46.3401 38.0362 46.3243 38.0277C44.1389 37.0066 42.4647 35.157 41.6305 32.918L40.9614 31.1222C40.7604 30.584 40.2119 30.2512 39.6332 30.3192C39.1063 30.356 38.6511 30.686 38.5104 31.1987L38.0007 33.0454C37.366 35.3468 35.8584 37.3309 33.7706 38.5347C33.7548 38.5446 33.7376 38.5531 33.7218 38.563C32.3994 39.3179 30.6419 39.8263 28.5355 39.8914C22.9098 40.0614 20.4603 38.6381 20.4603 38.6381C20.4603 38.6381 18.1126 37.059 17.2683 32.7466C17.1233 32.0031 17.1793 31.2001 16.8433 30.5047C16.4973 29.7881 16.0393 29.1225 15.5238 28.5149C15.1806 28.1113 14.981 27.6086 14.9595 27.0831V27.0775Z"
      fill="#1A1A1A"
    />
    <path
      d="M56.8595 28.1681C56.8595 28.1681 56.703 24.5496 54.0753 24.4193C51.4477 24.289 46.9951 24.6983 44.7581 25.7973C42.521 26.8949 42.5067 28.2332 42.8627 30.3788C43.2863 32.9252 45.084 36.4997 49.1159 36.6172C53.1018 36.7334 55.0962 36.1216 56.041 33.537C57.1093 30.6139 56.8595 28.1667 56.8595 28.1667V28.1681Z"
      fill="#84C3F7"
    />
    <path
      d="M19.2631 30.0982C19.2631 30.0982 19.085 26.4783 21.8447 26.1313C24.6044 25.7829 31.6574 25.3042 34.1199 26.212C36.5824 27.1198 36.7231 28.4525 36.5508 30.6193C36.3454 33.1926 34.7861 36.9045 30.5475 37.3563C26.3562 37.8038 21.872 37.876 20.6329 35.3807C19.2315 32.5567 19.2631 30.0982 19.2631 30.0982Z"
      fill="#84C3F7"
    />
    <path
      d="M30.0276 30.394C30.0391 29.8969 30.1209 29.4239 30.2616 28.992C30.477 29.1803 30.7584 29.2993 31.0686 29.3049C31.7621 29.3191 32.3365 28.7767 32.3522 28.0927C32.3623 27.6267 32.111 27.216 31.7305 26.9965C32.111 26.7657 32.5332 26.641 32.9768 26.6509C34.5592 26.6849 35.8055 28.4155 35.7595 30.5158C35.7136 32.6175 34.3926 34.2929 32.8103 34.2589C31.228 34.2249 29.9817 32.4943 30.0276 30.394Z"
      fill="#1A1A1A"
    />
    <path
      d="M50.9322 27.7641C51.6228 27.6948 52.1254 27.0872 52.055 26.406C52.0177 26.0477 51.8282 25.7418 51.5582 25.5407C51.7822 25.4359 52.0234 25.3665 52.2761 25.3424C53.8513 25.1852 55.3029 26.7516 55.5183 28.8419C55.7337 30.9322 54.6309 32.7549 53.0558 32.9135C51.4807 33.0707 50.029 31.5044 49.8137 29.414C49.7433 28.7243 49.8151 28.0644 50.0018 27.4837C50.2516 27.6905 50.5833 27.801 50.9336 27.7656L50.9322 27.7641Z"
      fill="#1A1A1A"
    />
    <path
      d="M40.4512 31.2993L40.9767 37.5774C41.0514 37.661 41.2064 37.6072 41.2122 37.4967L41.3917 33.9972C41.4433 33.0044 41.1102 32.0513 40.4512 31.2993Z"
      fill="#FFBD00"
    />
    <defs>
      <linearGradient
        id="paint0_linear_304_44234"
        x1="50.8384"
        y1="36.7702"
        x2="69.6011"
        y2="44.0637"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.16" stopColor="#83C3F7" />
        <stop offset="0.22" stopColor="#84C3F7" />
        <stop offset="0.32" stopColor="#7EC1F7" />
        <stop offset="0.43" stopColor="#6FBEFA" />
        <stop offset="0.56" stopColor="#56B9FE" />
        <stop offset="0.58" stopColor="#52B9FF" />
        <stop offset="1" stopColor="#0092FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_304_44234"
        x1="38.1084"
        y1="67.2045"
        x2="35.735"
        y2="13.3837"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.44" stopColor="#84C3F7" />
        <stop offset="0.52" stopColor="#7EC1F7" />
        <stop offset="0.62" stopColor="#6FBBF8" />
        <stop offset="0.74" stopColor="#56B2F9" />
        <stop offset="0.86" stopColor="#33A5FB" />
        <stop offset="0.98" stopColor="#0794FE" />
        <stop offset="1" stopColor="#0092FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_304_44234"
        x1="15.5108"
        y1="39.5701"
        x2="25.7466"
        y2="57.545"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.29" stopColor="#83C3F7" />
        <stop offset="0.36" stopColor="#84C3F7" />
        <stop offset="0.46" stopColor="#7EC1F7" />
        <stop offset="0.58" stopColor="#6FBEFA" />
        <stop offset="0.72" stopColor="#56B9FE" />
        <stop offset="0.74" stopColor="#52B9FF" />
        <stop offset="1" stopColor="#0092FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_304_44234"
        x1="39.6938"
        y1="65.6267"
        x2="39.6938"
        y2="41.5864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#88C5F7" />
        <stop offset="0.07" stopColor="#A8D4F9" />
        <stop offset="0.14" stopColor="#C3E2FB" />
        <stop offset="0.21" stopColor="#D9ECFC" />
        <stop offset="0.3" stopColor="#EAF5FD" />
        <stop offset="0.41" stopColor="#F6FAFE" />
        <stop offset="0.57" stopColor="#FDFEFE" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const ManagerThickOutlined = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_3437_70805)">
      <path
        d="M7 12.4165C7 11.9472 7.35126 11.5599 7.80523 11.5032L10.4479 18.7102C10.487 18.8171 10.5368 18.914 10.5951 19.0009H7.82765C7.37055 19.0009 7 18.6303 7 18.1732V12.4165ZM15.77 19.0009H12.7501C12.8054 18.9185 12.8531 18.827 12.8914 18.7266L15.6491 11.5067C16.1851 11.5816 16.5976 12.0419 16.5976 12.5985V18.1732C16.5976 18.6303 16.2271 19.0009 15.77 19.0009Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M10.7148 13.142L11.8577 16.5706L13.0006 13.142L11.8577 11.4277L10.7148 13.142Z" fill="currentColor" />
      <circle cx="11.8583" cy="7.42857" r="2.42857" stroke="currentColor" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_3437_70805">
        <rect width="24" height="24" rx="8" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

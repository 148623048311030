import { Icon } from '@chakra-ui/react';

export const RoleIcon = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_3437_70787)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10H4V8C4 7.44772 4.44772 7 5 7H9V6C9 4.89543 9.89543 4 11 4H13C14.1046 4 15 4.89543 15 6V7H19C19.5523 7 20 7.44772 20 8V10ZM20 11H4V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V11ZM18 13V18H6V13H18ZM12 8C11.4477 8 11 7.55229 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7C13 7.55228 12.5523 8 12 8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3437_70787">
        <rect width="24" height="24" rx="8" fill="transparent" />
      </clipPath>
    </defs>
  </Icon>
);

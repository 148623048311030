import {
  UPDATE_FEED,
  ADD_FEED,
  ADD_COMMENTS_TO_FEED,
  UPDATE_FEED_COMMENTS,
  UPDATE_FEED_COMMENT,
  UPDATE_FEED_TALKING_POINT_COMMENT,
  CLEAR_FEED,
  UPDATE_FEED_ITEM,
} from 'actions/actionTypes';

export const initialState = { result: [], entities: { comments: {}, posts: {} } };

export function feed(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FEED:
      return action.data;
    case ADD_FEED: {
      const newState = { ...initialState };
      newState.result = [...state.result, ...action.data.result];
      newState.entities.comments = { ...state.entities.comments, ...action.data.entities.comments };
      newState.entities.posts = { ...state.entities.posts, ...action.data.entities.posts };
      return newState;
    }
    case CLEAR_FEED: {
      return { ...initialState };
    }
    case ADD_COMMENTS_TO_FEED: {
      // action.data.comments is an object of normalized comments according to the comments schema inside schema file
      const newState = { ...state };
      // adding comments to the object which contains all the comments
      newState.entities.comments = {
        ...state.entities.comments,
        ...action.data.comments.entities.comments,
      };
      // adding comments ids to the comments array of that particular feed
      const currentPostOldData = state.entities.posts[action.data.feedId];
      const alreadyExistingComments = currentPostOldData.comments || [];
      // adding comments to the object which contains all the comments
      let comments;
      if (action.data.append) {
        comments = [...action.data.comments.result, ...alreadyExistingComments];
      } else comments = [...alreadyExistingComments, ...action.data.comments.result];
      // removing duplicates
      // TODO: Add polyfill if required, set is partially supported in edge
      comments = [...new Set(comments)];
      newState.entities.posts[action.data.feedId].comments = comments;
      return newState;
    }

    case UPDATE_FEED_ITEM: {
      const newState = { ...state };
      const oldPostData = newState.entities.posts[action.postId];
      // making sure that if the response that is returned from backend doesn't have some data then we use the old data
      newState.entities.posts[action.postId] = { ...oldPostData, ...action.post };
      return newState;
    }

    case UPDATE_FEED_COMMENTS: {
      const newState = { ...state };
      // adding comments to the object which contains all the comments
      newState.entities.comments = {
        ...state.entities.comments,
        ...action.data.comments.entities.comments,
      };

      // adding comments ids to the comments array of that particular feed
      newState.entities.posts[action.data.feedId].comments = [...action.data.comments.result];
      return newState;
    }
    case UPDATE_FEED_COMMENT: {
      const newState = { ...state };
      newState.entities.comments[action.commentId] = action.comment;
      return newState;
    }
    case UPDATE_FEED_TALKING_POINT_COMMENT: {
      const newState = { ...state };
      newState.entities.comments[action.commentId] = action.comment;
      return newState;
    }
    default:
      return state;
  }
}

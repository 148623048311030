import { Icon } from '@chakra-ui/react';

export const Email1 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M20 7.5C20 6.675 19.28 6 18.4 6H5.6C4.72 6 4 6.675 4 7.5V16.5C4 17.325 4.72 18 5.6 18H18.4C19.28 18 20 17.325 20 16.5V7.5ZM18.4 7.5L12 11.25L5.6 7.5H18.4ZM18.4 16.5H5.6V9L12 12.75L18.4 9V16.5Z"
      fill="currentColor"
    />
  </Icon>
);

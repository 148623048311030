import { Icon } from '@chakra-ui/react';

export const MyTeam = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M17.8 16.5C17.8 16.039 17.734 15.6114 17.615 15.216C17.7103 15.259 17.8031 15.3039 17.8927 15.3505C18.769 15.806 19 16.2306 19 16.5V17H17.8V16.5ZM11.4 9C11.4 10.1681 10.5279 11 9.6 11C8.67215 11 7.8 10.1681 7.8 9C7.8 7.8319 8.67215 7 9.6 7C10.5279 7 11.4 7.8319 11.4 9ZM5 16.5C5 16.3416 5.06629 16.1458 5.3152 15.8994C5.57304 15.6443 5.97459 15.3922 6.49235 15.17C7.53239 14.7237 8.79809 14.5 9.6 14.5C10.4019 14.5 11.6676 14.7237 12.7077 15.17C13.2254 15.3922 13.627 15.6443 13.8848 15.8994C14.1337 16.1458 14.2 16.3416 14.2 16.5V17H5V16.5ZM16.2 9C16.2 10.0558 15.4875 10.8369 14.6654 10.9774C14.8822 10.3591 15 9.69192 15 9C15 8.30808 14.8822 7.64091 14.6654 7.02263C15.4875 7.16308 16.2 7.94421 16.2 9Z"
      stroke="currentColor"
      strokeWidth="2"
      fill="transparent"
    />
  </Icon>
);

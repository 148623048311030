import { Icon } from '@chakra-ui/react';

export const MyOrgReviews = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.8 17.1384L11 16.6407C10.9 15.7449 10.4 14.9487 9.7 14.451C9.7 14.3515 9.7 14.3515 9.7 14.2519V13.0575C9.7 12.8585 9.6 12.7589 9.4 12.6594L8.3 12.0622C8.1 11.9627 7.9 11.9627 7.7 12.0622L6.5 12.6594C6.4 12.7589 6.3 12.8585 6.3 13.0575V14.3515V14.451C5.6 14.9487 5.1 15.7449 5 16.7403L4.3 17.1384C4.1 17.2379 4 17.437 4 17.6361V18.93C4 19.1291 4.1 19.2286 4.2 19.3281L5.2 19.9253C5.3 20.0249 5.5 20.0249 5.6 19.9253L6.3 19.5272C6.8 19.7263 7.4 19.9253 8 19.9253C8.6 19.9253 9.2 19.7263 9.7 19.4277L10.4 19.8258C10.5 19.9253 10.7 19.9253 10.8 19.8258L11.8 19.2286C11.9 19.1291 12 19.0295 12 18.8305V17.5365C12 17.3375 11.9 17.2379 11.8 17.1384ZM6.9 18.6314V17.5365C6.9 17.3375 6.8 17.2379 6.7 17.1384L6 16.7403C6.1 15.944 6.6 15.3468 7.3 15.1477L7.8 15.4463C8 15.5459 8.2 15.5459 8.3 15.4463L8.8 15.2473C9.5 15.5459 9.9 16.1431 10 16.9393L9.4 17.3375C9.3 17.437 9.2 17.5365 9.2 17.7356V18.7309C8.9 18.8305 8.5 18.93 8 18.93C7.6 18.93 7.2 18.8305 6.9 18.6314Z"
      fill="currentColor"
    />
    <path
      d="M13.7999 8.37946L13.8999 8.479C14.1999 9.17573 14.8999 9.77293 15.6999 9.77293H15.8999L15.6999 9.87246C15.0999 10.3701 14.7999 11.2659 14.9999 12.0622V12.2613L14.8999 12.1617C14.5999 12.0622 14.1999 11.9627 13.7999 11.9627C13.3999 11.9627 12.9999 12.0622 12.5999 12.2613C12.5999 12.1617 12.5999 12.0622 12.4999 12.0622C12.5999 11.3655 12.2999 10.5692 11.7999 10.0715L11.6999 9.87246H11.8999C12.6999 9.77293 13.3999 9.27526 13.6999 8.57853L13.7999 8.37946ZM13.7999 4C13.6999 4 13.5999 4.09953 13.4999 4.19907L11.8999 7.68273C11.8999 7.78226 11.7999 7.8818 11.6999 7.8818L7.7999 8.27993C7.4999 8.27993 7.3999 8.67806 7.5999 8.7776L10.3999 11.3655C10.4999 11.465 10.4999 11.5645 10.4999 11.6641V12.2613C10.5999 12.4603 10.6999 12.7589 10.6999 13.0575V13.3561C11.1999 13.7543 11.6999 14.2519 11.9999 14.8491L13.5999 13.9533C13.5999 13.9533 13.6999 13.9533 13.7999 13.9533C13.8999 13.9533 13.8999 13.9533 13.9999 13.9533L17.2999 15.8445C17.2999 15.8445 17.3999 15.8445 17.4999 15.8445C17.6999 15.8445 17.7999 15.6454 17.7999 15.4463L16.9999 11.7636C16.9999 11.6641 16.9999 11.5645 17.0999 11.465L19.8999 8.87713C20.0999 8.67806 19.9999 8.37946 19.6999 8.37946L15.8999 7.98133C15.7999 7.98133 15.6999 7.8818 15.6999 7.78227L14.0999 4.2986C13.9999 4.09953 13.8999 4 13.7999 4Z"
      fill="currentColor"
    />
  </Icon>
);

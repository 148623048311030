import { Icon } from '@chakra-ui/react';

export const RequestFeedback = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g id="Request Feedback">
      <g id="Vector">
        <path
          d="M14.1109 6.73868L14.8835 7.65948C16.4957 6.89779 19.1576 6.24625 21.4091 7.68728C18.7176 7.58378 16.6832 9.54808 16.5623 9.66L17.3349 10.5808L13.3144 10.6834L14.1109 6.73868Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6255 6.44136L13.976 4.69189H6.96015C6.60858 4.69189 6.21529 4.74421 5.9086 4.98003C5.59115 5.22411 5.4082 5.6307 5.4082 6.24383V15.0475C5.4082 15.5663 5.49213 15.9751 5.76235 16.2453C6.03257 16.5155 6.44135 16.5994 6.96022 16.5994H8.11603L8.10789 18.0078L8.10948 18.0736C8.11425 18.2731 8.11934 18.4857 8.16346 18.6706C8.21043 18.8674 8.30678 19.0557 8.50956 19.1785L8.51812 19.1837L8.52715 19.1879C8.69594 19.2681 8.87795 19.3082 9.06006 19.3082C9.37472 19.3082 9.67116 19.1867 9.91097 18.9791L12.4504 16.5994H18.4733C18.9922 16.5994 19.4009 16.5155 19.6712 16.2453C19.9414 15.9751 20.0253 15.5663 20.0253 15.0475V10.7687L18.2758 10.9277V14.0598C18.2758 14.4962 17.922 14.8499 17.4856 14.8499H12.3723C12.0577 14.8499 11.7613 14.9715 11.5215 15.1792L9.86672 16.641V14.8499H7.94793C7.51154 14.8499 7.15777 14.4962 7.15777 14.0598V7.23149C7.15777 6.79511 7.51154 6.44136 7.94793 6.44136H13.6255Z"
          fill="currentColor"
        />
      </g>
    </g>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CascadedListView = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 8.5H14.5C15.3284 8.5 16 9.17157 16 10C16 10.8284 15.3284 11.5 14.5 11.5H9.5C8.67157 11.5 8 10.8284 8 10C8 9.17157 8.67157 8.5 9.5 8.5ZM9.5 9.5C9.22386 9.5 9 9.72386 9 10C9 10.2761 9.22386 10.5 9.5 10.5H14.5C14.7761 10.5 15 10.2761 15 10C15 9.72386 14.7761 9.5 14.5 9.5H9.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 12.5H14.5C15.3284 12.5 16 13.1716 16 14C16 14.8284 15.3284 15.5 14.5 15.5H12.5C11.6716 15.5 11 14.8284 11 14C11 13.1716 11.6716 12.5 12.5 12.5ZM12.5 13.5C12.2239 13.5 12 13.7239 12 14C12 14.2761 12.2239 14.5 12.5 14.5H14.5C14.7761 14.5 15 14.2761 15 14C15 13.7239 14.7761 13.5 14.5 13.5H12.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46213 12.6716V15.5001L9.89164 14.0858L8.46213 12.6716Z"
      fill="currentColor"
    />
  </Icon>
);

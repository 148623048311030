import { Icon } from '@chakra-ui/react';

export const MyGoals = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9001 6.5V8.6L11.6001 10.8C11.1001 11.3 11.1001 12 11.6001 12.5C11.8001 12.7 12.2001 12.9 12.5001 12.9C12.8001 12.9 13.1001 12.8 13.3001 12.5L15.7001 10.1H17.5001L20.0001 7.7H16.3001V4L13.9001 6.5Z"
      fill="currentColor"
    />
    <path
      d="M8.9999 10.8C8.6999 10.7 8.2999 10.6 7.8999 10.6C7.5999 10.6 7.1999 10.7 6.8999 10.8C7.4999 8.50001 9.5999 6.70001 11.9999 6.70001C12.2999 6.70001 12.5999 6.70001 12.8999 6.80001V8.20001L12.2999 8.70001C12.1999 8.70001 12.0999 8.70001 11.9999 8.70001C10.5999 8.70001 9.4999 9.60001 8.9999 10.8Z"
      fill="currentColor"
    />
    <path
      d="M17.2998 11.9C17.2998 14.8 14.9998 17.1 12.0998 17.1C11.9998 17.1 11.9998 17.1 11.8998 17.1C11.5998 16.9 11.1998 16.6 10.7998 16.4C10.4998 16.3 10.0998 16.1 9.7998 16.1C10.1998 15.8 10.4998 15.3 10.6998 14.8C11.0998 15 11.4998 15.2 11.9998 15.2C13.7998 15.2 15.1998 13.8 15.1998 12L16.0998 11.1H17.1998C17.1998 11.4 17.2998 11.7 17.2998 11.9Z"
      fill="currentColor"
    />
    <path
      d="M9.9 13.7C9.9 14.8 9 15.8 7.9 15.8C6.8 15.8 6 14.8 6 13.7C6 12.5 6.9 11.6 7.9 11.6C8.9 11.6 9.9 12.5 9.9 13.7Z"
      fill="currentColor"
    />
    <path
      d="M11.8 19V20H4V19C4 18.7 4.1 18.4 4.4 18.1C4.7 17.9 5 17.6 5.4 17.4C6.2 17 7.2 16.9 7.9 16.9C8.6 16.9 9.5 17.1 10.4 17.4C10.8 17.6 11.2 17.8 11.4 18.1C11.7 18.3 11.8 18.7 11.8 19Z"
      fill="currentColor"
    />
  </Icon>
);

import {
  createOrUpdateTaskApi,
  getTeamTasksAndStatsApi,
  getTeamTasksAndStatsApiNew,
  getTaskDetailsApi,
  getTeamTasksStatsApi,
  deleteTaskApi,
  saveDiscussionApi,
  getTeamPerformanceApi,
  getTaskCommentsApi,
  getCheckInTasksApi,
  getGoalTasksApi,
  getSubTasksApi,
  getProgressGraphDataApi,
  refreshMetricApi,
  getTalkingPointCommentsApi,
  updateCheckInDateTimeApi,
  recordCheckInApi,
  saveOneOnOneRatingApi,
  acknowledgeOneOnOneApi,
  updateGoalsWeightageApi,
  getObjectiveHeirArchyApi,
  getGoalsTimelineListApi,
  getGoalWeightagesApi,
  getInitiativeStatsApi,
  getInitiativesTableDataApi,
  getInitiativesCardsDataApi,
  getReviewPeriodSummaryInitiativesApi,
  getMyInitiativesTableDataApi,
  getInitiativesTableDataApiForProfileAndSummary,
} from 'api/task';
import Employee from 'models/employee';
import PostFeedItemData from 'models/postFeedItemData';
import Task1 from 'models/task1';

import {
  TASK_TYPES_1,
  TEAM_TASKS_VIEW_TYPES,
  SEARCH_TAG_TYPES,
  TASK_TYPES_2,
  GOAL_PROGRESS_ENTITIES,
} from 'constants/ProjectConstants';
import { getEpochFromDate } from 'constants/helper';

import { getEmployeeObjectFromRes } from './helpers/employee';
import { convertParentPostsIntoBackendFormat, getStartEndTimeFromDurationKey } from './helpers/task';

function convertSubObjectiveToTagsObject(subObjective) {
  if (!subObjective || !subObjective.objectiveType) return null;
  return {
    [subObjective.objectiveType]: [
      {
        uuid: subObjective.objectiveId,
        objectiveTimeBound: { uuid: subObjective.objectiveTimeBoundId },
      },
    ],
  };
}

export function createOrUpdateTask({
  id,
  text,
  to,
  dueDate,
  macros,
  lastEditedBy,
  completionDate,
  editorState,
  // teamId,
  teams,
  removedTeam = null,
  description,
  key,
  tagType,
  type = TASK_TYPES_2.task,
  tagId,
  recurrenceDetails,
  taskType = TASK_TYPES_1.task,
  recurrentSubTaskType,
  parentTaskId,
  target,
  measure,
  actual,
  // overrideToIds = true,
  toIds = [],
  visibilityType,
  startDate,
  toIdType,
  subObjective,
  isOnBoarding,
  startingProgressValue,
  goalProgressLevel,
  goalClosureStatus,
  weightage,
  status,
  parentPosts, // for having multiple parents like in case of tasks that are linked to goal
  source,
  metricCalculationExpression,
  goalName,
  orgData = null,
  trackedByPlugin,
  metricId,
  measureType,
}) {
  // if (overrideToIds) {
  //   // case : non-list view
  //   if (!id) {
  //     // new task
  //     // todo: remove toId from the reducer and use toIds everywhere
  //     toIds = [{ uuid: to ? to.id : null }];
  //     if (macros.employees && Object.keys(macros.employees).length > 0) {
  //       toIds = Object.keys(macros.employees).map(eid => ({ uuid: macros.employees[eid].uuid }));
  //     }
  //   } else {
  //     // existing task
  //     const subTaskToIds = toIdType[TAG_ID_TYPE.subTask];
  //     // 1 - add existing subtask toIds
  //     if (subTaskToIds) {
  //       toIds = [...subTaskToIds];
  //     }
  //     // 2 - add existing explicit toIds
  //     const explicitToIds = toIdType[TAG_ID_TYPE.explicit];
  //     if (explicitToIds) {
  //       toIds = [...toIds, ...explicitToIds];
  //     }
  //     // 3 - add current mentions
  //     if (macros.employees && Object.keys(macros.employees).length > 0) {
  //       toIds = [...toIds, ...Object.keys(macros.employees).map(eid => macros.employees[eid].uuid)];
  //     }
  //     toIds = [...new Set(toIds)].map(id => ({ uuid: id, ownerType: Task1.EMPLOYEE_TYPES.owner }));
  //   }
  // } else {
  // case : list view
  toIds = toIds.map((employee) => ({
    uuid: employee.id,
    ownerType: employee.ownerType || Task1.EMPLOYEE_TYPES.collaborator,
  }));
  // }

  const tags = convertSubObjectiveToTagsObject(subObjective);
  if (to && to.id) {
    to = {
      uuid: to.id,
    };
  }

  // TODO: remove this logic from here
  // if the active page is 'hashtag', then add the current hashtag to the macros while creating/updating the task
  const updatedMacros =
    tagType === SEARCH_TAG_TYPES.tag
      ? {
          ...macros,
          attributes: { orgTag: { ...macros.attributes.orgTag, [tagId]: { uuid: tagId } } },
        }
      : macros;
  const completionTS = completionDate && getEpochFromDate(completionDate);
  let dueTS = dueDate && getEpochFromDate(dueDate);
  // const team = {};
  // const teams = [];
  // if (teamId) {
  //   team.uuid = teamId;
  //   teams.push(team);
  // }
  /*
        Send removedTeam and the newTeam
  */
  const uiKey = key;
  let parentPost = null;
  if (parentTaskId) {
    parentPost = {
      uuid: parentTaskId,
    };
  }

  // if (Array.isArray(teams)) teams = teams.map((team) => ({ uuid: team.id || team.uuid }));
  if (removedTeam) removedTeam.uuid = removedTeam.id || removedTeam.uuid;

  if (parentPosts) parentPosts = convertParentPostsIntoBackendFormat(parentPosts);
  let startTS = startDate && getEpochFromDate(startDate);
  // if type is GOAL, set taskType as null
  // also set default org value for dueTS and startTS if not specified
  if (type === TASK_TYPES_2.goal) {
    // do not send team with goal
    // teams = null;
    // taskType = null;
    if (orgData) {
      startTS = startTS || orgData.defaultGoalStartTS;
      dueTS = dueTS || orgData.defaultGoalDueTS;
    }
  }

  return createOrUpdateTaskApi({
    id,
    text,
    to,
    dueTS,
    uiKey,
    macros: updatedMacros,
    // lastEditedBy,
    completionTS,
    // contentState,
    description,
    startingProgressValue,
    teams: [],
    toIds,
    recurrenceDetails,
    taskType,
    recurrentSubTaskType,
    parentPost,
    type,
    target,
    measure,
    actual,
    visibilityType,
    startTS,
    tags,
    isOnBoarding,
    parentPosts,
    goalProgressLevel,
    goalClosureStatus,
    weightage,
    status,
    source,
    metricCalculationExpression,
    goalName,
    removedTeam,
    trackedByPlugin,
    metricId,
    measureType,
  }).then((res) => (res.entity ? Task1.fromJSON(res.entity) : null));
}

export function getGoalTasks({ goalId, limit = 3, offset = 0 }) {
  return getGoalTasksApi({ goalId, limit, offset }).then((res) =>
    res.entity.map((taskJSON) => Task1.fromJSON(taskJSON))
  );
}

export function getSubTasks({ taskId, limit = 3, offset = 0 }) {
  return getSubTasksApi({ taskId, limit, offset }).then((res) =>
    res.entity.map((taskJSON) => Task1.fromJSON(taskJSON))
  );
}

// todo: start passing filters here (POST api)
export function getTeamPerformance({ durationKey, teamId }) {
  const duration = getStartEndTimeFromDurationKey(durationKey);
  return getTeamPerformanceApi({ durationKey, duration, teamId });
}

export function getTeamTasksAndStatsForCardViewNew({
  durationKey,
  limit = 3,
  offset = 0,
  filters,
  tagId,
  tagType,
  type,
  startTS,
  endTS,
  timeBoundPeriod = null,
  selectedCohort = null,
}) {
  // remove this method
  const duration = getDuration({ startTS, endTS, durationKey });

  return getTeamTasksAndStatsApiNew({
    duration,
    resourceType: TEAM_TASKS_VIEW_TYPES.card,
    limit,
    offset,
    filters: constructFiltersObject(filters, type),
    tagId,
    tagType,
    timeBoundPeriod: type === 'GOAL' ? timeBoundPeriod : null,
    selectedCohort,
  }).then((res) => {
    const data = res.entity;
    const { team } = data;
    data.teamOrder.forEach((employeeId) => {
      team[employeeId] = getEmployeeObjectFromRes(team[employeeId]);
      // const employeeTasks =  {};
      // employeeTasks['ACTIVE'] = employeeTasks['ACTIVE']
      //   ? employeeTasks['ACTIVE'].map(taskJSON => Task1.fromJSON(taskJSON))
      //   : [];
      // employeeTasks['COMPLETED'] = employeeTasks['COMPLETED']
      //   ? employeeTasks['COMPLETED'].map(taskJSON => Task1.fromJSON(taskJSON))
      //   : [];
    });
    return data;
  });
}

export function getTeamTasksForListView({
  durationKey,
  limit,
  offset = 0,
  paginationKey = '',
  orderByParam,
  order,
  filters,
  filters1, // using this from tasks section in side panel view for goals, needed to pass multiple employeeIds in name filter, so overriding
  tagId,
  tagType,
  type,
  resourceType = TEAM_TASKS_VIEW_TYPES.list,
  startTS,
  endTS,
  cohort,
  dateRange,
  pageType = null,
  timeBoundPeriod = null,
  objectiveTimeboundFilter,
  isPrivateGoalRequired = false,
  selectedCohort = null,
}) {
  const duration = getDuration({ startTS, endTS, durationKey });
  const orderBy = orderByParam
    ? [
        {
          field: orderByParam,
          orderType: order.toUpperCase(),
        },
      ]
    : null;
  return getTeamTasksAndStatsApi({
    // durationKey,
    duration,
    resourceType,
    limit,
    offset,
    paginationKey,
    orderBy,
    filters: filters1 || constructFiltersObject(filters, type),
    tagId,
    tagType,
    cohort,
    dateRange,
    pageType,
    timeBoundPeriod,
    objectiveTimeboundFilter,
    isPrivateGoalRequired,
    selectedCohort,
  }).then((res) => {
    const data = res.entity;
    return data ? data.map((taskJSON) => Task1.fromJSON(taskJSON)) : [];
  });
}

export function getCheckInTasks({ checkInTaskId, limit, offset }) {
  return getCheckInTasksApi({ checkInTaskId, limit, offset }).then((res) => {
    const data = res.entity;
    return data ? data.map((taskJSON) => Task1.fromJSON(taskJSON)) : [];
  });
}

export function getTaskDetails({ taskId }) {
  return getTaskDetailsApi({
    taskId,
  }).then((res) => Task1.fromJSON(res.entity[0]));
}

export function getDuration({ startTS, endTS, durationKey }) {
  let duration;
  if (startTS || endTS) duration = { startTS, endTS };
  else duration = getStartEndTimeFromDurationKey(durationKey);
  return duration;
}

export const getTeamTasksStats = ({
  durationKey,
  startTS,
  endTS,
  filters,
  tagId,
  tagType,
  type,
  cohort,
  selectedCohort = null,
  dateRange,
  // only in case of goals
  timeBounds = null,
}) => {
  const duration = getDuration({ startTS, endTS, durationKey });
  return getTeamTasksStatsApi({
    startTS,
    endTS,
    duration,
    filters: constructFiltersObject(filters, type),
    tagId,
    tagType,
    cohort,
    dateRange,
    timeBounds: type === 'GOAL' ? timeBounds : null,
    selectedCohort,
  }).then((res) => res);
};

export const constructFiltersObject = (filters, type) => {
  let res = [];
  if (filters && filters.length) {
    // merging GOALS and TAGS filterType
    const filters1 = [];
    filters.forEach((f) => {
      const obj = { ...f };
      if (obj.type === 'GOALS') {
        obj.type = 'TAGS';
      }
      filters1.push(obj);
    });
    filters1.forEach((f) => {
      const obj = {
        type: f.type,
        operatorType: f.operator,
        data: [],
      };
      if (
        res.filter((r) => r.type === f.type && r.type !== 'ACTIVE_CONTENT' && r.type !== 'LAST_ACTIVITY_DATE').length >
        0
      ) {
        res = res.map((r) => {
          if (r.type === f.type) {
            r.data.push(f.value);
          }
          return r;
        });
      } else {
        obj.data = [f.value];
        res.push(obj);
      }
    });
  }
  if (type) {
    return [
      {
        type: 'TYPE',
        operatorType: 'IN',
        data: type === TASK_TYPES_2.goal ? ['GOAL'] : type === TASK_TYPES_2.checkIn ? ['CHECK_IN'] : ['TASK'],
      },
      ...res,
    ];
  }
  return res;
};

export function deleteTask({ id }) {
  return deleteTaskApi({ id });
}

export const saveDiscussion = ({ taskId, text, macros }) =>
  saveDiscussionApi({
    taskId,
    text,
    macros,
  }).then((res) => {
    const comment = new PostFeedItemData();
    comment.fromJSON(res.entity.comment);
    return comment;
  });

export const getTaskComments = async ({ taskId, offset = 0, limit = 50 }) => {
  const res = await getTaskCommentsApi({
    taskId,
    offset,
    limit,
  });
  return res.entity.map((commentJSON) => {
    const comment = new PostFeedItemData();
    comment.fromJSON(commentJSON);
    return comment;
  });
};

export const parseProgressGraphEmployeeData = (data) => {
  const achievedList = data[GOAL_PROGRESS_ENTITIES.ACHIEVEDLIST].map((goalProgress) => {
    const employee = new Employee();
    employee.fromJSON(goalProgress.lastEditedBy);

    return Object.assign(goalProgress, {
      lastEditedBy: employee,
    });
  });
  const targetList = data[GOAL_PROGRESS_ENTITIES.TARGETLIST].map((goalProgress) => {
    const employee = new Employee();
    employee.fromJSON(goalProgress.lastEditedBy);

    return Object.assign(goalProgress, {
      lastEditedBy: employee,
    });
  });
  return {
    achievedList,
    targetList,
  };
};

export const getGoalGraphProgress = (goalId) =>
  getProgressGraphDataApi(goalId).then((res) => parseProgressGraphEmployeeData(res.entity));

export const refreshMetric = ({ goalId }) => refreshMetricApi({ goalId }).then((res) => res);

export const updateCheckInDateTime = async ({ checkInInstanceId, dueDate, startDate }) => {
  const res = await updateCheckInDateTimeApi({ checkInInstanceId, dueDate, startDate });
  return Task1.fromJSON(res.entity);
};

export const getTalkingPointComments = async ({ checkInId, talkingPointQuestionId, offset, limit }) => {
  const res = await getTalkingPointCommentsApi({
    checkInId,
    talkingPointQuestionId,
    offset,
    limit,
  });
  return res.entity.map((commentJSON) => {
    const comment = new PostFeedItemData();
    comment.fromJSON(commentJSON);
    return comment;
  });
};

// this is deprecated now. TODO: remove by end of june.
export const recordCheckIn = async ({ checkInId }) => {
  const res = await recordCheckInApi({ checkInId });
  return res.entity ? Task1.fromJSON(res.entity) : null;
};

export const getGoalsTimelineList = async ({ employeeId }) => {
  const res = await getGoalsTimelineListApi({ employeeId });
  return res.entity;
};

export const getGoalWeightages = async ({ employeeId, startTS, endTS, offset, limit, body }) => {
  const res = await getGoalWeightagesApi({
    employeeId,
    startTS,
    endTS,
    offset,
    limit,
    body,
  });
  return res.entity;
};

export function updateGoalsWeightage({ employeeId, body }) {
  return updateGoalsWeightageApi({
    employeeId,
    body,
  }).then((res) => res.entity);
}

export const saveOneOnOneRating = async ({ checkInId, rating }) => {
  const res = await saveOneOnOneRatingApi({ checkInId, rating });
  return res.entity;
};

export const acknowledgeOneOnOne = async ({ checkInId, status }) => {
  const res = await acknowledgeOneOnOneApi({ checkInId, status });
  return res.entity;
};

export const getObjectiveHeirArchy = async ({ objectiveTimeBoundId }) =>
  getObjectiveHeirArchyApi({ objectiveTimeBoundId }).then((res) => res.entity);

export const getInitiativeStats = ({ dateRange, cohort, filters }) =>
  getInitiativeStatsApi({
    dateRange,
    cohort,
    filters,
  }).then((res) => res);

export function getInitiativesTableData({
  resourceType = TEAM_TASKS_VIEW_TYPES.list,
  cohort,
  dateRange = null,
  attributeFilter = null,
}) {
  return getInitiativesTableDataApi({
    resourceType,
    cohort,
    dateRange,
    attributeFilter,
  }).then(
    (res) => res.entity
    // return data ? data.map((taskJSON) => Task1.fromJSON(taskJSON)) : [];
  );
}
export function getInitiativesTableDataForProfileAndSummary({
  resourceType,
  attributeFilter,
  pageType,
  limit,
  offset,
}) {
  return getInitiativesTableDataApiForProfileAndSummary({
    resourceType,
    attributeFilter,
    pageType,
    limit,
    offset,
  }).then(
    (res) => res.entity
    // return data ? data.map((taskJSON) => Task1.fromJSON(taskJSON)) : [];
  );
}

export const getMyInitiativesTableData = async ({ type, dateRange }) => {
  try {
    const res = await getMyInitiativesTableDataApi({
      dateRange,
      type,
    });
    return res.entity;
  } catch (err) {
    console.error('Error fetching my initiatives data: ', err);
  }
};

export function getReviewPeriodSummaryInitiatives({
  limit,
  offset,
  resourceType = TEAM_TASKS_VIEW_TYPES.list,
  filters,

  isPrivateGoalRequired = false,
}) {
  return getReviewPeriodSummaryInitiativesApi({
    limit,
    offset,
    resourceType,
    filters,
    isPrivateGoalRequired,
  }).then((res) => {
    const data = res.entity;
    return data ? data.map((taskJSON) => Task1.fromJSON(taskJSON)) : [];
  });
}

export function getInitiativesCardsData({
  limit,
  offset,
  resourceType = TEAM_TASKS_VIEW_TYPES.card,
  filters,
  cohort,
  dateRange,
  isPrivateGoalRequired = false,
}) {
  return getInitiativesCardsDataApi({
    limit,
    offset,
    resourceType,
    cohort,
    filters,
    dateRange,
    isPrivateGoalRequired,
  }).then((res) => {
    const data = res.entity;
    const { team } = data;
    data.teamOrder.forEach((employeeId) => {
      team[employeeId] = getEmployeeObjectFromRes(team[employeeId]);
    });
    return data;
  });
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// type {targetPage, filters, gridStatePersistenceKey} when data is there
const initialState: any = null;

export const metricCardNavigateMetaDataSlice = createSlice({
  name: 'metricCardNavigateMetaData',
  initialState,
  reducers: {
    setMetricCardNavigateMetaData: (state, action: PayloadAction<any>) => action.payload,
    resetMetricCardNavigateMetaData: () => null,
  },
});

export const { setMetricCardNavigateMetaData, resetMetricCardNavigateMetaData } =
  metricCardNavigateMetaDataSlice.actions;

export default metricCardNavigateMetaDataSlice.reducer;

export const useSelectMetricCardNavigateMetaData = () =>
  useSelector((state: { metricCardNavigateMetaData: any }) => state.metricCardNavigateMetaData);

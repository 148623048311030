import { Icon } from '@chakra-ui/react';

export const ReviewsOverview = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M6 17V20H20V6H17V7H19V19H7V17H6Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18 4H4V18H18V4ZM17 5H5V17H17V5Z" fill="currentColor" />
    <path d="M7 13H15V14H7V13Z" fill="currentColor" />
    <path d="M7 15H15V16H7V15Z" fill="currentColor" />
    <path
      d="M13.9341 8.50634C14.0589 8.37475 13.9965 8.17737 13.83 8.13345L12.0821 7.87027C12.0404 7.87027 12.0197 7.84839 11.9989 7.80447L11.1872 6.11522C11.1041 5.96159 10.8959 5.96159 10.8335 6.11522L10.0427 7.7823C10.022 7.82622 10.0011 7.8481 9.95955 7.8481L8.16997 8.11128C8.00348 8.13316 7.94107 8.35258 8.0659 8.48417L9.33525 9.77835C9.35601 9.80023 9.37691 9.84414 9.37691 9.88806L9.06483 11.7527C9.04408 11.9282 9.21057 12.0598 9.35615 11.9721L10.9168 11.0947C10.9585 11.0729 10.9792 11.0729 11.0209 11.0947L12.6232 11.9721C12.769 12.0598 12.9353 11.9282 12.9145 11.7527L12.6232 9.91011C12.6232 9.8662 12.6232 9.84432 12.6649 9.8004L13.9341 8.50634Z"
      stroke="currentColor"
      fill="transparent"
      strokeWidth="0.75"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CalibrationNotes = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8 8.5C8 8.77614 7.77614 9 7.5 9C7.22386 9 7 8.77614 7 8.5C7 8.22386 7.22386 8 7.5 8C7.77614 8 8 8.22386 8 8.5Z"
      fill="currentColor"
    />
    <path
      d="M9 8.5C9 8.22386 9.22386 8 9.5 8H16.5C16.7761 8 17 8.22386 17 8.5C17 8.77614 16.7761 9 16.5 9H9.5C9.22386 9 9 8.77614 9 8.5Z"
      fill="currentColor"
    />
    <path
      d="M8 10.5C8 10.7761 7.77614 11 7.5 11C7.22386 11 7 10.7761 7 10.5C7 10.2239 7.22386 10 7.5 10C7.77614 10 8 10.2239 8 10.5Z"
      fill="currentColor"
    />
    <path
      d="M9 10.5C9 10.2239 9.22386 10 9.5 10H16.5C16.7761 10 17 10.2239 17 10.5C17 10.7761 16.7761 11 16.5 11H9.5C9.22386 11 9 10.7761 9 10.5Z"
      fill="currentColor"
    />
    <path
      d="M6 5H18C18.5523 5 19 5.44772 19 6V11H20V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H11V19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5Z"
      fill="currentColor"
    />
    <path
      d="M13 14.75H14.75V13H13V14.75ZM15.625 20H17.375V18.25H15.625V20ZM13 20H14.75V18.25H13V20ZM13 17.375H14.75V15.625H13V17.375ZM15.625 17.375H17.375V15.625H15.625V17.375ZM18.25 13V14.75H20V13H18.25ZM15.625 14.75H17.375V13H15.625V14.75ZM18.25 17.375H20V15.625H18.25V17.375ZM18.25 20H20V18.25H18.25V20Z"
      fill="currentColor"
    />
  </Icon>
);

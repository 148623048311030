import { Icon } from '@chakra-ui/react';

export const MyCompanyGoals = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_376_222)" fill="none">
      <path d="M14 16H5V19H14V16Z" stroke="currentColor" strokeWidth="2" />
      <path
        d="M5.3 13H13.7C13.9 13 14 13.1 14 13.3V19H5V13.3C5 13.1 5.1 13 5.3 13Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M11 19V12.3C11 12.2 10.9 12 10.7 12H8.3C8.1 12 8 12.2 8 12.3V19" stroke="currentColor" strokeWidth="2" />
      <path
        d="M8.30039 10H9.80039C10.4004 9.4 11.1004 9 12.0004 9C12.1004 9 12.1004 9 12.2004 9L13.0004 8.2V7.1C12.7004 7 12.3004 7 12.0004 7C9.90039 7 8.10039 8.4 7.40039 10.2C7.70039 10.1 8.00039 10 8.30039 10Z"
        fill="currentColor"
      />
      <path
        d="M15.8004 11L15.2004 11.6C15.7004 12 16.0004 12.6 16.0004 13.3V14V15C16.6004 14.2 17.0004 13.2 17.0004 12C17.0004 11.7 17.0004 11.3 16.9004 11H15.8004Z"
        fill="currentColor"
      />
      <path
        d="M16.0003 8V4L14.0003 6V8.6L12.1003 10.5C12.3003 10.6 12.5003 10.8 12.6003 11H13.7003C13.9003 11 14.1003 11 14.3003 11.1L15.4003 10H18.0003L20.0003 8H16.0003Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_376_222">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

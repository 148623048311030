import { Icon } from '@chakra-ui/react';

export const InitiativesPanel = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8 8.5C8 8.77614 7.77614 9 7.5 9C7.22386 9 7 8.77614 7 8.5C7 8.22386 7.22386 8 7.5 8C7.77614 8 8 8.22386 8 8.5Z"
      fill="currentColor"
    />
    <path
      d="M9 8.5C9 8.22386 9.22386 8 9.5 8H16.5C16.7761 8 17 8.22386 17 8.5C17 8.77614 16.7761 9 16.5 9H9.5C9.22386 9 9 8.77614 9 8.5Z"
      fill="currentColor"
    />
    <path
      d="M8 10.5C8 10.7761 7.77614 11 7.5 11C7.22386 11 7 10.7761 7 10.5C7 10.2239 7.22386 10 7.5 10C7.77614 10 8 10.2239 8 10.5Z"
      fill="currentColor"
    />
    <path
      d="M9 10.5C9 10.2239 9.22386 10 9.5 10H16.5C16.7761 10 17 10.2239 17 10.5C17 10.7761 16.7761 11 16.5 11H9.5C9.22386 11 9 10.7761 9 10.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 16L16.3284 17.9109L15 16.5L15.7435 15.8309L16.3284 16.5L17.8121 15.2739L18.5 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 14H14V19H19V14ZM14 13C13.4477 13 13 13.4477 13 14V19C13 19.5523 13.4477 20 14 20H19C19.5523 20 20 19.5523 20 19V14C20 13.4477 19.5523 13 19 13H14Z"
      fill="currentColor"
    />
    <path
      d="M6 5H18C18.5523 5 19 5.44772 19 6V11H20V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H11V19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5Z"
      fill="currentColor"
    />
  </Icon>
);

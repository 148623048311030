import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { ALL_ROLES_TYPE } from '../AccessControl/constants/roles';
import { AccessControlTableType } from '../types';
import { AccessControlState, initialStateGoalAccessMatrix } from './initialStateGoalAccessMatrix';

export const accessControlSlice = createSlice({
  name: 'accessControl',
  initialState: initialStateGoalAccessMatrix,
  reducers: {
    resetData: (state) => initialStateGoalAccessMatrix,
    openAccessControlModal: (state) => {
      state.isOpen = true;
    },
    closeAccessControlModal: (state) =>
      // state.isOpen = false;
      ({
        ...initialStateGoalAccessMatrix,
        isOpen: false,
      }),
    setTemplateData: (state, action: PayloadAction<AccessControlState>) =>
      // initializing the state with the template data
      // REFER: https://redux-toolkit.js.org/usage/immer-reducers#resetting-and-replacing-state
      // IMPORTANT!!!
      action.payload,
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
    updateConfigData: (state, action: PayloadAction<{ key: string; value: unknown }>) => {
      state.configData = {
        ...state.configData,
        [action.payload.key]: {
          configuration: action.payload.key,
          value: action.payload.value,
        },
      };
    },
    updateAccessControl: (
      state,
      action: PayloadAction<{
        tableType: AccessControlTableType;
        role: ALL_ROLES_TYPE;
        privilegeKey: string;
        isMultiSelect?: boolean;
        value: string | string[];
      }>
    ) => {
      if (action.payload.isMultiSelect) {
        state.accessControl[action.payload.tableType][action.payload.role][action.payload.privilegeKey].values =
          action.payload.value;
      } else {
        state.accessControl[action.payload.tableType][action.payload.role][action.payload.privilegeKey].value =
          action.payload.value;
      }
    },
    validateAccessControl: (
      state,
      action: PayloadAction<{
        rolesToCreateConfig: AccessControlState['accessControl']['rolesToCreateConfig'];
        rolesToViewUpdateStatusEditDeleteConfig: AccessControlState['accessControl']['rolesToViewUpdateStatusEditDeleteConfig'];
        rolesToUploadInsightsCustomTagConfig: AccessControlState['accessControl']['rolesToUploadInsightsCustomTagConfig'];
      }>
    ) => {
      // check if object is not empty then update
      if (Object.keys(action.payload.rolesToCreateConfig).length > 0) {
        state.accessControl.rolesToCreateConfig = action.payload.rolesToCreateConfig;
      }
      if (Object.keys(action.payload.rolesToViewUpdateStatusEditDeleteConfig).length > 0) {
        state.accessControl.rolesToViewUpdateStatusEditDeleteConfig =
          action.payload.rolesToViewUpdateStatusEditDeleteConfig;
      }
      if (Object.keys(action.payload.rolesToUploadInsightsCustomTagConfig).length > 0) {
        state.accessControl.rolesToUploadInsightsCustomTagConfig = action.payload.rolesToUploadInsightsCustomTagConfig;
      }
    },
    updateMetaData: (state, action: PayloadAction<{ key: string; value: unknown }>) => ({
      ...state,
      [action.payload.key]: action.payload.value,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  resetData,
  openAccessControlModal,
  closeAccessControlModal,
  setTemplateData,
  updateConfigData,
  setEditMode,
  updateAccessControl,
  validateAccessControl,
  updateMetaData,
} = accessControlSlice.actions;

export default accessControlSlice.reducer;

// use selectors to select the tables from accessControl

export const useTemplateMetaDataSelector = () =>
  useSelector((state: { accessControlSlice: AccessControlState }) => ({
    id: state?.accessControlSlice?.id,
    createdTS: state?.accessControlSlice?.createdTS,
    updatedTS: state?.accessControlSlice?.updatedTS,
    name: state?.accessControlSlice?.name,
    description: state?.accessControlSlice?.description,
    orgId: state?.accessControlSlice?.orgId,
    type: state?.accessControlSlice?.type,
    lastEditedBy: state?.accessControlSlice?.lastEditedBy,
    moduleName: state?.accessControlSlice?.moduleName,
    objectivesName: state?.accessControlSlice?.objectivesName,
    keyResultsName: state?.accessControlSlice?.keyResultsName,
    isEditMode: state?.accessControlSlice?.isEditMode,
    isOpen: state?.accessControlSlice?.isOpen,
    includeCollaboratorGoals: state?.accessControlSlice?.includeCollaboratorGoals,
    includeOwnerGoals: state?.accessControlSlice?.includeOwnerGoals,
  }));

export const useConfigDataSelector = () =>
  useSelector((state: { accessControlSlice: AccessControlState }) => ({
    configData: state?.accessControlSlice?.configData ?? {},
  }));

export const useAccessControlSelector = () =>
  useSelector((state: { accessControlSlice: AccessControlState }) => ({
    accessControl: state?.accessControlSlice?.accessControl ?? {},
  }));

export const useRolesToCreateConfigSelector = () =>
  useSelector((state: { accessControlSlice: AccessControlState }) => ({
    rolesToCreateConfig: state?.accessControlSlice?.accessControl?.rolesToCreateConfig ?? {},
  }));

export const useRolesToViewUpdateStatusEditDeleteConfigSelector = () =>
  useSelector((state: { accessControlSlice: AccessControlState }) => ({
    rolesToViewUpdateStatusEditDeleteConfig:
      state?.accessControlSlice?.accessControl?.rolesToViewUpdateStatusEditDeleteConfig ?? {},
  }));

export const useRolesToUploadInsightsCustomTagConfigSelector = () =>
  useSelector((state: { accessControlSlice: AccessControlState }) => ({
    rolesToUploadInsightsCustomTagConfig:
      state?.accessControlSlice?.accessControl?.rolesToUploadInsightsCustomTagConfig ?? {},
  }));

export const useAccessControlTemplateSelector = () =>
  useSelector((state: { accessControlSlice: AccessControlState }) => ({
    accessControlTemplate: state?.accessControlSlice,
  }));

import { HIDE_SNACKBAR, SHOW_SNACKBAR } from 'actions/actionTypes';

export const initialState = {
  display: false,
  severity: 'info',
  message: 'This is a snackbar',
  vertical: 'bottom',
  horizontal: 'center',
};

export default function snackbar(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      const { message, severity, vertical, horizontal } = action.data;
      const newState = { ...initialState, display: true, message, severity, vertical, horizontal };
      return newState;
    case HIDE_SNACKBAR:
      return { ...state, display: false };
    default:
      return state;
  }
}

import React, { useEffect, useState } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { useSelector } from 'react-redux';
import { selectEnabledFeatureFlags } from 'selectors/enabledFeatureFlags';
import { baseTheme } from 'themes/clients/baseTheme';
import { baseThemeDeer } from 'themes/clients/baseThemeDeer';
import { checkNewNavEnabled } from 'utils/helpers/featureFlags';

export const ChakraThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(baseTheme);
  const enabledFeatures = useSelector(selectEnabledFeatureFlags);

  useEffect(() => {
    setTheme(checkNewNavEnabled() ? baseThemeDeer : baseTheme);
  }, [enabledFeatures]);

  return (
    <ChakraProvider resetCSS={false} theme={theme}>
      {/* remove emotion theme provider when all usages are replaced with native theme usage from Chakra */}
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </ChakraProvider>
  );
};

import { Icon } from '@chakra-ui/react';

export const Public = (props) => (
  <Icon viewBox="0 0 12 13" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.02724 2.77185H5.97324C5.27347 2.77418 4.58663 2.96134 3.98177 3.31442C3.3769 3.66754 2.87532 4.17413 2.52743 4.78338C2.17961 5.39261 1.99769 6.08301 2.00002 6.78514C2.00356 7.84604 2.42693 8.86205 3.17704 9.60967C3.92715 10.3573 4.94249 10.7754 5.99978 10.7718C7.05705 10.7755 8.07253 10.3575 8.82267 9.60996C9.5729 8.86244 9.99639 7.84644 9.99998 6.78559C10.0036 5.72469 9.58709 4.70585 8.84208 3.95318C8.09701 3.20048 7.08452 2.77551 6.02724 2.77185ZM8.87267 5.10516H7.49486C7.38236 4.55009 7.16227 4.02258 6.84705 3.55265C7.7007 3.78016 8.42938 4.33859 8.87267 5.10516ZM5.99995 9.97267C5.60022 9.5413 5.32011 9.01234 5.18767 8.43846H6.81223C6.6798 9.01229 6.3997 9.54126 5.99995 9.97267ZM5.06479 7.7718C4.98282 7.10768 4.98282 6.43595 5.06479 5.77182H6.9351C6.97664 6.1035 6.99715 6.4375 6.99657 6.77179C6.99715 7.10609 6.97664 7.44009 6.9351 7.77177L5.06479 7.7718ZM2.67778 6.77182C2.67772 6.43265 2.72923 6.09545 2.83061 5.77185H4.39778C4.31917 6.43614 4.31917 7.10752 4.39778 7.77183H2.83061C2.72923 7.44823 2.67772 7.111 2.67778 6.77182ZM5.99987 3.57096C6.39961 4.00233 6.67972 4.5313 6.81216 5.10517H5.18759C5.32003 4.53134 5.60013 4.00237 5.99987 3.57096ZM7.60189 5.77183H9.16906C9.37281 6.42281 9.37281 7.12081 9.16906 7.77181H7.60189C7.64065 7.4399 7.66029 7.10602 7.66087 6.77184C7.66029 6.43766 7.64065 6.10374 7.60189 5.77183ZM5.15274 3.55266C4.83753 4.02257 4.61744 4.55008 4.50493 5.10517H3.12712C3.57043 4.33863 4.29913 3.7802 5.15274 3.55266ZM3.12715 8.43847H4.50496C4.61747 8.99354 4.83756 9.52105 5.15277 9.99099C4.29913 9.76347 3.57045 9.20504 3.12715 8.43847ZM6.84701 9.99099C7.16222 9.52108 7.38231 8.99357 7.49482 8.43847H8.8735C8.65387 8.81811 8.3619 9.15059 8.01419 9.417C7.66648 9.6834 7.26985 9.87846 6.84701 9.99099Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const ChevronRight = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M14.5 11.9901C14.5 12.2023 14.4123 12.4057 14.2564 12.5557L10.9299 15.7557H10.9297C10.7748 15.9101 10.5619 15.9981 10.3389 16C10.1158 16.0018 9.90128 15.9173 9.74353 15.7657C9.58579 15.614 9.49808 15.4076 9.50003 15.193C9.50198 14.9785 9.59345 14.7736 9.75384 14.6245L13.0803 11.4245H13.0805C13.2364 11.2745 13.4479 11.1903 13.6684 11.1903C13.8889 11.1903 14.1004 11.2745 14.2564 11.4245C14.4123 11.5745 14.5 11.778 14.5 11.9901H14.5Z"
      fill="currentColor"
    />
    <path
      d="M14.5001 11.9902C14.5001 12.2024 14.4124 12.4058 14.2565 12.5558C14.1005 12.7058 13.8891 12.7901 13.6685 12.7901C13.448 12.7901 13.2365 12.7058 13.0806 12.5558L9.7541 9.35584C9.55013 9.15267 9.47245 8.86095 9.55013 8.58855C9.62782 8.316 9.84905 8.1032 10.1322 8.02845C10.4156 7.95385 10.7186 8.02832 10.9299 8.22466L14.2563 11.4247C14.4123 11.5747 14.5001 11.7781 14.5001 11.9902Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const StarCurrentColorFilled = (props) => (
  <Icon viewBox="0 0 38 38" boxSize="16px" color="icon.g1" {...props}>
    <rect x="0.5" y="0.5" width="37" height="37" rx="3.5" fill="currentColor" />
    <path
      d="M19.0001 24.0596L22.8043 26.3605C23.501 26.7821 24.3535 26.1588 24.1701 25.3705L23.1618 21.0438L26.526 18.1288C27.1401 17.5971 26.8101 16.5888 26.0035 16.5246L21.576 16.1488L19.8435 12.0605C19.5318 11.318 18.4685 11.318 18.1568 12.0605L16.4243 16.1396L11.9968 16.5155C11.1901 16.5796 10.8601 17.588 11.4743 18.1196L14.8385 21.0346L13.8301 25.3613C13.6468 26.1496 14.4993 26.773 15.196 26.3513L19.0001 24.0596Z"
      fill="white"
    />
    <rect x="0.5" y="0.5" width="37" height="37" rx="3.5" stroke="currentColor" />
  </Icon>
);

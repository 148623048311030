import { Icon } from '@chakra-ui/react';

export const CollapseSidepanel = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M4.00014 12.0122C4.00014 11.7471 4.11469 11.4928 4.31835 11.3053L8.66298 7.30539C8.86537 7.11237 9.14357 7.00238 9.43488 7.00004C9.72619 6.99769 10.0064 7.10333 10.2124 7.29283C10.4184 7.48251 10.533 7.74047 10.5304 8.00867C10.5279 8.27685 10.4084 8.53297 10.199 8.71931L5.85432 12.7192C5.65066 12.9067 5.37425 13.012 5.08624 13.012C4.79823 13.012 4.522 12.9067 4.31835 12.7192C4.11469 12.5317 4.00014 12.2774 4.00014 12.0122Z"
      fill="currentColor"
    />
    <path
      d="M4.00014 12.0122C4.00014 11.7471 4.11469 11.4928 4.31835 11.3053C4.52201 11.1178 4.79813 11.0127 5.08613 11.0127C5.37414 11.0127 5.65037 11.118 5.85403 11.3055L10.1987 15.3054C10.4651 15.5593 10.5665 15.924 10.4651 16.2645C10.3636 16.6051 10.0747 16.8712 9.7048 16.9646C9.33476 17.0578 8.9389 16.9647 8.66305 16.7193L4.31835 12.7192C4.11469 12.5317 4.00014 12.2774 4.00014 12.0122Z"
      fill="currentColor"
    />
    <path
      d="M10.8572 12.0002C10.8572 11.4824 11.2769 11.0627 11.7947 11.0627H19.0625C19.5803 11.0627 20.0001 11.4824 20.0001 12.0002C20.0001 12.518 19.5803 12.9377 19.0625 12.9377H11.7947C11.2769 12.9377 10.8572 12.518 10.8572 12.0002Z"
      fill="currentColor"
    />
    <path
      d="M11.8368 7.93753C11.8368 7.41975 12.2565 7 12.7743 7H18.736C19.2538 7 19.6735 7.41975 19.6735 7.93753C19.6735 8.45531 19.2538 8.87506 18.736 8.87506H12.7743C12.2565 8.87506 11.8368 8.45531 11.8368 7.93753Z"
      fill="currentColor"
    />
    <path
      d="M11.8368 15.9378C11.8368 15.4201 12.2565 15.0003 12.7743 15.0003H18.736C19.2538 15.0003 19.6736 15.4201 19.6736 15.9378C19.6736 16.4556 19.2538 16.8754 18.736 16.8754H12.7743C12.2565 16.8754 11.8368 16.4556 11.8368 15.9378Z"
      fill="currentColor"
    />
  </Icon>
);

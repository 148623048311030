import { createSlice } from '@reduxjs/toolkit';
import { endOfDay, startOfDay } from 'date-fns';

export const SECONDARY_PANEL_TYPES = {
  REALIGN_OKR: 'REALIGN_OKR' /* This type is when user clicks on realign button */,
  REALIGN_CHILDREN: 'REALIGN_CHILDREN' /* This type is when user clicks on realign children (from inside modal) */,
  REALIGN_OKR_WITHOUT_CHILDREN_REALIGN_CONFIRMATION_MODAL:
    'REALIGN_OKR_WITHOUT_CHILDREN_REALIGN_CONFIRMATION_MODAL' /* This type is when user has already realigned children or has denied children realignment */,
  REALIGN_CHILDREN_AND_DELETE: 'REALIGN_CHILDREN_AND_DELETE',
  SELECT_PARENT_OKR: 'SELECT_PARENT_OKR',
  OPEN_CHECKIN_PANEL: 'OPEN_CHECKIN_PANEL',
  OPEN_CREATE_OKR_PANEL: 'OPEN_CREATE_OKR_PANEL',
  OPEN_CREATE_OKR_SIMPLE_PANEL: 'OPEN_CREATE_OKR_SIMPLE_PANEL',
  CREATE_INITIATIVE: 'CREATE_INITIATIVE',
  ADD_EDIT_CUSTOM_TAGS: 'ADD_EDIT_CUSTOM_TAGS',
  CREATE_CUSTOM_METRIC: 'CREATE_CUSTOM_METRIC',
  TITLE_AND_METRICS_SUGGESTION_PANEL: 'TITLE_AND_METRICS_SUGGESTION_PANEL',
  OTHER_DETAILS_PANEL: 'OTHER_DETAILS_PANEL',
  CHANGE_DETAILS_PANEL: 'CHANGE_DETAILS_PANEL',
  FULL_HISTORY_PANEL: 'FULL_HISTORY_PANEL',
};

const initialState = {
  type: null,
  details: {},
  initiativeSubtaskDetails: {
    text: '',
    to: null,
    macros: {
      employees: {},
      demographics: {},
      attributes: {
        orgTag: {},
        money: null,
      },
    },
    from: null,
    dueDate: null,
    dueTS: endOfDay(new Date()).valueOf(),
    teams: [],
    toIds: [],
    taskType: 'TASK',
    parentPost: null,
    type: 'TASK',
    actual: 0,
    visibilityType: 'PRIVATE',
    startTS: startOfDay(new Date()).valueOf(),
    description: '',
    tags: null,
    startingProgressValue: 0,
    weightage: 0,
    removedTeam: null,
  },
};

export const krDrawerSecondaryPanelSlice = createSlice({
  name: 'kRSecondaryPanelSlice',
  initialState,
  reducers: {
    openRealignPanel: (state, action) => ({
      type: SECONDARY_PANEL_TYPES.REALIGN_OKR,
      details: { ...action.payload },
    }),
    openChildrenRealignPanel: (state, action) => {
      const isOpenAsChild = action.payload?.isOpenAsChild;
      const parentState = isOpenAsChild ? { ...action.payload?.leftPanelDetails } : null;
      return {
        type: SECONDARY_PANEL_TYPES.REALIGN_CHILDREN,
        details: { ...action.payload, parentState },
      };
    },
    openRealignPanelWithoutChildrenRealignModal: (state, action) => {
      const isOpenAsChild = action.payload?.isOpenAsChild;
      const parentState = isOpenAsChild ? { ...action.payload?.leftPanelDetails } : null;
      return {
        type: SECONDARY_PANEL_TYPES.REALIGN_OKR_WITHOUT_CHILDREN_REALIGN_CONFIRMATION_MODAL,
        details: { ...action.payload, parentState },
      };
    },
    openDeletionChildrenRealignPanel: (state, action) => ({
      type: SECONDARY_PANEL_TYPES.REALIGN_CHILDREN_AND_DELETE,
      details: { ...action.payload },
    }),
    openSelectParentOKRPanel: (state, action) => {
      const isOpenAsChild = action.payload?.isOpenAsChild;
      const parentState = isOpenAsChild ? { ...action.payload?.leftPanelDetails } : null;
      return {
        type: SECONDARY_PANEL_TYPES.SELECT_PARENT_OKR,
        details: { ...action.payload, parentState },
      };
    },
    openOKRCheckInPanel: (state, action) => ({
      type: SECONDARY_PANEL_TYPES.OPEN_CHECKIN_PANEL,
      details: { ...action.payload },
    }),
    openOKRAddEditCustomTagsPanel: (state, action) => {
      const currentSecondaryPanelType = state.type;
      const currentSecondaryPanelTypeDetails = state.details;
      const isOpenAsChild = action.payload?.isOpenAsChild;
      const parentState = isOpenAsChild
        ? { type: currentSecondaryPanelType, okrDetails: currentSecondaryPanelTypeDetails }
        : null;
      return {
        type: SECONDARY_PANEL_TYPES.ADD_EDIT_CUSTOM_TAGS,
        details: { ...currentSecondaryPanelTypeDetails, ...action.payload, parentState },
      };
    },
    openTitleAndMetricPanel: (state, action) => {
      const isOpenAsChild = action.payload?.isOpenAsChild;
      const isAlignmentDisabled = action.payload?.isAlignmentDisabled;
      const parentState = isOpenAsChild ? { ...action.payload?.leftPanelDetails } : null;

      // const parentState = isOpenAsChild ? { ...state, okrDetails: action.payload?.okrDetails } : null;
      return {
        type: SECONDARY_PANEL_TYPES.TITLE_AND_METRICS_SUGGESTION_PANEL,
        details: { ...action.payload, parentState, isAlignmentDisabled },
      };
    },

    openCreateOKRPanel: (state, action) => ({
      type: SECONDARY_PANEL_TYPES.OPEN_CREATE_OKR_PANEL,
      details: { ...action.payload },
    }),

    openCreateOKRSimplePanel: (state, action) => ({
      type: SECONDARY_PANEL_TYPES.OPEN_CREATE_OKR_SIMPLE_PANEL,
      details: { ...action.payload },
    }),
    openChangeDetailsPanel: (state, action) => ({
      type: SECONDARY_PANEL_TYPES.CHANGE_DETAILS_PANEL,
      details: { ...action.payload },
    }),
    openCreateInitiativePanel: (state, action) => ({
      ...state,
      type: SECONDARY_PANEL_TYPES.CREATE_INITIATIVE,
      initiativeSubtaskDetails: { ...state.initiativeSubtaskDetails, ...action.payload },
    }),
    openCreateCustomMetricPanel: (state, action) => ({
      ...state,
      type: SECONDARY_PANEL_TYPES.CREATE_CUSTOM_METRIC,
      details: { ...action.payload },
    }),

    openOtherDetailsPanel: (state, action) => {
      const isOpenAsChild = action.payload?.isOpenAsChild;
      const parentState = isOpenAsChild
        ? { ...state, type: SECONDARY_PANEL_TYPES.OPEN_CREATE_OKR_PANEL, okrDetails: action.payload?.okrDetails }
        : null;
      return {
        type: SECONDARY_PANEL_TYPES.OTHER_DETAILS_PANEL,
        details: { ...action.payload, parentState },
      };
    },
    openFullHistoryPanel: (state, action) => {
      const isOpenAsChild = action.payload?.isOpenAsChild;
      const parentState = isOpenAsChild
        ? { ...state, type: SECONDARY_PANEL_TYPES.OPEN_CREATE_OKR_PANEL, okrDetails: action.payload?.okrDetails }
        : null;
      return {
        type: SECONDARY_PANEL_TYPES.FULL_HISTORY_PANEL,
        details: { ...action.payload, parentState },
      };
    },
    closeOtherDetailsPanel: () => initialState,

    closeRealignPanel: () => initialState,

    onDetailsChange: (state, action) => {
      state.initiativeSubtaskDetails = {
        ...state.initiativeSubtaskDetails,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openRealignPanel,
  openChildrenRealignPanel,
  openRealignPanelWithoutChildrenRealignModal,
  openDeletionChildrenRealignPanel,
  openCreateInitiativePanel,

  openSelectParentOKRPanel,
  closeRealignPanel,
  openOKRCheckInPanel,
  openCreateOKRPanel,
  openCreateOKRSimplePanel,
  openChangeDetailsPanel,
  onDetailsChange,
  openOKRAddEditCustomTagsPanel,
  openMetricsSuggestionPanel,
  openTitleAndMetricPanel,
  openCreateCustomMetricPanel,
  openOtherDetailsPanel,
  openFullHistoryPanel,
  closeOtherDetailsPanel,
} = krDrawerSecondaryPanelSlice.actions;

const closeSecondaryPanel = closeRealignPanel;
const closeCheckInPanel = closeRealignPanel;
const closeAddEditCustomTagsPanel = closeRealignPanel;
const closeTitleSuggestionPanel = closeRealignPanel;
const closeMetricsSuggestionPanel = closeRealignPanel;
const closeTitleAndMetricsSuggestionPanel = closeRealignPanel;
const closeCreateOKRSimplePanel = closeRealignPanel;
const closeChangeDetailsPanel = closeRealignPanel;
const closeFullHistoryPanel = closeRealignPanel;

export {
  closeSecondaryPanel,
  closeCheckInPanel,
  closeAddEditCustomTagsPanel,
  closeTitleSuggestionPanel,
  closeMetricsSuggestionPanel,
  closeTitleAndMetricsSuggestionPanel,
  closeCreateOKRSimplePanel,
  closeChangeDetailsPanel,
  closeFullHistoryPanel,
};
export default krDrawerSecondaryPanelSlice.reducer;

import { Icon } from '@chakra-ui/react';

export const Initiatives = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M5 6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44771 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44771 19 5 18.5523 5 18V6Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path d="M15.5 9.99998L10.75 14.5002L8.5 12" stroke="currentColor" strokeWidth="2" />
  </Icon>
);

import { createSlice } from '@reduxjs/toolkit';
import { Coin } from 'interfaces/MeshMaven';
import { useSelector } from 'react-redux';

import { Revision } from '../types/Revision';

interface InitialState {
  revisionNumber: number;
  revisions: Revision[];
  feedbackAnalysis: Coin;
  groupId: string;
}

const INITIAL_STATE: InitialState = {
  groupId: '',
  revisionNumber: 0,
  revisions: [],
  feedbackAnalysis: { context: 0, observations: 0, impact: 0, next_steps: 0 },
};

const meshMavenSlice = createSlice({
  name: 'reviewsMeshMaven',
  initialState: INITIAL_STATE,
  reducers: {
    setGroupId(state, action: { payload: { groupId: string } }) {
      state.groupId = action.payload.groupId;
    },
    setRevisionNumber(state, action: { payload: { revisionNumber: number } }) {
      state.revisionNumber = action.payload.revisionNumber;
    },
    setRevisions(state, action: { payload: { revisions: Revision[] } }) {
      state.revisions = action.payload.revisions;
    },
    setSelectedRevision(state, action: { payload: { revision: Revision } }) {
      state.revisions[state.revisionNumber] = action.payload.revision;
    },
    setAddRevision(state, action: { payload: { revision: Revision } }) {
      state.revisions.push(action.payload.revision);
    },
    setFeedbackAnalysis(state, action: { payload: { feedbackAnalysis: Coin } }) {
      state.feedbackAnalysis = action.payload.feedbackAnalysis;
    },
    resetState(state) {
      state.groupId = '';
      state.revisionNumber = 0;
      state.revisions = [];
      state.feedbackAnalysis = {
        context: 0 as 0 | 1,
        observations: 0 as 0 | 1,
        impact: 0 as 0 | 1,
        next_steps: 0 as 0 | 1,
      };
    },
  },
});

export default meshMavenSlice.reducer;

export const {
  resetState,
  setGroupId,
  setRevisionNumber,
  setSelectedRevision,
  setRevisions,
  setAddRevision,
  setFeedbackAnalysis,
} = meshMavenSlice.actions;

export const useGroupId = () => useSelector((state: any) => state.reviewsMeshMaven.groupId) as string;

export const useSelectedRevisionNumber = () =>
  useSelector((state: any) => state.reviewsMeshMaven.revisionNumber) as number;

export const useRevisions = () => useSelector((state: any) => state.reviewsMeshMaven.revisions) as Revision[];

export const useSelectedRevision = () => {
  const revisionNumber = useSelectedRevisionNumber();
  const revisions = useRevisions();
  return revisions[revisionNumber];
};

export const useFeedbackAnalysis = () => useSelector((state: any) => state.reviewsMeshMaven.feedbackAnalysis) as Coin;

import { Icon } from '@chakra-ui/react';

export const EditCalendar = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M3.93375 9.5H17.9338V11.5H19.9338V5.5C19.9338 4.4 19.0338 3.5 17.9338 3.5H16.9338V1.5H14.9338V3.5H6.93375V1.5H4.93375V3.5H3.93375C2.82375 3.5 1.94375 4.4 1.94375 5.5L1.93375 19.5C1.93375 20.6 2.82375 21.5 3.93375 21.5H10.9338V19.5H3.93375V9.5ZM3.93375 5.5H17.9338V7.5H3.93375V5.5ZM21.7738 15.78L21.0637 16.49L18.9438 14.37L19.6537 13.66C20.0437 13.27 20.6737 13.27 21.0637 13.66L21.7738 14.37C22.1637 14.76 22.1637 15.39 21.7738 15.78ZM18.2337 15.08L20.3538 17.2L15.0538 22.5H12.9338V20.38L18.2337 15.08Z"
      fill="currentColor"
    />
  </Icon>
);

import { getMySummaryInitiativesApi, getMySummaryInitiativesTableDataApi } from '../api';

export const getMySummaryInitiatives = async ({ employeeId, limit = 3, offset = 0 }) => {
  try {
    const res = await getMySummaryInitiativesApi({ employeeId, limit, offset });
    return res?.entity;
  } catch (error) {
    console.error('Error fetching my summary for initiatives : ', error);
  }
};

export const getMySummaryInitiativesTableData = async ({ resourceType, attributeFilter, pageType }) => {
  try {
    const res = await getMySummaryInitiativesTableDataApi({
      resourceType,
      attributeFilter,
      pageType,
    });
    return res?.entity;
  } catch (err) {
    console.error('Error fetching data for initiatives ag grid: ', err);
  }
};

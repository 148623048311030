import { Icon } from '@chakra-ui/react';

export const MyCompanyFeedback = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_413_368)">
      <path
        d="M13 10.5V10H11.5C11.22 10 11 9.78 11 9.5V5.5C11 5.22 11.22 5 11.5 5H18.5C18.78 5 19 5.22 19 5.5V9.5C19 9.78 18.78 10 18.5 10H15.91C15.51 10 15.13 10.16 14.85 10.44L13.85 11.44C13.54 11.75 13 11.53 13 11.09V10.5V10.5Z"
        stroke="#666666"
        strokeWidth="2"
      />
      <path
        d="M15 8.79001L15.31 8.10001L16 7.79001L15.31 7.48001L15 6.79001L14.69 7.48001L14 7.79001L14.69 8.10001L15 8.79001Z"
        fill="#666666"
      />
      <path
        d="M13.5 13.59C13.18 13.59 12.85 13.53 12.54 13.4C11.89 13.13 11.4 12.61 11.16 11.98C10.49 11.89 9.9 11.52 9.51 11H8.3C7.65 11 7.18 11.48 7.04 12H5.3C4.55 12 4 12.55 4 13.3V20H15V13.3C15 13.23 14.97 13.17 14.96 13.1C14.53 13.41 14.02 13.58 13.5 13.58V13.59ZM7 18H6V17H7V18ZM7 15H6V14H7V15ZM10 18H9V17H10V18ZM10 15H9V14H10V15ZM13 18H12V17H13V18ZM13 15H12V14H13V15Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_413_368">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

import Employee from 'models/employee';

import { maleIconSrc, femaleIconSrc } from 'constants/icons';

import { makeEmployeeModelSearchCompatible } from './search';

export const getEmployeeObjectFromRes = (res = {}) => {
  const emp = new Employee({});
  emp.fromJSON(res);
  return emp;
};

/* Nodes res would contain an array of employees */
export const parseNodesRes = (res = []) =>
  res.map((employee) => ({
    connectivityScore: employee.connectivityScore || 0,
    employee: getEmployeeObjectFromRes(employee.employee),
  }));

export const parseRelationshipsRes = (res = []) =>
  res.map((json) => ({
    roles: json.roles,
    employee: getEmployeeObjectFromRes(json.employee),
  }));

export const parseLeaderboardRes = (res = []) => ({
  trophy: { id: res.trophy.uuid },
  leaderboard: res.leaderboard.map((json) => {
    json.employee = getEmployeeObjectFromRes(json.employee);
    return json;
  }),
});

export const getSearchLabelFromEmployee = (employee) => {
  const { displayName, location, designation } = employee;
  const result = [];
  if (displayName) result.push(displayName);
  if (location) result.push(location);
  if (designation) result.push(designation);
  return result.join(', ');
};

export const getProfileImageOfEmployee = (employee) => {
  const { gender, profilePhotoSrc } = employee;
  if (profilePhotoSrc) {
    return profilePhotoSrc;
  }
  if (gender === 'MALE') return maleIconSrc;
  return femaleIconSrc;
};

export function makeToEmployeesSearchCompatible(toIds) {
  toIds = toIds || [];
  const result = [];
  toIds.forEach((toEmployee) => {
    result.push(makeEmployeeModelSearchCompatible(toEmployee));
  });
  return result;
}

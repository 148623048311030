import { Icon } from '@chakra-ui/react';

export const EngagementIcon = (props) => (
  <Icon viewBox="0 0 25 25" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.2347 13.5306L9.94466 11.5006C9.82466 11.3106 9.65466 11.2806 9.55466 11.2806C9.50466 11.2806 9.27466 11.3006 9.14466 11.5106L8.53466 12.5306H5.03466L4.76466 11.9606C4.43466 11.2906 4.28466 10.5906 4.29466 9.87059C4.33466 8.13059 5.39466 6.50059 7.01466 5.73059C8.74466 4.91059 10.7147 5.23059 12.2947 6.61059C14.1547 4.99059 16.5447 4.84059 18.3947 6.24059C20.1947 7.60059 20.7747 9.95059 19.8247 11.9606L19.5547 12.5306H15.5747L13.9547 9.53059C13.8347 9.30059 13.6247 9.29059 13.5247 9.28059C13.4147 9.28059 13.2247 9.32059 13.1147 9.55059L11.2247 13.5306H11.2347ZM16.7647 10.5306H18.2147C18.4747 9.41059 17.9347 8.40059 17.1847 7.83059C16.5447 7.34059 15.5847 7.07059 14.5647 7.50059C15.0447 7.72059 15.4547 8.09059 15.7147 8.58059L16.7647 10.5306ZM6.36466 10.5306H7.39466L7.42466 10.4806C7.86466 9.74059 8.64466 9.29059 9.51466 9.28059C9.98466 9.28059 10.4647 9.40059 10.8547 9.66059L11.3147 8.70059C11.3447 8.63059 11.3847 8.57059 11.4147 8.50059L10.9747 8.12059C9.65466 6.96059 8.46466 7.26059 7.87466 7.54059C6.93466 7.99059 6.31466 8.92059 6.29466 9.91059C6.29466 10.1206 6.31466 10.3206 6.36466 10.5306Z"
      fill="currentColor"
    />
    <path
      d="M18.7647 13.4706L12.6147 19.5806C12.4347 19.7606 12.1447 19.7606 11.9547 19.5806L5.8147 13.4706H8.7847C8.8647 13.4706 8.9247 13.4606 8.9947 13.4206L9.7947 13.0306L10.8947 14.7506C11.0247 14.9606 11.2247 14.9806 11.3147 14.9706C11.4047 14.9706 11.6047 14.9306 11.7147 14.7006L13.5647 10.7906L14.9147 13.3106C14.9647 13.4106 15.0647 13.4706 15.1847 13.4706H18.7647Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const PhotoComment = (props) => (
  <Icon viewBox="0 0 16 17" boxSize="16px" color="icon.g1" {...props}>
    <rect x="2.5" y="3.42981" width="11" height="11" rx="0.5" stroke="currentColor" />
    <circle cx="6" cy="6.92981" r="1" fill="currentColor" />
    <path
      d="M3.27735 8.51378L2.5 7.99555V8.92981V13.4298C2.5 13.9821 2.94772 14.4298 3.5 14.4298H12.5C13.0523 14.4298 13.5 13.9821 13.5 13.4298V9.92981V9.66222L13.2774 9.51378L10.866 7.90624C10.3369 7.55347 9.64277 7.57273 9.13399 7.95432L6.26601 10.1053C6.09642 10.2325 5.86505 10.2389 5.68866 10.1213L3.27735 8.51378Z"
      stroke="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const SaveAndExit = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M22.3305 4.30346C22.5017 4.49352 22.5864 4.73116 22.5874 4.96825C22.5952 5.21703 22.5107 5.46907 22.331 5.66865L19.6545 8.64123C19.285 9.05166 18.6527 9.0848 18.2422 8.71525C17.8318 8.3457 17.7987 7.7134 18.1682 7.30297L19.2624 6.08773H16.0118C15.4595 6.08773 15.0118 5.64002 15.0118 5.08773C15.0118 4.53545 15.4595 4.08773 16.0118 4.08773H19.445L18.1677 2.66914C17.7982 2.25871 17.8313 1.62641 18.2417 1.25686C18.6522 0.887313 19.2845 0.92045 19.654 1.33088L22.3305 4.30346Z"
      fill="currentColor"
    />
    <path d="M13 4H4L4.00021 20H20L20 11H18V18H6V6H13V4Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15ZM12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
      fill="currentColor"
    />
  </Icon>
);

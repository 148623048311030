import { createSlice } from '@reduxjs/toolkit';

const defaultClickThroughData = {
  name: null, // name of the employee
  parentUuid: null, // uuid of manager
};
const initialState = {
  selectedDateRange: null,
  isInsightsClickThroughViewActive: false,
  clickThroughViewData: defaultClickThroughData,
};

export const oneOnOnePageSlice = createSlice({
  name: 'oneOnOneSlice',
  initialState,
  reducers: {
    updateSelectedDateRange: (state, action) => {
      state.selectedDateRange = action.payload;
    },
    setClickThroughView: (state, action) => {
      state.isInsightsClickThroughViewActive = true;
      state.clickThroughViewData = action.payload;
    },
    clearClickThroughView: (state) => {
      state.isInsightsClickThroughViewActive = false;
      state.clickThroughViewData = defaultClickThroughData;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClickThroughView, clearClickThroughView, updateSelectedDateRange } = oneOnOnePageSlice.actions;

export default oneOnOnePageSlice.reducer;

import React from 'react';

import { Tabs, Tab } from '@material-ui/core';
import useOrganisationSettings from 'components/hooks/useOrganisationSettings';
import styled from 'styled-components';
import { checkLevelUpFeatureFlagEnabled } from 'utils/helpers/featureFlags';
import {
  checkFeedbackModuleEnabled,
  checkGoalModuleEnabled,
  checkOneOnOneModuleEnabled,
  checkReviewModuleEnabled,
  checkTaskModuleEnabled,
} from 'utils/helpers/moduleAccess';

import { MESH_LIST_TABS, TASK_TYPES_2 } from 'constants/ProjectConstants';

export const FEEDBACK_TYPE = 'FEEDBACK';

export const TASK_VIEW_TYPES = {
  '-1': TASK_TYPES_2.task,
  '-4': TASK_TYPES_2.goal,
  '-5': TASK_TYPES_2.checkIn,
  '-6': FEEDBACK_TYPE,
};

export default function TabHeader({
  currentTab = MESH_LIST_TABS.goal.id,
  onTabChange,
  isLoggedInEmployee = false,
  className,
  isOneOnOne = false,
  showLevelUpTab = false,
}) {
  const handleChange = (event, newValue) => {
    onTabChange && onTabChange(newValue);
  };

  const { moduleName, objectivesName, keyResultsName } = useOrganisationSettings();

  const isLevelUpTabValid = showLevelUpTab && checkLevelUpFeatureFlagEnabled();

  return (
    <StyledTabs
      className={className}
      variant="fullWidth"
      value={currentTab}
      onChange={handleChange}
      aria-label="Tasks List Tabs"
      indicatorColor="primary"
    >
      {checkTaskModuleEnabled() && <StyledTab label="Initiatives" value={MESH_LIST_TABS.task.id} />}
      {checkGoalModuleEnabled() && <StyledTab label={moduleName} value={MESH_LIST_TABS.goal.id} />}
      {isLoggedInEmployee && checkOneOnOneModuleEnabled() && (
        <StyledTab label="1:1" value={MESH_LIST_TABS.checkIn.id} />
      )}
      {/* disabling Feedback tab */}
      {false && !isLoggedInEmployee && checkFeedbackModuleEnabled() && isOneOnOne && (
        <StyledTab id="feedbacks-meshlist" label="Feedback" value={MESH_LIST_TABS.feedback.id} />
      )}
      {isOneOnOne ? (
        isLevelUpTabValid ? (
          <StyledTab
            id="levelup-meshlist"
            label={MESH_LIST_TABS.levelUp.displayName}
            value={MESH_LIST_TABS.levelUp.id}
          />
        ) : (
          <StyledTab
            id="competencies-meshlist"
            label={MESH_LIST_TABS.competencies.displayName}
            value={MESH_LIST_TABS.competencies.id}
          />
        )
      ) : null}
      {!isLoggedInEmployee && checkReviewModuleEnabled() && !isOneOnOne && (
        <StyledTab id="reviews-meshlist" label="Reviews" value={MESH_LIST_TABS.review.id} />
      )}
    </StyledTabs>
  );
}

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    width: 33%;
  }
`;

const StyledTab = styled(Tab)`
  min-width: auto;
  text-transform: none;
  color: ${(props) => props.theme.palette.text.label};
  &.Mui-selected {
    color: ${(props) => props.theme.palette.text.primaryBlue};
  }
  &.MuiTab-textColorInherit {
    opacity: 1;
  }
`;

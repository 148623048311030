import { Icon } from '@chakra-ui/react';

export const Emoji = (props) => (
  <Icon viewBox="0 0 16 17" boxSize="16px" color="icon.g1" {...props}>
    <circle cx="8" cy="8.92981" r="5.5" stroke="currentColor" />
    <mask id="path-2-inside-1_5394_209113" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9047 10.4298H5.09375C5.42678 11.7237 6.60136 12.6798 7.99924 12.6798C9.39712 12.6798 10.5717 11.7237 10.9047 10.4298Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9047 10.4298H5.09375C5.42678 11.7237 6.60136 12.6798 7.99924 12.6798C9.39712 12.6798 10.5717 11.7237 10.9047 10.4298Z"
      fill="currentColor"
    />
    <path
      d="M10.9047 10.4298L11.8732 10.6791L12.1947 9.42981H10.9047V10.4298ZM5.09375 10.4298V9.42981H3.80378L4.12531 10.6791L5.09375 10.4298ZM10.9047 9.42981H5.09375V11.4298H10.9047V9.42981ZM7.99924 11.6798C7.06869 11.6798 6.28426 11.0434 6.06219 10.1806L4.12531 10.6791C4.5693 12.4041 6.13403 13.6798 7.99924 13.6798V11.6798ZM9.93629 10.1806C9.71421 11.0434 8.92979 11.6798 7.99924 11.6798V13.6798C9.86445 13.6798 11.4292 12.4041 11.8732 10.6791L9.93629 10.1806Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_5394_209113)"
    />
    <path
      d="M11 7.92981C11 8.48209 10.5523 8.92981 10 8.92981C9.44772 8.92981 9 8.48209 9 7.92981C9 7.37752 9.44772 6.92981 10 6.92981C10.5523 6.92981 11 7.37752 11 7.92981Z"
      fill="currentColor"
    />
    <path
      d="M7 7.92981C7 8.48209 6.55228 8.92981 6 8.92981C5.44772 8.92981 5 8.48209 5 7.92981C5 7.37752 5.44772 6.92981 6 6.92981C6.55228 6.92981 7 7.37752 7 7.92981Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CheckInThick = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M20 12.0056C20 16.4257 16.4231 20 12 20C7.57687 20 4 16.4257 4 12.0056C4 7.58562 7.58815 4 12 4C12.3836 4 12.7673 4.02255 13.1396 4.0902V5.23713C12.769 5.17513 12.3883 5.14286 12 5.14286C8.2129 5.14286 5.14286 8.2129 5.14286 12C5.14286 15.7871 8.2129 18.8571 12 18.8571C15.7871 18.8571 18.8571 15.7871 18.8571 12C18.8571 11.614 18.8252 11.2354 18.7639 10.8668H19.9097C19.9774 11.2276 20 11.6223 20 12.0056Z"
      fill="currentColor"
    />
    <path
      d="M8.57143 10.8571L12 14.2857L19.9999 6.28571L18.8571 5.14286L12 12L9.71426 9.71427L8.57143 10.8571Z"
      fill="currentColor"
    />
    <path
      d="M20 12.0056C20 16.4257 16.4231 20 12 20C7.57687 20 4 16.4257 4 12.0056C4 7.58562 7.58815 4 12 4C12.3836 4 12.7673 4.02255 13.1396 4.0902V5.23713C12.769 5.17513 12.3883 5.14286 12 5.14286C8.2129 5.14286 5.14286 8.2129 5.14286 12C5.14286 15.7871 8.2129 18.8571 12 18.8571C15.7871 18.8571 18.8571 15.7871 18.8571 12C18.8571 11.614 18.8252 11.2354 18.7639 10.8668H19.9097C19.9774 11.2276 20 11.6223 20 12.0056Z"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <path
      d="M8.57143 10.8571L12 14.2857L19.9999 6.28571L18.8571 5.14286L12 12L9.71426 9.71427L8.57143 10.8571Z"
      stroke="currentColor"
      strokeWidth="0.8"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const PeerSettings = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_3844_21779)">
      <path
        d="M12.7861 7.42648C12.7861 9.04244 11.4752 10.353 9.85753 10.353C8.23983 10.353 6.92896 9.04244 6.92896 7.42648C6.92896 5.81052 8.23983 4.5 9.85753 4.5C11.4752 4.5 12.7861 5.81052 12.7861 7.42648Z"
        stroke="currentColor"
        fill="transparent"
      />
      <path
        d="M18.9345 17.424L18.1659 16.843C18.1802 16.731 18.1946 16.619 18.1946 16.5C18.1946 16.381 18.1874 16.269 18.1659 16.157L18.9273 15.576C18.9919 15.52 19.0135 15.429 18.9704 15.352L18.2521 14.141C18.209 14.064 18.1156 14.036 18.0294 14.064L17.1387 14.414C16.9519 14.274 16.7508 14.155 16.5281 14.071L16.3916 13.147C16.3844 13.063 16.3054 13 16.2192 13H14.7826C14.6964 13 14.6174 13.063 14.603 13.147L14.4665 14.071C14.251 14.162 14.0427 14.274 13.856 14.414L12.9652 14.064C12.8862 14.036 12.7928 14.064 12.7426 14.141L12.0242 15.352C11.9811 15.429 11.9955 15.52 12.0673 15.576L12.8288 16.157C12.8144 16.269 12.8072 16.381 12.8072 16.5C12.8072 16.619 12.8144 16.731 12.8288 16.843L12.0673 17.424C12.0027 17.48 11.9811 17.571 12.0242 17.648L12.7426 18.859C12.7857 18.936 12.879 18.964 12.9652 18.936L13.856 18.586C14.0427 18.726 14.2439 18.845 14.4665 18.929L14.603 19.853C14.6174 19.937 14.6892 20 14.7826 20H16.2192C16.3054 20 16.3844 19.937 16.3988 19.853L16.5353 18.929C16.7508 18.838 16.9591 18.726 17.1387 18.586L18.0366 18.936C18.1156 18.964 18.209 18.936 18.2593 18.859L18.9776 17.648C19.0207 17.571 18.9991 17.48 18.9345 17.424ZM15.5009 17.55C14.9047 17.55 14.4234 17.081 14.4234 16.5C14.4234 15.919 14.9047 15.45 15.5009 15.45C16.0971 15.45 16.5784 15.919 16.5784 16.5C16.5784 17.081 16.0971 17.55 15.5009 17.55Z"
        stroke="currentColor"
        fill="transparent"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3117 12.0178C12.2089 12.0099 12.1051 12.0059 12.0002 12.0059H8.00024C5.79111 12.0059 4.00024 13.7967 4.00024 16.0059V18.9412C4.00024 19.5265 4.47474 20.001 5.06005 20.001H11.2579C11.0042 19.6939 10.7831 19.3588 10.6001 19.001H5.06005C5.02702 19.001 5.00024 18.9742 5.00024 18.9412V16.0059C5.00024 14.349 6.34339 13.0059 8.00024 13.0059H11.2523C11.5602 12.6319 11.9166 12.2994 12.3117 12.0178Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3844_21779">
        <rect width="24" height="24" rx="8" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

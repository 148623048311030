import { Icon } from '@chakra-ui/react';

export const InfoWorkZero = (props) => (
  <Icon viewBox="0 0 24 25" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12.0015 11.6628C12.3097 11.6628 12.5596 11.9127 12.5596 12.221V15.2907C12.5596 15.5989 12.3097 15.8488 12.0015 15.8488C11.6932 15.8488 11.4434 15.5989 11.4434 15.2907V12.221C11.4434 11.9127 11.6932 11.6628 12.0015 11.6628Z"
      fill="currentColor"
    />
    <path
      d="M12.0005 10.5461C12.3858 10.5461 12.6982 10.2337 12.6982 9.84835C12.6982 9.46301 12.3858 9.15063 12.0005 9.15063C11.6151 9.15063 11.3027 9.46301 11.3027 9.84835C11.3027 10.2337 11.6151 10.5461 12.0005 10.5461Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12.5C6 9.18628 8.68628 6.5 12 6.5C15.3137 6.5 18 9.18628 18 12.5C18 15.8137 15.3137 18.5 12 18.5C8.68628 18.5 6 15.8137 6 12.5V12.5ZM12 7.61613C9.30271 7.61613 7.11613 9.80271 7.11613 12.5C7.11613 15.1973 9.30271 17.3839 12 17.3839C14.6973 17.3839 16.8839 15.1973 16.8839 12.5C16.8839 9.80271 14.6973 7.61613 12 7.61613Z"
      fill="currentColor"
    />
  </Icon>
);

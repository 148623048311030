import {
  ADD_TO_AGENDA_QUEUE,
  DISABLE_AGENDA_QUEUE,
  ENABLE_AGENDA_QUEUE,
  REMOVE_FROM_AGENDA_QUEUE,
} from 'actions/actionTypes';

const initialState = {
  showAddToAgenda: false,
  agendaQueue: [],
};

export default function agendaQueueReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_AGENDA_QUEUE: {
      /*
        1. for tagged/linked agendas
          a. isTagged is true and text is be empty string
          a. if label type is levelup, levelUpInfo is object
          c. if label type is task, goal, feedback, levelUpInfo is null
        2. for untagged/normal agenda
          a. uuid is randomly generated id ( so that it can be removed)
          b. isTagged is false
          c. text is non empty
          d. labelType is null
          f. levelUpInfo is null
        3. 
      */
      const { uuid, labelType, text, isTagged, levelUpInfo } = action.payload;
      return {
        ...state,
        agendaQueue: [
          ...state.agendaQueue,
          {
            uuid,
            labelType,
            text,
            isTagged,
            levelUpInfo,
          },
        ],
      };
    }

    case REMOVE_FROM_AGENDA_QUEUE: {
      const { uuid: removalId } = action.payload;
      return { ...state, agendaQueue: state.agendaQueue.filter(({ uuid }) => uuid !== removalId) };
    }

    case ENABLE_AGENDA_QUEUE: {
      return { ...state, showAddToAgenda: true, agendaQueue: [] };
    }

    case DISABLE_AGENDA_QUEUE: {
      return { ...state, showAddToAgenda: false, agendaQueue: [] };
    }

    default:
      return state;
  }
}

import { Icon } from '@chakra-ui/react';

export const CloseIconChip = (props) => (
  <Icon viewBox="0 0 12 12" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M9.15035 2.85499C8.95535 2.65999 8.64035 2.65999 8.44535 2.85499L6.00035 5.29499L3.55535 2.84999C3.36035 2.65499 3.04535 2.65499 2.85035 2.84999C2.65535 3.04499 2.65535 3.35999 2.85035 3.55499L5.29535 5.99999L2.85035 8.44498C2.65535 8.63998 2.65535 8.95498 2.85035 9.14998C3.04535 9.34498 3.36035 9.34498 3.55535 9.14998L6.00035 6.70498L8.44535 9.14998C8.64035 9.34498 8.95535 9.34498 9.15035 9.14998C9.34535 8.95498 9.34535 8.63998 9.15035 8.44498L6.70535 5.99999L9.15035 3.55499C9.34035 3.36499 9.34035 3.04499 9.15035 2.85499Z"
      fill="currentColor"
    />
  </Icon>
);

import { OPEN_DATETIME_PICKER, CLOSE_DATETIME_PICKER } from 'actions/actionTypes';

export const initialState = {
  isOpen: false,
  onChange: null,
  initialDate: null,
  endDate: null,
  autoOk: true,
  disablePast: false,
  clearable: true,
  onClose: null,
  minDate: null,
  isOneOnOneDatePicker: false,
};

const dateTimePicker = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DATETIME_PICKER:
      return {
        ...initialState,
        isOpen: true,
        onChange: action.data.onChange,
        initialDate: action.data.initialDate,
        endDate: action.data.endDate,
        clearable: action.data.clearable,
        autoOk: action.data.autoOk,
        openTo: action.data.openTo,
        minDate: action.data.minDate,
        isOneOnOneDatePicker: action.data.isOneOnOneDatePicker,
      };

    case CLOSE_DATETIME_PICKER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default dateTimePicker;

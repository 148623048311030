import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntentValuesType } from 'Deer/intent/types/IntentCommonTypes';
import { useSelector } from 'react-redux';

export type CompetenciesIntentType = {
  label: string;
  onClick: () => void;
  visible: () => boolean;
  value: {
    myData?: IntentValuesType;
    team?: {
      directReports?: IntentValuesType;
      allReports?: IntentValuesType;
    };
    company?: IntentValuesType;
  };
  //   applicableGroupByValues?: {
  //     value: string;
  //     label: string;
  //   }[];
  //   showGroupByDropdown?: boolean;
  queryParamValue?: string;
  showTimelineDropdown?: boolean;
  category: 'Manage' | 'Analyze' | 'Configure';
  isDefault?: boolean;
  showGroupByDropdown?: boolean;
  showDownloadCSV?: boolean;
};

export const GROUP_BY_VALUES = [
  { uuid: null, keyName: 'employeeId', displayName: 'Individual', enabled: true },
  { uuid: null, keyName: 'competency', displayName: 'Competencies', enabled: true },
  { uuid: null, keyName: 'orgTree', displayName: 'Organisation Tree', enabled: true },
];

const initialState: {
  selectedIntent: null | CompetenciesIntentType;
  selectedGroupBy: {
    '-2': 'employeeId' | 'competency';
    '-3': 'competency' | 'orgTree';
    '-1': 'competency' | 'orgTree';
  };
} = {
  selectedIntent: null,
  selectedGroupBy: {
    // direct team
    '-2': 'employeeId',
    '-3': 'orgTree',
    '-1': 'orgTree',
  },
};

export const competenciesIntentSlice = createSlice({
  name: 'competenciesIntent',
  initialState,
  reducers: {
    setSelectedCompetenciesIntent: (state, action: PayloadAction<CompetenciesIntentType>) => {
      const newState = { ...state };
      newState.selectedIntent = action.payload;
      return newState;
    },
    setSelectedGroupBy: (
      state,
      action: PayloadAction<{
        cohortId: number;
        groupBy: 'employeeId' | 'competency' | 'orgTree';
      }>
    ) => {
      const newState = state;
      if ([-3, -1].includes(action.payload.cohortId)) {
        // @ts-expect-error group by incorrect type
        newState.selectedGroupBy['-1'] = action.payload.groupBy;
        // @ts-expect-error group by incorrect type
        newState.selectedGroupBy['-3'] = action.payload.groupBy;
      }
      newState.selectedGroupBy[String(action.payload.cohortId)] = action.payload.groupBy;
    },
  },
});

export const { setSelectedCompetenciesIntent, setSelectedGroupBy } = competenciesIntentSlice.actions;

export default competenciesIntentSlice.reducer;

export const useSelectCompetenciesSelectedIntent = () =>
  useSelector((state: { competenciesIntent: typeof initialState }) => state.competenciesIntent);

export const useSelectCompetenciesSelectedGroupBy = (cohortId: number) =>
  useSelector((state: { competenciesIntent: typeof initialState }) => {
    const groupByKey = state.competenciesIntent.selectedGroupBy[String(cohortId)];
    return GROUP_BY_VALUES.find(({ keyName }) => keyName === groupByKey);
  });

import { Icon } from '@chakra-ui/react';

export const ArrowUp1 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M13 18.7912V7.62124L17.88 12.5012C18.27 12.8912 18.91 12.8912 19.3 12.5012C19.69 12.1112 19.69 11.4812 19.3 11.0912L12.71 4.50124C12.32 4.11124 11.69 4.11124 11.3 4.50124L4.7 11.0812C4.31 11.4712 4.31 12.1012 4.7 12.4912C5.09 12.8812 5.72 12.8812 6.11 12.4912L11 7.62124V18.7912C11 19.3412 11.45 19.7912 12 19.7912C12.55 19.7912 13 19.3412 13 18.7912Z"
      fill="currentColor"
    />
  </Icon>
);

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState: {
  type: null | string;
  details: any;
} = {
  type: null,
  details: {},
};

export const okrDrawerChangeDetailsSlice = createSlice({
  name: 'okrDrawerChangeDetails',
  initialState,
  reducers: {
    setInitialDetails: (state, action) => ({
      ...state,
      details: action.payload.details,
      type: action.payload.type,
    }),
  },
});

export const { setInitialDetails } = okrDrawerChangeDetailsSlice.actions;

export const useSelectOkrDrawerChangeDetails = () => {
  const details = useSelector((state: any) => state.okrDrawerChangeDetails.details);
  return details;
};

export default okrDrawerChangeDetailsSlice.reducer;

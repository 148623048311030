import { maleIconSrc, femaleIconSrc } from 'constants/icons';

function getProfileImage() {
  if (this.profilePhotoSrc) {
    return this.profilePhotoSrc;
  }
  if (this.gender === 'MALE') return maleIconSrc;
  return femaleIconSrc;
}

export const selectLoggedInEmployeeData = (state) => state.loggedInEmployee.data;
export const selectLoggedInEmployeeEmail = (state) => {
  const data = selectLoggedInEmployeeData(state);
  return data ? data.email : null;
};

export const selectLoggedInEmployeeId = (state) => state?.loggedInEmployee?.data?.id;

export const selectLoggedInEmployeeOrganisationSettings = (state) =>
  state?.loggedInEmployee?.data?.organization?.objectiveSettings;

export const selectLoggedInEmployeeTeams = (state) => state.loggedInEmployee.data.teams;

export const selectIsAuthenticated = (state) => state.loggedInEmployee.isAuthenticated;
export const selectIsAuthenticating = (state) => state.loggedInEmployee.isAuthenticating;

export const selectMoneyDetails = (state) => state.loggedInEmployee.data.money;

export const selectMoneyDetailsFromEmployee = (employee) => employee.money;

export const isExploringDemoSelector = (state) => {
  const email = selectLoggedInEmployeeEmail(state);
  // return true;
  // disabling HelloBar as per Saurabh's suggestion.
  return false && email && email === 'pranav+demo+1@thepeoplemesh.com';
};

export const selectPreferredTeamId = (state) =>
  state.loggedInEmployee.data && state.loggedInEmployee.data.teams && state.loggedInEmployee.data.teams[0]
    ? state.loggedInEmployee.data.teams[0].id
    : null;

export const selectLoggedInEmployeeImage = (state) => getProfileImage.call(state.loggedInEmployee.data);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState: {
  isExpanded: boolean;
} = {
  isExpanded: false,
};

export const expandedContainerSlice = createSlice({
  name: 'expandedContainer',
  initialState,
  reducers: {
    setIsExpandedContainer: (state, action: PayloadAction<boolean>) => {
      state.isExpanded = action.payload;
    },
  },
});

export const { setIsExpandedContainer } = expandedContainerSlice.actions;

export default expandedContainerSlice.reducer;

export const useSelectIsExpanded = () =>
  useSelector((state: { expandedContainer: typeof initialState }) => state.expandedContainer.isExpanded);

import { createSlice } from '@reduxjs/toolkit';

type EmployeesFilters = {
  grade?: { id: string; displayName: string }[];
  roleId?: { id: string; displayName: string }[];
  gender?: { id: string; displayName: string }[];
  functionId?: { id: string; displayName: string }[];
  ethnicity?: { id: string; displayName: string }[];
  location?: { id: string; displayName: string }[];
};

const initialState: {
  employeesFilters: EmployeesFilters;
} = {
  employeesFilters: {},
};

export const createCustomRoleSlice = createSlice({
  name: 'createCustomRole',
  initialState,
  reducers: {
    setEmployeesFilters: (state, action) => {
      const { employeesFilters: newEmployeesFilters } = action.payload;
      state.employeesFilters = newEmployeesFilters;
    },
    resetEmployeesFilters: (state) => {
      state.employeesFilters = initialState.employeesFilters;
    },
  },
});

export const { setEmployeesFilters, resetEmployeesFilters } = createCustomRoleSlice.actions;

export default createCustomRoleSlice.reducer;

// selectors
export const selectEmployeesFilters = (state: { createCustomRoleSlice: { employeesFilters: EmployeesFilters } }) =>
  state.createCustomRoleSlice.employeesFilters;

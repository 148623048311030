import { Icon } from '@chakra-ui/react';

export const ArrowDown16 = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8.00988 10.5C7.79773 10.5 7.5943 10.4123 7.4443 10.2564L4.24432 6.92986C4.0899 6.7749 4.00191 6.5619 4.00003 6.33886C3.99816 6.11581 4.08267 5.90128 4.23427 5.74353C4.38602 5.58579 4.59239 5.49808 4.80695 5.50003C5.02151 5.50198 5.22641 5.59345 5.37548 5.75384L8.57546 9.08034C8.72546 9.23627 8.8097 9.4479 8.8097 9.66842C8.8097 9.88893 8.72546 10.1004 8.57546 10.2564C8.42546 10.4123 8.22201 10.5 8.00988 10.5Z"
      fill="currentColor"
    />
    <path
      d="M8.00988 10.5C7.79773 10.5 7.5943 10.4123 7.4443 10.2564C7.2943 10.1004 7.20995 9.88899 7.20995 9.66848C7.20995 9.44796 7.29419 9.23647 7.4442 9.08053L10.6442 5.75404C10.8473 5.55007 11.1391 5.47239 11.4115 5.55007C11.684 5.62776 11.8968 5.84899 11.9716 6.13217C12.0462 6.41549 11.9717 6.71858 11.7754 6.92979L8.57546 10.2564C8.42546 10.4123 8.22201 10.5 8.00988 10.5Z"
      fill="currentColor"
    />
  </Icon>
);

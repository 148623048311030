import { Icon } from '@chakra-ui/react';

export const InsightsNewIconHover = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.1818 6.182L10.8655 4.67654L12.3636 4.00018L10.8655 3.32381L10.1818 1.81836L9.51273 3.32381L8 4.00018L9.51273 4.67654L10.1818 6.182Z"
      fill="#333333"
    />
    <path
      d="M1.81818 7.63654L2.18182 6.182L3.63636 5.81836L2.18182 5.45472L1.81818 4.00018L1.45455 5.45472L0 5.81836L1.45455 6.182L1.81818 7.63654Z"
      fill="#333333"
    />
    <path
      d="M14.5455 5.45508C13.4909 5.45508 12.9018 6.50235 13.1418 7.28053L10.56 9.86962C10.3418 9.80417 10.0218 9.80417 9.80364 9.86962L7.94909 8.01508C8.19636 7.2369 7.60727 6.18235 6.54545 6.18235C5.49091 6.18235 4.89455 7.22962 5.14182 8.01508L1.82545 11.3242C1.04727 11.0842 0 11.6733 0 12.7278C0 13.5278 0.654545 14.1824 1.45455 14.1824C2.50909 14.1824 3.09818 13.1351 2.85818 12.3569L6.16727 9.04053C6.38545 9.10599 6.70546 9.10599 6.92364 9.04053L8.77818 10.8951C8.53091 11.6733 9.12 12.7278 10.1818 12.7278C11.2364 12.7278 11.8327 11.6805 11.5855 10.8951L14.1745 8.31326C14.9527 8.55326 16 7.96417 16 6.90962C16 6.10962 15.3455 5.45508 14.5455 5.45508Z"
      fill="#333333"
    />
  </Icon>
);

import React from 'react';

export default function ThumbsDown({ width = 20, height = 20, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="currentColor"
      fillRule="evenodd"
      {...rest}
    >
      <path d="M15 11.25h3.247c.406 0 .487-.08.486-.493L18.73 1.66c0-.306-.1-.4-.4-.4h-2.85c-.378 0-.47.093-.47.473v9.528zm-1.25-4.902l.003-3.886c.001-.14-.028-.225-.168-.282-.198-.08-.387-.184-.576-.286-.836-.452-1.73-.654-2.678-.648l-4.94.003c-.722 0-1.445-.003-2.167.001-.406.003-.72.275-.724.617-.005.352.26.6.676.633.335.026.58.3.573.645a.61.61 0 0 1-.592.603c-.195.006-.4-.005-.586.004-.293.015-.535.13-.652.415-.166.4.138.802.62.834.34.023.6.292.585.633a.62.62 0 0 1-.603.616c-.104.003-.208-.003-.312.001-.446.02-.826.304-.922.685-.154.604.263 1.16.895 1.187a.61.61 0 0 1 .556.33c.226.42-.056.897-.54.92-.46.022-.8.295-.914.713-.103.413.05.82.434 1.008.196.097.434.147.653.15l5.663-.003c.456-.005.87.388.66.933l-.506 1.513c-.207.675-.48 1.343-.575 2.035-.162 1.188.3 2.178 1.238 2.93a.37.37 0 0 0 .333.04c.092-.05.164-.194.184-.307.03-.177.008-.363.008-.545a5.14 5.14 0 0 1 1.11-3.238l3.118-4.004a.66.66 0 0 0 .15-.442l-.004-3.808zM2.9 0h8.203c.036.012.072.03.1.034.804.077 1.557.32 2.273.686l.36.18c.2-.208.334-.425.53-.57s.446-.222.672-.328H18.7l.12.047a1.57 1.57 0 0 1 1.108 1.12l.06.2v9.766c-.012.022-.03.043-.034.067-.172.8-.768 1.3-1.588 1.3-1.275 0-2.55-.003-3.825.003-.248.001-.48-.013-.66-.222l-.135.162-2.077 2.66a4.49 4.49 0 0 0-1.062 3.094c.027 1.012-.316 1.465-1.27 1.8L8.8 20c-.23-.1-.496-.18-.7-.34-1.476-1.2-2.05-2.75-1.655-4.608.167-.782.478-1.533.723-2.298l.077-.256h-.317c-1.587 0-3.174.003-4.76-.001-.93-.002-1.783-.63-2.065-1.506L0 10.664V10c.1-.466.3-.898.654-1.252C.287 8.4.1 7.966 0 7.5v-.625l.098-.354c.144-.462.427-.825.8-1.1-.482-1.26-.378-1.73.576-2.614l-.047-.1C1 1.752 1.353.683 2.293.217 2.48.123 2.7.07 2.9 0zm14.38 3.636c-.515.001-.9-.4-.907-.918.003-.502.396-.896.897-.9a.9.9 0 0 1 .92.904.89.89 0 0 1-.912.914" />
    </svg>
  );
}

import { Icon } from '@chakra-ui/react';

export const Play = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.8 15.9L15.47 12.4C15.74 12.2 15.74 11.8 15.47 11.6L10.8 8.10001C10.47 7.85001 10 8.09001 10 8.50001V15.5C10 15.91 10.47 16.15 10.8 15.9Z"
      fill="currentColor"
    />
    <circle cx="12" cy="12" r="9.5" stroke="currentColor" fill="transparent" />
  </Icon>
);

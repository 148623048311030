import { Icon } from '@chakra-ui/react';

export const Goal = (props) => (
  <Icon viewBox="0 0 25 25" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_133_173)">
      <path
        d="M18.0843 12.4H15.9543L15.6543 12.7C15.6543 12.9 15.7543 13.21 15.7543 13.51C15.7543 15.74 13.9343 17.56 11.7043 17.56C9.47429 17.56 7.65429 15.74 7.65429 13.51C7.65429 11.28 9.47429 9.46003 11.7043 9.46003C12.1143 9.46003 12.4143 9.56003 12.7143 9.56003V7.13003C12.4143 7.03003 12.1043 7.03003 11.7043 7.03003C8.15429 7.03003 5.21429 9.97003 5.21429 13.51C5.21429 17.05 8.15429 20 11.7043 20C15.2543 20 18.1943 17.06 18.1943 13.51C18.1943 13.1 18.1943 12.8 18.0943 12.4H18.0843Z"
        fill="currentColor"
      />
      <path
        d="M20.2143 9.16L17.9843 11.39H15.5543L12.2143 14.73C11.8043 15.14 11.1043 15.14 10.5943 14.73C10.0843 14.32 10.1843 13.62 10.5943 13.11L13.8343 9.87V7.23L16.0543 5V9.16H20.2143Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_133_173">
        <rect width="15" height="15" fill="transparent" transform="translate(5.21429 5)" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Snooze = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6085 13.0947L18.5934 14.3242H18.5935C19.0276 14.8631 19.1278 15.5874 18.8272 16.2105C18.5435 16.8338 17.9258 17.2211 17.2414 17.2211H15.0712C14.9043 18.7874 13.5854 20 11.9995 20C10.4136 20 9.09488 18.7706 8.9279 17.2211H6.75769C6.07322 17.2211 5.47225 16.8336 5.17182 16.2105C4.87126 15.5874 4.97143 14.8631 5.42206 14.3242L6.40696 13.0947C6.7074 12.7074 6.87438 12.2526 6.87438 11.7643V10.4338C6.87438 8.36215 8.09305 6.50955 9.96271 5.68425V5.61693C9.96271 4.72417 10.6806 4.00002 11.5654 4.00002H12.4501C13.335 4.00002 14.0528 4.72429 14.0528 5.61693V5.68425C15.9225 6.49268 17.1411 8.3453 17.1411 10.4338V11.7643C17.1411 12.2526 17.3081 12.7242 17.6085 13.0947ZM10.2299 17.221C10.3968 18.0632 11.1147 18.7032 11.9994 18.7032H11.9995C12.8842 18.7032 13.6021 18.0633 13.769 17.221H10.2299ZM17.2246 15.9242C17.4916 15.9242 17.6252 15.739 17.6586 15.6547H17.6586C17.7087 15.5705 17.7755 15.3516 17.5582 15.1494L16.5733 13.92C16.0893 13.3137 15.8222 12.5558 15.8222 11.781V10.4505C15.8222 8.7663 14.7538 7.28412 13.1679 6.76204C12.9175 6.66099 12.7339 6.42521 12.7339 6.1389V5.63361C12.7339 5.46513 12.5837 5.31357 12.4167 5.31357H11.5319C11.3649 5.31357 11.2147 5.46513 11.2147 5.63361V6.1389C11.2147 6.42522 11.0478 6.6778 10.7807 6.76204C9.19484 7.28415 8.12644 8.76627 8.12644 10.4505V11.781C8.12644 12.5558 7.85932 13.3137 7.37526 13.92L6.39037 15.1494C6.20673 15.3516 6.29022 15.5705 6.32364 15.6547C6.37372 15.739 6.49052 15.9242 6.75765 15.9242H17.2246ZM11.916 12.4378H13.4017L13.4017 12.4378C13.769 12.4378 14.0695 12.7241 14.0695 13.0948C14.0695 13.4653 13.7691 13.7516 13.4185 13.7516H10.5972C10.3467 13.7516 10.113 13.6001 10.0129 13.3811C9.91274 13.1453 9.94616 12.8926 10.0964 12.6905L12.083 10.181H10.5972C10.2299 10.181 9.94617 9.87792 9.94617 9.52417C9.94617 9.15362 10.2466 8.86732 10.5972 8.86732L13.4017 8.8672C13.6522 8.8672 13.8859 9.01875 13.986 9.23773C14.0862 9.47353 14.0528 9.72623 13.9025 9.9283L11.916 12.4378Z"
      fill="currentColor"
    />
  </Icon>
);

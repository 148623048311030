import { Icon } from '@chakra-ui/react';

export const FormVisibilitySettings = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M4 17H20C20 17.8284 19.3284 18.5 18.5 18.5H5.5C4.67157 18.5 4 17.8284 4 17Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 6.5H6.5C6.22386 6.5 6 6.72386 6 7V15.5H18V7C18 6.72386 17.7761 6.5 17.5 6.5ZM6.5 5.5C5.67157 5.5 5 6.17157 5 7V16.5H19V7C19 6.17157 18.3284 5.5 17.5 5.5H6.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8497 11.4449C15.9474 11.3155 16 11.1598 16 11.0001C16 10.8403 15.9474 10.6846 15.8497 10.5552C14.8661 9.24917 13.4629 8.5 12 8.5C10.5371 8.5 9.134 9.24912 8.15028 10.5552C8.0526 10.6846 8 10.8403 8 11.0001C8 11.1599 8.0526 11.3156 8.15028 11.4449C9.1339 12.7509 10.5371 13.5 12 13.5C13.4629 13.5 14.866 12.751 15.8497 11.4449ZM14.9278 11C14.1204 11.9868 13.0542 12.5 12 12.5C10.9458 12.5 9.87953 11.9867 9.07214 11.0001C9.87963 10.0132 10.9458 9.5 12 9.5C13.0542 9.5 14.1204 10.0133 14.9278 11Z"
      fill="currentColor"
    />
    <path
      d="M13 11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11Z"
      fill="currentColor"
    />
  </Icon>
);

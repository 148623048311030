// import { MODULE_KEYS1 } from 'constants/ProjectConstants';

// object keys come from MODULE_KEYS1, dynamic keys not running in storybook for some reason
// check and fix

export const MODULE_SETTINGS_TABS_ID = {
  goal: [
    {
      queryParamId: 'settings',
    },
  ],
  initiative: [
    {
      queryParamId: 'tags',
    },
  ],
  status: [
    {
      queryParamId: 'question_library',
    },
    {
      queryParamId: 'templates',
    },
    {
      queryParamId: 'review_cycles',
    },
    {
      queryParamId: 'calibration_settings',
    },
  ],
  oneOnOne: [
    {
      queryParamId: 'question_library',
    },
    {
      queryParamId: 'templates',
    },
    {
      queryParamId: 'my_preferences',
    },
    {
      queryParamId: 'settings',
    },
  ],
  engagement: [
    {
      queryParamId: 'engagement_drivers',
    },
    {
      queryParamId: 'question_library',
    },
    {
      queryParamId: 'templates',
    },
    {
      queryParamId: 'engagement_surveys',
    },
  ],
  levelUp: [],
};

import { Icon } from '@chakra-ui/react';

export const OpenSidePanel = (props) => (
  <Icon viewBox="0 0 24 25" boxSize="16px" color="icon.g1" {...props}>
    <g fill="transparent">
      <rect x="4.31738" y="5.05664" width="15" height="15" rx="1.5" stroke="currentColor" />
      <path
        d="M14.3174 5.05664H17.8174C18.6458 5.05664 19.3174 5.72821 19.3174 6.55664V18.5566C19.3174 19.3851 18.6458 20.0566 17.8174 20.0566H14.3174V5.05664Z"
        fill="#D6E7F9"
        stroke="currentColor"
      />
      <path
        d="M6.81738 12.4883C6.81738 12.2121 7.04124 11.9883 7.31738 11.9883H12.3174C12.5935 11.9883 12.8174 12.2121 12.8174 12.4883C12.8174 12.7644 12.5935 12.9883 12.3174 12.9883H7.31738C7.04124 12.9883 6.81738 12.7644 6.81738 12.4883Z"
        fill="currentColor"
      />
      <path
        d="M9.7568 9.7891C9.95206 9.59384 10.2686 9.59384 10.4639 9.7891L12.9904 12.3156C13.1856 12.5108 13.1856 12.8274 12.9904 13.0227C12.7951 13.2179 12.4785 13.2179 12.2832 13.0227L9.7568 10.4962C9.56154 10.3009 9.56154 9.98436 9.7568 9.7891Z"
        fill="currentColor"
      />
      <path
        d="M9.87789 15.4463C10.0732 15.6415 10.3897 15.6415 10.585 15.4463L12.992 13.0392C13.1873 12.844 13.1873 12.5274 12.992 12.3321C12.7968 12.1368 12.4802 12.1368 12.2849 12.3321L9.87789 14.7391C9.68263 14.9344 9.68263 15.251 9.87789 15.4463Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1064_3698">
        <rect width="16" height="16" fill="white" transform="translate(4 4.5)" />
      </clipPath>
    </defs>
  </Icon>
);

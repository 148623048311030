import { Icon } from '@chakra-ui/react';

export const MyOrgGoals = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8 19.5C9.38071 19.5 10.5 18.3807 10.5 17C10.5 15.6193 9.38071 14.5 8 14.5C6.61929 14.5 5.5 15.6193 5.5 17C5.5 18.3807 6.61929 19.5 8 19.5Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M9.79995 14.3V13.1C9.79995 12.9 9.69995 12.8 9.49995 12.7L8.29995 12.1C8.19995 12 8.09995 12 7.99995 12C7.89995 12 7.79995 12 7.69995 12.1L6.49995 12.7C6.29995 12.8 6.19995 12.9 6.19995 13.1V14.3C6.19995 14.5 6.29995 14.6 6.49995 14.7L7.69995 15.3C7.79995 15.4 7.89995 15.4 7.99995 15.4C8.09995 15.4 8.19995 15.3 8.29995 15.3L9.49995 14.7C9.69995 14.6 9.79995 14.5 9.79995 14.3Z"
      fill="currentColor"
    />
    <path
      d="M6.8 19V17.7C6.8 17.5 6.7 17.4 6.6 17.3L5.6 16.7C5.5 16.6 5.5 16.6 5.4 16.6C5.4 16.6 5.3 16.6 5.2 16.6L4.2 17.2C4.1 17.3 4 17.4 4 17.6V18.9C4 19.1 4.1 19.2 4.2 19.3L5.2 19.9C5.3 20 5.3 20 5.4 20C5.5 20 5.6 20 5.6 20L6.6 19.4C6.7 19.3 6.8 19.2 6.8 19Z"
      fill="currentColor"
    />
    <path
      d="M12 19V17.7C12 17.5 11.9 17.3 11.8 17.3L10.8 16.7C10.7 16.6 10.7 16.6 10.6 16.6C10.5 16.6 10.4 16.6 10.4 16.6L9.39995 17.2C9.29995 17.3 9.19995 17.4 9.19995 17.6V18.9C9.19995 19.1 9.29995 19.2 9.39995 19.3L10.4 19.9C10.5 20 10.6 20 10.6 20C10.6 20 10.7 20 10.8 20L11.8 19.4C11.9 19.3 12 19.2 12 19Z"
      fill="currentColor"
    />
    <path
      d="M7.99961 11C8.29961 11 8.49961 11.1 8.69961 11.2L9.09961 11.4C9.39961 10 10.5996 9 11.9996 9C12.0996 9 12.0996 9 12.1996 9L12.9996 8.2V7.1C12.6996 7 12.2996 7 11.9996 7C9.49961 7 7.39961 8.9 7.09961 11.3L7.29961 11.2C7.49961 11.1 7.79961 11 7.99961 11Z"
      fill="currentColor"
    />
    <path
      d="M16.8997 11H15.7997L14.9997 11.8C14.9997 11.9 14.9997 11.9 14.9997 12C14.9997 13.7 13.6997 15 11.9997 15C11.7997 15 11.5997 15 11.3997 14.9C11.5997 15.3 11.7997 15.7 11.8997 16.1L12.2997 16.3C12.4997 16.4 12.6997 16.6 12.7997 16.8C15.1997 16.5 16.9997 14.5 16.9997 12C16.9997 11.7 16.9997 11.3 16.8997 11Z"
      fill="currentColor"
    />
    <path
      d="M20 8H16V4L14 6V8.6L11.3 11.3C10.9 11.7 10.9 12.3 11.3 12.7C11.5 12.9 11.7 13 12 13C12.3 13 12.5 12.9 12.7 12.7L15.4 10H18L20 8Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const MyTeamInitiativeLatest = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.7 17.1C7.2 17.1 7.7 16.8 7.9 16.3C7.7 15.9 7.6 15.4 7.6 15V14.6C7.4 14.4 7.1 14.2 6.7 14.2C6 14.2 5.4 14.8 5.4 15.6C5.3 16.5 5.9 17.1 6.7 17.1ZM6 19.2C6 18.7 6.2 18.3 6.5 17.9C5.5 17.9 4 18.4 4 19.3V20H6V19.2ZM10.1 16.7C11 16.7 11.7 16 11.7 15C11.7 14.1 11 13.3 10.1 13.3C9.2 13.3 8.5 14 8.5 15C8.5 15.9 9.2 16.7 10.1 16.7ZM10.1 17.5C9.1 17.5 7 18.1 7 19.2V20H13.2V19.2C13.2 18.1 11.1 17.5 10.1 17.5ZM13.7 17.9C14 18.3 14.2 18.7 14.2 19.2V20H16.2V19.3C16.1 18.4 14.6 17.9 13.7 17.9ZM12.6 15C12.6 15.5 12.5 15.9 12.3 16.3C12.5 16.8 13 17.1 13.5 17.1C14.2 17.1 14.8 16.5 14.8 15.7C14.8 14.9 14.2 14.3 13.5 14.3C13.2 14.3 12.9 14.4 12.6 14.7V15Z"
      fill="currentColor"
    />
    <path d="M16.8299 7.79999L13.6699 10.79L12.1699 9.12999" stroke="currentColor" strokeWidth="2" />
    <path
      d="M18.18 4H10.82C9.82 4 9 4.82 9 5.82V12.56C9.33 12.41 9.7 12.3 10.1 12.3C10.42 12.3 10.72 12.38 11 12.49V6H18V13H11.82C12.02 13.19 12.2 13.39 12.34 13.63C12.69 13.41 13.08 13.3 13.5 13.3C14.53 13.3 15.4 14.02 15.69 15H18.18C19.18 15 20 14.18 20 13.18V5.82C20 4.82 19.18 4 18.18 4Z"
      fill="currentColor"
    />
  </Icon>
);

import { OPEN_REORDER_CONFIRMATION_MODAL, CLOSE_REORDER_CONFIRMATION_MODAL } from 'actions/actionTypes';

export const initialState = {
  isOpen: false,
  onClose: () => {},
  onSelect: () => {},
  currentState: null,
};

const reorderConfirmationModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REORDER_CONFIRMATION_MODAL:
      return {
        isOpen: true,
        onClose: action.payload.onClose,
        onSelect: action.payload.onSelect,
        currentState: action.payload.currentState,
      };

    case CLOSE_REORDER_CONFIRMATION_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reorderConfirmationModalReducer;

import { Icon } from '@chakra-ui/react';

export const Gif = (props) => (
  <Icon viewBox="0 0 14 17" boxSize="16px" color="icon.g1" {...props}>
    <rect x="2.5" y="3.42981" width="9" height="11" rx="0.5" stroke="currentColor" />
    <path
      d="M4.9993 10.6041C4.81481 10.6041 4.64741 10.558 4.49709 10.4658C4.34676 10.3735 4.22548 10.2506 4.13324 10.0968C4.04441 9.93966 4 9.76542 4 9.5741V7.9496C4 7.76512 4.04441 7.59601 4.13324 7.44227C4.22548 7.28512 4.34676 7.16042 4.49709 7.06817C4.65082 6.97593 4.81823 6.92981 4.9993 6.92981C5.1872 6.92981 5.35631 6.97593 5.50663 7.06817C5.66037 7.157 5.78165 7.27828 5.87048 7.43202C5.95931 7.58576 6.00372 7.75829 6.00372 7.9496V8.18534C6.00372 8.199 5.99518 8.20584 5.9781 8.20584H5.40414C5.39048 8.20584 5.38364 8.199 5.38364 8.18534V7.9906C5.38364 7.86078 5.34606 7.75316 5.2709 7.66775C5.19916 7.58234 5.10862 7.53964 4.9993 7.53964C4.90022 7.53964 4.8114 7.58063 4.73282 7.66263C4.65766 7.74462 4.62008 7.84028 4.62008 7.9496V9.5741C4.62008 9.68685 4.65766 9.78421 4.73282 9.86621C4.80798 9.94478 4.89681 9.98407 4.9993 9.98407C5.10862 9.98407 5.19916 9.94478 5.2709 9.86621C5.34606 9.78763 5.38364 9.69026 5.38364 9.5741V9.323H5.02492C5.00784 9.323 4.9993 9.31446 4.9993 9.29738V8.72342C4.9993 8.70634 5.00784 8.6978 5.02492 8.6978H5.98322C5.99689 8.6978 6.00372 8.70634 6.00372 8.72342V9.5741C6.00372 9.76542 5.9576 9.93966 5.86536 10.0968C5.77653 10.2506 5.65695 10.3735 5.50663 10.4658C5.35631 10.558 5.1872 10.6041 4.9993 10.6041Z"
      fill="currentColor"
    />
    <path
      d="M6.82124 10.5529C6.80416 10.5529 6.79562 10.5444 6.79562 10.5273L6.80074 7.00668C6.80074 6.99301 6.80757 6.98618 6.82124 6.98618H7.3952C7.40886 6.98618 7.41569 6.99301 7.41569 7.00668L7.42082 10.5273C7.42082 10.5444 7.41399 10.5529 7.40032 10.5529H6.82124Z"
      fill="currentColor"
    />
    <path
      d="M8.32426 10.5529C8.30717 10.5529 8.29863 10.5444 8.29863 10.5273L8.30888 7.00668C8.30888 6.99301 8.31571 6.98618 8.32938 6.98618H9.96925C9.98633 6.98618 9.99488 6.99301 9.99488 7.00668V7.58576C9.99488 7.59942 9.98804 7.60626 9.97438 7.60626H8.92383V8.40057H9.97438C9.98804 8.40057 9.99488 8.40911 9.99488 8.42619L10 9.00527C10 9.01894 9.99146 9.02577 9.97438 9.02577H8.92383V10.5273C8.92383 10.5444 8.91529 10.5529 8.89821 10.5529H8.32426Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const RabbiPraise = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_2844_144387)">
      <path
        d="M9.29373 5.90112L9.32494 5.93268L10.9415 7.78223L11.3409 8.24304C11.784 8.83641 12.0462 9.58128 12.0524 10.3893C12.0524 10.6039 12.0399 10.8122 12.0025 11.0142C12.0025 11.0205 11.9962 11.0268 11.9962 11.0395L11.99 11.0458C11.6904 12.7312 10.2424 14 8.48859 14C8.00177 14 7.53991 13.899 7.11549 13.7286C6.68484 13.5392 6.29787 13.2741 5.96708 12.9458L2.6966 9.63809C2.43447 9.37297 2.43447 8.94372 2.6966 8.67229C2.95874 8.40716 3.38315 8.41348 3.64529 8.6786L2.90881 7.92111C2.64667 7.65598 2.64667 7.22042 2.90881 6.9553C3.17095 6.69018 3.6016 6.69018 3.86374 6.9553L3.30202 6.38718C3.03988 6.12206 3.03988 5.69281 3.30202 5.42137C3.56415 5.15625 3.98233 5.15625 4.2507 5.42769L4.83115 6.01475C4.56901 5.74962 4.56901 5.31406 4.83115 5.04894C5.09329 4.78382 5.5177 4.78382 5.78608 5.04894L9.61828 8.92479C9.83673 8.86166 8.5198 7.07524 8.29511 6.78486C8.08915 6.50712 8.12035 6.10312 8.38249 5.86956C8.65087 5.636 9.04408 5.65494 9.29373 5.90112Z"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.82495 6.02136L7.0656 8.28753"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.87622 6.98059L6.11687 9.24045"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.92761 7.9397L5.16202 10.2059"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0921 13.6022C11.771 13.5265 13.1441 12.2829 13.4375 10.6543L13.4437 10.648C13.4437 10.6417 13.45 10.6354 13.45 10.6228C13.4874 10.4208 13.4999 10.2125 13.4999 9.99783C13.4937 9.18983 13.2378 8.44496 12.7884 7.85159L12.3889 7.39078L10.7724 5.54123L10.7412 5.50967C10.4916 5.25717 10.0983 5.24455 9.82373 5.48442C9.56159 5.71798 9.53038 6.12198 9.73635 6.39973"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.35111 5.81262L7.22766 4.67638C6.96553 4.41126 6.54111 4.41126 6.27273 4.67638C6.07925 4.87207 6.02932 5.16244 6.12294 5.40863"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.83447 2.77643L6.97802 2"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.90173 3.09187L8.29494 2.40381"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.77576 3.86875L9.39989 3.38269"
        stroke="#666666"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2844_144387">
        <rect width="16" height="16" rx="2" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const InitiativeIcon = (props) => (
  <Icon viewBox="0 0 25 25" {...props}>
    <path
      d="M5.42859 6.5C5.42859 5.94772 5.8763 5.5 6.42859 5.5H18.4286C18.9809 5.5 19.4286 5.94771 19.4286 6.5V18.5C19.4286 19.0523 18.9809 19.5 18.4286 19.5H6.42859C5.8763 19.5 5.42859 19.0523 5.42859 18.5V6.5Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path d="M15.9286 10.4998L11.1786 15L8.92859 12.4998" stroke="currentColor" strokeWidth="2" />
  </Icon>
);

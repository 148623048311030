import { Icon } from '@chakra-ui/react';

export const LocationIcon = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g id="Location">
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29591 15.08C10.0708 16.0491 10.9601 16.8463 11.6703 17.4072C11.7864 17.4989 11.8968 17.5836 12 17.6609C12.1032 17.5836 12.2135 17.4989 12.3297 17.4072C13.0399 16.8463 13.9292 16.0491 14.7041 15.08C15.7726 13.7442 16.5 12.2361 16.5 10.6668C16.5 8.04362 14.4356 6 11.9999 6C9.56434 6 7.5 8.04357 7.5 10.6668C7.5 12.2359 8.22734 13.744 9.29591 15.08ZM11.7795 19.9384C11.7795 19.9384 9.5313 18.5771 7.73383 16.329C6.50885 14.7974 5.5 12.8587 5.5 10.6668C5.5 6.9871 8.41228 4 11.9999 4C15.5876 4 18.5 6.9871 18.5 10.6668C18.5 12.8588 17.4912 14.7975 16.2662 16.329C14.4686 18.5771 12.2205 19.9384 12.2205 19.9384C12.0844 20.0205 11.9156 20.0205 11.7795 19.9384Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12C12.8284 12 13.5 11.3284 13.5 10.5C13.5 9.67157 12.8284 9 12 9C11.1716 9 10.5 9.67157 10.5 10.5C10.5 11.3284 11.1716 12 12 12Z"
          fill="currentColor"
        />
      </g>
    </g>
  </Icon>
);

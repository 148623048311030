import { Icon } from '@chakra-ui/react';

export const WeightageTracking = (props) => (
  <Icon viewBox="0 0 18 18" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M3.375 13.125L7.5 9L9.75 11.25L14.25 6.375"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7907 12.6141C14.7171 11.3863 14.3269 10.1984 13.6582 9.16611C12.9894 8.1338 12.0647 7.29221 10.9742 6.72324C9.88367 6.15428 8.66443 5.87732 7.43513 5.91931C6.20582 5.96131 5.00833 6.32084 3.95918 6.96291"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </Icon>
);

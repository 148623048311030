import { Icon } from '@chakra-ui/react';

export const MyTeamDevelop = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M18.7 4H16.3C15.58 4 15 4.58 15 5.3V7H13.3C12.58 7 12 7.58 12 8.3V10H10.3C9.58 10 9 10.58 9 11.3V12.56C9.33 12.41 9.7 12.3 10.1 12.3C11.05 12.3 11.89 12.84 12.34 13.63C12.69 13.41 13.08 13.3 13.5 13.3C14.53 13.3 15.4 14.02 15.69 15H18.7C19.42 15 20 14.42 20 13.7V5.3C20 4.58 19.42 4 18.7 4ZM17.99 10.87C17.96 10.95 17.89 11 17.82 11H17.26V13H15.76V11H15.2C15.12 11 15.06 10.95 15.03 10.87C15 10.79 15.02 10.7 15.07 10.65L16.38 9.06C16.45 8.98 16.57 8.98 16.65 9.06L17.96 10.65C18.01 10.71 18.03 10.8 18 10.87H17.99Z"
      fill="currentColor"
    />
    <path
      d="M6.7 17.1C7.2 17.1 7.7 16.8 7.9 16.3C7.7 15.9 7.6 15.4 7.6 15V14.6C7.4 14.4 7.1 14.2 6.7 14.2C6 14.2 5.4 14.8 5.4 15.6C5.3 16.5 5.9 17.1 6.7 17.1ZM6 19.2C6 18.7 6.2 18.3 6.5 17.9C5.5 17.9 4 18.4 4 19.3V20H6V19.2ZM10.1 16.7C11 16.7 11.7 16 11.7 15C11.7 14.1 11 13.3 10.1 13.3C9.2 13.3 8.5 14 8.5 15C8.5 15.9 9.2 16.7 10.1 16.7ZM10.1 17.5C9.1 17.5 7 18.1 7 19.2V20H13.2V19.2C13.2 18.1 11.1 17.5 10.1 17.5ZM13.7 17.9C14 18.3 14.2 18.7 14.2 19.2V20H16.2V19.3C16.1 18.4 14.6 17.9 13.7 17.9ZM12.6 15C12.6 15.5 12.5 15.9 12.3 16.3C12.5 16.8 13 17.1 13.5 17.1C14.2 17.1 14.8 16.5 14.8 15.7C14.8 14.9 14.2 14.3 13.5 14.3C13.2 14.3 12.9 14.4 12.6 14.7V15Z"
      fill="currentColor"
    />
  </Icon>
);

import { sendRequest } from 'api/sendRequest';
import { getTSForGoalsOneOnOneTasks } from 'screens/home/summary/helpers';

import { endpoints } from 'constants/endpoints';

export const getMySummaryInitiativesApi = ({ limit = 3, offset = 0, employeeId }) => {
  const { endTS, startTS } = getTSForGoalsOneOnOneTasks();
  // still BE has this weird API where data in
  // both query params and in body is required
  // (┛◉Д◉)┛彡┻━┻
  // ಥ╭╮ಥ
  const queryParams = {
    resourceType: 'LIST',
    isPrivateGoalRequired: false,
    endTS,
    startTS,
    limit,
    offset,
  };
  const url = endpoints.home.mySummary.goals;
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams,
    body: {
      orderBy: null,
      filter: [
        {
          type: 'NAME',
          operatorType: 'IN',
          data: [employeeId],
        },
        {
          type: 'TYPE',
          operatorType: 'IN',
          data: ['TASK'],
        },
      ],
      pageType: null,
    },
  });
};

export function getMySummaryInitiativesTableDataApi({ resourceType, attributeFilter, pageType = 'HOME_PAGE' }) {
  const url = endpoints.analytics.initiative.list;

  const queryParams = {
    pageType,
    resourceType,
  };

  const body = {
    attributeFilter,
  };

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams,
    body,
  });
}

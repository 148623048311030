import { ADD_ORG_DATA } from 'actions/actionTypes';

const initialState = {};

export default function orgData(state = initialState, action) {
  switch (action.type) {
    case ADD_ORG_DATA: {
      return { ...state, ...action.data };
    }
    default:
      return state;
  }
}

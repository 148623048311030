import { OPEN_DATE_PICKER, CLOSE_DATE_PICKER } from 'actions/actionTypes';

export const initialState = {
  isOpen: false,
  onChange: null,
  initialDate: null,
  autoOk: true,
  disablePast: false,
  clearable: true,
  onClose: null,
  // whether the datepicker should set time to EOD on date select or keep as is supplied on initial datetime.
  setEODTime: true,
  setSODTime: false,
  minDate: null,
  maxDate: null,
};

const datePicker = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DATE_PICKER:
      return {
        ...initialState,
        isOpen: true,
        onChange: action.data.onChange,
        initialDate: action.data.initialDate,
        clearable: action.data.clearable,
        autoOk: action.data.autoOk,
        setEODTime: action.data.setEODTime,
        setSODTime: action.data.setSODTime,
        minDate: action.data.minDate,
        maxDate: action.data.maxDate,
      };

    case CLOSE_DATE_PICKER:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default datePicker;

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { REVIEW_PROVIDER_TYPES } from 'constants/ProjectConstants';

export const REVIEW_CYCLE_STEPS_V1 = {
  NAME: 'NAME',
  REVIEWEES: 'REVIEWEES',
  REVIEWERS: 'REVIEWERS',
  ELEMENTS: 'ELEMENTS',
  FORM_FILLING_EXPERIENCE: 'FORM_FILLING_EXPERIENCE',
  CALIBRATION: 'CALIBRATION',
  SHARE_RESULTS: 'SHARE_RESULTS',
  TIMELINES: 'TIMELINES',
  VALIDATION: 'VALIDATION',
  SUMMARY: 'SUMMARY',
  TEMPLATE_METADATA: 'TEMPLATE_METADATA',
};

export const REVIEWERS_PEER_POOLS = {
  SENIORS: 'SENIORS',
  JUNIORS: 'JUNIORS',
  OUTSIDE_TEAM: 'OUTSIDE_TEAM',
  PRIMARY_TEAM: 'PRIMARY_TEAM',
};

export const REVIEW_CYCLE_STEPS_METADATA = {
  [REVIEW_CYCLE_STEPS_V1.NAME]: {
    title: 'Review Details',
  },
  [REVIEW_CYCLE_STEPS_V1.REVIEWEES]: {
    title: 'Reviewees',
  },
  [REVIEW_CYCLE_STEPS_V1.REVIEWERS]: {
    title: 'Reviewers',
  },
  [REVIEW_CYCLE_STEPS_V1.ELEMENTS]: {
    title: 'Elements',
  },
  [REVIEW_CYCLE_STEPS_V1.FORM_FILLING_EXPERIENCE]: {
    title: 'Form Filling Experience',
  },
  [REVIEW_CYCLE_STEPS_V1.CALIBRATION]: {
    title: 'Calibration',
  },
  [REVIEW_CYCLE_STEPS_V1.SHARE_RESULTS]: {
    title: 'Share Results',
  },
  [REVIEW_CYCLE_STEPS_V1.TIMELINES]: {
    title: 'Timelines',
  },
  [REVIEW_CYCLE_STEPS_V1.VALIDATION]: {
    title: 'Validation',
  },
  [REVIEW_CYCLE_STEPS_V1.SUMMARY]: {
    title: 'Summary',
  },
};

export const REVIEW_FORM_STAGE = {
  DRAFT: 'DRAFT',
  IN_PROGRESS: 'IN_PROGRESS',
  PUBLISHED: 'PUBLISHED',
};

export const ALLOWED_STEPS_REVIEW_IN_PROGRESS = [REVIEW_CYCLE_STEPS_V1.TIMELINES, REVIEW_CYCLE_STEPS_V1.SUMMARY];
export const ALLOWED_STEPS_REVIEW_PUBLISHED = [REVIEW_CYCLE_STEPS_V1.SUMMARY];

export const REVIEW_CYCLE_STEPS_ORDER = [
  REVIEW_CYCLE_STEPS_V1.NAME,
  REVIEW_CYCLE_STEPS_V1.REVIEWEES,
  REVIEW_CYCLE_STEPS_V1.REVIEWERS,
  REVIEW_CYCLE_STEPS_V1.ELEMENTS,
  REVIEW_CYCLE_STEPS_V1.FORM_FILLING_EXPERIENCE,
  REVIEW_CYCLE_STEPS_V1.CALIBRATION,
  REVIEW_CYCLE_STEPS_V1.SHARE_RESULTS,
  REVIEW_CYCLE_STEPS_V1.TIMELINES,
  REVIEW_CYCLE_STEPS_V1.VALIDATION,
  REVIEW_CYCLE_STEPS_V1.SUMMARY,
];

export const REVIEW_PROVIDER_TYPE_LABELS = {
  [REVIEW_PROVIDER_TYPES.SELF]: 'Self',
  [REVIEW_PROVIDER_TYPES.MANAGER]: 'Manager',
  [REVIEW_PROVIDER_TYPES.PEER]: 'Peer',
  [REVIEW_PROVIDER_TYPES.UPWARD]: 'Upward',
  [REVIEW_PROVIDER_TYPES.DLM]: 'Dotted Line Manager',
};

const initialState = {
  step: REVIEW_CYCLE_STEPS_V1.NAME,
  lastEnabledSection: REVIEW_CYCLE_STEPS_V1.NAME,

  reviewCycleId: null,
  currentStage: REVIEW_FORM_STAGE.DRAFT,
  isDraft: true,
  isPublished: false,

  // data for each step
  // review type and details
  reviewTypeAndDetails: {},

  // reviewees
  reviewees: [],
  isRevieweesCustomViewsSettings: false,
  isRevieweesOverlappingEmployees: false,
  isAllEmployeesSelected: true,
  // all the employees of org
  totalEmployeeCount: 0,
  // the applicable employees for review
  selectedEmployeeCount: 0,

  // reviewers
  reviewers: [],

  // elements
  elements: [],

  // form filling experience
  formFillingSettings: [],

  // calibration
  calibrationScoreSettings: {},
  gridDefinition: {},
  // ui only calibration grid metadata
  gridMetaData: {
    boxCount: 0,
    rowsCount: 0,
    columnsCount: 0,
  },

  // timelines
  timelines: [],

  // share results
  shareResults: [],

  // validations
  validations: {
    hasEmployeesWithMultipleViews: false,
    isManagerMissingAcknowledged: true,
    isGoalsMissingAcknowledged: true,
    isGradesMissingAcknowledged: true,
  },
};

export const reviewCreation = createSlice({
  name: 'reviewCreation',
  initialState,
  reducers: {
    resetReviewCreation: () => initialState,
    setReviewCreationStep: (state, action) => {
      state.step = action.payload;
    },
    setReviewCycleId: (state, action) => {
      state.reviewCycleId = action.payload;
    },
    // reviewCreationGoToNextStep: (state) => {
    //   const currentIndex = REVIEW_CYCLE_STEPS_ORDER.indexOf(state.step);
    //   if (currentIndex === REVIEW_CYCLE_STEPS_ORDER.length - 1) {
    //     return;
    //   }

    //   const nextStep = REVIEW_CYCLE_STEPS_ORDER[currentIndex + 1];
    //   state.step = nextStep;
    // },
    // reviewCreationGoToPrevStep: (state) => {
    //   const currentIndex = REVIEW_CYCLE_STEPS_ORDER.indexOf(state.step);
    //   if (currentIndex === 0) {
    //     return;
    //   }

    //   const previousStep = REVIEW_CYCLE_STEPS_ORDER[currentIndex - 1];
    //   state.step = previousStep;
    // },

    // review type and details
    setReviewTypeAndDetails: (state, action) => {
      state.reviewTypeAndDetails = { ...state.reviewTypeAndDetails, ...action.payload };
    },

    setReviewMetaData: (state, action) => {
      const { currentStage, lastEnabledSection } = action.payload;
      state.currentStage = currentStage;
      state.isDraft = currentStage === REVIEW_FORM_STAGE.DRAFT;
      state.isPublished = currentStage === REVIEW_FORM_STAGE.PUBLISHED;
      state.lastEnabledSection = lastEnabledSection;
    },
    // reviewees
    setReviewees: (state, action) => {
      if (action.payload.length < 2) {
        state.isRevieweesCustomViewsSettings = false;
        state.isRevieweesOverlappingEmployees = false;
      }
      // else {
      //   state.isRevieweesOverlappingEmployees = true;
      // }
      state.isAllEmployeesSelected = false;
      state.reviewees = action.payload;
    },

    setIsRevieweesCustomViewsSettings: (state, action) => {
      state.isRevieweesCustomViewsSettings = action.payload;
    },

    setIsRevieweesOverlappingEmployees: (state, action) => {
      state.isRevieweesOverlappingEmployees = action.payload;
    },

    setIsAllEmployeesSelected: (state, action) => {
      state.isAllEmployeesSelected = action.payload;
      if (action.payload) {
        state.isRevieweesCustomViewsSettings = false;
        state.isRevieweesOverlappingEmployees = false;
        state.reviewees = [];
      }
    },

    setTotalEmployeeCount: (state, action) => {
      state.totalEmployeeCount = action.payload;
    },

    setSelectedEmployeeCount: (state, action) => {
      state.selectedEmployeeCount = action.payload;
    },

    // reviewers
    setReviewers: (state, action) => {
      state.reviewers = action.payload;
    },

    setReviewerViewSettings: (state, action) => {
      const { feedbackFormId, settings } = action.payload;

      // TODO: v1 or later -> update the target item plus any other view wise setting that copies this setting
      // const targetItem = state.reviewers.find(
      //   (r) => r.feedbackFormId === feedbackFormId || r.settingSameAsFormId === feedbackFormId
      // );
      const targetItem = state.reviewers.find((r) => r.feedbackFormId === feedbackFormId);
      // if (!targetItem.length) {
      //   return;
      // }
      targetItem.reviewerSettings = {
        ...targetItem.reviewerSettings,
        ...settings,
      };
    },

    //   state.reviewers[index].reviewerSettings = {
    //     ...state.reviewers[index].reviewerSettings,
    //     ...settings
    //   };
    // },
    setReviewerSameSettingAsFeedbackFormId: (state, action) => {
      const { feedbackFormId, settingSameAsFormId } = action.payload;
      const index = state.reviewers.findIndex((r) => r.feedbackFormId === feedbackFormId);
      if (index === -1) {
        return;
      }
      state.reviewers[index].settingSameAsFormId = settingSameAsFormId;
      // if (settingSameAsFormId === null) {
      //   return;
      // }
      // const sourceItem = state.reviewers.find((r) => r.cohort.id === settingSameAsFormId);
      // if (!sourceItem) {
      //   return;
      // }
      // // copy all settings from source item to target item
      // state.reviewers[index].reviewerSettings = sourceItem.reviewerSettings;
    },

    // elements
    setElements: (state, action) => {
      state.elements = action.payload;
    },

    setElementsTemplateSettings: (state, action) => {
      const { feedbackFormId, settings } = action.payload;
      const { template, providerType } = settings;

      // TODO: v1 or later -> update the target item plus any other view wise setting that copies this setting
      // const targetItem = state.elements.find(
      //   (r) => r.feedbackFormId === feedbackFormId || r.settingSameAsFormId === feedbackFormId
      // );
      const targetItem = state.elements.find((r) => r.feedbackFormId === feedbackFormId);
      // if (!targetItem.length) {
      //   return;
      // }

      const matchingTemplateIndex = targetItem.elementSettings.providerTypeToTemplateMap.findIndex(
        (r) => r.providerType === providerType
      );
      targetItem.elementSettings.providerTypeToTemplateMap[matchingTemplateIndex].template = template;
    },
    setElementsScoreSettings: (state, action) => {
      const { feedbackFormId, settings } = action.payload;

      // TODO: v1 or later -> update the target item plus any other view wise setting that copies this setting
      // const targetItem = state.elements.find(
      //   (r) => r.feedbackFormId === feedbackFormId || r.settingSameAsFormId === feedbackFormId
      // );
      const targetItem = state.elements.find((r) => r.feedbackFormId === feedbackFormId);
      // if (!targetItem.length) {
      //   return;
      // }

      targetItem.elementSettings.elementScoreSettings = {
        ...targetItem.elementSettings.elementScoreSettings,
        ...settings,
      };
    },
    setElementsSameSettingAsFeedbackFormId: (state, action) => {
      const { feedbackFormId, settingSameAsFormId } = action.payload;
      const index = state.elements.findIndex((r) => r.feedbackFormId === feedbackFormId);
      if (index === -1) {
        return;
      }
      state.elements[index].settingSameAsFormId = settingSameAsFormId;
    },

    // form filling experience
    setFormFillings: (state, action) => {
      state.formFillingSettings = action.payload;
    },
    setFormFillingsSameSettingAsFeedbackFormId: (state, action) => {
      const { feedbackFormId, settingSameAsFormId } = action.payload;
      const index = state.formFillingSettings.findIndex((r) => r.feedbackFormId === feedbackFormId);
      if (index === -1) {
        return;
      }
      state.formFillingSettings[index].settingSameAsFormId = settingSameAsFormId;
    },
    setSelfFormFillingScoreSettings: (state, action) => {
      const { feedbackFormId, settings } = action.payload;

      // update the target item plus any other view wise setting that copies this setting
      const matchingTargetItems = state.formFillingSettings.filter((r) => r.feedbackFormId === feedbackFormId);
      if (matchingTargetItems.length === 0) {
        return;
      }
      matchingTargetItems.forEach((settingItem) => {
        settingItem.formFillingScoreSettings.selfFormFillingScoreSettings = {
          ...settingItem.formFillingScoreSettings.selfFormFillingScoreSettings,
          ...settings,
        };
      });
    },
    setManagerFormFillingScoreSettings: (state, action) => {
      const { feedbackFormId, settings } = action.payload;

      // update the target item plus any other view wise setting that copies this setting
      const matchingTargetItems = state.formFillingSettings.filter((r) => r.feedbackFormId === feedbackFormId);
      if (matchingTargetItems.length === 0) {
        return;
      }
      matchingTargetItems.forEach((settingItem) => {
        settingItem.formFillingScoreSettings.managerFormFillingScoreSettings = {
          ...settingItem.formFillingScoreSettings.managerFormFillingScoreSettings,
          ...settings,
        };
      });
    },
    setPeerFormFillingScoreSettings: (state, action) => {
      const { feedbackFormId, settings } = action.payload;

      // update the target item plus any other view wise setting that copies this setting
      const matchingTargetItems = state.formFillingSettings.filter((r) => r.feedbackFormId === feedbackFormId);
      if (matchingTargetItems.length === 0) {
        return;
      }
      matchingTargetItems.forEach((settingItem) => {
        settingItem.formFillingScoreSettings.peerFormFillingScoreSettings = {
          ...settingItem.formFillingScoreSettings.peerFormFillingScoreSettings,
          ...settings,
        };
      });
    },

    // calibration
    setCalibrationScoreSettings: (state, action) => {
      let { answerOptions } = action.payload;
      answerOptions = answerOptions.map((item, index) => ({
        ...item,
        displayOrder: index + 1,
      }));

      state.calibrationScoreSettings = {
        ...state.calibrationScoreSettings,
        ...action.payload,
        answerOptions,
      };
    },
    setGridDefinition: (state, action) => {
      const rowsCount = Object.keys(action.payload).length;
      const columnsCount = Object.keys(action.payload['1']).length;
      const isGridDimensionsChanged =
        state.gridMetaData.rowsCount !== rowsCount || state.gridMetaData.columnsCount !== columnsCount;

      state.gridMetaData.rowsCount = rowsCount;
      state.gridMetaData.columnsCount = columnsCount;
      state.gridMetaData.boxCount = rowsCount * columnsCount;

      // don't overwrite in case of demension change and there is already a grid definition for this dimension
      if (isGridDimensionsChanged && !!state.gridDefinition[`${rowsCount}x${columnsCount}`]) {
        return;
      }
      state.gridDefinition[`${rowsCount}x${columnsCount}`] = action.payload;
    },

    // timelines
    setTimelines: (state, action) => {
      state.timelines = action.payload;
    },
    setTimelinesSettings: (state, action) => {
      const { feedbackFormId, settings } = action.payload;
      const { responseProviderType, ...updateSettings } = settings;
      // TODO: v1 or later -> update the target item plus any other view wise setting that copies this setting
      // const targetItemIndex = state.timelines.findIndex(
      //   (r) => r.feedbackFormId === feedbackFormId || r.settingSameAsFormId === feedbackFormId
      // );
      const targetItemIndex = state.timelines.findIndex((r) => r.feedbackFormId === feedbackFormId);

      const targetReviewProviderObjectIndex = state.timelines[targetItemIndex].reviewTimelineSettingList.findIndex(
        (r) => r.responseProviderType === responseProviderType
      );

      const targetReviewProviderObject =
        state.timelines[targetItemIndex].reviewTimelineSettingList[targetReviewProviderObjectIndex];

      state.timelines[targetItemIndex].reviewTimelineSettingList[targetReviewProviderObjectIndex] = {
        ...targetReviewProviderObject,
        ...updateSettings,
      };
    },
    setTimelinesSameSettingAsFeedbackFormId: (state, action) => {
      const { feedbackFormId, settingSameAsFormId } = action.payload;
      const index = state.timelines.findIndex((r) => r.feedbackFormId === feedbackFormId);
      if (index === -1) {
        return;
      }
      state.timelines[index].settingSameAsFormId = settingSameAsFormId;
    },

    // share results
    setShareResults: (state, action) => {
      state.shareResults = action.payload;
    },
    setShareResultsSameSettingAsFeedbackFormId: (state, action) => {
      const { feedbackFormId, settingSameAsFormId } = action.payload;
      const index = state.shareResults.findIndex((r) => r.feedbackFormId === feedbackFormId);
      if (index === -1) {
        return;
      }
      state.shareResults[index].settingSameAsFormId = settingSameAsFormId;
    },
    setShareResultsSettings: (state, action) => {
      const { feedbackFormId, settings } = action.payload;

      // update the target item plus any other view wise setting that copies this setting
      const matchingTargetItems = state.shareResults.filter((r) => r.feedbackFormId === feedbackFormId);
      if (matchingTargetItems.length === 0) {
        return;
      }
      matchingTargetItems.forEach((settingItem) => {
        settingItem.shareResultsSettings = {
          ...settingItem.shareResultsSettings,
          ...settings,
        };
      });
    },

    // validations
    setValidationAcknowledgements: (state, action) => {
      const { key, value } = action.payload;
      state.validations[key] = value;
    },
  },
});

export const {
  resetReviewCreation,
  setReviewCreationStep,
  setReviewCycleId,
  // reviewCreationGoToNextStep,
  // reviewCreationGoToPrevStep,
  setReviewTypeAndDetails,
  setReviewMetaData,

  setReviewees,
  setIsRevieweesCustomViewsSettings,
  setIsRevieweesOverlappingEmployees,
  setIsAllEmployeesSelected,
  setTotalEmployeeCount,
  setSelectedEmployeeCount,

  setReviewers,
  setReviewerViewSettings,
  setReviewerSameSettingAsFeedbackFormId,

  setElements,
  setElementsTemplateSettings,
  setElementsScoreSettings,
  setElementsSameSettingAsFeedbackFormId,

  setFormFillings,
  setFormFillingsSameSettingAsFeedbackFormId,
  setSelfFormFillingScoreSettings,
  setManagerFormFillingScoreSettings,
  setPeerFormFillingScoreSettings,

  setCalibrationScoreSettings,
  setGridDefinition,

  setTimelines,
  setTimelinesSettings,
  setTimelinesSameSettingAsFeedbackFormId,

  setShareResults,
  setShareResultsSameSettingAsFeedbackFormId,
  setShareResultsSettings,

  setValidationAcknowledgements,
} = reviewCreation.actions;

export default reviewCreation.reducer;

// selectors
export const useReviewCreationData = () => useSelector((state) => state.reviewCreation);

// selector for each step of review creation
// review type and details
export const useReviewTypeAndDetails = () => useSelector((state) => state.reviewCreation.reviewTypeAndDetails);

// reviewees
export const useReviewees = () => useSelector((state) => state.reviewCreation.reviewees);

export const useIsRevieweesCustomViewsSettings = () =>
  useSelector((state) => state.reviewCreation.isRevieweesCustomViewsSettings);

export const useIsReviewsOverlappingEmployees = () =>
  useSelector((state) => state.reviewCreation.isRevieweesOverlappingEmployees);

export const useIsAllEmployeesSelected = () => useSelector((state) => state.reviewCreation.isAllEmployeesSelected);

export const useTotalEmployeeCount = () => useSelector((state) => state.reviewCreation.totalEmployeeCount);

export const useSelectedEmployeeCount = () => useSelector((state) => state.reviewCreation.selectedEmployeeCount);

// reviewers
export const useReviewers = () => useSelector((state) => state.reviewCreation.reviewers);
export const useReviewersSameSetting = ({ feedbackFormId }) => {
  const reviewers = useReviewers();
  const index = reviewers.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }
  return reviewers[index].settingSameAsFormId;
};

export const useReviewersSettings = ({ feedbackFormId }) => {
  const reviewers = useReviewers();
  const index = reviewers.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }
  return reviewers[index].reviewerSettings;
};

// elements

export const useElements = () => useSelector((state) => state.reviewCreation.elements);

export const useElementsSameSetting = ({ feedbackFormId }) => {
  const elements = useElements();
  const index = elements.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }
  return elements[index].settingSameAsFormId;
};

export const useElementsSettings = ({ feedbackFormId }) => {
  const elements = useElements();
  const index = elements.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }
  return elements[index].elementSettings;
};

export const useElementsTemplateSettings = ({ feedbackFormId }) => {
  const elements = useElementsSettings({ feedbackFormId });

  return elements.providerTypeToTemplateMap;
};

export const useElementsScoreSettings = ({ feedbackFormId }) => {
  const elements = useElementsSettings({ feedbackFormId });

  return elements?.elementScoreSettings ?? {};
};

// form filling experience
export const useFormFillings = () => useSelector((state) => state.reviewCreation.formFillingSettings);

export const useFormFillingsByFeedbackFormId = ({ feedbackFormId }) => {
  const formFillingSettings = useFormFillings();
  return formFillingSettings.find((r) => r.feedbackFormId === feedbackFormId);
};

export const useFormFillingsSameSetting = ({ feedbackFormId }) => {
  const formFillingSettings = useFormFillings();
  const index = formFillingSettings.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }
  return formFillingSettings[index].settingSameAsFormId;
};

export const useFormFillingsSettings = ({ feedbackFormId }) => {
  const formFillingSettings = useFormFillings();
  const index = formFillingSettings.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }
  return formFillingSettings[index].formFillingScoreSettings;
};

export const useSelfFormFillingScoreSettings = ({ feedbackFormId }) => {
  const settings = useFormFillingsSettings({ feedbackFormId });

  return settings?.selfFormFillingScoreSettings ?? {};
};

export const useManagerFormFillingScoreSettings = ({ feedbackFormId }) => {
  const settings = useFormFillingsSettings({ feedbackFormId });

  return settings?.managerFormFillingScoreSettings ?? {};
};

export const usePeerFormFillingScoreSettings = ({ feedbackFormId }) => {
  const settings = useFormFillingsSettings({ feedbackFormId });

  return settings?.peerFormFillingScoreSettings ?? {};
};

// calibration

export const useCalibrationScoreSettings = () => useSelector((state) => state.reviewCreation.calibrationScoreSettings);

export const useGridDefinitionMetaData = () => useSelector((state) => state.reviewCreation.gridMetaData);

export const useGridDefinition = () => {
  const { rowsCount, columnsCount } = useGridDefinitionMetaData();

  return useSelector((state) => state.reviewCreation.gridDefinition[`${rowsCount}x${columnsCount}`]);
};

// timelines
export const useTimelines = () => useSelector((state) => state.reviewCreation.timelines);

export const useTimelinesSameSetting = ({ feedbackFormId }) => {
  const timelines = useTimelines();
  const index = timelines.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }
  // todo: vikalp ask BE to make this name consistent among steps
  return timelines[index].settingSameAsFormId;
};

export const useReviewTimelineSettingList = ({ feedbackFormId }) => {
  const timelines = useTimelines();
  const index = timelines.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }
  const { reviewTimelineSettingList } = timelines[index];

  return reviewTimelineSettingList;
};

export const useTimelinesProviderTypeSettings = ({ feedbackFormId, responseProviderType }) => {
  const sourceTimelineSettings = useReviewTimelineSettingList({ feedbackFormId });
  const matchingTimelineSettings = sourceTimelineSettings.find((r) => r.responseProviderType === responseProviderType);
  return matchingTimelineSettings;
};

// share results
export const useShareResults = () => useSelector((state) => state.reviewCreation.shareResults);

export const useShareResultsSameSetting = ({ feedbackFormId }) => {
  const shareResults = useShareResults();
  const index = shareResults.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }

  return shareResults[index].settingSameAsFormId;
};

export const useShareResultsSettings = ({ feedbackFormId }) => {
  const shareResults = useShareResults();
  const index = shareResults.findIndex((r) => r.feedbackFormId === feedbackFormId);
  if (index === -1) {
    return null;
  }

  return shareResults[index].shareResultsSettings;
};

// validations
export const useValidationAcknowledgements = () => useSelector((state) => state.reviewCreation.validations);

import { getUserL2Access, selectedLoggedInUsersAccesses } from 'Deer/helpers/getUserAccess';
import { selectEnabledFeatures } from 'selectors/enabledFeatures';
import store from 'store.js';

import { FEATURE_KEYS } from 'constants/ProjectConstants';

import { checkNewNavEnabled } from './featureFlags';

export const checkModuleEnabled = (moduleKey) => {
  if (!FEATURE_KEYS[moduleKey]) {
    console.error(`Module named '${moduleKey}' is not a valid module key. Not found in FEATURE_KEYS`);
    return false;
  }
  const enabledFeatures = checkNewNavEnabled()
    ? selectedLoggedInUsersAccesses(store.getState()).enabledFeatures
    : selectEnabledFeatures(store.getState());
  return enabledFeatures.includes(moduleKey);
};

export const checkAnyTeamPageModuleEnabled = () => {
  const enabledFeatures = checkNewNavEnabled()
    ? selectedLoggedInUsersAccesses(store.getState()).enabledFeatures
    : selectEnabledFeatures(store.getState());
  return enabledFeatures.includes(FEATURE_KEYS.TASK) || enabledFeatures.includes(FEATURE_KEYS.GOAL);
};

export const checkGoalModuleEnabled = () => {
  const enabledFeatures = selectEnabledFeatures(store.getState());
  return checkNewNavEnabled() ? getUserL2Access().hasGoalAccess : enabledFeatures.includes(FEATURE_KEYS.GOAL);
};

export const checkTaskModuleEnabled = () => {
  const enabledFeatures = selectEnabledFeatures(store.getState());
  return enabledFeatures.includes(FEATURE_KEYS.TASK);
};

export const checkReviewModuleEnabled = () => {
  const enabledFeatures = selectEnabledFeatures(store.getState());
  return checkNewNavEnabled() ? getUserL2Access().hasReviewAccess : enabledFeatures.includes(FEATURE_KEYS.REVIEW);
};

export const checkFeedbackModuleEnabled = () => {
  const enabledFeatures = selectEnabledFeatures(store.getState());
  return checkNewNavEnabled() ? getUserL2Access().hasFeedbackAccess : enabledFeatures.includes(FEATURE_KEYS.FEEDBACK);
};

export const checkEngagementModuleEnabled = () => {
  const enabledFeatures = selectEnabledFeatures(store.getState());
  return checkNewNavEnabled()
    ? getUserL2Access().hasEngagementAccess
    : enabledFeatures.includes(FEATURE_KEYS.ENGAGEMENT);
};

export const checkOneOnOneModuleEnabled = () => {
  const enabledFeatures = selectEnabledFeatures(store.getState());
  return checkNewNavEnabled() ? getUserL2Access().hasOneOnOneAccess : enabledFeatures.includes(FEATURE_KEYS.CHECK_IN);
};

export const checkLevelUpModuleEnabled = () => {
  const enabledFeatures = selectEnabledFeatures(store.getState());
  return enabledFeatures.includes(FEATURE_KEYS.LEVEL_UP);
};

import { createSlice, current } from '@reduxjs/toolkit';

import { GOAL_MEASUREMENT_TYPES, OKR_PROGRESS_TYPES, OKR_TYPES } from 'constants/ProjectConstants';
import { getCreatableObjectiveLevelList } from 'constants/helper';

const initialState = {
  okrDetails: {
    uuid: null,
    text: '',
    description: '',
    type: OKR_TYPES.OBJECTIVE,
    parentPost: null,
    uiKey: null,
    toIds: [],
    dueTS: null,
    startTS: null,
    completionTS: null,
    measure: GOAL_MEASUREMENT_TYPES[1]?.code,
    measureType: '',
    target: 100,
    /* 
      removing "actual" to not send it mistakenly in future. BE handles null cases. 
      TODO: figure out all null values and remove from initialState.
    */
    startingProgressValue: 0,
    goalProgressLevel: null,
    goalClosureStatus: null,
    visibilityType: null,
    timeBoundDetails: null,
    objectiveLevel: 'COMPANY',
    okrProgressDetailsInfo: null,
    progressType: OKR_PROGRESS_TYPES.MANUAL,
    departmentName: null,
    departmentId: null,
    parentMeasure: null,
    parentMeasureType: null,
    parentProgressType: null,
    tagKeyList: [],
  },
  modifiedFields: [],
  initializedState: null,
};

export const OKRCreationPanelSlice = createSlice({
  name: 'OKRCreationPanelSlice',
  initialState,
  reducers: {
    initialize: (state, action) => {
      const initializedState = {
        ...initialState.okrDetails,
        ...action.payload,
      };
      state.okrDetails = { ...initializedState };
      state.initializedState = { ...initializedState };
      state.modifiedFields = [];
    },
    updateOKR: (state, action) => {
      const currentState = current(state);
      const fieldsObject = { ...currentState.okrDetails, ...action.payload };

      state.modifiedFields = Object.keys(fieldsObject).filter(
        (key) => fieldsObject[key] !== currentState.initializedState[key]
      );
      state.okrDetails = { ...fieldsObject };
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
const { initialize, updateOKR } = OKRCreationPanelSlice.actions;

const initializeOKRCreationPanelState = (payload) => (dispatch, getState) => {
  /* read defaults based on access control from store and pass */

  const { orgData, loggedInEmployee, employeeGoalsAccessPrivileges } = getState();
  const { defaultGoalDueTS, defaultGoalStartTS, okrConfigData } = orgData;

  const startTS = payload?.timeBoundDetails?.startTS ?? defaultGoalStartTS;
  const dueTS = payload?.timeBoundDetails?.endTS ?? defaultGoalDueTS;

  const isObjective = payload?.type === OKR_TYPES.OBJECTIVE;

  const visibilityType = isObjective
    ? okrConfigData?.DEFAULT_OBJECTIVE_VISIBILITY?.value
    : okrConfigData?.DEFAULT_KR_VISIBILITY?.value;

  const {
    email,
    id,
    displayName,
    profilePhotoSrc: profilePhoto,
    designation,
    employeeFunction,
  } = loggedInEmployee?.data;

  const defaultOwner = {
    email,
    id,
    displayName,
    profilePhoto,
    designation,
    employeeFunction,
    ownerType: 'OWNER',
  };

  let progressType = isObjective
    ? okrConfigData?.DEFAULT_OBJECTIVE_PROGRESS?.value
    : okrConfigData?.DEFAULT_KR_PROGRESS?.value;

  let measureType = '';
  let measure = GOAL_MEASUREMENT_TYPES[1]?.code;

  if (payload?.parentProgressType === OKR_PROGRESS_TYPES.DERIVED_SUM) {
    progressType = OKR_PROGRESS_TYPES.MANUAL;
    measureType = payload?.parentMeasureType;
    measure = payload?.parentMeasure;
  } else if (progressType === OKR_PROGRESS_TYPES.DERIVED_AVG) {
    measureType = 'Progress';
  }

  /* get default objective level based on access */
  const objectiveLevelList = getCreatableObjectiveLevelList(employeeGoalsAccessPrivileges);
  /* this shouldn't be empty */
  const objectiveLevel = objectiveLevelList?.[0];

  const defaults = {
    startTS,
    dueTS,
    visibilityType,
    toIds: [defaultOwner],
    progressType,
    measureType,
    measure,
    objectiveLevel,
  };
  dispatch(initialize({ ...defaults, ...payload }));
};

export { updateOKR, initializeOKRCreationPanelState };

export default OKRCreationPanelSlice.reducer;

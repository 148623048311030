import { Icon } from '@chakra-ui/react';

export const DepartmentName = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <rect width="24" height="24" fill="white" />
    <path
      d="M10.1538 8.63509C10.3395 8.52791 10.5682 8.52791 10.7538 8.63509L11.6827 9.17137C11.8684 9.27855 11.9827 9.47663 11.9827 9.69099V10.7636C11.9827 10.9779 11.8684 11.176 11.6827 11.2832L10.7538 11.8195C10.5682 11.9266 10.3395 11.9266 10.1538 11.8195L9.22496 11.2832C9.03932 11.176 8.92496 10.9779 8.92496 10.7636V9.69099C8.92496 9.47663 9.03932 9.27855 9.22496 9.17137L10.1538 8.63509Z"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <path
      d="M7.92727 12.3472C8.11292 12.2401 8.34163 12.2401 8.52727 12.3472L9.45615 12.8835C9.64179 12.9907 9.75615 13.1888 9.75615 13.4031V14.4757C9.75615 14.6901 9.64179 14.8882 9.45615 14.9953L8.52727 15.5316C8.34163 15.6388 8.11292 15.6388 7.92727 15.5316L6.9984 14.9953C6.81276 14.8882 6.6984 14.6901 6.6984 14.4757V13.4031C6.6984 13.1888 6.81276 12.9907 6.9984 12.8835L7.92727 12.3472Z"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <path
      d="M12.3824 12.3472C12.568 12.2401 12.7967 12.2401 12.9824 12.3472L13.9112 12.8835C14.0969 12.9907 14.2112 13.1888 14.2112 13.4031V14.4757C14.2112 14.6901 14.0969 14.8882 13.9112 14.9953L12.9824 15.5316C12.7967 15.6388 12.568 15.6388 12.3824 15.5316L11.4535 14.9953C11.2678 14.8882 11.1535 14.6901 11.1535 14.4757V13.4031C11.1535 13.1888 11.2678 12.9907 11.4535 12.8835L12.3824 12.3472Z"
      stroke="currentColor"
      strokeWidth="0.8"
    />
    <rect x="3.5" y="4.5" width="17" height="14" rx="1.5" stroke="currentColor" />
    <path d="M16 11L18 11" stroke="currentColor" strokeLinecap="round" />
    <path d="M15 9L18 9" stroke="currentColor" strokeLinecap="round" />
  </Icon>
);

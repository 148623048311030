import { Icon } from '@chakra-ui/react';

export const ProgressingWell = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.6664 5.99993L6.99371 9.61146L5.33315 7.99996"
      stroke="currentColor"
      strokeWidth="2"
      fill="transparent"
    />
    <path
      d="M10.06 0.279595C10.74 0.45939 11.3799 0.718981 11.9799 1.07858C12.5799 1.43818 13.14 1.85762 13.64 2.35699C14.14 2.85636 14.5599 3.4157 14.92 4.01495C15.2599 4.61419 15.54 5.25332 15.72 5.93249C15.9 6.61165 16 7.31077 16 8.00988C16 8.3095 15.98 8.60912 15.94 8.92869C15.92 9.00861 15.92 9.10848 15.9 9.1884C15.86 9.48802 15.8 9.78764 15.72 10.0873C15.7 10.1672 15.68 10.2671 15.64 10.347C15.4599 10.9263 15.22 11.4855 14.92 12.0049C14.5599 12.6042 14.14 13.1635 13.64 13.6629C13.4 13.9025 13.12 14.1423 12.84 14.362C12.56 14.5817 12.2799 14.7615 11.9799 14.9413C11.3799 15.2808 10.74 15.5605 10.06 15.7403C9.45998 15.9001 8.85999 15.9799 8.24002 16H8.00007C7.6801 16 7.34002 15.98 7.02005 15.94C6.9801 15.94 6.94003 15.9201 6.90007 15.9201C6.5801 15.8802 6.24002 15.8003 5.94002 15.7203C5.26 15.5405 4.62006 15.2809 4.02007 14.9213C3.42007 14.5617 2.86002 14.1423 2.36003 13.6429C1.84006 13.1433 1.42009 12.584 1.06004 11.9848C0.720097 11.3855 0.440071 10.7464 0.260044 10.0672C0.0800222 9.36813 0 8.68896 0 7.98985C0 7.65033 0.0199775 7.29073 0.0800221 6.95122C0.119976 6.59162 0.199998 6.25211 0.28002 5.93254V5.91259L2.79997 6.59176C2.49997 7.67042 2.56002 8.82886 2.93992 9.88758C3.33992 10.9463 4.03991 11.8452 4.97985 12.4843C5.91978 13.1234 7.01977 13.4431 8.15982 13.4031C9.27978 13.3632 10.3798 12.9837 11.2798 12.3045C12.1798 11.6253 12.8398 10.6666 13.1598 9.58796C13.4798 8.5093 13.4798 7.35085 13.1199 6.29214C12.7598 5.23342 12.0799 4.29467 11.1598 3.63545C10.22 2.95628 9.11997 2.59671 7.99984 2.59671V0C8.31981 0 8.63978 0.0199524 8.95989 0.0599697C9.01993 0.0599697 9.07987 0.0799221 9.13991 0.0799221C9.45988 0.119826 9.75997 0.199674 10.06 0.279595Z"
      fill="currentColor"
    />
    <path
      d="M5.29987 3.31574C5.69987 3.07609 6.13981 2.8963 6.59986 2.77648L5.91996 0.259705C5.23993 0.439499 4.59999 0.719179 4 1.05869L5.29987 3.31574Z"
      fill="currentColor"
    />
    <path
      d="M4.1796 4.17467C3.83966 4.51418 3.55964 4.87378 3.31955 5.29322L1.05963 3.99487C1.41969 3.39563 1.83965 2.83628 2.33965 2.33691L4.1796 4.17467Z"
      fill="currentColor"
    />
  </Icon>
);

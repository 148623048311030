import { z } from 'zod';

export const AGENDA_LIBRARY_TYPE = ['THEME', 'LIFECYCLE_MOMENT'] as const;

export const AGENDA_THEME_TYPE = [
  'BELONGING',
  'COLLABORATION',
  'GROWTH',
  'MANAGER_EFFECTIVENESS',
  'PERFORMANCE',
] as const;

export const LIFECYCLE_MOMENT_TYPE = [
  'FIRST_WEEK',
  'EXIT',
  'FIRST_MONTH',
  'NEW_RESPONSIBILITIES',
  'POST_GOAL_SETTING',
  'POST_PERFORMANCE_REVIEW',
  'PROMOTION',
  'RETENTION',
  'SECOND_MONTH',
  'THIRD_MONTH',
] as const;

export type LibraryTypeI = {
  type: (typeof AGENDA_LIBRARY_TYPE)[number];
};

export const oneOnOneAgendaLibraryValidator = z
  .array(
    z.object({
      id: z.number().nullable(),
      agendaText: z.string().nullable(),
      relationType: z.enum(['MANAGER', 'SKIP_MANAGER', 'PEER', 'SKIP_REPORTEE', 'REPORTEE']).nullable(),
      theme: z.enum(AGENDA_THEME_TYPE).nullable(),
      lifecycleMoment: z.enum(LIFECYCLE_MOMENT_TYPE).nullable(),
      libraryAgendaType: z.enum(AGENDA_LIBRARY_TYPE).nullable(),
      themeDisplayText: z.string().nullable(),
      lifecycleMomentDisplayText: z.string().nullable(),
    })
  )
  .nullable();

export type OneOnOneAgendaLibraryI = z.infer<typeof oneOnOneAgendaLibraryValidator>;

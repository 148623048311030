import { Icon } from '@chakra-ui/react';

export const MyTeamGoals = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12 8.70001C12.2 8.70001 12.3 8.70001 12.4 8.70001L13.2 8.00001V6.80001C12.8 6.70001 12.4 6.70001 12 6.70001C9.10005 6.70001 6.80005 9.00001 6.80005 11.9C6.80005 12.4 6.90005 12.9 7.00005 13.3C7.30005 13.3 7.60005 13.4 7.90005 13.6C8.10005 13.2 8.50005 12.8 8.90005 12.6C8.80005 12.4 8.80005 12.2 8.80005 11.9C8.80005 10.1 10.2 8.70001 12 8.70001Z"
      fill="currentColor"
    />
    <path
      d="M16.1 10.6L15.2 11.5C15.2 11.6 15.2 11.8 15.2 11.9C15.2 12.6 15 13.2 14.7 13.7C15.3 14.1 15.7 14.8 15.8 15.5C16.7 14.6 17.3 13.3 17.3 11.9C17.3 11.4 17.2 11 17.1 10.6H16.1Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3001 4L13.9001 6.5V8.6L11.6001 10.8C11.1001 11.3 11.1001 12 11.6001 12.5C12.1001 13 12.9001 13 13.3001 12.5L15.7001 10.1H17.5001L20.0001 7.7H16.3001V4Z"
      fill="currentColor"
    />
    <path
      d="M6.70006 17.1C7.20006 17.1 7.70006 16.8 7.90006 16.3C7.70006 15.9 7.60006 15.4 7.60006 15C7.60006 14.9 7.60006 14.8 7.60006 14.6C7.40006 14.4 7.10006 14.2 6.70006 14.2C6.00006 14.2 5.40006 14.8 5.40006 15.6C5.30006 16.5 5.90006 17.1 6.70006 17.1Z"
      fill="currentColor"
    />
    <path d="M6 19.2C6 18.7 6.2 18.3 6.5 17.9C5.5 17.9 4 18.4 4 19.3V20H6V19.2Z" fill="currentColor" />
    <path
      d="M10.1 16.7C11 16.7 11.7 16 11.7 15C11.7 14.1 11 13.3 10.1 13.3C9.2 13.3 8.5 14 8.5 15C8.5 15.9 9.2 16.7 10.1 16.7ZM10.1 17.5C9.1 17.5 7 18.1 7 19.2V20H13.2V19.2C13.2 18.1 11.1 17.5 10.1 17.5Z"
      fill="currentColor"
    />
    <path d="M13.7 17.9C14 18.3 14.2 18.7 14.2 19.2V20H16.2V19.3C16.1 18.4 14.6 17.9 13.7 17.9Z" fill="currentColor" />
    <path
      d="M12.6 15C12.6 15.5 12.5 15.9 12.3 16.3C12.5 16.8 13 17.1 13.5 17.1C14.2 17.1 14.8 16.5 14.8 15.7C14.8 14.9 14.2 14.3 13.5 14.3C13.2 14.3 12.9 14.4 12.6 14.7C12.6 14.8 12.6 14.9 12.6 15Z"
      fill="currentColor"
    />
  </Icon>
);

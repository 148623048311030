import isBefore from 'date-fns/isBefore';
import isFuture from 'date-fns/isFuture';
import subDays from 'date-fns/subDays';
import Deliverable from 'models/deliverable';
import Task1 from 'models/task1';
import Team from 'models/team';
import { normalize } from 'normalizr';
import { employees as employeesSchema } from 'schema';

import { TEAM_DURATIONS_OBJECT, TASK_STATUS, SEARCH_TAG_TYPES, TASK_TYPES_1 } from 'constants/ProjectConstants';

import { deleteAllMentionEntities, getAllMentionEntities, getApiBodyFromEditorStateAndMedia } from './post';
import { getPrimaryPod } from './team';

export const convertTaskFormValueToObject = (values, task) => {
  const { endDate, description, completed, goal } = values;
  const newTask = { ...task };
  newTask.description = description;
  newTask.endDate = endDate;
  newTask.completed = completed;
  newTask.deliver = new Deliverable({ id: goal.value, description: goal.label });
  return newTask;
};

export const isMultipleMentionsPresent = (macros) => macros.employees && Object.keys(macros.employees).length > 1;

export const getStartEndTimeFromDurationKey = (durationKey) => {
  const now = new Date();
  let startDate;
  const endTS = now.getTime();
  switch (durationKey) {
    case 'LASTYEAR':
      startDate = new Date(now.setFullYear(now.getFullYear() - 1));
      break;
    case TEAM_DURATIONS_OBJECT.lastMonth:
      // var firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      startDate = new Date(now.setDate(now.getDate() - 30));
      break;
    case TEAM_DURATIONS_OBJECT.last2Weeks:
      startDate = new Date(now.setDate(now.getDate() - 14));
      break;
    case TEAM_DURATIONS_OBJECT.lastWeek: {
      startDate = new Date(now.setDate(now.getDate() - 7));
      break;
    }
    case TEAM_DURATIONS_OBJECT.yesterday:
      startDate = new Date(now.setDate(now.getDate() - 1));
      break;
    case 'custom':
      startDate = new Date('2000-01-01 00:00:00');
      break;
    case 'BEGINNING_OF_TIME':
      startDate = new Date('1970-01-01');
      break;
    default:
      return null;
  }
  // setting date to the start of the day
  startDate.setHours(0, 0, 0, 0);
  return { startTS: startDate.getTime(), endTS /* startDate: startDate.toString() */ };
};

// Calculate team options considering all the toIds
// the purpose is to find unique team ids, otherwise return Team: {id: -2, name: 'Default'}
export function getTeamOptions(toIds = []) {
  if (toIds.length === 0) return [];

  // flattening employee object
  const data = normalize(toIds, employeesSchema);
  const teamIdsArray = [];
  data.result.forEach((employeeId, index) => {
    const employee = data.entities.employees[employeeId];
    teamIdsArray.push(employee.teams);
  });

  const uniqueTeamIds = teamIdsArray.reduce((teamIds, currentArray) =>
    teamIds.filter((teamId) => currentArray.includes(teamId))
  );
  if (uniqueTeamIds.length === 0) return [new Team({ id: -2, name: 'Default' })];
  return uniqueTeamIds.reduce((teams, teamId) => {
    teams.push({ ...data.entities.teams[teamId] });
    return teams;
  }, []);
}

export function convertParentPostsIntoBackendFormat(parentPosts) {
  const result = {};
  // parentPosts is like this {goal: {uuid: 1, ...otherProperties}},
  // there can be other keys there as well, so making sure we only get uuid
  // in case later we update this task
  if (parentPosts) {
    Object.keys(parentPosts).forEach((postType) => {
      const postTypeData = parentPosts[postType];
      if (!postTypeData) return;
      const newPostTypeData = postTypeData.map((post) => ({ uuid: post.id }));
      result[postType] = newPostTypeData;
    });
  }
  return result;
}

export function parseProgressGraphRes(res) {
  return res.map((progressObj) => ({
    // createdAt: convertDateToString3(getDateFromEpoch(progressObj.createdTS)),
    createdAt: progressObj.createdTS,
    actual: progressObj.actual,
    updatedBy: {
      name: progressObj.lastEditedBy.fullName,
      avatar: progressObj.lastEditedBy.profilePhotoSrc,
    },
    progressLevel: progressObj.goalProgressLevel,
    target: progressObj.target,
  }));
}

export function getTags(task) {
  const { macros } = task;
  if (macros && macros.attributes && macros.attributes.orgTag) {
    return Object.values(macros.attributes.orgTag).filter((tag) => tag.tagType !== SEARCH_TAG_TYPES.subObjectiveTag);

    // return [];
  }
  return [];
}

// get the color for due date according to the detailed status
export function getDueDateColorForSelectedStatus(status, isDueDatePresent) {
  let color = '#999999';
  if (!isDueDatePresent) return color;
  if (status) {
    const selectedStatus = Object.values(TASK_STATUS).filter((s) => s.value === status)[0];
    color = selectedStatus?.color;
  }
  return color;
}

export function getCheckInDueDateColor(dueDate) {
  if (!dueDate) {
    return 'grey';
  }
  if (isFuture(dueDate)) {
    return 'blue';
  }
  return isBefore(dueDate, subDays(new Date(), 7)) ? 'red' : 'orange';
}

export function selectCollaborators(toIds) {
  return toIds.filter((employee) => employee.ownerType === Task1.EMPLOYEE_TYPES.collaborator);
}

export function addCollaboratorOwnerType(employees) {
  return employees.map((employee) => ({
    ...employee,
    ownerType: Task1.EMPLOYEE_TYPES.collaborator,
  }));
}

export function removeCommonToIdsFromOwners(toIds, updatedCollaborators) {
  const common = {};
  const updatedCollaboratorsWithOwnerType = addCollaboratorOwnerType(updatedCollaborators);
  toIds.forEach((employee) => (common[employee.id] = employee));
  updatedCollaboratorsWithOwnerType.forEach((employee) => (common[employee.id] = employee));
  return Object.values(common);
}

export function selectOwners(toIds) {
  const result = toIds.filter((employee) => employee.ownerType === Task1.EMPLOYEE_TYPES.owner);
  return result;
}

export function addOwnerType(employees) {
  return employees.map((employee) => ({ ...employee, ownerType: Task1.EMPLOYEE_TYPES.owner }));
}

export function isAnyOwnerPresent(employees = []) {
  return employees.some((employee) => employee.ownerType === Task1.EMPLOYEE_TYPES.owner);
}

export function removeCommonToIdsFromCollaborators(toIds, updatedOwners) {
  const common = {};
  const updatedOwnersWithType = addOwnerType(updatedOwners);
  toIds.forEach((employee) => (common[employee.id] = employee));
  updatedOwnersWithType.forEach((employee) => (common[employee.id] = employee));
  return Object.values(common);
}

export function sortToIdsOwnersFirstAscendingName(toIds) {
  const sortByName = (e1, e2) => e1?.fullName?.localeCompare(e2?.fullName);
  const owners = selectOwners(toIds).sort(sortByName);
  const collaborators = selectCollaborators(toIds).sort(sortByName);
  return [...owners, ...collaborators];
}

export function getEmployeeTeamForTask(allEmployeeTeams, taskTeams = []) {
  taskTeams = taskTeams || [];
  allEmployeeTeams = allEmployeeTeams || [];
  // Add support for first selecting the common team for all employees ?
  /*
    var arr = [[0,1,2,3,4,5,6,7,8,9],[0,2,4,6,8],[4,5,6,7]],
    int = arr.reduce((p,c) => p.filter(e => c.includes(e)));
  */
  // from task teams
  const employeePrimaryPod = getPrimaryPod(allEmployeeTeams);
  const taskTeamsMap = taskTeams.reduce((map, team) => {
    map[team.id] = team;
    return map;
  }, {});
  // see if employee's default team is present
  const isPrimaryPodPresent = Boolean(taskTeamsMap[employeePrimaryPod?.id]);
  if (isPrimaryPodPresent) return employeePrimaryPod;
  const allEmployeeTeamIds = allEmployeeTeams.map((team) => team.id);
  // otherwise see if any other team present
  for (let i = 0; i < allEmployeeTeamIds.length; i++) {
    const team = taskTeamsMap[allEmployeeTeamIds[i]];
    if (team) return team;
  }
  // return null otherwise
  return null;
}

export function getCommaSeparatedTeamNames(task) {
  return task.teams.map((team) => `${team.name}`).join(', ');
}

// We need to make sure that we don't pass teams and removedTeam key in task update
// but when it is a new task, we need new team object
export function clearTeamsAndRemovedTeam(task) {
  return { ...task, teams: !task.id ? task.teams : null, removedTeam: null };
}

export function updateTaskBodyAccordingToTeamChange(task, newTeamId, oldTeamId) {
  // we need to make sure that when there is no change then teams and removedTeam key contain null
  const newTask = clearTeamsAndRemovedTeam(task);
  if (!task.id) newTask.teams = [{ uuid: newTeamId }];
  else if (newTeamId !== oldTeamId) {
    // we need to pass the changed team
    newTask.teams = [{ uuid: newTeamId }];
    // also the old team in removedTeam key
    newTask.removedTeam = { uuid: oldTeamId };
  }
  return newTask;
}

export function isRecurrent(taskType) {
  return taskType === TASK_TYPES_1.recurrentTask || taskType === TASK_TYPES_1.recurrentInstanceTask;
}

export const updateTextMacros = (task) => {
  const newTask = { ...task };
  const { text, macros } = getApiBodyFromEditorStateAndMedia(newTask.editorState);
  newTask.text = text;
  newTask.macros = macros;
  return newTask;
};

export const addTaggedEmployeesToOwners = (task) => {
  const toIds = [...task.toIds];
  const keysMap = {};
  toIds.forEach((toId) => (keysMap[toId.id] = true));
  const mentions = getAllMentionEntities(task.editorState);
  mentions.forEach(({ entityData }) => {
    const { employee } = entityData.mention;
    if (!toIds[employee.id]) toIds.push(employee);
  });
  return { ...task, toIds };
};

export const deleteMentions = (task) => {
  const newEditorState = deleteAllMentionEntities(task.editorState);
  return { ...task, editorState: newEditorState };
};

export const addTaggedEmployeesUpdateMacrosDeleteMentions = (task) =>
  updateTextMacros(deleteMentions(addTaggedEmployeesToOwners(task)));

export function isComplete(completionDate) {
  return !(completionDate === null || completionDate === undefined);
}

export function toggleCompletion(task) {
  const isTaskComplete = isComplete(task.completionDate);
  const completionDate = isTaskComplete ? null : new Date();
  return { ...task, completionDate };
}

export function updateToIds(task, toIds) {
  return { ...task, toIds };
}

import { Icon } from '@chakra-ui/react';

export const Preferences = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_376_269)">
      <path d="M5 12H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M11 7H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M13 17H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <circle fill="transparent" cx="17" cy="12" r="2" stroke="currentColor" strokeWidth="2" />
      <path d="M5 7H7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <circle fill="transparent" cx="11" cy="17" r="2" stroke="currentColor" strokeWidth="2" />
      <path d="M5 17H8.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <circle fill="transparent" cx="9" cy="7" r="2" stroke="currentColor" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_376_269">
        <rect width="16" height="16" fill="transparent" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const REVIEW_RESULT_VIEW = {
  QUESTION: 'VIEW_BY_QUESTION',
  PEOPLE: 'VIEW_BY_PEOPLE',
};

const initialState = {
  // currentFormKey: null,
  // currentQuestions: {
  //   [REVIEW_FORM_KEYS.COMPETENCY]: {
  //     questionIndex: 0
  //   },
  //   [REVIEW_FORM_KEYS.QNA]: {
  //     questionIndex: 0
  //   },
  //   [REVIEW_FORM_KEYS.GOAL]: {
  //     questionIndex: 0
  //   }
  // },
  currentProvider: {},
  selectedView: REVIEW_RESULT_VIEW.QUESTION,
};

export const reviewResultSlice = createSlice({
  name: 'reviewResult',
  initialState,
  reducers: {
    // setCurrentFormKey: (state, action) => {
    //   state.currentFormKey = action.payload.id;
    // },
    // setCurrentCompetencyQuestionIndex: (state, action) => {
    //   state.currentQuestions.COMPETENCY = {
    //     questionIndex: action.payload
    //   };
    // },
    // setCurrentGoalQuestionIndex: (state, action) => {
    //   state.currentQuestions.GOAL = {
    //     questionIndex: action.payload
    //   };
    // },
    // setCurrentQNAQuestionIndex: (state, action) => {
    //   state.currentQuestions.QNA = {
    //     questionIndex: action.payload
    //   };
    // },
    setCurrentProvider: (state, action) => {
      state.currentProvider = action.payload;
      state.selectedView = REVIEW_RESULT_VIEW.PEOPLE;
    },
    setSelectedReviewResultView: (state, action) => {
      state.currentProvider = {};
      state.selectedView = action.payload;
    },
    resetResultState: (state, action) => {
      state.currentProvider = {};
      state.selectedView = REVIEW_RESULT_VIEW.QUESTION;
    },
  },
});

export const {
  // setCurrentFormKey,
  // setCurrentCompetencyQuestionIndex,
  // setCurrentGoalQuestionIndex,
  // setCurrentQNAQuestionIndex,
  setCurrentProvider,
  setSelectedReviewResultView,
  resetResultState,
} = reviewResultSlice.actions;

export default reviewResultSlice.reducer;

// selectors
// export const useSelectedReviewForm = () =>
//   useSelector((state) => state.reviewResultSlice.currentFormKey);

// export const useCurrentCompetencyQuestionIndex = () =>
//   useSelector((state) => state.reviewResultSlice.currentQuestions.COMPETENCY.questionIndex);

// export const useCurrentGoalQuestionIndex = () =>
//   useSelector((state) => state.reviewResultSlice.currentQuestions.GOAL.questionIndex);

// export const useCurrentQNAQuestionIndex = () =>
//   useSelector((state) => state.reviewResultSlice.currentQuestions.QNA.questionIndex);

export const useCurrentProvider = () => useSelector((state) => state.reviewResultSlice.currentProvider);

export const useSelectedReviewResultView = () => useSelector((state) => state.reviewResultSlice.selectedView);

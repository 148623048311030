import { Icon } from '@chakra-ui/react';

export const MyFeedback = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_412_332)">
      <path
        d="M13 10.5V10H11.5C11.22 10 11 9.78 11 9.5V5.5C11 5.22 11.22 5 11.5 5H18.5C18.78 5 19 5.22 19 5.5V9.5C19 9.78 18.78 10 18.5 10H15.91C15.51 10 15.13 10.16 14.85 10.44L13.85 11.44C13.54 11.75 13 11.53 13 11.09V10.5V10.5Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M15 8.79001L15.31 8.10001L16 7.79001L15.31 7.48001L15 6.79001L14.69 7.48001L14 7.79001L14.69 8.10001L15 8.79001Z"
        fill="currentColor"
      />
      <path
        d="M12 20H4V18.85C4 18.49 4.15 18.16 4.46 17.86C4.72 17.6 5.08 17.38 5.53 17.19C6.44 16.8 7.43 16.66 8.01 16.66C8.59 16.66 9.57 16.8 10.49 17.19C10.94 17.38 11.3 17.61 11.56 17.86C11.86 18.16 12.02 18.49 12.02 18.85V20H12ZM8 15.83C6.87 15.83 5.95 14.85 5.95 13.64C5.95 12.43 6.87 11.45 8 11.45C9.13 11.45 10.05 12.43 10.05 13.64C10.05 14.85 9.13 15.83 8 15.83Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_412_332">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

import { OPEN_GIF_MODAL, CLOSE_GIF_MODAL } from 'actions/actionTypes';

export const initialState = {
  isOpen: false,
  onSelect: null,
};

const gifModal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_GIF_MODAL:
      return {
        isOpen: true,
        onSelect: action.onSelect,
      };

    case CLOSE_GIF_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default gifModal;

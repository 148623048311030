import { Icon } from '@chakra-ui/react';

export const Company = (props) => (
  <Icon viewBox="0 0 24 25" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00245 6.20887C9.22304 5.93313 9.60602 5.65857 10.1333 5.65857H13.8666C14.3939 5.65857 14.7769 5.93313 14.9975 6.20887C15.1928 6.45294 15.3333 6.78422 15.3333 7.12524V7.21417H18.5333C18.843 7.21417 19.259 7.29227 19.5904 7.62373C19.9219 7.95519 20 8.37114 20 8.68084V18.5474H4V8.68084C4 8.37114 4.0781 7.95519 4.40956 7.62373C4.74102 7.29227 5.15697 7.21417 5.46667 7.21417H8.66665V7.12524C8.66665 6.78422 8.80719 6.45294 9.00245 6.20887ZM8.66665 9.21417H6V11.8808H8.66665V9.21417ZM10.6666 11.8808V9.21417H13.3333V11.8808H10.6666ZM8.66665 13.8808H6V16.5474H8.66665V13.8808ZM10.6666 16.5474V13.8808H13.3333V16.5474H10.6666ZM15.3333 16.5474H18V13.8808H15.3333V16.5474ZM15.3333 11.8808H18V9.21417H15.3333V11.8808Z"
      fill="currentColor"
    />
  </Icon>
);

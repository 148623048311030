import { Icon } from '@chakra-ui/react';

export const Delete = (props) => (
  <Icon viewBox="0 0 20 20" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M5.55519 15.1858C5.55519 16.0007 6.22186 16.6673 7.03668 16.6673H12.9626C13.7774 16.6673 14.4441 16.0007 14.4441 15.1858V6.29695H5.55519V15.1858ZM7.03668 7.77843H12.9626V15.1858H7.03668V7.77843ZM12.5922 4.07473L11.8515 3.33398H8.14779L7.40705 4.07473H4.81445V5.55621H15.1848V4.07473H12.5922Z"
      fill="currentColor"
    />
  </Icon>
);

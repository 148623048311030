import { Icon } from '@chakra-ui/react';

export const GoalIconSelected = (props) => (
  <Icon viewBox="0 0 25 25" {...props}>
    <path
      d="M18.0859 12.3986H15.9575L15.6535 12.7027C15.6535 12.9054 15.7548 13.2095 15.7548 13.5135C15.7548 15.7432 13.9305 17.5676 11.7008 17.5676C9.47105 17.5676 7.64673 15.7432 7.64673 13.5135C7.64673 11.2838 9.47105 9.45946 11.7008 9.45946C12.1062 9.45946 12.4102 9.56081 12.7143 9.56081V7.12838C12.4102 7.02703 12.1062 7.02703 11.7008 7.02703C8.15348 7.02703 5.21429 9.96622 5.21429 13.5135C5.21429 17.0608 8.15348 20 11.7008 20C15.2481 20 18.1873 17.0608 18.1873 13.5135C18.1873 13.1081 18.1873 12.8041 18.0859 12.3986Z"
      fill="currentColor"
    />
    <path
      d="M20.2143 9.15541L17.9846 11.3851H15.5521L12.2075 14.7297C11.8021 15.1351 11.0927 15.1351 10.5859 14.7297C10.0792 14.3243 10.1805 13.6149 10.5859 13.1081L13.8292 9.86486V7.22973L16.0589 5V9.15541H20.2143Z"
      fill="currentColor"
    />
  </Icon>
);

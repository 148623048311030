import { Icon } from '@chakra-ui/react';

export const Calendar2 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <rect x="4.5" y="6.5" width="15" height="13" rx="0.5" stroke="currentColor" fill="transparent" />
    <path d="M5 9.5H19.5" stroke="currentColor" />
    <path d="M8 6V4" stroke="currentColor" />
    <path d="M16 6V4" stroke="currentColor" />
    <rect x="14.5" y="14.5" width="2" height="2" fill="currentColor" stroke="currentColor" />
  </Icon>
);

export const colorsOld = {
  black: `hsla(0, 0%, 0%, 1)`,
  white: `hsla(0, 0%, 100%, 1)`,
  neutral: {
    gray: {
      100: `hsla(0, 0%, 100%, 1)`,
      98: `hsla(0, 0%, 98%, 1)`,
      96: `hsla(0, 0%, 96%, 1)`,
      94: `hsla(0, 0%, 94%, 1)`,
      92: `hsla(0, 0%, 92%, 1)`,
      90: `hsla(0, 0%, 90%, 1)`,
      88: `hsla(0, 0%, 88%, 1)`,
      86: `hsla(0, 0%, 86%, 1)`,
      84: `hsla(0, 0%, 84%, 1)`,
      82: `hsla(0, 0%, 82%, 1)`,
      80: `hsla(0, 0%, 80%, 1)`,
      70: `hsla(0, 0%, 70%, 1)`,
      60: `hsla(0, 0%, 60%, 1)`,
      50: `hsla(0, 0%, 50%, 1)`,
      40: `hsla(0, 0%, 40%, 1)`,
      30: `hsla(0, 0%, 30%, 1)`,
      20: `hsla(0, 0%, 20%, 1)`,
      10: `hsla(0, 0%, 10%, 1)`,
    },
    blueGray: {
      100: `hsla(216, 24%, 100%, 1)`,
      98: `hsla(216, 24%, 98%, 1)`,
      96: `hsla(216, 24%, 96%, 1)`,
      94: `hsla(216, 24%, 94%, 1)`,
      92: `hsla(216, 24%, 92%, 1)`,
      90: `hsla(216, 24%, 90%, 1)`,
      88: `hsla(216, 24%, 88%, 1)`,
      86: `hsla(216, 24%, 86%, 1)`,
      84: `hsla(216, 24%, 84%, 1)`,
      82: `hsla(216, 24%, 82%, 1)`,
      80: `hsla(216, 24%, 80%, 1)`,
      70: `hsla(216, 24%, 70%, 1)`,
      60: `hsla(216, 24%, 60%, 1)`,
      50: `hsla(216, 24%, 50%, 1)`,
      40: `hsla(216, 24%, 40%, 1)`,
      30: `hsla(216, 24%, 30%, 1)`,
      20: `hsla(216, 24%, 20%, 1)`,
      10: `hsla(216, 24%, 10%, 1)`,
    },
    transparentWhite: {
      0: `hsla(0, 0%, 100%, 0)`,
      4: `hsla(0, 0%, 100%, 0.04)`,
      8: `hsla(0, 0%, 100%, 0.08)`,
      12: `hsla(0, 0%, 100%, 0.12)`,
      16: `hsla(0, 0%, 100%, 0.16)`,
      20: `hsla(0, 0%, 100%, 0.20)`,
      24: `hsla(0, 0%, 100%, 0.24)`,
      28: `hsla(0, 0%, 100%, 0.28)`,
      32: `hsla(0, 0%, 100%, 0.32)`,
      36: `hsla(0, 0%, 100%, 0.36)`,
      40: `hsla(0, 0%, 100%, 0.40)`,
      44: `hsla(0, 0%, 100%, 0.44)`,
      48: `hsla(0, 0%, 100%, 0.48)`,
      52: `hsla(0, 0%, 100%, 0.52)`,
      56: `hsla(0, 0%, 100%, 0.56)`,
      60: `hsla(0, 0%, 100%, 0.60)`,
      64: `hsla(0, 0%, 100%, 0.64)`,
      68: `hsla(0, 0%, 100%, 0.68)`,
      72: `hsla(0, 0%, 100%, 0.72)`,
      76: `hsla(0, 0%, 100%, 0.76)`,
      80: `hsla(0, 0%, 100%, 0.80)`,
      84: `hsla(0, 0%, 100%, 0.84)`,
      88: `hsla(0, 0%, 100%, 0.88)`,
      92: `hsla(0, 0%, 100%, 0.92)`,
      96: `hsla(0, 0%, 100%, 0.96)`,
      100: `hsla(0, 0%, 100%, 1)`,
    },
    transparentBlack: {
      0: `hsla(0, 0%, 0%, 0)`,
      4: `hsla(0, 0%, 0%, 0.04)`,
      8: `hsla(0, 0%, 0%, 0.08)`,
      12: `hsla(0, 0%, 0%, 0.12)`,
      16: `hsla(0, 0%, 0%, 0.16)`,
      20: `hsla(0, 0%, 0%, 0.20)`,
      24: `hsla(0, 0%, 0%, 0.24)`,
      28: `hsla(0, 0%, 0%, 0.28)`,
      32: `hsla(0, 0%, 0%, 0.32)`,
      36: `hsla(0, 0%, 0%, 0.36)`,
      40: `hsla(0, 0%, 0%, 0.40)`,
      44: `hsla(0, 0%, 0%, 0.44)`,
      48: `hsla(0, 0%, 0%, 0.48)`,
      52: `hsla(0, 0%, 0%, 0.52)`,
      56: `hsla(0, 0%, 0%, 0.56)`,
      60: `hsla(0, 0%, 0%, 0.60)`,
      64: `hsla(0, 0%, 0%, 0.64)`,
      68: `hsla(0, 0%, 0%, 0.68)`,
      72: `hsla(0, 0%, 0%, 0.72)`,
      76: `hsla(0, 0%, 0%, 0.76)`,
      80: `hsla(0, 0%, 0%, 0.80)`,
      84: `hsla(0, 0%, 0%, 0.84)`,
      88: `hsla(0, 0%, 0%, 0.88)`,
      92: `hsla(0, 0%, 0%, 0.92)`,
      96: `hsla(0, 0%, 0%, 0.96)`,
      100: `hsla(0, 0%, 0%, 1)`,
    },
  },
  material: {
    red: `hsla(0, 81%, 63%, 1)`,
    pink: `hsla(340, 80%, 59%, 1)`,
    deep_purple: `hsla(261, 44%, 55%, 1)`,
    purple: `hsla(291, 44%, 51%, 1)`,
    indigo: `hsla(230, 43%, 55%, 1)`,
    blue: `hsla(206, 87%, 62%, 1)`,
    light_blue: `hsla(199, 90%, 58%, 1)`,
    cyan: `hsla(187, 68%, 53%, 1)`,
    teal: `hsla(174, 56%, 42%, 1)`,
    green: `hsla(124, 37%, 57%, 1)`,
    light_green: `hsla(89, 48%, 61%, 1)`,
    lime: `hsla(66, 67%, 63%, 1)`,
    yellow: `hsla(53, 100%, 70%, 1)`,
    amber: `hsla(43, 99%, 62%, 1)`,
    orange: `hsla(33, 98%, 61%, 1)`,
    deep_orange: `hsla(13, 98%, 64%, 1)`,
    brown: `hsla(15, 17%, 47%, 1)`,
    blue_grey: `hsla(201, 15%, 54%, 1)`,
    grey: `hsla(0, 0%, 74%, 1)`,
  },
  chromatic: {
    green: {
      400: `hsla(124, 37%, 57%, 1)`,
    },
    amber: {
      400: `hsla(43, 99%, 62%, 1)`,
    },
    red: {
      400: `hsla(0, 81%, 63%, 1)`,
    },
    pink: {
      300: `hsla(340, 80%, 59%, 1)`,
      500: `hsla(349, 52%, 61%, 1)`,
    },
    purple: {
      500: `hsla(261, 44%, 55%, 1)`,
    },
    blue: {
      700: `hsla(224, 51%, 34%, 1)`,
      500: `hsla(214, 84%, 56%, 1)`,
    },
    purple: {
      500: `hsla(261, 44%, 55%, 1)`,
    },
  },
};

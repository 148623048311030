import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {};

// use this as a middleware to set the persistence data in the redux store too.
// to support globally subscribing to the persisted data

export const localStorageToReduxPersistenceDataSlice = createSlice({
  name: 'localStorageToReduxPersistenceData',
  initialState,
  reducers: {
    setLocalStorageToReduxPersistenceData: (state, action: PayloadAction<any>) => {
      state = { ...state, ...action.payload };
      return state;
    },

    resetLocalStorageToReduxPersistenceData: () => ({}),
  },
});

export const { setLocalStorageToReduxPersistenceData, resetLocalStorageToReduxPersistenceData } =
  localStorageToReduxPersistenceDataSlice.actions;

export default localStorageToReduxPersistenceDataSlice.reducer;

export const useSelectLocalStorageToReduxPersistenceData = () =>
  useSelector((state: { localStorageToReduxPersistenceData: any }) => state.localStorageToReduxPersistenceData);

export const API_META_UPDATE = 'API_META_UPDATE';
export const API_META_RESET = 'API_META_RESET';

// export function apiMetaUpdateLimitAction(limit) {
//   return { type: API_META_UPDATE, payload: { limit } };
// }

export function apiMetaUpdateOffsetAction(offset) {
  return { type: API_META_UPDATE, payload: { offset } };
}

export function apiMetaUpdateHasMoreAction(hasMore) {
  return { type: API_META_UPDATE, payload: { hasMore } };
}

// export function apiMetaUpdateAction({ limit, offset, hasMore }) {
//   return { type: API_META_UPDATE, payload: { offset, limit, hasMore } };
// }

export function apiMetaResetAction({ limit, offset, hasMore, paginationKey = '' }) {
  return { type: API_META_UPDATE, payload: { offset, limit, hasMore, paginationKey } };
}

import {
  POST_ADD_HASH_TAG,
  UPDATE_ADD_POST_VISIBILITY,
  CHANGE_ADD_POST_VISIBILITY_UPDATED_BY_USER_STATUS,
  POST_UPDATE_EDITOR_STATE,
  POST_TOGGLE_UPDATE_STATUS,
  POST_UPDATE_IMAGES,
  POST_UPDATE_GIFS,
  POST_UPDATE,
  POST_ADD_MENTION,
  POST_CLEAR,
  UPDATE_TAGS_RECOMMENDATIONS,
  UPDATE_ADD_POST_SENTIMENT,
} from 'actions/actionTypes';
import { EditorState } from 'draft-js';
import Media from 'models/media';
import { combineReducers } from 'redux';
import { addMention } from 'screens/home/addPost/mentionHelper';
import { selectImage } from 'selectors/addPost';

import { SENTIMENTS, POST_VISIBILITY_TYPES, ADD_POST_MODAL_TYPES } from 'constants/ProjectConstants';

import { getEditorStateFromStringifiedRawState } from 'services/helpers/post';

export function addPostModal(state = { open: false, modalType: ADD_POST_MODAL_TYPES.GIVE_KUDOS }, action) {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return state.open
        ? { ...state, open: false, modalType: ADD_POST_MODAL_TYPES.GIVE_KUDOS }
        : {
            ...state,
            open: true,
            modalType: action.modalType || ADD_POST_MODAL_TYPES.GIVE_KUDOS,
          };
    default:
      return state;
  }
}

// for visibility type of the post
export function addPostVisibilityMeta(state = false, action) {
  switch (action.type) {
    case CHANGE_ADD_POST_VISIBILITY_UPDATED_BY_USER_STATUS: {
      return action.data.isUpdatedByUser || false;
    }
    default:
      return state;
  }
}

export const postDataInitialState = (contentState, media = [], id, visibility, sentiment) => {
  const state = {
    id,
    editorState: contentState
      ? getEditorStateFromStringifiedRawState({
          stringifiedRawState: contentState,
        })
      : EditorState.createEmpty(),
    images: selectImage(media),
    gifs: media.filter((m) => m.type === Media.TYPES.gif),
    documents: [],
    isUpdating: false,
    isUploading: false,
    visibility: visibility || POST_VISIBILITY_TYPES.global.name,
    sentiment: sentiment || SENTIMENTS.neutral,
  };
  return state;
};

export const postDataInitialStateRabbit = (contentState, media = [], id, visibility, sentiment) => {
  const state = {
    id,
    editorState: contentState
      ? getEditorStateFromStringifiedRawState({
          stringifiedRawState: contentState,
        })
      : EditorState.createEmpty(),
    images: selectImage(media),
    gifs: media.filter((m) => m.type === Media.TYPES.gif),
    documents: [],
    isUpdating: false,
    isUploading: false,
    visibility: visibility || POST_VISIBILITY_TYPES.global.name,
    sentiment: sentiment || ADD_POST_MODAL_TYPES.GIVE_KUDOS,
  };

  return state;
};

// new editor state should be inside action.data
export function post(state = postDataInitialStateRabbit(), action) {
  switch (action.type) {
    case POST_ADD_HASH_TAG: {
      // use it for adding a mention entity to editorState
      // Copied this addMention function from official mention plugin of draft js
      const newEditorState = addMention(
        // this.props.store.getEditorState(),
        state.editorState,
        action.data,
        ' #',
        '#',
        true
      );
      return { ...state, editorState: newEditorState };
    }
    case POST_ADD_MENTION: {
      const newEditorState = addMention(
        // this.props.store.getEditorState(),
        state.editorState,
        action.data,
        ' @',
        '@',
        true
      );
      return { ...state, editorState: newEditorState };
    }
    case POST_UPDATE_EDITOR_STATE:
      return { ...state, editorState: action.editorState };
    case POST_TOGGLE_UPDATE_STATUS:
      return { ...state, isUpdating: !state.isUpdating };
    case POST_UPDATE_IMAGES: {
      // keeping it an array because in future we want to have multiple images
      const newImages = [];
      if (action.image) newImages.push(action.image);
      return { ...state, images: newImages };
    }
    case POST_UPDATE_GIFS: {
      const newGifs = [];
      if (action.gif) newGifs.push(action.gif);
      return { ...state, gifs: newGifs };
    }
    case POST_UPDATE:
      return { ...state, ...action.post };
    case POST_CLEAR:
      return postDataInitialStateRabbit();
    case UPDATE_ADD_POST_VISIBILITY:
      return { ...state, visibility: action.data.visibilityType };
    case UPDATE_ADD_POST_SENTIMENT:
      return { ...state, sentiment: action.data };
    default:
      return state;
  }
}

export function recommendations(state = [], action) {}

// TODO: old code, check what can be removed

// export function addPostMoolahAttribute(state = 0, action) {
//

//   switch (action.type) {
//     case 'ADDPOST_UPDATE_MOOLAH':
//       return action.data;
//     default:
//       return state;
//   }
// }

// export function addPostSentiment(state = null, action) {
//   switch (action.type) {
//     case 'ADDPOST_UPDATE_SENTIMENT': {
//       if (state !== action.newSentiment) return action.newSentiment;
//       else return state;
//     }
//     default:
//       return state;
//   }
// }

function update(state, action) {
  const newState = [...state];
  newState[action.index] = { ...action.data };
  return newState;
}

function remove(state, action) {
  return state.filter((d, index) => index !== action.index);
}

function addPredicted(state, action) {
  // if (state.length === 0 && (action.data.length === 0)) return state;
  // removed the previously predicted tags
  const newState = state.filter((d) => !d.predicted);
  // adding the new tags

  return [...newState, ...action.data];
}

// export function addPostDeliverAttributes(state = [], action) {
//
//   switch (action.type) {
//     case 'DELIVER_ADD':
//       return [...state, action.data];
//     case 'DELIVER_UPDATE':
//       return update(state, action);
//     case 'DELIVER_CLEAR':
//       return [];
//     case 'DELIVER_REMOVE':
//       return remove(state, action);
//     case 'ADDPOST_DELIVER_ADD_PREDICTED':
//       return addPredicted(state, action);
//     default:
//       return state;
//   }
//   // return updateReducer(state, action);
// }

export function addPostDevelopAttributes(state = [], action) {
  switch (action.type) {
    case 'DEVELOP_ADD':
      return [...state, action.data];
    case 'DEVELOP_UPDATE':
      return update(state, action);
    case 'DEVELOP_CLEAR':
      return [];
    case 'DEVELOP_REMOVE':
      return remove(state, action);
    case 'ADDPOST_DEVELOP_ADD_PREDICTED':
      return addPredicted(state, action);
    default:
      return state;
  }
}

export function tagRecommendations(state = [], action) {
  switch (action.type) {
    case UPDATE_TAGS_RECOMMENDATIONS:
      return Array.isArray(action.tags) ? [...action.tags] : [];
    default:
      return state;
  }
}

// export function addPostDriverAttributes(state = [], action) {
//   switch (action.type) {
//     case 'DRIVER_ADD':
//       return [...state, action.data];
//     case 'DRIVER_CLEAR':
//       return [];
//     case 'DRIVER_UPDATE':
//       return update(state, action);
//     case 'DRIVER_REMOVE':
//       return remove(state, action);
//     case 'ADDPOST_DRIVER_ADD_PREDICTED':
//       return addPredicted(state, action);
//     default:
//       return state;
//   }
// }

// function updateReducer(state = [], action) {
//
//   switch (action.type) {
//     case 'DEVELOP_ADD':
//       return [...state, action.data];
//     case 'DRIVER_UPDATE': {
//       const newState = [...state];
//       newState[action.index] = { ...action.data };
//       return newState;
//     }
//     case 'updateDevelopable': {
//       const newState = [...state];
//       newState[action.index].id = action.data.id;
//       newState[action.index].name = action.data.name;
//       return newState;
//     }
//     case 'addPredicted': {
//       // removed the previously predicted tags
//       const newState = state.filter(d => !d.predicted);
//       // adding the new tags
//       return [newState, ...action.data];
//     }

//     case 'DEVELOP_REMOVE':
//       return state.filter((d, index) => index !== action.index);
//     case 'updateSentiment': {
//       const { index, newSentiment } = action.data;
//       if (typeof index !== 'number' || !newSentiment)
//         throw Error('Provide index of the developable in the array and the new Sentiment');
//       // only update sentiment if the sentiment changed, otherwise no point calling re-render
//       if (state[index].sentiment !== action.data.newSentiment) {
//         const newState = [...state];
//         newState[index].sentiment = newSentiment;
//         return newState;
//       }
//       return state;
//     }
//     case 'close': {
//       return { ...state, open: false };
//     }
//     case 'open': {
//       return { ...state, open: true };
//     }
//     default:
//       return state;
//   }
// }

export default combineReducers({
  // driverAttributes,
  // deliverAttributes,
  // developAttributes,
  // sentiment,
  // moolahAttribute,
  // editorState,
  // modalVisibility
  // data
  tagRecommendations,
  data: post,
  meta: combineReducers({ isVisibilityUpdatedByUser: addPostVisibilityMeta }),
});

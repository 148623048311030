import { Icon } from '@chakra-ui/react';

export const Advice = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.81797 17.5H6.62053L6.47635 17.6349L3.04297 20.8474V4C3.04297 3.17157 3.71454 2.5 4.54297 2.5H19.543C20.3714 2.5 21.043 3.17157 21.043 4V16C21.043 16.8284 20.3714 17.5 19.543 17.5H6.81797Z"
      stroke="currentColor"
    />
    <path
      d="M12.043 12C11.7668 12 11.543 11.7761 11.543 11.5V6.5C11.543 6.22386 11.7668 6 12.043 6C12.3191 6 12.543 6.22386 12.543 6.5V11.5C12.543 11.7761 12.3191 12 12.043 12Z"
      fill="currentColor"
    />
    <path
      d="M12.043 14C11.7668 14 11.543 13.7761 11.543 13.5C11.543 13.2239 11.7668 13 12.043 13C12.3191 13 12.543 13.2239 12.543 13.5C12.543 13.7761 12.3191 14 12.043 14Z"
      fill="currentColor"
    />
  </Icon>
);

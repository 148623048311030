import { createSlice } from '@reduxjs/toolkit';

export const HOME_PAGE_VIEWS = {
  MY_SUMMARY: { id: 'MY_SUMMARY', displayName: 'My Summary' },
  MY_FEED: { id: 'MY_FEED', displayName: 'My Feed' },
};

const initialState = {
  homePageView: HOME_PAGE_VIEWS.MY_SUMMARY.id,
};

export const homePageViewSlice = createSlice({
  name: 'homePageView',
  initialState,
  reducers: {
    setHomePageView: (state, action) => {
      state.homePageView = action.payload;
    },
  },
});

export const { setHomePageView } = homePageViewSlice.actions;

export default homePageViewSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntentValuesType } from 'Deer/intent/types/IntentCommonTypes';
import { useSelector } from 'react-redux';

export type GoalsIntentType = {
  label: string;
  onClick: () => void;
  visible: () => boolean;
  value: {
    myData?: IntentValuesType;
    team?: {
      directReports: IntentValuesType;
      allReports: IntentValuesType;
      view?: IntentValuesType;
    };
    company?: IntentValuesType;
  };
  applicableGroupByValues?: {
    value: string;
    label: string;
  }[];
  showGroupByDropdown?: boolean;
  applicableLayoutValues?: string[];
  showLayoutDropdown?: boolean;
  applicableOKRViewValues?: {
    value: string;
    label: string;
    onClick: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Icon: (props: any) => React.JSX.Element;
  }[];
  showOKRViewDropdown?: boolean;
  showTimelineDropdown?: boolean;
  category: 'Manage' | 'Analyze' | 'Checkin' | 'Configure' | 'Create';
  showGoalOwnershipFilter?: boolean;
  isDefault?: boolean;
  timelineDropdownOnChangeCallback?: (onChange: () => void) => void;
  ownershipDropdownOnChangeCallback?: (onChange: () => void) => void;
};

const initialState: {
  selectedIntent: null | GoalsIntentType;
} = {
  selectedIntent: null,
};

export const goalsIntentSlice = createSlice({
  name: 'goalsIntent',
  initialState,
  reducers: {
    setSelectedGoalsIntent: (state, action: PayloadAction<GoalsIntentType>) => {
      const newState = { ...state };
      newState.selectedIntent = action.payload;
      return newState;
    },
  },
});

export const { setSelectedGoalsIntent } = goalsIntentSlice.actions;

export default goalsIntentSlice.reducer;

export const useSelectGoalsSelectedIntent = () =>
  useSelector((state: { goalsIntent: typeof initialState }) => state.goalsIntent.selectedIntent);

import { SEARCH_WIDGET_TYPES } from 'constants/ProjectConstants';

import { getStartEndTimeFromDurationKey } from 'services/helpers/task';
import { singleTagTypeAutocompleteV1 } from 'services/search';

export function getSelectedFiltersCountFromMap(selectedFiltersMap) {
  return Object.entries(selectedFiltersMap).reduce((count, [value, isSelected]) => (isSelected ? count + 1 : count), 0);
}

export function convertEmployeeRes(res) {
  const teamEmployees = res.teamEmployees
    ? res.teamEmployees.map((emp) => ({
        label: emp.employee.metadata.displayName,
        designation: emp.employee.metadata.designation,
        avatar: emp.employee.profile.profilePhoto,
        value: emp.employee.uuid,
        selected: false,
      }))
    : [];
  return teamEmployees;
}

export const getPrimaryPodsList = (empIds) =>
  singleTagTypeAutocompleteV1({
    text: '',
    tagType: SEARCH_WIDGET_TYPES.primaryPod,
    employeeIds: empIds,
  }).then((res) =>
    res.length > 0
      ? res.map((pod) => ({
          value: pod.uuid,
          label: pod.displayName,
        }))
      : []
  );

export function getSelectedIdsFromSelectedMap(selectedMap) {
  return Object.entries(selectedMap).reduce((ids, [id, isSelected]) => {
    if (isSelected) ids.push(id);
    return ids;
  }, []);
}

export function getFunctionForSortingSelectedAtTop(selected) {
  return (firstEntity, secondEntity) => {
    if (
      (selected[firstEntity.value] && selected[secondEntity.value]) ||
      (!selected[firstEntity.value] && !selected[secondEntity.value])
    )
      return firstEntity.label.localeCompare(secondEntity.label);
    if (selected[firstEntity.value]) return -1;
    if (selected[secondEntity.value]) return 1;
    return 1;
  };
}

export function getStartTSEndTSFromDateFilters(selectedDateFilters) {
  let startTS;
  let endTS;
  if (selectedDateFilters?.predefined) {
    const result = getStartEndTimeFromDurationKey(selectedDateFilters.predefined);
    startTS = result.startTS;
    endTS = result.endTS;
  } else {
    let startDate;
    let endDate;
    if (selectedDateFilters.from) {
      startDate = selectedDateFilters.from;
      startDate.setHours(0, 0, 0, 0);
      startTS = startDate.getTime();
    }
    if (selectedDateFilters.to) {
      endDate = selectedDateFilters.to;
      endDate.setHours(23, 59, 0, 0);
      endTS = endDate.getTime();
    }
  }
  return { startTS, endTS };
}

export function buildDateFilters(filterType) {
  return (selectedDateFilters) => {
    const filters = [];
    const { startTS, endTS } = getStartTSEndTSFromDateFilters(selectedDateFilters);
    if (startTS) filters.push({ type: filterType, value: startTS, operator: 'GREATER_THAN' });
    if (endTS) filters.push({ type: filterType, value: endTS, operator: 'LESS_THAN' });

    return filters;
  };
}

export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';

export function clearAllReducerWrapper(reducer, initialFiltersState) {
  return function clearAllReducer(state, action) {
    switch (action.type) {
      case CLEAR_ALL_FILTERS:
        return { ...state, ...initialFiltersState };
      case RESET_ALL_FILTERS:
        return { ...initialFiltersState, ...action.data };
      default:
        return reducer(state, action);
    }
  };
}

// https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates
// to overcome limitation of parsing dates, while saving filters to localstorage.

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

export const dateParser = function (key, value) {
  if (typeof value === 'string') {
    const a = reISO.exec(value);
    if (a) return new Date(value);
  }
  return value;
};

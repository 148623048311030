import {
  frequentListApi,
  recentListApi,
  tagsListApi,
  submitSearchApi,
  searchAutoCompleteApi,
  searchAutoCompleteV1Api,
} from 'api/search';
import Employee from 'models/employee';

import {
  SEARCH_WIDGET_TYPES,
  SEARCH_TAG_TYPES,
  UNIVERSAL_SEARCH_RESULTS_LIMIT,
  UNIVERSAL_SEARCH_RESULTS_LIMIT_MOBILE,
} from 'constants/ProjectConstants';

import {
  addActionItemsToTagResponse,
  parseSearchAutoCompleteRes,
  parseUniversalSearchAutoCompleteObjectives,
  parseUniversalSearchAutoCompleteRes,
  parseUniversalSearchAutoCompleteResV1,
} from './helpers/search';

export const fetchFrequentList = () => frequentListApi().then((res) => res.entity);

export const fetchRecentList = () => recentListApi().then((res) => res.entity);

// converting tags to search type
export const fetchTagsList = () => tagsListApi().then((res) => addActionItemsToTagResponse(res.entity));

export const submitSearch = (data = []) =>
  submitSearchApi(data).then((res) => {
    alert('successfully submited search');
    return { message: 'Successfully completed search', success: true };
  });

export const searchAutoComplete = ({ tagType, query, callback, widgetType, teamId, ...otherProps }) => {
  searchAutoCompleteApi({ tagType, query, widgetType, teamId, ...otherProps })
    .then((res) => {
      const data = parseSearchAutoCompleteRes(res.entity || []);
      callback(data);
    })
    .catch((err) => console.error('An error occurred while searching', err));
};

export const universalSearchAutoComplete = ({ query, callback }) => {
  searchAutoCompleteApi({ query, widgetType: SEARCH_WIDGET_TYPES.search }).then((res) => {
    const data = parseUniversalSearchAutoCompleteRes(res.entity || []);
    return callback(data);
  });
};

export const universalSearchAutoCompleteV1 = (
  { text, tagsSet, callback, cohort },
  isMobileView = false,
  existingData
) => {
  // this is only for v0 of mobile site,
  if (isMobileView) {
    tagsSet = [
      {
        tagType: 'EMPLOYEE_NAME',
        limit: UNIVERSAL_SEARCH_RESULTS_LIMIT_MOBILE.employeeName,
      },
    ];
  }
  const tagTypeSet = tagsSet || [
    // {
    //   tagType: 'TEAM',
    //   limit: UNIVERSAL_SEARCH_RESULTS_LIMIT.team,
    // },
    {
      tagType: 'EMPLOYEE_NAME',
      limit: UNIVERSAL_SEARCH_RESULTS_LIMIT.employeeName,
    },
    // {
    //   tagType: 'ORGTAG',
    //   limit: UNIVERSAL_SEARCH_RESULTS_LIMIT.orgTag,
    // },
  ];
  searchAutoCompleteV1Api({
    text,
    widgetType: SEARCH_WIDGET_TYPES.search,
    tagTypeSet,
    cohort,
  }).then((res) => {
    const data = parseUniversalSearchAutoCompleteResV1(res.entity, existingData);
    return callback(data);
  });
};

// This was made to keep things simple, pass the tagSet and return promise, not callback
export const universalSearchAutoCompleteV2 = ({ text, tagsSet }) =>
  searchAutoCompleteV1Api({
    text,
    widgetType: SEARCH_WIDGET_TYPES.search,
    tagTypeSet: tagsSet,
  }).then((res) => parseUniversalSearchAutoCompleteResV1(res.entity));

export const departmentAutoComplete = ({ text, tagsSet }) =>
  searchAutoCompleteV1Api({
    text,
    widgetType: SEARCH_WIDGET_TYPES.search,
    tagTypeSet: tagsSet,
  }).then((res) =>
    // filtering out empty string "" and null values
    res.entity.DEPARTMENT.filter(({ displayName }) => displayName)
  );

export const universalSearchAutoCompleteObjectives = ({ text, tagsSet, timePeriod }) =>
  searchAutoCompleteV1Api({
    text,
    widgetType: SEARCH_WIDGET_TYPES.goal,
    tagTypeSet: tagsSet,
    timePeriod,
  }).then((res) => parseUniversalSearchAutoCompleteObjectives(res.entity));

export function employeeAutocompleteForMention({ query, teamId, widgetType }) {
  // const tagType = 'EMPLOYEE_NAME';
  const tagType = null;
  return searchAutoCompleteApi({
    tagType,
    query,
    widgetType,
    teamId,
  }).then((res) =>
    res.entity.map((entry) => {
      if (entry.tagType === SEARCH_TAG_TYPES.employee) {
        const employee = new Employee(null, entry.employee);
        employee.name = employee.fullName; // adding name key because draft js expects a name key to be there to use for displaying mention name
        employee.uuid = employee.id;
        return { employee, tagType: entry.tagType, name: employee.name };
      }
      return {
        group: entry.group,
        tagType: entry.tagType,
        name: entry.group.displayName,
      };
    })
  );
}

export function employeeAutocompleteForMentionWithLimit({ query, teamId, widgetType, limit }) {
  // const tagType = 'EMPLOYEE_NAME';
  const tagType = 'EMPLOYEE_NAME_AND_EMAIL';
  return searchAutoCompleteApi({
    tagType,
    query,
    widgetType,
    teamId,
    limit,
  }).then((res) =>
    res.entity.map((entry) => {
      if (entry.tagType === SEARCH_TAG_TYPES.employee) {
        const employee = new Employee(null, entry.employee);
        employee.name = employee.fullName; // adding name key because draft js expects a name key to be there to use for displaying mention name
        employee.uuid = employee.id;
        const { metadata } = entry.employee;
        return { employee, tagType: entry.tagType, name: employee.name, metadata };
      }
      return {
        group: entry.group,
        tagType: entry.tagType,
        name: entry.group.displayName,
      };
    })
  );
}

export function employeeUniversalAutoSearch({ query, teamId, widgetType, limit, tagType }) {
  return searchAutoCompleteApi({
    tagType,
    query,
    widgetType,
    teamId,
    limit,
  }).then((res) =>
    res.entity.map((entry) => {
      if (entry.tagType === SEARCH_TAG_TYPES.employee) {
        const employee = new Employee(null, entry.employee);
        employee.name = employee.fullName; // adding name key because draft js expects a name key to be there to use for displaying mention name
        employee.uuid = employee.id;
        const { metadata } = entry.employee;

        return { employee, tagType: entry.tagType, name: employee.name, metadata };
      }
      return {
        group: entry.group,
        tagType: entry.tagType,
        name: entry.group.displayName,
      };
    })
  );
}

export function tagAutocomplete({ query, teamId, widgetType, employeeIds }) {
  const tagType = 'CORE_COMPETENCIES_TAG';
  return searchAutoCompleteApi({
    tagType,
    query,
    widgetType,
    teamId,
    employeeIds,
  }).then((res) => res.entity.map((object) => object.orgTag));
}

export function tagAutocompleteWithLimit({ query, teamId, widgetType, employeeIds, limit }) {
  const tagType = 'CORE_COMPETENCIES_TAG';
  return searchAutoCompleteApi({
    tagType,
    query,
    widgetType,
    teamId,
    employeeIds,
    limit,
  }).then((res) => res.entity.map((object) => object.orgTag));
}

export function driversAutocomplete({ query, teamId = null, widgetType }) {
  const tagType = 'ENGAGEMENT_DRIVER_TAG';
  return searchAutoCompleteApi({
    tagType,
    query,
    teamId,
    widgetType,
  }).then((res) => res.entity.map((object) => object.orgTag));
}

export const singleTagTypeAutocompleteV1 = ({ text, tagType, limit = 50, employeeIds }) => {
  const tagTypeSet = [
    {
      tagType,
      limit,
    },
  ];

  return searchAutoCompleteV1Api({
    text,
    widgetType: SEARCH_WIDGET_TYPES.search,
    tagTypeSet,
    employeeIds,
  }).then((res) => res.entity[tagType]);
};

export const NominationPeerSearch = ({ text, tagsSet, callback, cohort, empId }, existingData) => {
  searchAutoCompleteV1Api({
    text,
    widgetType: SEARCH_WIDGET_TYPES.search,
    tagTypeSet: tagsSet,
    cohort,
    empId,
  })
    .then((res) => {
      const data = parseUniversalSearchAutoCompleteResV1(res.entity, existingData);
      return callback(data);
    })
    .catch((err) =>
      callback([
        {
          value: 'error',
          label: err.message || 'Oops! Something went wrong!',
          isDisabled: true,
        },
      ])
    );
};

export const NominationPeerSearchByEmail = ({ text, tagType, callback, cohort, empId, limit }, existingData) => {
  searchAutoCompleteApi({
    query: text,
    widgetType: SEARCH_WIDGET_TYPES.search,
    tagType,
    cohort,
    empId,
    limit,
  })
    .then((res) => {
      // const data = parseUniversalSearchAutoCompleteResV1(res['entity'], existingData);
      // return callback(data);
      const data = res.entity.map((entry) => {
        if (entry.tagType === SEARCH_TAG_TYPES.employee) {
          const employee = new Employee(null, entry.employee);
          employee.name = employee.fullName; // adding name key because draft js expects a name key to be there to use for displaying mention name
          employee.uuid = employee.id;
          return {
            employee,
            ...employee,
            tagType: entry.tagType,
            name: employee.name,
            isDisabled:
              existingData && existingData.filter((r) => r.toString() === employee.uuid.toString()).length > 0,
          };
        }
        return {
          group: entry.group,
          tagType: entry.tagType,
          name: entry.group.displayName,
        };
      });
      return callback(data);
    })
    .catch((err) =>
      callback([
        {
          value: 'error',
          label: err.message || 'Oops! Something went wrong!',
          isDisabled: true,
        },
      ])
    );
};

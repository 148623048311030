import { schema } from 'normalizr';

// TODO: Normalize employee object as well
export const comment = new schema.Entity('comments', {});

export const comments = new schema.Array(comment);

export const post = new schema.Entity('posts', { comments: [comment] });

export const posts = new schema.Array(post);

export const team = new schema.Entity('teams', {});

export const employee = new schema.Entity('employees', { teams: [team] });

export const employees = new schema.Array(employee);

export const task = new schema.Entity('tasks', {
  from: employee,
  to: employee,
  toIds: [employee],
  lastEditedBy: employee,
});

export const tasks = new schema.Array(task);

import { extendTheme } from '@chakra-ui/react';

import { colors, typography } from '../tokens';

export const FONT_VARIANTS = {
  labelS: 'labelS',
  label: 'label',
  bodyM: 'bodyM',
  bodyL: 'bodyL',
  button: 'button',
  header4: 'header4',
  header3: 'header3',
  header2: 'header2',
  header1: 'header1',
};

export const FONT_WEIGHT_VARIANTS = {
  regular: 'regular',
  bold: 'bold',
};

const customFontStyles = {
  letterSpacing: {
    labelS: '0.5px',
    label: '0.25px',
    bodyM: 'normal',
    bodyL: '0.25px',
    button: '0.25px',
    header4: '0.25px',
    header3: '0.25px',
    header2: 'normal',
    header1: 'normal',
  },
  lineHeight: {
    labelS: typography.fontSizes.m,
    label: typography.fontSizes.m,
    bodyM: typography.fontSizes.l,
    bodyL: typography.fontSizes.l,
    button: typography.fontSizes.l,
    header4: typography.fontSizes.l,
    header3: typography.fontSizes.xl,
    header2: typography.fontSizes['3xl'],
    header1: typography.fontSizes['4xl'],
  },
  size: {
    labelS: typography.fontSizes['2xs'],
    label: typography.fontSizes.xs,
    bodyM: typography.fontSizes.s,
    bodyL: typography.fontSizes.m,
    button: typography.fontSizes.s,
    header4: typography.fontSizes.s,
    header3: typography.fontSizes.m,
    header2: typography.fontSizes.l,
    header1: typography.fontSizes.xl,
  },
  weight: {
    ...typography.fontWeights,
  },
};

const black = '#000';
const white = '#FFF';

export const themeColors = {
  common: {
    black,
    white,
    transparent: 'transparent',
  },
  brand: {
    red: colors['#EC3B64'],
    blue: colors['#008EF0'],
    green: colors['#5CC461'],
    yellow: colors['#FFC200'],
  },
  functional: {
    B96400: colors['#B96400'],
    '237C73': colors['#237C73'],
    '2F8133': colors['#2F8133'],
    C01443: colors['#C01443'],
    '1565BC': colors['#1565BC'],
    '7651BC': colors['#7651BC'],
    333333: colors['#333333'],
    C62C02: colors['#C62C02'],
    '8C6A00': colors['#8C6A00'],
    '4657AE': colors['#4657AE'],
    E6F1FD: colors['#E6F1FD'],
    DBF2D3: colors['#DBF2D3'],
    '44812F': colors['#44812F'],
    FFEFD4: colors['#FFEFD4'],
    '1A5724': colors['#1A5724'],
    '91CA7D': colors['#91CA7D'],
    FFE7C5: colors['#FFE7C5'],
    FFDE88: colors['#FFDE88'],
    FD9B9B: colors['#FD9B9B'],
    F6F6F7: colors['#F6F6F7'],
    AF5C20: colors['#AF5C20'], // <- functional/yellow,,
    A12402: colors['#A12402'],
    EAEAEA: colors['#EAEAEA'],
    CAE8F0: colors['#CAE8F0'],
  },
  primary: {
    FD812D: colors['#FD812D'],
    '5CC461': colors['#5CC461'],
    EC3B64: colors['#EC3B64'],
    '1565BC': colors['#1565BC'],
  },
  secondary: {
    '7E5BC0': colors['#7E5BC0'],
    666666: colors['#666666'],
    ED5554: colors['#ED5554'],
    F0B51E: colors['#F0B51E'],
    '5D6DBE': colors['#5D6DBE'],
    '2FA69A': colors['#2FA69A'],
  },
  feedback: {
    FFEFD5: colors['#FFEFD5'],
    C0FFF9: colors['#C0FFF9'],
    BFFFC2: colors['#BFFFC2'],
    FAD4D4: colors['#FAD4D4'],
    D6E7F9: colors['#D6E7F9'],
    E7DAFF: colors['#E7DAFF'],
    F2F2F2: colors['#F2F2F2'],
    FFE1D9: colors['#FFE1D9'],
    FEECBD: colors['#FEECBD'],
    CDD5FF: colors['#CDD5FF'],
    '91CA7D': colors['#91CA7D'],
    E6F1FD: colors['#E6F1FD'],
    DBF2D3: colors['#DBF2D3'],
    FFEFD4: colors['#FFEFD4'],
    BFE8C6: colors['#BFE8C6'],
    FFF6E4: colors['#FFF6E4'],
    FFF1E4: colors['#FFF1E4'],
    EFFFEA: colors['#EFFFEA'],
    FFF6D3: colors['#FFF6D3'],
  },
  text: {
    black,
    '1565BC': colors['#1565BC'],
    g1: colors['#333333'],
    g2: colors['#666666'],
    g3: colors['#999999'],
  },
  alerts: {
    negative: colors['#C62C02'],
    in_progress: colors['#FD812D'],
    positive: colors['#2F8133'],
    bgNegative: colors['#FFF9F7'],
  },
  neutral: {
    333333: colors['#333333'],
    666666: colors['#666666'],
    999999: colors['#999999'],
    F2F2F2: colors['#F2F2F2'],
    F5F7F9: colors['#F5F7F9'],
    EBEFF3: colors['#EBEFF3'],
    '595E6A': colors['#595E6A'],
    323846: colors['#323846'],
    CFD7DF: colors['#CFD7DF'],
    F6FAFE: colors['#F6FAFE'],
    CCCCCC: colors['#CCCCCC'],
    FDFDFD: colors['#FDFDFD'],
    EEEEEE: colors['#EEEEEE'],
  },
  button: {
    blue: {
      default: colors['#1565BC'],
      focus: colors['#104F95'],
      hover: colors['#125AA9'],
      disabled: colors['#99D2F9'],
      dropdownHover: colors['#E6F1FD'],
    },
    red: {
      default: colors['#C62C02'],
      focus: colors['#A12402'],
      hover: colors['#AA2602'],
      disabled: colors['#F6B9B9'],
    },
    green: {
      default: colors['#44812F'],
      focus: colors['#1A5724'],
      hover: colors['#237C73'],
      disabled: colors['#BFFFC2'],
    },
    grey: {
      default: colors['#666666'],
      focus: colors['#104F95'],
      hover: colors['#125AA9'],
      disabled: colors['#666666'],
    },
    black: {
      default: colors['#333333'],
      focus: colors['#000000'],
      hover: colors['#222222'],
      disabled: colors['#666666'],
    },
  },
  circularButton: {
    blue: {
      default: white,
      focus: colors['#104F95'],
      hover: colors['#125AA9'],
      // disabled: colors['#99D2F9']
    },
  },
  icon: {
    black,
    white,
    g1: colors['#333333'],
    g2: colors['#666666'],
    g3: colors['#999999'],
    666666: colors['#666666'],
    '1565BC': colors['#1565BC'],
  },
  surface: {
    white: colors['#FFFFFF'],
    F5F5F5: colors['#F5F5F5'],
    F5F7F9: colors['#F5F7F9'],
    EBEFF3: colors['#EBEFF3'],
    E6F1FD: colors['#E6F1FD'],
    FAFAFA: colors['#FAFAFA'],
    g2: colors['#666666'],
    g3: colors['#999999'],
    '104F95': colors['#104F95'],
    FDFDFD: colors['#FDFDFD'],
  },
  border: {
    '125AA9': colors['#125AA9'],
    CFD7DF: colors['#CFD7DF'],
    EBEFF3: colors['#EBEFF3'],
  },
  timeline: {
    CFD7DF: colors['#CFD7DF'],
    '99D2F9': colors['#99D2F9'],
    DBF2D3: colors['#DBF2D3'],
    '44812F': colors['#44812F'],
    FFEFD5: colors['#FFEFD5'],
    AF5C20: colors['#AF5C20'],
    g1: colors['#333333'],
  },
  borders: {
    '5CC461': colors['#5CC461'],
    FFC200: colors['#FFC200'],
    '7651BC': colors['#7651BC'],
    CCCCCC: colors['#CCCCCC'],
    EBEFF3: colors['#EBEFF3'],
    AF5C20: colors['#AF5C20'],
  },
  OKR_TYPES: {
    OBJECTIVE: '#7651BC',
    KEY_RESULT: '#4657AE',
  },
};

export const overrides = {
  styles: {
    global: {
      body: {
        bg: '#EBEFF3',
      },
    },
  },
  colors: themeColors,
  fonts: {
    body: "'Source Sans Pro', sans-serif",
    heading: "'Source Sans Pro', sans-serif",
    mono: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
  },
  // custom font properties to be appended to theme
  customFontStyles,
  shadows: {
    xSmall: '0px 0px 1px rgba(0, 0, 0, 0.25)',
    small: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    large: '2px 0px 4px 3px rgba(0, 0, 0, 0.12), 0px 4px 4px 3px rgba(0, 0, 0, 0.08)',
    search: '0px 0px 4px rgba(0, 142, 240, 0.2)',
    circularButton: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    paper: '0px 0px 2px 0px rgba(0, 0, 0, 0.24)',
  },
};

export const baseTheme = extendTheme(overrides);

import { getDateFromEpoch } from 'constants/helper';

import { FEED_TYPES } from 'services/helpers/feed';

import Deliverable from './deliverable';

class DeliverFeedItemData {
  constructor(params, json) {
    this.type = FEED_TYPES.deliver;
    if (params) this.fromParams(params);
    else if (json) this.fromJson(json);
  }

  fromParams = ({ deliver, createdDate }) => {
    this.deliver = deliver;
    this.createdDate = createdDate;
  };

  fromJson(res = {}) {
    this.deliver = Deliverable.getObjectFromRes(res.deliver);
    this.createdDate = getDateFromEpoch(res.createdTimestamp);
  }
}

export default DeliverFeedItemData;

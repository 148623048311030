import { HIDE_NOMINATE_PEERS_SIDEPANEL, SHOW_NOMINATE_PEERS_SIDEPANEL } from 'actions/actionTypes';

import { noOp } from 'constants/helper';

// a component should also pass onCancel and onSubmit callbacks, that way we can do a lot more

export const initialState = {
  display: false,
  reviewId: 0,
  empId: undefined,
  onSubmit: noOp,
  headerText: null,
  closingStatus: null,
  emp: undefined,
  nominationEndDateTS: null,
};

const nominatePeers = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOMINATE_PEERS_SIDEPANEL:
      return {
        ...state,
        display: true,
        reviewId: action.data.reviewId,
        empId: action.data.empId,
        onSubmit: action.data.onSubmit || noOp,
        headerText: action.data.headerText,
        closingStatus: null,
        emp: action.data.emp,
        nominationEndDateTS: action.data.nominationEndDateTS,
      };

    case HIDE_NOMINATE_PEERS_SIDEPANEL: {
      return {
        ...initialState,
        display: false,
        closingStatus: action?.data?.closingStatus || null,
      };
    }

    default:
      return state;
  }
};

export default nominatePeers;

import { Icon } from '@chakra-ui/react';

export const ViewMore = (props) => (
  <Icon viewBox="0 0 24 25" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_112_37)">
      <rect x="4.31781" y="4.98824" width="15" height="15" rx="1.5" stroke="#1565BC" />
      <path
        d="M14.3178 4.98824H17.8178C18.6462 4.98824 19.3178 5.65981 19.3178 6.48824V18.4882C19.3178 19.3167 18.6462 19.9882 17.8178 19.9882H14.3178V4.98824Z"
        fill="#1565BC"
        fillOpacity="0.2"
        stroke="#1565BC"
      />
      <path
        d="M6.81781 12.4194C6.81781 12.1432 7.04167 11.9194 7.31781 11.9194H12.3178C12.594 11.9194 12.8178 12.1432 12.8178 12.4194C12.8178 12.6955 12.594 12.9194 12.3178 12.9194H7.31781C7.04167 12.9194 6.81781 12.6955 6.81781 12.4194Z"
        fill="#1565BC"
      />
      <path
        d="M9.75723 9.72045C9.95249 9.52519 10.2691 9.52519 10.4643 9.72045L12.9908 12.2469C13.186 12.4422 13.186 12.7587 12.9908 12.954C12.7955 13.1493 12.4789 13.1493 12.2837 12.954L9.75723 10.4276C9.56196 10.2323 9.56196 9.91571 9.75723 9.72045Z"
        fill="#1565BC"
      />
      <path
        d="M9.87856 15.3774C10.0738 15.5726 10.3904 15.5726 10.5857 15.3774L12.9927 12.9703C13.188 12.7751 13.188 12.4585 12.9927 12.2632C12.7974 12.068 12.4809 12.068 12.2856 12.2632L9.87856 14.6703C9.6833 14.8655 9.6833 15.1821 9.87856 15.3774Z"
        fill="#1565BC"
      />
    </g>
    <defs>
      <clipPath id="clip0_112_37">
        <rect width="16" height="16" fill="white" transform="translate(4 4.43115)" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const ThumbsUpGenAi = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M20 13.5117C20 14.1675 19.6384 14.7356 19.1145 15.0068C19.2538 15.2547 19.3341 15.5437 19.3341 15.8522C19.3341 16.5628 18.9094 17.1706 18.3136 17.4088C18.4325 17.6425 18.5001 17.9095 18.5001 18.1926C18.5001 19.1105 17.792 19.8571 16.9215 19.8571H9.76501L7.89954 19.1152V20H4V8.92047H7.89954V9.86405H8.43567L11.3804 6.27213L11.6334 4.80212C11.7135 4.33735 12.0967 4 12.5448 4H13.0864C14.1293 4 14.9778 4.89467 14.9778 5.99424C14.9778 6.68542 14.8517 7.35986 14.6029 7.99929L14.0409 9.50675H17.9478C18.8182 9.50675 19.5265 10.2534 19.5265 11.1712C19.5265 11.515 19.427 11.8349 19.257 12.1004C19.7028 12.3949 20 12.9173 20 13.5117ZM4.9375 19.0115H6.96204V9.90897H4.9375V19.0115ZM17.9478 10.4952H12.665L13.7317 7.63427L13.7344 7.62706C13.9374 7.10656 14.0403 6.55709 14.0403 5.99424C14.0403 5.43963 13.6124 4.9885 13.0864 4.9885H12.5542L12.2571 6.71425L8.8645 10.8525H7.89954V18.0588L9.93591 18.8686H16.9215C17.275 18.8686 17.5626 18.5654 17.5626 18.1926C17.5626 17.8199 17.275 17.5167 16.9215 17.5167H14.5729V16.5282H17.7555C18.109 16.5282 18.3966 16.2249 18.3966 15.8522C18.3966 15.4794 18.109 15.1762 17.7555 15.1762H15.4069V14.1877H18.4214C18.7749 14.1877 19.0625 13.8844 19.0625 13.5117C19.0625 13.1388 18.7749 12.8357 18.4214 12.8357H15.4819V11.8472H17.9478C18.3014 11.8472 18.589 11.544 18.589 11.1712C18.589 10.7984 18.3014 10.4952 17.9478 10.4952Z"
      fill="currentColor"
    />
  </Icon>
);

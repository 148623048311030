import { Icon } from '@chakra-ui/react';

export const Details = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <circle cx="8.5" cy="9" r="0.5" fill="currentColor" />
    <path
      d="M10 9C10 8.72386 10.2239 8.5 10.5 8.5H15.5C15.7761 8.5 16 8.72386 16 9C16 9.27614 15.7761 9.5 15.5 9.5H10.5C10.2239 9.5 10 9.27614 10 9Z"
      fill="currentColor"
    />
    <circle cx="8.5" cy="12" r="0.5" fill="currentColor" />
    <path
      d="M10 12C10 11.7239 10.2239 11.5 10.5 11.5H15.5C15.7761 11.5 16 11.7239 16 12C16 12.2761 15.7761 12.5 15.5 12.5H10.5C10.2239 12.5 10 12.2761 10 12Z"
      fill="currentColor"
    />
    <circle cx="8.5" cy="15" r="0.5" fill="currentColor" />
    <path
      d="M10 15C10 14.7239 10.2239 14.5 10.5 14.5H15.5C15.7761 14.5 16 14.7239 16 15C16 15.2761 15.7761 15.5 15.5 15.5H10.5C10.2239 15.5 10 15.2761 10 15Z"
      fill="currentColor"
    />
    <rect x="4.5" y="5" width="15" height="14" rx="1.5" stroke="currentColor" />
  </Icon>
);

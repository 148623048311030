import { Icon } from '@chakra-ui/react';

export const AddKr = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M10 5L9 4H4V17H5V11H8L9 12H14V5H10ZM13 11H9.5L8.5 10H5V5H8.5L9.5 6H13V11Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19.5C16 19.7761 16.2239 20 16.5 20C16.7761 20 17 19.7761 17 19.5V17H19.5C19.7761 17 20 16.7761 20 16.5C20 16.2239 19.7761 16 19.5 16H17V13.5C17 13.2239 16.7761 13 16.5 13C16.2239 13 16 13.2239 16 13.5V16H13.5C13.2239 16 13 16.2239 13 16.5C13 16.7761 13.2239 17 13.5 17H16V19.5Z"
      fill="currentColor"
    />
  </Icon>
);

/* eslint-disable no-shadow */
import { EditorState } from 'draft-js';
import { UploadedImage } from 'interfaces/UploadedImage';

import { FeedbackType } from './postsRaw';

export enum ProgressKeys {
  BUILDING = 'BUILDING',
  EXCELLED = 'EXCELLED',
  NOT_CRITICAL = 'NOT_CRITICAL',
  SKILLED = 'SKILLED',
  STARTING = 'STARTING',
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
}

export enum PrimaryDrawerScreens {
  COMPETENCY = 'COMPETENCY',
  REQUEST_FEEDBACK = 'REQUEST_FEEDBACK',
  SHARE_FEEDBACK = 'SHARE_FEEDBACK',
}

export enum SecondaryDrawerScreens {
  REQUEST_FEEDBACK = 'REQUEST_FEEDBACK',
  SHARE_FEEDBACK = 'SHARE_FEEDBACK',
  GOAL_SELECTION = 'GOAL_SELECTION',
  MESH_MAVEN = 'MESH_MAVEN',
  ADD_INITIATIVE = 'ADD_INITIATIVE',
}

export interface CompetencyDrawerState {
  isOpen: boolean;
  progressScore: number;
  progressLabel: ProgressKeys;
  comment: EditorState;
  uploadedImage: UploadedImage;
  uploadedGif: UploadedImage;
  feedbackType: 'praise' | 'feedback';
  praiseOffset: number;
  adviceOffset: number;
  praisePosts: FeedbackType[];
  advicePosts: FeedbackType[];
  commentsOffset: number;
  comments: FeedbackType[];
  isAllCommentsFetched: boolean;
  isAllPraiseFetched: boolean;
  isAllAdviceFetched: boolean;
  isSecondaryPanelOpen: boolean;
  selectedPrimaryScreen: PrimaryDrawerScreens;
  selectedSecondaryScreen: SecondaryDrawerScreens;
}

import { Icon } from '@chakra-ui/react';

export const Department = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8302 4.51102C11.5509 4.08161 12.4491 4.08161 13.1698 4.51102L14.7698 5.46421C15.4618 5.87644 15.8857 6.62243 15.8857 7.42787V9.25118C15.8857 10.0566 15.4618 10.8026 14.7698 11.2148L13.1698 12.168C12.4491 12.5974 11.5509 12.5974 10.8302 12.168L9.23016 11.2148C8.5382 10.8026 8.11429 10.0566 8.11429 9.25117V7.42787C8.11429 6.62243 8.5382 5.87644 9.23016 5.46421L10.8302 4.51102ZM13.6 7.42787L12 6.47468L10.4 7.42787L10.4 9.25117L12 10.2044L13.6 9.25118V7.42787ZM6.68044 11.8142C7.40482 11.3783 8.31067 11.3778 9.0355 11.813L10.6623 12.7896C11.3504 13.2028 11.7714 13.9466 11.7714 14.7493V16.5486C11.7714 17.3512 11.3504 18.0951 10.6623 18.5082L9.0355 19.4849C8.31067 19.9201 7.40482 19.9196 6.68044 19.4837L5.10719 18.537C4.42016 18.1236 4 17.3804 4 16.5785L4 14.7194C4 13.9175 4.42016 13.1743 5.10719 12.7609L6.68044 11.8142ZM14.9444 11.8496C15.6652 11.4202 16.5633 11.4202 17.2841 11.8496L18.8841 12.8028C19.5761 13.215 20 13.961 20 14.7665V16.5606C20 17.366 19.5761 18.112 18.8841 18.5243L17.2841 19.4774C16.5633 19.9069 15.6652 19.9068 14.9444 19.4774L13.3444 18.5243C12.6525 18.112 12.2286 17.366 12.2286 16.5606V14.7665C12.2286 13.961 12.6525 13.215 13.3444 12.8028L14.9444 11.8496ZM9.48571 14.7493L7.85896 13.7726L6.28571 14.7194V16.5785L7.85896 17.5253L9.48571 16.5486L9.48571 14.7493ZM16.1143 13.8133L14.5143 14.7665V16.5606L16.1143 17.5138L17.7143 16.5606L17.7143 14.7665L16.1143 13.8133Z"
      fill="currentColor"
    />
  </Icon>
);

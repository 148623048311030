import { SHOW_IMAGE_PREVIEW_MODAL, HIDE_IMAGE_PREVIEW_MODAL } from 'actions/actionTypes';

const initialState = { visibility: false, images: [], currentIndex: 0 };

export default function imagePreview(state = initialState, action) {
  switch (action.type) {
    case SHOW_IMAGE_PREVIEW_MODAL:
      const newState = { images: action.data.images, visibility: true };
      return newState;
    case HIDE_IMAGE_PREVIEW_MODAL:
      return { ...initialState };
    default:
      return state;
  }
}

import { Icon } from '@chakra-ui/react';

export const ReOpen = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M13.8929 4.29289C13.7053 4.10536 13.451 4 13.1858 4H7.19998C6.31998 4 5.59998 4.72 5.59998 5.6V18.4C5.59998 19.28 6.31998 20 7.19998 20H16.8C17.68 20 18.4 19.28 18.4 18.4V9.21421C18.4 8.949 18.2946 8.69464 18.1071 8.50711L13.8929 4.29289ZM17.6 18C17.6 18.5523 17.1523 19 16.6 19H7.59998C7.04769 19 6.59998 18.5523 6.59998 18V6C6.59998 5.44772 7.04769 5 7.59998 5H13.6V7.8C13.6 8.35229 14.0477 8.8 14.6 8.8H17.6V18Z"
      fill="currentColor"
    />
    <path d="M11.6 7.59998V9.51998V10.48V12.4L9.19995 9.99998L11.6 7.59998Z" fill="currentColor" />
    <path
      d="M12.5739 10.0253L12.6362 9.52918L12.5739 10.0253ZM14.2959 10.8555L13.9466 11.2132L13.9466 11.2132L14.2959 10.8555ZM15.1669 12.5572L15.6613 12.4831L15.6613 12.4831L15.1669 12.5572ZM14.8331 14.4396L15.2719 14.6792L14.8331 14.4396ZM13.4301 15.7381L13.2251 15.2821L13.4301 15.7381ZM11.5276 15.9256L11.6396 15.4383L11.5276 15.9256ZM9.89813 14.9258L10.2818 14.6051L9.89813 14.9258ZM9.70284 13.121C9.68969 12.8451 9.45542 12.6322 9.1796 12.6453C8.90377 12.6585 8.69082 12.8928 8.70398 13.1686L9.70284 13.121ZM10.95 10.8368C11.4222 10.5642 11.9706 10.4534 12.5116 10.5214L12.6362 9.52918C11.8788 9.43406 11.1111 9.58914 10.45 9.97081L10.95 10.8368ZM12.5116 10.5214C13.0525 10.5893 13.5565 10.8323 13.9466 11.2132L14.6452 10.4977C14.0991 9.96443 13.3936 9.62431 12.6362 9.52918L12.5116 10.5214ZM13.9466 11.2132C14.3367 11.5941 14.5916 12.0921 14.6724 12.6314L15.6613 12.4831C15.5482 11.7282 15.1914 11.031 14.6452 10.4977L13.9466 11.2132ZM14.6724 12.6314C14.7532 13.1706 14.6555 13.7214 14.3942 14.2L15.2719 14.6792C15.6377 14.0092 15.7745 13.238 15.6613 12.4831L14.6724 12.6314ZM14.3942 14.2C14.1329 14.6785 13.7224 15.0585 13.2251 15.2821L13.6351 16.1942C14.3313 15.8812 14.9061 15.3492 15.2719 14.6792L14.3942 14.2ZM13.2251 15.2821C12.7278 15.5056 12.171 15.5605 11.6396 15.4383L11.4155 16.4128C12.1594 16.5839 12.9389 16.5071 13.6351 16.1942L13.2251 15.2821ZM11.6396 15.4383C11.1083 15.3161 10.6314 15.0235 10.2818 14.6051L9.51448 15.2464C10.004 15.8321 10.6716 16.2417 11.4155 16.4128L11.6396 15.4383ZM10.2818 14.6051C9.93211 14.1868 9.72881 13.6656 9.70284 13.121L8.70398 13.1686C8.74033 13.9311 9.02496 14.6607 9.51448 15.2464L10.2818 14.6051Z"
      fill="currentColor"
    />
  </Icon>
);

import { getDateFromEpoch } from 'constants/helper';

class Objective {
  constructor() {
    this.name = '';
    this.id = null;
    this.createdDate = null;
    this.updatedDate = null;
  }

  fromJSON(res = {}) {
    this.name = res.name;
    this.id = res.id;
    this.createdDate = getDateFromEpoch(res.createdTimestamp);
    this.updatedDate = getDateFromEpoch(res.updatedTimestamp);
  }

  static getObjectFromRes = (res = {}) => {
    const objective = new Objective();
    objective.fromJSON(res);
    return objective;
  };
}

export default Objective;

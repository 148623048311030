import { Icon } from '@chakra-ui/react';

export const DepartmentThin = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.75 5.72169C11.9047 5.63237 12.0953 5.63237 12.25 5.72169L14.7141 7.14434C14.8688 7.23365 14.9641 7.39872 14.9641 7.57735V10.4226C14.9641 10.6013 14.8688 10.7663 14.7141 10.8557L12.25 12.2783C12.0953 12.3676 11.9047 12.3676 11.75 12.2783L9.2859 10.8557C9.1312 10.7663 9.0359 10.6013 9.0359 10.4226V7.57735C9.0359 7.39872 9.1312 7.23365 9.2859 7.14434L11.75 5.72169Z"
      stroke="currentColor"
    />
    <path
      d="M7.75 12.3883C7.9047 12.299 8.0953 12.299 8.25 12.3883L10.7141 13.811C10.8688 13.9003 10.9641 14.0653 10.9641 14.244V17.0893C10.9641 17.2679 10.8688 17.433 10.7141 17.5223L8.25 18.9449C8.0953 19.0343 7.9047 19.0343 7.75 18.9449L5.2859 17.5223C5.1312 17.433 5.0359 17.2679 5.0359 17.0893V14.244C5.0359 14.0653 5.1312 13.9003 5.2859 13.811L7.75 12.3883Z"
      stroke="currentColor"
    />
    <path
      d="M15.75 12.3883C15.9047 12.299 16.0953 12.299 16.25 12.3883L18.7141 13.811C18.8688 13.9003 18.9641 14.0653 18.9641 14.244V17.0893C18.9641 17.2679 18.8688 17.433 18.7141 17.5223L16.25 18.9449C16.0953 19.0343 15.9047 19.0343 15.75 18.9449L13.2859 17.5223C13.1312 17.433 13.0359 17.2679 13.0359 17.0893V14.244C13.0359 14.0653 13.1312 13.9003 13.2859 13.811L15.75 12.3883Z"
      stroke="currentColor"
    />
  </Icon>
);

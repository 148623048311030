import { HELLO_BAR_ACTIONS } from 'actions/actionTypes';

export const initialState = {
  review: {
    count: null,
    refresh: 0,
  },
  engagement: {
    count: null,
    refresh: 0,
  },
};

const helloBarContext = (state = initialState, action) => {
  switch (action.type) {
    case HELLO_BAR_ACTIONS.SET_COUNT:
      return {
        ...state,
        [action.payload.param]: {
          ...state[action.payload.param],
          count: action.payload.value,
        },
      };
    case HELLO_BAR_ACTIONS.FORCE_REFRESH:
      return {
        ...state,
        [action.payload.param]: {
          ...state[action.payload.param],
          refresh: state[action.payload.param].refresh + 1,
        },
      };
    default:
      return state;
  }
};

export default helloBarContext;

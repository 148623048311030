import { Icon } from '@chakra-ui/react';

export const CloseIcon = (props) => (
  <Icon viewBox="0 0 20 20" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.575 7.24935L9.99996 8.82435L8.42496 7.24935C8.09996 6.92435 7.57496 6.92435 7.24996 7.24935C6.92496 7.57435 6.92496 8.09935 7.24996 8.42435L8.82496 9.99935L7.24996 11.5743C6.92496 11.8993 6.92496 12.4243 7.24996 12.7493C7.57496 13.0743 8.09996 13.0743 8.42496 12.7493L9.99996 11.1743L11.575 12.7493C11.9 13.0743 12.425 13.0743 12.75 12.7493C13.075 12.4243 13.075 11.8993 12.75 11.5743L11.175 9.99935L12.75 8.42435C13.075 8.09935 13.075 7.57435 12.75 7.24935C12.425 6.93268 11.8916 6.93268 11.575 7.24935ZM9.99996 1.66602C5.39163 1.66602 1.66663 5.39102 1.66663 9.99935C1.66663 14.6077 5.39163 18.3327 9.99996 18.3327C14.6083 18.3327 18.3333 14.6077 18.3333 9.99935C18.3333 5.39102 14.6083 1.66602 9.99996 1.66602ZM9.99996 16.666C6.32496 16.666 3.33329 13.6743 3.33329 9.99935C3.33329 6.32435 6.32496 3.33268 9.99996 3.33268C13.675 3.33268 16.6666 6.32435 16.6666 9.99935C16.6666 13.6743 13.675 16.666 9.99996 16.666Z"
      fill="currentColor"
    />
  </Icon>
);

import { createSlice } from '@reduxjs/toolkit';

export const INITIATIVE_CREATION_TEMPLATE = {
  text: '',
  to: null,
  macros: {
    employees: {},
    demographics: {},
    attributes: {
      orgTag: {},
      money: null,
    },
  },
  from: null,
  dueDate: null,
  dueTS: new Date().valueOf(),
  teams: [],
  toIds: [],
  taskType: 'TASK',
  parentPost: null,
  type: 'TASK',
  actual: 0,
  visibilityType: 'PRIVATE',
  startTS: new Date().valueOf(),
  description: null,
  tags: null,
  startingProgressValue: 0,
  weightage: 0,
  removedTeam: null,
};

const initialState = {
  isOpen: false,
  initiativeDetails: {
    ...INITIATIVE_CREATION_TEMPLATE,
  },
};

export const InitiativeCreationPanelSlice = createSlice({
  name: 'InitiativeCreationPanelSlice',
  initialState,
  reducers: {
    openPanel: (state, action) => {
      state.isOpen = true;
      state.initiativeDetails = {
        ...initialState.initiativeDetails,
        ...action.payload,
      };
    },
    updateInitiative: (state, action) => {
      state.initiativeDetails = { ...state.initiativeDetails, ...action.payload };
      return state;
    },
    closePanel: (state) => {
      state.isOpen = false;
      state.initiativeDetails = INITIATIVE_CREATION_TEMPLATE;
    },
  },
});

export const { openPanel, updateInitiative, closePanel } = InitiativeCreationPanelSlice.actions;

export default InitiativeCreationPanelSlice.reducer;

import { Icon } from '@chakra-ui/react';

export const ExpandAll = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12 5.83L14.46 8.29C14.85 8.68 15.48 8.68 15.87 8.29C16.26 7.9 16.26 7.27 15.87 6.88L12.7 3.7C12.31 3.31 11.68 3.31 11.29 3.7L8.12001 6.88C7.73001 7.27 7.73001 7.9 8.12001 8.29C8.51001 8.68 9.14001 8.68 9.53001 8.29L12 5.83ZM12 18.17L9.54001 15.71C9.15001 15.32 8.52001 15.32 8.13001 15.71C7.74001 16.1 7.74001 16.73 8.13001 17.12L11.3 20.3C11.69 20.69 12.32 20.69 12.71 20.3L15.88 17.13C16.27 16.74 16.27 16.11 15.88 15.72C15.49 15.33 14.86 15.33 14.47 15.72L12 18.17Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const PerformanceIconHover = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M4.82254 15.5373C4.29591 14.4708 4 13.2699 4 12C4 7.58172 7.58172 4 12 4C14.837 4 17.3291 5.47672 18.7495 7.70342L15.1362 9.73589L14.5 8.63397L16.1595 7.67584C15.3085 6.857 14.2163 6.28701 13 6.08296V8H11V6.08296C8.16229 6.55904 6 9.027 6 12C6 12.2511 6.01543 12.4987 6.0454 12.7417L7.09808 12.134L8.02347 13.7368L4.82254 15.5373Z"
      fill="#333333"
    />
    <path
      d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
      fill="#333333"
    />
    <path
      d="M20 11V15L18.6679 13.6679L11.8612 19.4274L9.77515 16.8198L6.52997 18.848L5.46997 17.152L10.2248 14.1802L12.1387 16.5726L17.2487 12.2488L16 11H20Z"
      fill="#333333"
    />
  </Icon>
);

import {
  UPDATE_LOGGED_IN_EMPLOYEE,
  AUTHENTICATE_LOGGED_IN_EMPLOYEE,
  LOGOUT,
  ATTEMPT_TO_AUTHENTICATE,
  SET_USER_TIMEZONE,
} from 'actions/actionTypes';

import { localStorageKeys } from 'constants/ProjectConstants';

const initialState = { data: null, isAuthenticated: null, isAuthenticating: false };

export default function loggedInEmployee(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOGGED_IN_EMPLOYEE: {
      const newState = { ...state };
      const payloadData = { ...action.data };
      newState.data = payloadData;
      return newState;
    }
    case AUTHENTICATE_LOGGED_IN_EMPLOYEE: {
      const newState = { ...state };
      newState.isAuthenticated = action.data;
      return newState;
    }
    case LOGOUT: {
      const newState = { ...initialState };
      localStorage.removeItem(localStorageKeys.selectedTeamInMeshList);
      localStorage.removeItem(localStorageKeys.selectedTaskType);
      // newState.isAuthenticated = false;
      return newState;
    }
    case ATTEMPT_TO_AUTHENTICATE: {
      const newState = { ...state };
      newState.isAuthenticating = action.data;
      return newState;
    }
    case SET_USER_TIMEZONE: {
      const newState = { ...state };
      newState.data.timezone = action.payload.timezone;
      return newState;
    }
    default:
      return state;
  }
}

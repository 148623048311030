import { Modifier, EditorState, convertToRaw } from 'draft-js';

export function addMention(editorState, mention, mentionPrefix, mentionTrigger, entityMutability) {
  console.log('addMention -> mentionTrigger', mentionTrigger);
  console.log('addMention -> mentionPrefix', mentionPrefix);
  console.log('addMention -> mention', mention);
  const contentStateWithEntity = editorState
    .getCurrentContent()
    .createEntity(getTypeByTrigger(mentionTrigger), entityMutability, { mention });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const currentSelectionState = editorState.getSelection();
  const { begin, end } = getSearchText(editorState, currentSelectionState, mentionTrigger);

  // get selection of the @mention search text
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: end,
    focusOffset: end,
  });

  let mentionReplacedContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    mentionTextSelection,
    `${mentionPrefix}${mention.name}`,
    null, // no inline style needed
    entityKey
  );

  // If the mention is inserted at the end, a space is appended right after for
  // a smooth writing experience.
  const blockKey = mentionTextSelection.getAnchorKey();
  const blockSize = editorState.getCurrentContent().getBlockForKey(blockKey).getLength();
  if (blockSize === end) {
    mentionReplacedContent = Modifier.insertText(
      mentionReplacedContent,
      mentionReplacedContent.getSelectionAfter(),
      ' '
    );
  }

  const newEditorState = EditorState.push(editorState, mentionReplacedContent, 'insert-mention');
  const rawState = convertToRaw(newEditorState.getCurrentContent());
  return EditorState.forceSelection(newEditorState, mentionReplacedContent.getSelectionAfter());
}

// export function addMoney({ getEditorState, text, mentionPrefix, begin, end }) {
//   const editorState = getEditorState();
//   const contentStateWithEntity = editorState
//     .getCurrentContent()
//     .createEntity('meshMoney', 'IMMUTABLE', {
//       text
//     });
//   const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

//   const currentSelectionState = editorState.getSelection();

//   // get selection of the @mention search text
//   const mentionTextSelection = currentSelectionState.merge({
//     anchorOffset: end,
//     focusOffset: end
//   });

//   let mentionReplacedContent = Modifier.replaceText(
//     editorState.getCurrentContent(),
//     mentionTextSelection,
//     `${mentionPrefix}${mention.name}`,
//     null, // no inline style needed
//     entityKey
//   );

//   // If the mention is inserted at the end, a space is appended right after for
//   // a smooth writing experience.
//   const blockKey = mentionTextSelection.getAnchorKey();
//   const blockSize = editorState
//     .getCurrentContent()
//     .getBlockForKey(blockKey)
//     .getLength();
//   if (blockSize === end) {
//     mentionReplacedContent = Modifier.insertText(
//       mentionReplacedContent,
//       mentionReplacedContent.getSelectionAfter(),
//       ' '
//     );
//   }

//   const newEditorState = EditorState.push(editorState, mentionReplacedContent, 'insert-mention');
//   return EditorState.forceSelection(newEditorState, mentionReplacedContent.getSelectionAfter());
// }

function getSearchTextAt(blockText, position, trigger) {
  const str = blockText.substr(0, position);
  const begin = trigger.length === 0 ? 0 : str.lastIndexOf(trigger);
  const matchingString = trigger.length === 0 ? str : str.slice(begin + trigger.length);
  const end = str.length;

  return {
    begin,
    end,
    matchingString,
  };
}

function getSearchText(editorState, selection, trigger) {
  const anchorKey = selection.getAnchorKey();
  const anchorOffset = selection.getAnchorOffset();
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(anchorKey);
  const blockText = currentBlock.getText();
  return getSearchTextAt(blockText, anchorOffset, trigger);
}

function getTypeByTrigger(trigger) {
  return trigger === '@' ? 'mention' : `${trigger}mention`;
}

export default getTypeByTrigger;

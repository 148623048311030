import { CREATE_KR_PANEL_OPEN, CREATE_KR_PANEL_CLOSE } from 'actions/actionTypes';

import { noOp } from 'constants/helper';

const initialState = {
  isOpen: false,
  onClose: noOp,
  onCancel: noOp,
  onSave: noOp,
  selectedTimeBoundPeriod: null,
  baseKR: {},
  ownerEmployee: null,
  subObjective: null,
  actionDispatchSource: '',
  panelHeaderText: 'Add goal',
};

const createKRPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_KR_PANEL_OPEN: {
      const {
        onClose,
        onCancel,
        onSave,
        selectedTimeBoundPeriod,
        baseKR,
        ownerEmployee,
        subObjective,
        actionDispatchSource,
        panelHeaderText,
      } = action.payload;

      return {
        isOpen: true,
        onClose,
        onCancel,
        onSave,
        selectedTimeBoundPeriod,
        baseKR,
        ownerEmployee,
        subObjective,
        actionDispatchSource,
        panelHeaderText,
      };
    }

    case CREATE_KR_PANEL_CLOSE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default createKRPanelReducer;

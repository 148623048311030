import { Icon } from '@chakra-ui/react';

export const KeyResultPrivate2 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M10 5L9 4H4V17H5V11H8L9 12H14V5H10ZM13 11H9.5L8.5 10H5V5H8.5L9.5 6H13V11Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7435 14.1988C16.5907 14.42 16.4942 14.7613 16.4942 15.1875H18.2428C18.2428 14.7634 18.1445 14.4211 17.991 14.1985C17.8485 13.9921 17.6528 13.875 17.375 13.875C17.0817 13.875 16.8841 13.9953 16.7435 14.1988ZM16.0237 13.7014C16.3207 13.2715 16.7823 13 17.375 13C17.9596 13 18.4166 13.2747 18.7111 13.7016C18.9946 14.1124 19.1178 14.6451 19.1178 15.1875H19.125C19.6082 15.1875 20 15.5793 20 16.0625V19.125C20 19.6082 19.6082 20 19.125 20H15.625C15.1418 20 14.75 19.6082 14.75 19.125V16.0625C14.75 15.5793 15.1418 15.1875 15.625 15.1875H15.6192C15.6192 14.6472 15.7389 14.1135 16.0237 13.7014ZM15.625 16.0625V19.125H19.125V16.0625H15.625Z"
      fill="currentColor"
    />
  </Icon>
);

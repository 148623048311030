import { Icon } from '@chakra-ui/react';

export const PreviousReviews = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12.6366 7.49999C12.6366 7.22385 12.8808 6.99999 13.1821 6.99999H14.7277C15.029 6.99999 15.2732 7.22385 15.2732 7.49999C15.2732 7.77613 15.029 7.99999 14.7277 7.99999H13.1821C12.8808 7.99999 12.6366 7.77613 12.6366 7.49999Z"
      fill="currentColor"
    />
    <path
      d="M12.6366 10.5C12.6366 10.2238 12.8808 9.99998 13.1821 9.99998H14.7277C15.029 9.99998 15.2732 10.2238 15.2732 10.5C15.2732 10.7761 15.029 11 14.7277 11H13.1821C12.8808 11 12.6366 10.7761 12.6366 10.5Z"
      fill="currentColor"
    />
    <path
      d="M7.63659 13.5C7.63659 13.2238 7.88081 13 8.18206 13H11.4549C11.7561 13 12.0004 13.2238 12.0004 13.5C12.0004 13.7761 11.7561 14 11.4549 14H8.18206C7.88081 14 7.63659 13.7761 7.63659 13.5Z"
      fill="currentColor"
    />
    <path
      d="M7.63659 16.5C7.63659 16.2238 7.88081 16 8.18206 16H10.3639C10.6652 16 10.9094 16.2238 10.9094 16.5C10.9094 16.7761 10.6652 17 10.3639 17H8.18206C7.88081 17 7.63659 16.7761 7.63659 16.5Z"
      fill="currentColor"
    />
    <path
      d="M4.36377 5.99999C4.36377 4.89543 5.34063 4 6.54565 4H16.3641C17.5691 4 18.546 4.89543 18.546 5.99999V12.3321C18.1724 12.2003 17.7764 12.1048 17.3642 12.0509V5.99999C17.3642 5.44771 16.8757 5 16.2732 5H6.63656C6.03405 5 5.54562 5.44771 5.54562 5.99999V18C5.54562 18.5522 6.03405 18.9999 6.63656 18.9999H12.5392C12.9027 19.3934 13.3449 19.7324 13.8456 19.9999H6.54565C5.34063 19.9999 4.36377 19.1045 4.36377 18V5.99999Z"
      fill="currentColor"
    />
    <path
      d="M9.5 11.0184L10.6382 11.7416C10.7885 11.837 10.9792 11.7033 10.9407 11.5295L10.635 10.15L11.6639 9.21179C11.7925 9.0945 11.7203 8.88028 11.5469 8.8648L10.2025 8.74474L9.68553 7.46077C9.61826 7.29371 9.38174 7.29371 9.31447 7.46077L8.7975 8.74474L7.45311 8.8648C7.27974 8.88028 7.20752 9.0945 7.33614 9.21179L8.365 10.15L8.05928 11.5295C8.02075 11.7033 8.2115 11.837 8.36179 11.7416L9.5 11.0184Z"
      fill="currentColor"
    />
    <path
      d="M15.2732 16.4998C15.2732 16.2237 15.5174 15.9998 15.8187 15.9998H16.9096C17.2109 15.9998 17.4551 16.2237 17.4551 16.4998C17.4551 16.776 17.2109 16.9998 16.9096 16.9998H15.8187C15.5174 16.9998 15.2732 16.776 15.2732 16.4998Z"
      fill="currentColor"
    />
    <path
      d="M15.8187 16.9998C15.5174 16.9998 15.2732 16.776 15.2732 16.4998V14.9998C15.2732 14.7236 15.5174 14.4998 15.8187 14.4998C16.1199 14.4998 16.3641 14.7236 16.3641 14.9998V16.4999C16.3641 16.776 16.1199 16.9998 15.8187 16.9998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8184 19.9994C17.927 19.9994 19.6364 18.4326 19.6364 16.4997C19.6364 14.5669 17.927 13 15.8184 13C13.7097 13 12.0004 14.5669 12.0004 16.4997C12.0004 18.4326 13.7097 19.9994 15.8184 19.9994ZM15.8187 19C17.3249 19 18.546 17.8807 18.546 16.5C18.546 15.1193 17.3249 14 15.8187 14C14.3124 14 13.0913 15.1193 13.0913 16.5C13.0913 17.8807 14.3124 19 15.8187 19Z"
      fill="currentColor"
    />
  </Icon>
);

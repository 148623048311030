import { REACTIONS } from 'constants/ProjectConstants';

// TODO: make a class named feedItemData and parse all the common feed data there and then inherit that class
export default class FeedReactions {
  constructor(props, json) {
    if (props) this.fromProps(props);
    else if (json) this.fromJSON(json);
  }

  fromProps({
    emotionsCount = 0,
    commentsCount = 0,
    isLiked = false,
    likesCount = 0,
    applauseCount = 0,
    isApplauded = false,
    isCommented = false,
  }) {
    this.emotionsCount = emotionsCount;
    this.commentsCount = commentsCount;
    this.isLiked = isLiked;
    this.likesCount = likesCount;
    this.applauseCount = applauseCount;
    this.isApplauded = isApplauded;
    this.isCommented = isCommented;
  }

  fromJSON(json = {}) {
    const emotionsCount = json.overallEmotionCount;
    // adding empty value when no overallReaction is present to avoid error
    json.overallReaction = json.overallReaction || { emotion: {} };
    // const isLiked = json.userEmotions && json.userEmotion.toLowerCase() === 'like';
    json.userEmotions = json.userEmotions || [];
    const isLiked = json.userEmotions.findIndex((reaction) => reaction === REACTIONS.like) !== -1;
    const isApplauded = json.userEmotions.findIndex((reaction) => reaction === REACTIONS.applause) !== -1;
    const isCommented = json.userEmotions.findIndex((reaction) => reaction === REACTIONS.comment) !== -1;
    const commentsCount = json.overallReaction.COMMENT;
    const likesCount = json.overallReaction.emotion.LIKE || 0;
    const applauseCount = json.overallReaction.emotion.APPLAUSE || 0;
    // const isApplauded = json.userEmotions.filter()
    this.fromProps({
      emotionsCount,
      isLiked,
      commentsCount,
      likesCount,
      applauseCount,
      isApplauded,
      isCommented,
    });
  }

  static getObject({
    emotionsCount = 0,
    commentsCount = 0,
    isLiked = false,
    likesCount,
    applauseCount,
    isApplauded,
    isCommented,
  }) {
    return new FeedReactions({
      emotionsCount,
      commentsCount,
      isLiked,
      likesCount,
      applauseCount,
      isApplauded,
      isCommented,
    });
  }
}

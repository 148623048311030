import { Icon } from '@chakra-ui/react';

export const KeyResultWithoutPadding = (props) => (
  <Icon viewBox="0 0 12 13" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8 1.5L7 0.5H0V12.5H1V8.5H6L7 9.5H12V1.5H8ZM11 8.5H7.5L6.5 7.5H1V1.5H6.5L7.5 2.5H11V8.5Z"
      fill="currentColor"
    />
  </Icon>
);

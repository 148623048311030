export const localStorageService = {
  // Get item from localStorage
  getItem: (key) => {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        // Parse JSON data
        return JSON.parse(item);
      } catch (error) {
        console.error('Error parsing localStorage item:', error);
        return null;
      }
    }
    return null;
  },

  // Set item in localStorage
  setItem: (key, value) => {
    try {
      // Stringify value before saving
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  },
};

export default localStorageService;

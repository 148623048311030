import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  contentComponent: null,
  wide: true,
};

export const keyResultPromptSlice = createSlice({
  name: 'keyResultPromptModal',
  initialState,
  reducers: {
    openKRPromptModal: (state, action) => {
      state.isOpen = true;
      state.contentComponent = action.payload;
    },
    closeKRPromptModal: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openKRPromptModal, closeKRPromptModal } = keyResultPromptSlice.actions;

export default keyResultPromptSlice.reducer;

import {
  GLOBAL_GOALS_MAP_ADD_GOALS,
  GLOBAL_GOALS_MAP_DELETE_GOAL,
  GLOBAL_GOALS_MAP_UPDATE_GOAL,
} from 'actions/actionTypes';
import { cloneDeep } from 'lodash';

/* 
Expected state strcuture.

  {
    [goal.id]: {
      isDeleted: false,
      data: {...goal},
      metadata: {} // this might contain info about when was this goal added etc.
    }
  }

*/

const globalGoalsReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_GOALS_MAP_UPDATE_GOAL: {
      const { goal } = payload;
      return {
        ...state,
        [goal.id]: {
          data: cloneDeep(goal),
          metadata: {},
        },
      };
    }
    case GLOBAL_GOALS_MAP_ADD_GOALS: {
      const { goals } = payload;

      const goalMap = goals.reduce(
        (_map, goal) =>
          goal.id
            ? {
                ..._map,
                [goal.id]: {
                  data: cloneDeep(goal),
                  metadata: {},
                },
              }
            : _map,
        {}
      );

      return {
        ...state,
        ...goalMap,
      };
    }
    case GLOBAL_GOALS_MAP_DELETE_GOAL: {
      const { goalId } = payload;
      return {
        ...state,
        [goalId]: {
          isDeleted: true,
          data: null,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default globalGoalsReducer;

import { Icon } from '@chakra-ui/react';

export const CloneWithoutPadding = (props) => (
  <Icon viewBox="0 0 14 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.2728 0H1.54549C0.745488 0 0.0909424 0.654545 0.0909424 1.45455V11.6364H1.54549V1.45455H10.2728V0ZM9.54549 2.90909H4.45458C3.65458 2.90909 3.00731 3.56364 3.00731 4.36364L3.00003 14.5455C3.00003 15.3455 3.64731 16 4.44731 16H12.4546C13.2546 16 13.9091 15.3455 13.9091 14.5455V7.27273L9.54549 2.90909ZM4.45458 14.5455V4.36364H8.81822V8H12.4546V14.5455H4.45458Z"
      fill="currentColor"
    />
  </Icon>
);

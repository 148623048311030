import { Icon } from '@chakra-ui/react';

export const CallMissedOutgoing = (props) => (
  <Icon viewBox="0 0 20 20" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M2.91133 7.59427L9.23633 13.9193C9.56133 14.2443 10.0863 14.2443 10.4113 13.9193L15.6613 8.66927V11.6693C15.6613 12.1276 16.0363 12.5026 16.4947 12.5026C16.953 12.5026 17.328 12.1276 17.328 11.6693V6.66927C17.328 6.21094 16.953 5.83594 16.4947 5.83594H11.4947C11.0363 5.83594 10.6613 6.21094 10.6613 6.66927C10.6613 7.1276 11.0363 7.5026 11.4947 7.5026H14.4863L9.828 12.1609L4.08633 6.41927C3.76133 6.09427 3.23633 6.09427 2.91133 6.41927C2.59466 6.74427 2.59466 7.2776 2.91133 7.59427Z"
      fill="currentColor"
    />
  </Icon>
);

export default class OrgObjective {
  constructor({
    objectiveTimeBoundId = null,
    organizationId = null,
    objectiveId = null,
    id,
    name = '',
    displayName = '',
    departmentId = null,
    departmentName = null,
    // objectiveInstanceType = '',
    timeBoundDetails = null,
    description = '',
    objectiveType = 'GOAL',
    tagType = 'GOAL',
    objectiveLevel = null,
    parentTag = null,
  }) {
    this.objectiveTimeBoundId = objectiveTimeBoundId;
    this.organizationId = organizationId;
    this.objectiveId = objectiveId || id;
    this.id = objectiveId || id;
    this.name = name;
    this.displayName = displayName;
    this.departmentId = departmentId;
    this.departmentName = departmentName;
    // // this.objectiveInstanceType = objectiveInstanceType;
    this.timeBoundDetails = timeBoundDetails;

    this.description = description || '';
    this.objectiveType = objectiveType;
    this.tagType = objectiveType || tagType;
    this.objectiveLevel = objectiveLevel ?? null;
    this.parentTag = parentTag ?? null;
  }

  static getAPIStructure(objective, renameObjective = false) {
    return {
      uuid: objective.objectiveTimeBoundId,
      organizationId: objective.organizationId,
      organizationTag: {
        uuid: renameObjective ? null : objective.objectiveId,
        name: objective.name,
        displayName: objective.displayName,
        // tagType: objective.objectiveInstanceType
      },
      departmentId: objective.departmentId,
      departmentName: objective.departmentName,
      parentTag: objective.parentTag ? { ...objective.parentTag, uuid: objective.parentTag.id } : null,
      timeBoundDetails: objective.timeBoundDetails,
      description: objective.description,
      tagType: objective.objectiveType,
      objectiveLevel: objective.objectiveLevel,
    };
  }

  static fromJSON(json) {
    if (!json) return;
    const objectiveTimeBoundId = json.uuid;
    const { organizationId } = json;
    const objectiveId = json.organizationTag.uuid;
    const id = json.organizationTag.uuid;
    const { name } = json.organizationTag;
    const { displayName } = json.organizationTag;
    // const objectiveInstanceType = json.organizationTag.tagType;

    let parentTag;
    if (json.parentTag) {
      parentTag = {
        ...json.parentTag,
        id: json.parentTag.uuid,
      };
    }
    const { departmentId } = json;
    const { departmentName } = json;
    const { timeBoundDetails } = json;
    const { description } = json;
    const objectiveType = json.tagType;
    const { tagType } = json;
    const { objectiveLevel } = json;

    return new OrgObjective({
      objectiveTimeBoundId,
      organizationId,
      objectiveId,
      departmentId,
      departmentName,
      id,
      name,
      displayName,
      // objectiveInstanceType,
      timeBoundDetails,
      description,
      objectiveType,
      tagType,
      objectiveLevel,
      parentTag,
    });
  }
}

export const typography = {
  fontSizes: {
    // '4xs': '0.5rem', // 8px
    // '3xs': '0.625rem', // 10px
    // '2xs': '0.6875rem', // 11px
    '2xs': '0.625rem', // 10px
    xs: '0.75rem', // 12px
    s: '0.875rem', // 14px
    m: '1.0rem', // 16px
    l: '1.25rem', // 20px
    xl: '1.5rem', // 24px
    // '2xl': '1.75rem', // 28px
    '3xl': '2.0rem', // 32px
    '4xl': '2.5rem', // 40px
    // '5xl': '2.8125rem', // 45px
    // '6xl': '3.5625rem' // 57px
  },
  fontWeights: {
    regular: 400,
    bold: 600,
  },
};

import { Icon } from '@chakra-ui/react';

export const Hashtag = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <rect width="24" height="24" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.58789 20.0472L10.1644 3.95288H11.0585L7.48202 20.0472H6.58789ZM16.4233 3.95288L17.3174 3.95288L13.7409 20.0472H12.8468L16.4233 3.95288Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.773 9.31759H5.21875L5.43705 8.42346H19.9998L19.773 9.31759Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5315 15.1295H4L4.20852 14.2354H18.7391L18.5315 15.1295Z"
      fill="currentColor"
    />
  </Icon>
);

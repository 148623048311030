import { Icon } from '@chakra-ui/react';

export const Leaders = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_557_92)">
      <path
        d="M6.5 12.6424C6.5 11.8895 7.11033 11.2792 7.86321 11.2792C7.99183 11.2792 8.10679 11.3595 8.15107 11.4802L10.7485 18.564C11.0046 19.2625 11.9901 19.2691 12.2556 18.5741L14.9811 11.4385L14.514 11.2601L14.9811 11.4385C15.0177 11.3425 15.1098 11.2792 15.2124 11.2792C16.0628 11.2792 16.7522 11.9686 16.7522 12.819V18.1733C16.7522 18.9065 16.1578 19.5009 15.4246 19.5009H7.82765C7.09441 19.5009 6.5 18.9065 6.5 18.1733V12.6424Z"
        stroke="currentColor"
        fill="transparent"
      />
      <path
        d="M10.574 13.3454L11.6828 16.6718L12.7916 13.3454L11.6828 11.6821L10.574 13.3454Z"
        stroke="currentColor"
        fill="transparent"
      />
      <circle cx="11.6829" cy="7.80206" r="2.82647" stroke="currentColor" fill="transparent" />
      <path
        d="M20.5535 6.52257C20.5535 6.61959 20.5535 6.81364 20.4565 6.91066C20.3595 7.00768 20.2625 7.1047 20.1655 7.1047C20.0684 7.1047 19.9714 7.00768 19.8744 6.91066L18.6131 5.64937V9.33621C18.6131 9.62728 18.4191 9.82132 18.128 9.82132C17.8369 9.82132 17.6429 9.62728 17.6429 9.33621V5.64937L16.6727 6.81364C16.5756 6.91066 16.3816 7.00768 16.1876 6.91066C15.9935 6.91066 15.8965 6.71662 15.8965 6.52257C15.8965 6.32853 15.8965 6.13448 15.9935 6.03746L17.8369 4.09702C17.8369 4.09702 17.8369 4.09702 17.9339 4H18.031C18.128 4 18.128 4 18.225 4C18.322 4 18.322 4 18.4191 4L18.5161 4.09702H18.6131L20.4565 6.03746C20.5535 6.23151 20.5535 6.32853 20.5535 6.52257Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_557_92">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export type rowData = {
  uuid: string;
  progressLevel: string;
  closureStatus: string;
  achieved: string;
  newComment: string;
  commentPayloadType: unknown;
  hasError?: boolean;
  title: string;
};

const initialState: {
  editableRowData: rowData[];
  initialRowData: rowData[];
} = {
  editableRowData: [],
  initialRowData: [],
};

export const checkInIntentSlice = createSlice({
  name: 'checkInIntent',
  initialState,
  reducers: {
    setEditableRowData: (state, action: PayloadAction<{ rowData: rowData[] }>) => {
      state.editableRowData = action.payload.rowData;
    },
    setInitialRowData: (state, action: PayloadAction<{ rowData: rowData[] }>) => {
      state.initialRowData = action.payload.rowData;
    },
    updateEditableRowData: (state, action: PayloadAction<{ uuid: string; updatedData: { uuid: string } }>) => {
      const index = state.editableRowData.findIndex((row) => row.uuid === action.payload.uuid);
      if (index !== -1) {
        state.editableRowData[index] = { ...state.editableRowData[index], ...action.payload.updatedData };
      }
    },
  },
});

export const { updateEditableRowData, setEditableRowData, setInitialRowData } = checkInIntentSlice.actions;

export default checkInIntentSlice.reducer;

export const useSelectEditableRowData = () =>
  useSelector((state: { checkInIntent: typeof initialState }) => state.checkInIntent.editableRowData);

export const useSelectInitialRowDataCheckIn = () =>
  useSelector((state: { checkInIntent: typeof initialState }) => state.checkInIntent.initialRowData);

import { Icon } from '@chakra-ui/react';

export const NoRecommendationsIcon = (props) => (
  <Icon viewBox="0 0 48 54" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M0.5 20.0133C0.5 15.8056 2.79803 11.9338 6.49168 9.91838L18.1167 3.5752C21.55 1.70183 25.7 1.70183 29.1333 3.5752L40.7583 9.91838C44.452 11.9338 46.75 15.8056 46.75 20.0134V34.358C46.75 38.6346 44.3768 42.5581 40.5889 44.5435L28.9639 50.637C25.6204 52.3896 21.6296 52.3896 18.2861 50.637L6.66106 44.5435C2.87321 42.5581 0.5 38.6346 0.5 34.358V20.0133Z"
      fill="#EBEFF3"
      stroke="#666666"
    />
    <path
      d="M29.4088 28.2954L22.1054 26.5053C21.7805 26.3232 21.5132 26.0487 21.3359 25.7151L19.5929 18.2156C19.4562 17.9594 19.194 17.8 18.9093 17.8C18.6246 17.8 18.3628 17.9594 18.2257 18.2156L16.4777 25.7151C16.3 26.0487 16.0331 26.3228 15.7081 26.5053L8.40472 28.2954C8.15522 28.4358 8 28.7051 8 28.9974C8 29.2897 8.15522 29.5586 8.40472 29.6994L15.7081 31.4895C16.0331 31.6716 16.3 31.946 16.4777 32.2793L18.2206 39.7844C18.3573 40.0406 18.6195 40.2 18.9042 40.2C19.1889 40.2 19.4507 40.0406 19.5878 39.7844L21.3307 32.285C21.5081 31.9513 21.775 31.6768 22.0999 31.4947L29.4088 29.7046C29.6609 29.565 29.8183 29.2942 29.8183 29C29.8183 28.7058 29.6609 28.4351 29.4088 28.2954Z"
      fill="#666666"
    />
    <path
      d="M37.045 19.7348L33.6624 18.9272C33.5119 18.845 33.3881 18.7212 33.306 18.5707L32.4987 15.1875C32.4354 15.0719 32.314 15 32.1821 15C32.0503 15 31.929 15.0719 31.8655 15.1875L31.0559 18.5707C30.9736 18.7212 30.85 18.8449 30.6995 18.9272L27.3168 19.7348C27.2013 19.7981 27.1294 19.9196 27.1294 20.0514C27.1294 20.1833 27.2013 20.3046 27.3168 20.3681L30.6995 21.1757C30.85 21.2578 30.9736 21.3817 31.0559 21.532L31.8631 24.9178C31.9265 25.0334 32.0479 25.1053 32.1797 25.1053C32.3116 25.1053 32.4329 25.0334 32.4964 24.9178L33.3036 21.5346C33.3857 21.384 33.5094 21.2602 33.6599 21.1781L37.045 20.3705C37.1618 20.3075 37.2347 20.1854 37.2347 20.0526C37.2347 19.9199 37.1618 19.7978 37.045 19.7348Z"
      fill="#666666"
    />
    <path
      d="M35.8293 34.6563L33.2923 34.0507C33.1795 33.989 33.0866 33.8962 33.025 33.7833L32.4196 31.2459C32.3721 31.1592 32.281 31.1053 32.1821 31.1053C32.0832 31.1053 31.9923 31.1592 31.9447 31.2459L31.3375 33.7833C31.2757 33.8962 31.183 33.9889 31.0701 34.0507L28.5332 34.6563C28.4465 34.7038 28.3926 34.7949 28.3926 34.8938C28.3926 34.9928 28.4465 35.0837 28.5332 35.1314L31.0701 35.737C31.183 35.7986 31.2757 35.8915 31.3375 36.0043L31.9429 38.5436C31.9904 38.6303 32.0815 38.6842 32.1803 38.6842C32.2792 38.6842 32.3702 38.6303 32.4178 38.5436L33.0232 36.0062C33.0848 35.8933 33.1776 35.8004 33.2904 35.7388L35.8293 35.1331C35.9169 35.0859 35.9715 34.9943 35.9715 34.8947C35.9715 34.7952 35.9169 34.7036 35.8293 34.6563Z"
      fill="#666666"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Manager = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.9499 9.85C10.4499 9.85 9.1499 8.55 9.1499 7.05C9.1499 5.45 10.3499 4.25 11.9499 4.25C13.4499 4.25 14.7499 5.55 14.7499 7.05C14.7499 8.65 13.5499 9.85 11.9499 9.85ZM11.9499 6.25C11.5499 6.25 11.1499 6.65 11.1499 7.05C11.1499 7.55 11.4499 7.85 11.9499 7.85C12.3499 7.85 12.7499 7.45 12.7499 7.05C12.7499 6.55 12.4499 6.25 11.9499 6.25Z"
      fill="currentColor"
    />
    <path d="M10.85 11.85L12.05 14.15L13.25 11.85L11.95 10.35L10.85 11.85Z" fill="currentColor" />
    <path
      d="M14.65 19.75H9.24995C9.04995 19.75 8.94995 19.75 8.74995 19.65C8.54995 19.55 8.34995 19.45 8.24995 19.35C7.94995 19.15 7.64995 18.65 7.44995 17.85L6.44995 12.25V12.15C6.54995 11.15 7.34995 10.35 8.34995 10.35H9.34995L11.95 15.15L14.55 10.35H15.65C16.65 10.35 17.45 11.15 17.55 12.15V12.25L16.55 17.85C16.35 18.65 16.05 19.15 15.75 19.35C15.55 19.45 15.45 19.65 15.25 19.65L14.85 19.75C14.85 19.75 14.75 19.75 14.65 19.75ZM12.85 17.75H14.45C14.45 17.65 14.55 17.65 14.55 17.45L15.35 13.15L12.85 17.75ZM9.54995 17.75H11.15L8.64995 13.15L9.44995 17.45C9.44995 17.55 9.54995 17.65 9.54995 17.75Z"
      fill="currentColor"
    />
  </Icon>
);

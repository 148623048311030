import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import female from 'assets/images/femaleProfilePic.png';
import female2 from 'assets/images/dummyProfileWoman1.svg';
import female3 from 'assets/images/dummyProfileWoman2.svg';
import male from 'assets/images/maleProfilePic.png';
import monk from 'assets/images/monk.png';
import monk2x from 'assets/images/monk@2x.png';
// all main mesh in icon imports
import deliver from 'assets/images/deliver.svg';
import develop from 'assets/images/develop.png';
import RulerIcon from 'assets/images/ruler.png';
import LogoWithoutName from 'assets/images/logo-without-name.png';
import { ReactComponent as DeliverableSvgI } from 'assets/images/deliver.svg';
import { ReactComponent as DevelopSvgI } from 'assets/images/develop.svg';

import { ReactComponent as LogoSvg } from 'assets/images/logo.svg';
import { ReactComponent as MeshUpSvg } from 'assets/images/meshUp.svg';
import { ReactComponent as MeshUpSelectedSvg } from 'assets/images/meshUpSelected.svg';
import { ReactComponent as PortfolioSvg , ReactComponent as TeamSummarySvg } from 'assets/images/portfolio.svg';
import { ReactComponent as MailCircular } from 'assets/images/mailCircular.svg';
import { ReactComponent as MeshInSvg } from 'assets/images/meshIn.svg';
import { ReactComponent as PortfolioSelectedSvg , ReactComponent as TeamSummarySelectedSvg } from 'assets/images/portfolioSelected.svg';
import { ReactComponent as MyTeamSvg } from 'assets/images/myTeam.svg';
import { ReactComponent as AddMoolahSvg } from 'assets/images/addMoolah.svg';
import { ReactComponent as StrengthSvg } from 'assets/images/strength.svg';
import { ReactComponent as ExperienceSvg } from 'assets/images/drive.svg';
import { ReactComponent as MoolahSvg } from 'assets/images/moolah.svg';
import { ReactComponent as GroupMoolahSvg } from 'assets/images/groupMoolah.svg';
import { ReactComponent as ExpandSvg } from 'assets/images/expand.svg';
import { ReactComponent as HomeSvg } from 'assets/images/home.svg';
import { ReactComponent as HomeSelectedSvg } from 'assets/images/homeSelected.svg';
import { ReactComponent as NotificationSvg } from 'assets/images/notification.svg';
import { ReactComponent as Notification1Svg } from 'assets/images/notification1.svg';
import { ReactComponent as ConnectBadgeOutsetSvg } from 'assets/images/badges/connectBadgeOutset.svg';
import { ReactComponent as ReflectBadgeOutsetSvg } from 'assets/images/badges/reflectBadgeOutset.svg';
import { ReactComponent as GrowBadgeOutsetSvg } from 'assets/images/badges/growBadgeOutset.svg';
import { ReactComponent as LoginBadgeOutsetSvg } from 'assets/images/badges/loginBadgeOutset.svg';
import { ReactComponent as TeamSvg } from 'assets/images/team.svg';
import { ReactComponent as InsightsSvg } from 'assets/images/insights.svg';
import { ReactComponent as AddCircularSvg } from 'assets/images/addCircular.svg';
import { ReactComponent as KeySvg } from 'assets/images/key.svg';
import { ReactComponent as GoogleSvg } from 'assets/images/google.svg';
import { ReactComponent as HierarchySvg } from 'assets/images/hierarchy.svg';
import { ReactComponent as HierarchyTwoChildSvg } from 'assets/images/hierarchy-twoChild.svg';
import { ReactComponent as FilterIconSvg } from 'assets/images/filter.svg';
import { ReactComponent as ChatBubbleIconSvg } from 'assets/images/bubble-chat.svg';
import { ReactComponent as ChatBubbleFilledIconSvg } from 'assets/images/bubble-chat-filled.svg';
import { ReactComponent as GroupSvg } from 'assets/images/group-new.svg';
import { ReactComponent as TargetSvg } from 'assets/images/target1.svg';
import { ReactComponent as AddUserSvg } from 'assets/images/add-user.svg';

// Emojis
import { ReactComponent as HappyEmojiSvg } from 'assets/images/emojis/happy.svg';
import { ReactComponent as SmartEmojiSvg } from 'assets/images/emojis/smart.svg';
import { ReactComponent as VeryHappyEmojiSvg } from 'assets/images/emojis/veryHappy.svg';
import { ReactComponent as SadEmojiSvg } from 'assets/images/emojis/sad.svg';
import { ReactComponent as AngryEmojiSvg } from 'assets/images/emojis/angry.svg';
import { ReactComponent as HappyColoredEmojiSvg } from 'assets/images/emojis/happyColored.svg';
import { ReactComponent as SmartColoredEmojiSvg } from 'assets/images/emojis/smartColored.svg';
import { ReactComponent as VeryHappyColoredEmojiSvg } from 'assets/images/emojis/veryHappyColored.svg';
import { ReactComponent as SadColoredEmojiSvg } from 'assets/images/emojis/sadColored.svg';
import { ReactComponent as AngryColoredEmojiSvg } from 'assets/images/emojis/angryColored.svg';
import { ReactComponent as ThickOutlineSmileEmojiSvg } from 'assets/images/emojis/thickOutlineSmile.svg';
import { ReactComponent as EmojiSmileSvg } from 'assets/images/emojis/emojiSmile.svg';

import { ReactComponent as HeartPulseSvg } from 'assets/images/heart-rate.svg';
import { ReactComponent as AnonymousSvg } from 'assets/images/anonymous.svg';
import { ReactComponent as QuestionSvg } from 'assets/images/question.svg';
import { ReactComponent as SignatureSvg } from 'assets/images/signature.svg';
import { ReactComponent as PastSvg } from 'assets/images/past.svg';
import { ReactComponent as PulseSvg } from 'assets/images/pulse.svg';
import { ReactComponent as DotsSvg } from 'assets/images/dots.svg';
import { ReactComponent as ListSvg } from 'assets/images/list.svg';
import { ReactComponent as WinnerCupSvg } from 'assets/images/winnerCup.svg';
import { ReactComponent as GlobeSvg } from 'assets/images/globe.svg';
import { ReactComponent as PictureSvg } from 'assets/images/pictureIcon.svg';
import { ReactComponent as CrossSvg } from 'assets/images/cross.svg';
import { ReactComponent as CrossThickSvg } from 'assets/images/cross-thick.svg';
import { ReactComponent as PlaySvg } from 'assets/images/play.svg';
import { ReactComponent as PanningNavigationSvg } from 'assets/images/panning-navigation.svg';

import ThumbsDown from 'assets/images/ThumbsDown';
import LogoGif from 'assets/gifs/logo.gif';
import { ReactComponent as ThumbsUpSvg } from 'assets/images/thumbsUp.svg';
import BadgeContainer from 'components/BadgeContainer';
import { IconSizesCSS, GutterCSS, CircularIconCSS, PaddingCSS } from 'styles/common';
// ---------------------------- strength icons -----------------------------------
import { ReactComponent as AccountabilityEffortSvg } from 'assets/images/accountabilityEffortStrength.svg';
import { ReactComponent as DriveCompetitivenessSvg } from 'assets/images/driveCompetitivenessStrength.svg';
import { ReactComponent as RelationshipStrengthSvg } from 'assets/images/relationshipStrength.svg';
import { ReactComponent as MentoringStrengthSvg } from 'assets/images/mentoringStrength.svg';
import { ReactComponent as LearningStrengthSvg } from 'assets/images/learningStrength.svg';
import { ReactComponent as InsightsStrengthSvg } from 'assets/images/insightsStrength.svg';
import { ReactComponent as InnovationStrengthSvg } from 'assets/images/innovationStrength.svg';
import { ReactComponent as InfluenceStrengthSvg } from 'assets/images/influenceStrength.svg';
import { ReactComponent as HarmonyStrengthSvg } from 'assets/images/harmonyStrength.svg';
import { ReactComponent as FocusStrengthSvg } from 'assets/images/focusStrength.svg';
import { ReactComponent as Deadline1Svg } from 'assets/images/deadline1.svg';
import { ReactComponent as Deadline2Svg } from 'assets/images/deadline2.svg';
import { ReactComponent as InterviewSvg } from 'assets/images/interview.svg';
import { ReactComponent as ManagementSvg } from 'assets/images/management.svg';
import { ReactComponent as CustomerFeedbackSvg } from 'assets/images/customer-feedback.svg';
import { ReactComponent as GoalsSvg } from 'assets/images/goals.svg';

import { ReactComponent as Download1Svg } from 'assets/images/download1.svg';
import { ReactComponent as UninstallSvg } from 'assets/images/uninstall.svg';
import { ReactComponent as ReviewFilledSvg } from 'assets/images/review-filled.svg';
import { ReactComponent as GoalsMiniSvg } from 'assets/images/goalsMini.svg';
import { ReactComponent as UpdatedSvg } from 'assets/images/updated.svg';
import { ReactComponent as CheckMarkSvg } from 'assets/images/check-mark.svg';
import { ReactComponent as CheckListSvg } from 'assets/images/checklist.svg';
import { ReactComponent as ResponsiveSvg } from 'assets/images/responsive.svg';
import { ReactComponent as PaperSvg } from 'assets/images/paper.svg';
//-------------------------------------------------------------------------------

// ------------ Commercial Logos ------------------
import AmazonLogo from 'assets/images/amazonLogo.png';
import JabongLogo from 'assets/images/jabongLogo.png';
import MyntraLogo from 'assets/images/myntraLogo.jpg';
import UberLogo from 'assets/images/uberLogo.jpg';
import BookMyShowLogo from 'assets/images/bookmyshow.jpeg';
import FlipkartLogo from 'assets/images/flipkartLogo.png';
import OlaLogo from 'assets/images/olaLogo.png';
import AmazonCircularLogo from 'assets/images/amazonCircularLogo.png';
import SlackLogo from 'assets/images/slackLogo.png';
import MSTeamsLogo from 'assets/images/microsoft-teams.png';
import HrisLogo from 'assets/images/hrisLogo.svg';
import JiraLogo from 'assets/images/jiraLogo.jpeg';
import SalesforceLogo from 'assets/images/salesforce-logo.png';
import DarwinBoxLogo from 'assets/images/darwinbox-logo.png';

// ------------------------------------------------
import { ReactComponent as LikeIconSvg } from 'assets/images/like.svg';
import { ReactComponent as FilledLikeIconSvg } from 'assets/images/likeFilled.svg';
import { ReactComponent as CommentIconSvg } from 'assets/images/comment.svg';
import { ReactComponent as CommentFilledIconSvg } from 'assets/images/commentFilled.svg';
import { ReactComponent as SettingsIconSvg } from 'assets/images/settingsIcon.svg';
import { ReactComponent as SettingsFilledSvg } from 'assets/images/settings.svg';
import { ReactComponent as SettingsCircularSvg } from 'assets/images/settingsCircular.svg';

//
import { ReactComponent as CheckedCircleSvg } from 'assets/images/checkedCircle.svg';
import { ReactComponent as UncheckedCircleSvg } from 'assets/images/uncheckedCircle.svg';

import { ReactComponent as TeamColoredSvg } from 'assets/images/teamColored.svg';
import { ReactComponent as ProblemSvg } from 'assets/images/problem.svg';
import { ReactComponent as ApplauseSvg } from 'assets/images/applause.svg';
import { ReactComponent as HandSvg } from 'assets/images/hand.svg';
import { ReactComponent as ApplauseFilledSvg } from 'assets/images/applauseFilled.svg';
import { ReactComponent as ImageIconSvg } from 'assets/images/imageIcon.svg';
import { ReactComponent as GifIconSvg } from 'assets/images/gifIcon.svg';
import { ReactComponent as UserIconSvg } from 'assets/images/user.svg';

// ------- material ui icons -------
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { ReactComponent as EmployeeIconSvg } from 'assets/images/employeeIcon.svg';
import { ReactComponent as CheckIconSvg } from 'assets/images/check.svg';
import { ReactComponent as HighLowIconSvg } from 'assets/images/highLowIcon.svg';
import { ReactComponent as UpDownIconSvg } from 'assets/images/upDownIcon.svg';
import { ReactComponent as OngoingShuttleSvg } from 'assets/images/shuttle.svg';
import { ReactComponent as EditSvg } from 'assets/images/edit.svg';
import { ReactComponent as DelayedSvg } from 'assets/images/delayed.svg';
import { ReactComponent as ProgressSvg } from 'assets/images/progress.svg';
import { ReactComponent as LoadingSvg } from 'assets/images/loading.svg';
import { ReactComponent as LogoutSvg } from 'assets/images/log-out.svg';

// Performance review icons
import { ReactComponent as ReviewNavIconSelectedSvg } from 'assets/images/review-header-icon-selected.svg';
import { ReactComponent as NoRecognitionFeedbackPlaceholderSvg } from 'assets/images/no-recognition-feedback.svg';
import { ReactComponent as ReviewChartMiniIconSvg } from 'assets/images/chart.svg';
import { ReactComponent as GrowthGraphMiniIconSvg } from 'assets/images/graph.svg';
import { ReactComponent as ReviewMiniIconSvg } from 'assets/images/review.svg';
import { ReactComponent as ReviewTeamStatusIconSvg } from 'assets/images/group.svg';
import { ReactComponent as TaskTypeIconSvg } from 'assets/images/taskType.svg';
import { ReactComponent as DownloadIconSvg } from 'assets/images/download.svg';
import { ReactComponent as PDFFileIconSvg } from 'assets/images/pdf-file.svg';
import { ReactComponent as EmployeeIconCircleSvg } from 'assets/images/employeeIconCircle.svg';
import { ReactComponent as HashTagIconSvg } from 'assets/images/hashTag.svg';

// analytics icons
import { ReactComponent as CloseIconSvg } from 'assets/images/close.svg';
import { ReactComponent as HourglassIconSvg } from 'assets/images/hourglass.svg';
import { ReactComponent as EmployeeGrowthIconSvg } from 'assets/images/growth.svg';
import { ReactComponent as CheckinIconSvg } from 'assets/images/check-box.svg';
import { ReactComponent as ShareIconSvg } from 'assets/images/users.svg';
import { ReactComponent as FormIconSvg } from 'assets/images/form_1.svg';
import { ReactComponent as UserIcon2Svg } from 'assets/images/user2_1.svg';
import { ReactComponent as EmailIconSvg } from 'assets/images/email.svg';
import { ReactComponent as MIconSvg } from 'assets/images/m-icon.svg';
import { ReactComponent as SpeakerIconSvg } from 'assets/images/speaker.svg';
import { ReactComponent as PlaceIconSvg } from 'assets/images/place_1.svg';
import { ReactComponent as CircularDownloadIconSvg } from 'assets/images/downloadCircular.svg';
import { ReactComponent as BarGraphIconSvg } from 'assets/images/barGraph.svg';
import { ReactComponent as TimelineGraphIconSvg } from 'assets/images/timelineGraph.svg';
import { ReactComponent as ArrowDownwardIconSvg } from 'assets/images/arrow-downward.svg';
import { ReactComponent as BackArrowSvg } from 'assets/images/backArrow.svg';
import { ReactComponent as DownloadArrowIconSvg } from 'assets/images/downloadArrow.svg';
import { ReactComponent as CalendarIconSvg } from 'assets/images/calendar.svg';
import { ReactComponent as CloseIconv1Svg } from 'assets/images/close_v1.svg';
import { ReactComponent as CheckedCheckbox } from 'assets/images/checked_checkbox.svg';
import { ReactComponent as UncheckedCheckbox } from 'assets/images/unchecked_checkbox.svg';

// continuous feedback icons
import { ReactComponent as ContinuousFeedbackSvg } from 'assets/images/feedback.svg';
import { ReactComponent as ManagerViewSvg } from 'assets/images/man-user.svg';
import { ReactComponent as PrivateViewSvg } from 'assets/images/padlock.svg';
import { ReactComponent as PrivateManagerViewSvg } from 'assets/images/2-users.svg';
import { ReactComponent as OneOnOneSvg } from 'assets/images/oneOnOne.svg';
import { ReactComponent as DeleteIconSvg } from 'assets/images/delete.svg';
import { ReactComponent as DeleteFilledIconSvg } from 'assets/images/delete1.svg';
import { ReactComponent as LinkIconSvg } from 'assets/images/link.svg';
import { ReactComponent as PublicViewSvg } from 'assets/images/world.svg';
import { ReactComponent as SendTiltedSvg } from 'assets/images/send-tilted.svg';
import { ReactComponent as ShareSvg } from 'assets/images/Share.svg';
import { ReactComponent as RequestSvg } from 'assets/images/Request.svg';
import { ReactComponent as WomanSvg } from 'assets/images/woman.svg';
import { ReactComponent as ManSvg } from 'assets/images/man.svg';
import { ReactComponent as KudosSvg } from 'assets/images/kudos-icon-bw.svg';

import { ReactComponent as StarSvg } from 'assets/images/star.svg';
import { ReactComponent as ScaleSvg } from 'assets/images/scale.svg';
import { ReactComponent as TextBoxSvg } from 'assets/images/textBox.svg';
import { ReactComponent as NumberSvg } from 'assets/images/number.svg';
import { ReactComponent as OptionsSvg } from 'assets/images/options.svg';
import { ReactComponent as CommentSvg } from 'assets/images/comment-colored.svg';
import { ReactComponent as UpdatWeightage } from 'assets/images/weightage.svg';

// Header Icons
import { ReactComponent as InsightsHeaderIconSvg } from 'assets/images/insightsHeader.svg';
import { ReactComponent as HomeHeaderIconSvg } from 'assets/images/homeHeader.svg';
import { ReactComponent as TeamHubHeaderIconSvg } from 'assets/images/teamHeader.svg';
import { ReactComponent as PulseHeaderIconSvg } from 'assets/images/pulseHeader.svg';
import { ReactComponent as NotificationHeaderIconSvg } from 'assets/images/notificationHeader.svg';
import { ReactComponent as ReviewHeaderIconSvg } from 'assets/images/reviewHeader.svg';
import { ReactComponent as UserChatBubbleSvg } from 'assets/images/userChatBubble.svg';
import { ReactComponent as TasksSvg } from 'assets/images/tasks.svg';
import { ReactComponent as PrivateTasksSvg } from 'assets/images/PrivateTask.svg';
import { ReactComponent as ArrowDownIconSvg } from 'assets/images/arrowDown.svg';
import { ReactComponent as SearchSvg } from 'assets/images/search1.svg';
import { ReactComponent as OneOnOneHeaderIconSvg } from 'assets/images/friends2.svg';
import { ReactComponent as AnonymousUserIconSvg } from 'assets/images/user-silhoutte.svg';
import { ReactComponent as LevelUpIconSvg } from 'assets/images/level_up.svg';

// Weightage Icons
import { ReactComponent as EditIconSvg } from 'assets/images/editIcon.svg';
import { ReactComponent as ViewIconSvg } from 'assets/images/viewIcon.svg';

// Nomination Icons
import { ReactComponent as AddUserSvg2 } from 'assets/images/add-user-2.svg';
import { ReactComponent as SeniorsSvg } from 'assets/images/Seniors.svg';
import { ReactComponent as JuniorsSvg } from 'assets/images/Juniors.svg';
import { ReactComponent as PrimaryTeamSvg } from 'assets/images/WithinTeam.svg';
import { ReactComponent as OutsideTeamSvg } from 'assets/images/OutsideTeam.svg';
import { ReactComponent as AddPeerSvg } from 'assets/images/addPeerIcon.svg';

// Calibration Grid Icons
import { ReactComponent as RoundedArrowUp } from 'assets/images/RoundedArrowUp.svg';
import { ReactComponent as RoundedArrowRight } from 'assets/images/RoundedArrowRight.svg';

// ReviewTemplate Icons
import { ReactComponent as RemoveIconSvg } from 'assets/images/removeIcon.svg';
import { ReactComponent as upArrowSvg2 } from 'assets/images/upArrow.svg';
import { ReactComponent as downArrowSvg2 } from 'assets/images/downArrow.svg';
import { ReactComponent as DeleteIcon3Svg } from 'assets/images/delete3.svg';
import { ReactComponent as InfoIconSvg } from 'assets/images/information.svg';
import { ReactComponent as InfoIcon2Svg } from 'assets/images/info.svg';
import { ReactComponent as CrossIconSvg } from 'assets/images/CrossIcon.svg';
import { ReactComponent as PlusIconSvg } from 'assets/images/PlusIcon.svg';
import { ReactComponent as CheckIcon2Svg } from 'assets/images/CheckIcon.svg';
import { ReactComponent as EmptyCheckboxSvg } from 'assets/images/EmptyCheckbox.svg';

// Objective tags icons
import { ReactComponent as CaretDown } from 'assets/images/caret-down.svg';
import { ReactComponent as CTag } from 'assets/images/c-tag.svg';
import { ReactComponent as CompanyTagSvg } from 'assets/images/company-tag-two-tone.svg';
import { ReactComponent as DTag } from 'assets/images/d-tag.svg';
import { ReactComponent as DepartmentTagSvg } from 'assets/images/department-tag-two-tone.svg';
import { ReactComponent as ITag } from 'assets/images/i-tag.svg';
import { ReactComponent as IndividualTagSvg } from 'assets/images/individual-tag-two-tone.svg';
import { ReactComponent as TTag } from 'assets/images/t-tag.svg';
import { ReactComponent as TeamTagSvg } from 'assets/images/team-tag-two-tone.svg';
import { ReactComponent as NoTag } from 'assets/images/no-tag.svg';
import { ReactComponent as ReplaceSvg } from 'assets/images/replace.svg';

// thank the designer for the following icon.
import { ReactComponent as AddCircular2Svg } from 'assets/images/AddCircular2.svg';
import { ReactComponent as ThinCurvedArrowSvg } from 'assets/images/thin-curved-arrow-icon.svg';

// CheckInAccordion
import { ReactComponent as ManagerAndReporteeSvg } from 'assets/images/ManagerReportee.svg';
import { ReactComponent as OnlyReporteeSvg } from 'assets/images/OnlyReportee.svg';

// oneOnOne Icons
import { ReactComponent as TwoPeopleSvg } from 'assets/images/people.svg';
import { ReactComponent as PlusSvg } from 'assets/images/plus.svg';
import { ReactComponent as FilledGearSvg } from 'assets/images/filled-gear.svg';

// OneOnOne Refreshed Icons
import { ReactComponent as RescheduleMeetingSvg } from 'assets/images/RescheduleMeeting2.svg';
import { ReactComponent as ReopenMeetingSvg } from 'assets/images/ReopenMeeting2.svg';
import { ReactComponent as CancelMeetingSvg } from 'assets/images/CancelMeeting2.svg';
import { ReactComponent as CancelledMeetingsSvg } from 'assets/images/CancelledMeetings.svg';
import { ReactComponent as ShareMeetingSvg } from 'assets/images/ShareMeeting.svg';

// Company Settings Icons
import { ReactComponent as FlagSvg } from 'assets/images/flag.svg';
import { ReactComponent as CoinsSvg } from 'assets/images/coins.svg';
import { ReactComponent as ClockSvg } from 'assets/images/clock.svg';
import { ReactComponent as ChatSvg } from 'assets/images/chat.svg';

import { ReactComponent as ChevronLeftSvg } from 'assets/images/chevronLeft.svg';
import { ReactComponent as ArrowThickUpSvg } from 'assets/images/arrow-thick-up.svg';
import { ReactComponent as JustifySvg } from 'assets/images/justifyParagraph.svg';
// Review Settings Icons
import { ReactComponent as TrophySvg } from 'assets/images/trophyImage.svg';
import { ReactComponent as VerticalUpArrowSvg } from 'assets/images/verticalUpArrow.svg';
import { ReactComponent as HorizontalRightArrowSvg } from 'assets/images/horizontalRightArrow.svg';
import { ReactComponent as EditPenSvg } from 'assets/images/EditPenIcon.svg';

// Settings panel Icons
import { ReactComponent as PinSvg } from 'assets/images/pin.svg';

// Template Icons
import { ReactComponent as ArchiveSvg } from 'assets/images/archive.svg';
import { ReactComponent as DuplicateSvg } from 'assets/images/duplicate.svg';
import { ReactComponent as PreviewSvg } from 'assets/images/preview.svg';

// arrow icons
import { ReactComponent as PositiveSentimentArrowSvg } from 'assets/images/up-arrow.svg';
import { ReactComponent as NegativeSentimentArrowSvg } from 'assets/images/top-right-arrow.svg';

// notification icons
import { ReactComponent as BellNotificationSvg } from 'assets/images/bell_notification.svg';
import { ReactComponent as SlackNotificationSvg } from 'assets/images/slack_notification.svg';
import { ReactComponent as BrowserNotificationSvg } from 'assets/images/browser_notification.svg';
import { ReactComponent as EmailNotificationSvg } from 'assets/images/email_notification.svg';
import { ReactComponent as NotificationReminderSvg } from 'assets/images/notification-reminder.svg';
import { ReactComponent as NotificationTiltedSvg } from 'assets/images/notification-tilted.svg';
import { ReactComponent as NotificationSettingsSvg } from 'assets/images/notification-settings.svg';

import { ReactComponent as RoundedSquareCheckSvg } from 'assets/images/notification-check.svg';
// sentiment icons
import { ReactComponent as AppreciativeFeedbackSvg } from 'assets/images/feedback-appreciative.svg';
import { ReactComponent as ConstructiveFeedbackSvg } from 'assets/images/feedback-constructive.svg';
import { ReactComponent as NeutralFeedbackSvg } from 'assets/images/feedback-neutral.svg';

// React Select icons
import { ReactComponent as DownChevronSvg } from 'assets/images/DownChevron.svg';

// Bullet point and paragraph icons
import { ReactComponent as List2Svg } from 'assets/images/list_2.svg';
import { ReactComponent as Bullet2Svg } from 'assets/images/bullet_2.svg';

// New OKR icons
import { ReactComponent as ObjectiveSvg } from 'assets/images/objective.svg';
import { ReactComponent as KrSvg } from 'assets/images/KR.svg';

import { ReactComponent as DustbinSvg } from 'assets/images/dustbin.svg';
import { ReactComponent as MegaPhoneSvg } from 'assets/images/megaphone-kudos.svg';
import { ReactComponent as MegaPhoneSvgColored } from 'assets/images/green-praise.svg';
import { ReactComponent as CommentFeedbackSvg } from 'assets/images/comment-feedback.svg';
import { ReactComponent as ImageSvg } from 'assets/images/image-upload.svg';

import { ReactComponent as PrivateKRSvg } from 'assets/images/private_kr.svg';
import { ReactComponent as SubDirectorySvg } from 'assets/images/subdirectory.svg';
import { ReactComponent as ObjectiveTwoToneSvg } from 'assets/images/objective-two-tone.svg';
import { ReactComponent as GoalsNavSvg } from 'assets/images/goals_nav.svg';

import { ReactComponent as RealignSvg } from 'assets/images/realign.svg';
import { ReactComponent as ParentRealignSvg } from 'assets/images/parentRealign.svg';

import { ReactComponent as DetailsIconSvg } from 'assets/images/details-icon.svg';
// level up icons
import { ReactComponent as SkilledSvg } from 'assets/images/skilled.svg';
import { ReactComponent as BuildingSvg } from 'assets/images/building.svg';
import { ReactComponent as FeedbackSvg } from 'assets/images/feedback_icon.svg';

// RTE icons
import { ReactComponent as RteBoldSvg } from 'assets/images/rte/bold.svg';
import { ReactComponent as RteItalicSvg } from 'assets/images/rte/italic.svg';
import { ReactComponent as RteUnderlineSvg } from 'assets/images/rte/underline.svg';
import { ReactComponent as RteStrikethroughSvg } from 'assets/images/rte/strikethrough.svg';
import { ReactComponent as RteUlSvg } from 'assets/images/rte/list-ul.svg';
import { ReactComponent as RteOlSvg } from 'assets/images/rte/list-ol.svg';

import { ReactComponent as EditNotificationSvg } from 'assets/images/editNotifications.svg';

import { POST_VISIBILITY_TYPES } from './ProjectConstants';
import { getColorFromKey } from './helper';

// --- all svgs directly exporting for use as it is in graphs ------
export const DeliverableSvg = DeliverableSvgI;
export const DevelopSvg = DevelopSvgI;

// ----------------------------------------------------------------

// ---------------- all png sources -------------------

export const maleIconSrc = male;
export const femaleIconSrc = female;
export const female2IconSrc = female2;
export const female3IconSrc = female3;
export const deliverIconSrc = deliver;
export const developIconSrc = develop;
export const monkSrc = monk;
export const monk2xSrc = monk2x;

// -----------------------------------------------------

// ------------ commercial logo src export  ------------
export const AmazonLogoSrc = AmazonLogo;
export const MyntraLogoSrc = MyntraLogo;
export const FlipkartLogoSrc = FlipkartLogo;
export const OlaLogoSrc = OlaLogo;
export const UberLogoSrc = UberLogo;
export const BookmyshowLogoSrc = BookMyShowLogo;
export const JabongLogoSrc = JabongLogo;
export const AmazonCircularLogoSrc = AmazonCircularLogo;
export const SlackLogoSrc = SlackLogo;
export const MSTeamsLogoSrc = MSTeamsLogo;
export const HrisLogoSrc = HrisLogo;
export const JiraLogoSrc = JiraLogo;
export const SalesforceSrc = SalesforceLogo;
export const DarwinBoxLogoSrc = DarwinBoxLogo;

// ------------------------------------------------------
export const RulerIconSrc = RulerIcon;

export function withStyledIcon(WrappedIcon) {
  return forwardRef((props, ref) => (
      <StyledIcon {...props} ref={ref}>
        <WrappedIcon />
      </StyledIcon>
    ));
}

// This Function Adds functnionality of giving SVG Icon Container size.
// Ex: Often, We get requirement of having a bordered container of 24px while icon to be 10px.
// you can pass size="fixed10px" and containerSize="fixed24px" in icon props.
export function withStyledIconV2(WrappedIcon) {
  return forwardRef((props, ref) => (
      <StyledIconV2 {...props} ref={ref}>
        <WrappedIcon />
      </StyledIconV2>
    ));
}

function isPublic(visibility) {
  return visibility === POST_VISIBILITY_TYPES.global.name;
}

export function VisibilityIcon({ visibility, onClick, size, useTeamIcon = false, ...props }) {
  return (
    <>
      {isPublic(visibility) ? (
        useTeamIcon ? (
          <TeamsIcon size={size} color="#666" onClick={onClick} />
        ) : (
          <GlobeIcon size={size} color="#666" onClick={onClick} />
        )
      ) : (
        <StyledIcon {...props} onClick={onClick} size={size}>
          <LockRoundedIcon />
        </StyledIcon>
      )}
    </>
  );
}
export const LogoutIcon = withStyledIcon(LogoutSvg);
export const EditIcon = withStyledIcon(EditSvg);
export const TaskTypeIcon = TaskTypeIconSvg;
export const LoadingIcon = withStyledIcon(LoadingSvg);
export const DelayedIcon = withStyledIcon(DelayedSvg);
export const ProgressIcon = withStyledIcon(ProgressSvg);
export const HighLowIcon = withStyledIcon(HighLowIconSvg);
export const UpArrowIcon = styled(HighLowIcon)`
  transform: rotate(270deg);
`;
export const BackArrowIcon = styled(HighLowIcon)`
  transform: rotate(180deg);
`;
export const DownArrowIcon = styled(HighLowIcon)`
  transform: rotate(90deg);
`;
export const UpDownIcon = withStyledIcon(UpDownIconSvg);
export const OngoingShuttleIcon = withStyledIcon(OngoingShuttleSvg);
export const LeftRightArrowIcon = styled(UpDownIcon)`
  transform: rotate(90deg);
`;
export const EmployeeIcon = withStyledIcon(EmployeeIconSvg);
export const CheckIcon = withStyledIcon(CheckIconSvg);
export const HierarchyIcon = withStyledIcon(HierarchySvg);
export const HierarchyTwoChildIcon = withStyledIcon(HierarchyTwoChildSvg);
export const KeyIcon = withStyledIcon(KeySvg);
export const GoogleIcon = withStyledIcon(GoogleSvg);
export const ImageIcon = withStyledIcon(ImageIconSvg);
export const GifIcon = withStyledIcon(GifIconSvg);
export const UserIcon = withStyledIcon(UserIconSvg);
export const FilterIcon = <FilterIconSvg />;

export const ApplauseIcon = withStyledIcon(ApplauseSvg);

export const HandIcon = withStyledIcon(HandSvg);

export const ApplauseFilledIcon = withStyledIcon(ApplauseFilledSvg);

export const CheckedCircle = withStyledIcon(CheckedCircleSvg);

export const UncheckedCircle = withStyledIcon(UncheckedCircleSvg);

export const TeamColored = withStyledIcon(TeamColoredSvg);

export const SettingsIcon = withStyledIcon(SettingsIconSvg);

export const SettingsIconFilled = withStyledIcon(SettingsFilledSvg);

export const SettingsCircularIcon = withStyledIcon(SettingsCircularSvg);

export const AddCircularIcon = withStyledIcon(AddCircularSvg);

export const Deadline1Icon = withStyledIcon(Deadline1Svg);

export const Deadline2Icon = withStyledIcon(Deadline2Svg);

export const InterviewIcon = withStyledIcon(InterviewSvg);

export const ManagementIcon = withStyledIcon(ManagementSvg);

export const CustomerFeedbackIcon = withStyledIcon(CustomerFeedbackSvg);

export const GoalsIcon = withStyledIcon(GoalsSvg);

export const Download1Icon = withStyledIcon(Download1Svg);

export const UninstallIcon = withStyledIcon(UninstallSvg);

export const ReviewFilledIcon = withStyledIcon(ReviewFilledSvg);

export const GoalsMiniIcon = withStyledIcon(GoalsMiniSvg);

export const UpdatedIcon = withStyledIcon(UpdatedSvg);

export const CheckMarkIcon = withStyledIcon(CheckMarkSvg);

export const CheckListIcon = withStyledIcon(CheckListSvg);

export const ChatBubbleIcon = withStyledIcon(ChatBubbleIconSvg);

export const ChatBubbleFilledIcon = withStyledIcon(ChatBubbleFilledIconSvg);

export const ResponsiveIcon = withStyledIcon(ResponsiveSvg);

export const PaperIcon = withStyledIcon(PaperSvg);

export const GroupIcon = withStyledIcon(GroupSvg);

export const TargetIcon = withStyledIcon(TargetSvg);

export const AddUserIcon = withStyledIcon(AddUserSvg);

export const HappyEmojiIcon = withStyledIcon(HappyEmojiSvg);

export const SmartEmojiIcon = withStyledIcon(SmartEmojiSvg);

export const VeryHappyEmojiIcon = withStyledIcon(VeryHappyEmojiSvg);

export const SadEmojiIcon = withStyledIcon(SadEmojiSvg);

export const AngryEmojiIcon = withStyledIcon(AngryEmojiSvg);

export const HappyColoredEmojiIcon = withStyledIcon(HappyColoredEmojiSvg);

export const SmartColoredEmojiIcon = withStyledIcon(SmartColoredEmojiSvg);

export const VeryHappyColoredEmojiIcon = withStyledIcon(VeryHappyColoredEmojiSvg);

export const SadColoredEmojiIcon = withStyledIcon(SadColoredEmojiSvg);

export const AngryColoredEmojiIcon = withStyledIcon(AngryColoredEmojiSvg);

export const AnonymousIcon = withStyledIcon(AnonymousSvg);

export const QuestionIcon = withStyledIcon(QuestionSvg);

export const SignatureIcon = withStyledIcon(SignatureSvg);

export const HeartPulseIcon = withStyledIcon(HeartPulseSvg);

export const ThickOutlineSmileIcon = withStyledIcon(ThickOutlineSmileEmojiSvg);

export const MailCircularIcon = withStyledIcon(MailCircular);

// ------------Strength Icons---------------------

export const AccountabilityEffortIcon = withStyledIcon(AccountabilityEffortSvg);

export const DriveCompetitivenessIcon = withStyledIcon(DriveCompetitivenessSvg);

export const RelationshipStrengthIcon = withStyledIcon(RelationshipStrengthSvg);

export const MentoringStrengthIcon = withStyledIcon(MentoringStrengthSvg);

export const LearningStrengthIcon = withStyledIcon(LearningStrengthSvg);

// ------------Company Settings Icons---------------------
export const FlagIcon = withStyledIcon(FlagSvg);
export const CoinsIcon = withStyledIcon(CoinsSvg);
export const ClockIcon = withStyledIcon(ClockSvg);
export const ChatIcon = withStyledIcon(ChatSvg);

// Settings panel Icons
export const PinIcon = withStyledIcon(PinSvg);
// ------------Templpate Icons---------------------
export const ArchiveIcon = withStyledIcon(ArchiveSvg);
export const DuplicateIcon = withStyledIcon(DuplicateSvg);

export const InsightsStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <InsightsStrengthSvg />
  </StyledIcon>
);

export const InnovationStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <InnovationStrengthSvg />
  </StyledIcon>
);

export const InfluenceStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <InfluenceStrengthSvg />
  </StyledIcon>
);

export const HarmonyStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <HarmonyStrengthSvg />
  </StyledIcon>
);

export const FocusStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <FocusStrengthSvg />
  </StyledIcon>
);

// -----------------------------------------------

export const LikeIcon = withStyledIcon(LikeIconSvg);
export const FilledLikeIcon = withStyledIcon(FilledLikeIconSvg);

export const CommentIcon = withStyledIcon(CommentIconSvg);

export const CommentFilledIcon = withStyledIcon(CommentFilledIconSvg);

export const ProblemIcon = withStyledIcon(ProblemSvg);

export const LogoGifSrc = LogoGif;
export const LogoIcon = withStyledIcon(LogoSvg);
export const LogoImage = LogoSvg;
export const LogoWithoutNameImage = LogoWithoutName;
export const MeshUpIcon = MeshUpSvg;
export const MeshUpSelectedIcon = MeshUpSelectedSvg;
export const PortfolioIcon = PortfolioSvg;
export const PortfolioSelectedIcon = PortfolioSelectedSvg;
export const MyTeamIcon = MyTeamSvg;
export const AddMoolahIcon = AddMoolahSvg;
export const HomeIcon = HomeSvg;
export const HomeSelectedIcon = HomeSelectedSvg;
export const TeamSummaryIcon = TeamSummarySvg;
export const TeamSummarySelectedIcon = TeamSummarySelectedSvg;
export const DeliverableIcon = withStyledIcon(DeliverableSvg);
export const DevelopIcon = withStyledIcon(DevelopSvg);

export const MoolahIcon = ({ ref, ...props }) => (
  <StyledIcon color="secondary" ref={ref} {...props}>
    <MoolahSvg />
  </StyledIcon>
);

export const GroupMoolahIcon = ({ ref, ...props }) => (
  <StyledIcon color="green" ref={ref} {...props}>
    <GroupMoolahSvg />
  </StyledIcon>
);
export const MeshInIcon = withStyledIcon(MeshInSvg);

export const ExperienceIcon = withStyledIcon(ExperienceSvg);

export const TeamIcon = withStyledIcon(TeamSvg);

export const InsightsIcon = withStyledIcon(InsightsSvg);

// export const DeliverableIcon = Deliverable;
export const StrengthIcon = StrengthSvg;
export const NotificationIcon = NotificationSvg;
export const ThumbsDownIcon = ThumbsDown;
export const ThumbsUpIcon = ThumbsUpSvg;
export const NotificationIcon1 = withStyledIcon(Notification1Svg);
export const GrowBadgeOutset = (props) => (
  <BadgeContainer {...props}>
    <GrowBadgeOutsetSvg />
  </BadgeContainer>
);
export const LoginBadgeOutset = (props) => (
  <BadgeContainer {...props}>
    <LoginBadgeOutsetSvg />
  </BadgeContainer>
);
export const ConnectBadgeOutset = (props) => (
  <BadgeContainer {...props}>
    <ConnectBadgeOutsetSvg />
  </BadgeContainer>
);
export const ReflectBadgeOutset = (props) => (
  <BadgeContainer {...props}>
    <ReflectBadgeOutsetSvg />
  </BadgeContainer>
);

export const badgesObject = {
  grow: <GrowBadgeOutset />,
  connect: <ConnectBadgeOutset />,
  login: <LoginBadgeOutset />,
  reflect: <ReflectBadgeOutset />
};

export const CircularInfoIcon = ({
  size = 'smallest',
  backgroundColor = 'textSecondary',
  ...props // ref and props are used by the tooltip component
}) => (
  <CircularInfoContainer {...props} backgroundColor={backgroundColor} size={size} circular>
    i
  </CircularInfoContainer>
);

export function getBadge({
  key,
  iconColor = '#fafafa',
  backgroundColor = 'green',
  size = 'small'
}) {
  const params = { iconColor, backgroundColor, size };
  switch (key) {
    case 'grow':
      return <GrowBadgeOutset {...params} />;
    case 'connect':
      return <ConnectBadgeOutset {...params} />;
    case 'login':
      return <LoginBadgeOutset {...params} />;
    case 'reflect':
      return <ReflectBadgeOutset {...params} />;
    default:
      return <GrowBadgeOutset {...params} />;
  }
}

export const badges = [
  <GrowBadgeOutset backgroundColor="green" iconColor="#fafafa" />,
  <LoginBadgeOutset />,
  <ConnectBadgeOutset backgroundColor="green" iconColor="#fafafa" />,
  <ReflectBadgeOutset />
];

export const ExpandIcon = ExpandSvg;

// Need to separately import this when working directly with
// material ui icons
export function StyledIcon({ children, ...props }) {
  return <StyledSpan {...props}> {children} </StyledSpan>;
}

const StyledSpan = styled.span`
  svg {
    ${(props) => IconSizesCSS[props.size || 'smallest']};
    fill: ${(props) => getColorFromKey(props.color)};
    ${PaddingCSS};
    ${(props) =>
      props.rotate &&
      css`
        transform: rotate(${props.rotate});
      `}
    ${(props) =>
      props.stroke &&
      css`
        stroke: ${getColorFromKey(props.stroke)};
      `}
  }
  display: inline-flex;
  align-items: center;
  ${(props) =>
    (props.onClick || props.clickable) &&
    css`
      cursor: pointer;
    `};
  ${(props) =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `};
  ${GutterCSS};
  ${(props) =>
    props.circularOutline &&
    css`
      border: 1px solid ${getColorFromKey(props.color)};
      border-radius: 50%;
    `}
  ${(props) =>
    props.smoothEdgeOutline &&
    css`
      border: 1px solid ${getColorFromKey(props.color)};
      border-radius: 4px;
    `}
`;

// This component wraps StyledSpan and provides additional features like bgColor, circular, and container size.
export const StyledIconV2 = styled(StyledSpan)`
  justify-content: center;
  ${(props) => props.containerSize && IconSizesCSS[props.containerSize]}
  ${(props) =>
    props.bgColor &&
    css`
      background: ${getColorFromKey(props.bgColor)};
    `}
  ${(props) =>
    props.circular &&
    css`
      border-radius: 50%;
    `}
  svg {
    ${(props) =>
      props.color &&
      css`
        fill: ${(props) => getColorFromKey(props.color)};
      `}
  }
  ${(props) =>
    props.hoverShadow &&
    css`
      :hover {
        box-shadow: 3px 4px 5px 0px #0000001c;
      }
    `}
`;

const CircularInfoContainer = styled.span`
  ${(props) => props.size && IconSizesCSS[props.size]}
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => props.circular && CircularIconCSS};
`;

export function getStrengthIcon(strengthName, iconProps = {}) {
  strengthName = strengthName.toLowerCase();
  switch (strengthName) {
    case 'accountability and effort':
      return <AccountabilityEffortIcon {...iconProps} />;
    case 'drive and competitiveness':
      return <DriveCompetitivenessIcon {...iconProps} />;
    case 'learning and application':
      return <LearningStrengthIcon {...iconProps} />;
    case 'relationship and trust':
      return <RelationshipStrengthIcon {...iconProps} />;
    case 'harmony and inclusion':
      return <HarmonyStrengthIcon {...iconProps} />;
    case 'mentoring and development':
      return <MentoringStrengthIcon {...iconProps} />;
    case 'influence and conviction':
      return <InfluenceStrengthIcon {...iconProps} />;
    case 'innovation and planning':
      return <InnovationStrengthIcon {...iconProps} />;
    case 'focus and priorities':
      return <FocusStrengthIcon {...iconProps} />;
    default:
      return <AccountabilityEffortIcon {...iconProps} />;
  }
}

export const PastIcon = PastSvg;
export const PulseIcon = PulseSvg;

// performance review icons
export const ReviewNavSelectedIcon = ReviewNavIconSelectedSvg;
export const NoRecognitionFeedbackPlaceholderImg = NoRecognitionFeedbackPlaceholderSvg;
export const ReviewChartMiniIcon = ReviewChartMiniIconSvg;
export const GrowthGraphMiniIcon = GrowthGraphMiniIconSvg;
export const ReviewMiniIcon = ReviewMiniIconSvg;
export const ReviewTeamStatusIcon = ReviewTeamStatusIconSvg;
export const PDFFileIcon = PDFFileIconSvg;
export const DownloadIcon = withStyledIcon(DownloadIconSvg);
export const EmployeeIconCircleIcon = EmployeeIconCircleSvg;
export const HashTagIcon = HashTagIconSvg;

// analytics icons

export const CloseIcon = CloseIconSvg;
export const HourglassIcon = HourglassIconSvg;
export const StyledHourGlassIcon = withStyledIcon(HourglassIcon);
export const CloseIconv1 = withStyledIcon(CloseIconv1Svg);

export const StyledCloseIcon = withStyledIcon(CloseIconSvg);

export const EmployeeProgressIcon = withStyledIcon(EmployeeGrowthIconSvg);
export const EmployeeCheckInIcon = withStyledIcon(CheckinIconSvg);

export const ShareCohortIcon = withStyledIcon(ShareIconSvg);
export const LockIcon = withStyledIcon(PrivateViewSvg);

export const FormIcon = withStyledIcon(FormIconSvg);
export const UserIcon2 = withStyledIcon(UserIcon2Svg);
export const EmailIcon = withStyledIcon(EmailIconSvg);

export const SpeakerIcon = withStyledIcon(SpeakerIconSvg);
export const MIcon = withStyledIcon(MIconSvg);
export const PlaceIcon = withStyledIcon(PlaceIconSvg);
export const CircularDownloadIcon = withStyledIcon(CircularDownloadIconSvg);
export const BarGraphIcon = withStyledIcon(BarGraphIconSvg);
export const TimelineGraphIcon = withStyledIcon(TimelineGraphIconSvg);
export const ArrowDownwardIcon = withStyledIcon(ArrowDownwardIconSvg);
export const BackArrowIcon2 = withStyledIcon(BackArrowSvg);
export const ArrowUpwardIcon = styled(ArrowDownwardIcon)`
  transform: rotate(180deg);
`;
export const DownloadArrowIcon = withStyledIcon(DownloadArrowIconSvg);
export const CalendarIcon = withStyledIcon(CalendarIconSvg);

export const CheckedCheckboxIcon = withStyledIconV2(CheckedCheckbox);
export const UncheckedCheckboxIcon = withStyledIconV2(UncheckedCheckbox);

// Continuous Feedback Icons
export const ContinuousFeedbackIcon = ContinuousFeedbackSvg;
export const ManagerViewIcon = ManagerViewSvg;
export const PrivateViewIcon = PrivateViewSvg;
export const PrivateManagerViewIcon = PrivateManagerViewSvg;
export const PublicViewIcon = PublicViewSvg;
export const SendTiltedIcon = SendTiltedSvg;
export const ShareIcon = withStyledIcon(ShareSvg);
export const RequestIcon = withStyledIcon(RequestSvg);
export const WomanIcon = withStyledIcon(WomanSvg);
export const ManIcon = withStyledIcon(ManSvg);

export const KudosSvgIcon = withStyledIconV2(KudosSvg);

// Engagement Icons
export const CloseWithStyledIcon = withStyledIcon(CloseIconSvg);
export const PreviewIcon = withStyledIconV2(PreviewSvg);

export const StarIcon = withStyledIcon(StarSvg);
// Header Icons
export const InsightsHeaderIcon = withStyledIcon(InsightsHeaderIconSvg);
export const HomeHeaderIcon = withStyledIcon(HomeHeaderIconSvg);
export const TeamHubHeaderIcon = withStyledIcon(TeamHubHeaderIconSvg);
export const PulseHeaderIcon = withStyledIcon(PulseHeaderIconSvg);
export const NotificationHeaderIcon = withStyledIcon(NotificationHeaderIconSvg);
export const ReviewHeaderIcon = withStyledIcon(ReviewHeaderIconSvg);
export const UserChatBubbleIcon = withStyledIcon(UserChatBubbleSvg);
export const TasksIcon = withStyledIcon(TasksSvg);
export const PrivateTaskIcon = withStyledIconV2(PrivateTasksSvg);
export const ArrowDownIcon = withStyledIcon(ArrowDownIconSvg);
export const SearchIcon = withStyledIcon(SearchSvg);
export const OneOnOneHeaderIcon = withStyledIcon(OneOnOneHeaderIconSvg);
export const LevelUpIcon = withStyledIcon(LevelUpIconSvg);

// Weightage Icons
export const EditWeightageIcon = withStyledIcon(EditIconSvg);
export const ViewIcon = withStyledIcon(ViewIconSvg);
export const TeamsIcon = withStyledIcon(TwoPeopleSvg);

// Nominate Icons
export const AddUserIcon2 = withStyledIcon(AddUserSvg2);
export const SeniorsIcon = withStyledIcon(SeniorsSvg);
export const JuniorsIcon = withStyledIcon(JuniorsSvg);
export const PrimaryTeamIcon = withStyledIcon(PrimaryTeamSvg);
export const OutsideTeamIcon = withStyledIcon(OutsideTeamSvg);
export const AddPeerIcon = withStyledIcon(AddPeerSvg);

export const DotsIcon = withStyledIcon(DotsSvg);
export const ListIcon = withStyledIconV2(ListSvg);
export const WinnerCupIcon = withStyledIcon(WinnerCupSvg);
export const PictureIcon = withStyledIcon(PictureSvg);
export const CrossIcon = withStyledIcon(CrossSvg);
export const CrossThickIcon = withStyledIcon(CrossThickSvg);
export const PlayIcon = withStyledIcon(PlaySvg);
export const PanningNavigationIcon = withStyledIcon(PanningNavigationSvg);
export const GlobeIcon = withStyledIcon(GlobeSvg);
export const GlobeSvgIcon = GlobeSvg;
export const EmojiSmileIcon = withStyledIcon(EmojiSmileSvg);

// Calibration Grid Icons
export const RoundedArrowUpIcon = withStyledIcon(RoundedArrowUp);
export const RoundedArrowRightIcon = withStyledIcon(RoundedArrowRight);

// Review Template Icons
export const RemoveIcon = withStyledIcon(RemoveIconSvg);
export const UpArrowIcon2 = withStyledIconV2(upArrowSvg2);
export const DownArrowIcon2 = withStyledIconV2(downArrowSvg2);
export const DeleteIcon3 = withStyledIconV2(DeleteIcon3Svg);
export const InfoIcon = withStyledIconV2(InfoIconSvg);
export const InfoIcon2 = withStyledIconV2(InfoIcon2Svg);
export const CrossIcon2 = withStyledIconV2(CrossIconSvg);
export const PlusIcon = withStyledIconV2(PlusIconSvg);
export const CheckIcon2 = withStyledIconV2(CheckIcon2Svg);
export const EmptyCheckbox = withStyledIconV2(EmptyCheckboxSvg);
export const CloseIcon2 = withStyledIconV2(CloseIconSvg);

export const OneOnOneIcon = withStyledIcon(OneOnOneSvg);
export const DeleteIcon = withStyledIcon(DeleteIconSvg);
export const DeleteIconFilled = withStyledIcon(DeleteFilledIconSvg);
export const LinkIcon = withStyledIcon(LinkIconSvg);

// Objective tags icons
export const CaretDownIcon = withStyledIcon(CaretDown);
export const CTagIcon = withStyledIcon(CTag);
export const DTagIcon = withStyledIcon(DTag);
export const ITagIcon = withStyledIcon(ITag);
export const TTagIcon = withStyledIcon(TTag);

export const CompanyTagSvgIcon = withStyledIconV2(CompanyTagSvg);
export const DepartmentTagSvgIcon = withStyledIconV2(DepartmentTagSvg);
export const IndividualTagSvgIcon = withStyledIconV2(IndividualTagSvg);
export const TeamTagSvgIcon = withStyledIconV2(TeamTagSvg);

export const NoTagIcon = withStyledIcon(NoTag);
export const ReplaceIcon = withStyledIcon(ReplaceSvg);
// thank the designer for this.
export const AddCircular2Icon = withStyledIcon(AddCircular2Svg);
export const ThinCurvedArrowIcon = withStyledIcon(ThinCurvedArrowSvg);

// Question Form icons

export const StarAnswerIcon = (props) => <StarIcon color="#FFE17D" {...props} />;
export const ScaleIcon = withStyledIcon(ScaleSvg);
export const TextBoxIcon = withStyledIcon(TextBoxSvg);
export const NumberIcon = withStyledIcon(NumberSvg);
export const OptionsIcon = withStyledIcon(OptionsSvg);
export const CommentAnswerIcon = withStyledIcon(CommentSvg);

// CheckIn Accordion
export const ManagerAndReporteeIcon = withStyledIconV2(ManagerAndReporteeSvg);
export const OnlyReporteeIcon = withStyledIconV2(OnlyReporteeSvg);

// TeamIcons
export const AddUserIcon3 = withStyledIconV2(AddUserSvg2);

// OneOnOne Icons
export const TwoPeopleIcon = TwoPeopleSvg;
export const TwoPeopleStyledIcon = withStyledIcon(TwoPeopleIcon);

// OneOnOne Refreshed Icons

export const RescheduleMeetingIcon = withStyledIconV2(RescheduleMeetingSvg);
export const ReopenMeetingIcon = withStyledIconV2(ReopenMeetingSvg);
export const CancelMeetingIcon = withStyledIconV2(CancelMeetingSvg);
export const CancelledMeetingsIcon = withStyledIconV2(CancelledMeetingsSvg);
export const ShareMeetingIcon = withStyledIconV2(ShareMeetingSvg);

export const PlusIcon2 = PlusSvg;
export const PlusIcon3 = withStyledIcon(PlusSvg);
export const FilledGearIcon = FilledGearSvg;

export const ArrowThickUpIcon = withStyledIcon(ArrowThickUpSvg);
export const ArrowThickDownIcon = withStyledIcon(
  styled(ArrowThickUpSvg)`
    transform: rotate(180deg);
  `
);
export const ArrowThickRightIcon = withStyledIcon(
  styled(ArrowThickUpSvg)`
    margin-right: -2px;
    transform: rotate(90deg);
  `
);
export const ArrowThickLeftIcon = withStyledIcon(
  styled(ArrowThickUpSvg)`
    margin-left: -2px;
    transform: rotate(270deg);
  `
);
export const ChevronLeftIcon = withStyledIconV2(ChevronLeftSvg);
export const ChevronRightIcon = withStyledIconV2(
  styled(ChevronLeftSvg)`
    transform: rotate(180deg);
  `
);
export const ChevronDownIcon = withStyledIconV2(
  styled(ChevronLeftSvg)`
    transform: rotate(270deg);
  `
);
export const ChevronUpIcon = withStyledIconV2(
  styled(ChevronLeftSvg)`
    transform: rotate(90deg);
  `
);

export const RemoveIconV2 = withStyledIconV2(RemoveIconSvg);
export const JustifyIcon = withStyledIconV2(JustifySvg);
// Review Settings Icons
export const TrophyIcon = withStyledIconV2(TrophySvg);
export const VerticalUpArrow = withStyledIconV2(VerticalUpArrowSvg);
export const HorizontalRightArrow = withStyledIconV2(HorizontalRightArrowSvg);
export const EditPenIcon = withStyledIconV2(EditPenSvg);

export const PositiveSentimentArrowIcon = withStyledIconV2(PositiveSentimentArrowSvg);
export const NegativeSentimentArrowIcon = withStyledIconV2(NegativeSentimentArrowSvg);

// competency redesign side panel
export const AnonymousUserIcon = withStyledIconV2(AnonymousUserIconSvg);

// notification icons

export const BellNotificationIcon = withStyledIcon(BellNotificationSvg);
export const SlackNotificationIcon = withStyledIconV2(SlackNotificationSvg);
export const BrowserNotificationIcon = withStyledIconV2(BrowserNotificationSvg);
export const EmailNotificationIcon = withStyledIconV2(EmailNotificationSvg);
export const RoundedSquareCheckIcon = withStyledIconV2(RoundedSquareCheckSvg);
// feedback sentiment icons
export const AppreciativeFeedbackIcon = withStyledIconV2(AppreciativeFeedbackSvg);
export const ConstructiveFeedbackIcon = withStyledIconV2(ConstructiveFeedbackSvg);
export const NeutralFeedbackIcon = withStyledIconV2(NeutralFeedbackSvg);
export const NotificationSettingsIcon = withStyledIconV2(NotificationSettingsSvg);
export const NotificationTiltedIcon = withStyledIconV2(NotificationTiltedSvg);
export const NotificationReminderIcon = withStyledIconV2(NotificationReminderSvg);

export const DustbinSvgIcon = withStyledIconV2(DustbinSvg);

export const DownChevronSvgIcon = withStyledIconV2(DownChevronSvg);

export const List2SvgIcon = withStyledIconV2(List2Svg);
export const Bullet2SvgIcon = withStyledIconV2(Bullet2Svg);

// feedback icon changes.
export const PraiseSvgIcon = withStyledIconV2(MegaPhoneSvg);
export const PraiseSvgIconColored = withStyledIconV2(MegaPhoneSvgColored);
export const FeedbackSvgIcon = withStyledIconV2(CommentFeedbackSvg);
export const ImagePlaceholderSvgIcon = withStyledIconV2(ImageSvg);

export const PrivateKRSvgIcon = withStyledIconV2(PrivateKRSvg);
export const ObjectiveSvgIcon = withStyledIconV2(ObjectiveSvg);
export const KrSvgIcon = withStyledIconV2(KrSvg);
// new goal icons
export const SubDirectorySvgIcon = withStyledIconV2(SubDirectorySvg);
export const ObjectiveTwoToneSvgIcon = withStyledIconV2(ObjectiveTwoToneSvg);
export const RealignSvgIcon = withStyledIconV2(RealignSvg);
export const ParentRealignIcon = withStyledIconV2(ParentRealignSvg);

// level up icons
export const SkilledIcon = withStyledIconV2(SkilledSvg);
export const BuildingIcon = withStyledIconV2(BuildingSvg);
export const FeedbackIcon = withStyledIconV2(FeedbackSvg);

// navbar icons for goals and initiatives

export const GoalsNavSvgIcon = withStyledIconV2(GoalsNavSvg);

export const DetailsSvgIcon = withStyledIconV2(DetailsIconSvg);

export const UpdateWeightageIcon = withStyledIconV2(UpdatWeightage);

// RTE Icons
export const RteBoldIcon = withStyledIconV2(RteBoldSvg);
export const RteItalicIcon = withStyledIconV2(RteItalicSvg);
export const RteUnderlineIcon = withStyledIconV2(RteUnderlineSvg);
export const RteStrikethroughIcon = withStyledIconV2(RteStrikethroughSvg);
export const RteUlIcon = withStyledIconV2(RteUlSvg);
export const RteOlIcon = withStyledIconV2(RteOlSvg);

export const EditNotificationIcon = withStyledIconV2(EditNotificationSvg);

import { createSlice } from '@reduxjs/toolkit';

export const ACTIONS_TO_PERFORM = {
  REALIGN: 'REALIGN',
  CLONE: 'CLONE',
  DELETE: 'DELETE',
};

const initialState = null;

export const okrPanelKebabMenuActionsSlice = createSlice({
  name: 'okrPanelKebabMenuActionsSlice',
  initialState,
  reducers: {
    performRealign: () => ACTIONS_TO_PERFORM.REALIGN,
    performClone: () => ACTIONS_TO_PERFORM.CLONE,
    performDelete: () => ACTIONS_TO_PERFORM.DELETE,
    markActionAsDone: () => null,
  },
});

// Action creators are generated for each case reducer function
export const { performRealign, performClone, performDelete, markActionAsDone } = okrPanelKebabMenuActionsSlice.actions;

export default okrPanelKebabMenuActionsSlice.reducer;

import { Icon } from '@chakra-ui/react';

export const MyInitiatives = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_433_131)">
      <path
        d="M12 20H4V18.85C4 18.49 4.15 18.16 4.46 17.86C4.72 17.6 5.08 17.38 5.53 17.19C6.44 16.8 7.43 16.66 8.01 16.66C8.59 16.66 9.57 16.8 10.49 17.19C10.94 17.38 11.3 17.61 11.56 17.86C11.86 18.16 12.02 18.49 12.02 18.85V20H12ZM8 15.83C6.87 15.83 5.95 14.85 5.95 13.64C5.95 12.43 6.87 11.45 8 11.45C9.13 11.45 10.05 12.43 10.05 13.64C10.05 14.85 9.13 15.83 8 15.83Z"
        fill="currentColor"
      />
      <path d="M16.8299 7.79999L13.6699 10.79L12.1699 9.12999" stroke="currentColor" strokeWidth="2" />
      <path
        d="M18.18 4H10.82C9.82 4 9 4.82 9 5.82V10.63C10.19 11.07 11.05 12.24 11.05 13.63C11.05 14.12 10.94 14.58 10.75 14.99C10.77 14.99 10.79 14.99 10.81 14.99H18.17C19.17 14.99 19.99 14.17 19.99 13.17V5.82C20 4.82 19.18 4 18.18 4ZM18 13H11V6H18V13Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_433_131">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const MyOrgDefaultOneOnOneLatest = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M18.5 4H12.5C11.67 4 11 4.67 11 5.5V6H15.5C16.88 6 18 7.12 18 8.5V11H18.5C19.33 11 20 10.33 20 9.5V5.5C20 4.67 19.33 4 18.5 4Z"
      fill="currentColor"
    />
    <path
      d="M8 19.5C9.38071 19.5 10.5 18.3807 10.5 17C10.5 15.6193 9.38071 14.5 8 14.5C6.61929 14.5 5.5 15.6193 5.5 17C5.5 18.3807 6.61929 19.5 8 19.5Z"
      stroke="currentColor"
    />
    <path
      d="M9.79995 14.3V13.1C9.79995 12.9 9.69995 12.8 9.49995 12.7L8.29995 12.1C8.19995 12 8.09995 12 7.99995 12C7.89995 12 7.79995 12 7.69995 12.1L6.49995 12.7C6.29995 12.8 6.19995 12.9 6.19995 13.1V14.3C6.19995 14.5 6.29995 14.6 6.49995 14.7L7.69995 15.3C7.79995 15.4 7.89995 15.4 7.99995 15.4C8.09995 15.4 8.19995 15.3 8.29995 15.3L9.49995 14.7C9.69995 14.6 9.79995 14.5 9.79995 14.3Z"
      fill="currentColor"
    />
    <path
      d="M6.8 19V17.7C6.8 17.5 6.7 17.4 6.6 17.3L5.6 16.7C5.53333 16.6333 5.46667 16.6 5.4 16.6H5.2L4.2 17.2C4.1 17.3 4 17.4 4 17.6V18.9C4 19.1 4.1 19.2 4.2 19.3L5.2 19.9C5.26667 19.9666 5.33333 20 5.4 20H5.6L6.6 19.4C6.7 19.3 6.8 19.2 6.8 19Z"
      fill="currentColor"
    />
    <path
      d="M12 19V17.7C12 17.5 11.9 17.3 11.8 17.3L10.8 16.7C10.7333 16.6333 10.6666 16.6 10.6 16.6H10.4L9.39995 17.2C9.29995 17.3 9.19995 17.4 9.19995 17.6V18.9C9.19995 19.1 9.29995 19.2 9.39995 19.3L10.4 19.9C10.5 20 10.6 20 10.6 20H10.8L11.8 19.4C11.9 19.3 12 19.2 12 19Z"
      fill="currentColor"
    />
    <path
      d="M15.5 7H9.5C8.67 7 8 7.67 8 8.5V11C8.15 11 8.5 11 8.85 11.26L9.95 11.81C9.95 11.81 9.98 11.84 10 11.85V9.01H15V12.01H13.91C13.24 12.01 12.62 12.27 12.14 12.74L11.99 12.89V12.01H10.22C10.66 12.35 10.79 12.78 10.79 13.11V14.17C11.18 14.56 11.5 15.03 11.71 15.55C12.02 15.5 12.32 15.39 12.55 15.15L13.55 14.15C13.64 14.06 13.77 14 13.9 14H15.49C16.32 14 16.99 13.33 16.99 12.5V8.5C16.99 7.67 16.32 7 15.49 7H15.5Z"
      fill="currentColor"
    />
  </Icon>
);

// eslint-disable-next-line import/no-cycle
import store from 'store.js';

export function selectedLoggedInUsersAccesses(state) {
  return state?.loggedInUsersAccesses ?? {};
}

export const getUserAccess = () => {
  const loggedInUserAccesses = selectedLoggedInUsersAccesses(store.getState());
  return {
    isDirectManager: loggedInUserAccesses?.directManager,
    isSkipManager: loggedInUserAccesses?.skipManager,
    isPasswordEnabled: loggedInUserAccesses?.passwordEnabled,
    isGoalAdmin: loggedInUserAccesses?.adminAccesses?.includes('GOAL_ADMIN'),
    isReviewAdmin: loggedInUserAccesses?.adminAccesses?.includes('REVIEW_ADMIN'),
    isEngagementAdmin: loggedInUserAccesses?.adminAccesses?.includes('ENGAGEMENT_ADMIN'),
    isFeedbackAdmin: loggedInUserAccesses?.adminAccesses?.includes('FEEDBACK_ADMIN'),
    isPlatformAdmin: loggedInUserAccesses?.adminAccesses?.includes('PLATFORM_ADMIN'),
    isOneOnOneAdmin: loggedInUserAccesses?.adminAccesses?.includes('ONE_ON_ONE_ADMIN'),
    isCustomAdmin:
      loggedInUserAccesses?.adminAccesses?.includes('CUSTOM_ADMIN') && loggedInUserAccesses?.customAccess?.length > 0,
    isCustomGoalAdmin:
      loggedInUserAccesses?.adminAccesses?.includes('CUSTOM_ADMIN') &&
      loggedInUserAccesses?.customAccess?.includes('GOAL_INSIGHTS'),
    isCustomReviewAdmin:
      loggedInUserAccesses?.adminAccesses?.includes('CUSTOM_ADMIN') &&
      loggedInUserAccesses?.customAccess?.includes('REVIEW_INSIGHTS'),
    isCustomEngagementAdmin:
      loggedInUserAccesses?.adminAccesses?.includes('CUSTOM_ADMIN') &&
      loggedInUserAccesses?.customAccess?.includes('ENGAGEMENT_INSIGHTS'),
    isCustomFeedbackAdmin:
      loggedInUserAccesses?.adminAccesses?.includes('CUSTOM_ADMIN') &&
      loggedInUserAccesses?.customAccess?.includes('FEEDBACK_INSIGHTS'),
    isCustomOneOnOneAdmin:
      loggedInUserAccesses?.adminAccesses?.includes('CUSTOM_ADMIN') &&
      loggedInUserAccesses?.customAccess?.includes('ONE_ON_ONE_INSIGHTS'),
    isModuleAdmin:
      loggedInUserAccesses?.adminAccesses?.filter((access) =>
        ['GOAL_ADMIN', 'REVIEW_ADMIN', 'ENGAGEMENT_ADMIN', 'FEEDBACK_ADMIN', 'ONE_ON_ONE_ADMIN'].includes(access)
      ).length > 0,
  };
};

export const getUserL2Access = () => {
  const loggedInUserAccesses = selectedLoggedInUsersAccesses(store.getState());
  return {
    hasGoalAccess: loggedInUserAccesses.enabledFeatures.includes('GOAL'),
    hasOneOnOneAccess: loggedInUserAccesses.enabledFeatures.includes('CHECK_IN'),
    hasFeedbackAccess: loggedInUserAccesses.enabledFeatures.includes('FEEDBACK'),
    hasReviewAccess: loggedInUserAccesses.enabledFeatures.includes('REVIEW'),
    hasEngagementAccess: loggedInUserAccesses.enabledFeatures.includes('ENGAGEMENT'),
    hasInitiativeAccess: loggedInUserAccesses.enabledFeatures.includes('TASK'),
    hasIDPAccess: loggedInUserAccesses.enabledFeatures.includes('COMPETENCY_IDP'),
  };
};

export const getUserL1Access = () => {
  const userAccess = getUserAccess();
  const l2Access = getUserL2Access();
  return {
    hasL1TeamAccess:
      userAccess.isDirectManager ||
      userAccess.isSkipManager ||
      userAccess.isGoalAdmin ||
      userAccess.isReviewAdmin ||
      userAccess.isCustomGoalAdmin ||
      userAccess.isCustomReviewAdmin,

    hasViewsAccess:
      (userAccess.isDirectManager && (l2Access.hasGoalAccess || l2Access.hasReviewAccess)) ||
      (userAccess.isSkipManager && (l2Access.hasGoalAccess || l2Access.hasReviewAccess)) ||
      userAccess.isGoalAdmin ||
      userAccess.isReviewAdmin ||
      userAccess.isCustomGoalAdmin ||
      userAccess.isCustomReviewAdmin,
    hasL1CompanyAccess: userAccess.isCustomAdmin || userAccess.isModuleAdmin || userAccess.isPlatformAdmin,
  };
};

export const getProfileRole = () => {
  const { hasL1CompanyAccess, hasL1TeamAccess } = getUserL1Access();
  const { isSkipManager } = getUserAccess();
  if (hasL1CompanyAccess) {
    return 'Admin';
  }
  if (hasL1TeamAccess) {
    return 'Manager';
  }
  if (isSkipManager) {
    return 'Manager of Managers';
  }
  return 'Individual';
};

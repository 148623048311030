import { Icon } from '@chakra-ui/react';

export const ExpandIconV2 = (props) => (
  <Icon viewBox="0 0 32 32" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_2357_161418)">
      <path d="M9 23L14.5 17.5" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
      <path d="M15 23L9 23" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
      <path d="M9 23L9 17" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
      <path d="M23 9L17.5 14.5" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
      <path d="M17 9L23 9" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
      <path d="M23 9L23 15" stroke="#666666" strokeWidth="2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_2357_161418">
        <rect width="24" height="24" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

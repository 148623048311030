import theme from 'constants/theme';

export default class ColorCodedDate {
  constructor({ timeStamp, color, reason, isCrossed = false }) {
    // should have received timestamp as variable.
    this.timeStamp = timeStamp;
    this.color = color;
    this.reason = reason;
    this.isCrossed = isCrossed;
  }

  static fromJSON({ timeStamp, color, reason, isCrossed }) {
    return new ColorCodedDate({
      timeStamp,
      color: getColorCode(color),
      reason: reason || '',
      isCrossed: isDateCrossed(color) || isCrossed,
    });
  }
}

function isDateCrossed(color) {
  // this logic needs to fixed with backend. otherwise going forward it will be chaos.
  return String(color).endsWith('_CROSSED') || String(color).startsWith('DARK_');
}

function getColorCode(color) {
  switch (color) {
    case 'GREEN':
    case 'GREEN_CROSSED':
    case 'DARK_GREEN':
      return theme.palette.green.main;

    case 'GREY':
    case 'GREY_CROSSED':
    case 'DARK_GREY':
      return theme.palette.grey.dark;

    case 'LIGHT_GREY':
      return theme.palette.grey.main;

    case 'RED':
    case 'RED_CROSSED':
    case 'DARK_RED':
      return theme.palette.red.main;

    case 'BLUE':
      return theme.palette.primary.main;
    case 'DARK_BLUE':
      return theme.palette.primary.main;

    case 'ORANGE':
      return theme.palette.orange.main;

    default:
      return null;
  }
}

/* 
- actual date,
- color of text and background color,
- reason of that color,
- isCrossed, means changeable or not.

*/

import { Icon } from '@chakra-ui/react';

export const OneOnOneIconHover = (props) => (
  <Icon viewBox="0 0 25 25" {...props}>
    <g clipPath="url(#clip0_133_257)">
      <path
        d="M10.3328 15.746H12.5528L9.33284 18.6189L9.28284 16.3492L9.67284 16.0016C9.86284 15.838 10.0928 15.746 10.3328 15.746Z"
        fill="#333333"
      />
      <path
        d="M20.1428 12.8323C20.1428 13.8751 19.2728 14.7236 18.2228 14.7236H18.0828V8.58946C18.0828 7.46486 17.1928 6.54473 16.0828 6.54473H7.13281V6.39137C7.13281 5.34856 7.99281 4.5 9.05281 4.5H18.1828C19.2328 4.5 20.1028 5.34856 20.1028 6.39137L20.1428 12.8323Z"
        fill="#333333"
      />
      <path
        d="M17.1128 9.45845C17.1128 8.41564 16.2528 7.56708 15.1928 7.56708H6.06282C5.00282 7.56708 4.14282 8.41564 4.14282 9.45845L4.18282 15.8993C4.18282 16.9422 5.05282 17.7907 6.11282 17.7907H6.26282L6.30282 19.4981C6.31282 19.8968 6.54282 20.2546 6.89282 20.408C7.02282 20.4693 7.16282 20.5 7.30282 20.5C7.54282 20.5 7.77282 20.408 7.96282 20.2444L10.7128 17.7907H15.2328C16.2928 17.7907 17.1528 16.9422 17.1528 15.8993L17.1128 9.45845ZM10.3328 15.746C10.0928 15.746 9.86282 15.838 9.67282 16.0016L8.25282 17.2693V15.746H6.18282L6.14282 9.61181H15.1128L15.1528 15.746H10.3328Z"
        fill="#333333"
      />
    </g>
    <defs>
      <clipPath id="clip0_133_257">
        <rect width="16" height="16" fill="white" transform="translate(4.14282 4.5)" />
      </clipPath>
    </defs>
  </Icon>
);

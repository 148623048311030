import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoggedInUsersAccesses } from 'Deer/types/logeedInUsersAccesses';
import { useSelector } from 'react-redux';

const initialState: LoggedInUsersAccesses = {
  adminAccesses: [],
  customAccess: [],
  directManager: false,
  enabledFeatures: [],
  passwordEnabled: false,
  skipManager: false,
  cohorts: [],
  visierAuthToken: '',
};

export const loggedInUsersAccessesSlice = createSlice({
  name: 'loggedInUsersAccesses',
  initialState,
  reducers: {
    setLoggedInUsersAccesses: (state, action: PayloadAction<LoggedInUsersAccesses>) => ({
      ...state,
      ...action.payload,
    }),
    setVisierAuthToken: (state, action: PayloadAction<string>) => {
      state.visierAuthToken = action.payload;
    },
  },
});

export const { setLoggedInUsersAccesses, setVisierAuthToken } = loggedInUsersAccessesSlice.actions;

export default loggedInUsersAccessesSlice.reducer;

export const useSelectedLoggedInUsersAccesses = () =>
  useSelector((state: { loggedInUsersAccesses: LoggedInUsersAccesses }) => state.loggedInUsersAccesses);

export const useSelectVisierAuthToken = () =>
  useSelector((state: { loggedInUsersAccesses: LoggedInUsersAccesses }) => state.loggedInUsersAccesses.visierAuthToken);

import { Icon } from '@chakra-ui/react';

export const PlaneExpandMore = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_258_245)">
      <path d="M5 19L10.5 13.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M11 19L5 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M5 19L5 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M19 5L13.5 10.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M13 5L19 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M19 5L19 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_258_245">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Overflow = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_633_80)">
      <path
        d="M12 8.5C12.825 8.5 13.5 7.825 13.5 7C13.5 6.175 12.825 5.5 12 5.5C11.175 5.5 10.5 6.175 10.5 7C10.5 7.825 11.175 8.5 12 8.5Z"
        fill="#666666"
      />
      <path
        d="M12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5Z"
        fill="#666666"
      />
      <path
        d="M12 18.5C12.825 18.5 13.5 17.825 13.5 17C13.5 16.175 12.825 15.5 12 15.5C11.175 15.5 10.5 16.175 10.5 17C10.5 17.825 11.175 18.5 12 18.5Z"
        fill="#666666"
      />
    </g>
    <defs>
      <clipPath id="clip0_633_80">
        <rect width="3" height="13" fill="white" transform="translate(10.5 5.5)" />
      </clipPath>
    </defs>
  </Icon>
);

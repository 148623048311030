import { Icon } from '@chakra-ui/react';

export const MyCompanyDevelop = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_404_247)">
      <path
        d="M18.7 4H16.3C15.58 4 15 4.58 15 5.3V7H13.3C12.58 7 12 7.58 12 8.3V10H10.7C11.49 10 12.18 10.4 12.6 11H13.7C14.6 11 15.37 11.52 15.75 12.28V11H15.19C15.11 11 15.05 10.95 15.02 10.87C14.99 10.79 15.01 10.7 15.06 10.65L16.37 9.06C16.44 8.98 16.56 8.98 16.64 9.06L17.95 10.65C18 10.71 18.02 10.8 17.99 10.87C17.96 10.95 17.89 11 17.82 11H17.26V13H15.98C15.99 13.1 16.01 13.2 16.01 13.3V15H18.71C19.43 15 20.01 14.42 20.01 13.7V5.3C20.01 4.58 19.43 4 18.71 4H18.7Z"
        fill="currentColor"
      />
      <path d="M14 16H5V19H14V16Z" stroke="currentColor" strokeWidth="2" />
      <path
        d="M5.3 13H13.7C13.9 13 14 13.1 14 13.3V19H5V13.3C5 13.1 5.1 13 5.3 13Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M11 19V12.3C11 12.2 10.9 12 10.7 12H8.3C8.1 12 8 12.2 8 12.3V19" stroke="currentColor" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_404_247">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const StarFilled = (props) => (
  <Icon viewBox="0 0 46 46" boxSize="16px" color="icon.g1" {...props}>
    <g filter="url(#filter0_d_106_49420)">
      <rect x="4" y="4" width="38" height="38" rx="4" fill="#104F95" />
      <path
        d="M23.0001 28.0599L26.8043 30.3608C27.501 30.7824 28.3535 30.1591 28.1701 29.3708L27.1618 25.0441L30.526 22.1291C31.1401 21.5974 30.8101 20.5891 30.0035 20.5249L25.576 20.1491L23.8435 16.0608C23.5318 15.3183 22.4685 15.3183 22.1568 16.0608L20.4243 20.1399L15.9968 20.5158C15.1901 20.5799 14.8601 21.5883 15.4743 22.1199L18.8385 25.0349L17.8301 29.3616C17.6468 30.1499 18.4993 30.7733 19.196 30.3516L23.0001 28.0599Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_106_49420"
        x="0"
        y="0"
        width="46"
        height="46"
        filterUnits="objectBoundingBox"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.556863 0 0 0 0 0.941176 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_106_49420" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_106_49420" result="shape" />
      </filter>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const MyTeamDefaultOneOnOneLatest = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M18.5 4H12.5C11.67 4 11 4.67 11 5.5V6H15.5C16.88 6 18 7.12 18 8.5V11H18.5C19.33 11 20 10.33 20 9.5V5.5C20 4.67 19.33 4 18.5 4Z"
      fill="currentColor"
    />
    <path
      d="M6.7 17.1C7.2 17.1 7.7 16.8 7.9 16.3C7.7 15.9 7.6 15.4 7.6 15V14.6C7.4 14.4 7.1 14.2 6.7 14.2C6 14.2 5.4 14.8 5.4 15.6C5.3 16.5 5.9 17.1 6.7 17.1ZM6 19.2C6 18.7 6.2 18.3 6.5 17.9C5.5 17.9 4 18.4 4 19.3V20H6V19.2ZM10.1 16.7C11 16.7 11.7 16 11.7 15C11.7 14.1 11 13.3 10.1 13.3C9.2 13.3 8.5 14 8.5 15C8.5 15.9 9.2 16.7 10.1 16.7ZM10.1 17.5C9.1 17.5 7 18.1 7 19.2V20H13.2V19.2C13.2 18.1 11.1 17.5 10.1 17.5ZM13.7 17.9C14 18.3 14.2 18.7 14.2 19.2V20H16.2V19.3C16.1 18.4 14.6 17.9 13.7 17.9ZM12.6 15C12.6 15.5 12.5 15.9 12.3 16.3C12.5 16.8 13 17.1 13.5 17.1C14.2 17.1 14.8 16.5 14.8 15.7C14.8 14.9 14.2 14.3 13.5 14.3C13.2 14.3 12.9 14.4 12.6 14.7V15Z"
      fill="currentColor"
    />
    <path
      d="M10.1 12.3C11.05 12.3 11.89 12.84 12.34 13.63C12.69 13.41 13.08 13.3 13.5 13.3C14.13 13.3 14.71 13.57 15.12 14H15.5C16.33 14 17 13.33 17 12.5V8.5C17 7.67 16.33 7 15.5 7H9.5C8.67 7 8 7.67 8 8.5V12.5C8 12.75 8.06 12.97 8.17 13.18C8.64 12.64 9.31 12.3 10.1 12.3ZM10 9H15V12H13.91C13.24 12 12.62 12.26 12.14 12.73L11.99 12.88V12H9.99V9H10Z"
      fill="currentColor"
    />
  </Icon>
);

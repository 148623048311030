import { Icon } from '@chakra-ui/react';

export const FilledStar = (props) => (
  <Icon viewBox="0 0 14 14" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.99992 10.8467L11.1199 13.3334L10.0266 8.64675L13.6666 5.49341L8.87325 5.08675L6.99992 0.666748L5.12659 5.08675L0.333252 5.49341L3.97325 8.64675L2.87992 13.3334L6.99992 10.8467Z"
      fill="currentColor"
    />
  </Icon>
);

import { ADD_MS_TEAMS_CONTEXT } from 'actions/actionTypes';

export const initialState = {
  context: null,
};

const msTeamsContext = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MS_TEAMS_CONTEXT:
      return {
        context: action.data,
      };
    default:
      return state;
  }
};

export default msTeamsContext;

import { Icon } from '@chakra-ui/react';

export const OpenSidePanelWithoutPadding = (props) => (
  <Icon viewBox="0 0 16 17" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_112_19)" fill="transparent">
      <rect x="0.31781" y="0.988235" width="15" height="15" rx="1.5" stroke="currentColor" />
      <path
        d="M10.3178 0.988235H13.8178C14.6462 0.988235 15.3178 1.65981 15.3178 2.48824V14.4882C15.3178 15.3167 14.6462 15.9882 13.8178 15.9882H10.3178V0.988235Z"
        fill="currentColor"
        fillOpacity="0.2"
        stroke="currentColor"
      />
      <path
        d="M2.81781 8.41939C2.81781 8.14325 3.04167 7.91939 3.31781 7.91939H8.31781C8.59395 7.91939 8.81781 8.14325 8.81781 8.41939C8.81781 8.69553 8.59395 8.91939 8.31781 8.91939H3.31781C3.04167 8.91939 2.81781 8.69553 2.81781 8.41939Z"
        fill="currentColor"
      />
      <path
        d="M5.75723 5.72045C5.95249 5.52519 6.26907 5.52519 6.46433 5.72045L8.99078 8.2469C9.18604 8.44216 9.18604 8.75875 8.99078 8.95401C8.79552 9.14927 8.47894 9.14927 8.28368 8.95401L5.75723 6.42756C5.56196 6.2323 5.56196 5.91571 5.75723 5.72045Z"
        fill="currentColor"
      />
      <path
        d="M5.87856 11.3774C6.07383 11.5726 6.39041 11.5726 6.58567 11.3774L8.9927 8.97033C9.18796 8.77506 9.18797 8.45848 8.9927 8.26322C8.79744 8.06796 8.48086 8.06796 8.2856 8.26322L5.87856 10.6703C5.6833 10.8655 5.6833 11.1821 5.87856 11.3774Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_112_19">
        <rect width="16" height="16" fill="white" transform="translate(0 0.431152)" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const FeedbackIconHover = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.99902 18.9155V12.7436C6.99902 12.5177 6.77866 12.5258 6.55905 12.5258H4.41315C4.19354 12.5258 3.99902 12.6872 3.99902 12.9131V19.0318C3.99902 19.2577 4.19354 19.4578 4.41315 19.4578H6.55905C6.77866 19.4352 6.99902 19.1414 6.99902 18.9155Z"
      fill="#333333"
    />
    <path
      d="M13.7031 9.07453L13.2676 10.389H14.6524H18.5769C18.6379 10.4019 18.7457 10.4415 18.8333 10.5202C18.8776 10.5601 18.9148 10.608 18.9425 10.6695C18.9644 10.7181 18.9869 10.7896 18.995 10.8934L17.4599 18.5217C17.3966 18.8098 17.1334 19 16.9112 19H12.13C11.7875 19 11.441 18.9723 11.0933 18.9303C11.093 18.9302 11.0926 18.9302 11.0923 18.9301L10.0339 18.7995L9.99309 18.7913L9.78805 18.7503C9.61825 18.7163 9.39124 18.6711 9.16271 18.6257C8.97043 18.5876 8.77617 18.5493 8.61373 18.5176L8.63336 13.0304C8.64141 13.0197 8.64958 13.0091 8.65765 12.9988C9.12951 12.698 9.47114 12.3538 9.70079 12.0744C9.82858 11.919 9.92409 11.7808 9.99032 11.6763C10.0235 11.6239 10.0496 11.5796 10.0689 11.5454C10.0734 11.5374 10.0776 11.5299 10.0814 11.523L13.9303 5.31422L13.943 5.29387L13.9546 5.27294C13.9932 5.20342 14.0247 5.16165 14.0713 5.11246C14.1919 5.00204 14.3723 4.97254 14.5046 5.02682C14.6328 5.08549 14.7433 5.22005 14.7763 5.39864L14.7815 5.42655C14.7816 5.43515 14.7816 5.44508 14.7816 5.45775C14.7814 5.45911 14.7811 5.46092 14.7807 5.46326C14.7793 5.47215 14.777 5.48474 14.7727 5.50681L14.7653 5.54369L14.756 5.59047L14.7441 5.65209L14.5178 6.39271L14.5178 6.39271L14.5166 6.39648L14.0335 8.00037L14.0311 8.00817L14.0289 8.01601L13.8811 8.53719L13.7031 9.07453ZM14.7377 5.78133C14.7377 5.78485 14.7372 5.77845 14.7377 5.76425V5.78133ZM8.73672 12.9044C8.73706 12.904 8.73711 12.9039 8.73686 12.9042L8.73672 12.9044Z"
      stroke="#333333"
      strokeWidth="2"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Individual = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M13 8.5C13 9.39239 12.3372 10 11.6667 10C10.9961 10 10.3333 9.39239 10.3333 8.5C10.3333 7.60761 10.9961 7 11.6667 7C12.3372 7 13 7.60761 13 8.5ZM8 14.75C8 14.6749 8.02746 14.5519 8.21324 14.368C8.40796 14.1753 8.72209 13.9753 9.14268 13.7949C9.98839 13.4319 11.0208 13.25 11.6667 13.25C12.3126 13.25 13.3449 13.4319 14.1907 13.7949C14.6112 13.9753 14.9254 14.1753 15.1201 14.368C15.3059 14.5519 15.3333 14.6749 15.3333 14.75V15H8V14.75Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const MeshLogo = (props) => (
  <Icon viewBox="0 0 36 35.94" {...props}>
    <defs>
      <style>
        {`.cls-1{fill:#ec3b64;}.cls-1,.cls-2,.cls-3,.cls-4{fill-rule:evenodd;}.cls-2{fill:#5cc461;}.cls-3{fill:#008ef0;}.cls-4{fill:#ffc200;}`}
      </style>
    </defs>
    <g id="Layer_1-2">
      <g>
        <path
          className="cls-4"
          d="M19.5,29.93l-4.99-4.99c-.95,.27-2.01,.03-2.75-.72-1.1-1.1-1.1-2.88,0-3.98,1.1-1.1,2.88-1.1,3.98,0,.75,.75,.99,1.81,.72,2.75l4.99,4.99c1.56-.76,3.49-.49,4.78,.81,1.63,1.63,1.63,4.28,0,5.92-1.63,1.63-4.28,1.63-5.92,0-1.29-1.29-1.56-3.23-.81-4.78M7.18,15.66c-1.1,1.1-2.88,1.1-3.98,0-1.1-1.1-1.1-2.88,0-3.98,1.1-1.1,2.88-1.1,3.98,0,1.1,1.1,1.1,2.88,0,3.98Z"
        />
        <path
          className="cls-2"
          d="M6.01,19.51l4.99-4.99c-.27-.95-.03-2.01,.72-2.75,1.1-1.1,2.88-1.1,3.98,0,1.1,1.1,1.1,2.88,0,3.98-.75,.75-1.81,.98-2.75,.72l-4.99,4.99c.76,1.56,.49,3.49-.81,4.78-1.63,1.63-4.28,1.63-5.92,0s-1.63-4.28,0-5.92c1.29-1.29,3.23-1.56,4.78-.81M20.28,7.18c-1.1-1.1-1.1-2.88,0-3.98,1.1-1.1,2.88-1.1,3.98,0,1.1,1.1,1.1,2.88,0,3.98s-2.88,1.1-3.98,0Z"
        />
        <path
          className="cls-3"
          d="M16.5,6.01l4.99,4.99c.95-.27,2.01-.03,2.75,.72,1.1,1.1,1.1,2.88,0,3.98-1.1,1.1-2.88,1.1-3.98,0-.75-.75-.99-1.81-.72-2.75l-4.99-4.99c-1.56,.76-3.49,.49-4.78-.81-1.63-1.63-1.63-4.28,0-5.92,1.63-1.63,4.28-1.63,5.92,0,1.29,1.29,1.56,3.23,.81,4.78m12.33,14.27c1.1-1.1,2.88-1.1,3.98,0,1.1,1.1,1.1,2.88,0,3.98-1.1,1.1-2.88,1.1-3.98,0-1.1-1.1-1.1-2.88,0-3.98Z"
        />
        <path
          className="cls-1"
          d="M15.72,28.79c1.1,1.1,1.1,2.88,0,3.98-1.1,1.1-2.88,1.1-3.98,0-1.1-1.1-1.1-2.88,0-3.98,1.1-1.1,2.88-1.1,3.98,0m14.27-12.33l-4.99,4.99c.27,.95,.03,2.01-.72,2.75-1.1,1.1-2.88,1.1-3.98,0-1.1-1.1-1.1-2.88,0-3.98,.75-.75,1.81-.99,2.75-.72l4.99-4.99c-.76-1.56-.49-3.49,.81-4.78,1.63-1.63,4.28-1.63,5.92,0s1.63,4.28,0,5.92c-1.29,1.29-3.23,1.56-4.78,.81Z"
        />
      </g>
    </g>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CascadedViewV2 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 4H7C5.34315 4 4 5.34315 4 7C4 8.65685 5.34314 10 7 10H17C18.6569 10 20 8.65685 20 7C20 5.34315 18.6569 4 17 4ZM6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H7C6.44772 8 6 7.55228 6 7Z"
      fill="currentColor"
    />
    <path d="M4 20L7.85903 16.0876L4 12.1753V20Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 13.1973H17C18.6569 13.1973 20 14.5698 20 16.263C20 17.9562 18.6569 19.3288 17 19.3288H13C11.3431 19.3288 10 17.9562 10 16.263C10 14.5698 11.3431 13.1973 13 13.1973ZM13 15.2411C12.4477 15.2411 12 15.6986 12 16.263C12 16.8274 12.4477 17.2849 13 17.2849H17C17.5523 17.2849 18 16.8274 18 16.263C18 15.6986 17.5523 15.2411 17 15.2411H13Z"
      fill="currentColor"
    />
  </Icon>
);

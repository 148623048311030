import { OPEN_GENERIC_DRAWER, CLOSE_ALL_GENERIC_DRAWER, CLOSE_GENERIC_DRAWER } from 'actions/actionTypes';

// the number of objects here will increase as per requirement to
// support n number of multiple level drawers simultaneously
export const initialState = {
  1: {
    contentComponent: null,
    isOpen: false,
  },
  2: {
    contentComponent: null,
    isOpen: false,
  },
  3: {
    contentComponent: null,
    isOpen: false,
  },
  4: {
    contentComponent: null,
    isOpen: false,
  },
  5: {
    contentComponent: null,
    isOpen: false,
  },
};

const genericDrawer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_GENERIC_DRAWER:
      return {
        ...state,
        [action.payload.level]: {
          isOpen: true,
          contentComponent: action.payload.contentComponent,
        },
      };

    case CLOSE_GENERIC_DRAWER:
      return {
        ...state,
        [action.payload.level]: initialState[action.payload.level],
      };

    case CLOSE_ALL_GENERIC_DRAWER:
      return initialState;

    default:
      return state;
  }
};

export default genericDrawer;

import {
  HIDE_QUESTION_DETAILS, // UPDATE_QUESTION_DETAILS,
  SHOW_QUESTION_DETAILS,
} from 'actions/actionTypes';

export const initialState = {
  display: false,
  action: 'edit',
  questionId: 0,
  source: 'review',
  updatedQuestion: null,
};

const questionDetails = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_QUESTION_DETAILS:
      return {
        ...state,
        display: true,
        questionId: action.data.questionId,
        updatedQuestion: null,
        action: action.data.action,
        source: action.data.source,
      };
    // case UPDATE_QUESTION_DETAILS:
    //   return { ...state };
    case HIDE_QUESTION_DETAILS:
      return {
        ...initialState,
        display: false,
        updatedQuestion: action.data.updatedQuestion,
      };

    default:
      return state;
  }
};

export default questionDetails;

import MacrosMessage from './macrosMessage';

// a model object for the json response on the header of the post
export default class PostReason {
  constructor({ message, reactionType }) {
    this.message = message;
    this.type = reactionType;
  }

  static fromJSON(json) {
    if (!json) return null;
    const reason = new PostReason({
      message: MacrosMessage.fromJSON(json.message),
      reactionType: json.reactionType,
    });
    return reason;
  }
}

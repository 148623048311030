import { Icon } from '@chakra-ui/react';

export const ArrowRightCircle = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8 15.5C3.86014 15.5 0.5 12.1399 0.5 8C0.5 3.86014 3.86014 0.5 8 0.5C12.1399 0.5 15.5 3.86014 15.5 8C15.5 12.1399 12.1399 15.5 8 15.5Z"
      stroke="currentColor"
    />
    <path
      d="M10 7.99228C10 8.162 9.92983 8.32475 9.80508 8.44475L7.14389 11.0047C7.01992 11.1283 6.84952 11.1987 6.67108 11.2002C6.49265 11.2017 6.32103 11.1341 6.19483 11.0128C6.06863 10.8914 5.99847 10.7263 6.00003 10.5546C6.00158 10.383 6.07476 10.2191 6.20307 10.0998L8.86427 7.53982C8.98901 7.41982 9.15832 7.35242 9.33473 7.35242C9.51114 7.35242 9.68034 7.41982 9.80509 7.53982C9.92983 7.65982 10 7.82258 10 7.99228Z"
      fill="currentColor"
    />
    <path
      d="M10 7.99237C10 8.16209 9.92988 8.32484 9.80513 8.44484C9.68039 8.56484 9.51119 8.63223 9.33478 8.63223C9.15837 8.63223 8.98917 8.56484 8.86443 8.44484L6.20323 5.88485C6.04006 5.72231 5.97791 5.48894 6.04006 5.27102C6.10221 5.05298 6.27919 4.88274 6.50574 4.82294C6.73239 4.76326 6.97486 4.82283 7.14383 4.97991L9.80503 7.5399C9.92977 7.6599 10 7.82267 10 7.99237Z"
      fill="currentColor"
    />
  </Icon>
);

import DeliverFeedItemData from 'models/deliverFeedItemData';
import PostFeedItemData from 'models/postFeedItemData';
import { normalize } from 'normalizr';
import { posts, comments as commentsSchema, post as postSchema } from 'schema';

import { convertDateToString2 } from 'constants/helper';

export const FEED_TYPES = {
  deliver: 'DELIVER',
  post: 'POST',
  monk: 'MONK',
  experiences: 'EXPERIENCES',
  connect: 'CONNECT',
  htmlPost: 'HTML_POST',
  activityCard: 'ACTIVITY_CARD',
  activityCardHtmlPost: 'ACTIVITY_CARD_HTML_POST',
  task: 'TASK',
  continuousFeedback: 'CONTINUOUS_FEEDBACK',
  comment: 'COMMENT',
};

export const JSON_FEED_TYPES = {
  SINGLE_EVENT_UPDATE: 'SINGLE_EVENT_UPDATE',
  LIST_EVENT_UPDATE: 'LIST_EVENT_UPDATE',
  LIST_EVENT_CHECKBOX_UPDATE: 'LIST_EVENT_CHECKBOX_UPDATE',
};

export const parseMeshFeedRes = (res) =>
  res
    .map((feedItem) => {
      switch (feedItem.type) {
        case FEED_TYPES.deliver: {
          const item = new DeliverFeedItemData(null, feedItem);
          return item;
        }
        case FEED_TYPES.post:
        case FEED_TYPES.continuousFeedback:
        case FEED_TYPES.activityCard: {
          const item = new PostFeedItemData(null, feedItem);
          return item;
        }
        // Temporary hack till backend resolves not escaping "(double quote or any special character) symbol
        // in JSON fetched for activityCardHtmlPost and htmlPost.
        // the null items are filtered in next step.
        case FEED_TYPES.activityCardHtmlPost:
        case FEED_TYPES.htmlPost: {
          if (!feedItem.post.from) {
            const postFromEmployee = feedItem.post.postEmployees.filter((emp) => emp.postEmployeeType === 'FROM');
            feedItem.post.from = postFromEmployee?.[0].employee;
          }
          let item = null;
          try {
            item = new PostFeedItemData(null, feedItem, true);
          } catch (err) {
            console.error('Parsing feed item failed');
            console.error(err.message);
          }
          return item;
        }
        case FEED_TYPES.task: {
          const item = new PostFeedItemData(null, feedItem);
          return item;
        }
        case FEED_TYPES.monk: {
          return feedItem;
        }
        case FEED_TYPES.experiences: {
          const item = new DeliverFeedItemData(null, feedItem);
          item.type = FEED_TYPES.experiences;
          return item;
        }
        default:
          throw Error("feed item doesn't exist");
      }
    })
    .filter((item) => item !== null);

// export const parseTextComponent = (text, res) => {
//   const pattern = /{{(.*?)}}/gm;
//   return text.replace(pattern, function(fullMatch, groupMatch) {
//     const keys = groupMatch.split(".");
//     let d = { ...res };
//     for (let i = 0; i < keys.length; i++) {
//       d = d[keys[i]];
//     }
//     return d;

//     //
//   });
// };

export function normalizePost(post) {
  return normalize(post, postSchema);
}

export function normalizeFeed(feed) {
  return normalize(feed, posts);
}

export function normalizeComments(comments) {
  return normalize(comments, commentsSchema);
}

export function replaceCorrectTimeInTemplate(templateHtmlString) {
  const replaceRegex = /(?:{{customDateDisplay\.([0-9]*)}})/gm;
  return templateHtmlString.replace(replaceRegex, (match, firstGroupMatch) => {
    const date = new Date(parseInt(firstGroupMatch));
    return convertDateToString2(date);
  });
}

import { Icon } from '@chakra-ui/react';

export const ThumbsDownGenAi = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M20 10.4883C20 9.83252 19.6384 9.26439 19.1145 8.99319C19.2538 8.7453 19.3341 8.45634 19.3341 8.14782C19.3341 7.43721 18.9094 6.82944 18.3136 6.5912C18.4325 6.35746 18.5001 6.09052 18.5001 5.80735C18.5001 4.88952 17.792 4.14287 16.9215 4.14287H9.76501L7.89954 4.88476V4H4V15.0795H7.89954V14.136H8.43567L11.3804 17.7279L11.6334 19.1979C11.7135 19.6627 12.0967 20 12.5448 20H13.0864C14.1293 20 14.9778 19.1053 14.9778 18.0058C14.9778 17.3146 14.8517 16.6401 14.6029 16.0007L14.0409 14.4933H17.9478C18.8182 14.4933 19.5265 13.7466 19.5265 12.8288C19.5265 12.485 19.427 12.1651 19.257 11.8996C19.7028 11.6051 20 11.0827 20 10.4883ZM4.9375 4.9885H6.96204V14.091H4.9375V4.9885ZM17.9478 13.5048H12.665L13.7317 16.3657L13.7344 16.3729C13.9374 16.8934 14.0403 17.4429 14.0403 18.0058C14.0403 18.5604 13.6124 19.0115 13.0864 19.0115H12.5542L12.2571 17.2858L8.8645 13.1475H7.89954V5.94121L9.93591 5.13137H16.9215C17.275 5.13137 17.5626 5.43461 17.5626 5.80735C17.5626 6.1801 17.275 6.48334 16.9215 6.48334H14.5729V7.47184H17.7555C18.109 7.47184 18.3966 7.77508 18.3966 8.14782C18.3966 8.52057 18.109 8.82381 17.7555 8.82381H15.4069V9.81231H18.4214C18.7749 9.81231 19.0625 10.1156 19.0625 10.4883C19.0625 10.8612 18.7749 11.1643 18.4214 11.1643H15.4819V12.1528H17.9478C18.3014 12.1528 18.589 12.456 18.589 12.8288C18.589 13.2016 18.3014 13.5048 17.9478 13.5048Z"
      fill="currentColor"
    />
  </Icon>
);

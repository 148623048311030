import { Icon } from '@chakra-ui/react';

export const CloseBox = (props) => (
  <Icon viewBox="0 0 32 32" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M22.2997 9.71C21.9097 9.32 21.2797 9.32 20.8897 9.71L15.9997 14.59L11.1097 9.7C10.7197 9.31 10.0897 9.31 9.69973 9.7C9.30973 10.09 9.30973 10.72 9.69973 11.11L14.5897 16L9.69973 20.89C9.30973 21.28 9.30973 21.91 9.69973 22.3C10.0897 22.69 10.7197 22.69 11.1097 22.3L15.9997 17.41L20.8897 22.3C21.2797 22.69 21.9097 22.69 22.2997 22.3C22.6897 21.91 22.6897 21.28 22.2997 20.89L17.4097 16L22.2997 11.11C22.6797 10.73 22.6797 10.09 22.2997 9.71Z"
      fill="currentColor"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="currentColor" />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const RealignWithoutPadding = (props) => (
  <Icon viewBox="0 0 12 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.13177 2.66078L3.59162 0.129738C3.40953 -0.0432466 3.12729 -0.0432466 2.9452 0.129738L0.405051 2.66078C0.113708 2.94302 0.323111 3.43466 0.723709 3.43466L2.36251 3.43466L2.36251 8.90645C2.36251 9.4072 2.77221 9.8169 3.27296 9.8169C3.77371 9.8169 4.18341 9.4072 4.18341 8.90645L4.18341 3.43466L5.81311 3.43466C6.22281 3.43466 6.42311 2.94302 6.13177 2.66078ZM9.0543 15.8714L11.5945 13.3403C11.8858 13.0581 11.6764 12.5665 11.2758 12.5665L9.64609 12.5665L9.64609 7.08556C9.64609 6.58481 9.23639 6.17511 8.73565 6.17511C8.2349 6.17511 7.8252 6.58481 7.8252 7.08556L7.8252 12.5573L6.1955 12.5573C5.7858 12.5573 5.5855 13.049 5.87684 13.3312L8.41699 15.8623C8.58997 16.0444 8.88132 16.0444 9.0543 15.8714Z"
      fill="currentColor"
    />
  </Icon>
);

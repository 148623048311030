import { Icon } from '@chakra-ui/react';

export const MeshGuide = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <g id="Group 1231">
      <g id="Group 1230">
        <g id="Group 1227">
          <path
            id="Rectangle 1021"
            d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H2.5V15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z"
            fill="white"
            stroke="currentColor"
          />
          <path
            id="Rectangle 1022"
            d="M2.5 0.5H4H14C14.8284 0.5 15.5 1.17157 15.5 2V14C15.5 14.8284 14.8284 15.5 14 15.5H4H2.5V0.5Z"
            fill="white"
            stroke="currentColor"
          />
        </g>
        <g id="Group 940">
          <g id="Blue">
            <path
              d="M13.2357 9.1691C13.2357 9.59499 12.8913 9.94024 12.4664 9.94024C12.0416 9.94024 11.6972 9.59499 11.6972 9.1691C11.6972 8.74321 12.0416 8.39796 12.4664 8.39796C12.8913 8.39796 13.2357 8.74321 13.2357 9.1691Z"
              fill="currentColor"
            />
            <path
              d="M7.96385 5.20068C7.81322 5.27294 7.64451 5.31341 7.46637 5.31341C6.8291 5.31341 6.3125 4.79554 6.3125 4.15671C6.3125 3.51788 6.8291 3 7.46637 3C8.10363 3 8.62023 3.51788 8.62023 4.15671C8.62023 4.33528 8.57987 4.5044 8.50779 4.6554L9.95948 6.11067C10.023 6.09363 10.0898 6.08455 10.1587 6.08455C10.5836 6.08455 10.928 6.4298 10.928 6.85569C10.928 7.28157 10.5836 7.62682 10.1587 7.62682C9.73388 7.62682 9.38948 7.28157 9.38948 6.85569C9.38948 6.7866 9.39854 6.71964 9.41554 6.65593L7.96385 5.20068Z"
              fill="currentColor"
            />
          </g>
          <g id="Yellow">
            <path
              d="M6.30411 6.85463C6.30411 7.28052 5.95971 7.62577 5.53487 7.62577C5.11003 7.62577 4.76562 7.28052 4.76562 6.85463C4.76562 6.42875 5.11003 6.0835 5.53487 6.0835C5.95971 6.0835 6.30411 6.42875 6.30411 6.85463Z"
              fill="currentColor"
            />
            <path
              d="M8.16206 9.88897C8.09851 9.90601 8.03172 9.91509 7.96282 9.91509C7.53797 9.91509 7.19357 9.56984 7.19357 9.14395C7.19357 8.71806 7.53797 8.37281 7.96282 8.37281C8.38766 8.37281 8.73206 8.71806 8.73206 9.14395C8.73206 9.21303 8.723 9.27999 8.706 9.3437L10.1577 10.799C10.3083 10.7267 10.477 10.6862 10.6552 10.6862C11.2924 10.6862 11.809 11.2041 11.809 11.8429C11.809 12.4818 11.2924 12.9996 10.6552 12.9996C10.0179 12.9996 9.50129 12.4818 9.50129 11.8429C9.50129 11.6644 9.54166 11.4952 9.61375 11.3442L8.16206 9.88897Z"
              fill="currentColor"
            />
          </g>
          <g id="Red">
            <path
              d="M8.61661 11.4811C8.61661 11.907 8.27221 12.2522 7.84737 12.2522C7.42253 12.2522 7.07812 11.907 7.07812 11.4811C7.07812 11.0552 7.42253 10.71 7.84737 10.71C8.27221 10.71 8.61661 11.0552 8.61661 11.4811Z"
              fill="currentColor"
            />
            <path
              d="M14.0013 6.46872C14.0013 7.10755 13.4847 7.62543 12.8474 7.62543C12.6708 7.62543 12.5033 7.58561 12.3536 7.51445L10.8994 8.97226C10.9157 9.03467 10.9243 9.10018 10.9243 9.1677C10.9243 9.59359 10.5799 9.93884 10.1551 9.93884C9.73025 9.93884 9.38585 9.59359 9.38585 9.1677C9.38585 8.74182 9.73025 8.39657 10.1551 8.39657C10.2255 8.39657 10.2938 8.40606 10.3586 8.42384L11.8078 6.97109C11.7346 6.81916 11.6936 6.64874 11.6936 6.46872C11.6936 5.82989 12.2102 5.31201 12.8474 5.31201C13.4847 5.31201 14.0013 5.82989 14.0013 6.46872Z"
              fill="currentColor"
            />
          </g>
          <g id="Green">
            <path
              d="M10.9232 4.54165C10.9232 4.96753 10.5788 5.31278 10.1539 5.31278C9.7291 5.31278 9.3847 4.96753 9.3847 4.54165C9.3847 4.11576 9.7291 3.77051 10.1539 3.77051C10.5788 3.77051 10.9232 4.11576 10.9232 4.54165Z"
              fill="currentColor"
            />
            <path
              d="M8.61547 6.85506C8.61547 7.28094 8.27107 7.62619 7.84622 7.62619C7.77887 7.62619 7.71353 7.61752 7.65127 7.60121L6.19704 9.05903C6.26802 9.2091 6.30773 9.37692 6.30773 9.55404C6.30773 10.1929 5.79113 10.7107 5.15387 10.7107C4.5166 10.7107 4 10.1929 4 9.55404C4 8.91521 4.5166 8.39733 5.15387 8.39733C5.33345 8.39733 5.50346 8.43846 5.65502 8.51183L7.10419 7.05909C7.08645 6.9941 7.07698 6.92569 7.07698 6.85506C7.07698 6.42917 7.42138 6.08392 7.84622 6.08392C8.27107 6.08392 8.61547 6.42917 8.61547 6.85506Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const MyCompanyDefaultOneOnOneLatest = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M18.5 4H12.5C11.67 4 11 4.67 11 5.5V6H15.5C16.88 6 18 7.12 18 8.5V11H18.5C19.33 11 20 10.33 20 9.5V5.5C20 4.67 19.33 4 18.5 4Z"
      fill="currentColor"
    />
    <path
      d="M13.27 15.85C12.79 16.33 12.15 16.58 11.5 16.58C11.18 16.58 10.85 16.52 10.54 16.39C9.9 16.12 9.42 15.61 9.17 15H9V14.94C7.86 14.71 7 13.71 7 12.5V12H5.3C4.55 12 4 12.55 4 13.3V20H15V15H14.12L13.27 15.85ZM7 18H6V17H7V18ZM7 15H6V14H7V15ZM10 18H9V17H10V18ZM13 18H12V17H13V18Z"
      fill="currentColor"
    />
    <path
      d="M11 13.5V13H9.5C9.22 13 9 12.78 9 12.5V8.5C9 8.22 9.22 8 9.5 8H15.5C15.78 8 16 8.22 16 8.5V12.5C16 12.78 15.78 13 15.5 13H13.91C13.51 13 13.13 13.16 12.85 13.44L11.85 14.44C11.54 14.75 11 14.53 11 14.09V13.5Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Download = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12.0147 16.9999C11.6965 16.9999 11.3913 16.8764 11.1663 16.6567L6.36647 11.971C6.13484 11.7527 6.00286 11.4527 6.00005 11.1385C5.99723 10.8243 6.124 10.5221 6.3514 10.2999C6.57901 10.0777 6.88856 9.95415 7.2104 9.95689C7.53222 9.95964 7.83956 10.0885 8.06317 10.3144L12.863 15.0002C13.088 15.2198 13.2144 15.5179 13.2144 15.8285C13.2144 16.1392 13.088 16.4371 12.863 16.6567C12.638 16.8764 12.3329 16.9999 12.0147 16.9999Z"
      fill="currentColor"
    />
    <path
      d="M12.0149 16.9999C11.6967 16.9999 11.3915 16.8763 11.1665 16.6567C10.9415 16.437 10.8152 16.1391 10.8152 15.8285C10.8152 15.5179 10.9415 15.2199 11.1665 15.0003L15.9664 10.3145C16.2711 10.0272 16.7087 9.91781 17.1173 10.0272C17.5261 10.1367 17.8453 10.4483 17.9574 10.8472C18.0693 11.2463 17.9576 11.6732 17.6631 11.9707L12.8633 16.6565C12.6383 16.8761 12.3331 16.9999 12.0149 16.9999Z"
      fill="currentColor"
    />
    <rect x="20" y="18" width="2" height="16" rx="1" transform="rotate(90 20 18)" fill="currentColor" />
    <path
      d="M11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V5Z"
      fill="currentColor"
    />
  </Icon>
);

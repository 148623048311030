import { OPEN_COMPETENCY_SIDE_PANEL, CLOSE_COMPETENCY_SIDE_PANEL } from 'actions/actionTypes';

export const initialState = {
  isOpen: false,
  tagTitle: '',
  tagDescription: '',
  tagId: null,
  employeeId: '',
  onClose: () => {},
  hideShareFeedbackButtons: false,
};

const competencySidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_COMPETENCY_SIDE_PANEL:
      return {
        isOpen: true,
        tagTitle: action.tagTitle,
        tagDescription: action.tagDescription,
        tagId: action.tagId,
        employeeId: action.employeeId,
        onClose: action.onClose,
        hideShareFeedbackButtons: action.hideShareFeedbackButtons,
      };

    case CLOSE_COMPETENCY_SIDE_PANEL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default competencySidePanelReducer;

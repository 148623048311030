import { CLOSE_COHORT_BASE_RESET_MODAL, OPEN_COHORT_BASE_RESET_MODAL } from 'actions/actionTypes';

export const COHORT_RESET_MODAL_TYPES = {
  noAdminAccessModal: 'noAdminAccessModal',
  setToAllEmployeesModal: 'setToAllEmployeesModal',
};
export const initialState = {
  display: false,
  onConfirm: () => {},
  onCancel: () => {},
  confirmationType: COHORT_RESET_MODAL_TYPES.noAdminAccessModal,
  selectedModule: '',
};

const cohortBaseResetModal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_COHORT_BASE_RESET_MODAL:
      return {
        ...initialState,
        display: true,
        onConfirm: action.payload.onConfirm,
        onCancel: action.payload.onCancel,
        confirmationType: action.payload.confirmationType,
        selectedModule: action.payload.selectedModule,
      };

    case CLOSE_COHORT_BASE_RESET_MODAL:
      return {
        ...initialState,
        display: false,
      };

    default:
      return state;
  }
};

export default cohortBaseResetModal;

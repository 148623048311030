import { createSlice } from '@reduxjs/toolkit';

const initialState = { localStateValues: {}, localCheckInStateValues: {} };

export const kRSlice = createSlice({
  name: 'kRSlice',
  initialState,
  reducers: {
    updateKR: (state, action) => ({ ...state, ...action.payload }),
    updateLocalCheckInStateValues: (state, action) => ({
      ...state,
      localCheckInStateValues: { ...state.localCheckInStateValues, ...action.payload },
    }),
    resetLocalCheckInStateValues: (state) => ({ ...state, localCheckInStateValues: {} }),
    updateLocalStateValues: (state, action) => ({
      ...state,
      localStateValues: { ...state.localStateValues, ...action.payload },
    }),
    resetLocalStateValues: (state) => ({ ...state, localStateValues: {} }),
    resetKR: () => initialState,
    setCurrentSectionInView: (state, action) => ({ ...state, currentSectionInView: action.payload }),
  },
});

// Action creators are generated for each case reducer function
export const {
  updateKR,
  resetKR,
  setCurrentSectionInView,
  updateLocalStateValues,
  resetLocalStateValues,
  updateLocalCheckInStateValues,
  resetLocalCheckInStateValues,
} = kRSlice.actions;

export default kRSlice.reducer;

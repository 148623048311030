import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  isModalOpen: false,
  modalType: null,
};

export const PERSONA_INSIGHTS_MODAL_TYPES = {
  CHECK_IN_USER_TREND: 'CHECK_IN_USER_TREND',
  CHECK_IN_USER_BEHAVIOR: 'CHECK_IN_USER_BEHAVIOR',
  CHECK_IN_QUALITY: 'CHECK_IN_QUALITY',
  NETWORK_ENGAGEMENT_TREND: 'NETWORK_ENGAGEMENT_TREND',
};

export const personaInsightsSlice = createSlice({
  name: 'personaInsight',
  initialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.isModalOpen = true;
      state.modalType = action.payload;
    },
    setModalClose: (state) => {
      state.isModalOpen = false;
      state.modalType = null;
    },
  },
});

export const { setModalOpen, setModalClose } = personaInsightsSlice.actions;

export default personaInsightsSlice.reducer;

// selectors
export const useIsPersonaInsightsModalOpen = () => useSelector((state) => state.personaInsightSlice.isModalOpen);
export const usePersonaInsightsModalType = () => useSelector((state) => state.personaInsightSlice.modalType);

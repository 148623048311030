import { Icon } from '@chakra-ui/react';

export const RabbitCompetency = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_2844_144387)">
      <path
        d="M8.19056 3.11942C7.46341 3.06805 6.77114 3.31112 6.24066 3.80517C5.71791 4.292 5.41821 4.98037 5.41821 5.69364C5.41821 6.27479 5.60932 6.82557 5.97094 7.28584C6.23481 7.62169 6.60431 8.02277 6.82746 8.39535L6.82993 8.83164C6.82993 8.97539 6.94683 9.09249 7.09078 9.09249L9.01686 9.06454C9.1606 9.06454 9.2775 8.94754 9.2775 8.80369L9.27494 8.38927C9.51029 7.99962 9.78999 7.59024 10.0288 7.28654C10.4094 6.80509 10.6051 6.20059 10.5796 5.5843C10.5266 4.29397 9.47706 3.21142 8.19056 3.11942Z"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <rect x="6.62324" y="10.6414" width="2.85838" height="0.40834" stroke="#333333" strokeWidth="0.40834" />
      <mask id="path-4-inside-1_2844_144387" fill="white">
        <path d="M7.23584 12.0707H8.8692V12.8874H7.23584V12.0707Z" />
      </mask>
      <path
        d="M7.23584 12.0707V11.0707H6.23584V12.0707H7.23584ZM8.8692 12.0707H9.8692V11.0707H8.8692V12.0707ZM8.8692 12.8874V13.8874H9.8692V12.8874H8.8692ZM7.23584 12.8874H6.23584V13.8874H7.23584V12.8874ZM7.23584 13.0707H8.8692V11.0707H7.23584V13.0707ZM7.8692 12.0707V12.8874H9.8692V12.0707H7.8692ZM8.8692 11.8874H7.23584V13.8874H8.8692V11.8874ZM8.23584 12.8874V12.0707H6.23584V12.8874H8.23584Z"
        fill="#333333"
        mask="url(#path-4-inside-1_2844_144387)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2844_144387">
        <rect width="16" height="16" rx="2" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

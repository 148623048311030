import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  isTalentPlanningSidepanelOpen: false,
  isCalibrationHistorySecondaryPanelOpen: false,

  selectedCalibrationView: null,
  // this is required to persist selection of calibration view,
  // in new nav we'll need this to figure out what was the last page when both(review cycle and calibration view) are persisted
  // this will help in deciding which page to show review manage or talent planning
  isCalibrationViewPreviouslySelected: false,
};

export const talentPlanningSidepanel = createSlice({
  name: 'talentPlanningSidepanel',
  initialState,
  reducers: {
    setIsTalentPlanningSidepanelOpen: (state, action) => {
      state.isTalentPlanningSidepanelOpen = action.payload;
    },
    setIsCalibrationHistorySecondaryPanelOpen: (state, action) => {
      state.isCalibrationHistorySecondaryPanelOpen = action.payload;
    },

    setSelectedCalibrationView: (state, action) => {
      state.selectedCalibrationView = action.payload;
    },

    resetTalentPlanningSidepanelState: (state) => ({
      ...initialState,
      selectedCalibrationView: state.selectedCalibrationView,
    }),
    setIsCalibrationViewPreviouslySelected: (state, action) => {
      state.isCalibrationViewPreviouslySelected = action.payload;
    },
  },
});

export const {
  setIsTalentPlanningSidepanelOpen,
  setIsCalibrationHistorySecondaryPanelOpen,
  setSelectedCalibrationView,
  resetTalentPlanningSidepanelState,
  setIsCalibrationViewPreviouslySelected,
} = talentPlanningSidepanel.actions;

export default talentPlanningSidepanel.reducer;

// selectors
export const useTalentPlanningSidepanelData = () => useSelector((state) => state.talentPlanningSidepanel);

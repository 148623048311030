export default class MacrosMessage {
  constructor({ text = '', macros = {} }) {
    this.text = text;
    this.macros = macros;
  }

  static fromJSON(json) {
    if (!json) return null;
    const message = new MacrosMessage({ text: json.text, macros: json.macros });
    return message;
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  isL1Visible: false,
  isL2Visible: false,
};

export const deerNavSlice = createSlice({
  name: 'deerNav',
  initialState,
  reducers: {
    setL1Visible: (
      state,
      action: PayloadAction<{
        isL1Visible: boolean;
      }>
    ) => {
      const newState = { ...state };
      newState.isL1Visible = action.payload.isL1Visible;
      return newState;
    },
    setL2Visible: (
      state,
      action: PayloadAction<{
        isL2Visible: boolean;
      }>
    ) => {
      const newState = { ...state };
      if (action.payload.isL2Visible) {
        newState.isL1Visible = true;
      }
      newState.isL2Visible = action.payload.isL2Visible;
      return newState;
    },
  },
});

export const { setL1Visible, setL2Visible } = deerNavSlice.actions;

export default deerNavSlice.reducer;

export const useSelectIsL1Visible = () =>
  useSelector((state: { deerNav: typeof initialState }) => state.deerNav.isL1Visible);

export const useSelectIsL2Visible = () =>
  useSelector((state: { deerNav: typeof initialState }) => state.deerNav.isL2Visible);

import { Icon } from '@chakra-ui/react';

export const ChevronDown = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12.0099 14.5C11.7977 14.5 11.5943 14.4123 11.4443 14.2564L8.24432 10.9299C8.0899 10.7749 8.00191 10.5619 8.00003 10.3389C7.99816 10.1158 8.08267 9.90128 8.23427 9.74353C8.38602 9.58579 8.59239 9.49808 8.80695 9.50003C9.02151 9.50198 9.22641 9.59345 9.37548 9.75384L12.5755 13.0803C12.7255 13.2363 12.8097 13.4479 12.8097 13.6684C12.8097 13.8889 12.7255 14.1004 12.5755 14.2564C12.4255 14.4123 12.222 14.5 12.0099 14.5Z"
      fill="currentColor"
    />
    <path
      d="M12.0099 14.5C11.7977 14.5 11.5943 14.4123 11.4443 14.2564C11.2943 14.1004 11.21 13.889 11.21 13.6685C11.21 13.448 11.2942 13.2365 11.4442 13.0805L14.6442 9.75404C14.8473 9.55007 15.1391 9.47239 15.4115 9.55007C15.684 9.62776 15.8968 9.84899 15.9716 10.1322C16.0462 10.4155 15.9717 10.7186 15.7754 10.9298L12.5755 14.2564C12.4255 14.4123 12.222 14.5 12.0099 14.5Z"
      fill="currentColor"
    />
  </Icon>
);

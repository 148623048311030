import { Icon } from '@chakra-ui/react';

export const Feedback = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M7 19.46H4.55C4.24 19.46 4 19.21 4 18.91V13.08C4 12.77 4.25 12.53 4.55 12.53H7V19.46V19.46ZM5.33 18.13H5.66V13.87H5.33V18.13Z"
      fill="currentColor"
    />
    <path
      d="M13.76 20.01C12.17 20.01 11.29 19.99 10.91 19.92C10.07 19.84 8.98 19.62 8.41 19.5L7.62 19.33L7.64 12.52L8.05 12.22C8.66 11.78 8.96 11.47 9.21 11.03L13.08 4.78997C13.13 4.68997 13.22 4.53997 13.37 4.39997C13.83 3.96997 14.46 3.87997 14.99 4.15997C15.53 4.43997 15.84 5.02997 15.76 5.63997C15.76 5.69997 15.74 5.81997 15.49 6.62997C15.38 6.96997 15.27 7.33997 15.16 7.72997C14.98 8.32997 14.79 8.97997 14.65 9.38997L18.79 9.40997C19.49 9.56997 20 10.19 19.99 10.9L19.97 11.09L18.43 18.72C18.27 19.46 17.61 20 16.9 20C16.64 20 16.09 20 15.43 20C14.8 20 14.24 20 13.75 20L13.76 20.01ZM9.62 17.7C10.11 17.79 10.69 17.89 11.2 17.94C11.7 18.02 14.22 18.01 15.43 18.01C15.87 18.01 16.26 18.01 16.55 18.01L17.88 11.4H11.88L12.5 9.51997L10.93 12.06C10.62 12.6 10.23 13.06 9.63 13.54V17.71L9.62 17.7Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const FeedbackIconSelected = (props) => (
  <Icon viewBox="0 0 25 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M7.99805 18.9155V12.7436C7.99805 12.5177 7.77768 12.5258 7.55807 12.5258H5.41217C5.19256 12.5258 4.99805 12.6872 4.99805 12.9131V19.0318C4.99805 19.2577 5.19256 19.4578 5.41217 19.4578H7.55807C7.77768 19.4352 7.99805 19.1414 7.99805 18.9155Z"
      fill="#104F95"
    />
    <path
      d="M14.7021 9.07453L14.2667 10.389H15.6514H19.576C19.6369 10.4019 19.7447 10.4415 19.8323 10.5202C19.8766 10.5601 19.9139 10.608 19.9416 10.6695C19.9634 10.7181 19.9859 10.7896 19.994 10.8934L18.459 18.5217C18.3956 18.8098 18.1325 19 17.9103 19H13.129C12.7866 19 12.44 18.9723 12.0923 18.9303C12.092 18.9302 12.0916 18.9302 12.0913 18.9301L11.0329 18.7995L10.9921 18.7913L10.7871 18.7503C10.6173 18.7163 10.3903 18.6711 10.1617 18.6257C9.96945 18.5876 9.77519 18.5493 9.61276 18.5176L9.63238 13.0304C9.64044 13.0197 9.6486 13.0091 9.65668 12.9988C10.1285 12.698 10.4702 12.3538 10.6998 12.0744C10.8276 11.919 10.9231 11.7808 10.9893 11.6763C11.0225 11.6239 11.0486 11.5796 11.0679 11.5454C11.0725 11.5374 11.0766 11.5299 11.0804 11.523L14.9294 5.31422L14.942 5.29387L14.9536 5.27294C14.9922 5.20342 15.0237 5.16165 15.0703 5.11246C15.1909 5.00204 15.3713 4.97254 15.5036 5.02682C15.6319 5.08549 15.7423 5.22005 15.7754 5.39864L15.7805 5.42655C15.7806 5.43515 15.7806 5.44508 15.7806 5.45775C15.7804 5.45911 15.7801 5.46092 15.7798 5.46326C15.7783 5.47215 15.776 5.48474 15.7717 5.50681L15.7643 5.54369L15.7551 5.59047L15.7431 5.65209L15.5168 6.39271L15.5168 6.39271L15.5157 6.39648L15.0325 8.00037L15.0302 8.00817L15.0279 8.01601L14.8802 8.53719L14.7021 9.07453ZM15.7367 5.78133C15.7367 5.78485 15.7362 5.77845 15.7367 5.76425V5.78133ZM9.73574 12.9044C9.73608 12.904 9.73613 12.9039 9.73588 12.9042L9.73574 12.9044Z"
      stroke="#104F95"
      strokeWidth="2"
    />
  </Icon>
);

import Employee from 'models/employee';
import HashTag from 'models/hashtag';
import { SearchType } from 'models/searchType';

import { SEARCH_TAG_TYPES, ORDERED_TAG_TYPES } from 'constants/ProjectConstants';

import { getSearchLabelFromEmployee, getProfileImageOfEmployee } from './employee';

/* Sample input:
  [
    {
      type: "EMPLOYEE_NAME",
      searches: ["Kar", "Singh"],
      selected: false
    }
  ]
*/

/* Sample output:
{
  "EMPLOYEE_NAME": [
    "sa"
],
"DESIGNATION": [
    "Manager",
    "Associate"
]
}
*/
export const convertSearchUiDataToApiBody = (data = []) => {
  const body = {};
  data.forEach((d) => {
    if (d.selected) body[d.type] = [];
    d.searches.forEach((a) => body[d.type].push(a.value));
  });
  return body;
};

// opposite of above
export const convertApiBodyToSearchUiData = (body = {}) => {
  const data = [];
  Object.keys(body).forEach((key) => {
    data.push(SearchType({ type: key }));
  });
  return data;
};

// Make tag response into a search type by adding action items to it
export const addActionItemsToTagResponse = (res = []) => {
  const result = [];
  res.forEach((data) => {
    result.push(
      SearchType({
        type: data.type,
        displayName: data.displayName,
        deletable: true,
      })
    );
  });
  return result;
};

export const addSearchTypeWithEmptyTag = (data = []) => {
  const searchTypeWithEmptyTag = new SearchType({
    deletable: false,
    selected: true,
  });
  return [searchTypeWithEmptyTag, ...data];
};

export const checkIfSearchDataIsCorrect = () => {};

export const convertSearchParamToDisplay = (searchParam = {}) =>
  `${searchParam.displayName}: ${searchParam.searches.join(', ')}`;

export const makeSearchAutoCompleteBody = ({ tagType, query, teamId, employeeIds, ...otherProps }) => {
  const body = { text: query, ...otherProps };
  if (tagType) body.tagType = tagType;
  if (teamId) {
    body.team = {
      uuid: teamId,
    };
  }
  if (employeeIds) {
    body.employeeIds = employeeIds;
  }
  return body;
};

/*
Sample response:
  [
    {
      "id": 123,
      "name": "influence and conviction",
      "tagType": "DEVELOP"
    },
    {
      "id": 124,
      "name": "insights and analysis",
      "tagType": "DEVELOP"
    }
  ]
*/
/*
  React select understands value and label, so converting it into that
  Also keeping tagType and id because we will need it
*/

export function makeEmployeeModelSearchCompatible(employee, data) {
  return {
    label: getSearchLabelFromEmployee(employee),
    value: employee.id,
    tagType: data ? data.tagType : SEARCH_TAG_TYPES.employee,
    id: employee.id,
    image: getProfileImageOfEmployee(employee),
    fullName: employee.fullName,
    displayName: employee.displayName,
    profilePhotoSrc: getProfileImageOfEmployee(employee),
    teams: employee.teams, // TODO: this is a heavy object, don't use it in search everywhere
    isUnassignedUser: employee.isUnassignedUser,
    ownerType: employee.ownerType,
    email: employee.email,
    metadata: data?.employee.metadata,
  };
}

export const parseSearchEmployeeAutocompleteRes = (data, employee) => {
  if (!employee) employee = new Employee(null, data.employee);
  return makeEmployeeModelSearchCompatible(employee, data);
};

const parseUniversalSearchEmployeeAutocompleteRes = (data) => {
  const employee = new Employee(null, data.employee);
  return {
    label: employee.getSearchLabel(),
    value: employee.id,
    tagType: data.tagType,
    id: employee.id,
    key: `${data.tagType}_${data.id}`,
    image: employee.getProfileImage(),
  };
};

function parseOrgTagResponse(data) {
  return {
    label: `#${data.orgTag.name}`,
    name: data.orgTag.name,
    tagType: data.tagType,
    value: data.orgTag.uuid,
    id: data.orgTag.uuid,
    key: `${data.tagType}_${data.orgTag.uuid}`,
    image: null,
  };
}

function parseSubObjectiveTagResponse(data) {
  return {
    label: `${data.orgTag.name}`,
    name: data.orgTag.name,
    tagType: data.tagType,
    value: data.orgTag.uuid,
    id: data.orgTag.uuid,
    key: `${data.tagType}_${data.orgTag.uuid}`,
    color: data.color,
    parentTag: data.orgTag.parentTag,
  };
}

export function getSearchCompatibleTagFromModelData(data) {
  if (!data) return null;
  return {
    label: data.name,
    name: data.name,
    tagType: data.tagType,
    value: data.id,
    id: data.id,
    uuid: data.id,
    color: data.color,
    key: `${data.tagType}_${data.id}`,
    parentTag: data.parentTag,
  };
}

export const parseSearchAutoCompleteRes = (res) => {
  const result = [];
  res.forEach((data) => {
    if (data.tagType && data.tagType.toLowerCase() === 'employee_name')
      result.push(parseSearchEmployeeAutocompleteRes(data));
    else if (data.tagType && data.tagType === SEARCH_TAG_TYPES.tag) {
      if (data.orgTag.tagType === SEARCH_TAG_TYPES.subObjectiveTag) {
        const hashtag = new HashTag();
        hashtag.fromJSON(data.orgTag);
        result.push(getSearchCompatibleTagFromModelData(hashtag));
      } else result.push(parseOrgTagResponse(data));
    }
  });
  return result;
};

export const parseUniversalSearchAutoCompleteRes = (res) => {
  const result = [];
  res.forEach((data) => {
    if (data.tagType && data.tagType === SEARCH_TAG_TYPES.employee)
      result.push(parseUniversalSearchEmployeeAutocompleteRes(data));
    else if (data.tagType && data.tagType === SEARCH_TAG_TYPES.tag) {
      result.push(parseOrgTagResponse(data));
    } else {
      result.push({
        label: data.group.displayName,
        name: data.group.displayName,
        tagType: data.group.filterTagType,
        id: data.group.uuid,
        key: `${data.group.filterTagType}_${data.group.uuid}`,
        image: null,
        isDemographicsTag: true,
      });
    }
  });
  return result;
};

export const parseUniversalSearchAutoCompleteResV1 = (res, existingData) => {
  const result = [];
  let index = 0;
  for (const tagType of ORDERED_TAG_TYPES) {
    const data = res[tagType];
    if (data && data.length > 0) {
      index++;
      result.push({
        label: tagType,
        options: data.map((d) => ({
          tagType,
          label:
            tagType === SEARCH_TAG_TYPES.tag ||
            tagType === SEARCH_TAG_TYPES.taskTag ||
            tagType === SEARCH_TAG_TYPES.goalTag
              ? `#${d.displayName}`
              : d.displayName,
          value: `${tagType}_${d.uuid}`,
          id: d.uuid,
          index,
          imageUrl: d.imageUrl,
          designation: d.designation ?? '',
          subType: d.subType,
          isDisabled: existingData && existingData.filter((r) => r.toString() === d.uuid.toString()).length > 0,
        })),
      });
    }
  }
  return result;
};

export const parseUniversalSearchAutoCompleteObjectives = (res) => {
  const result = [];
  for (const tagType of ORDERED_TAG_TYPES) {
    const data = res[tagType];
    if (data && data.length > 0) {
      result.push({
        label: tagType,
        options: data.map((d) => ({
          label: d.displayName,
          value: d.displayName,
          // value: `${tagType}_${d.uuid}`,

          objectiveType: d.subType,
          objectiveId: d.uuid,
          name: d.displayName,
          displayName: d.displayName,
        })),
      });
    }
  }
  return result?.[0]?.options ?? [];
};

import { Icon } from '@chakra-ui/react';

export const Person2 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10.125C12.7747 10.125 13.625 9.40627 13.625 8.25C13.625 7.09373 12.7747 6.375 12 6.375C11.2253 6.375 10.375 7.09373 10.375 8.25C10.375 9.40627 11.2253 10.125 12 10.125ZM6.89308 17.5628C6.87509 17.6 6.875 17.6152 6.875 17.625H17.125C17.125 17.6152 17.1249 17.6 17.1069 17.5628C17.0853 17.518 17.0331 17.4341 16.9163 17.3185C16.6696 17.0743 16.2445 16.7971 15.6381 16.5369C14.4168 16.0128 12.9186 15.75 12 15.75C11.0814 15.75 9.58323 16.0128 8.3619 16.5369C7.75548 16.7971 7.33039 17.0743 7.08366 17.3185C6.96692 17.4341 6.91471 17.518 6.89308 17.5628ZM5 19.5V17.625C5 15.1286 9.66 13.875 12 13.875C14.34 13.875 19 15.1286 19 17.625V19.5H5ZM15.5 8.25C15.5 10.3179 13.93 12 12 12C10.07 12 8.5 10.3179 8.5 8.25C8.5 6.18214 10.07 4.5 12 4.5C13.93 4.5 15.5 6.18214 15.5 8.25Z"
      fill="currentColor"
    />
  </Icon>
);

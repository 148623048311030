import { Icon } from '@chakra-ui/react';

export const Subordinate = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M5.5 15.9746C5.5 14.0416 7.067 12.4746 9 12.4746H13C14.933 12.4746 16.5 14.0416 16.5 15.9746V18.9099C16.5 19.2191 16.2494 19.4697 15.9402 19.4697H6.05981C5.75063 19.4697 5.5 19.2191 5.5 18.9099V15.9746Z"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M13.7859 7.39523C13.7859 9.01119 12.475 10.3217 10.8573 10.3217C9.23959 10.3217 7.92871 9.01119 7.92871 7.39523C7.92871 5.77927 9.23959 4.46875 10.8573 4.46875C12.475 4.46875 13.7859 5.77927 13.7859 7.39523Z"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M20 6.6C20 6.7 20 6.9 19.9 7C19.8 7.1 19.7 7.2 19.6 7.2C19.5 7.2 19.4 7.1 19.3 7L18 5.7V9.5C18 9.8 17.8 10 17.5 10C17.2 10 17 9.8 17 9.5V5.7L16 6.9C15.9 7 15.7 7.1 15.5 7C15.3 7 15.2 6.8 15.2 6.6C15.2 6.4 15.2 6.2 15.3 6.1L17.2 4.1C17.2 4.1 17.2 4.1 17.3 4H17.4C17.5 4 17.5 4 17.6 4C17.7 4 17.7 4 17.8 4L17.9 4.1H18L19.9 6.1C20 6.3 20 6.4 20 6.6Z"
      fill="currentColor"
    />
  </Icon>
);

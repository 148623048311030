import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const REVIEW_FORM_KEYS = {
  COMPETENCY: 'COMPETENCY',
  QNA: 'QNA',
  GOAL: 'GOAL',
  OVERALL: 'OVERALL',
  OUTCOME: 'OUTCOME',
};

const initialState = {
  currentFormKey: null,
  currentQuestions: {
    [REVIEW_FORM_KEYS.COMPETENCY]: {
      questionIndex: 0,
    },
    [REVIEW_FORM_KEYS.QNA]: {
      questionIndex: 0,
    },
    [REVIEW_FORM_KEYS.GOAL]: {
      questionIndex: 0,
    },
  },
  // to track validation to be shown after first submit attempt
  isValidateForm: false,
  selectedReviewPeriodSummarySection: null,
  isSelectedFAQSection: false,
  currentMLInputSelectedQuestionId: null,
  MLSummaryFeedback: [],
};

export const reviewFormSlice = createSlice({
  name: 'reviewForm',
  initialState,
  reducers: {
    setCurrentFormKey: (state, action) => {
      state.currentFormKey = action.payload.id;
      state.selectedReviewPeriodSummarySection = null;
      state.isSelectedFAQSection = false;
      state.currentMLInputSelectedQuestionId = null;
    },
    setCurrentCompetencyQuestionIndex: (state, action) => {
      state.currentQuestions.COMPETENCY = {
        questionIndex: action.payload,
      };
      state.currentMLInputSelectedQuestionId = null;
    },
    setCurrentGoalQuestionIndex: (state, action) => {
      state.currentQuestions.GOAL = {
        questionIndex: action.payload,
      };
      state.currentMLInputSelectedQuestionId = null;
    },
    setCurrentQNAQuestionIndex: (state, action) => {
      state.currentQuestions.QNA = {
        questionIndex: action.payload,
      };
      state.currentMLInputSelectedQuestionId = null;
    },

    setIsValidateForm: (state, action) => {
      state.isValidateForm = action.payload;
    },

    setSelectedReviewPeriodSummarySection: (state, action) => {
      state.selectedReviewPeriodSummarySection = action.payload.id;
      state.isSelectedFAQSection = false;
    },
    setCurrentFormKeyWithDataReset: (state, action) => {
      state.currentFormKey = action.payload.id;
      state.selectedReviewPeriodSummarySection = null;
      state.isSelectedFAQSection = false;
      state.currentQuestions = { ...initialState.currentQuestions };
      state.isValidateForm = false;
    },
    setIsSelectedFAQSection: (state, action) => {
      state.isSelectedFAQSection = action.payload;
    },
    setCurrentMLInputSelectedQuestionId: (state, action) => {
      state.currentMLInputSelectedQuestionId = action.payload;
    },
    setMLSummaryFeedback: (state, action) => {
      state.MLSummaryFeedback = [...state.MLSummaryFeedback, action.payload];
    },
    clearMLSummaryFeedback: (state) => {
      state.MLSummaryFeedback = [];
    },
  },
});

export const {
  setCurrentFormKey,
  setCurrentCompetencyQuestionIndex,
  setCurrentGoalQuestionIndex,
  setCurrentQNAQuestionIndex,
  setIsValidateForm,
  setSelectedReviewPeriodSummarySection,
  setCurrentFormKeyWithDataReset,
  setIsSelectedFAQSection,
  setCurrentMLInputSelectedQuestionId,
  setMLSummaryFeedback,
  clearMLSummaryFeedback,
} = reviewFormSlice.actions;

export default reviewFormSlice.reducer;

// selectors
export const useSelectedReviewForm = () => useSelector((state) => state.reviewFormSlice.currentFormKey);

export const useCurrentCompetencyQuestionIndex = () =>
  useSelector((state) => state.reviewFormSlice.currentQuestions.COMPETENCY.questionIndex);

export const useCurrentGoalQuestionIndex = () =>
  useSelector((state) => state.reviewFormSlice.currentQuestions.GOAL.questionIndex);

export const useCurrentQNAQuestionIndex = () =>
  useSelector((state) => state.reviewFormSlice.currentQuestions.QNA.questionIndex);

export const useIsValidateForm = () => useSelector((state) => state.reviewFormSlice.isValidateForm);

export const useSelectedReviewPeriodSummarySection = () =>
  useSelector((state) => state.reviewFormSlice.selectedReviewPeriodSummarySection);

export const useIsSelectedFAQSection = () => useSelector((state) => state.reviewFormSlice.isSelectedFAQSection);

export const useCurrentMLInputSelectedQuestionId = () =>
  useSelector((state) => state.reviewFormSlice.currentMLInputSelectedQuestionId);

export const useMLSummaryFeedback = () => useSelector((state) => state.reviewFormSlice.MLSummaryFeedback);

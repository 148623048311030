import { Icon } from '@chakra-ui/react';

export const Increase = (props) => (
  <Icon viewBox="0 0 14 8" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.0973 0.702531L11.0573 1.66253L7.80392 4.91586L5.61059 2.72253C5.35059 2.46253 4.93059 2.46253 4.67059 2.72253L0.670586 6.7292C0.410586 6.9892 0.410586 7.4092 0.670586 7.6692C0.930586 7.9292 1.35059 7.9292 1.61059 7.6692L5.13725 4.13586L7.33059 6.3292C7.59059 6.5892 8.01058 6.5892 8.27059 6.3292L11.9973 2.6092L12.9573 3.5692C13.1639 3.77586 13.5239 3.6292 13.5239 3.33586V0.469198C13.5306 0.282531 13.3839 0.135864 13.1973 0.135864H10.3373C10.0373 0.135864 9.89058 0.495864 10.0973 0.702531Z"
      fill="currentColor"
    />
  </Icon>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AGENDA_CONDITIONS_TYPE } from 'screens/oneOnOneRabbit/SettingsPage/constants';

const initialState = {
  eventIdsToBeDeleted: [] as AGENDA_CONDITIONS_TYPE[],
  saveButtonIsDisabled: true,
};

export const OneOnOneEventBasedAgendaSlice = createSlice({
  name: 'OneOnOneEventBasedAgendaSlice',
  initialState,
  reducers: {
    deleteOneOnOneEvent: (
      state,
      action: PayloadAction<{
        eventIdToBeDeleted: AGENDA_CONDITIONS_TYPE;
      }>
    ) => {
      state.eventIdsToBeDeleted.push(action.payload.eventIdToBeDeleted);
    },
    resetIdsToBeDeleted: (state) => {
      state.eventIdsToBeDeleted = [];
    },
    setSaveButtonIsDisabled: (
      state,
      action: PayloadAction<{
        saveButtonIsDisabled: boolean;
      }>
    ) => {
      state.saveButtonIsDisabled = action.payload.saveButtonIsDisabled;
    },
  },
});

export const { deleteOneOnOneEvent, resetIdsToBeDeleted, setSaveButtonIsDisabled } =
  OneOnOneEventBasedAgendaSlice.actions;

export default OneOnOneEventBasedAgendaSlice.reducer;

export const useEventIdsToBeDeleted = () => {
  const eventIdsToBeDeleted = useSelector((state: any) => state.OneOnOneEventBasedAgendaSlice.eventIdsToBeDeleted);
  return eventIdsToBeDeleted as AGENDA_CONDITIONS_TYPE[];
};

export const useSaveButtonIsDisabled = () => {
  const saveButtonIsDisabled = useSelector((state: any) => state.OneOnOneEventBasedAgendaSlice.saveButtonIsDisabled);
  return saveButtonIsDisabled as boolean;
};

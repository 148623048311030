import { Icon } from '@chakra-ui/react';

export const OneOnOne = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_836_48180)">
      <path
        d="M10.1906 15.7461H11.0992L9.66663 17.0242L9.64559 16.069L9.85967 15.8782C9.96747 15.7864 10.0831 15.7461 10.1906 15.7461Z"
        stroke="currentColor"
      />
      <path
        d="M20.0002 12.3323C20.0002 13.3751 19.1302 14.2236 18.0802 14.2236H17.9402V8.08946C17.9402 6.96486 17.0502 6.04473 15.9402 6.04473H6.99023V5.89137C6.99023 4.84856 7.85023 4 8.91023 4H18.0402C19.0902 4 19.9602 4.84856 19.9602 5.89137L20.0002 12.3323Z"
        fill="currentColor"
      />
      <path
        d="M16.97 8.95827C16.97 7.91546 16.11 7.06689 15.05 7.06689H5.92C4.86 7.06689 4 7.91546 4 8.95827L4.04 15.3992C4.04 16.442 4.91 17.2905 5.97 17.2905H6.12L6.16 18.9979C6.17 19.3966 6.4 19.7544 6.75 19.9078C6.88 19.9691 7.02 19.9998 7.16 19.9998C7.4 19.9998 7.63 19.9078 7.82 19.7442L10.57 17.2905H15.09C16.15 17.2905 17.01 16.442 17.01 15.3992L16.97 8.95827ZM10.19 15.2458C9.95 15.2458 9.72 15.3378 9.53 15.5014L8.11 16.7691V15.2458H6.04L6 9.11162H14.97L15.01 15.2458H10.19Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_836_48180">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

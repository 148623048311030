import { Icon } from '@chakra-ui/react';

export const Competency = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12.2858 4.67913C11.1951 4.60208 10.1567 4.96668 9.36099 5.70776C8.57687 6.43799 8.12732 7.47055 8.12732 8.54047C8.12732 9.41219 8.41399 10.2384 8.95641 10.9288C9.35222 11.4325 9.90647 12.0342 10.2412 12.593L10.2449 13.2475C10.2449 13.4631 10.4202 13.6387 10.6362 13.6387L13.5253 13.5968C13.7409 13.5968 13.9163 13.4213 13.9163 13.2055L13.9124 12.5839C14.2654 11.9994 14.685 11.3854 15.0433 10.9298C15.6141 10.2076 15.9077 9.30089 15.8694 8.37644C15.7899 6.44096 14.2156 4.81713 12.2858 4.67913Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <rect x="9.93492" y="15.9621" width="4.28757" height="0.61251" stroke="currentColor" strokeWidth="0.61251" />
    <path d="M11.3538 18.606H12.8038V18.831H11.3538V18.606Z" stroke="currentColor" />
  </Icon>
);

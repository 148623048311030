import { Icon } from '@chakra-ui/react';

export const CheckCircularGreenDualTone = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <circle cx="12" cy="12" r="6" fill="#CFD7DF" />
    <path
      opacity="0.3"
      d="M12 6C8.6925 6 6 8.6925 6 12C6 15.3075 8.6925 18 12 18C15.3075 18 18 15.3075 18 12C18 8.6925 15.3075 6 12 6ZM10.5 15.75L7.5 12.75L8.5575 11.6925L10.5 13.6275L15.4425 8.685L16.5 9.75L10.5 15.75Z"
      fill="#44812F"
    />
    <path
      d="M12 4.5C7.86 4.5 4.5 7.86 4.5 12C4.5 16.14 7.86 19.5 12 19.5C16.14 19.5 19.5 16.14 19.5 12C19.5 7.86 16.14 4.5 12 4.5ZM12 18C8.6925 18 6 15.3075 6 12C6 8.6925 8.6925 6 12 6C15.3075 6 18 8.6925 18 12C18 15.3075 15.3075 18 12 18ZM15.4425 8.685L10.5 13.6275L8.5575 11.6925L7.5 12.75L10.5 15.75L16.5 9.75L15.4425 8.685Z"
      fill="#44812F"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Parent = (props) => (
  <Icon viewBox="0 0 16 14" boxSize="16px" color="icon.g1" {...props}>
    <circle cx="6.02161" cy="9.97864" r="4.02161" fill="#666666" />
    <circle cx="6.02197" cy="9.97803" r="3.21729" fill="#CCCCCC" />
    <mask
      id="path-3-outside-1_3949_166949"
      maskUnits="userSpaceOnUse"
      x="2.92963"
      y="-0.32394"
      width="13.4141"
      height="12.563"
      fill="black"
    >
      <rect fill="white" x="2.92963" y="-0.32394" width="13.4141" height="12.563" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85231 4.29384L9.53687 3.38541L9.87268 5.77488L12.2624 5.43902L11.5779 6.34745L9.65125 6.61822L6.69168 9.98298C6.54379 10.1511 6.28705 10.1661 6.12065 10.0162C5.95696 9.86884 5.9423 9.61719 6.08778 9.4518L9.09648 6.03118L8.85231 4.29384Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.85231 4.29384L9.53687 3.38541L9.87268 5.77488L12.2624 5.43902L11.5779 6.34745L9.65125 6.61822L6.69168 9.98298C6.54379 10.1511 6.28705 10.1661 6.12065 10.0162C5.95696 9.86884 5.9423 9.61719 6.08778 9.4518L9.09648 6.03118L8.85231 4.29384Z"
      fill="#666666"
    />
    <path
      d="M9.53687 3.38541L10.5271 3.24624L10.1911 0.855517L8.73823 2.78359L9.53687 3.38541ZM8.85231 4.29384L8.05368 3.69203L7.8044 4.02283L7.86204 4.43302L8.85231 4.29384ZM9.87268 5.77488L8.88242 5.91405L9.02159 6.90432L10.0119 6.76514L9.87268 5.77488ZM12.2624 5.43902L13.0611 6.04083L14.514 4.11276L12.1233 4.44875L12.2624 5.43902ZM11.5779 6.34745L11.717 7.33772L12.1272 7.28007L12.3765 6.94927L11.5779 6.34745ZM9.65125 6.61822L9.51207 5.62795L9.14512 5.67953L8.90038 5.95777L9.65125 6.61822ZM6.69168 9.98298L5.94081 9.32253L5.94081 9.32253L6.69168 9.98298ZM6.12065 10.0162L6.78978 9.27309L6.78978 9.27309L6.12065 10.0162ZM6.08778 9.4518L5.33691 8.79135L5.33691 8.79135L6.08778 9.4518ZM9.09648 6.03118L9.84735 6.69163L10.1507 6.34679L10.0867 5.89201L9.09648 6.03118ZM8.73823 2.78359L8.05368 3.69203L9.65095 4.89566L10.3355 3.98722L8.73823 2.78359ZM10.863 5.6357L10.5271 3.24624L8.5466 3.52458L8.88242 5.91405L10.863 5.6357ZM12.1233 4.44875L9.73351 4.78461L10.0119 6.76514L12.4016 6.42929L12.1233 4.44875ZM12.3765 6.94927L13.0611 6.04083L11.4638 4.8372L10.7792 5.74564L12.3765 6.94927ZM9.79042 7.60849L11.717 7.33772L11.4387 5.35719L9.51207 5.62795L9.79042 7.60849ZM7.44255 10.6434L10.4021 7.27867L8.90038 5.95777L5.94081 9.32253L7.44255 10.6434ZM5.45152 10.7594C6.03172 11.2818 6.9269 11.2297 7.44255 10.6434L5.94081 9.32253C6.16068 9.07256 6.54238 9.05033 6.78978 9.27309L5.45152 10.7594ZM5.33691 8.79135C4.82967 9.36804 4.88076 10.2455 5.45152 10.7594L6.78978 9.27309C7.03315 9.49222 7.05493 9.86635 6.83865 10.1122L5.33691 8.79135ZM8.34561 5.37073L5.33691 8.79135L6.83865 10.1123L9.84735 6.69163L8.34561 5.37073ZM7.86204 4.43302L8.10621 6.17036L10.0867 5.89201L9.84258 4.15467L7.86204 4.43302Z"
      fill="white"
      mask="url(#path-3-outside-1_3949_166949)"
    />
  </Icon>
);

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export type UnsavedDataAlertState = {
  onCancel: () => void;
  onAction: () => void;
  onOpenChange: () => void;
  isTriggerRequired: boolean;
  triggeredText: string;
  title: string;
  description: string;
  cancelText: string;
  actionText: string;
  isUnsavedDataAlertDialogVisible: boolean;
};

const initialState: UnsavedDataAlertState = {
  isTriggerRequired: true,
  triggeredText: 'Open',
  title: 'Save Unsaved Changes?',
  description: 'You have unsaved changes. Do you want to save them before leaving?',
  cancelText: 'cancel',
  actionText: 'Stay on this page',
  onCancel: () => {},
  onAction: () => {},
  onOpenChange: () => {},
  isUnsavedDataAlertDialogVisible: false,
};

export const unsavedDataAlertSlice = createSlice({
  name: 'unsavedDataAlert',
  initialState,
  reducers: {
    showAlertDialog: (state, action) => ({ ...state, ...action.payload }),
    updateAlertDialog: (state, action) => ({ ...state, ...action.payload }),
    onCancel: (state) => {
      if (typeof state.onCancel === 'function') {
        state.onCancel();
      }
      return initialState;
    },
    onAction: (state) => {
      if (typeof state.onAction === 'function') {
        state.onAction();
      }
      return initialState;
    },
  },
});

export const { showAlertDialog, onCancel, onAction, updateAlertDialog } = unsavedDataAlertSlice.actions;

export default unsavedDataAlertSlice.reducer;

export const useUnsavedDataAlertProps = () =>
  useSelector((state: { unsavedDataAlertSlice: UnsavedDataAlertState }) => state.unsavedDataAlertSlice);

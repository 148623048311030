import { OPEN_OBJECTIVE_PROMPT_MODAL, CLOSE_OBJECTIVE_PROMPT_MODAL } from 'actions/actionTypes';

export const initialState = {
  contentComponent: null,
  isOpen: false,
  wide: false,
};

const objectivePromptModal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_OBJECTIVE_PROMPT_MODAL:
      return {
        isOpen: true,
        contentComponent: action.payload.contentComponent,
        wide: action.payload?.wide || false,
      };

    case CLOSE_OBJECTIVE_PROMPT_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default objectivePromptModal;

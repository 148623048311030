import { Icon } from '@chakra-ui/react';

export const Text = (props) => (
  <Icon viewBox="0 0 18 18" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33213 14.4657V5.13718H2.06859C1.47706 5.13718 1 4.66012 1 4.06859C1 3.47706 1.47706 3 2.06859 3H10.7329C11.3244 3 11.8014 3.47706 11.8014 4.06859C11.8014 4.66012 11.3244 5.13718 10.7329 5.13718H7.46931V14.4657C7.46931 15.0572 6.99225 15.5343 6.40072 15.5343C5.80919 15.5343 5.33213 15.0572 5.33213 14.4657ZM12.2635 14.4657V9.46931H10.7329C10.1413 9.46931 9.66426 8.99225 9.66426 8.40072C9.66426 7.80919 10.1413 7.33213 10.7329 7.33213H15.9314C16.5229 7.33213 17 7.80919 17 8.40072C17 8.99225 16.5229 9.46931 15.9314 9.46931H14.4007V14.4657C14.4007 15.0572 13.9237 15.5343 13.3321 15.5343C12.7406 15.5343 12.2635 15.0572 12.2635 14.4657Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const MeshAiRegularWithoutPadding = (props) => (
  <Icon viewBox="0 0 12 12" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M5.27306 3.50046C5.592 3.13881 5.93941 2.80879 6.31643 2.50682C6.15613 2.41387 5.99611 2.32091 5.8358 2.22795C5.77492 2.44384 5.52441 2.61023 5.41298 2.80494C5.28479 3.02853 5.22475 3.31813 5.44649 3.50211C5.52804 3.56977 5.65008 3.58132 5.74671 3.54254C6.04386 3.42318 6.20612 3.05823 6.5231 2.97765C6.41865 2.84042 6.31392 2.70346 6.20947 2.56623C6.06592 2.81622 5.69002 3.34425 6.17065 3.49469C6.52282 3.60497 6.75881 3.18254 7.05819 3.08959C6.92749 2.95923 6.79707 2.82887 6.66637 2.69823L6.66804 2.69163C6.40301 2.9705 6.87973 3.33325 7.14281 3.05631C7.17744 3.02001 7.20118 2.97875 7.21961 2.93227C7.32043 2.67843 7.07578 2.46392 6.82779 2.54092C6.69764 2.58135 6.5823 2.65093 6.47422 2.73206C6.42088 2.77194 6.37089 2.81649 6.31727 2.85637L6.26365 2.89597C6.18071 2.94053 6.19132 2.94768 6.29521 2.9177C6.36028 2.95565 6.42563 2.99333 6.4907 3.03128L6.46836 2.99773C6.48791 3.07198 6.50746 3.14651 6.52701 3.22077C6.53874 3.10114 6.70267 2.90367 6.76216 2.79999C6.86772 2.616 6.66134 2.33438 6.44853 2.38856C6.25108 2.43862 6.08184 2.51617 5.92154 2.64213C5.79559 2.74114 5.66377 2.9342 5.51575 2.9936L5.81597 3.03403L5.78749 3.01038C5.82547 3.07611 5.86317 3.14211 5.90115 3.20784C5.87741 3.07528 6.08128 2.90642 6.15836 2.81594C6.28851 2.66331 6.37648 2.50572 6.43094 2.31348C6.50299 2.05854 6.13965 1.8828 5.9503 2.03461C5.53419 2.36794 5.14907 2.73619 4.79746 3.13496C4.54276 3.42373 5.01864 3.78731 5.27223 3.49964L5.27306 3.50046Z"
      fill="#008EF0"
    />
    <path
      d="M9.80127 3.06159C9.65829 2.91473 9.40442 2.9546 9.22681 3.01538C8.99417 3.09486 8.78974 3.19442 8.54007 3.21972C8.32475 3.24172 8.1058 3.23622 7.8902 3.22137C7.73911 3.21092 7.58774 3.19497 7.43749 3.17434C7.13504 3.08909 6.82169 3.03408 6.51002 3.01043C5.88668 2.9634 5.2382 3.01813 4.64642 3.17902C4.50678 3.19744 4.36659 3.21175 4.22611 3.22137C4.01023 3.23622 3.79156 3.24145 3.57624 3.21972C3.32657 3.19442 3.12214 3.09459 2.8895 3.01538C2.71188 2.95488 2.45802 2.91473 2.31503 3.06159C2.09692 3.28573 2.33626 3.6746 2.38625 3.91937C2.44434 4.20511 2.50047 4.49471 2.48176 4.7865C2.48176 4.7865 2.39965 5.42977 2.44378 5.8764L2.73841 8.85925C2.84426 9.93182 3.43269 10.8999 4.33642 11.5363C4.67518 11.7747 5.08153 11.9018 5.49793 11.9018H6.63514C7.05153 11.9018 7.45788 11.7744 7.79664 11.5363C8.70065 10.8999 9.2888 9.93182 9.39465 8.85925L9.68928 5.8764C9.73341 5.43114 9.63427 4.7865 9.63427 4.7865C9.61528 4.49443 9.67169 4.20484 9.72978 3.91937C9.77949 3.6746 10.0191 3.28573 9.80127 3.06159Z"
      fill="url(#paint0_linear_3365_219689)"
    />
    <path
      d="M5.53161 11.9015C4.93536 11.5314 4.24806 10.6689 4.24806 9.78416C4.24806 8.61149 5.05824 7.89616 6.05776 7.89616C7.05729 7.89616 7.86747 8.61149 7.86747 9.78416C7.86747 10.6637 7.25502 11.5212 6.64452 11.8949"
      fill="url(#paint1_linear_3365_219689)"
    />
    <path
      d="M7.97762 6.34637C7.81871 7.64446 8.08625 9.50991 9.19777 10.3583C9.62254 10.6826 10.1775 10.8528 10.705 10.6994C11.1633 10.566 11.5616 10.2112 11.666 9.74203C11.7098 9.54566 11.7933 9.22939 11.6275 9.15184C11.2289 8.96592 11.091 8.93485 10.8667 8.80614C10.3766 8.5798 9.74263 7.98906 9.45079 7.36532"
      fill="url(#paint2_linear_3365_219689)"
    />
    <path
      d="M4.09137 6.30975C4.2782 7.60399 4.0506 9.47494 2.95751 10.3462C2.53972 10.6792 1.98871 10.861 1.45809 10.7186C0.997285 10.5948 0.591499 10.2486 0.476996 9.78131C0.428961 9.58577 0.338476 9.27143 0.502969 9.1903C0.897305 8.99613 1.03471 8.96231 1.25617 8.82892C1.74155 8.59241 2.36238 7.98874 2.64082 7.35895"
      fill="url(#paint3_linear_3365_219689)"
    />
    <path
      d="M10.5418 5.04362L10.5387 4.80986C10.529 4.69215 10.4452 4.59424 10.3318 4.57004C9.91204 4.47984 8.83012 4.31813 7.39269 4.62945C7.39269 4.62945 6.6825 4.83626 6.06614 4.84094L6.03095 4.84699C5.41459 4.85441 4.70048 4.66162 4.70048 4.66162C3.25719 4.37891 2.17891 4.56179 1.76083 4.66052C1.64801 4.6872 1.56618 4.78648 1.55864 4.90447L1.56004 5.13823C1.56059 5.24219 1.5955 5.34257 1.65834 5.42425C1.75274 5.54746 1.83568 5.68194 1.89684 5.8255C1.95633 5.96494 1.94041 6.12307 1.96331 6.27076C2.09624 7.12744 2.53303 7.45416 2.53303 7.45416C2.53303 7.45416 2.79834 7.76603 3.87104 7.76906C4.27291 7.77016 4.61056 7.68133 4.86749 7.54079C4.87056 7.53914 4.87391 7.53722 4.87699 7.53557C5.28249 7.3117 5.58216 6.9297 5.71816 6.47977L5.82708 6.11867C5.85975 6.01031 5.96085 5.93936 6.07144 5.94651V5.94046C6.17226 5.94238 6.26219 6.00894 6.29431 6.10822L6.41021 6.46684C6.55459 6.91402 6.86151 7.2897 7.27121 7.50559C7.27428 7.50724 7.27763 7.50889 7.28071 7.51054C7.54015 7.64585 7.87975 7.72808 8.28135 7.719C9.35376 7.6948 9.61293 7.37771 9.61293 7.37771C9.61293 7.37771 10.0436 7.04246 10.1603 6.1833C10.1804 6.03534 10.1614 5.87748 10.2184 5.73667C10.2768 5.59201 10.3572 5.45588 10.4494 5.33074C10.5105 5.24769 10.5435 5.14648 10.5421 5.0428V5.04362H10.5418Z"
      fill="#008EF0"
    />
    <path
      d="M2.36022 5.70265C2.36022 5.70265 2.34989 4.98733 2.87772 4.93673C3.40555 4.88612 4.56007 4.8523 5.02311 5.04756C5.48615 5.24282 5.50458 5.50656 5.45738 5.93312C5.40152 6.43943 5.08064 7.1619 4.27046 7.2235C3.46028 7.28511 2.80678 7.25485 2.58699 6.75405C2.33816 6.18778 2.36022 5.70265 2.36022 5.70265Z"
      fill="#008EF0"
    />
    <path
      d="M9.75396 5.62357C9.75396 5.62357 9.75088 4.90825 9.22222 4.86782C8.69355 4.82767 7.53847 4.81667 7.07934 5.02101C6.61993 5.22535 6.60681 5.48937 6.66182 5.91509C6.72718 6.4203 7.06175 7.13618 7.87304 7.18183C8.67512 7.22693 9.337 7.18403 9.54729 6.67937C9.78524 6.10843 9.75396 5.62385 9.75396 5.62385V5.62357Z"
      fill="#008EF0"
    />
    <path
      d="M7.58299 6.70015C7.88293 6.6677 8.08932 6.30742 8.04351 5.89544C7.99771 5.48319 7.71732 5.17517 7.41738 5.20762C7.11744 5.2398 6.91105 5.60035 6.95686 6.0126C7.00266 6.42485 7.28305 6.73287 7.58299 6.70042V6.70015Z"
      fill="#008EF0"
    />
    <path
      d="M7.79352 5.69334C7.92562 5.69334 8.03286 5.58389 8.03286 5.44858C8.03286 5.31327 7.92562 5.20381 7.79352 5.20381C7.66143 5.20381 7.55418 5.31354 7.55418 5.44858C7.55418 5.58361 7.66143 5.69334 7.79352 5.69334Z"
      fill="#008EF0"
    />
    <path
      d="M5.15781 6.01169C5.20361 5.59943 4.99751 5.23916 4.69729 5.20698C4.39734 5.17453 4.11695 5.48255 4.07115 5.8948C4.02535 6.30706 4.23145 6.66733 4.53168 6.69951C4.83162 6.73196 5.11201 6.42394 5.15781 6.01169Z"
      fill="#008EF0"
    />
    <path
      d="M4.98069 5.69344C5.11278 5.69344 5.22002 5.5837 5.22002 5.44867C5.22002 5.31364 5.11278 5.2039 4.98069 5.2039C4.84859 5.2039 4.74135 5.31364 4.74135 5.44867C4.74135 5.5837 4.84859 5.69344 4.98069 5.69344Z"
      fill="#008EF0"
    />
    <path
      d="M5.7259 6.21464L6.04986 5.31367L6.38918 6.21464L6.07779 7.05757C6.0708 7.07599 6.04427 7.07599 6.03757 7.05757L5.7259 6.21464Z"
      fill="#FFC200"
      stroke="#FFC200"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M10.7115 4.78737L10.7084 4.5536C10.6983 4.43589 10.6112 4.33799 10.4936 4.31379C10.058 4.22358 8.93555 4.06187 7.44366 4.37319C7.44366 4.37319 6.70665 4.58 6.06711 4.58468L6.03081 4.59073C5.39127 4.59816 4.65035 4.40537 4.65035 4.40537C3.15288 4.12265 2.03382 4.30554 1.59983 4.40427C1.48281 4.43094 1.39764 4.53023 1.38982 4.64821L1.39121 4.88197C1.39177 4.98593 1.42808 5.08631 1.49315 5.16799C1.59089 5.2912 1.67719 5.42569 1.74059 5.56925C1.80231 5.70868 1.78583 5.86682 1.80957 6.0145C1.94753 6.87118 2.40079 7.19791 2.40079 7.19791C2.40079 7.19791 2.67616 7.50978 3.78907 7.5128C4.20603 7.5139 4.55652 7.42507 4.82322 7.28454C4.82657 7.28289 4.82965 7.28096 4.833 7.27931C5.25359 7.05545 5.5647 6.67344 5.70573 6.22351L5.81884 5.86242C5.85263 5.75406 5.95764 5.6831 6.07242 5.69025V5.6842C6.17687 5.68613 6.27043 5.75268 6.30366 5.85196L6.42375 6.21059C6.57372 6.65777 6.89209 7.03344 7.31715 7.24933C7.3205 7.25098 7.32385 7.25263 7.32692 7.25428C7.59614 7.38959 7.94831 7.47182 8.36527 7.46275C9.47818 7.43855 9.74712 7.12145 9.74712 7.12145C9.74712 7.12145 10.194 6.7862 10.3152 5.92704C10.3361 5.77909 10.3163 5.62122 10.3755 5.48041C10.4361 5.33576 10.5196 5.19962 10.6151 5.07449C10.6785 4.99143 10.7129 4.89023 10.7115 4.78654V4.78737Z"
      fill="#333333"
    />
    <path
      d="M2.22098 5.50645C2.22098 5.50645 2.21037 4.79113 2.75775 4.74053C3.30541 4.68993 4.5035 4.6561 4.98413 4.85136C5.46476 5.04662 5.48375 5.31037 5.43488 5.73692C5.37707 6.24323 5.04389 6.9657 4.20327 7.02731C3.37187 7.08836 2.68458 7.05866 2.45641 6.55785C2.19836 5.99159 2.22126 5.50645 2.22126 5.50645H2.22098Z"
      fill="#E5F8FF"
    />
    <path
      d="M9.89287 5.42729C9.89287 5.42729 9.88952 4.71196 9.34102 4.67153C8.79252 4.63138 7.59388 4.62038 7.11715 4.82472C6.64043 5.02906 6.62675 5.29308 6.684 5.7188C6.75186 6.22401 7.099 6.93989 7.94073 6.98554C8.77325 7.03064 9.45999 6.98774 9.6781 6.48308C9.92498 5.91214 9.89259 5.42756 9.89259 5.42756L9.89287 5.42729Z"
      fill="#E5F8FF"
    />
    <path
      d="M4.4119 5.93158C4.3742 6.11227 4.22618 6.25913 4.03013 6.28828C3.77906 6.32568 3.54475 6.15572 3.50649 5.90848"
      stroke="black"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.46216 5.93158C8.42445 6.11227 8.27644 6.25913 8.08039 6.28828C7.82932 6.32568 7.59501 6.15572 7.55675 5.90848"
      stroke="black"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.13284 10.0169L5.85859 9.74688C5.80665 9.76145 5.74828 9.74853 5.70723 9.7081C5.6469 9.6487 5.6469 9.55244 5.70723 9.49304C5.76755 9.43363 5.86529 9.43363 5.92562 9.49304C5.96667 9.53346 5.9798 9.59067 5.965 9.6421L6.23924 9.91216C6.32498 9.87119 6.43083 9.88576 6.50204 9.95589C6.59169 10.0442 6.59169 10.1875 6.50204 10.2757C6.41239 10.364 6.26689 10.364 6.17724 10.2757C6.10603 10.2056 6.09151 10.1014 6.13284 10.0169ZM5.4556 9.24524C5.39528 9.30465 5.29753 9.30465 5.23721 9.24524C5.17688 9.18584 5.17688 9.08958 5.23721 9.03018C5.29753 8.97078 5.39528 8.97078 5.4556 9.03018C5.51592 9.08958 5.51592 9.18584 5.4556 9.24524Z"
      fill="#FFC200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.39108 9.45339L5.66532 9.18332C5.65052 9.13217 5.66365 9.07469 5.7047 9.03426C5.76502 8.97486 5.86277 8.97486 5.92309 9.03426C5.98342 9.09367 5.98342 9.18992 5.92309 9.24933C5.88204 9.28976 5.82395 9.30268 5.77173 9.28811L5.49748 9.55817C5.53909 9.6426 5.52429 9.74684 5.45307 9.81697C5.36343 9.90525 5.21793 9.90525 5.12828 9.81697C5.03863 9.72869 5.03863 9.5854 5.12828 9.49712C5.19949 9.42699 5.30534 9.41269 5.39108 9.45339ZM6.17472 8.78675C6.1144 8.72734 6.1144 8.63109 6.17472 8.57168C6.23504 8.51228 6.33279 8.51228 6.39311 8.57168C6.45344 8.63109 6.45344 8.72734 6.39311 8.78675C6.33279 8.84615 6.23504 8.84615 6.17472 8.78675Z"
      fill="#5CC461"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96769 8.72324L6.24194 8.99331C6.29389 8.97873 6.35226 8.99166 6.39331 9.03209C6.45363 9.09149 6.45363 9.18775 6.39331 9.24715C6.33299 9.30656 6.23524 9.30656 6.17492 9.24715C6.13386 9.20673 6.12074 9.14952 6.13554 9.09809L5.86129 8.82802C5.77555 8.869 5.66971 8.85443 5.59849 8.7843C5.50885 8.69602 5.50885 8.55273 5.59849 8.46445C5.68814 8.37617 5.83364 8.37617 5.92329 8.46445C5.9945 8.53458 6.00903 8.63881 5.96769 8.72324ZM6.64494 9.49494C6.70526 9.43554 6.803 9.43554 6.86333 9.49494C6.92365 9.55435 6.92365 9.6506 6.86333 9.71001C6.803 9.76941 6.70526 9.76941 6.64494 9.71001C6.58461 9.6506 6.58461 9.55435 6.64494 9.49494Z"
      fill="#008EF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.92508 9.95526C5.9854 10.0147 5.9854 10.1109 5.92508 10.1703C5.86476 10.2297 5.76701 10.2297 5.70669 10.1703C5.64636 10.1109 5.64636 10.0147 5.70669 9.95526C5.76701 9.89585 5.86476 9.89585 5.92508 9.95526ZM6.70873 9.28834L6.43448 9.5584C6.44928 9.60956 6.43615 9.66704 6.3951 9.70746C6.33478 9.76687 6.23703 9.76687 6.17671 9.70746C6.11638 9.64806 6.11638 9.5518 6.17671 9.4924C6.21776 9.45197 6.27585 9.43905 6.32807 9.45362L6.60232 9.18355C6.56071 9.09912 6.57551 8.99489 6.64673 8.92476C6.73637 8.83648 6.88188 8.83648 6.97152 8.92476C7.06117 9.01304 7.06117 9.15633 6.97152 9.24461C6.90031 9.31474 6.79446 9.32904 6.70873 9.28834Z"
      fill="#EC3B64"
    />
    <path
      d="M8.78041 11.2806C8.84129 11.2368 8.84576 11.1494 8.79046 11.0991C8.53911 10.8708 8.15092 10.8414 7.86438 11.0474C7.57757 11.2534 7.48764 11.6263 7.63175 11.9315C7.66358 11.9989 7.74932 12.0212 7.8102 11.9775L8.78041 11.2806Z"
      fill="#333333"
    />
    <path
      d="M3.41995 11.2806C3.35907 11.2368 3.3546 11.1494 3.4099 11.0991C3.66124 10.8708 4.04944 10.8414 4.33597 11.0474C4.62279 11.2534 4.71271 11.6263 4.56861 11.9315C4.53677 11.9989 4.45103 12.0212 4.39015 11.9775L3.41995 11.2806Z"
      fill="#333333"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3365_219689"
        x1="6.05787"
        y1="11.9018"
        x2="6.05787"
        y2="2.96065"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.38" stopColor="#84C3F7" />
        <stop offset="0.43" stopColor="#7EC1F7" />
        <stop offset="0.5" stopColor="#6FBEFA" />
        <stop offset="0.57" stopColor="#56B9FE" />
        <stop offset="0.58" stopColor="#52B9FF" />
        <stop offset="1" stopColor="#0092FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3365_219689"
        x1="6.05776"
        y1="11.9015"
        x2="6.05776"
        y2="7.89616"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#88C5F7" />
        <stop offset="0.07" stopColor="#A8D4F9" />
        <stop offset="0.14" stopColor="#C3E2FB" />
        <stop offset="0.21" stopColor="#D9ECFC" />
        <stop offset="0.3" stopColor="#EAF5FD" />
        <stop offset="0.41" stopColor="#F6FAFE" />
        <stop offset="0.57" stopColor="#FDFEFE" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3365_219689"
        x1="9.01652"
        y1="6.03203"
        x2="10.4519"
        y2="10.7761"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#84C3F7" />
        <stop offset="0.12" stopColor="#7EC1F7" />
        <stop offset="0.27" stopColor="#6FBEFA" />
        <stop offset="0.43" stopColor="#56B9FE" />
        <stop offset="0.45" stopColor="#52B9FF" />
        <stop offset="0.56" stopColor="#4CB6FF" />
        <stop offset="0.69" stopColor="#3DAFFF" />
        <stop offset="0.84" stopColor="#24A3FF" />
        <stop offset="0.99" stopColor="#0293FF" />
        <stop offset="1" stopColor="#0092FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3365_219689"
        x1="3.04632"
        y1="6.01713"
        x2="1.71029"
        y2="10.7893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#84C3F7" />
        <stop offset="0.12" stopColor="#7EC1F7" />
        <stop offset="0.27" stopColor="#6FBEFA" />
        <stop offset="0.43" stopColor="#56B9FE" />
        <stop offset="0.45" stopColor="#52B9FF" />
        <stop offset="0.56" stopColor="#4CB6FF" />
        <stop offset="0.69" stopColor="#3DAFFF" />
        <stop offset="0.84" stopColor="#24A3FF" />
        <stop offset="0.99" stopColor="#0293FF" />
        <stop offset="1" stopColor="#0092FF" />
      </linearGradient>
    </defs>
  </Icon>
);

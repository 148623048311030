import { SET_ENABLED_FEATURE_FLAGS } from 'actions/actionTypes';

const initialState = null;

export default function enabledFeatureFlagsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ENABLED_FEATURE_FLAGS: {
      const newState = [...action.payload.enabledFeatureFlags];
      return newState;
    }

    default:
      return state;
  }
}

import { Icon } from '@chakra-ui/react';

export const ArrowLeft = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M9.5 12.0099C9.5 11.7977 9.58771 11.5943 9.74364 11.4443L13.0701 8.24432C13.2251 8.0899 13.4381 8.00191 13.6611 8.00003C13.8842 7.99816 14.0987 8.08267 14.2565 8.23427C14.4142 8.38602 14.5019 8.59239 14.5 8.80695C14.498 9.02151 14.4065 9.22641 14.2462 9.37548L10.9197 12.5755C10.7637 12.7255 10.5521 12.8097 10.3316 12.8097C10.1111 12.8097 9.89957 12.7255 9.74364 12.5755C9.58771 12.4255 9.5 12.222 9.5 12.0099Z"
      fill="currentColor"
    />
    <path
      d="M9.5 12.0099C9.5 11.7977 9.58771 11.5943 9.74364 11.4443C9.89957 11.2943 10.111 11.21 10.3315 11.21C10.552 11.21 10.7635 11.2942 10.9195 11.4442L14.246 14.6442C14.4499 14.8473 14.5276 15.1391 14.4499 15.4115C14.3722 15.684 14.151 15.8968 13.8678 15.9716C13.5845 16.0462 13.2814 15.9717 13.0702 15.7754L9.74364 12.5755C9.58771 12.4255 9.5 12.222 9.5 12.0099Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const ProgressNotStarted = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.9451 3C9.08539 3.20558 7.39648 3.97777 6.05481 5.14012L7.47372 6.55902C8.44424 5.75243 9.63626 5.20317 10.9451 5.01596V3Z"
      fill="currentcolor"
    />
    <path
      d="M16.2543 6.42827C15.3144 5.6931 14.1819 5.19287 12.9451 5.01596V3C14.7322 3.19756 16.3616 3.91837 17.6767 5.00585L16.2543 6.42827Z"
      fill="currentcolor"
    />
    <path
      d="M18.8742 10.9451C18.7119 9.8102 18.2773 8.76314 17.6388 7.87215L19.0679 6.44303C20.0528 7.71616 20.7039 9.26056 20.8901 10.9451H18.8742Z"
      fill="currentcolor"
    />
    <path
      d="M17.3311 16.4164C18.1377 15.4459 18.687 14.2539 18.8742 12.9451H20.8901C20.6846 14.8048 19.9124 16.4937 18.75 17.8353L17.3311 16.4164Z"
      fill="currentcolor"
    />
    <path
      d="M12.9451 18.8742C14.0073 18.7222 14.9927 18.3318 15.8451 17.7588L17.2802 19.194C16.04 20.1083 14.5567 20.712 12.9451 20.8901V18.8742Z"
      fill="currentcolor"
    />
    <path
      d="M7.87215 17.6388C8.76314 18.2773 9.8102 18.7119 10.9451 18.8742V20.8901C9.26056 20.7039 7.71616 20.0528 6.44303 19.0679L7.87215 17.6388Z"
      fill="currentcolor"
    />
    <path
      d="M5.01596 12.9451C5.19287 14.1819 5.6931 15.3144 6.42827 16.2543L5.00585 17.6767C3.91837 16.3616 3.19756 14.7322 3 12.9451H5.01596Z"
      fill="currentcolor"
    />
    <path
      d="M6.13131 8.04504C5.55835 8.89746 5.16791 9.8828 5.01596 10.9451H3C3.17815 9.33349 3.78182 7.85015 4.69617 6.6099L6.13131 8.04504Z"
      fill="currentcolor"
    />
  </Icon>
);

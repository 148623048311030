import {
  CLOSE_REALIGN_MODAL,
  OPEN_REALIGN_MODAL,
  SET_PARENT_OBJECTIVE_ID_REALIGN_MODAL,
  SET_OBJECTIVE_REALIGN_MODAL,
  SET_TIMEBOUND_DETAILS_REALIGN_MODAL,
  SET_SELECTED_OBJECTIVE_REALIGN_MODAL,
} from './actionTypes';

export const initialState = {
  display: false,
  objectiveId: null,
  objectiveName: '',
  parentId: null,
  timeboundDetails: {},
  realignId: null,
  selectedObjective: {},
  onClose: () => {},
  onSelect: () => {},
  objective: null,
  isSelectObjectiveModal: false,
  timeboundIsDisabled: false,
  showAlignAsParent: false,
  krName: '',
  krId: '',
  setTimeboundDetails: () => {},
  isRealignObjective: false,
  onSuccess: () => {},
  isObjectiveSidePanel: false,
  isDeleteRealign: false,
  isParentTagHighlighted: true,
  source: '',
};

const realignModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REALIGN_MODAL:
      return {
        ...initialState,
        display: true,
        objectiveId: action.payload.objectiveId,
        onClose: action.payload.onClose,
        onSelect: action.payload.onSelect,
        objectiveName: action.payload.objectiveName,
        realignId: action.payload.realignId,
        selectedTimeBoundPeriod: action.payload.selectedTimeBoundPeriod,
        selectedObjective: action.payload.selectedObjective,
        isSelectObjectiveModal: action.payload.isSelectObjectiveModal,
        timeboundIsDisabled: action.payload.timeboundIsDisabled,
        showAlignAsParent: action.payload.showAlignAsParent,
        krName: action.payload.krName,
        krId: action.payload.krId,
        setTimeboundDetails: action.payload.setTimeboundDetails,
        isRealignObjective: action.payload.isRealignObjective,
        onSuccess: action.payload.onSuccess,
        isObjectiveSidePanel: action.payload.isObjectiveSidePanel,
        isDeleteRealign: action.payload.isDeleteRealign,
        source: action.payload?.source || '',
      };

    case CLOSE_REALIGN_MODAL:
      return {
        ...initialState,
        display: false,
      };

    case SET_PARENT_OBJECTIVE_ID_REALIGN_MODAL:
      return {
        ...state,
        parentId: action.payload.parentId,
      };

    case SET_TIMEBOUND_DETAILS_REALIGN_MODAL:
      return {
        ...state,
        timeboundDetails: action.payload.timeboundDetails,
      };

    case SET_OBJECTIVE_REALIGN_MODAL:
      return {
        ...state,
        objective: action.payload.objective,
        isParentTagHighlighted: action.payload.isParentTagHighlighted,
      };

    case SET_SELECTED_OBJECTIVE_REALIGN_MODAL:
      return {
        ...state,
        selectedObjective: action.payload.selectedObjective,
      };

    default:
      return state;
  }
};

export default realignModalReducer;

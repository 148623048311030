import { Icon } from '@chakra-ui/react';

export const MyTeamReviews = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M19.8998 8.5C20.0998 8.3 19.9998 8 19.6998 8L16.1998 7.6C16.0998 7.6 15.9998 7.5 15.9998 7.4L14.4998 4.2C14.4998 4.1 14.3998 4 14.2998 4C14.1998 4 14.0998 4.1 13.9998 4.2L12.5998 7.4C12.5998 7.5 12.4998 7.6 12.3998 7.6L8.79984 8C8.59984 8 8.49984 8.3 8.69984 8.5L11.2998 10.9C11.3998 11 11.3998 11.1 11.3998 11.2L11.0998 12.5C11.5998 12.7 12.0998 13.1 12.2998 13.6C12.6998 13.4 12.9998 13.3 13.4998 13.3C13.5998 13.3 13.7998 13.3 13.8998 13.3L14.0998 13.2H14.1998H14.2998L17.3998 15H17.4998C17.6998 15 17.7998 14.8 17.7998 14.7L17.0998 11.2C17.0998 11.1 17.0998 11 17.1998 10.9L19.8998 8.5ZM15.1998 11.4C14.8998 11.3 14.5998 11.2 14.2998 11.2C13.9998 11.2 13.6998 11.3 13.3998 11.4C13.3998 10.7 13.1998 10 12.7998 9.5C13.3998 9.4 13.9998 9 14.2998 8.4C14.5998 9 15.1998 9.4 15.7998 9.5C15.3998 10 15.1998 10.7 15.1998 11.4Z"
      fill="currentColor"
    />
    <path
      d="M6.7 17.1C7.2 17.1 7.7 16.8 7.9 16.3C7.7 15.9 7.6 15.4 7.6 15C7.6 14.9 7.6 14.8 7.6 14.6C7.4 14.4 7.1 14.2 6.7 14.2C6 14.2 5.4 14.8 5.4 15.6C5.3 16.5 5.9 17.1 6.7 17.1ZM6 19.2C6 18.7 6.2 18.3 6.5 17.9C5.5 17.9 4 18.4 4 19.3V20H6V19.2ZM10.1 16.7C11 16.7 11.7 16 11.7 15C11.7 14.1 11 13.3 10.1 13.3C9.2 13.3 8.5 14 8.5 15C8.5 15.9 9.2 16.7 10.1 16.7ZM10.1 17.5C9.1 17.5 7 18.1 7 19.2V20H13.2V19.2C13.2 18.1 11.1 17.5 10.1 17.5ZM13.7 17.9C14 18.3 14.2 18.7 14.2 19.2V20H16.2V19.3C16.1 18.4 14.6 17.9 13.7 17.9ZM12.6 15C12.6 15.5 12.5 15.9 12.3 16.3C12.5 16.8 13 17.1 13.5 17.1C14.2 17.1 14.8 16.5 14.8 15.7C14.8 14.9 14.2 14.3 13.5 14.3C13.2 14.3 12.9 14.4 12.6 14.7C12.6 14.8 12.6 14.9 12.6 15Z"
      fill="currentColor"
    />
  </Icon>
);

import { OPEN_OBJECTIVE_CREATION_MODAL, CLOSE_OBJECTIVE_CREATION_MODAL } from 'actions/actionTypes';

export const initialState = {
  isOpen: false,
  onClose: () => {},
  onSelect: () => {},
  enableCreation: false,
  selectedTimeBoundPeriod: false,
  allowTimePeriodChange: true,
  selectedObjective: null,
};

const objectiveCreationModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_OBJECTIVE_CREATION_MODAL:
      return {
        isOpen: true,
        onClose: action.onClose,
        onSelect: action.onSelect,
        enableCreation: action.enableCreation,
        allowTimePeriodChange: action?.allowTimePeriodChange,
        selectedTimeBoundPeriod: action?.selectedTimeBoundPeriod,
        selectedObjective: action?.selectedObjective,
      };

    case CLOSE_OBJECTIVE_CREATION_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default objectiveCreationModalReducer;

import { Icon } from '@chakra-ui/react';

export const CoachingIconSelected = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 10.92V6.96H11L12.23 8.17L8.83 11.16L10.17 12.65L13.66 9.59L15.01 10.92H15Z"
      fill="#104F95"
    />
    <path
      d="M15 4H9C7.35 4 6 5.34 6 7V12.86C6 14.47 7.27 15.78 8.86 15.85L8.07 18.5C8.04 18.59 8.03 18.68 8.03 18.77C8.03 19.18 8.3 19.57 8.71 19.7C9.2 19.84 9.7 19.58 9.84 19.09L10.81 15.86H13.19L14.16 19.09C14.3 19.58 14.81 19.84 15.29 19.7C15.7 19.57 15.97 19.19 15.97 18.78C15.97 18.68 15.97 18.59 15.93 18.5L15.14 15.85C16.73 15.78 18 14.47 18 12.86V7C18 5.34 16.66 4 15 4ZM16 12.86C16 13.41 15.55 13.86 15 13.86H9C8.45 13.86 8 13.41 8 12.86V7C8 6.45 8.45 6 9 6H15C15.55 6 16 6.45 16 7V12.86Z"
      fill="#104F95"
    />
  </Icon>
);

import React, { forwardRef } from 'react';

import {
  Typography,
  IconButton,
  CircularProgress,
  Button,
  Link,
  TextField,
  Divider,
  Badge,
  Tooltip,
  Checkbox,
  Radio,
  Grid,
  Box,
  Paper,
  LinearProgress,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ToggleButton } from '@material-ui/lab';
import { ResponsiveContainer } from 'recharts';
import styled, { css } from 'styled-components';

import { getColorFromKey } from 'constants/helper';
import theme from 'constants/theme';

// Pointers: have predefined sizes of your own icon button and fab button as well, then just like color key parser, make a parser for that and use it
// it will override the by default one provided by material ui

export const RippleCSS = css`
  .MuiTouchRipple-root {
    color: ${(props) => props.theme.palette.ripple};
    opacity: 0.2;
  }
`;
export const gutterCSSProps = {
  rightGutter: true,
  leftGutter: true,
  bottomGutter: true,
  topGutter: true,
};
export const GutterCSS = css`
  ${(props) =>
    props.rightGutter &&
    css`
      margin-right: ${props.theme.spacing(props.rightGutter === true ? 1 : props.rightGutter)}rem;
    `};
  ${(props) =>
    props.leftGutter &&
    css`
      margin-left: ${props.theme.spacing(props.leftGutter === true ? 1 : props.leftGutter)}rem;
    `};
  ${(props) =>
    props.bottomGutter &&
    css`
      margin-bottom: ${props.theme.spacing(props.bottomGutter === true ? 1 : props.bottomGutter)}rem;
    `};

  ${(props) =>
    props.topGutter &&
    css`
      margin-top: ${props.theme.spacing(props.topGutter === true ? 1 : props.topGutter)}rem;
    `};
`;

const PaddingCSSProps = {
  padding: true,
  paddingLeft: true,
  paddingRight: true,
  paddingTop: true,
  paddintRight: true,
};
export const PaddingCSS = css`
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${(props) =>
    props.paddingLeft &&
    css`
      padding-left: ${props.paddingLeft};
    `};
  ${(props) =>
    props.paddingRight &&
    css`
      padding-right: ${props.paddingRight};
    `};
  ${(props) =>
    props.paddingTop &&
    css`
      padding-top: ${props.paddingTop};
    `};
  ${(props) =>
    props.paddingBottom &&
    css`
      padding-bottom: ${props.paddingBottom};
    `};
`;

const backgroundCSSProps = { bgColor: true };
export const BackgroundCSS = css`
  background-color: ${(props) => getColorFromKey(props.bgColor)};
  &:hover {
    opacity: 0.8;
    background-color: ${(props) => getColorFromKey(props.bgColor)};
  }
`;

const borderCSSProps = {
  borderleft: true,
  borderright: true,
  bordertop: true,
  borderbottom: true,
  border: true,
};
export const borderCSS = css`
  ${(props) =>
    props.borderleft &&
    css`
      border-left: 1px solid ${props.borderleft};
    `};
  ${(props) =>
    props.borderright &&
    css`
      border-right: 1px solid ${props.borderright};
    `};
  ${(props) =>
    props.bordertop &&
    css`
      border-top: 1px solid ${props.bordertop};
    `};
  ${(props) =>
    props.borderbottom &&
    css`
      border-bottom: 1px solid ${props.borderbottom};
    `};
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.border};
    `};
`;

export const HorizontalSpacer = styled.div`
  display: inline-block;
  // height: 100%;
  width: ${(props) => props.theme.itemSpace.horizontal[props.spacing] || props.theme.itemSpace.horizontal.normal};
`;

export const VerticalSpacer = styled.div`
  height: ${(props) => props.theme.itemSpace.vertical[props.spacing] || props.theme.itemSpace.vertical.normal};
`;

export const EquallySpacedVerticalContainer = styled.div`
  > * {
    margin-bottom: ${(props) => props.theme.spacing(props.space || 0.5)}rem;
  }
`;

export const getEquallySpacedVerticalCSS = (space) => css`
  > * {
    margin-bottom: ${(props) => props.theme.spacing(space || 0.5)}rem;
  }
`;

const fillMixinCSSProps = { fill: true };
export const fillMixin = css`
  fill: ${(props) => (props.fill ? props.fill : 'inherit')};
`;

export const mediumSizeMixin = css`
  width: 36px;
  height: 36px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 44px;
    height: 44px;
  }
`;

const colorMixinCSSProps = { color: true };
export const colorMixin = css`
  color: ${(props) => (props.color ? props.color : 'inherit')};
`;

export const graphMargin = { right: 50, left: 50 };

export const legendWrapperStyle = { bottom: '-20px' };

export const FlexContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !gutterCSSProps[prop],
})`
  display: flex;
  ${GutterCSS};
`;

export const MarginContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !gutterCSSProps[prop],
})`
  ${GutterCSS};
`;

export const WrappedFlexContainer = styled(FlexContainer)`
  flex-wrap: wrap;
`;

export const VerticallyAlignedFlexContainer = styled(FlexContainer)`
  align-items: center;
`;

export const CenterItemFlexContainer = styled(VerticallyAlignedFlexContainer)`
  justify-content: center;
`;

const buttonBackgroundColorCSSProps = { customColor: true };
const buttonBackgroundColorCSS = css`
  background-color: ${(props) => getColorFromKey(props.customColor)};
  &:hover {
    filter: grayscale(25%);
    background-color: ${(props) => getColorFromKey(props.customColor)};
  }
`;
const buttonColorCSSProps = { fontColor: true };
const buttonColorCSS = css`
  color: ${(props) => getColorFromKey(props.fontColor)};
  &:hover {
    color: ${(props) => getColorFromKey(props.fontColor)};
  }
`;

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin: 0 auto;
`;

export const CenterFlexCSS = css`
  justify-content: center;
  align-items: center;
`;

export const MaxWidthCSS = css`
  // max-width: 94%;
  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 92%;
  }
  // ${(props) => props.theme.breakpoints.up('lg')} {
  //   max-width: 87%;
  // }
  ${(props) => props.theme.breakpoints.up('xl')} {
    // max-width: 1400px;
    max-width: 82%;
  }
  margin: 0 auto;
`;

export const PageCSS = css`
  padding: 16px;
`;

function getCustomColorString(color, theme) {
  return color && color !== 'default'
    ? theme.palette[color]
      ? theme.palette[color].main
      : color
    : theme.palette.borders.black;
}

export const MeshInSectionHeadingContainer = styled(FlexContainer)`
  margin: ${(props) => props.theme.spacing(3)}rem 0;
  padding-left: ${(props) => props.theme.spacing(1)}rem;
  justify-content: space-between;
  align-items: center;
`;

// export const SmallestIconButton = styled(StyledIconButton)`
//   ${SmallestSizeCSS};
// `;

const TruncateCSS = css`
  // width: 100%;
  white-space: nowrap;
  & > span {
    white-space: nowrap !important;
  }
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightGutterCSSProps = { rightGutter: true };
export const RightGutterCSS = css`
  ${(props) =>
    props.rightGutter &&
    css`
      margin-right: 1rem;
    `};
`;

const borderColorCSSProps = { borderColor: true };
const borderColorCSS = css`
  border: 1px solid black;
  border-color: ${(props) => getColorFromKey(props.borderColor)};
  &:hover {
    border-color: ${(props) =>
      props.hoverBorderColor ? getColorFromKey(props.hoverBorderColor) : getColorFromKey(props.borderColor)};
  }
`;

const styledButtonProps = {
  ...buttonBackgroundColorCSSProps,
  ...buttonColorCSSProps,
  ...borderColorCSSProps,
  ...gutterCSSProps,
  elongated: true,
  padding: true,
};

export const StyledButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !styledButtonProps[prop],
})`
  text-transform: none;
  ${(props) => props.customColor && buttonBackgroundColorCSS};
  ${(props) => props.fontColor && buttonColorCSS};
  ${(props) => props.borderColor && borderColorCSS};
  ${GutterCSS};
  ${(props) =>
    props.elongated &&
    css`
      padding: 0.15rem 2rem;
    `};
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  ${RippleCSS};
  &.MuiButton-root.Mui-disabled {
    color: ${(props) => props.theme.palette.text.label};
    background-color: ${(props) => props.theme.palette.background.lightGrey3};
  }
`;

const StyledFilledHoverButtonProps = {
  color: true,
  selected: true,
  backgroundColor: true,
  borderColor: true,
  ...gutterCSSProps,
  elongated: true,
  padding: true,
};

export const StyledFilledHoverButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !StyledFilledHoverButtonProps[prop],
})`
  text-transform: none;
  background-color: white;
  color: ${(props) => (props.color ? getColorFromKey(props.color) : getColorFromKey('primary'))};
  &:hover {
    background-color: ${(props) => (props.color ? getColorFromKey(props.color) : getColorFromKey('primary'))};
    color: white;
  }
  ${(props) =>
    props.selected &&
    css`
      color: white;
      background-color: ${(props) =>
        props.backgroundColor ? getColorFromKey(props.backgroundColor) : getColorFromKey('primary')};
    `}

  ${(props) => props.borderColor && borderColorCSS};
  ${GutterCSS};
  ${(props) =>
    props.elongated &&
    css`
      padding: 0.15rem 2rem;
    `};
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  ${RippleCSS};
`;

export const GreenButtonCSS = css`
  border-radius: 4px;
  background: linear-gradient(180deg, #5cc461 0%, #1ba522 100%);
  box-shadow: 0 2px 4px 0 rgba(24, 118, 210, 0.3);
  text-transform: none;
  color: white;
  border: 0;
`;

export const BlueShadowButton = styled(StyledButton)`
  border-radius: 4px;
  background: linear-gradient(180deg, #0c9cff 0%, #0077ca 100%);
  box-shadow: 0 2px 4px 0 rgba(24, 118, 210, 0.3);
  text-transform: none;
  color: white;
  border-radius: 4px;
`;

export const SmallCountButton = styled(StyledButton)`
  background: ${(props) => props.theme.palette.primary.main};
  text-transform: none;
  color: white;
  font-size: 14px;
  padding: 5px 12px;
  min-width: fit-content;
  border-radius: 4px;
  height: 24px;
`;

export const SpaceBetweenContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !gutterCSSProps[prop],
})`
  display: flex;
  justify-content: space-between;
  ${GutterCSS};
`;

export const GreenCheckInButton = styled(Button)`
  ${GreenButtonCSS}
  padding: ${(props) => props.theme.spacing(0.75)}rem
    ${(props) => props.theme.spacing(4)}rem;
`;

export const StyledCheckbox = styled(Checkbox).withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})`
  &.MuiCheckbox-root:hover,
  &.Mui-checked {
    color: ${(props) => getColorFromKey(props.color)};
  }
`;
const StyledRadioProps = { size: true, color: true };
export const StyledRadio = styled(Radio).withConfig({
  shouldForwardProp: (prop) => !StyledRadioProps[prop],
})`
  .MuiSvgIcon-root {
    width: ${(props) => props.theme.typography[props.size || 'body1'].fontSize};
    height: ${(props) => props.theme.typography[props.size || 'body1'].fontSize};
  }
  &.MuiCheckbox-root:hover,
  &.Mui-checked {
    color: ${(props) => getColorFromKey(props.color)};
  }
`;

export const elongatedButtonCSS = css`
  padding: 0.4rem 2rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 0.5rem 3.5rem;
  }
`;

export const ElongatedButton = styled(StyledButton)`
  ${elongatedButtonCSS};
`;

export const HeaderSizeCSS = css`
  width: 16px;
  height: 16px;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 18px;
    height: 18px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 20px;
    height: 20px;
  }
`;

export const TiniestSizeCSS = css`
  width: 16px;
  height: 16px;
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 18px;
    height: 18px;
  }
`;

export const TinierSizeCSS = css`
  width: 18px;
  height: 18px;

  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 24px;
    height: 24px;
  }
`;

export const TinySizeCSS = css`
  width: 24px;
  height: 24px;

  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 28px;
    height: 28px;
  }
`;

export const SmallestSizeCSS = css`
  width: 28px;
  height: 28px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 32px;
    height: 32px;
  }
`;

export const SmallerSizeCSS = css`
  width: 35px;
  height: 35px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 40px;
    height: 40px;
  }
`;

export const SmallSizeCSS = css`
  width: 40px;
  height: 40px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 50px;
    height: 50px;
  }
`;

export const SmallMediumSizeCSS = css`
  width: 48px;
  height: 48px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 60px;
    height: 60px;
  }
`;

export const MediumSizeCSS = css`
  width: 60px;
  height: 60px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 80px;
    height: 80px;
  }
`;

export const BigSizeCSS = css`
  width: 100px;
  height: 100px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 120px;
    height: 120px;
  }
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 140px;
    height: 140px;
  }
`;

export const Big2SizeCSS = css`
  width: 100px;
  height: 100px;
  ${(props) => props.theme.breakpoints.up('xl')} {
    width: 124px;
    height: 124px;
  }
`;

export const BiggerSizeCSS = css`
  width: 140px;
  height: 140px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 175px;
    height: 175px;
  }
`;

export const BiggestSizeCSS = css`
  width: 200px;
  height: 200px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 240px;
    height: 240px;
  }
`;

export const Fixed12pxSizeCSS = css`
  width: 12px;
  height: 12px;
`;

export const Fixed16pxSizeCSS = css`
  width: 16px;
  height: 16px;
`;

export const Fixed18pxSizeCSS = css`
  width: 18px;
  height: 18px;
`;

export const Fixed24pxSizeCSS = css`
  width: 24px;
  height: 24px;
`;

export const Fixed28pxSizeCSS = css`
  width: 28px;
  height: 28px;
`;

export const Fixed32pxSizeCSS = css`
  width: 32px;
  height: 32px;
`;

export const Fixed40pxSizeCSS = css`
  width: 40px;
  height: 40px;
`;

export const Fixed48pxSizeCSS = css`
  width: 48px;
  height: 48px;
`;

export const ImageSizesCSS = {
  header: HeaderSizeCSS,
  tiniest: TiniestSizeCSS,
  tinier: TinierSizeCSS,
  tiny: TinySizeCSS,
  smallest: SmallestSizeCSS,
  smaller: SmallerSizeCSS,
  small: SmallSizeCSS,
  smallMedium: SmallMediumSizeCSS,
  medium: MediumSizeCSS,
  big: BigSizeCSS,
  big2: Big2SizeCSS,
  bigger: BiggerSizeCSS,
  biggest: BiggestSizeCSS,
  fixed12px: Fixed12pxSizeCSS,
  fixed16px: Fixed16pxSizeCSS,
  fixed18px: Fixed18pxSizeCSS,
  fixed24px: Fixed24pxSizeCSS,
  fixed28px: Fixed28pxSizeCSS,
  fixed32px: Fixed32pxSizeCSS,
  fixed40px: Fixed40pxSizeCSS,
  fixed48px: Fixed48pxSizeCSS,
};

export const RedBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${(props) => props.theme.palette.red.main};
    color: white;
    top: 4px;
    right: 2px;
  }
`;

export const BottomCenterBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${(props) => getColorFromKey(props.color)};
    color: white;
    top: auto;
    right: auto;
    bottom: -50%;
    left: -5%;
  }
`;

const StyledImageProps = { ...gutterCSSProps };
export const StyledImage = styled.img.withConfig({
  shouldForwardProp: (prop) => !StyledImageProps[prop],
})`
  ${(props) => props.size && IconSizesCSS[props.size]};
  ${GutterCSS};
`;

export const HideScrollbarCSS = css`
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: block; /* Safari and Chrome */
  }
`;

export const MeshInPageCSS = css`
  padding: ${(props) => props.theme.spacing(2)}rem 0;
  margin: 0 auto;
  ${MaxWidthCSS}
`;

// --------------------- icons --------------------------

export const BigIconSizeCSS = css`
  width: 72px;
  height: 72px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 80px;
    height: 80px;
  }
`;

export const MediumIconSizeCSS = css`
  width: 50px;
  height: 50px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 64px;
    height: 64px;
  }
`;

export const FixedSmallestIconSizeCSS = css`
  width: 24px;
  height: 24px;
`;

export const FixedMediumIconSizeCSS = css`
  width: 50px;
  height: 50px;
`;

export const SmallIconSizeCSS = css`
  width: 24px;
  height: 24px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 32px;
    height: 32px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 36px;
    height: 36px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 42px;
    height: 42px;
  }
`;

export const SmallerIconSizeCSS = css`
  width: 20px;
  height: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 28px;
    height: 28px;
  }
`;

export const SmallestIconSizeCSS = css`
  width: 18px;
  height: 18px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 24px;
    height: 24px;
  }
`;

export const HeaderIconSizeCSS = css`
  width: 16px;
  height: 16px;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 18px;
    height: 18px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 20px;
    height: 20px;
  }
`;

export const HeaderSmallIconSizeCSS = css`
  width: 14px;
  height: 14px;

  ${(props) => props.theme.breakpoints.up('md')} {
    width: 16px;
    height: 16px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 18px;
    height: 18px;
  }
`;

export const TinyIconSizeCSS = css`
  width: 14px;
  height: 14px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 16px;
    height: 16px;
  }
`;
export const TinierIconSizeCSS = css`
  width: 12px;
  height: 12px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 14px;
    height: 14px;
  }
`;

export const TinierFixedIconSizeCSS = css`
  width: 12px;
  height: 12px;
`;

export const TinyFixedIconSizeCSS = css`
  width: 16px;
  height: 16px;
`;

export const TiniestIconSizeCSS = css`
  width: 8px;
  height: 8px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 10px;
    height: 10px;
  }
`;

export const TiniestFixedIconSizeCSS = css`
  width: 10px;
  height: 10px;
`;

export const Fixed8pxIconSizeCSS = css`
  width: 8px;
  height: 8px;
`;

export const Fixed10pxIconSizeCSS = css`
  width: 10px;
  height: 10px;
`;

export const Fixed12pxIconSizeCSS = css`
  width: 12px;
  height: 12px;
`;

export const Fixed14pxIconSizeCSS = css`
  width: 14px;
  height: 14px;
`;

export const Fixed16pxIconSizeCSS = css`
  width: 16px;
  height: 16px;
`;

export const Fixed18pxIconSizeCSS = css`
  width: 18px;
  height: 18px;
`;

export const Fixed20pxIconSizeCSS = css`
  width: 20px;
  height: 20px;
`;

export const Fixed22pxIconSizeCSS = css`
  width: 22px;
  height: 22px;
`;

export const Fixed24pxIconSizeCSS = css`
  width: 24px;
  height: 24px;
`;

export const Fixed26pxIconSizeCSS = css`
  width: 26px;
  height: 26px;
`;

export const Fixed28pxIconSizeCSS = css`
  width: 28px;
  height: 28px;
`;

export const Fixed30pxIconSizeCSS = css`
  width: 30px;
  height: 30px;
`;

export const Fixed32pxIconSizeCSS = css`
  width: 32px;
  height: 32px;
`;

export const Fixed40pxIconSizeCSS = css`
  width: 40px;
  height: 40px;
`;
export const Fixed54pxIconSizeCSS = css`
  width: 54px;
  height: 54px;
`;

export const Fixed100pxIconSizeCSS = css`
  width: 100px;
  height: 100px;
`;

export const IconSizesCSS = {
  tiniestFixed: TiniestFixedIconSizeCSS,
  tiniest: TiniestIconSizeCSS,
  tinier: TinierIconSizeCSS,
  tinierFixed: TinierFixedIconSizeCSS,
  tiny: TinyIconSizeCSS,
  tinyFixed: TinyFixedIconSizeCSS,
  headerSmall: HeaderSmallIconSizeCSS,
  header: HeaderIconSizeCSS,
  smallest: SmallestIconSizeCSS,
  smaller: SmallerIconSizeCSS,
  small: SmallIconSizeCSS,
  medium: MediumIconSizeCSS,
  big: BigIconSizeCSS,
  fixedMedium: FixedMediumIconSizeCSS,
  fixedSmallest: FixedSmallestIconSizeCSS,
  fixed8px: Fixed8pxIconSizeCSS,
  fixed10px: Fixed10pxIconSizeCSS,
  fixed12px: Fixed12pxIconSizeCSS,
  fixed14px: Fixed14pxIconSizeCSS,
  fixed16px: Fixed16pxIconSizeCSS,
  fixed18px: Fixed18pxIconSizeCSS,
  fixed20px: Fixed20pxIconSizeCSS,
  fixed22px: Fixed22pxIconSizeCSS,
  fixed24px: Fixed24pxIconSizeCSS,
  fixed26px: Fixed26pxIconSizeCSS,
  fixed28px: Fixed28pxIconSizeCSS,
  fixed30px: Fixed30pxIconSizeCSS,
  fixed32px: Fixed32pxIconSizeCSS,
  fixed40px: Fixed40pxIconSizeCSS,
  fixed54px: Fixed54pxIconSizeCSS,
  fixed100px: Fixed100pxIconSizeCSS,
};

export const IconPadding = {
  none: '0',
  smallest: '1px',
  smaller: '2px',
  small: '4px',
  big: '6px',
  large: '8px',
  xLarge: '10px',
  xxLarge: '12px',
};

export const StrikethroughCSS = css`
  &:after {
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
    background: #000000;
    content: '';
    width: 100%;
    display: block;
  }
`;

export const StrikethroughCSS1 = css`
  text-decoration: ${(props) => (props.completed ? 'line-through' : 'inherit')};
`;

export const StrikethroughCSS2 = css`
  text-decoration: line-through;
`;

export const CircularIconCSSProps = {
  borderColor: true,
  backgroundColor: true,
};
export const CircularIconCSS = css`
  border-radius: 50%;
  border: 1px solid
    ${(props) =>
      getColorFromKey(
        props.borderColor
          ? props.borderColor
          : props.backgroundColor !== 'white'
            ? props.backgroundColor
            : 'textSecondary'
      )} !important;
`;

const SquarIconCSSProps = { backgroundColor: true };
const SquareIconCSS = css`
  border: 1px solid ${(props) => getColorFromKey(props.backgroundColor)} !important;
  // padding: 4px !important;
  border-radius: 0;
`;

export const RoundButton = styled(StyledButton)`
  border-radius: 20px !important;
`;

export const DiscoverDetailSmallCardPadding = css`
  padding: ${(props) => props.theme.spacing(3)}rem;
`;

const StyledIconCSSProps = {
  ...CircularIconCSSProps,
  // color: true,
  hoverColor: true,
  // size: true,
  backgroundColor: true,
  square: true,
  padding: true,
  ...gutterCSSProps,
};
const StyledIconCSS = css`
  ${(props) => props.circular && CircularIconCSS};
  color: ${(props) => getCustomColorString(props.color, props.theme)} !important;
  ${(props) =>
    props.hoverColor &&
    css`
      &:hover {
        color: ${(props) => getCustomColorString(props.hoverColor, props.theme)} !important;
      }
    `}
  ${(props) => props.size && IconSizesCSS[props.size]};
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${getColorFromKey(props.backgroundColor)};
    `};
  ${(props) => props.square && SquareIconCSS};
  padding: 0px;
  ${(props) =>
    props.padding &&
    css`
      padding: ${IconPadding[props.padding]};
    `};
  ${GutterCSS};
`;

const NewStyledIconCSSProps = {
  circular: true,
  // color: true,
  // size: true,
  backgroundColor: true,
  square: true,
  padding: true,
  ...gutterCSSProps,
};
const NewStyledIconCSS = css`
  ${(props) =>
    props.circular &&
    css`
      border-radius: 50%;
    `};
  color: ${(props) => getCustomColorString(props.color, props.theme)} !important;
  svg {
    ${(props) => props.size && IconSizesCSS[props.size]};
  }
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${getColorFromKey(props.backgroundColor)};
    `};
  ${(props) => props.square && SquareIconCSS};
  padding: 0px;
  ${(props) =>
    props.padding &&
    css`
      padding: ${IconPadding[props.padding]};
    `};
  ${GutterCSS};
`;

const StyledIconButtonProps = {
  ...StyledIconCSSProps,
  cursor: true,
};
export const StyledIconButton = styled(IconButton).withConfig({
  shouldForwardProp: (prop) => !StyledIconButtonProps[prop],
})`
  ${StyledIconCSS}
  ${RippleCSS};
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  &:disabled {
    color: #e1e1e1;
  }
`;

const NewStyledIconButtonProps = {
  ...NewStyledIconCSSProps,
  height: true,
  hoverBackgroundColor: true,
  hoverIconColor: true,
};

export const NewStyledIconButton = styled(IconButton).withConfig({
  shouldForwardProp: (prop) => !NewStyledIconButtonProps[prop],
})`
  ${NewStyledIconCSS}
  ${RippleCSS};
  cursor: pointer;
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  &.MuiIconButton-root:hover {
    ${(props) =>
      props.hoverBackgroundColor &&
      css`
        background-color: ${props.hoverBackgroundColor};
      `}
    ${(props) =>
      props.hoverIconColor &&
      css`
        svg {
          fill: ${props.hoverIconColor};
        }
      `}
  }
`;

export const StyledIconContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'color' && !StyledIconCSSProps[prop],
})`
  ${StyledIconCSS}
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.color &&
    css`
      svg {
        fill: ${getColorFromKey(props.color)};
      }
    `}
`;

export const NewStyledIconContainer = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'size',
})`
  color: ${(props) => getCustomColorString(props.color, props.theme)} !important;

  svg {
    ${(props) => props.size && IconSizesCSS[props.size]};
    fill: ${(props) => getCustomColorString(props.color, props.theme)};
  }
`;

export const StyledSvg = styled.svg.withConfig({
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'size',
})`
  ${(props) => props.size && IconSizesCSS[props.size]};
  color: ${(props) => getCustomColorString(props.color, props.theme)} !important;
  // display: flex;
  // align-items: center;
  // justify-content: center;
`;

export const StyledTextField = styled(TextField).withConfig({
  shouldForwardProp: (prop) => !gutterCSSProps[prop],
})`
  ${GutterCSS};
`;

// export const CircularIconButton = styled(StyledIconButton)`
//   border-radius: 50%;
//   border: 1px solid ${props => getCustomColorString(props.color, props.theme)} !important;
//   padding: 5px !important;
//   ${props => IconSizesCSS[props.size || "small"]};
// `;

// ------------------------------------------------------

// both do same thing, hyphens is new and is not supported in some browsers,
// so in case hyphens doesn't work than word-break works
export const wordBreakCSS = css`
  word-break: break-word;
  hyphens: auto;
`;

const styledTypographyProps = [
  'texttransform',
  // 'weight',
  'customColor',
  // 'color',
  'truncate',
  'clickable',
  'cursor',
  'wordBreak',
  'textDecorationColor',
  'lineheight',
  'strikeThrough',
  'textalign',
  'fontsize',
  'leftGutter',
  'rightGutter',
  'topGutter',
  'bottomGutter',
].reduce((prev, current) => ({ ...prev, [current]: true }), {});
export const StyledTypography = styled(Typography).withConfig({
  shouldForwardProp: (prop) => !styledTypographyProps[prop],
})`
  ${(props) =>
    props.texttransform &&
    css`
      text-transform: ${props.texttransform};
    `};
  ${GutterCSS};
  font-weight: ${(props) => props.weight || 'normal'};
  ${(props) =>
    props.customColor &&
    css`
      color: ${getColorFromKey(props.customColor)};
    `};
  ${(props) =>
    props.color &&
    css`
      color: ${getColorFromKey(props.color)};
    `}
  ${(props) => props.truncate && TruncateCSS};
  cursor: ${(props) => (props.onClick || props.clickable ? 'pointer' : 'inherit')};
  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${(props) => props.wordBreak && wordBreakCSS};
  ${(props) =>
    props.textDecorationColor &&
    css`
      text-decoration-color: ${getColorFromKey(props.textDecorationColor)} !important;
    `};
  ${(props) =>
    props.lineheight &&
    css`
      line-height: ${props.lineheight};
    `}
  ${(props) =>
    props.strikeThrough &&
    css`
      text-decoration: line-through;
    `};
  ${(props) =>
    props.textalign &&
    css`
      text-align: ${props.textalign};
    `};
  ${(props) =>
    props.fontsize &&
    css`
      font-size: ${props.fontsize};
    `};
  ${(props) =>
    props.bottomGutter &&
    css`
      margin-bottom: ${props.bottomGutter};
    `};
  ${(props) =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `};
  ${(props) =>
    props.display &&
    css`
      display: ${props.display};
    `};
  ${(props) =>
    props.lineClamp &&
    css`
      -webkit-line-clamp: ${props.lineClamp};
      -webkit-box-orient: vertical;
    `};
`;

const StyledDividerProps = {
  ...gutterCSSProps,
  length: true,
  thickness: true,
  // color: true
};

export const StyledDivider = styled(Divider).withConfig({
  shouldForwardProp: (prop) => !StyledDividerProps[prop],
})`
  ${GutterCSS};
  ${(props) =>
    props.length &&
    css`
      width: ${props.theme.spacing(props.length)}rem;
    `};
  ${(props) =>
    props.thickness &&
    css`
      height: ${props.theme.spacing(props.thickness)}rem;
    `};
  ${(props) =>
    props.color &&
    css`
      background-color: ${getColorFromKey(props.color)};
    `};
`;

// material UI divider using Hr renders inconsistently
export const Divider1 = styled.div.withConfig({
  shouldForwardProp: (prop) => !gutterCSSProps[prop] && prop !== 'sideMargin',
})`
  border-bottom: 1px solid ${(props) => props.theme.palette.borders.lighterGrey};
  width: auto;
  ${GutterCSS};
  ${(props) =>
    props.sideMargin &&
    css`
      margin-left: ${props.sideMargin}rem;
      margin-right: ${props.sideMargin}rem;
    `};
`;

export const VerticalDivider = styled.div.withConfig({
  shouldForwardProp: (prop) => !gutterCSSProps[prop] && prop !== 'height',
})`
  border-right: 1px solid ${(props) => props.theme.palette.borders.lighterGrey};
  height: ${(props) => props.height};
  ${GutterCSS};
`;

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#595E6A',
    fontSize: '12px',
    padding: '8px',
    whiteSpace: 'pre-line',
  },
}))(Tooltip);

export const HiddenContentCSS = css`
  filter: blur(8px);
`;

export const HiddenContentConditionCSSProps = {
  hiddenContent: true,
};
export const HiddenContentConditionCSS = css`
  ${(props) => props.hiddenContent && HiddenContentCSS};
`;

const ColoredCircularProgressProps = {
  opacity: true,
  // size: true,
  ...gutterCSSProps,
  ...PaddingCSSProps,
};
export const ColoredCircularProgress = styled(CircularProgress).withConfig({
  shouldForwardProp: (prop) => !ColoredCircularProgressProps[prop],
})`
  color: ${(props) => getColorFromKey(props.color)};
  ${(props) =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `};
  ${(props) => props.size && ImageSizesCSS[props.size]};
  ${GutterCSS};
  ${PaddingCSS};
`;

export const TwoButtonsContainer = styled.div`
  text-align: center;
`;

export const ColoredSpan = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})`
  color: ${(props) => getColorFromKey(props.color)};
`;

const StyledLinkProps = {
  weight: true,
  ...gutterCSSProps,
  display: true,
  primaryColorOnHover: true,
};
export const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !StyledLinkProps[prop],
})`
  font-weight: ${(props) => props.weight || 'normal'};
  ${GutterCSS};
  display: ${(props) => props.display || 'flex'};
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.primaryColorOnHover &&
    css`
      &:hover {
        color: ${(props) => props.theme.palette.primary.main};
      }
    `}
`;

export const meshTeamDropdownStyles = css`
  .MuiSelect-root {
    padding-right: 28px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 0.875rem;
  }
`;

export const getMeshDropdownStyles = ({
  paddingRight = '28px',
  paddingTop = '4px',
  paddingBottom = '4px',
  fontSize = '0.875rem',
}) => css`
  .MuiSelect-root {
    padding-right: ${paddingRight};
    padding-top: ${paddingTop};
    padding-bottom: ${paddingBottom};
    font-size: ${fontSize};
  }
`;

export const leaderboardDropdownStyles = css`
  .MuiSelect-root {
    // padding-right: 28px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: ${(props) => props.theme.typography.body2.fontSize};
  }
`;

export const TeamIconContainer = styled.span`
  display: inline-block;
  margin-left: 0.85rem;
  margin-top: 4px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-55%);
  svg {
    font-size: 1rem;
    color: #555;
  }
`;

export const editorStyles = css`
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 0;
  min-height: inherit;
  background: transparent;
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  max-width: 100%;
  display: inline-block;
`;

export const editorPlaceholderStyles = css`
  position: block;
  margin: 0;
  font-size: ${(props) => props.theme.typography.caption.fontSize};
`;

export const commentEditorStyles = css`
  font-size: 14px;
  padding-right: 2rem;
  height: auto;
  min-height: auto;
  max-height: 10rem;
  overflow-y: scroll;
  border: 0;
`;

export const commentEditorStylesOKRPanel = css`
  overflow-y: scroll;
  font-size: 14px;
  padding-right: 2rem;
  height: auto;
  min-height: auto;
  max-height: 10rem;
  border: 0;
`;

export const commentEditorStyles2 = css`
  font-size: 14px;
  // padding: 4px 16px;
  height: auto;
  min-height: auto;
  max-height: 12rem;
  border: 0;
  background: transparent;
  overflow-y: scroll;
`;

export const commentPlaceholderStyles = css`
  font-size: 14px;
  margin: ${(props) => props.theme.spacing(0.5)}rem ${(props) => props.theme.spacing(1)}rem;
`;

export const commentPlaceholderStyles2 = css`
  font-size: 14px;
  padding: 6px 16px;
  margin: ${(props) => props.theme.spacing(0.5)}rem ${(props) => props.theme.spacing(1)}rem;
`;

export const EmojiFontCSS = css`
  font-family: Segoe UI Emoji;
`;

/* TODO: fix using $props */
export const SearchSelectCSS = css`
  width: 100%;
  display: inline-block;
  .react-select-custom__control {
    // border: 0;
    ${(props) =>
      props?.customCSS?.border !== 'default' &&
      css`
        border: 2px solid ${(props) => props.theme.palette.borders.lightGrey};
      `}
    ${(props) =>
      props?.customCSS?.paddingLeft !== 'default' &&
      css`
        padding-left: ${props.theme.space.horizontal.normal};
      `}
    padding-right: 10%;
    ${(props) =>
      props?.customCSS?.border !== 'default' &&
      css`
        border-radius: 20px;
      `}
  }
  .react-select-custom__indicators {
    display: none;
  }
`;

export const VerticalAlignComponent = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'align',
})`
  vertical-align: ${(props) => props.align || 'middle'};
`;

export const FeedCardMobileCSS = css`
  margin-bottom: 2px;
`;

export const StyledToggleButton = styled(ToggleButton)`
  // padding: 0px 14px 0px 14px;
  background-color: white;
  &:hover {
    background-color: #fafafa;
  }
  border: 0;
  border-radius: 0;
  &.MuiToggleButton-root.Mui-selected {
    background-color: white;
    &:hover {
      background-color: #fafafa;
    }
  }
  &.MuiToggleButton-root {
    text-transform: none;
  }
  padding: 0.6rem 1.2rem;
`;

export const VerticallyCenterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const hideNumberInputArrowsCSS = css`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const StyledGridProps = {
  ...gutterCSSProps,
  ...PaddingCSSProps,
  ...borderCSSProps,
};
export const StyledGrid = styled(Grid).withConfig({
  shouldForwardProp: (prop) => !StyledGridProps[prop],
})`
  ${GutterCSS};
  ${PaddingCSS};
  ${borderCSS};
`;

// It was a common requirement that the padding should be less and it should
// have a grey background
export const MeshOutlineButtonCSS = css`
  border-color: ${(props) => props.theme.palette.borders.lightGrey};
  padding: 0.15rem 0.5rem calc(0.15rem + 2px) 0.5rem;
`;

export const EditableTextFieldCSS = css`
  fieldset {
    border-width: 0px;
  }
  .Mui-focused fieldset,
  &:hover fieldset {
    border-width: 1px;
    border-color: ${(props) => props.theme.palette.borders.lightGrey} !important;
  }
`;

const FlexCSSProps = {
  flexcontainer: true,
  flexdirection: true,
  flexwrap: true,
  justifycontent: true,
  alignitems: true,
  flex: true,
  flexgrow: true,
  flexbasis: true,
  flexshrink: true,
};
export const FlexCSS = css`
  ${(props) =>
    props.flexcontainer &&
    css`
      display: flex;
    `};
  ${(props) =>
    props.flexdirection &&
    css`
      flex-direction: ${props.flexdirection};
    `};
  ${(props) =>
    props.flexwrap &&
    css`
      flex-wrap: ${props.flexwrap};
    `};
  ${(props) =>
    props.justifycontent &&
    css`
      justify-content: ${props.justifycontent};
    `};
  ${(props) =>
    props.alignitems &&
    css`
      align-items: ${props.alignitems};
    `};
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  ${(props) =>
    props.flexgrow &&
    css`
      flex-grow: ${props.flexgrow};
    `};
  ${(props) =>
    props.flexbasis &&
    css`
      flex-basis: ${props.flexbasis};
    `};
  ${(props) =>
    props.flexshrink &&
    css`
      flex-shrink: ${props.flexshrink};
    `};
`;

export const VerticalFlexContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !FlexCSSProps[prop] && !gutterCSSProps[prop],
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  ${FlexCSS};
  ${GutterCSS};
`;

export const HeightCSS = css`
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;

const StyledBoxProps = {
  ...gutterCSSProps,
  ...PaddingCSSProps,
  ...FlexCSSProps,
  ...borderCSSProps,
  clickable: true,
  maxWidthCSS: true,
  height: true,
};
export const StyledBox = styled(Box).withConfig({
  shouldForwardProp: (prop) => !StyledBoxProps[prop],
})`
  ${GutterCSS};
  ${PaddingCSS};
  ${FlexCSS};
  ${borderCSS};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
  ${(props) => (props.maxWidthCSS ? MaxWidthCSS : '')};
  ${HeightCSS}
`;

const StyledPaperProps = {
  ...gutterCSSProps,
  ...PaddingCSSProps,
  ...FlexCSSProps,
  bgColor: true,
  clickable: true,
  showBorder: true,
  ...borderCSSProps,
};
export const StyledPaper = styled(Paper).withConfig({
  shouldForwardProp: (prop) => !StyledPaperProps[prop],
})`
  ${GutterCSS};
  ${PaddingCSS};
  ${FlexCSS};
  ${(props) => props.bgColor && BackgroundCSS}
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
  ${(props) =>
    props.showBorder &&
    css`
      border: 1px solid ${props.theme.palette.borders.lighterGrey};
    `};
  ${borderCSS};
`;
const StyledBorderedPaperProps = {
  overFlow: true,
  marginBottom: false,
};
export const StyledBorderedPaper = styled(StyledPaper).withConfig({
  shouldForwardProp: (prop) => !StyledBorderedPaperProps[prop],
})`
  box-shadow: ${(props) => props.theme.palette.shadows.paper};
  border-radius: 8px;
  // border: 1px solid ${theme.palette.borders.lighterGrey};
  ${(props) =>
    props.overFlow &&
    css`
      overflow: ${props.overFlow};
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`;

const StyledLinearProgressProps = {
  ...gutterCSSProps,
  barColor: true,
  width: true,
  height: true,
  fullWidth: true,
};
export const StyledLinearProgress = styled(LinearProgress).withConfig({
  shouldForwardProp: (prop) => !StyledLinearProgressProps[prop],
})`
  ${GutterCSS};
  .MuiLinearProgress-bar1Determinate {
    ${(props) =>
      props.barColor &&
      css`
        background: ${(props) => getColorFromKey(props.barColor)};
      `}
  }
  &.MuiLinearProgress-root {
    display: inline-block;
    width: ${(props) => (props.width ? props.width : '75%')};
    margin-right: 0.5rem;
    height: ${(props) => (props.height ? props.height : '0.5rem')};
    border-radius: 4px;
    ${(props) =>
      props.fullWidth &&
      css`
        width: 100%;
      `}
  }
  &.MuiLinearProgress-colorPrimary {
    background: #eaeaea;
  }
  // this style is not working currently but adding it just in case for extra safety
  .MuiLinearProgress-bar2Determinate {
    background: #eaeaea;
  }
`;

export const StyledTableCell = styled(TableCell).withConfig({
  shouldForwardProp: (prop) => !borderCSSProps[prop],
})`
  ${borderCSS};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};
`;

export const StyledTableRow = styled(TableRow)``;

export const RelativePositionFullHeightContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const CustomStyledHoverIconButton = styled(StyledIconButton)`
  &.MuiIconButton-root.Mui-disabled {
    background-color: #008ef0;
  }
  &.MuiButtonBase-root {
    width: ${(props) => (props.size === 'big' ? '28px' : '24px')};
    height: ${(props) => (props.size === 'big' ? '28px' : '24px')};
  }
  &:hover {
    background-color: #008ef0;
    border-color: #008ef0 !important;
  }
`;

const StyledIconSpanProps = {
  // size: true,
  // color: true,
  ...PaddingCSSProps,
  rotate: true,
  hoverColor: true,
  opacity: true,
  ...gutterCSSProps,
};
const StyledIconSpan = styled.span.withConfig({
  shouldForwardProp: (prop) => !StyledIconSpanProps[prop],
})`
  svg {
    ${(props) => IconSizesCSS[props.size || 'smallest']};
    fill: ${(props) => getColorFromKey(props.color)};
    ${PaddingCSS};
    ${(props) =>
      props.rotate &&
      css`
        transform: rotate(${props.rotate});
      `}
  }
  ${CustomStyledHoverIconButton}:hover & {
    svg {
      fill: ${(props) => getColorFromKey(props.hoverColor)} !important;
    }
  }
  display: inline-flex;
  align-items: center;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `};
  ${(props) =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `};
  ${GutterCSS};
`;

function StyledIcon({ children, ...props }) {
  return <StyledIconSpan {...props}> {children} </StyledIconSpan>;
}
export function withStyledHoverIcon(WrappedIcon) {
  return forwardRef((props, ref) => (
    <StyledIcon {...props} ref={ref}>
      <WrappedIcon />
    </StyledIcon>
  ));
}

import { createSlice } from '@reduxjs/toolkit';
import { camelCase } from 'lodash';
import { useSelector } from 'react-redux';

import {
  VIEWS_DYNAMIC_TYPES_VALUES,
  VIEWS_FILTER_KEYS,
  VIEWS_VISIBILITY_TYPES_VALUES,
} from 'constants/ProjectConstants';

// export const VIEWS_SCREENS = {
//   MANAGE_VIEWS: 'MANAGE_VIEWS',
//   EDIT_CREATE_VIEW: 'EDIT_CREATE_VIEW'
// };
export const VIEWS_SUB_SCREENS = {
  NONE: 'NONE',
  SAVE: 'SAVE',
  UPLOAD_CSV: 'UPLOAD_CSV',
};
export const VIEWS_ACTIONS = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  CLONE: 'CLONE',
  EDIT_CUSTOM_ACCESS: 'EDIT_CUSTOM_ACCESS',
  // RENAME: 'RENAME'
};

const initialState = {
  viewsPanelState: {
    open: null,
    onSuccess: null,
    onCancel: null,
    fetchData: null,
    constraints: {},
    managePanelConstraints: {},
  },
  appRefreshCount: 0,
  id: null,
  name: '',
  visibilityType: VIEWS_VISIBILITY_TYPES_VALUES.PRIVATE,
  activeTab: VIEWS_FILTER_KEYS.BASE_COHORT,
  baseCohort: {
    uuid: null,
  },
  cohortEmployees: null,
  query: {},
  isSaveViewModalOpen: false,
  isViewStatic: 0,
  viewsScreen: null,
  viewsSubScreen: null,
  csvUploadEmployeeList: [],
  uploadedFileName: null,
  selectedView: null,
  selectedViewComplete: null,
  selectedModuleViews: null,
  viewAction: null,
  filterSidebarState: [],
  saveViewErrorMessage: null,
};

export const viewsSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setShowViewsPanel: (state, action) => {
      const { onSuccess, onCancel, fetchData, constraints = {}, managePanelConstraints = {} } = action.payload;
      state.viewsPanelState = {
        open: true,
        onSuccess,
        onCancel,
        fetchData,
        constraints,
        managePanelConstraints,
      };
    },
    resetViewsPanel: (state) => {
      state.viewsPanelState = initialState.viewsPanelState;
      state.viewAction = null;
    },
    setAppRefreshCount: (state) => {
      state.appRefreshCount += 1;
    },
    setViewName: (state, action) => {
      state.name = action.payload;
    },
    setViewVisibility: (state, action) => {
      state.visibilityType = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setBaseCohort: (state, action) => {
      const baseCohortId = action.payload;
      state.baseCohort.uuid = baseCohortId;
      if (['-2', '-3'].includes(baseCohortId)) {
        state.visibilityType = VIEWS_VISIBILITY_TYPES_VALUES.PRIVATE;
      }
      // temporarily reset query after base cohort selection
      state.query = {};
      state.cohortEmployees = [];
    },
    setCheckedManagers: (state, action) => {
      state.query.managers = action.payload;
      if (state.query.managers?.length === 0) {
        delete state.query.managers;
      }
    },
    setCheckedGrades: (state, action) => {
      state.query.grade = action.payload.map((p) => ({
        id: p.uuid,
        displayName: p.name,
      }));
      if (state.query.grade?.length === 0) {
        delete state.query.grade;
      }
    },
    setCheckedRoles: (state, action) => {
      state.query.roleId = action.payload.map((p) => ({
        id: p.uuid,
        displayName: p.name,
      }));
      if (state.query.roleId?.length === 0) {
        delete state.query.roleId;
      }
    },
    setCheckedFunctions: (state, action) => {
      state.query.functionId = action.payload.map((p) => ({
        id: p.uuid,
        displayName: p.name,
      }));
      if (state.query.functionId?.length === 0) {
        delete state.query.functionId;
      }
    },
    setCheckedEthnicity: (state, action) => {
      state.query.ethnicity = action.payload.map((p) => ({
        id: p.displayName,
        displayName: p.displayName,
      }));
      if (state.query.ethnicity?.length === 0) {
        delete state.query.ethnicity;
      }
    },
    setCheckedGender: (state, action) => {
      state.query.gender = action.payload.map((p) => ({
        id: p.displayName,
        displayName: p.displayName,
      }));
      if (state.query.gender?.length === 0) {
        delete state.query.gender;
      }
    },
    setCheckedLocation: (state, action) => {
      state.query.location = action.payload.map((p) => ({
        id: p.displayName,
        displayName: p.displayName,
      }));
      if (state.query.location?.length === 0) {
        delete state.query.location;
      }
    },
    setGoalStartTime: (state, action) => {
      if (!state.query.goals) {
        state.query.goals = {};
      }
      if (!state.query.goals.dateRange) {
        state.query.goals.dateRange = {};
      }
      state.query.goals.dateRange.startTS = action.payload;
    },
    setGoalEndTime: (state, action) => {
      if (!state.query.goals) {
        state.query.goals = {};
      }
      if (!state.query.goals.dateRange) {
        state.query.goals.dateRange = {};
      }
      state.query.goals.dateRange.endTS = action.payload;
    },
    setGoalMinRange: (state, action) => {
      if (!state.query.goals) {
        state.query.goals = {};
      }
      if (!state.query.goals.data) {
        state.query.goals.data = [{}];
      }
      const range = state.query.goals.data[0];
      range.minValue = action.payload;
    },
    setGoalMaxRange: (state, action) => {
      if (!state.query.goals) {
        state.query.goals = {};
      }
      if (!state.query.goals.data) {
        state.query.goals.data = [{}];
      }
      const range = state.query.goals.data[0];
      range.maxValue = action.payload;
    },
    setGoalStatus: (state, action) => {
      if (!state.query.goals) {
        state.query.goals = {};
      }
      if (!state.query.goals.goalStatus) {
        state.query.goals.goalStatus = [];
      }
      const index = state.query.goals.goalStatus.findIndex((g) => g.filterValue === action.payload);
      if (index !== -1) {
        state.query.goals.goalStatus.splice(index, 1);
      } else {
        state.query.goals.goalStatus.push({ filterValue: action.payload });
      }
      if (state.query.goals.goalStatus.length === 0) {
        delete state.query.goals.goalStatus;
      }
      if (Object.keys(state.query.goals).length === 0) {
        delete state.query.goals;
      }
    },
    setOneOnOneStartTime: (state, action) => {
      if (!state.query.oneOnOne) {
        state.query.oneOnOne = {};
      }
      if (!state.query.oneOnOne.dateRange) {
        state.query.oneOnOne.dateRange = {};
      }
      state.query.oneOnOne.dateRange.startTS = action.payload;
    },
    setOneOnOneEndTime: (state, action) => {
      if (!state.query.oneOnOne) {
        state.query.oneOnOne = {};
      }
      if (!state.query.oneOnOne.dateRange) {
        state.query.oneOnOne.dateRange = {};
      }
      state.query.oneOnOne.dateRange.endTS = action.payload;
    },
    setOneOnOneMinRange: (state, action) => {
      if (!state.query.oneOnOne) {
        state.query.oneOnOne = {};
      }
      if (!state.query.oneOnOne.data) {
        state.query.oneOnOne.data = [{}];
      }
      const range = state.query.oneOnOne.data[0];
      range.minValue = action.payload;
    },
    setOneOnOneMaxRange: (state, action) => {
      if (!state.query.oneOnOne) {
        state.query.oneOnOne = {};
      }
      if (!state.query.oneOnOne.data) {
        state.query.oneOnOne.data = [{}];
      }
      const range = state.query.oneOnOne.data[0];
      range.maxValue = action.payload;
    },
    setOneOnOneMinTeamMemberScoreRange: (state, action) => {
      if (!state.query.oneOnOne) {
        state.query.oneOnOne = {};
      }
      if (!state.query.oneOnOne.reporteeRating) {
        state.query.oneOnOne.reporteeRating = [{}];
      }
      const range = state.query.oneOnOne.reporteeRating[0];
      range.minValue = action.payload;
    },
    setOneOnOneMaxTeamMemberScoreRange: (state, action) => {
      if (!state.query.oneOnOne) {
        state.query.oneOnOne = {};
      }
      if (!state.query.oneOnOne.reporteeRating) {
        state.query.oneOnOne.reporteeRating = [{}];
      }
      const range = state.query.oneOnOne.reporteeRating[0];
      range.maxValue = action.payload;
    },
    setOneOnOneStatus: (state, action) => {
      if (!state.query.oneOnOne) {
        state.query.oneOnOne = {};
      }
      if (!state.query.oneOnOne.reporteeMood) {
        state.query.oneOnOne.reporteeMood = [];
      }
      const index = state.query.oneOnOne.reporteeMood.indexOf(action.payload);
      if (index !== -1) {
        state.query.oneOnOne.reporteeMood.splice(index, 1);
      } else {
        state.query.oneOnOne.reporteeMood.push(action.payload);
      }
      if (state.query.oneOnOne.reporteeMood.length === 0) {
        delete state.query.oneOnOne.reporteeMood;
      }
      if (Object.keys(state.query.oneOnOne).length === 0) {
        delete state.query.oneOnOne;
      }
    },
    setReviewCycle: (state, action) => {
      if (!state.query.review) {
        state.query.review = {};
      }
      state.query.review.source = action.payload;
      if (!state.query.review.source) {
        delete state.query.review.source;
      }
      if (Object.keys(state.query.review).length === 0) {
        delete state.query.review;
      }
    },
    setReviewMinRange: (state, action) => {
      if (!state.query.review) {
        state.query.review = {};
      }
      if (!state.query.review.data) {
        state.query.review.data = [{}];
      }
      const range = state.query.review.data[0];
      range.minValue = action.payload;
    },
    setReviewMaxRange: (state, action) => {
      if (!state.query.review) {
        state.query.review = {};
      }
      if (!state.query.review.data) {
        state.query.review.data = [{}];
      }
      const range = state.query.review.data[0];
      range.maxValue = action.payload;
    },
    setReviewGridPosition: (state, action) => {
      if (!state.query.review) {
        state.query.review = {};
      }
      if (!state.query.review.gridPositions) {
        state.query.review.gridPositions = [];
      }
      const position = {
        row: action.payload.rowNumber,
        column: action.payload.columnNumber,
        id: camelCase(action.payload.label),
      };
      const index = state.query.review.gridPositions.findIndex((p) => p.id === position.id);
      if (index !== -1) {
        state.query.review.gridPositions.splice(index, 1);
      } else {
        state.query.review.gridPositions.push(position);
      }
      if (state.query.review.gridPositions.length === 0) {
        delete state.query.review.gridPositions;
      }
      if (Object.keys(state.query.review).length === 0) {
        delete state.query.review;
      }
    },
    setReviewStatus: (state, action) => {
      if (!state.query.review) {
        state.query.review = {};
      }
      if (!state.query.review.promotionStatus) {
        state.query.review.promotionStatus = [];
      }
      const index = state.query.review.promotionStatus.indexOf(action.payload);
      if (index !== -1) {
        state.query.review.promotionStatus.splice(index, 1);
      } else {
        state.query.review.promotionStatus.push(action.payload);
      }
      if (state.query.review.promotionStatus.length === 0) {
        delete state.query.review.promotionStatus;
      }
      if (Object.keys(state.query.review).length === 0) {
        delete state.query.review;
      }
    },
    setEngagementDriver: (state, action) => {
      if (!state.query.engagement) {
        state.query.engagement = {};
      }
      if (action.payload) {
        state.query.engagement.engagementDrivers = [{ filterValue: action.payload }];
      } else {
        delete state.query.engagement.engagementDrivers;
      }
      if (Object.keys(state.query.engagement).length === 0) {
        delete state.query.engagement;
      }
    },
    setEngagementSource: (state, action) => {
      if (!state.query.engagement) {
        state.query.engagement = {};
      }
      state.query.engagement.source = action.payload;
      if (!state.query.engagement.source) {
        delete state.query.engagement.source;
      }
      if (Object.keys(state.query.engagement).length === 0) {
        delete state.query.engagement;
      }
    },
    setEngagementMinRange: (state, action) => {
      if (!state.query.engagement) {
        state.query.engagement = {};
      }
      if (!state.query.engagement.data) {
        state.query.engagement.data = [{}];
      }
      const range = state.query.engagement.data[0];
      range.minValue = action.payload;
    },
    setEngagementMaxRange: (state, action) => {
      if (!state.query.engagement) {
        state.query.engagement = {};
      }
      if (!state.query.engagement.data) {
        state.query.engagement.data = [{}];
      }
      const range = state.query.engagement.data[0];
      range.maxValue = action.payload;
    },
    setFeedbackStartTime: (state, action) => {
      if (!state.query.feedback) {
        state.query.feedback = {};
      }
      if (!state.query.feedback.dateRange) {
        state.query.feedback.dateRange = {};
      }
      state.query.feedback.dateRange.startTS = action.payload;
    },
    setFeedbackEndTime: (state, action) => {
      if (!state.query.feedback) {
        state.query.feedback = {};
      }
      if (!state.query.feedback.dateRange) {
        state.query.feedback.dateRange = {};
      }
      state.query.feedback.dateRange.endTS = action.payload;
    },
    setCompetencyFilter: (state, action) => {
      if (!state.query.feedback) {
        state.query.feedback = {};
      }
      if (action.payload) {
        state.query.feedback.competencies = [{ filterValue: action.payload }];
      } else {
        delete state.query.feedback.competencies;
      }
      if (Object.keys(state.query.feedback).length === 0) {
        delete state.query.feedback;
      }
    },
    setFeedbackMinRange: (state, action) => {
      if (!state.query.feedback) {
        state.query.feedback = {};
      }
      if (!state.query.feedback.data) {
        state.query.feedback.data = [{}];
      }
      const range = state.query.feedback.data[0];
      range.minValue = action.payload;
    },
    setFeedbackMaxRange: (state, action) => {
      if (!state.query.feedback) {
        state.query.feedback = {};
      }
      if (!state.query.feedback.data) {
        state.query.feedback.data = [{}];
      }
      const range = state.query.feedback.data[0];
      range.maxValue = action.payload;
    },
    setCheckedEmployees: (state, action) => {
      if (!state.cohortEmployees) {
        state.cohortEmployees = [];
      }
      state.cohortEmployees = action.payload.map((p) => {
        if (typeof p === 'object') return p.id;

        return p;
      });
      if (state.cohortEmployees?.length === 0) {
        delete state.cohortEmployees;
      }
    },
    setIsSaveViewModalOpen: (state, action) => {
      state.isSaveViewModalOpen = action.payload;
    },
    resetView: (state) => {
      state.name = '';
      state.visibilityType = VIEWS_VISIBILITY_TYPES_VALUES.PRIVATE;
      state.activeTab = VIEWS_FILTER_KEYS.BASE_COHORT;
      state.baseCohort.uuid = null;
      state.query = {};
      state.cohortEmployees = null;
      state.isViewStatic = 0;
      state.csvUploadEmployeeList = [];
      state.id = null;
      state.isSaveViewModalOpen = false;
      state.selectedModuleViews = null;
      state.uploadedFileName = null;
      state.viewAction = null;
      // state.viewsScreen = VIEWS_SCREENS.MANAGE_VIEWS;
      state.viewsSubScreen = null;
      state.filterSidebarState = [];
      state.saveViewErrorMessage = null;
    },
    setIsViewStatic: (state, action) => {
      state.isViewStatic = action.payload === VIEWS_DYNAMIC_TYPES_VALUES.STATIC ? 1 : 0;
    },
    setViewsSubScreen: (state, action) => {
      state.viewsSubScreen = action.payload;
    },
    setCSVUploadEmployeeList: (state, action) => {
      state.csvUploadEmployeeList = action.payload;
    },
    setCSVUploadFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setSelectedView: (state, action) => {
      state.selectedView = action.payload;
    },
    // setSelectedViewObject: (state, action) => {
    //   state.selectedViewComplete = action.payload;
    // },
    clearSelectedView: (state) => {
      state.selectedView = null;
      state.selectedViewComplete = null;
    },
    setSelectedModuleViews: (state, action) => {
      state.selectedModuleViews = action.payload;
    },
    clearUploadedCSVFile: (state) => {
      const csvUploadEmployeeListIds = (state.csvUploadEmployeeList ?? []).map((p) => p.id);
      state.cohortEmployees = state.cohortEmployees?.filter((p) => !csvUploadEmployeeListIds.includes(p));
      state.csvUploadEmployeeList = [];
      state.uploadedFileName = null;
    },
    clearSelection: (state, action) => {
      const { filter, selection } = action.payload;
      if (state.query[filter]?.[selection]) {
        delete state.query[filter][selection];
      }
      if (state.query[filter] && Object.keys(state.query[filter]).length === 0) {
        delete state.query[filter];
      }
    },
    clearFilterKey: (state, action) => {
      if (state.query[action.payload]) {
        delete state.query[action.payload];
      }
    },
    setViewWithSelectedView: (state, action) => {
      const view = action.payload;

      // handling for baseCohorts
      // todo: vikalp check if needed
      const baseCohortId = ['-1', '-2', '-3'].includes(view.uuid) ? view.uuid : view.baseCohort?.uuid ?? null;

      state.id = view.id;
      state.name = view.name;
      state.visibilityType = view.visibilityType || VIEWS_VISIBILITY_TYPES_VALUES.PRIVATE;
      state.activeTab = VIEWS_FILTER_KEYS.BASE_COHORT;
      state.baseCohort = {
        uuid: baseCohortId,
      };
      state.isSaveViewModalOpen = false;
      state.isViewStatic = view.isStatic ? 1 : 0;
      state.viewsSubScreen = null;
      state.csvUploadEmployeeList = [];
      state.cohortEmployees = view.cohortEmployees;
      state.query = {};
      for (const key in view.query) {
        if (view.query?.[key]) {
          state.query[key] = view.query[key];
        }
      }
    },
    setViewAction: (state, action) => {
      state.viewAction = action.payload;
    },
    setFilterSidebarState: (state, action) => {
      const index = state.filterSidebarState.indexOf(action.payload);
      if (index === -1) {
        state.filterSidebarState.push(action.payload);
      } else {
        state.filterSidebarState.splice(index, 1);
      }
    },
    setSaveViewErrorMessage: (state, action) => {
      state.saveViewErrorMessage = action.payload;
    },
  },
});

export const {
  setShowViewsPanel,
  resetViewsPanel,
  setViewName,
  setActiveTab,
  setBaseCohort,
  setCheckedManagers,
  setCheckedGrades,
  setCheckedRoles,
  setCheckedFunctions,
  setCheckedEthnicity,
  setCheckedGender,
  setCheckedLocation,
  setGoalStartTime,
  setGoalEndTime,
  setGoalMinRange,
  setGoalMaxRange,
  setGoalStatus,
  setOneOnOneStartTime,
  setOneOnOneEndTime,
  setOneOnOneMinRange,
  setOneOnOneMaxRange,
  setOneOnOneMinTeamMemberScoreRange,
  setOneOnOneMaxTeamMemberScoreRange,
  setOneOnOneStatus,
  setReviewCycle,
  setReviewMinRange,
  setReviewMaxRange,
  setReviewGridPosition,
  setReviewStatus,
  setEngagementDriver,
  setEngagementSource,
  setEngagementMinRange,
  setEngagementMaxRange,
  setCompetencyFilter,
  setFeedbackStartTime,
  setFeedbackEndTime,
  setFeedbackMinRange,
  setFeedbackMaxRange,
  setCheckedEmployees,
  setViewVisibility,
  setOnSavePage,
  setIsSaveViewModalOpen,
  resetView,
  setIsViewStatic,
  setViewsSubScreen,
  setCSVUploadEmployeeList,
  setCSVUploadFileName,
  setSelectedView,
  // setSelectedViewObject,
  clearSelectedView,
  setSelectedModuleViews,
  clearUploadedCSVFile,
  clearSelection,
  setViewWithSelectedView,
  setViewAction,
  clearFilterKey,
  setFilterSidebarState,
  setSaveViewErrorMessage,
  setAppRefreshCount,
} = viewsSlice.actions;

export default viewsSlice.reducer;

// selectors
export const useViewsPanelState = () => useSelector((state) => state.views.viewsPanelState);
export const useViewName = () => useSelector((state) => state.views.name);
export const useViewVisibility = () => useSelector((state) => state.views.visibilityType);
export const useActiveTab = () => useSelector((state) => state.views.activeTab);
export const useCheckedBaseCohort = () => useSelector((state) => state.views.baseCohort);
export const useCheckedManagers = () => useSelector((state) => state.views.query.managers);
export const useCheckedGrades = () => useSelector((state) => state.views.query.grade);
export const useCheckedRoles = () => useSelector((state) => state.views.query.roleId);
export const useCheckedFunctions = () => useSelector((state) => state.views.query.functionId);
export const useCheckedEthnicity = () => useSelector((state) => state.views.query.ethnicity);
export const useCheckedGenders = () => useSelector((state) => state.views.query.gender);
export const useCheckedLocations = () => useSelector((state) => state.views.query.location);
export const useGoals = () => useSelector((state) => state.views.query.goals);
export const useOneOnOnes = () => useSelector((state) => state.views.query.oneOnOne);
export const useReviews = () => useSelector((state) => state.views.query.review);
export const useEngagement = () => useSelector((state) => state.views.query.engagement);
export const useFeedback = () => useSelector((state) => state.views.query.feedback);
export const useCheckedEmployees = () => useSelector((state) => state.views.cohortEmployees);
export const useViewsQuery = () => useSelector((state) => state.views.query);
export const useView = () => useSelector((state) => state.views);
export const useIsSaveViewModalOpen = () => useSelector((state) => state.views.isSaveViewModalOpen);
export const useIsViewStatic = () => useSelector((state) => state.views.isViewStatic);
// export const useViewScreen = () => useSelector((state) => state.views.viewsScreen);
export const useViewSubScreen = () => useSelector((state) => state.views.viewsSubScreen);
export const useSelectedView = () => useSelector((state) => state.views.selectedView);
export const useSelectedViewComplete = () => useSelector((state) => state.views.selectedViewComplete);
export const useCSVUploadEmployeeList = () => useSelector((state) => state.views.csvUploadEmployeeList);
export const useSelectedModuleViews = () => useSelector((state) => state.views.selectedModuleViews);
export const useCSVUploadFileName = () => useSelector((state) => state.views.uploadedFileName);
export const useViewActions = () => useSelector((state) => state.views.viewAction);
export const useFilterSidebarState = () => useSelector((state) => state.views.filterSidebarState);
export const useSaveViewErrorMessage = () => useSelector((state) => state.views.saveViewErrorMessage);
export const useAppRefreshCount = () => useSelector((state) => state.views.appRefreshCount);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  isArchivedView: false,
};

export const goalsSlice = createSlice({
  name: 'goalsSlice',
  initialState,
  reducers: {
    setIsArchivedView: (
      state,
      action: PayloadAction<{
        isArchivedView: boolean;
      }>
    ) => {
      const newState = { ...state };
      newState.isArchivedView = action.payload.isArchivedView;
      return newState;
    },
  },
});

export const { setIsArchivedView } = goalsSlice.actions;

export default goalsSlice.reducer;

export const useSelectIsArchivedViewVisible = () =>
  useSelector((state: { goalsSlice: typeof initialState }) => state.goalsSlice.isArchivedView);

import { Icon } from '@chakra-ui/react';

export const Info = (props) => (
  <Icon viewBox="0 0 24 25" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12.002 11.3838C12.413 11.3838 12.7461 11.7169 12.7461 12.1279V16.2209C12.7461 16.6319 12.413 16.9651 12.002 16.9651C11.5909 16.9651 11.2578 16.6319 11.2578 16.2209V12.1279C11.2578 11.7169 11.5909 11.3838 12.002 11.3838Z"
      fill="currentColor"
    />
    <path
      d="M12.0006 9.89475C12.5144 9.89475 12.9309 9.47825 12.9309 8.96447C12.9309 8.45068 12.5144 8.03418 12.0006 8.03418C11.4868 8.03418 11.0703 8.45068 11.0703 8.96447C11.0703 9.47825 11.4868 9.89475 12.0006 9.89475Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12.5C4 8.08171 7.58171 4.5 12 4.5C16.4183 4.5 20 8.08171 20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58171 20.5 4 16.9183 4 12.5ZM12 5.98818C8.40362 5.98818 5.48818 8.90362 5.48818 12.5C5.48818 16.0964 8.40362 19.0118 12 19.0118C15.5964 19.0118 18.5118 16.0964 18.5118 12.5C18.5118 8.90362 15.5964 5.98818 12 5.98818Z"
      fill="currentColor"
    />
  </Icon>
);

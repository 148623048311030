import { createSlice } from '@reduxjs/toolkit';
// import { OWNERSHIP_TYPE } from 'Deer/intent/Goals/components/MetaFilters';
import { useSelector } from 'react-redux';
import { okrsInsightsSelector } from 'selectors/okrsInsightsSelector';

export const INSIGHT_VIEWS = [
  { value: 'INDIVIDUAL', label: 'Individual' },
  { value: 'OBJECTIVE', label: 'Objective' },
];

const defaultClickThroughData = {
  name: null, // name of the employee
  parentUuid: null, // uuid of manager
};

const initialState = {
  selectedTabId: null,
  timelinesData: null,
  allTimelines: null,
  selectedTimelines: null,
  insights: {
    isInsightsClickThroughViewActive: false,
    clickThroughViewData: defaultClickThroughData,
    viewType: INSIGHT_VIEWS[1].value,
  },
  ownership: null,
};

export const okrsPageSlice = createSlice({
  name: 'okrsPageSlice',
  initialState,
  reducers: {
    updateSelectedTimelines: (state, action) => {
      state.selectedTimelines = action.payload;
    },
    setAllTimelines: (state, action) => {
      state.allTimelines = action.payload;
    },
    setTimelinesData: (state, action) => {
      state.timelinesData = action.payload;
    },
    setClickThroughView: (state, action) => {
      state.insights.isInsightsClickThroughViewActive = true;
      state.insights.clickThroughViewData = action.payload;
    },
    clearClickThroughView: (state) => {
      state.insights.isInsightsClickThroughViewActive = false;
      state.insights.clickThroughViewData = defaultClickThroughData;
    },
    setViewType: (state, action) => {
      state.insights.viewType = action.payload;
    },
    setOwnership: (state, action) => {
      state.ownership = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateSelectedTimelines,
  setAllTimelines,
  setTimelinesData,
  setClickThroughView,
  clearClickThroughView,
  setViewType,
  setOwnership,
} = okrsPageSlice.actions;

export default okrsPageSlice.reducer;

export const useOKRInsights = () => useSelector(okrsInsightsSelector);

export const useOKROwnership = () => useSelector((state) => state.okrsPage.ownership);

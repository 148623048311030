import {
  OBJECTIVE_DETAILS_PANEL_OPEN,
  OBJECTIVE_DETAILS_PANEL_CLOSE,
  OBJECTIVE_DETAILS_PANEL_GOBACK,
} from 'actions/actionTypes';

const initialState = {
  open: false,
  callbackInfo: [
    /* expected structure of object. onNameChange and onLevelChange to be deprecated. */
    /* {
    objectiveId: null,
    objectiveTimeBoundId: null,
    timeBoundId: null,
    objectivePId: null,
    onUpdate: () => {},
    onNameChange: () => {},
    onLevelChange: () => {},
    onDepartmentChange: () => {},
    onDelete: () => {},
    onReAlign: () => {}
  } */
  ],
  onKeyResultCreation: () => {},
  onObjectiveCreation: () => {},
};

const objectiveDetailsPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case OBJECTIVE_DETAILS_PANEL_OPEN: {
      const {
        objectiveId,
        objectiveTimeBoundId,
        timeBoundId,
        objectivePId,
        // onUpdate,
        onNameChange,
        onLevelChange,
        onDepartmentChange,
        onDelete,
        onReAlign,
        onKeyResultCreation,
        onObjectiveCreation,
      } = action.payload;

      return {
        open: true,
        callbackInfo: [
          ...state.callbackInfo,
          {
            objectiveId,
            objectiveTimeBoundId,
            timeBoundId,
            objectivePId,
            onNameChange,
            onLevelChange,
            onDepartmentChange,
            // onUpdate,
            onDelete,
            onReAlign,
          },
        ],
        onKeyResultCreation,
        onObjectiveCreation,
      };
    }

    case OBJECTIVE_DETAILS_PANEL_CLOSE: {
      return initialState;
    }

    case OBJECTIVE_DETAILS_PANEL_GOBACK: {
      return state.callbackInfo.length > 1 ? { ...state, callbackInfo: state.callbackInfo.slice(0, -1) } : initialState;
    }

    default:
      return state;
  }
};

export default objectiveDetailsPanelReducer;

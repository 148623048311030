import { Icon } from '@chakra-ui/react';

export const Clone1 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M15.2728 4H6.54549C5.74549 4 5.09094 4.65455 5.09094 5.45455V15.6364H6.54549V5.45455H15.2728V4ZM14.5455 6.90909H9.45458C8.65458 6.90909 8.00731 7.56364 8.00731 8.36364L8.00003 18.5455C8.00003 19.3455 8.64731 20 9.44731 20H17.4546C18.2546 20 18.9091 19.3455 18.9091 18.5455V11.2727L14.5455 6.90909ZM9.45458 18.5455V8.36364H13.8182V12H17.4546V18.5455H9.45458Z"
      fill="currentColor"
    />
  </Icon>
);

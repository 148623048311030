import { Icon } from '@chakra-ui/react';

export const GoalThinOutline = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_3844_45366)">
      <path
        d="M10.6667 7.09874C7.508 7.2705 5 9.86854 5 13.0483C5 16.3392 7.68629 19.0069 11 19.0069C14.2019 19.0069 16.818 16.5162 16.9909 13.3793H17.9917C17.8116 17.0324 14.7156 20 11 20C7.17104 20 4 16.8377 4 13.0429C4 9.35884 6.98873 6.28097 10.6667 6.10453V7.09874Z"
        fill="currentColor"
      />
      <path
        d="M18 12.97V13.0591C18 13.0537 18 13.0483 18 13.0429C18 13.0182 18 12.9939 18 12.97Z"
        fill="currentColor"
      />
      <path
        d="M10.6667 9.7414C8.98225 9.90747 7.66667 11.3189 7.66667 13.0354C7.66667 14.8637 9.15906 16.3457 11 16.3457C12.7285 16.3457 14.1497 15.0392 14.3169 13.3664H15.3201C15.1451 15.5696 13.2572 17.3388 11 17.3388C8.62973 17.3388 6.66671 15.3812 6.66671 13.032C6.66671 10.794 8.44848 8.9173 10.6667 8.74483V9.7414Z"
        fill="currentColor"
      />
      <path
        d="M15.3334 12.9862V13.0423C15.3334 13.0389 15.3334 13.0355 15.3334 13.032C15.3334 13.0165 15.3334 13.0012 15.3334 12.9862Z"
        fill="currentColor"
      />
      <path d="M15.6667 7.31035H20L18.6667 8.63448H15.6667V7.31035Z" fill="currentColor" />
      <path d="M15.3333 8.63448V5.32414L16.6667 4V8.63448H15.3333Z" fill="currentColor" />
      <path
        d="M10.6763 13.3998C10.4019 13.1333 10.3994 12.6956 10.6707 12.4261L15.9871 7.14643L16.9299 8.08274L11.6486 13.3914C11.382 13.6594 10.9475 13.6631 10.6763 13.3998Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3844_45366">
        <rect width="24" height="24" rx="8" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Italics = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.2857 7.28571C10.2857 7.99714 10.86 8.57143 11.5714 8.57143H12.18L9.24855 15.4286H8.14283C7.4314 15.4286 6.85712 16.0029 6.85712 16.7143C6.85712 17.4257 7.4314 18 8.14283 18H12.4285C13.14 18 13.7143 17.4257 13.7143 16.7143C13.7143 16.0029 13.14 15.4286 12.4285 15.4286H11.82L14.7514 8.57143H15.8571C16.5685 8.57143 17.1428 7.99714 17.1428 7.28571C17.1428 6.57429 16.5685 6 15.8571 6H11.5714C10.86 6 10.2857 6.57429 10.2857 7.28571Z"
      fill="currentColor"
    />
  </Icon>
);

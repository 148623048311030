import { Icon } from '@chakra-ui/react';

export const Continue = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <circle cx="12" cy="12" r="9.5" stroke="currentColor" fill="transparent" />
    <path
      d="M11 8.4C11 8.17909 10.8209 8 10.6 8H9.4C9.17909 8 9 8.17909 9 8.4V15.6C9 15.8209 9.17909 16 9.4 16H10.6C10.8209 16 11 15.8209 11 15.6V8.4ZM16.6096 12.3123C16.8097 12.1522 16.8097 11.8478 16.6096 11.6877L12.6499 8.5199C12.388 8.31038 12 8.49685 12 8.83225V15.1677C12 15.5032 12.388 15.6896 12.6499 15.4801L16.6096 12.3123Z"
      fill="currentColor"
    />
  </Icon>
);

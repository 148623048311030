import { Icon } from '@chakra-ui/react';

export const ShareFeedback = (props) => (
  <Icon viewBox="0 0 20 20" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M14.8516 3.87866V4.84661C13.4628 5.21118 11.4835 6.18704 10.8405 8.24131C12.4472 6.78439 14.7191 6.9431 14.8516 6.94957V7.91752L17.3848 5.89978L14.8516 3.87866Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0946 4.42402C13.307 4.42924 12.2153 4.7755 11.2631 5.51462C11.7616 5.12767 12.2983 4.84845 12.8097 4.66942C13.2825 4.50386 13.7336 4.42402 14.1127 4.42402V4.66942C13.3845 4.66942 12.3352 4.99309 11.4136 5.70852L11.3472 5.76008H5.93227C5.76662 5.76008 5.63233 5.89436 5.63233 6.06001V12.0586C5.63233 12.2243 5.76662 12.3586 5.93227 12.3586H7.81377V13.6957L9.00346 12.6448C9.2106 12.4654 9.46888 12.3586 9.74513 12.3586H14.1127C14.2783 12.3586 14.4126 12.2243 14.4126 12.0586V9.25976L15.9942 7.77715V12.604C15.9942 13.0266 15.9269 13.3864 15.6837 13.6296C15.4405 13.8728 15.0807 13.94 14.658 13.94H9.84214L7.81963 15.8353C7.61249 16.0147 7.3541 16.1214 7.07785 16.1214C6.91849 16.1214 6.75928 16.0862 6.61169 16.0162L6.60047 16.0109L6.58984 16.0044C6.40052 15.8898 6.31307 15.7142 6.27186 15.5416C6.23418 15.3837 6.22991 15.2045 6.22616 15.047L6.22471 14.9874L6.23082 13.94H5.38691C4.9643 13.94 4.60446 13.8728 4.36126 13.6296C4.11805 13.3864 4.05078 13.0266 4.05078 12.604V5.51468C4.05078 5.00356 4.20406 4.64643 4.48744 4.42854C4.75747 4.22092 5.09807 4.17862 5.38685 4.17862H14.1127V4.42402H14.0946Z"
      fill="currentColor"
    />
  </Icon>
);

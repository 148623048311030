import {
  RolesToCreateConfig,
  RolesToUploadInsightsCustomTagConfig,
  RolesToViewUpdateStatusEditDeleteConfig,
} from '../types';

export type AccessControlState = {
  id?: number | null;
  uuid?: string | null;
  createdTS?: number;
  updatedTS?: number;
  name: string;
  description: string;
  orgId: number;
  type: 'CUSTOM' | 'DEFAULT';
  lastEditedBy?: Record<string, unknown>;
  moduleName: string;
  objectivesName: string;
  keyResultsName: string;
  configData: Record<string, unknown>;
  includeCollaboratorGoals: boolean;
  includeOwnerGoals: boolean;
  accessControl: {
    rolesToCreateConfig: RolesToCreateConfig;
    rolesToViewUpdateStatusEditDeleteConfig: RolesToViewUpdateStatusEditDeleteConfig;
    rolesToUploadInsightsCustomTagConfig: RolesToUploadInsightsCustomTagConfig;
  };

  isOpen: boolean;
  isEditMode: boolean;
};

export const initialStateGoalAccessMatrix: AccessControlState = {
  id: null,
  uuid: null,
  createdTS: 0,
  updatedTS: 0,
  name: 'OKR - Simplified',
  description: '',
  orgId: 0,
  type: 'CUSTOM',
  lastEditedBy: {},
  moduleName: 'Goal',
  objectivesName: 'Objective',
  keyResultsName: 'Key result',
  includeOwnerGoals: true,
  includeCollaboratorGoals: true,
  configData: {
    OBJECTIVE_TYPE: {
      configuration: 'OBJECTIVE_TYPE',
      value: 'SIMPLE',
    },
    DEFAULT_KR_PROGRESS: {
      configuration: 'DEFAULT_KR_PROGRESS',
      value: 'MANUAL',
    },
    DEFAULT_KR_VISIBILITY: {
      configuration: 'DEFAULT_KR_VISIBILITY',
      value: 'GLOBAL',
    },
    IS_KR_CASCADING_ENABLED: {
      configuration: 'IS_KR_CASCADING_ENABLED',
      value: 'false',
    },
    DEFAULT_OBJECTIVE_PROGRESS: {
      configuration: 'DEFAULT_OBJECTIVE_PROGRESS',
      value: 'DERIVED_AVG',
    },
    ENABLE_IMPORTANCE_IN_GOALS: {
      configuration: 'ENABLE_IMPORTANCE_IN_GOALS',
      value: 'false',
    },
    DEFAULT_OBJECTIVE_VISIBILITY: {
      configuration: 'DEFAULT_OBJECTIVE_VISIBILITY',
      value: 'GLOBAL',
    },
  },
  accessControl: {
    rolesToCreateConfig: {
      GOAL_ADMIN: {
        createObjective: {
          cellType: 'MULTI_VALUE',
          values: [
            'CREATE_OBJECTIVE_DEPARTMENT',
            'CREATE_OBJECTIVE_TEAM',
            'CREATE_OBJECTIVE_INDIVIDUAL',
            'CREATE_OBJECTIVE_COMPANY',
            'NONE',
          ],
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_OBJECTIVE_COMPANY'],
        },
        createKeyResult: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_KEY_RESULT_PARENT_NON_MANDATORY',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_KEY_RESULT_PARENT_MANDATORY', 'CREATE_KEY_RESULT_PARENT_NON_MANDATORY', 'NONE'],
        },
        createInitiative: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_INITIATIVES', 'NONE'],
        },
      },
      GOAL_CUSTOM_ADMIN: {
        createObjective: {
          cellType: 'MULTI_VALUE',
          values: [
            'CREATE_OBJECTIVE_DEPARTMENT',
            'CREATE_OBJECTIVE_TEAM',
            'CREATE_OBJECTIVE_INDIVIDUAL',
            'CREATE_OBJECTIVE_COMPANY',
            'NONE',
          ],
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_OBJECTIVE_COMPANY'],
        },
        createKeyResult: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_KEY_RESULT_PARENT_NON_MANDATORY',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_KEY_RESULT_PARENT_MANDATORY', 'CREATE_KEY_RESULT_PARENT_NON_MANDATORY', 'NONE'],
        },
        createInitiative: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_INITIATIVES', 'NONE'],
        },
      },
      GOAL_USER: {
        createObjective: {
          cellType: 'MULTI_VALUE',
          values: ['CREATE_OBJECTIVE_TEAM', 'CREATE_OBJECTIVE_INDIVIDUAL', 'NONE'],
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_OBJECTIVE_DEPARTMENT', 'CREATE_OBJECTIVE_TEAM', 'CREATE_OBJECTIVE_COMPANY'],
        },
        createKeyResult: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_KEY_RESULT_PARENT_MANDATORY',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_KEY_RESULT_PARENT_MANDATORY', 'CREATE_KEY_RESULT_PARENT_NON_MANDATORY', 'NONE'],
        },
        createInitiative: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_INITIATIVES', 'NONE'],
        },
      },
      MANAGER: {
        createObjective: {
          cellType: 'MULTI_VALUE',
          values: ['CREATE_OBJECTIVE_DEPARTMENT', 'CREATE_OBJECTIVE_TEAM', 'CREATE_OBJECTIVE_INDIVIDUAL', 'NONE'],
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_OBJECTIVE_DEPARTMENT', 'CREATE_OBJECTIVE_COMPANY'],
        },
        createKeyResult: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_KEY_RESULT_PARENT_MANDATORY',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_KEY_RESULT_PARENT_MANDATORY', 'CREATE_KEY_RESULT_PARENT_NON_MANDATORY', 'NONE'],
        },
        createInitiative: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_INITIATIVES', 'NONE'],
        },
      },
    },
    rolesToViewUpdateStatusEditDeleteConfig: {
      GOAL_ADMIN: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_OBJECTIVE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS'],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_INITIATIVE_STATUS_PROGRESS'],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
      GOAL_CUSTOM_ADMIN: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_OBJECTIVE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS'],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_INITIATIVE_STATUS_PROGRESS'],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
      OWNER: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_PUBLIC_OBJECTIVE', 'EDIT_ALL_OBJECTIVE', 'NONE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS'],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT', 'NONE', 'EDIT_PUBLIC_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_INITIATIVE_STATUS_PROGRESS'],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
      CREATOR: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_OBJECTIVE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS'],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_INITIATIVE_STATUS_PROGRESS'],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
      OWNER_MANAGER: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_OBJECTIVE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS'],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_INITIATIVE_STATUS_PROGRESS'],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
      GOAL_USER: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_PUBLIC_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES', 'VIEW_COMMENT_PUBLIC_OBJECTIVES', 'NONE'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS', 'UPDATE_PUBLIC_OBJECTIVE_STATUS_PROGRESS', 'NONE'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_PUBLIC_OBJECTIVE', 'EDIT_ALL_OBJECTIVE', 'NONE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_PUBLIC_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_PUBLIC_KEY_RESULTS', 'NONE', 'VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: [
            'UPDATE_PUBLIC_KEY_RESULT_STATUS_PROGRESS',
            'NONE',
            'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          ],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT', 'NONE', 'EDIT_PUBLIC_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_PUBLIC_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES', 'VIEW_COMMENT_PUBLIC_INITIATIVES', 'NONE'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: [
            'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
            'UPDATE_PUBLIC_INITIATIVE_STATUS_PROGRESS',
            'NONE',
          ],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_PUBLIC_INITIATIVE', 'NONE', 'EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
      MANAGER: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_PUBLIC_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES', 'VIEW_COMMENT_PUBLIC_OBJECTIVES', 'NONE'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS', 'UPDATE_PUBLIC_OBJECTIVE_STATUS_PROGRESS', 'NONE'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_PUBLIC_OBJECTIVE', 'EDIT_ALL_OBJECTIVE', 'NONE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_PUBLIC_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_PUBLIC_KEY_RESULTS', 'NONE', 'VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: [
            'UPDATE_PUBLIC_KEY_RESULT_STATUS_PROGRESS',
            'NONE',
            'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          ],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT', 'NONE', 'EDIT_PUBLIC_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_PUBLIC_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES', 'VIEW_COMMENT_PUBLIC_INITIATIVES', 'NONE'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: [
            'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
            'UPDATE_PUBLIC_INITIATIVE_STATUS_PROGRESS',
            'NONE',
          ],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_PUBLIC_INITIATIVE', 'NONE', 'EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
      COLLABORATOR_MANAGER: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_OBJECTIVE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS'],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_INITIATIVE_STATUS_PROGRESS'],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
      COLLABORATOR: {
        viewCommentObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_OBJECTIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_OBJECTIVES'],
        },
        updateStatusProgressObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_OBJECTIVE_STATUS_PROGRESS'],
        },
        editObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_OBJECTIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_PUBLIC_OBJECTIVE', 'EDIT_ALL_OBJECTIVE', 'NONE'],
        },
        deleteObjectives: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_ALL_OBJECTIVE', 'NONE', 'DELETE_PUBLIC_OBJECTIVE'],
        },
        viewCommentKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_KEY_RESULTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_KEY_RESULTS'],
        },
        updateStatusProgressKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_KEY_RESULT_STATUS_PROGRESS'],
        },
        editKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_KEY_RESULT',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_KEY_RESULT', 'NONE', 'EDIT_PUBLIC_KEY_RESULT'],
        },
        deleteKeyResults: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_KEY_RESULT', 'NONE', 'DELETE_ALL_KEY_RESULT'],
        },
        viewCommentInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_COMMENT_ALL_INITIATIVES',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_COMMENT_ALL_INITIATIVES'],
        },
        updateStatusProgressInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'UPDATE_ALL_INITIATIVE_STATUS_PROGRESS',
          valueType: 'USER_SELECTED',
          permittedValues: ['UPDATE_ALL_INITIATIVE_STATUS_PROGRESS'],
        },
        editInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'EDIT_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['EDIT_ALL_INITIATIVE'],
        },
        deleteInitiatives: {
          cellType: 'SINGLE_VALUE',
          value: 'DELETE_ALL_INITIATIVE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DELETE_PUBLIC_INITIATIVE', 'DELETE_ALL_INITIATIVE', 'NONE'],
        },
      },
    },
    rolesToUploadInsightsCustomTagConfig: {
      GOAL_ADMIN: {
        individualInsights: {
          cellType: 'SINGLE_VALUE',
          value: 'DOWNLOAD_INDIVIDUAL_INSIGHTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_INDIVIDUAL_INSIGHTS', 'DOWNLOAD_INDIVIDUAL_INSIGHTS', 'NONE'],
        },
        objectiveLevelInsights: {
          cellType: 'SINGLE_VALUE',
          value: 'DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS', 'VIEW_OBJECTIVE_LEVEL_INSIGHTS', 'NONE'],
        },
        bulkUpload: {
          cellType: 'MULTI_VALUE',
          values: ['BULK_UPLOAD_OBJECTIVES', 'BULK_UPLOAD_INITIATIVES', 'BULK_UPLOAD_KEY_RESULTS'],
          valueType: 'USER_SELECTED',
          permittedValues: ['BULK_UPLOAD_OBJECTIVES', 'BULK_UPLOAD_INITIATIVES', 'BULK_UPLOAD_KEY_RESULTS'],
        },
        customTags: {
          cellType: 'SINGLE_VALUE',
          value: 'CREATE_EDIT_DELETE_CUSTOM_TAGS',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_EDIT_DELETE_CUSTOM_TAGS', 'VIEW_ASSIGN_CUSTOM_TAGS'],
        },
      },
      GOAL_CUSTOM_ADMIN: {
        individualInsights: {
          cellType: 'SINGLE_VALUE',
          value: 'DOWNLOAD_INDIVIDUAL_INSIGHTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_INDIVIDUAL_INSIGHTS', 'DOWNLOAD_INDIVIDUAL_INSIGHTS', 'NONE'],
        },
        objectiveLevelInsights: {
          cellType: 'SINGLE_VALUE',
          value: 'DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS', 'VIEW_OBJECTIVE_LEVEL_INSIGHTS', 'NONE'],
        },
        bulkUpload: {
          cellType: 'MULTI_VALUE',
          values: ['BULK_UPLOAD_OBJECTIVES', 'BULK_UPLOAD_KEY_RESULTS'],
          valueType: 'USER_SELECTED',
          permittedValues: ['BULK_UPLOAD_OBJECTIVES', 'BULK_UPLOAD_INITIATIVES', 'BULK_UPLOAD_KEY_RESULTS'],
        },
        customTags: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_ASSIGN_CUSTOM_TAGS',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_EDIT_DELETE_CUSTOM_TAGS', 'VIEW_ASSIGN_CUSTOM_TAGS'],
        },
      },
      GOAL_USER: {
        individualInsights: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_INDIVIDUAL_INSIGHTS', 'DOWNLOAD_INDIVIDUAL_INSIGHTS', 'NONE'],
        },
        objectiveLevelInsights: {
          cellType: 'SINGLE_VALUE',
          value: 'NONE',
          valueType: 'USER_SELECTED',
          permittedValues: ['DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS', 'VIEW_OBJECTIVE_LEVEL_INSIGHTS', 'NONE'],
        },
        bulkUpload: {
          cellType: 'MULTI_VALUE',
          values: [],
          valueType: 'USER_SELECTED',
          permittedValues: ['BULK_UPLOAD_OBJECTIVES', 'BULK_UPLOAD_INITIATIVES', 'BULK_UPLOAD_KEY_RESULTS'],
        },
        customTags: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_ASSIGN_CUSTOM_TAGS',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_EDIT_DELETE_CUSTOM_TAGS', 'VIEW_ASSIGN_CUSTOM_TAGS'],
        },
      },
      MANAGER: {
        individualInsights: {
          cellType: 'SINGLE_VALUE',
          value: 'DOWNLOAD_INDIVIDUAL_INSIGHTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['VIEW_INDIVIDUAL_INSIGHTS', 'DOWNLOAD_INDIVIDUAL_INSIGHTS', 'NONE'],
        },
        objectiveLevelInsights: {
          cellType: 'SINGLE_VALUE',
          value: 'DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS',
          valueType: 'USER_SELECTED',
          permittedValues: ['DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS', 'VIEW_OBJECTIVE_LEVEL_INSIGHTS', 'NONE'],
        },
        bulkUpload: {
          cellType: 'MULTI_VALUE',
          values: [],
          valueType: 'USER_SELECTED',
          permittedValues: ['BULK_UPLOAD_OBJECTIVES', 'BULK_UPLOAD_INITIATIVES', 'BULK_UPLOAD_KEY_RESULTS'],
        },
        customTags: {
          cellType: 'SINGLE_VALUE',
          value: 'VIEW_ASSIGN_CUSTOM_TAGS',
          valueType: 'USER_SELECTED',
          permittedValues: ['CREATE_EDIT_DELETE_CUSTOM_TAGS', 'VIEW_ASSIGN_CUSTOM_TAGS'],
        },
      },
    },
  },

  isOpen: false,
  isEditMode: false,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntentValuesType } from 'Deer/intent/types/IntentCommonTypes';
import { useSelector } from 'react-redux';

import { past3Months } from 'constants/ProjectConstants';

export type OneOnOneIntentType = {
  label: string;
  onClick: () => void;
  visible: () => boolean;
  value: {
    myData?: IntentValuesType;
    team?: {
      directReports?: IntentValuesType;
      allReports?: IntentValuesType;
    };
    company?: IntentValuesType;
  };
  applicableGroupByValues?: {
    value: string;
    label: string;
  }[];
  showGroupByDropdown?: boolean;
  showTimelineDropdown?: boolean;
  showEllipsisMenu?: boolean;
  queryParamValue?: string;
  category?: string;
};

export const ONE_ON_ONE_INSIGHTS_GROUP_BY_KEYS = [
  {
    label: 'Manager',
    value: {
      displayName: 'Manager',
      enabled: true,
      keyName: 'manager',
      uuid: null,
    },
  },
  {
    label: 'Skip Manager',
    value: {
      displayName: 'Skip-Manager',
      enabled: true,
      keyName: 'skipManager',
      uuid: null,
    },
  },
];

const initialState = {
  dateRange: past3Months,
  groupByKeys: ONE_ON_ONE_INSIGHTS_GROUP_BY_KEYS[0].value,
  filterText: '',
  selectedIntent: null,
};

interface DateRangeI {
  startDate: Date;
  endDate: Date;
  label?: string;
}

type GroupByKeysI = {
  displayName: string;
  enabled: boolean;
  keyName: string;
  uuid: string | null;
};

export const OneOnOneInsightsFiltersSlice = createSlice({
  name: 'OneOnOneInsightsSlice',
  initialState,
  reducers: {
    initializeOneOnOneInsightsFilters: (
      state,
      action: PayloadAction<{
        dateRange?: DateRangeI;
        groupByKeys?: GroupByKeysI;
      }>
    ) => {
      state.dateRange = action.payload.dateRange;
      state.groupByKeys = action.payload.groupByKeys;
    },
    setDateRangeOneOnOneInsightsFilters: (
      state,
      action: PayloadAction<{
        dateRange: DateRangeI;
      }>
    ) => {
      state.dateRange = action.payload.dateRange;
    },
    setGroupByKeysOneOnOneInsightsFilters: (
      state,
      action: PayloadAction<{
        groupByKeys: GroupByKeysI;
      }>
    ) => {
      state.groupByKeys = action.payload.groupByKeys;
    },
    setFilterTextOneOnOneInsightsFilters: (
      state,
      action: PayloadAction<{
        filterText: string;
      }>
    ) => {
      state.filterText = action.payload.filterText;
    },
    setSelectedOneOnOneIntent: (state, action: PayloadAction<OneOnOneIntentType>) => {
      const newState = { ...state };
      newState.selectedIntent = action.payload;
      return newState;
    },
  },
});

export const {
  initializeOneOnOneInsightsFilters,
  setDateRangeOneOnOneInsightsFilters,
  setGroupByKeysOneOnOneInsightsFilters,
  setFilterTextOneOnOneInsightsFilters,
  setSelectedOneOnOneIntent,
} = OneOnOneInsightsFiltersSlice.actions;

export default OneOnOneInsightsFiltersSlice.reducer;

export const useOneOnOneInsightsFiltersData = () => {
  const dateRange: DateRangeI = useSelector((state: any) => state.OneOnOneInsightsFiltersSlice.dateRange);
  const groupByKeys: GroupByKeysI = useSelector((state: any) => state.OneOnOneInsightsFiltersSlice.groupByKeys);

  const filterText: string = useSelector((state: any) => state.OneOnOneInsightsFiltersSlice.filterText);

  const selectedIntent: OneOnOneIntentType = useSelector(
    (state: any) => state.OneOnOneInsightsFiltersSlice.selectedIntent
  );

  return {
    dateRange,
    groupByKeys,
    filterText,
    selectedIntent,
  };
};

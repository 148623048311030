import { Icon } from '@chakra-ui/react';

export const Objective = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12C20 12.2231 19.9909 12.444 19.973 12.6625L18.6511 12.4591C18.6614 12.3074 18.6667 12.1543 18.6667 12C18.6667 8.3181 15.6819 5.33333 12 5.33333C8.3181 5.33333 5.33333 8.3181 5.33333 12C5.33333 15.6819 8.3181 18.6667 12 18.6667C12.1543 18.6667 12.3074 18.6614 12.4591 18.6511L12.6625 19.973C12.444 19.9909 12.2231 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM12 16C12.0171 16 12.0342 15.9999 12.0512 15.9997L11.8455 14.6623C10.4446 14.5822 9.33333 13.4209 9.33333 12C9.33333 10.5272 10.5272 9.33333 12 9.33333C13.4209 9.33333 14.5822 10.4446 14.6623 11.8455L15.9997 12.0512C15.9999 12.0342 16 12.0171 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 12L14.7814 20L15.4377 15.9902C15.4846 15.704 15.7078 15.4799 15.9927 15.4333L20 14.7765L12 12Z"
      fill="currentColor"
    />
  </Icon>
);

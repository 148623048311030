import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { CompetencySettingsSlice } from './types/competencySettingsSlice';

const initialState: CompetencySettingsSlice = {
  viewType: null,
  labelSettings: [],
};

export const competencySettingsSlice = createSlice({
  name: 'competencySettings',
  initialState,
  reducers: {
    setViewType: (state, action: PayloadAction<CompetencySettingsSlice['viewType']>) => {
      state.viewType = action.payload;
    },
    setLabelSettings: (state, action: PayloadAction<CompetencySettingsSlice['labelSettings']>) => {
      state.labelSettings = action.payload;
    },
  },
});

export const { setViewType, setLabelSettings } = competencySettingsSlice.actions;

export default competencySettingsSlice.reducer;

export const useCompetencySettings = () =>
  useSelector<{ competencySettings: CompetencySettingsSlice }, CompetencySettingsSlice>(
    (state) => state.competencySettings
  );

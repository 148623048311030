import { Icon } from '@chakra-ui/react';

export const Withdraw = (props) => (
  <Icon viewBox="0 0 20 20" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.3887 6.66797C8.18034 6.66797 6.18034 7.49297 4.63867 8.83464L3.06367 7.25964C2.53867 6.73464 1.63867 7.1013 1.63867 7.84297V12.5013C1.63867 12.9596 2.01367 13.3346 2.47201 13.3346H7.13034C7.87201 13.3346 8.24701 12.4346 7.72201 11.9096L6.13034 10.318C7.28867 9.3513 8.76367 8.7513 10.397 8.7513C13.0303 8.7513 15.3053 10.2846 16.3887 12.5013C16.6137 12.968 17.147 13.2013 17.6387 13.0346C18.2303 12.843 18.5303 12.168 18.2637 11.6013C16.8303 8.68464 13.847 6.66797 10.3887 6.66797Z"
      fill="currentColor"
    />
  </Icon>
);

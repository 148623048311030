import localStorageService from './localStorageHelper';

interface UserPreference {
  [key: string]: boolean;
}

export const setCollapsibleCardPreference = ({ userPreference }: { userPreference: UserPreference }) => {
  // Get current mesh_ai_internals data from localStorage
  const meshInternals = localStorageService.getItem('mesh_ai_internals') ?? {};

  // Update userPreference inside mesh_ai_internals
  const updatedMeshInternals = {
    ...meshInternals,
    userPreference: {
      ...meshInternals.userPreference,
      ...userPreference,
    },
  };

  // Set the updated mesh_ai_internals back to localStorage
  localStorageService.setItem('mesh_ai_internals', updatedMeshInternals);
};

export const getValueFromUserPreference = (key: string): boolean => {
  try {
    const meshInternals = localStorageService.getItem('mesh_ai_internals');

    // Check if meshInternals and userPreference[key] exist using optional chaining
    return meshInternals?.userPreference?.[key] ?? true;
  } catch (error) {
    console.error(`Error retrieving value for key '${key}' from localStorage:`, error);
    return true;
  }
};

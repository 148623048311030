import { Icon } from '@chakra-ui/react';

export const Share = (props) => (
  <Icon viewBox="0 0 20 20" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M16.6663 5.41732C16.6663 6.56791 15.7336 7.50065 14.583 7.50065C13.9247 7.50065 13.3377 7.19534 12.956 6.71859L7.49959 9.56539L7.49967 9.58398C7.49967 9.75238 7.47969 9.91611 7.44198 10.0729L13.0088 13.2194C13.3907 12.7791 13.9544 12.5007 14.583 12.5007C15.7336 12.5007 16.6663 13.4334 16.6663 14.584C16.6663 15.7346 15.7336 16.6673 14.583 16.6673C13.4324 16.6673 12.4997 15.7346 12.4997 14.584C12.4997 14.4731 12.5083 14.3642 12.525 14.2579L6.87955 11.067C6.50335 11.4382 5.9866 11.6673 5.41634 11.6673C4.26575 11.6673 3.33301 10.7346 3.33301 9.58398C3.33301 8.43339 4.26575 7.50065 5.41634 7.50065C6.14457 7.50065 6.78553 7.87429 7.15798 8.44032L12.5122 5.64683C12.5039 5.57146 12.4997 5.49488 12.4997 5.41732C12.4997 4.26672 13.4324 3.33398 14.583 3.33398C15.7336 3.33398 16.6663 4.26672 16.6663 5.41732Z"
      fill="currentColor"
    />
  </Icon>
);

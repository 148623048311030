import { SHOW_TASK_DETAILS, HIDE_TASK_DETAILS, UPDATE_TASK_DETAILS } from 'actions/actionTypes';

// currently not using employee key, have to avoid using that, causes unnecessary complications with task link route
export const initialState = {
  display: false,
  taskIds: [],
  onDelete: null,
  onUpdate: null,
  employee: null,
};

const taskDetails = (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW_TASK_DETAILS:
      return {
        ...state,
        display: true,
        taskIds: action.data.taskIds,
        onDelete: action.data.onDelete,
      };
    case UPDATE_TASK_DETAILS:
      return {
        ...state,
        onDelete: action.data.onDelete,
        employee: action.data.employee,
        onUpdate: action.data.onUpdate,
      };
    case HIDE_TASK_DETAILS:
      return {
        ...initialState,
        display: false,
      };

    default:
      return state;
  }
};

export default taskDetails;

import { Icon } from '@chakra-ui/react';

export const MyTeamEngagements = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_413_469)">
      <path
        d="M13.2106 10.1981L12.2406 8.6781C12.1506 8.5381 12.0206 8.5081 11.9506 8.5081C11.9106 8.5081 11.7406 8.5281 11.6406 8.6781L11.1806 9.4481H8.55062L8.35062 9.0181C8.10062 8.5181 7.99062 7.9881 8.00062 7.4481C8.03062 6.1381 8.83062 4.9181 10.0406 4.3381C11.3406 3.7181 12.8206 3.9581 14.0006 4.9981C15.4006 3.7781 17.1906 3.6681 18.5806 4.7181C19.9306 5.7381 20.3706 7.5081 19.6506 9.0081L19.4506 9.4381H16.4606L15.2406 7.1881C15.1506 7.0181 14.9906 7.0081 14.9206 6.9981C14.8406 6.9981 14.6906 7.0281 14.6106 7.1981L13.1906 10.1881L13.2106 10.1981ZM17.3606 7.9481H18.4506C18.6506 7.1081 18.2406 6.3481 17.6806 5.9181C17.2006 5.5481 16.4806 5.3481 15.7106 5.6681C16.0706 5.8381 16.3806 6.1081 16.5706 6.4781L17.3606 7.9381V7.9481ZM9.55062 7.9481H10.3206L10.3406 7.9081C10.6706 7.3481 11.2606 7.0181 11.9106 7.0081C12.2606 7.0081 12.6206 7.0981 12.9206 7.2981L13.2706 6.5781C13.2906 6.5281 13.3206 6.4781 13.3506 6.4281L13.0206 6.1381C12.0306 5.2681 11.1406 5.4881 10.6906 5.6981C9.98062 6.0381 9.52062 6.7381 9.50062 7.4781C9.50062 7.6381 9.52062 7.7881 9.55062 7.9481Z"
        fill="currentColor"
      />
      <path
        d="M6.7 17.1081C7.2 17.1081 7.7 16.8081 7.9 16.3081C7.7 15.9081 7.6 15.4081 7.6 15.0081V14.6081C7.4 14.4081 7.1 14.2081 6.7 14.2081C6 14.2081 5.4 14.8081 5.4 15.6081C5.3 16.5081 5.9 17.1081 6.7 17.1081ZM6 19.2081C6 18.7081 6.2 18.3081 6.5 17.9081C5.5 17.9081 4 18.4081 4 19.3081V20.0081H6V19.2081ZM10.1 16.7081C11 16.7081 11.7 16.0081 11.7 15.0081C11.7 14.1081 11 13.3081 10.1 13.3081C9.2 13.3081 8.5 14.0081 8.5 15.0081C8.5 15.9081 9.2 16.7081 10.1 16.7081ZM10.1 17.5081C9.1 17.5081 7 18.1081 7 19.2081V20.0081H13.2V19.2081C13.2 18.1081 11.1 17.5081 10.1 17.5081ZM13.7 17.9081C14 18.3081 14.2 18.7081 14.2 19.2081V20.0081H16.2V19.3081C16.1 18.4081 14.6 17.9081 13.7 17.9081ZM12.6 15.0081C12.6 15.5081 12.5 15.9081 12.3 16.3081C12.5 16.8081 13 17.1081 13.5 17.1081C14.2 17.1081 14.8 16.5081 14.8 15.7081C14.8 14.9081 14.2 14.3081 13.5 14.3081C13.2 14.3081 12.9 14.4081 12.6 14.7081V15.0081Z"
        fill="currentColor"
      />
      <path
        d="M6.7 17.1081C7.2 17.1081 7.7 16.8081 7.9 16.3081C7.7 15.9081 7.6 15.4081 7.6 15.0081V14.6081C7.4 14.4081 7.1 14.2081 6.7 14.2081C6 14.2081 5.4 14.8081 5.4 15.6081C5.3 16.5081 5.9 17.1081 6.7 17.1081ZM6 19.2081C6 18.7081 6.2 18.3081 6.5 17.9081C5.5 17.9081 4 18.4081 4 19.3081V20.0081H6V19.2081ZM10.1 16.7081C11 16.7081 11.7 16.0081 11.7 15.0081C11.7 14.1081 11 13.3081 10.1 13.3081C9.2 13.3081 8.5 14.0081 8.5 15.0081C8.5 15.9081 9.2 16.7081 10.1 16.7081ZM10.1 17.5081C9.1 17.5081 7 18.1081 7 19.2081V20.0081H13.2V19.2081C13.2 18.1081 11.1 17.5081 10.1 17.5081ZM13.7 17.9081C14 18.3081 14.2 18.7081 14.2 19.2081V20.0081H16.2V19.3081C16.1 18.4081 14.6 17.9081 13.7 17.9081ZM12.6 15.0081C12.6 15.5081 12.5 15.9081 12.3 16.3081C12.5 16.8081 13 17.1081 13.5 17.1081C14.2 17.1081 14.8 16.5081 14.8 15.7081C14.8 14.9081 14.2 14.3081 13.5 14.3081C13.2 14.3081 12.9 14.4081 12.6 14.7081V15.0081Z"
        fill="currentColor"
      />
      <path
        d="M13.5006 13.3081C14.1006 13.3081 14.6406 13.5581 15.0506 13.9481L18.8706 10.1581H16.1806C16.0906 10.1581 16.0106 10.1081 15.9806 10.0381L14.9706 8.1481L13.5806 11.0881C13.5006 11.2581 13.3506 11.2881 13.2806 11.2881C13.2106 11.2881 13.0606 11.2881 12.9606 11.1181L12.1306 9.8281L11.5306 10.1181C11.4806 10.1481 11.4306 10.1581 11.3706 10.1581H9.14062L12.5506 13.5481C12.8506 13.3981 13.1606 13.3081 13.5006 13.3081Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_413_469">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

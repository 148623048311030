import { Icon } from '@chakra-ui/react';

export const Tags = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M17.636 13.1413L13.1481 17.6292C13.0318 17.7456 12.8938 17.838 12.7418 17.9009C12.5898 17.9639 12.4269 17.9964 12.2624 17.9964C12.0979 17.9964 11.935 17.9639 11.783 17.9009C11.631 17.838 11.493 17.7456 11.3767 17.6292L6 12.2588V5.99951H12.2593L17.636 11.3762C17.8691 11.6108 18 11.9281 18 12.2588C18 12.5895 17.8691 12.9068 17.636 13.1413Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.12939 9.12915H9.13526" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

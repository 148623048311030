import { Icon } from '@chakra-ui/react';

export const ResourceNotFound = (props) => (
  <Icon viewBox="0 0 80 81" boxSize="16px" color="icon.g1" {...props}>
    <rect x="1" y="1.5" width="78" height="78" rx="7" fill="#F6FAFE" />
    <circle cx="20" cy="25.5" r="1" fill="#1565BC" />
    <path
      d="M25 25.5C25 24.9477 25.4477 24.5 26 24.5H60C60.5523 24.5 61 24.9477 61 25.5C61 26.0523 60.5523 26.5 60 26.5H26C25.4477 26.5 25 26.0523 25 25.5Z"
      fill="#1565BC"
    />
    <circle cx="20" cy="35.5" r="1" fill="#1565BC" />
    <path
      d="M25 35.5C25 34.9477 25.4477 34.5 26 34.5H60C60.5523 34.5 61 34.9477 61 35.5C61 36.0523 60.5523 36.5 60 36.5H26C25.4477 36.5 25 36.0523 25 35.5Z"
      fill="#1565BC"
    />
    <circle cx="20" cy="45.5" r="1" fill="#1565BC" />
    <path
      d="M25 45.5C25 44.9477 25.4477 44.5 26 44.5H60C60.5523 44.5 61 44.9477 61 45.5C61 46.0523 60.5523 46.5 60 46.5H26C25.4477 46.5 25 46.0523 25 45.5Z"
      fill="#1565BC"
    />
    <circle cx="20" cy="55.5" r="1" fill="#1565BC" />
    <path
      d="M25 55.5C25 54.9477 25.4477 54.5 26 54.5H60C60.5523 54.5 61 54.9477 61 55.5C61 56.0523 60.5523 56.5 60 56.5H26C25.4477 56.5 25 56.0523 25 55.5Z"
      fill="#1565BC"
    />
    <rect x="1" y="1.5" width="78" height="78" rx="7" stroke="#1565BC" strokeWidth="2" />
  </Icon>
);

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { FeedbackSentiment, VisibilityType } from 'interfaces/CF';
import { useSelector } from 'react-redux';

import { InitialStateType, PrimaryPanelAction, SecondaryPanelAction } from '../types/FeedbackSlice';
import { Revision } from '../types/Revision';

const initialState: InitialStateType = {
  PRIMARY_PANEL_ACTION: 'SHARE',
  MESH_MAVEN_GROUP_ID: '',
  PROVIDED: {
    feedbackType: 'PROVIDED',
    feedbackSentimentType: 'APPRECIATIVE',
    secondaryPanelAction: 'GOAL_SELECTION',
    visibility: 'PUBLIC',
    feedbackPost: EditorState.createEmpty(),
    goal: null,
    attachments: {
      gifs: [],
      images: [],
      documents: [],
      isUploading: false,
    },
    isSecondaryPanelOpen: false,
    applicableEmployees: [],
    revisionNumber: 0,
    revisions: [],
  },
  REQUESTED: {
    feedbackType: 'REQUESTED',
    visibility: 'RECEIVER_AND_MANAGER',
    questionText: null,
    goal: null,
    receiverEmployeeId: '',
    providerEmployees: [],
    isSecondaryPanelOpen: false,
  },
  FILLREQUESTED: {
    feedbackType: 'FILLREQUESTED',
    feedbackSentimentType: 'CONSTRUCTIVE',
    feedbackPost: EditorState.createEmpty(),
    revisionNumber: 0,
    revisions: [],
    attachments: {
      gifs: [],
      images: [],
      documents: [],
      isUploading: false,
    },
  },
};

export const FeedbackSlice = createSlice({
  name: 'FeedbackSlice',
  initialState,
  reducers: {
    // Common
    setPrimaryPanelAction: (state, action: PayloadAction<{ primaryPanelAction: PrimaryPanelAction }>) => {
      state.PRIMARY_PANEL_ACTION = action.payload.primaryPanelAction;
    },
    setMeshMavenGroupId: (state, action: PayloadAction<{ meshMavenGroupId: string }>) => {
      state.MESH_MAVEN_GROUP_ID = action.payload.meshMavenGroupId;
    },

    // Provided
    setProvidedFeedbackSentimentType: (state, action: PayloadAction<{ feedbackSentimentType: FeedbackSentiment }>) => {
      state.PROVIDED.feedbackSentimentType = action.payload.feedbackSentimentType;
    },
    setProvidedVisibility: (state, action: PayloadAction<{ visibility: VisibilityType }>) => {
      state.PROVIDED.visibility = action.payload.visibility;
    },
    setProvidedFeedbackPost: (state, action: PayloadAction<{ feedbackPost: EditorState }>) => {
      state.PROVIDED.feedbackPost = action.payload.feedbackPost;
    },
    setProvidedGoal: (state, action: PayloadAction<{ goal: null }>) => {
      state.PROVIDED.goal = action.payload.goal;
    },
    setProvidedAttachments: (
      state,
      action: PayloadAction<{
        gifs?: Array<{
          url: string;
          type: 'GIF';
        }>;
        images?: Array<{
          url: string;
          type: 'IMAGE';
        }>;
        documents?: Array<{
          url: string;
          type: 'DOCUMENT';
        }>;
        isUploading?: boolean;
      }>
    ) => {
      state.PROVIDED.attachments.gifs = [...(action.payload.gifs ?? [])];
      state.PROVIDED.attachments.images = [...(action.payload.images ?? [])];
      state.PROVIDED.attachments.documents = [...(action.payload.documents ?? [])];
      state.PROVIDED.attachments.isUploading = action.payload.isUploading;
    },

    setProvidedSecondaryPanelOpen: (state, action: PayloadAction<{ isSecondaryPanelOpen: boolean }>) => {
      state.PROVIDED.isSecondaryPanelOpen = action.payload.isSecondaryPanelOpen;
    },

    setProvidedSecondaryPanelAction: (state, action: PayloadAction<{ secondaryPanelAction: SecondaryPanelAction }>) => {
      state.PROVIDED.secondaryPanelAction = action.payload.secondaryPanelAction;
    },

    setProvidedApplicableEmployees: (state, action: PayloadAction<{ applicableEmployees: string[] }>) => {
      state.PROVIDED.applicableEmployees = action.payload.applicableEmployees;
    },

    setProvidedRevisionNumber: (state, action: PayloadAction<{ revisionNumber: number }>) => {
      state.PROVIDED.revisionNumber = action.payload.revisionNumber;
    },

    setProvidedRevisionByRevisionNumber: (
      state,
      action: PayloadAction<{ revision: Revision; revisionNumber: number }>
    ) => {
      state.PROVIDED.revisions[action.payload.revisionNumber] = action.payload.revision;
    },

    setProvidedAddRevision: (state, action: PayloadAction<{ revision: Revision }>) => {
      state.PROVIDED.revisions.push(action.payload.revision);
    },

    setProvidedRephraseError: (state, action: PayloadAction<{ error: string }>) => {
      state.PROVIDED.rephraseError = action.payload.error;
    },

    setProvidedAnalysisError: (state, action: PayloadAction<{ error: string }>) => {
      state.PROVIDED.analysisError = action.payload.error;
    },

    setClearProvidedFeedbackData: (state) => {
      state.PROVIDED.feedbackSentimentType = 'APPRECIATIVE';
      state.PROVIDED.visibility = 'PUBLIC';
      state.PROVIDED.feedbackPost = EditorState.createEmpty();
      state.PROVIDED.goal = null;
      state.PROVIDED.attachments = {
        gifs: [],
        images: [],
        documents: [],
        isUploading: false,
      };
      state.PROVIDED.isSecondaryPanelOpen = false;
      state.PROVIDED.applicableEmployees = [];
      state.PROVIDED.revisionNumber = 0;
      state.PROVIDED.revisions = [];
      state.PROVIDED.secondaryPanelAction = 'GOAL_SELECTION';
      state.PROVIDED.analysisError = null;
      state.PROVIDED.rephraseError = null;
    },

    // Requested

    setRequestedVisibility: (state, action: PayloadAction<{ visibility: VisibilityType }>) => {
      state.REQUESTED.visibility = action.payload.visibility;
    },
    setRequestedQuestion: (state, action: PayloadAction<{ questionText: string }>) => {
      state.REQUESTED.questionText = action.payload.questionText;
    },
    setRequestedGoal: (state, action: PayloadAction<{ goal: null }>) => {
      state.REQUESTED.goal = action.payload.goal;
    },
    setRequestedReceiverEmployeeId: (state, action: PayloadAction<{ receiverEmployeeId: string }>) => {
      state.REQUESTED.receiverEmployeeId = action.payload.receiverEmployeeId;
    },
    setRequestedProviderEmployees: (state, action: PayloadAction<{ providerEmployees: Record<string, string>[] }>) => {
      state.REQUESTED.providerEmployees = action.payload.providerEmployees;
    },
    setRequestedSecondaryPanelOpen: (state, action: PayloadAction<{ isSecondaryPanelOpen: boolean }>) => {
      state.REQUESTED.isSecondaryPanelOpen = action.payload.isSecondaryPanelOpen;
    },
    setClearRequestedFeedbackData: (state) => {
      state.REQUESTED.visibility = initialState.REQUESTED.visibility;
      state.REQUESTED.questionText = null;
      state.REQUESTED.goal = null;
      state.REQUESTED.receiverEmployeeId = '';
      state.REQUESTED.providerEmployees = [];
      state.REQUESTED.isSecondaryPanelOpen = false;
    },

    // FillRequested
    setFillRequestedSentimentType: (
      state,
      action: PayloadAction<{ feedbackSentimentType: 'APPRECIATIVE' | 'CONSTRUCTIVE' }>
    ) => {
      state.FILLREQUESTED.feedbackSentimentType = action.payload.feedbackSentimentType;
    },
    setFillRequestedPost: (state, action: PayloadAction<{ feedbackPost: EditorState }>) => {
      state.FILLREQUESTED.feedbackPost = action.payload.feedbackPost;
    },
    setClearFillRequestedFeedbackData: (state) => {
      state.FILLREQUESTED.feedbackSentimentType = 'APPRECIATIVE';
      state.FILLREQUESTED.feedbackPost = EditorState.createEmpty();
      state.FILLREQUESTED.attachments = {
        gifs: [],
        images: [],
        documents: [],
        isUploading: false,
      };
      state.FILLREQUESTED.revisionNumber = 0;
      state.FILLREQUESTED.revisions = [];
      state.FILLREQUESTED.analysisError = null;
      state.FILLREQUESTED.rephraseError = null;
    },
    setFillRequestedAttachments: (
      state,
      action: PayloadAction<{
        gifs?: Array<{
          url: string;
          type: 'GIF';
        }>;
        images?: Array<{
          url: string;
          type: 'IMAGE';
        }>;
        documents?: Array<{
          url: string;
          type: 'DOCUMENT';
        }>;
        isUploading?: boolean;
      }>
    ) => {
      state.FILLREQUESTED.attachments.gifs = [...(action.payload.gifs ?? [])];
      state.FILLREQUESTED.attachments.images = [...(action.payload.images ?? [])];
      state.FILLREQUESTED.attachments.documents = [...(action.payload.documents ?? [])];
      state.FILLREQUESTED.attachments.isUploading = action.payload.isUploading;
    },
    setFillRequestedRevisionNumber: (state, action: PayloadAction<{ revisionNumber: number }>) => {
      state.FILLREQUESTED.revisionNumber = action.payload.revisionNumber;
    },
    setFillRequestedAddRevision: (state, action: PayloadAction<{ revision: Revision }>) => {
      state.FILLREQUESTED.revisions.push(action.payload.revision);
    },
    setFillRequestedRevisionByRevisionNumber: (
      state,
      action: PayloadAction<{ revision: Revision; revisionNumber: number }>
    ) => {
      state.FILLREQUESTED.revisions[action.payload.revisionNumber] = action.payload.revision;
    },
    setFillRequestedRephraseError: (state, action: PayloadAction<{ error: string }>) => {
      state.FILLREQUESTED.rephraseError = action.payload.error;
    },
    setFillRequestedAnalysisError: (state, action: PayloadAction<{ error: string }>) => {
      state.FILLREQUESTED.analysisError = action.payload.error;
    },
  },
});

export const {
  // Common
  setPrimaryPanelAction,
  setMeshMavenGroupId,

  // Provided
  setProvidedFeedbackSentimentType,
  setProvidedVisibility,
  setProvidedFeedbackPost,
  setProvidedGoal,
  setProvidedAttachments,
  setProvidedSecondaryPanelOpen,
  setProvidedSecondaryPanelAction,
  setProvidedApplicableEmployees,
  setProvidedRevisionNumber,
  setProvidedAddRevision,
  setProvidedRevisionByRevisionNumber,
  setClearProvidedFeedbackData,
  setProvidedRephraseError,
  setProvidedAnalysisError,
  // Requested
  setRequestedVisibility,
  setRequestedQuestion,
  setRequestedGoal,
  setRequestedReceiverEmployeeId,
  setRequestedProviderEmployees,
  setRequestedSecondaryPanelOpen,
  setClearRequestedFeedbackData,
  // FillRequested
  setFillRequestedSentimentType,
  setFillRequestedPost,
  setClearFillRequestedFeedbackData,
  setFillRequestedAttachments,
  setFillRequestedRevisionNumber,
  setFillRequestedAddRevision,
  setFillRequestedRevisionByRevisionNumber,
  setFillRequestedRephraseError,
  setFillRequestedAnalysisError,
} = FeedbackSlice.actions;

export default FeedbackSlice.reducer;

export const useProvidedFeedbackData = () => {
  const feedbackSentimentType: 'APPRECIATIVE' | 'CONSTRUCTIVE' = useSelector(
    (state: any) => state.FeedbackSlice.PROVIDED.feedbackSentimentType
  );
  const visibility: VisibilityType = useSelector((state: any) => state.FeedbackSlice.PROVIDED.visibility);
  const feedbackPost: EditorState = useSelector((state: any) => state.FeedbackSlice.PROVIDED.feedbackPost);
  const goal: null = useSelector((state: any) => state.FeedbackSlice.PROVIDED.goal);
  const revisionNumber: number = useSelector((state: any) => state.FeedbackSlice.PROVIDED.revisionNumber);
  const revisions: Revision[] = useSelector((state: any) => state.FeedbackSlice.PROVIDED.revisions);
  const selectedRevision = revisions[revisionNumber];

  const attachments: {
    gifs: Array<{
      url: string;
      type: 'GIF';
    }>;
    images: Array<{
      url: string;
      type: 'GIF';
    }>;
    documents: Array<{
      url: string;
      type: 'GIF';
    }>;
    isUploading: boolean;
  } = useSelector((state: any) => state.FeedbackSlice.PROVIDED.attachments);

  const isSecondaryPanelOpen: boolean = useSelector((state: any) => state.FeedbackSlice.PROVIDED.isSecondaryPanelOpen);

  const secondaryPanelAction: SecondaryPanelAction = useSelector(
    (state: any) => state.FeedbackSlice.PROVIDED.secondaryPanelAction
  );

  const applicableEmployees: string[] = useSelector((state: any) => state.FeedbackSlice.PROVIDED.applicableEmployees);

  const rephraseError: string = useSelector((state: any) => state.FeedbackSlice.PROVIDED.rephraseError);

  const analysisError: string = useSelector((state: any) => state.FeedbackSlice.PROVIDED.analysisError);

  return {
    feedbackSentimentType,
    visibility,
    feedbackPost,
    goal,
    attachments,
    isSecondaryPanelOpen,
    secondaryPanelAction,
    applicableEmployees,
    revisionNumber,
    revisions,
    selectedRevision,
    rephraseError,
    analysisError,
    setRevisionNumber: setProvidedRevisionNumber,
    setAddRevision: setProvidedAddRevision,
    setRevisionByRevisionNumber: setProvidedRevisionByRevisionNumber,
    setFeedbackPost: setProvidedFeedbackPost,
    setRephraseError: setProvidedRephraseError,
    setAnalysisError: setProvidedAnalysisError,
  };
};

export const useRequestedFeedbackData = () => {
  const visibility: VisibilityType = useSelector((state: any) => state.FeedbackSlice.REQUESTED.visibility);
  const questionText: string | null = useSelector((state: any) => state.FeedbackSlice.REQUESTED.questionText);
  const goal: null = useSelector((state: any) => state.FeedbackSlice.REQUESTED.goal);
  const receiverEmployeeId: string = useSelector((state: any) => state.FeedbackSlice.REQUESTED.receiverEmployeeId);

  const providerEmployees: Record<string, string>[] = useSelector(
    (state: any) => state.FeedbackSlice.REQUESTED.providerEmployees
  );

  const isSecondaryPanelOpen: boolean = useSelector((state: any) => state.FeedbackSlice.REQUESTED.isSecondaryPanelOpen);

  return {
    visibility,
    questionText,
    goal,
    receiverEmployeeId,
    providerEmployees,
    isSecondaryPanelOpen,
  };
};

export const useFillRequestedFeedbackData = () => {
  const feedbackSentimentType: 'APPRECIATIVE' | 'CONSTRUCTIVE' = useSelector(
    (state: any) => state.FeedbackSlice.FILLREQUESTED.feedbackSentimentType
  );
  const feedbackPost: EditorState = useSelector((state: any) => state.FeedbackSlice.FILLREQUESTED.feedbackPost);

  const attachments: {
    gifs: Array<{
      url: string;
      type: 'GIF';
    }>;
    images: Array<{
      url: string;
      type: 'GIF';
    }>;
    documents: Array<{
      url: string;
      type: 'GIF';
    }>;
    isUploading: boolean;
  } = useSelector((state: any) => state.FeedbackSlice.FILLREQUESTED.attachments);

  const revisionNumber: number = useSelector((state: any) => state.FeedbackSlice.FILLREQUESTED.revisionNumber);
  const revisions: Revision[] = useSelector((state: any) => state.FeedbackSlice.FILLREQUESTED.revisions);
  const selectedRevision = revisions[revisionNumber];
  const rephraseError: string = useSelector((state: any) => state.FeedbackSlice.FILLREQUESTED.rephraseError);
  const analysisError: string = useSelector((state: any) => state.FeedbackSlice.FILLREQUESTED.analysisError);

  return {
    feedbackSentimentType,
    feedbackPost,
    attachments,
    revisionNumber,
    revisions,
    selectedRevision,
    rephraseError,
    analysisError,
    setRevisionNumber: setFillRequestedRevisionNumber,
    setAddRevision: setFillRequestedAddRevision,
    setRevisionByRevisionNumber: setFillRequestedRevisionByRevisionNumber,
    setFeedbackPost: setFillRequestedPost,
    setRephraseError: setFillRequestedRephraseError,
    setAnalysisError: setFillRequestedAnalysisError,
  };
};

export const usePrimaryPanelAction = (): PrimaryPanelAction =>
  useSelector((state: any) => state.FeedbackSlice.PRIMARY_PANEL_ACTION);

export const useMeshMavenGroupId = (): string => useSelector((state: any) => state.FeedbackSlice.MESH_MAVEN_GROUP_ID);

export const useFeedbackData = () => {
  const providedData = useProvidedFeedbackData();
  const requestedData = useFillRequestedFeedbackData();
  return usePrimaryPanelAction() === 'SHARE' ? providedData : requestedData;
};

import { Icon } from '@chakra-ui/react';

export const Calibrate = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M8.5 4V20" stroke="currentColor" strokeLinecap="round" />
    <path d="M4 16L20 16" stroke="currentColor" strokeLinecap="round" />
    <path d="M16 4V20" stroke="currentColor" strokeLinecap="round" />
    <path d="M4 8L20 8" stroke="currentColor" strokeLinecap="round" />
  </Icon>
);

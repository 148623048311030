import { Icon } from '@chakra-ui/react';

export const Template = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_3844_7240)">
      <rect x="4.5" y="5.04102" width="15" height="15" rx="1.5" stroke="currentColor" fill="transparent" />
      <path d="M8 9.54102H16" stroke="currentColor" fill="transparent" strokeLinecap="round" />
      <path d="M8 12.541H11" stroke="currentColor" fill="transparent" strokeLinecap="round" />
      <path d="M8 15.541H11" stroke="currentColor" fill="transparent" strokeLinecap="round" />
      <path d="M13 12.541H16" stroke="currentColor" fill="transparent" strokeLinecap="round" />
      <path d="M13 15.541H16" stroke="currentColor" fill="transparent" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_3844_7240">
        <rect width="24" height="24" rx="8" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const TeamIcon = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M7.76602 6C7.76602 6.85977 7.12061 7.5 6.39935 7.5C5.67809 7.5 5.03268 6.85977 5.03268 6C5.03268 5.14023 5.67809 4.5 6.39935 4.5C7.12061 4.5 7.76602 5.14023 7.76602 6ZM3.16602 11C3.16602 10.8376 3.23799 10.6668 3.42558 10.4812C3.61762 10.2911 3.90581 10.113 4.26186 9.9602C4.97621 9.65365 5.8424 9.5 6.39935 9.5C6.9563 9.5 7.82249 9.65365 8.53684 9.9602C8.89289 10.113 9.18108 10.2911 9.37312 10.4812C9.5607 10.6668 9.63268 10.8376 9.63268 11V11.5H3.16602V11ZM11.6993 11C11.6993 10.5716 11.6088 10.1886 11.4533 9.84804C11.6472 9.91752 11.8338 9.9969 12.0047 10.0858C12.6005 10.3955 12.8327 10.721 12.8327 11V11.5H11.6993V11ZM10.966 6C10.966 6.85977 10.3206 7.5 9.59935 7.5C9.57531 7.5 9.55142 7.49926 9.52766 7.49779C9.72399 7.03964 9.83268 6.5315 9.83268 6C9.83268 5.4685 9.72399 4.96036 9.52766 4.50221C9.55142 4.50074 9.57531 4.5 9.59935 4.5C10.3206 4.5 10.966 5.14023 10.966 6Z"
      fill="currentColor"
      stroke="white"
    />
  </Icon>
);

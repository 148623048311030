import { Icon } from '@chakra-ui/react';

export const Refresh2 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.80918 9.68473C5.97358 9.57858 6.18101 9.57671 6.34528 9.6729L6.42341 9.72914L6.44999 9.75955L7.60024 11.5209L7.61531 11.5462C7.69411 11.6837 7.71173 11.7181 7.73004 11.7856C7.74874 11.8546 7.74998 11.9229 7.73441 11.9999C7.65579 12.3902 7.18333 12.5465 6.86747 12.2564L6.38191 11.5931L6.37599 11.6446L6.36075 11.8461C6.15273 14.4095 7.69164 16.796 10.1218 17.6513C12.691 18.5555 15.5318 17.5003 16.8932 15.1369C17.1867 14.6219 17.9731 14.9316 17.8383 15.5091C17.8266 15.5593 17.8074 15.6075 17.7819 15.651C16.176 18.4389 12.8121 19.688 9.78169 18.6215C6.921 17.6147 5.10009 14.7978 5.33781 11.7714L5.36365 11.5641L5.05728 11.8416L4.77666 12.0906C4.33983 12.455 3.79831 11.8528 4.07581 11.3876L4.12369 11.319L4.18573 11.2548L5.80918 9.68473ZM6.2181 8.34904C7.824 5.56107 11.1879 4.31198 14.2183 5.37848C17.079 6.38527 18.8999 9.20219 18.6622 12.2286L18.6346 12.4356L19.0767 12.0392L19.2233 11.9095C19.6602 11.545 20.2017 12.1472 19.9242 12.6124L19.8763 12.681L19.8143 12.7452L18.1908 14.3153C17.999 14.4391 17.7487 14.421 17.55 14.2404L16.3847 12.4538L16.3155 12.3296C16.2917 12.2835 16.2809 12.2549 16.27 12.2144C16.2513 12.1454 16.25 12.0771 16.2656 12.0001C16.3442 11.6098 16.8167 11.4535 17.1325 11.7436L17.6173 12.4066L17.624 12.3554L17.6393 12.1539C17.8473 9.59051 16.3084 7.20401 13.8782 6.34875C11.309 5.44453 8.46819 6.49969 7.10682 8.86315C6.81335 9.37815 6.02692 9.0684 6.16174 8.49093C6.17344 8.44074 6.19263 8.39253 6.2181 8.34904Z"
      fill="currentColor"
    />
  </Icon>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AGENDA_CONDITIONS_TYPE } from 'screens/oneOnOneRabbit/SettingsPage/constants';

import { AGENDA_LIBRARY_TYPE, AGENDA_THEME_TYPE, LIFECYCLE_MOMENT_TYPE } from '../features/AgendaLibrary/interface';

const initialState: {
  rightSidebar: {
    isExpanded: boolean;
  };
  agendaLibrary: {
    isOpen: boolean;
    type: (typeof AGENDA_LIBRARY_TYPE)[number];
    themeType: (typeof AGENDA_THEME_TYPE)[number];
    lifeCycleMomentType: (typeof LIFECYCLE_MOMENT_TYPE)[number];
    addedAgendaIds: number[];
    // settings page state
    agendaCondition: AGENDA_CONDITIONS_TYPE;
  };
} = {
  rightSidebar: {
    isExpanded: false,
  },
  agendaLibrary: {
    isOpen: false,
    type: AGENDA_LIBRARY_TYPE[0],
    themeType: AGENDA_THEME_TYPE[0],
    lifeCycleMomentType: LIFECYCLE_MOMENT_TYPE[0],
    addedAgendaIds: [],
    agendaCondition: 'REGULAR',
  },
};

export const OneOnOneInstancePageSlice = createSlice({
  name: 'OneOnOneInstancePage',
  initialState,
  reducers: {
    setIsExpandedOneOnOneSidebar: (state, action) => {
      state.rightSidebar.isExpanded = action.payload;
    },
    setAgendaLibraryIsOpen: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        agendaCondition?: AGENDA_CONDITIONS_TYPE;
      }>
    ) => {
      state.agendaLibrary.isOpen = action.payload.isOpen;
      if (action.payload.agendaCondition) {
        state.agendaLibrary.agendaCondition = action.payload.agendaCondition;
      } else {
        state.agendaLibrary.agendaCondition = 'REGULAR';
      }
    },
    setAgendaLibraryType: (
      state,
      action: PayloadAction<{
        type: (typeof AGENDA_LIBRARY_TYPE)[number];
      }>
    ) => {
      state.agendaLibrary.type = action.payload.type;
    },
    setAgendaLibraryThemeType: (
      state,
      action: PayloadAction<{
        themeType: (typeof AGENDA_THEME_TYPE)[number];
      }>
    ) => {
      state.agendaLibrary.themeType = action.payload.themeType;
    },
    setAgendaLibraryLifeCycleMomentType: (
      state,
      action: PayloadAction<{
        lifeCycleMomentType: (typeof LIFECYCLE_MOMENT_TYPE)[number];
      }>
    ) => {
      state.agendaLibrary.lifeCycleMomentType = action.payload.lifeCycleMomentType;
    },
    setAgendaLibraryAddedAgendaIds: (
      state,
      action: PayloadAction<{
        addedAgendaId: number;
      }>
    ) => {
      state.agendaLibrary.addedAgendaIds = [...state.agendaLibrary.addedAgendaIds, action.payload.addedAgendaId];
    },
    resetAgendaLibrary: (state) => {
      state.agendaLibrary = initialState.agendaLibrary;
    },
  },
});

export const {
  setIsExpandedOneOnOneSidebar,
  setAgendaLibraryIsOpen,
  setAgendaLibraryType,
  setAgendaLibraryThemeType,
  setAgendaLibraryLifeCycleMomentType,
  setAgendaLibraryAddedAgendaIds,
  resetAgendaLibrary,
} = OneOnOneInstancePageSlice.actions;

export default OneOnOneInstancePageSlice.reducer;

export const useOneOnOneRightSidebar = () =>
  useSelector((state: any) => state.OneOnOneInstancePage.rightSidebar.isExpanded as boolean);

export const useOneOnOneAgendaLibraryIsOpen = () =>
  useSelector((state: any) => state.OneOnOneInstancePage.agendaLibrary.isOpen as boolean);

export const useOneOnOneAgendaLibraryType = () =>
  useSelector((state: any) => state.OneOnOneInstancePage.agendaLibrary.type as (typeof AGENDA_LIBRARY_TYPE)[number]);

export const useOneOnOneAgendaLibraryThemeType = () =>
  useSelector((state: any) => state.OneOnOneInstancePage.agendaLibrary.themeType as (typeof AGENDA_THEME_TYPE)[number]);

export const useOneOnOneAgendaLibraryAddedAgendaIds = () =>
  useSelector((state: any) => state.OneOnOneInstancePage.agendaLibrary.addedAgendaIds as number[]);

export const useOneOnOneAgendaLibraryLifeCycleMomentType = () =>
  useSelector(
    (state: any) =>
      state.OneOnOneInstancePage.agendaLibrary.lifeCycleMomentType as (typeof LIFECYCLE_MOMENT_TYPE)[number]
  );

export const useOneOnOneAgendaLibraryAgendaCondition = () =>
  useSelector((state: any) => state.OneOnOneInstancePage.agendaLibrary.agendaCondition as AGENDA_CONDITIONS_TYPE);

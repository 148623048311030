import { Icon } from '@chakra-ui/react';

export const ManagerScore = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.5 12.4165C6.5 11.632 7.13593 10.9961 7.9204 10.9961C8.05941 10.9961 8.18364 11.0829 8.2315 11.2134L10.9173 18.5381C11.1734 19.2365 12.1589 19.2431 12.4244 18.5482L15.2424 11.1701L14.7753 10.9917L15.2424 11.1701C15.2825 11.0653 15.383 10.9961 15.4952 10.9961C16.3802 10.9961 17.0976 11.7135 17.0976 12.5985V18.1732C17.0976 18.9065 16.5032 19.5009 15.77 19.5009H7.82765C7.09441 19.5009 6.5 18.9065 6.5 18.1732V12.4165Z"
      stroke="currentColor"
      fill="transparent"
    />
    <path d="M10.7144 13.142L11.8572 16.5706L13.0001 13.142L11.8572 11.4277L10.7144 13.142Z" fill="currentColor" />
    <circle cx="11.8573" cy="7.42857" r="2.92857" stroke="currentColor" fill="transparent" />
  </Icon>
);

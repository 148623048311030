import { Icon } from '@chakra-ui/react';

export const MyReviews = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8.1 11.2C7 11.2 6 12.2 6 13.4C6 14.6 6.9 15.6 8.1 15.6C9.2 15.6 10.1 14.6 10.1 13.4C10.1 12.2 9.2 11.2 8.1 11.2Z"
      fill="currentColor"
    />
    <path
      d="M4 20H12.2V18.9C12.2 18.6 12 18.2 11.8 17.9C11.5 17.6 11.2 17.4 10.7 17.2C9.8 16.8 8.8 16.7 8.1 16.7C7.5 16.7 6.4 16.9 5.5 17.2C5 17.4 4.7 17.6 4.4 17.9C4.1 18.2 4 18.5 4 18.9V20H4Z"
      fill="currentColor"
    />
    <path
      d="M17.6004 11.2L19.9004 8.9C20.1004 8.7 20.0004 8.5 19.7004 8.4L16.7004 8C16.4004 8 16.1004 7.9 15.9004 7.7C15.6004 7.5 15.5004 7.2 15.5004 7.1L14.1004 4.3C14.1004 4.1 14.0004 4 13.9004 4C13.8004 4 13.7004 4.1 13.6004 4.2L12.3004 6.9C12.3004 7 12.1004 7.3 11.9004 7.5C11.6004 7.7 11.3004 7.8 11.0004 7.9L7.90036 8.3C7.70036 8.3 7.60036 8.6 7.70036 8.8L9.50036 10.7C10.4004 11.3 11.0004 12.3 11.0004 13.5C11.0004 14.5 10.6004 15.4 9.90036 16C9.90036 16.1 9.90036 16.1 10.0004 16.1C10.1004 16.1 10.1004 16.1 10.2004 16.2H10.3004L13.0004 14.6C13.1004 14.5 13.4004 14.4 13.8004 14.4C14.2004 14.4 14.4004 14.5 14.6004 14.6L17.3004 16.1H17.4004C17.6004 16.1 17.7004 16 17.7004 15.8L17.2004 12.6V12.5V12.4C17.2004 12.4 17.2004 12.1 17.3004 11.9C17.3004 11.5 17.5004 11.3 17.6004 11.2ZM15.3004 11.2C15.2004 11.7 15.1004 12.1 15.1004 12.4V12.5C15.1004 12.6 15.1004 12.6 15.1004 12.7C14.7004 12.5 14.2004 12.4 13.7004 12.4C13.3004 12.4 12.9004 12.5 12.5004 12.6C12.5004 12.5 12.5004 12.5 12.5004 12.4V12.3C12.5004 11.4 12.1004 10.4 11.5004 9.8C12.2004 9.7 12.7004 9.4 13.2004 9C13.5004 8.8 13.7004 8.5 13.9004 8.2C14.1004 8.5 14.4004 8.8 14.7004 9.1C15.1004 9.5 15.6004 9.7 16.2004 9.8C15.8004 10.3 15.5004 10.7 15.3004 11.2Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const ViewAllIcon = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" fill="none" {...props}>
    <circle cx="8.5" cy="8.93164" r="0.5" fill="#1565BC" />
    <path
      d="M10 8.93164C10 8.6555 10.2239 8.43164 10.5 8.43164H15.5C15.7761 8.43164 16 8.6555 16 8.93164C16 9.20778 15.7761 9.43164 15.5 9.43164H10.5C10.2239 9.43164 10 9.20778 10 8.93164Z"
      fill="#1565BC"
    />
    <circle cx="8.5" cy="11.9316" r="0.5" fill="#1565BC" />
    <path
      d="M10 11.9316C10 11.6555 10.2239 11.4316 10.5 11.4316H15.5C15.7761 11.4316 16 11.6555 16 11.9316C16 12.2078 15.7761 12.4316 15.5 12.4316H10.5C10.2239 12.4316 10 12.2078 10 11.9316Z"
      fill="#1565BC"
    />
    <circle cx="8.5" cy="14.9316" r="0.5" fill="#1565BC" />
    <path
      d="M10 14.9316C10 14.6555 10.2239 14.4316 10.5 14.4316H15.5C15.7761 14.4316 16 14.6555 16 14.9316C16 15.2078 15.7761 15.4316 15.5 15.4316H10.5C10.2239 15.4316 10 15.2078 10 14.9316Z"
      fill="#1565BC"
    />
    <rect x="4.5" y="4.93164" width="15" height="14" rx="1.5" stroke="#1565BC" />
  </Icon>
);

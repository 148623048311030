import { Icon } from '@chakra-ui/react';

export const StartIcon = (props) => (
  <Icon viewBox="0 0 24 25" boxSize="16px" fill="none" {...props}>
    <rect x="4.5" y="4.93164" width="15" height="15" rx="1.5" stroke="#1565BC" />
    <path
      d="M14.5 4.93164H18C18.8284 4.93164 19.5 5.60321 19.5 6.43164V18.4316C19.5 19.2601 18.8284 19.9316 18 19.9316H14.5V4.93164Z"
      fill="#1565BC"
      fillOpacity="0.2"
      stroke="#1565BC"
    />
    <path
      d="M7 12.3628C7 12.0867 7.22386 11.8628 7.5 11.8628H12.5C12.7761 11.8628 13 12.0867 13 12.3628V12.3628C13 12.6389 12.7761 12.8628 12.5 12.8628H7.5C7.22386 12.8628 7 12.6389 7 12.3628V12.3628Z"
      fill="#1565BC"
    />
    <path
      d="M9.93942 9.66386C10.1347 9.46859 10.4513 9.46859 10.6465 9.66386L13.173 12.1903C13.3682 12.3856 13.3682 12.7022 13.173 12.8974V12.8974C12.9777 13.0927 12.6611 13.0927 12.4659 12.8974L9.93942 10.371C9.74415 10.1757 9.74415 9.85912 9.93942 9.66386V9.66386Z"
      fill="#1565BC"
    />
    <path
      d="M10.0608 15.3208C10.256 15.516 10.5726 15.516 10.7679 15.3208L13.1749 12.9137C13.3702 12.7185 13.3702 12.4019 13.1749 12.2066V12.2066C12.9796 12.0114 12.663 12.0114 12.4678 12.2066L10.0608 14.6137C9.86549 14.8089 9.86549 15.1255 10.0608 15.3208V15.3208Z"
      fill="#1565BC"
    />
  </Icon>
);

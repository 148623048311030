import { Icon } from '@chakra-ui/react';

export const Peers = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M6.50031 16.0059C6.50031 14.0729 8.06731 12.5059 10.0003 12.5059H14.0003C15.9333 12.5059 17.5003 14.0729 17.5003 16.0059V18.9412C17.5003 19.2503 17.2497 19.501 16.9405 19.501H7.06011C6.75094 19.501 6.50031 19.2503 6.50031 18.9412V16.0059Z"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M14.7862 7.42648C14.7862 9.04244 13.4753 10.353 11.8576 10.353C10.2399 10.353 8.92902 9.04244 8.92902 7.42648C8.92902 5.81052 10.2399 4.5 11.8576 4.5C13.4753 4.5 14.7862 5.81052 14.7862 7.42648Z"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M6.30389 11.6855V11.6855C5.03151 11.6855 4.00004 12.7158 4.00004 13.9882C4.00004 15.1075 4.00004 16.5006 4.00004 17.8526C4.00004 18.4864 4.51384 19 5.14764 19V19"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M8.00018 5.5C7.50018 5 6.6914 5.1887 6.6914 5.1887C6.38809 5.31433 6.11249 5.49848 5.88035 5.73063C5.6482 5.96278 5.46405 6.23837 5.33841 6.54169C5.21278 6.845 5.14811 7.17009 5.14811 7.49839C5.14811 7.8267 5.21278 8.15179 5.33841 8.4551C5.46405 8.75842 5.6482 9.03402 5.88035 9.26616C6.27769 9.66433 7.50018 9.99793 8.00018 9.49905"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M17.6962 11.6855V11.6855C18.9685 11.6855 20 12.7158 20 13.9882C20 15.1075 20 16.5006 20 17.8526C20 18.4864 19.4862 19 18.8524 19V19"
      stroke="currentColor"
      fill="transparent"
    />
    <path
      d="M15.9999 5.5C16.4999 5 17.3087 5.1887 17.3087 5.1887C17.612 5.31433 17.8876 5.49848 18.1197 5.73063C18.3519 5.96278 18.536 6.23837 18.6616 6.54169C18.7873 6.845 18.8519 7.17009 18.8519 7.49839C18.8519 7.8267 18.7873 8.15179 18.6616 8.4551C18.536 8.75842 18.3519 9.03402 18.1197 9.26616C17.7224 9.66433 16.4999 9.99793 15.9999 9.49905"
      stroke="currentColor"
      fill="transparent"
    />
  </Icon>
);

function parseFeatureFlags(queryParam) {
  const flags = {};
  const flagPairs = queryParam.split(',');

  flagPairs.forEach((pair) => {
    const [flag, value] = pair.split(':');
    flags[flag] = value === 'true';
  });

  return flags;
}

export function selectEnabledFeatureFlags(state) {
  /**  This is done to provide override support for FF for testing environments only.
   *  Don't use this in production code.
   * Details: https://github.com/meshai/mesh-ui/pull/2897#issue-2249992132 */
  if (process.env.REACT_APP_ENABLE_FEATURE_FLAG_OVERRIDES === 'true') {
    const queryString = window.sessionStorage.getItem('overrideFeatureFlags') || '';
    const parsedFlags = parseFeatureFlags(queryString);

    const enabledFeatureFlags = state?.enabledFeatureFlags;

    if (Array.isArray(enabledFeatureFlags) && enabledFeatureFlags.length > 0) {
      Object.keys(parsedFlags).forEach((flag) => {
        if (parsedFlags[flag] === true) {
          enabledFeatureFlags.push(flag);
        } else {
          const index = enabledFeatureFlags.indexOf(flag);
          if (index !== -1) {
            enabledFeatureFlags.splice(index, 1);
          }
        }
      });
      return enabledFeatureFlags;
    }
  }
  return state?.enabledFeatureFlags;
}

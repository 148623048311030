import { CONTINUOUS_FEEDBACK_RELOAD_STATUS } from 'actions/actionTypes';

export const initialState = {
  reloadType: null,
  reloadStats: false,
  primaryCallbackFunction: null,
};

const continuousFeedback = (state = initialState, action) => {
  switch (action.type) {
    case CONTINUOUS_FEEDBACK_RELOAD_STATUS.RELOAD_FEEDBACK_LIST:
      return {
        ...state,
        reloadType: action.data.reloadType,
      };
    case CONTINUOUS_FEEDBACK_RELOAD_STATUS.CLEAR_FEEDBACK_LIST:
      return {
        ...state,
        reloadType: null,
      };
    case CONTINUOUS_FEEDBACK_RELOAD_STATUS.RELOAD_FEEDBACK_STATS:
      return {
        ...state,
        reloadStats: true,
      };
    case CONTINUOUS_FEEDBACK_RELOAD_STATUS.CLEAR_RELOAD_FEEDBACK_STATS_STATE:
      return {
        ...state,
        reloadStats: false,
      };
    case CONTINUOUS_FEEDBACK_RELOAD_STATUS.ADD_PRIMARY_CALLBACK:
      return {
        ...state,
        primaryCallbackFunction: action.payload,
      };
    default:
      return state;
  }
};

export default continuousFeedback;

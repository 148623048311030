import { SET_EMPLOYEE_GOALS_ACCESS_PRIVILEGES } from 'actions/actionTypes';

const initialState = [];

export default function employeeGoalsAccessPrivileges(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEE_GOALS_ACCESS_PRIVILEGES: {
      const accessPrivileges = action.payload.accessPrivileges ?? [];
      const accessPrivilegesList = Array.isArray(accessPrivileges) ? accessPrivileges.map(({ name }) => name) : [];

      return accessPrivilegesList;
    }

    default:
      return state;
  }
}

import { Icon } from '@chakra-ui/react';

export const DisplayName = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M4 17H20C20 17.8284 19.3284 18.5 18.5 18.5H5.5C4.67157 18.5 4 17.8284 4 17Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 6.5H6.5C6.22386 6.5 6 6.72386 6 7V15.5H18V7C18 6.72386 17.7761 6.5 17.5 6.5ZM6.5 5.5C5.67157 5.5 5 6.17157 5 7V16.5H19V7C19 6.17157 18.3284 5.5 17.5 5.5H6.5Z"
      fill="currentColor"
    />
    <path
      d="M11.5 11C10.675 11 10 10.325 10 9.5C10 8.675 10.675 8 11.5 8C12.325 8 13 8.675 13 9.5C13 10.325 12.325 11 11.5 11ZM11.5 9C11.225 9 11 9.225 11 9.5C11 9.775 11.225 10 11.5 10C11.775 10 12 9.775 12 9.5C12 9.225 11.775 9 11.5 9ZM14.5 14H8.5V13.235C8.5 11.985 10.485 11.445 11.5 11.445C12.515 11.445 14.5 11.985 14.5 13.235V14ZM9.655 13H13.345C13 12.72 12.155 12.44 11.5 12.44C10.845 12.44 9.995 12.72 9.655 13Z"
      fill="currentColor"
    />
    <path d="M16 8.5H13.5V9.3H16V8.5Z" fill="currentColor" />
    <path d="M16 10H14V10.8H16V10Z" fill="currentColor" />
  </Icon>
);

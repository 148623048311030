import { Icon } from '@chakra-ui/react';

export const ViewStar = (props) => (
  <Icon viewBox="0 0 18 18" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8.99983 14.5198L13.1498 17.0298C13.9098 17.4898 14.8398 16.8098 14.6398 15.9498L13.5398 11.2298L17.2098 8.04977C17.8798 7.46977 17.5198 6.36977 16.6398 6.29977L11.8098 5.88977L9.91983 1.42977C9.57983 0.619766 8.41983 0.619766 8.07983 1.42977L6.18983 5.87977L1.35983 6.28977C0.479829 6.35977 0.119828 7.45977 0.789828 8.03977L4.45983 11.2198L3.35983 15.9398C3.15983 16.7998 4.08983 17.4798 4.84983 17.0198L8.99983 14.5198Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const PeerSettingsV2 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <circle cx="8.5" cy="6.50391" r="2" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4264 10.034C10.2871 10.0142 10.1447 10.0039 10 10.0039H7C5.34315 10.0039 4 11.3471 4 13.0039V15.2086C4 15.6478 4.35609 16.0039 4.79534 16.0039H8.71276C8.57355 15.6868 8.4672 15.3521 8.39802 15.0039H5V13.0039C5 11.8993 5.89543 11.0039 7 11.0039H9.37761C9.67738 10.6316 10.0309 10.3043 10.4264 10.034Z"
      fill="currentColor"
    />
    <path
      d="M15 18.0039L15.0384 18.0037L14.8841 17.0006C13.8335 16.9406 13 16.0696 13 15.0039C13 13.8993 13.8954 13.0039 15 13.0039C16.0657 13.0039 16.9367 13.8374 16.9967 14.888L17.9998 15.0423L18 15.0039C18 13.3471 16.6569 12.0039 15 12.0039C13.3431 12.0039 12 13.3471 12 15.0039C12 16.6608 13.3431 18.0039 15 18.0039Z"
      fill="currentColor"
    />
    <path
      d="M15 15.0039L16.0861 20.0039L16.5783 16.9965C16.6134 16.7819 16.7809 16.6139 16.9945 16.5789L20 16.0863L15 15.0039Z"
      fill="currentColor"
    />
    <path
      d="M14.9515 19.9515C13.972 19.942 13.0174 19.6423 12.2083 19.0902C11.3992 18.5381 10.772 17.7585 10.4059 16.85C10.0399 15.9414 9.95153 14.9447 10.1519 13.986C10.3524 13.0272 10.8326 12.1494 11.5319 11.4635C12.2312 10.7777 13.1182 10.3146 14.0807 10.1328C15.0432 9.9511 16.038 10.0588 16.9392 10.4425C17.8405 10.8261 18.6078 11.4683 19.144 12.288C19.6802 13.1077 19.9614 14.068 19.9519 15.0474"
      stroke="currentColor"
    />
  </Icon>
);

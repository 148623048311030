import { useMemo } from 'react';

import { useSelector } from 'react-redux';

const goalCustomNamesConfigSelector = (state) => state.orgData.goalCustomNamesConfig;

const useOrganisationSettings = () => {
  const goalCustomNamesConfig = useSelector(goalCustomNamesConfigSelector);

  return useMemo(() => {
    const configToUse = goalCustomNamesConfig;

    return {
      /* Title strings */
      moduleName: configToUse?.moduleName,
      objectivesName: configToUse?.objectivesName,
      keyResultsName: configToUse?.keyResultsName,
    };
  }, [goalCustomNamesConfig]);
};

export default useOrganisationSettings;

// for add post
export const UPDATE_ADD_POST_VISIBILITY = 'UPDATE_ADD_POST_VISIBILITY';
export const UPDATE_ADD_POST_SENTIMENT = 'UPDATE_ADD_POST_SENTIMENT';
export const CHANGE_ADD_POST_VISIBILITY_UPDATED_BY_USER_STATUS = 'CHANGE_ADD_POST_VISIBILITY_UPDATED_BY_USER_STATUS';

export const POST_ADD_HASH_TAG = 'POST_ADD_HASH_TAG';
export const POST_UPDATE_EDITOR_STATE = 'POST_UPDATE_EDITOR_STATE';
export const POST_TOGGLE_UPDATE_STATUS = 'POST_TOGGLE_UPDATE_STATUS';
export const POST_UPDATE_IMAGES = 'POST_UPDATE_IMAGES';
export const POST_UPDATE_GIFS = 'POST_UPDATE_GIFS';
export const POST_UPDATE = 'POST_UPDATE';
export const POST_CLEAR = 'POST_CLEAR';
export const POST_ADD_MENTION = 'POST_ADD_MENTION';
// addPost -> tagRecommendations
export const UPDATE_TAGS_RECOMMENDATIONS = 'UPDATE_TAG_RECOMMENDATIONS';

// for loggedin user
export const UPDATE_LOGGED_IN_EMPLOYEE = 'LOGGED_IN_USER_UPDATE';
export const AUTHENTICATE_LOGGED_IN_EMPLOYEE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const ATTEMPT_TO_AUTHENTICATE = 'ATTEMPT_TO_AUTHENTICATE';

// for enterComment
export const UPDATE_ENTER_COMMENT_STATE = 'UPDATE_ENTER_COMMENT_STATE';
export const UPDATE_ENTER_COMMENT_IMAGE = 'UPDATE_ENTER_COMMENT_IMAGE';

// for feed
export const UPDATE_FEED = 'UPDATE_FEED';
export const ADD_FEED = 'ADD_FEED';
export const CLEAR_FEED = 'CLEAR_FEED';
export const ADD_COMMENTS_TO_FEED = 'ADD_COMMENT_TO_FEED';
export const UPDATE_FEED_COMMENTS = 'UPDATE_FEED_COMMENTS';
export const UPDATE_FEED_COMMENT = 'UPDATE_FEED_COMMENT';
export const UPDATE_FEED_TALKING_POINT_COMMENT = 'UPDATE_FEED_TALKING_POINT_COMMENT';
export const UPDATE_FEED_ITEM = 'UPDATE_FEED_ITEM';

// for snacbar
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

// for imagePreview
export const SHOW_IMAGE_PREVIEW_MODAL = 'SHOW_IMAGE_PREVIEW_MODAL';
export const HIDE_IMAGE_PREVIEW_MODAL = 'HIDE_IMAGE_PREVIEW_MODAL';

// for taskDetails
export const SHOW_TASK_DETAILS = 'SHOW_TASK_DETAILS';
export const HIDE_TASK_DETAILS = 'HIDE_TASK_DETAILS';
export const UPDATE_TASK_DETAILS = 'UPDATE_TASK_DETAILS';

// for datePicker
export const OPEN_DATE_PICKER = 'OPEN_DATE_PICKER';
export const CLOSE_DATE_PICKER = 'CLOSE_DATE_PICKER';

// for dateTimePicker
export const OPEN_DATETIME_PICKER = 'OPEN_DATETIME_PICKER';
export const CLOSE_DATETIME_PICKER = 'CLOSE_DATETIME_PICKER';

// for gifModal
export const OPEN_GIF_MODAL = 'OPEN_GIF_MODAL';
export const CLOSE_GIF_MODAL = 'CLOSE_GIF_MODAL';

// for create sub-objective modal
export const SHOW_CREATE_SUB_OBJECTIVE_MODAL = 'SHOW_CREATE_SUB_OBJECTIVE_MODAL';
export const HIDE_CREATE_SUB_OBJECTIVE_MODAL = 'HIDE_CREATE_SUB_OBJECTIVE_MODAL';
export const ADD_MS_TEAMS_CONTEXT = 'ADD_MS_TEAMS_CONTEXT';

// for continuous feedback sidepanel
export const CONTINUOUS_FEEDBACK_RELOAD_STATUS = {
  RELOAD_FEEDBACK_LIST: 'RELOAD_FEEDBACK_LIST',
  CLEAR_FEEDBACK_LIST: 'CLEAR_FEEDBACK_LIST',
  RELOAD_FEEDBACK_STATS: 'RELOAD_FEEDBACK_STATS',
  CLEAR_RELOAD_FEEDBACK_STATS_STATE: 'CLEAR_RELOAD_FEEDBACK_STATS_STATE',
  ADD_PRIMARY_CALLBACK: 'ADD_PRIMARY_CALLBACK',
};

// for questionDetails
export const SHOW_QUESTION_DETAILS = 'SHOW_QUESTION_DETAILS';
export const HIDE_QUESTION_DETAILS = 'HIDE_QUESTION_DETAILS';
// export const UPDATE_QUESTION_DETAILS = 'UPDATE_QUESTION_DETAILS';

// for nominatePeers
export const SHOW_NOMINATE_PEERS_SIDEPANEL = 'SHOW_NOMINATE_PEERS_SIDEPANEL';
export const HIDE_NOMINATE_PEERS_SIDEPANEL = 'HIDE_NOMINATE_PEERS_SIDEPANEL';

// for organization specific data accessible throughout the app
export const ADD_ORG_DATA = 'ADD_ORG_DATA';

// for team page filters
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_ALL = 'RESET_ALL';
export const SET_TEAM = 'SET_TEAM';
export const SET_TAG_ID = 'SET_TAG_ID';

// filter menus
export const FILTER_MENU_OPEN = 'FILTER_MENU_OPEN';

// for objectiveCreationModal
export const OPEN_OBJECTIVE_CREATION_MODAL = 'OPEN_OBJECTIVE_CREATION_MODAL';
export const CLOSE_OBJECTIVE_CREATION_MODAL = 'CLOSE_OBJECTIVE_CREATION_MODAL';

// for reviewCalibrationModal
export const OPEN_REVIEW_CALIBRATION_MODAL = 'OPEN_REVIEW_CALIBRATION_MODAL';
export const CLOSE_REVIEW_CALIBRATION_MODAL = 'CLOSE_REVIEW_CALIBRATION_MODAL';

// for reorder confirmation modal reducer
export const OPEN_REORDER_CONFIRMATION_MODAL = 'OPEN_REORDER_CONFIRMATION_MODAL';
export const CLOSE_REORDER_CONFIRMATION_MODAL = 'CLOSE_REORDER_CONFIRMATION_MODAL';
// for ObjectivePromptModal
export const OPEN_OBJECTIVE_PROMPT_MODAL = 'OPEN_OBJECTIVE_PROMPT_MODAL';
export const CLOSE_OBJECTIVE_PROMPT_MODAL = 'CLOSE_OBJECTIVE_PROMPT_MODAL';

// hellobars
export const HELLO_BAR_ACTIONS = {
  SET_COUNT: 'SET_COUNT',
  CLEAR_COUNT: 'CLEAR_COUNT',
  FORCE_REFRESH: 'FORCE_REFRESH',
};

export const SET_GMAIL_OUTLOOK_APP = 'SET_GMAIL_OUTLOOK_APP';

export const OPEN_GENERIC_DRAWER = 'OPEN_GENERIC_DRAWER';
export const CLOSE_GENERIC_DRAWER = 'CLOSE_GENERIC_DRAWER';
export const CLOSE_ALL_GENERIC_DRAWER = 'CLOSE_ALL_GENERIC_DRAWER';
// for CompetencySidePanel
export const OPEN_COMPETENCY_SIDE_PANEL = 'OPEN_COMPETENCY_SIDE_PANEL';
export const CLOSE_COMPETENCY_SIDE_PANEL = 'CLOSE_COMPETENCY_SIDE_PANEL';

// for cohort base reset
export const OPEN_COHORT_BASE_RESET_MODAL = 'OPEN_COHORT_BASE_RESET_MODAL';
export const CLOSE_COHORT_BASE_RESET_MODAL = 'CLOSE_COHORT_BASE_RESET_MODAL';

// for enabledFeatureFlags
export const SET_ENABLED_FEATURE_FLAGS = 'SET_ENABLED_FEATURE_FLAGS';

// for passing data to agenda components
export const ADD_TO_AGENDA_QUEUE = 'ADD_TO_AGENDA_QUEUE';
export const REMOVE_FROM_AGENDA_QUEUE = 'REMOVE_FROM_AGENDA_QUEUE';
export const ENABLE_AGENDA_QUEUE = 'ENABLE_AGENDA_QUEUE';
export const DISABLE_AGENDA_QUEUE = 'DISABLE_AGENDA_QUEUE';

export const GLOBAL_GOALS_MAP_ADD_GOALS = 'GLOBAL_GOAL_MAP_ADD_GOALS';
export const GLOBAL_GOALS_MAP_UPDATE_GOAL = 'GLOBAL_GOAL_MAP_UPDATE_GOAL';
export const GLOBAL_GOALS_MAP_DELETE_GOAL = 'GLOBAL_GOAL_MAP_DELETE_GOAL';
export const OBJECTIVE_DETAILS_PANEL_OPEN = 'OBJECTIVE_DETAILS_PANEL_OPEN';
export const OBJECTIVE_DETAILS_PANEL_CLOSE = 'OBJECTIVE_DETAILS_PANEL_CLOSE';
export const OBJECTIVE_DETAILS_PANEL_GOBACK = 'OBJECTIVE_DETAILS_PANEL_GOBACK';

export const CREATE_KR_PANEL_OPEN = 'CREATE_KR_PANEL_OPEN';
export const CREATE_KR_PANEL_CLOSE = 'CREATE_KR_PANEL_CLOSE';

export const TOGGLE_GROUPBY_MANAGER_CLICKTHROUGH = 'TOGGLE_GROUPBY_MANAGER_CLICKTHROUGH';

export const CREATE_INITIATIVE_PANEL_OPEN = 'CREATE_INITIATIVE_PANEL_OPEN';
export const CREATE_INITIATIVE_PANEL_CLOSE = 'CREATE_INITIATIVE_PANEL_CLOSE';

export const SET_EMPLOYEE_GOALS_ACCESS_PRIVILEGES = 'SET_EMPLOYEE_GOALS_ACCESS_PRIVILEGES';

export const SET_USER_TIMEZONE = 'SET_USER_TIMEZONE';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // selectedCohort: null,
  // cohorts: null,
  isExpanded: true,
};

export const sidebarNavSlice = createSlice({
  name: 'sidebarNav',
  initialState,
  reducers: {
    // changeSelectedCohort: (state, action) => {
    //   state.selectedCohort = action.payload;
    // },
    // getAllOneOnOnecohorts: (state, action) => {
    //   state.cohorts = action.payload;
    // },
    // clearSelectedCohort: (state) => {
    //   state.selectedCohort = null;
    // },
    openSidebar: (state) => {
      state.isExpanded = true;
    },
    closeSidebar: (state) => {
      state.isExpanded = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  // changeSelectedCohort,
  // clearSelectedCohort,
  // getAllOneOnOnecohorts,
  openSidebar,
  closeSidebar,
} = sidebarNavSlice.actions;

export default sidebarNavSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import {
  checkEngagementModuleEnabled,
  checkFeedbackModuleEnabled,
  checkGoalModuleEnabled,
  checkLevelUpModuleEnabled,
  checkOneOnOneModuleEnabled,
  checkReviewModuleEnabled,
  checkTaskModuleEnabled,
} from 'utils/helpers/moduleAccess';

export const SUMMARY_TYPE_IDS = {
  ALL: 'ALL',
  PERFORMANCE: 'PERFORMANCE',
  ENGAGEMENT: 'ENGAGEMENT',
  COACHING: 'COACHING',
};

export const SUMMARY_TYPES = [
  {
    text: 'All',
    id: SUMMARY_TYPE_IDS.ALL,
    enabled: () =>
      checkGoalModuleEnabled() ||
      checkTaskModuleEnabled() ||
      checkReviewModuleEnabled() ||
      checkOneOnOneModuleEnabled() ||
      checkFeedbackModuleEnabled() ||
      checkLevelUpModuleEnabled(),
  },
  {
    text: 'Performance',
    id: SUMMARY_TYPE_IDS.PERFORMANCE,
    enabled: () => checkGoalModuleEnabled() || checkTaskModuleEnabled() || checkReviewModuleEnabled(),
  },
  {
    text: 'Engagement',
    id: SUMMARY_TYPE_IDS.ENGAGEMENT,
    enabled: () => checkEngagementModuleEnabled(),
  },
  {
    text: 'Coaching',
    id: SUMMARY_TYPE_IDS.COACHING,
    enabled: () => checkOneOnOneModuleEnabled() || checkFeedbackModuleEnabled() || checkLevelUpModuleEnabled(),
  },
];
const initialState = {
  mySummaryView: [SUMMARY_TYPES[0].id],
};

export const mySummaryViewSlice = createSlice({
  name: 'mySummaryView',
  initialState,
  reducers: {
    setMySummaryView: (state, action) => {
      state.mySummaryView = action.payload;
    },
  },
});

export const { setMySummaryView } = mySummaryViewSlice.actions;

export default mySummaryViewSlice.reducer;

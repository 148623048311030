import { Icon } from '@chakra-ui/react';

export const CompanyIcon = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00098 4.07097C6.14804 3.88714 6.40336 3.7041 6.75489 3.7041H9.24378C9.59531 3.7041 9.85063 3.88714 9.99769 4.07097C10.1279 4.23368 10.2216 4.45453 10.2216 4.68188V4.74117H12.3549C12.5614 4.74117 12.8387 4.79324 13.0596 5.01421C13.2806 5.23518 13.3327 5.51248 13.3327 5.71895V12.2967H2.66602V5.71895C2.66602 5.51248 2.71808 5.23518 2.93906 5.01421C3.16003 4.79324 3.43733 4.74117 3.64379 4.74117H5.77711V4.68188C5.77711 4.45453 5.87081 4.23368 6.00098 4.07097ZM5.77711 6.0745H3.99935V7.85223H5.77711V6.0745ZM7.11045 7.85223V6.0745H8.88823V7.85223H7.11045ZM5.77711 9.18557H3.99935V10.9633H5.77711V9.18557ZM7.11045 10.9633V9.18557H8.88823V10.9633H7.11045ZM10.2216 10.9633H11.9993V9.18557H10.2216V10.9633ZM10.2216 7.85223H11.9993V6.0745H10.2216V7.85223Z"
      fill="currentColor"
    />
  </Icon>
);

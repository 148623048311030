import { Icon } from '@chakra-ui/react';

export const DoubleArrowLeft = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M5.23499 12.0122C5.23499 11.7471 5.34954 11.4928 5.5532 11.3053L9.89783 7.30539C10.1002 7.11237 10.3784 7.00238 10.6697 7.00004C10.961 6.99769 11.2412 7.10333 11.4473 7.29283C11.6533 7.48251 11.7678 7.74047 11.7653 8.00867C11.7627 8.27685 11.6433 8.53297 11.4338 8.71931L7.08916 12.7192C6.88551 12.9067 6.60909 13.012 6.32109 13.012C6.03308 13.012 5.75685 12.9067 5.55319 12.7192C5.34954 12.5317 5.23499 12.2774 5.23499 12.0122Z"
      fill="currentColor"
    />
    <path
      d="M5.23499 12.0122C5.23499 11.7471 5.34954 11.4928 5.5532 11.3053C5.75686 11.1178 6.03297 11.0127 6.32098 11.0127C6.60899 11.0127 6.88522 11.118 7.08888 11.3055L11.4335 15.3054C11.6999 15.5593 11.8014 15.924 11.6999 16.2645C11.5984 16.6051 11.3095 16.8712 10.9396 16.9646C10.5696 17.0578 10.1738 16.9647 9.89789 16.7193L5.55319 12.7192C5.34954 12.5317 5.23499 12.2774 5.23499 12.0122Z"
      fill="currentColor"
    />
    <path
      d="M12.2347 12.0122C12.2347 11.7471 12.3493 11.4928 12.553 11.3053L16.8976 7.30539C17.1 7.11237 17.3782 7.00238 17.6695 7.00004C17.9608 6.99769 18.241 7.10333 18.447 7.29283C18.653 7.48251 18.7676 7.74047 18.765 8.00867C18.7625 8.27685 18.643 8.53297 18.4336 8.71931L14.0889 12.7192C13.8853 12.9067 13.6089 13.012 13.3208 13.012C13.0328 13.012 12.7566 12.9067 12.5529 12.7192C12.3493 12.5317 12.2347 12.2774 12.2347 12.0122Z"
      fill="currentColor"
    />
    <path
      d="M12.2347 12.0122C12.2347 11.7471 12.3493 11.4928 12.553 11.3053C12.7566 11.1178 13.0327 11.0127 13.3207 11.0127C13.6087 11.0127 13.885 11.118 14.0886 11.3055L18.4333 15.3054C18.6997 15.5593 18.8011 15.924 18.6997 16.2645C18.5982 16.6051 18.3093 16.8712 17.9394 16.9646C17.5694 17.0578 17.1735 16.9647 16.8976 16.7193L12.5529 12.7192C12.3493 12.5317 12.2347 12.2774 12.2347 12.0122Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const GradeMissing = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_3844_27167)">
      <path
        d="M3.90234 12.3262C3.90234 11.5862 4.50222 10.9863 5.2422 10.9863C5.36797 10.9863 5.48005 11.0657 5.52185 11.1843L8.09561 18.4878C8.34628 19.1991 9.34991 19.2056 9.6098 18.4976L12.3097 11.1426C12.3442 11.0487 12.4336 10.9863 12.5336 10.9863C13.3702 10.9863 14.0485 11.6646 14.0485 12.5012V18.1635C14.0485 18.8967 13.4541 19.4911 12.7208 19.4911H5.22999C4.49675 19.4911 3.90234 18.8967 3.90234 18.1635V12.3262Z"
        stroke="currentColor"
        fill="transparent"
      />
      <path d="M7.93262 13.1323L9.03098 16.5608L10.1294 13.1323L9.03098 11.418L7.93262 13.1323Z" fill="currentColor" />
      <path
        d="M11.8265 7.41881C11.8265 9.05511 10.5566 10.3474 9.03143 10.3474C7.50626 10.3474 6.23633 9.05511 6.23633 7.41881C6.23633 5.7825 7.50626 4.49023 9.03143 4.49023C10.5566 4.49023 11.8265 5.7825 11.8265 7.41881Z"
        stroke="currentColor"
        fill="transparent"
      />
      <path
        d="M15 12V12C16.2228 12 17.2142 12.9907 17.2142 14.2136C17.2142 15.1628 17.2142 16.3206 17.2142 17.4868C17.2142 18.4962 16.3959 19.3145 15.3865 19.3145H15.1502"
        stroke="currentColor"
        fill="transparent"
      />
      <path
        d="M12.4782 5.49567C12.7773 5.4257 13.0878 5.41974 13.3894 5.47818C13.691 5.53662 13.9769 5.65813 14.2282 5.83475C14.4796 6.01138 14.6907 6.23912 14.8479 6.50307C15.0051 6.76701 15.1047 7.06119 15.1403 7.36633C15.1758 7.67147 15.1465 7.98067 15.0541 8.27368C14.9618 8.56668 14.8087 8.83687 14.6046 9.06653C14.4006 9.2962 14.1503 9.48015 13.8703 9.60634C13.5902 9.73253 13.2866 9.7981 12.9794 9.79876"
        stroke="currentColor"
        fill="transparent"
      />
      <path d="M17 7L20 10" stroke="currentColor" fill="transparent" />
      <path d="M17 10L20 7" stroke="currentColor" fill="transparent" />
    </g>
    <defs>
      <clipPath id="clip0_3844_27167">
        <rect width="24" height="24" rx="8" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const EmptyStar = (props) => (
  <Icon viewBox="0 0 14 14" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M13.6666 5.49341L8.87325 5.08008L6.99992 0.666748L5.12659 5.08675L0.333252 5.49341L3.97325 8.64675L2.87992 13.3334L6.99992 10.8467L11.1199 13.3334L10.0333 8.64675L13.6666 5.49341ZM6.99992 9.60008L4.49325 11.1134L5.15992 8.26008L2.94659 6.34008L5.86659 6.08675L6.99992 3.40008L8.13992 6.09341L11.0599 6.34675L8.84659 8.26675L9.51325 11.1201L6.99992 9.60008Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const AddKeyresultsPlus = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path d="M10 5L9 4H4V17H5V11H8L9 12H14V5H10ZM13 11H9.5L8.5 10H5V5H8.5L9.5 6H13V11Z" fill="currentColor" />
    <mask id="path-2-outside-1_163_110" maskUnits="userSpaceOnUse" x="12" y="12" width="9" height="9" fill="black">
      <rect fill="none" x="12" y="12" width="9" height="9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 19.5C16 19.7761 16.2239 20 16.5 20C16.7761 20 17 19.7761 17 19.5V17H19.5C19.7761 17 20 16.7761 20 16.5C20 16.2239 19.7761 16 19.5 16H17V13.5C17 13.2239 16.7761 13 16.5 13C16.2239 13 16 13.2239 16 13.5V16H13.5C13.2239 16 13 16.2239 13 16.5C13 16.7761 13.2239 17 13.5 17H16V19.5Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19.5C16 19.7761 16.2239 20 16.5 20C16.7761 20 17 19.7761 17 19.5V17H19.5C19.7761 17 20 16.7761 20 16.5C20 16.2239 19.7761 16 19.5 16H17V13.5C17 13.2239 16.7761 13 16.5 13C16.2239 13 16 13.2239 16 13.5V16H13.5C13.2239 16 13 16.2239 13 16.5C13 16.7761 13.2239 17 13.5 17H16V19.5Z"
      fill="currentColor"
    />
    <path
      d="M17 17V16H16V17H17ZM17 16H16V17H17V16ZM16 16V17H17V16H16ZM16 17H17V16H16V17ZM16.5 19C16.7761 19 17 19.2239 17 19.5H15C15 20.3284 15.6716 21 16.5 21V19ZM16 19.5C16 19.2239 16.2239 19 16.5 19V21C17.3284 21 18 20.3284 18 19.5H16ZM16 17V19.5H18V17H16ZM19.5 16H17V18H19.5V16ZM19 16.5C19 16.2239 19.2239 16 19.5 16V18C20.3284 18 21 17.3284 21 16.5H19ZM19.5 17C19.2239 17 19 16.7761 19 16.5H21C21 15.6716 20.3284 15 19.5 15V17ZM17 17H19.5V15H17V17ZM16 13.5V16H18V13.5H16ZM16.5 14C16.2239 14 16 13.7761 16 13.5H18C18 12.6716 17.3284 12 16.5 12V14ZM17 13.5C17 13.7761 16.7761 14 16.5 14V12C15.6716 12 15 12.6716 15 13.5H17ZM17 16V13.5H15V16H17ZM13.5 17H16V15H13.5V17ZM14 16.5C14 16.7761 13.7761 17 13.5 17V15C12.6716 15 12 15.6716 12 16.5H14ZM13.5 16C13.7761 16 14 16.2239 14 16.5H12C12 17.3284 12.6716 18 13.5 18V16ZM16 16H13.5V18H16V16ZM17 19.5V17H15V19.5H17Z"
      fill="#EFF5FD"
      mask="url(#path-2-outside-1_163_110)"
    />
  </Icon>
);

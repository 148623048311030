import { TEAM_TYPE } from 'constants/ProjectConstants';

// if teamType is not HRIS then it is a pod, otherwise it is a team
export default class Team {
  constructor({ id, name, userRole, teamType, createdDate, visibilityType, color, teamEmployees }) {
    this.id = id;
    this.name = name;
    this.userRole = userRole;
    this.teamType = teamType;
    this.createdDate = createdDate;
    this.visibilityType = visibilityType;
    this.color = color;
    this.teamEmployees = teamEmployees;
  }

  static fromJSON(json) {
    return new Team({
      id: json.team.uuid,
      name: json.team.name,
      userRole: json.userRole,
      teamType: json.teamType,
      createdDate: json.team.createdTS,
      visibilityType: json.team.visibilityType,
      color: json.color,
      teamEmployees: json.team.teamEmployees,
    });
  }

  static fromJSON1(json) {
    return new Team({
      id: json.uuid,
      name: json.name,
      userRole: json.userRole,
      teamType: json.teamType,
      createdDate: json.createdTS,
      visibilityType: json.visibilityType,
      color: json.color,
      teamEmployees: json.teamEmployees,
    });
  }

  isRoleManager = () => this.userRole && this.userRole === 'MANAGER';

  isPrimaryPod = () => this.teamType === TEAM_TYPE.preferred;
}

import { Icon } from '@chakra-ui/react';

export const Praise = (props) => (
  <Icon viewBox="0 0 23 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M13.4518 8.50184L13.5044 8.55444L16.2293 11.637L16.9027 12.405C17.6496 13.394 18.0915 14.6354 18.102 15.9821C18.102 16.3398 18.081 16.687 18.0179 17.0236C18.0179 17.0342 18.0073 17.0447 18.0073 17.0657L17.9968 17.0763C17.4918 19.8853 15.051 22 12.0947 22C11.274 22 10.4955 21.8316 9.7801 21.5476C9.05417 21.232 8.40188 20.7901 7.84428 20.243L2.3314 14.7301C1.88953 14.2883 1.88953 13.5728 2.3314 13.1204C2.77328 12.6786 3.48869 12.6891 3.93056 13.131L2.68911 11.8685C2.24724 11.4266 2.24724 10.7007 2.68911 10.2588C3.13098 9.81693 3.85691 9.81693 4.29879 10.2588L3.35192 9.31193C2.91005 8.87006 2.91005 8.15465 3.35192 7.70226C3.79379 7.26039 4.49868 7.26039 4.95107 7.71278L5.9295 8.69121C5.48763 8.24934 5.48763 7.52341 5.9295 7.08153C6.37137 6.63966 7.08679 6.63966 7.53918 7.08153L13.9989 13.5413C14.3672 13.4361 12.1473 10.4587 11.7685 9.97474C11.4213 9.51183 11.4739 8.8385 11.9158 8.44923C12.3682 8.05996 13.031 8.09153 13.4518 8.50184Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.91797 8.70222L9.69492 12.4792"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.32031 10.3009L8.09726 14.0673"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.72266 11.8995L6.48909 15.6765"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7993 21.3371C17.6294 21.2108 19.944 19.1382 20.4385 16.4239L20.449 16.4134C20.449 16.4029 20.4595 16.3923 20.4595 16.3713C20.5226 16.0346 20.5437 15.6874 20.5437 15.3297C20.5331 13.9831 20.1018 12.7416 19.3443 11.7527L18.671 10.9847L15.9461 7.90208L15.8935 7.84948C15.4727 7.42865 14.8099 7.40761 14.3469 7.80739C13.9051 8.19666 13.8525 8.86999 14.1997 9.3329"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8644 8.3543L9.97061 6.46056C9.52874 6.01869 8.81333 6.01869 8.36094 6.46056C8.03479 6.78671 7.95063 7.27066 8.10844 7.68097"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.30469 3.29405L9.54666 2"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1055 3.81972L11.7683 2.67296"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5781 5.1146L13.6302 4.3045"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

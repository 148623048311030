export const colors = {
  '#FFFFFF': '#FFFFFF',
  '#000000': '#000000',
  '#EC3B64': `#EC3B64`,
  '#008EF0': `#008EF0`,
  '#5CC461': `#5CC461`,
  '#FFC200': `#FFC200`,
  '#B96400': `#B96400`,
  '#237C73': `#237C73`,
  '#2F8133': `#2F8133`,
  '#C01443': `#C01443`,
  '#1565BC': `#1565BC`,
  '#7651BC': `#7651BC`,
  '#333333': `#333333`,
  '#C62C02': `#C62C02`,
  '#A12402': `#A12402`,
  '#AA2602': `#AA2602`,
  '#F6B9B9': `#F6B9B9`,
  '#8C6A00': `#8C6A00`,
  '#4657AE': `#4657AE`,
  '#FD812D': `#FD812D`,
  '#7E5BC0': `#7E5BC0`,
  '#666666': `#666666`,
  '#ED5554': `#ED5554`,
  '#F0B51E': `#F0B51E`,
  '#5D6DBE': `#5D6DBE`,
  '#2FA69A': `#2FA69A`,
  '#FFEFD5': `#FFEFD5`,
  '#FFF9F7': `#FFF9F7`,
  '#C0FFF9': `#C0FFF9`,
  '#BFFFC2': `#BFFFC2`,
  '#FAD4D4': `#FAD4D4`,
  '#D6E7F9': `#D6E7F9`,
  '#E7DAFF': `#E7DAFF`,
  '#F2F2F2': `#F2F2F2`,
  '#FFE1D9': `#FFE1D9`,
  '#FEECBD': `#FEECBD`,
  '#CDD5FF': `#CDD5FF`,
  '#999999': `#999999`,
  '#F5F5F5': `#F5F5F5`,
  '#F5F7F9': `#F5F7F9`,
  '#EBEFF3': `#EBEFF3`,
  '#595E6A': `#595E6A`,
  '#323846': `#323846`,
  '#104F95': `#104F95`,
  '#125AA9': `#125AA9`,
  '#99D2F9': `#99D2F9`,
  '#E6F1FD': `#E6F1FD`,
  '#CFD7DF': `#CFD7DF`,
  '#F6FAFE': `#F6FAFE`,
  '#DBF2D3': `#DBF2D3`,
  '#FFEFD4': `#FFEFD4`,
  '#44812F': `#44812F`,
  '#1A5724': `#1A5724`,
  '#91CA7D': `#91CA7D`,
  '#FFE7C5': `#FFE7C5`,
  '#FFDE88': `#FFDE88`,
  '#FD9B9B': `#FD9B9B`,
  '#AF5C20': '#AF5C20',
  '#CCCCCC': '#CCCCCC',
  '#F6F6F7': '#F6F6F7',
  '#FAFAFA': '#FAFAFA',
  '#E9F6FF': '#E9F6FF',
  '#FDFDFD': '#FDFDFD',
  '#BFE8C6': '#BFE8C6',
  '#FFF6E4': '#FFF6E4',
  '#FFF1E4': '#FFF1E4',
  '#EFFFEA': '#EFFFEA',
  '#EEEEEE': '#EEEEEE',
  '#EAEAEA': '#EAEAEA',
  '#FFF6D3': '#FFF6D3',
  '#CAE8F0': '#CAE8F0',
};

// Same colors but with hsl code
// export const colors = {
//   '#EC3B64': `hsla(346, 82%, 58%, 1)`,
//   '#008EF0': `hsla(205, 100%, 47%, 1)`,
//   '#5CC461': `hsla(123, 47%, 56%, 1)`,
//   '#FFC200': `hsla(46, 100%, 50%, 1)`,
//   '#B96400': `hsla(32, 100%, 36%, 1)`,
//   '#237C73': `hsla(174, 56%, 31%, 1)`,
//   '#2F8133': `hsla(123, 47%, 35%, 1)`,
//   '#C01443': `hsla(344, 81%, 42%, 1)`,
//   '#1565BC': `hsla(211, 80%, 41%, 1)`,
//   '#7651BC': `hsla(261, 44%, 53%, 1)`,
//   '#333333': `hsla(0, 0%, 20%, 1)`,
//   '#C62C02': `hsla(13, 98%, 39%, 1)`,
//   '#AA2402': `hsla(12, 98%, 34%, 1)`,
//   '#AA2602': `hsla(13, 98%, 34%, 1)`,
//   '#F6B9B9': `hsla(0, 77%, 85%, 1)`,
//   '#8C6A00': `hsla(45, 100%, 27%, 1)`,
//   '#4657AE': `hsla(230, 43%, 48%, 1)`,
//   '#FD812D': `hsla(24, 98%, 58%, 1)`,
//   '#7E5BC0': `hsla(261, 44%, 55%, 1)`,
//   '#666666': `hsla(0, 0%, 40%, 1)`,
//   '#ED5554': `hsla(0, 81%, 63%, 1)`,
//   '#F0B51E': `hsla(43, 87%, 53%, 1)`,
//   '#5D6DBE': `hsla(230, 43%, 55%, 1)`,
//   '#2FA69A': `hsla(174, 56%, 42%, 1)`,
//   '#FFEFD5': `hsla(37, 100%, 92%, 1)`,
//   '#C0FFF9': `hsla(174, 100%, 88%, 1)`,
//   '#BFFFC2': `hsla(123, 100%, 88%, 1)`,
//   '#FAD4D4': `hsla(0, 78%, 91%, 1)`,
//   '#D6E7F9': `hsla(211, 73%, 91%, 1)`,
//   '#E7DAFF': `hsla(261, 100%, 93%, 1)`,
//   '#F2F2F2': `hsla(0, 0%, 95%, 1)`,
//   '#FFE1D9': `hsla(13, 100%, 93%, 1)`,
//   '#FEECBD': `hsla(43, 97%, 87%, 1)`,
//   '#CDD5FF': `hsla(230, 100%, 90%, 1)`,
//   '#000000': `hsla(0, 0%, 0%, 1)`,
//   '#999999': `hsla(0, 0%, 60%, 1)`,
//   '#F5F7F9': `hsla(210, 25%, 97%, 1)`,
//   '#EBEFF3': `hsla(210, 25%, 94%, 1)`,
//   '#595E6A': `hsla(222, 9%, 38%, 1)`,
//   '#323846': `hsla(222, 17%, 24%, 1)`,
//   '#104F95': `hsla(212, 81%, 32%, 1)`,
//   '#125AA9': `hsla(211, 81%, 37%, 1)`,
//   '#99D2F9': `hsla(204, 89%, 79%, 1)`
// };

import { Icon } from '@chakra-ui/react';

export const StarBordered = (props) => (
  <Icon viewBox="0 0 38 38" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M18.9999 24.0604L22.804 26.3613C23.5007 26.7829 24.3532 26.1596 24.1699 25.3713L23.1616 21.0446L26.5257 18.1296C27.1399 17.5979 26.8099 16.5896 26.0032 16.5254L21.5757 16.1496L19.8432 12.0613C19.5315 11.3188 18.4682 11.3188 18.1565 12.0613L16.424 16.1404L11.9966 16.5163C11.1899 16.5804 10.8599 17.5888 11.4741 18.1204L14.8382 21.0354L13.8299 25.3621C13.6466 26.1504 14.499 26.7738 15.1957 26.3521L18.9999 24.0604Z"
      fill="#666666"
    />
    <rect x="0.5" y="0.500732" width="37" height="37" rx="3.5" stroke="#CCCCCC" />
  </Icon>
);

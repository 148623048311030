import { Icon } from '@chakra-ui/react';

export const MyDevelop = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M18.6999 4H16.2999C15.5799 4 14.9999 4.58 14.9999 5.3V7H13.2999C12.5799 7 11.9999 7.58 11.9999 8.3V10H10.2999C9.80992 10 9.38992 10.29 9.16992 10.69C10.2699 11.17 11.0499 12.31 11.0499 13.64C11.0499 14.13 10.9399 14.59 10.7499 15H18.6999C19.4199 15 19.9999 14.42 19.9999 13.7V5.3C19.9999 4.58 19.4199 4 18.6999 4ZM17.9899 10.87C17.9599 10.95 17.8899 11 17.8199 11H17.2599V13H15.7599V11H15.1999C15.1199 11 15.0599 10.95 15.0299 10.87C14.9999 10.79 15.0199 10.7 15.0699 10.65L16.3799 9.06C16.4499 8.98 16.5699 8.98 16.6499 9.06L17.9599 10.65C18.0099 10.71 18.0299 10.8 17.9999 10.87H17.9899Z"
      fill="currentColor"
    />
    <path
      d="M12 20H4V18.85C4 18.5 4.15 18.16 4.46 17.87C4.72 17.61 5.08 17.39 5.53 17.2C6.44 16.81 7.43 16.67 8.01 16.67C8.59 16.67 9.57 16.81 10.49 17.2C10.94 17.39 11.3 17.62 11.56 17.87C11.86 18.17 12.02 18.5 12.02 18.86V20.01L12 20ZM8 15.83C6.87 15.83 5.95 14.85 5.95 13.64C5.95 12.43 6.87 11.45 8 11.45C9.13 11.45 10.05 12.43 10.05 13.64C10.05 14.85 9.13 15.83 8 15.83Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const AddObjective = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 10C16 10.1673 15.9932 10.333 15.9797 10.4969L14.9883 10.3444C14.9961 10.2306 15 10.1157 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C10.1157 15 10.2306 14.9961 10.3444 14.9883L10.4969 15.9797C10.333 15.9932 10.1673 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10ZM10 13L10.0384 12.9998L9.88411 11.9967C8.83347 11.9367 8 11.0657 8 10C8 8.89543 8.89543 8 10 8C11.0657 8 11.9367 8.83347 11.9967 9.88411L12.9998 10.0384L13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13ZM10 10L11.0861 15L11.5783 11.9926C11.6134 11.778 11.7809 11.61 11.9945 11.575L15 11.0824L10 10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19.5C16 19.7761 16.2239 20 16.5 20C16.7761 20 17 19.7761 17 19.5V17H19.5C19.7761 17 20 16.7761 20 16.5C20 16.2239 19.7761 16 19.5 16H17V13.5C17 13.2239 16.7761 13 16.5 13C16.2239 13 16 13.2239 16 13.5V16H13.5C13.2239 16 13 16.2239 13 16.5C13 16.7761 13.2239 17 13.5 17H16V19.5Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const KeyResult = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M14 7L13 6H6V18H7.59798V14.0789H12.5L13.5 15H18V7H14ZM16.4261 13.3968H13.7686L12.875 12.5H7.59798V7.56824H12L13.1161 8.5H16.4261V13.3968Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Refresh = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M17.6418 6.35097C16.0118 4.72097 13.7018 3.78097 11.1618 4.04097C7.49184 4.41097 4.47184 7.39097 4.06184 11.061C3.51184 15.911 7.26184 20.001 11.9918 20.001C15.1818 20.001 17.9218 18.131 19.2018 15.441C19.5218 14.771 19.0418 14.001 18.3018 14.001C17.9318 14.001 17.5818 14.201 17.4218 14.531C16.2918 16.961 13.5818 18.501 10.6218 17.841C8.40184 17.351 6.61184 15.541 6.14184 13.321C5.30184 9.44097 8.25184 6.00097 11.9918 6.00097C13.6518 6.00097 15.1318 6.69097 16.2118 7.78097L14.7018 9.29097C14.0718 9.92097 14.5118 11.001 15.4018 11.001H18.9918C19.5418 11.001 19.9918 10.551 19.9918 10.001V6.41097C19.9918 5.52097 18.9118 5.07097 18.2818 5.70097L17.6418 6.35097Z"
      fill="currentColor"
    />
  </Icon>
);

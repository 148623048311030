import { Icon } from '@chakra-ui/react';

export const ExclamationMark = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M7.18696 16.0743L5.5 17.6738V6C5.5 5.44772 5.94772 5 6.5 5H17.5C18.0523 5 18.5 5.44772 18.5 6V14.8C18.5 15.3523 18.0523 15.8 17.5 15.8H7.875H7.47629L7.18696 16.0743Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <mask id="path-2-inside-1_483_16" fill="currentColor">
      <path d="M12.5 11C11.9477 11 11.5 10.5523 11.5 10V8C11.5 7.44772 11.9477 7 12.5 7V7C13.0523 7 13.5 7.44772 13.5 8V10C13.5 10.5523 13.0523 11 12.5 11V11Z" />
    </mask>
    <path
      d="M13.5 10V8H9.5V10H13.5ZM11.5 8V10H15.5V8H11.5ZM11.5 10C11.5 9.44772 11.9477 9 12.5 9V13C14.1569 13 15.5 11.6569 15.5 10H11.5ZM12.5 9C11.9477 9 11.5 8.55228 11.5 8H15.5C15.5 6.34315 14.1569 5 12.5 5V9ZM13.5 8C13.5 8.55228 13.0523 9 12.5 9V5C10.8431 5 9.5 6.34315 9.5 8H13.5ZM9.5 10C9.5 11.6569 10.8431 13 12.5 13V9C13.0523 9 13.5 9.44772 13.5 10H9.5Z"
      fill="currentColor"
      mask="url(#path-2-inside-1_483_16)"
    />
    <path
      d="M13 13C13 13.2761 12.7761 13.5 12.5 13.5C12.2239 13.5 12 13.2761 12 13C12 12.7239 12.2239 12.5 12.5 12.5C12.7761 12.5 13 12.7239 13 13Z"
      stroke="currentColor"
    />
  </Icon>
);

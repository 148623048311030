import { Icon } from '@chakra-ui/react';

export const Checkin = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M19 12.0049C19 15.8724 15.8702 19 12 19C8.12976 19 5 15.8724 5 12.0049C5 8.13742 8.13963 5 12 5C12.3357 5 12.6714 5.01973 12.9972 5.07893V6.08249C12.6729 6.02823 12.3397 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18C15.3137 18 18 15.3137 18 12C18 11.6622 17.9721 11.331 17.9185 11.0085H18.921C18.9803 11.3242 19 11.6695 19 12.0049Z"
      fill="currentColor"
    />
    <path d="M9 11L12 14L18.9999 7L17.9999 6L12 12L9.99997 9.99999L9 11Z" fill="currentColor" />
  </Icon>
);

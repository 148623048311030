import { POST_VISIBILITY_TYPES } from 'constants/ProjectConstants';
import { getDateFromEpoch } from 'constants/helper';

import { FEED_TYPES } from 'services/helpers/feed';
import { convertTextMacrosToStringifiedContentState } from 'services/helpers/post';

import Employee from './employee';

export default class Post {
  static SUB_TYPES = { automatedComment: 'AUTOMATED_COMMENT' };

  constructor({
    macrosRes,
    fromEmployee,
    toEmployee,
    text = '',
    media = [],
    createdDate,
    updatedDate,
    visibilityType,
    type,
    contentState,
    id,
    subType,
    feedback,
  } = {}) {
    this.id = id;
    this.macrosRes = macrosRes;
    this.fromEmployee = fromEmployee;
    this.toEmployee = toEmployee;
    this.text = text;
    this.media = media;
    this.createdDate = createdDate;
    this.updatedDate = updatedDate;
    this.visibilityType = visibilityType;
    this.type = type;
    this.contentState = contentState;
    this.subType = subType;
    this.feedback = feedback;
  }

  static fromJSON(post) {
    const { type } = post;
    const { text } = post;
    const mediasRes = post.media || [];
    const medias = mediasRes.map((res) => Media.fromJSON(res));
    const visibilityType = POST_VISIBILITY_TYPES[(post.visibilityType || 'global').toLowerCase()];
    const postObject = new Post({
      fromEmployee: new Employee(null, post.from),
      toEmployee: new Employee(null, post.to),
      text,
      media: medias,
      type,
      id: post.uuid,
      // text: parseTextComponent(post["text"], post["macros"]),
      macrosRes: post.macros,
      createdDate: getDateFromEpoch(post.createdTS),
      updatedDate: getDateFromEpoch(post.updatedTS),
      visibilityType,
      // contentState: post.contentState,
      contentState: [FEED_TYPES.post, 'COMMENT'].includes(type)
        ? convertTextMacrosToStringifiedContentState(post.text, post.macros)
        : null,
      subType: post.postSubType,
      feedback: post.feedback,
    });
    return postObject;
  }
}

class Media {
  constructor({ id, url, type }) {
    this.id = id;
    this.uuid = id;
    this.url = url;
    this.type = type;
  }

  static fromJSON(media) {
    return new Media({
      id: media.uuid,
      url: media.url,
      type: media.type,
    });
  }
}

import { Icon } from '@chakra-ui/react';

export const Goals = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_836_48176)">
      <path
        d="M17.68 12.9101C17.68 16.5539 14.7339 19.5001 11.09 19.5001C7.44614 19.5001 4.5 16.5539 4.5 12.9101C4.5 9.26574 7.4566 6.31006 11.09 6.31006C11.2642 6.31006 11.4344 6.31564 11.6 6.32916V7.36152C11.4287 7.34674 11.2573 7.34006 11.09 7.34006C8.02322 7.34006 5.53 9.83456 5.53 12.9101C5.53 15.9856 8.02322 18.4801 11.09 18.4801C14.1568 18.4801 16.65 15.9856 16.65 12.9101C16.65 12.7428 16.6433 12.5714 16.6285 12.4001H17.6612C17.6744 12.5648 17.68 12.7364 17.68 12.9101Z"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0004 8.45L17.5704 10.88H15.0204L14.8704 11.03L11.4504 14.45C10.9704 14.92 10.2004 14.92 9.73043 14.45C9.26043 13.97 9.26043 13.2 9.73043 12.73L12.9804 9.49L13.1204 9.34V6.43L15.5404 4V8.45H20.0004Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_836_48176">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const RabbitPost = (props) => (
  <Icon viewBox="0 0 12 12" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8.2798 1.51942C8.18837 1.50662 8.09496 1.5 8 1.5H2C0.895431 1.5 0 2.39543 0 3.5V9.5C0 10.6046 0.89543 11.5 2 11.5H8C9.10457 11.5 10 10.6046 10 9.5V5.4393L9 6.4999V9.5C9 10.0523 8.55229 10.5 8 10.5H2C1.44772 10.5 1 10.0523 1 9.5V3.5C1 2.94772 1.44772 2.5 2 2.5H7.15101L8.2798 1.51942Z"
      fill="currentColor"
    />
    <path
      d="M9.7551 1.4732C10.1777 1.06441 10.8273 1.05856 11.2565 1.45967C11.6535 1.83063 11.6554 2.48059 11.2607 2.85415L7.77928 6.14889L6.3476 6.14887L6.3476 4.76956L9.7551 1.4732Z"
      fill="currentColor"
    />
    <rect x="1.90894" y="7.76184" width="6.68126" height="0.934552" rx="0.467276" fill="currentColor" />
    <path
      d="M1.90894 6.35998C1.90894 6.10191 2.11814 5.8927 2.37621 5.8927H4.30506C4.56313 5.8927 4.77233 6.10191 4.77233 6.35998C4.77233 6.61805 4.56313 6.82725 4.30506 6.82725H2.37621C2.11814 6.82725 1.90894 6.61805 1.90894 6.35998Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Warning = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12 5.49359L19.53 18.5036H4.47L12 5.49359ZM2.74 17.5036C1.97 18.8336 2.93 20.5036 4.47 20.5036H19.53C21.07 20.5036 22.03 18.8336 21.26 17.5036L13.73 4.49359C12.96 3.16359 11.04 3.16359 10.27 4.49359L2.74 17.5036ZM11 10.5036V12.5036C11 13.0536 11.45 13.5036 12 13.5036C12.55 13.5036 13 13.0536 13 12.5036V10.5036C13 9.95359 12.55 9.50359 12 9.50359C11.45 9.50359 11 9.95359 11 10.5036ZM11 15.5036H13V17.5036H11V15.5036Z"
      fill="currentColor"
    />
  </Icon>
);

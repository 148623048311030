import { Icon } from '@chakra-ui/react';

export const Decrease = (props) => (
  <Icon viewBox="0 0 14 8" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M10.0975 7.30083L11.0575 6.34083L7.80416 3.08749L5.61083 5.28083C5.35083 5.54083 4.93083 5.54083 4.67083 5.28083L0.67083 1.27416C0.41083 1.01416 0.41083 0.59416 0.67083 0.33416C0.93083 0.0741602 1.35083 0.0741602 1.61083 0.33416L5.1375 3.86749L7.33083 1.67416C7.59083 1.41416 8.01083 1.41416 8.27083 1.67416L11.9975 5.39416L12.9575 4.43416C13.1642 4.22749 13.5242 4.37416 13.5242 4.66749V7.52749C13.5242 7.71416 13.3775 7.86083 13.1908 7.86083H10.3308C10.0375 7.86749 9.89083 7.50749 10.0975 7.30083Z"
      fill="currentColor"
    />
  </Icon>
);

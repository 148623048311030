import { Icon } from '@chakra-ui/react';

export const Google = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.52 12.2729C23.52 11.422 23.4436 10.6038 23.3018 9.81836H12V14.4602H18.4582C18.18 15.9602 17.3345 17.2311 16.0636 18.082V21.0929H19.9418C22.2109 19.0038 23.52 15.9274 23.52 12.2729Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.9983C15.24 23.9983 17.9564 22.9238 19.9418 21.091L16.0636 18.0801C14.9891 18.8001 13.6145 19.2256 12 19.2256C8.87455 19.2256 6.22909 17.1147 5.28546 14.2783H1.27637V17.3874C3.25091 21.3092 7.30909 23.9983 12 23.9983Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28545 14.2794C5.04545 13.5594 4.90909 12.7904 4.90909 11.9994C4.90909 11.2085 5.04545 10.4394 5.28545 9.71944V6.61035H1.27636C0.463636 8.23035 0 10.0631 0 11.9994C0 13.9358 0.463636 15.7685 1.27636 17.3885L5.28545 14.2794Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.77273C13.7618 4.77273 15.3436 5.37818 16.5873 6.56727L20.0291 3.12545C17.9509 1.18909 15.2346 0 12 0C7.3091 0 3.25091 2.68909 1.27637 6.61091L5.28546 9.72C6.22909 6.88364 8.87455 4.77273 12 4.77273Z"
      fill="#EA4335"
    />
  </Icon>
);

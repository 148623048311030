/**
 *  Reducer: 'auth'
 */
export const UPDATE_CURRENT_EMAIL = 'AUTH/UPDATE_CURRENT_EMAIL';
export const UPDATE_CURRENT_TEMP_PASSWORD = 'AUTH/UPDATE_CURRENT_TEMP_PASSWORD';
export const UPDATE_REFERRAL_CODE = 'AUTH/UPDATE_REFERRAL_CODE';
export const UPDATE_PENDING_INVITATIONS = 'AUTH/UPDATE_PENDING_INVITATIONS';
export const UPDATE_REDIRECT_TO_TEAM = 'AUTH/UPDATE_REDIRECT_TO_TEAM';
export const UPDATE_IS_PENDING_INVITE_ACCEPTED = 'AUTH/UPDATE_IS_PENDING_INVITE_ACCEPTED';

const initialState = {
  currentEmail: '',
  currentTempPassword: '',
  referralCode: '',
  pendingInvitations: null,
  inviteTeamId: '',
  isPendingInviteAccepted: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_EMAIL:
      return {
        ...state,
        currentEmail: action.currentEmail,
      };

    case UPDATE_CURRENT_TEMP_PASSWORD:
      return {
        ...state,
        currentTempPassword: action.currentTempPassword,
      };

    case UPDATE_REFERRAL_CODE:
      return {
        ...state,
        referralCode: action.referralCode,
      };

    case UPDATE_PENDING_INVITATIONS:
      return {
        ...state,
        pendingInvitations: action.pendingInvitations,
      };

    case UPDATE_REDIRECT_TO_TEAM:
      return {
        ...state,
        inviteTeamId: action.inviteTeamId,
      };

    case UPDATE_IS_PENDING_INVITE_ACCEPTED:
      return {
        ...state,
        isPendingInviteAccepted: action.isPendingInviteAccepted,
      };

    default:
      return state;
  }
};

const actions = {
  updateCurrentEmail: (currentEmail) => ({ type: UPDATE_CURRENT_EMAIL, currentEmail }),
  updateCurrentTempPassword: (currentTempPassword) => ({
    type: UPDATE_CURRENT_TEMP_PASSWORD,
    currentTempPassword,
  }),
  updateReferralCode: (referralCode) => ({ type: UPDATE_REFERRAL_CODE, referralCode }),
  updatePendingInvitations: (pendingInvitations) => ({
    type: UPDATE_PENDING_INVITATIONS,
    pendingInvitations,
  }),
  updateInviteTeamId: (inviteTeamId) => ({ type: UPDATE_REDIRECT_TO_TEAM, inviteTeamId }),
  updateIsPendingInviteAccepted: (isPendingInviteAccepted) => ({
    type: UPDATE_IS_PENDING_INVITE_ACCEPTED,
    isPendingInviteAccepted,
  }),
};

/**
 *  Action: 'updateCurrentEmail'
 */
export const updateCurrentEmail = (email) => (dispatch) => {
  dispatch(actions.updateCurrentEmail(email));
};

/**
 *  Action: 'updateCurrentTempPassword'
 */
export const updateCurrentTempPassword = (password) => (dispatch) => {
  dispatch(actions.updateCurrentTempPassword(password));
};

/**
 *  Action: 'updateReferralCode'
 */
export const updateReferralCode = (referralCode) => (dispatch) => {
  dispatch(actions.updateReferralCode(referralCode));
};

/**
 *  Action: 'updatePendingInvitations'
 */
export const updatePendingInvitations = (pendingInvitations) => (dispatch) => {
  dispatch(actions.updatePendingInvitations(pendingInvitations));
};

/**
 *  Action: 'updateInviteTeamId'
 */
export const updateInviteTeamId = (inviteTeamId) => (dispatch) => {
  dispatch(actions.updateInviteTeamId(inviteTeamId));
};

/**
 *  Action: 'updateIsPendingInviteAccepted'
 */
export const updateIsPendingInviteAccepted = (isPendingInviteAccepted) => (dispatch) => {
  dispatch(actions.updateIsPendingInviteAccepted(isPendingInviteAccepted));
};

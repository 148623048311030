import { createSlice } from '@reduxjs/toolkit';

// export const SECONDARY_PANEL_TYPES = {
//   REALIGN_OKR: 'REALIGN_OKR' /* This type is when user clicks on realign button */,
//   REALIGN_CHILDREN:
//     'REALIGN_CHILDREN' /* This type is when user clicks on realign children (from inside modal) */,
//   REALIGN_OKR_WITHOUT_CHILDREN_REALIGN_CONFIRMATION_MODAL:
//     'REALIGN_OKR_WITHOUT_CHILDREN_REALIGN_CONFIRMATION_MODAL' /* This type is when user has already realigned children or has denied children realignment */
// };

export const SECONDARY_PANEL_TYPES = {
  ADD_INITIATIVE: 'ADD_INITIATIVE' /* This type is when user clicks on add new initiative  button */,
  ADD_INITIATIVE_SUBTASK: 'ADD_INITIATIVE_SUBTASK' /* This type is when user clicks on add new initiative  button */,
  REALIGN_CHILDREN: 'REALIGN_CHILDREN' /* This type is when user clicks on realign children (from inside modal) */,
  REALIGN_OKR_WITHOUT_CHILDREN_REALIGN_CONFIRMATION_MODAL:
    'REALIGN_OKR_WITHOUT_CHILDREN_REALIGN_CONFIRMATION_MODAL' /* This type is when user has already realigned children or has denied children realignment */,
};

const initialState = {
  type: null,
  initiativeSubtaskDetails: {
    text: '',
    to: null,
    macros: {
      employees: {},
      demographics: {},
      attributes: {
        orgTag: {},
        money: null,
      },
    },
    from: null,
    dueDate: null,
    dueTS: new Date().valueOf(),
    teams: [],
    toIds: [],
    taskType: 'TASK',
    parentPost: null,
    type: 'TASK',
    actual: 0,
    visibilityType: 'PRIVATE',
    startTS: new Date().valueOf(),
    description: '',
    tags: null,
    startingProgressValue: 0,
    weightage: 0,
    removedTeam: null,
  },
};

export const initiativeSecondaryPanelSlice = createSlice({
  name: 'initiativeSecondaryPanelSlice',
  initialState,
  reducers: {
    openSubtaskCreationPanel: (state, action) => ({
      type: SECONDARY_PANEL_TYPES.ADD_INITIATIVE_SUBTASK,
      initiativeSubtaskDetails: {
        ...state.initiativeSubtaskDetails,
        ...action.payload,
      },
    }),

    onSubtaskChange: (state, action) => {
      state.initiativeSubtaskDetails = {
        ...state.initiativeSubtaskDetails,
        ...action.payload,
      };
    },

    closeSecondaryPanel: (state, action) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { openSubtaskCreationPanel, closeSecondaryPanel, onSubtaskChange } = initiativeSecondaryPanelSlice.actions;

export default initiativeSecondaryPanelSlice.reducer;

import { Icon } from '@chakra-ui/react';

export const LevelUpIcon = (props) => (
  <Icon viewBox="0 0 25 25" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M17.498 13.972L15.7483 11.5906C15.6507 11.4698 15.4924 11.4698 15.3948 11.5906L13.6446 13.972C13.5731 14.0605 13.5516 14.1934 13.5904 14.3091C13.629 14.4248 13.7203 14.5 13.8213 14.5H14.5713L14.5716 17.5H16.5715L16.5713 14.5H17.3212C17.4223 14.5 17.5136 14.4248 17.5522 14.3091C17.591 14.1934 17.5695 14.0605 17.498 13.972Z"
      fill="currentColor"
    />
    <path
      d="M15.059 8.62213H16.059V7.62213V5.50011L19.5713 5.50002V19.5H5.57129V15.9879H7.69342H8.69342V14.9879V13.8659H9.81515H10.8151V12.8659V11.744H11.937H12.937V10.744V8.62213H15.059Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </Icon>
);

import { TEAM_TYPE } from 'constants/ProjectConstants';
import { maleIconSrc, femaleIconSrc } from 'constants/icons';

import Task1 from './task1';
import Team from './team';

function round(num) {
  return Math.round((num + Number.EPSILON) * 10) / 10;
}

class Employee {
  fullName;

  firstName;

  lastName;

  organisationId;

  gender;

  designation;

  department;

  profilePhotoSrc;

  location;

  tenure;

  id;

  aggregatedValues;

  externalEmployeeId;

  displayName;

  joiningDate;

  resignationDate;

  country;

  preferredFirstName;

  dateOfBirth;

  selfExpressions = [];

  // TODO: set profile id when parsing json response
  profileId;

  lastUserEvent;

  userRole;

  planType;

  organizationStatus;

  enabledFeatures;

  weightage;

  isHRISManager = false;

  ethnicity;

  jobFunctionId;

  jobGradeUI;

  employeeAccesses;

  jobGradeNameUI;

  jobRoleId;

  employeeFunction;

  role;

  canCreateSharedCohort;

  timezone;

  experienceBeforeJoining;
  customFields;

  constructor(props, json) {
    if (props) this.fromProps(props);
    if (json) this.fromJSON(json);
  }

  fromProps = ({ id, fullName, name }) => {
    this.id = id;
    this.fullName = fullName;
    this.name = name;
  };

  addOrganizationDetails = (res) => {
    if (res) {
      this.organisationId = res.id;
      this.planType = res.planType;
      this.organizationStatus = res.organizationStatus;
    }
  };

  // expects employeeProfile object
  addProfilePhoto = (res) => {
    this.profilePhotoSrc = res != null ? res.profilePhoto : '';
  };

  addMoney(res) {
    if (!res) {
      this.money = {};
      return;
    }

    this.money = {
      received: round(Number(res.receivedMeshMoneyBalance)),
      allocated: round(Number(res.sharedMeshMoneyBalance)),
    };
  }

  addDetailsFromMetaPojoJSON(res) {
    if (res != null) {
      this.firstName = res.firstName;
      this.lastName = res.lastName;
      // this.fullName = `${res['firstName']} ${res['lastName'] || ''}`;
      this.fullName = res.displayName || '';
      this.gender = res.gender;
      this.designation = res.designation || '';
      this.department = res.department || '';
      this.location = res.location || '';
      this.tenure = res.tenure != null ? res.tenure.displayData : null;
      this.email = res.email;
      this.aggregatedValues = res.aggregatedValues;
      this.externalEmployeeId = res.externalEmployeeId;
      this.displayName = res.displayName;
      this.joiningDate = res.joiningDate;
      this.resignationDate = res.resignationDate;
      this.country = res.country;
      this.preferredFirstName = res.preferredFirstName;
      this.dateOfBirth = res.dateOfBirth;
      this.manager = {
        email: res.managerEmail,
      };
      this.employeeFunction = res.employeeFunction;
      this.grade = res.grade;
      this.ethnicity = res.ethnicity;
      this.jobFunctionId = res.jobFunctionId;
      this.jobGradeUI = res.jobGradeUI;
      this.jobGradeNameUI = res.jobGradeNameUI;
      this.jobRoleId = res.jobRoleId;
      this.employeeFunction = res.employeeFunction;
      this.role = res.role;
      this.experienceBeforeJoining = res.experienceBeforeJoining;
      this.customFields = res.customFields;
    }
  }

  fromJSON(res = {}, shouldAddSelfExpressions = false) {
    this.weightage = res.weightage || 0;
    this.isSignUpCompletionPending = res.signUpCompletionPending;
    this.addDetailsFromMetaPojoJSON(res.metadata);
    this.addProfilePhoto(res.profile);
    this.active = res.active;
    // if (shouldAddSelfExpressions)
    //   this.addSelfExpressions(res);
    this.addOrganizationDetails(res.organization);
    this.addMoney(res.profile);
    this.addTeams(res.teams || res.teamBasicDetails);
    this.id = res.uuid;
    this.lastUserEvent = res.lastUserEvent;
    this.userRole = res.userRole;
    this.invitedUser = res.invitedUser;
    this.image = this.getProfileImage();
    this.ownerType = res.ownerType || Task1.EMPLOYEE_TYPES.collaborator;
    this.managerName = res.managerName ?? '';
    this.managerEmpId = res.managerEmpId;
    this.managerProfilePhoto = res.managerProfilePhoto ?? '';
    // this.invitedUser = true;
    if (res.organization) {
      const {
        uuid: id,
        orgName,
        planType,
        currency,
        organizationStatus,
        country,
        objectiveSettings,
      } = res.organization;
      this.organization = {
        id,
        name: orgName,
        planType,
        organizationStatus,
        country,
        currency,
        objectiveSettings,
      };
    } else {
      this.organization = {
        id: null,
        name: null,
        planType: null,
        organizationStatus: null,
        country: null,
        objectiveSettings: null,
      };
    }
    this.enabledFeatures = res.enabledFeatures;
    this.authToken = res.authToken ?? null;
    this.isHRISManager = res?.isHRISManager || false;
    this.employeeAccesses = res.employeeAccesses ?? [];
    this.canCreateSharedCohort = res.canCreateSharedCohort;
    this.metadata = res.metadata;
    this.timezone = res.timezone;
    this.customFields = res.customFields;
  }

  static getDefaultImage(gender) {
    if (gender === 'MALE') return maleIconSrc;
    return femaleIconSrc;
  }

  isEnterpriseUser = () => this.organization && this.organization.planType === 'ENTERPRISE';

  isUnassignedUser = () => this.firstName && this.firstName === 'Unassigned';

  getProfileImage = () => {
    if (this.profilePhotoSrc) {
      return this.profilePhotoSrc;
    }
    if (this.gender === 'MALE') return maleIconSrc;
    return femaleIconSrc;
  };

  addTeams = (teamsJSON = []) => {
    const teams = [];
    teamsJSON && teamsJSON.forEach((teamJSON) => teams.push(Team.fromJSON(teamJSON)));
    this.teams = teams;
  };

  static getEmployeeObjectFromRes(res = {}) {
    const emp = new Employee({});
    emp.fromJSON(res);
    return emp;
  }

  // if any of the team contains MANAGER role for this employee
  // then the employee is a manager
  isManager = () => {
    if (!this.teams) return false;
    for (let i = 0; i < this.teams.length; i++) {
      if (this.teams[i].isRoleManager()) return true;
    }
  };

  isHRISManager = () => {
    if (!this.teams) return false;

    let isManager = false;
    this.teams.forEach((team) => {
      if (team.teamType === TEAM_TYPE.hris && team.isRoleManager()) isManager = true;
    });

    return isManager;
  };

  isPreferredTeamManager = () => {
    if (!this.teams) return false;
    const preferredTeam = this.teams.filter((t) => t.teamType === 'DEFAULT')[0];
    if (preferredTeam && preferredTeam.userRole === 'MANAGER') {
      return true;
    }
    return false;
  };

  isWhitelistedOrg = () => {
    const blacklistedOrgs = ['1259'];
    if (blacklistedOrgs.includes(this.organization.id)) {
      return false;
    }
    return true;
  };

  isSuperAdmin = () => this.userRole && this.userRole === 'ADMIN';

  getFirstTeam = () => {
    if (!this.teams) {
      return null;
    }
    return this.teams[0];
  };

  // static getProfileImageFn = (emp) => {
  //   if (emp.profilePhotoSrc) {
  //     return emp.profilePhotoSrc;
  //   } else if (this.gender === "MALE")
  //     return ProjectConstants.MALE_PROFILE_ICON;
  //   else return ProjectConstants.FEMALE_PROFILE_ICON;
  // }

  // TODO: when you add self expression model, use this
  // addSelfExpressions(res){
  //   if (
  //       res["employeeProfile"] != null &&
  //       res["employeeProfile"]["employeeSelfExpressions"] != null) {
  //     this.selfExpressions = res["employeeProfile"]["employeeSelfExpressions"]
  //         .map((expression) => SelfExpressionPojo.fromJson(expression));
  //   }
  // }

  getMyManagerTeams = () => {
    if (!this.teams) return [];
    return this.teams.filter((team) => team.isRoleManager());
  };

  getSearchLabel = () => {
    const result = [];
    if (this.displayName) result.push(this.displayName);
    if (this.location) result.push(this.location);
    if (this.designation) result.push(this.designation);
    return result.join(', ');
  };

  isFeatureEnabled = (feature) =>
    this.enabledFeatures ? this.enabledFeatures.filter((f) => f === feature).length > 0 : false;
}

export default Employee;

export function getPrimaryPod() {
  return this.teams ? this.teams.filter((team) => team.isPrimaryPod())[0] || { name: '' } : { name: '' };
}

export class BasicEmployee {
  constructor({ id, displayName, designation, profilePhotoSrc }) {
    this.id = id;
    this.displayName = displayName;
    this.profilePhotoSrc = profilePhotoSrc;
    this.designation = designation;
  }

  static fromJSON({ id, displayName, designation, profilePhoto: profilePhotoSrc }) {
    return new BasicEmployee({
      id,
      displayName,
      designation,
      profilePhotoSrc,
    });
  }
}


import { Icon } from '@chakra-ui/react';
export const ExpandAllListStroke = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
<path d="M9 6.85903L11.8284 4L14.6569 6.85903H9Z" fill="currentColor"/>
<path d="M9 17.141L11.8284 20L14.6569 17.141H9Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7 14H7.3C7.13431 14 7 14.1343 7 14.3C7 14.4657 7.13431 14.6 7.3 14.6H16.7C16.8657 14.6 17 14.4657 17 14.3C17 14.1343 16.8657 14 16.7 14ZM7.3 13C6.58203 13 6 13.582 6 14.3C6 15.018 6.58203 15.6 7.3 15.6H16.7C17.418 15.6 18 15.018 18 14.3C18 13.582 17.418 13 16.7 13H7.3Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7 10.0547H7.3C7.13431 10.0547 7 10.189 7 10.3547C7 10.5204 7.13431 10.6547 7.3 10.6547H16.7C16.8657 10.6547 17 10.5204 17 10.3547C17 10.189 16.8657 10.0547 16.7 10.0547ZM7.3 9.05469C6.58203 9.05469 6 9.63672 6 10.3547C6 11.0727 6.58203 11.6547 7.3 11.6547H16.7C17.418 11.6547 18 11.0727 18 10.3547C18 9.63672 17.418 9.05469 16.7 9.05469H7.3Z" fill="currentColor"/>
<path d="M9 6.85903L11.8284 4L14.6569 6.85903H9Z" stroke="currentColor" stroke-width="0.5"/>
<path d="M9 17.141L11.8284 20L14.6569 17.141H9Z" stroke="currentColor" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7 14H7.3C7.13431 14 7 14.1343 7 14.3C7 14.4657 7.13431 14.6 7.3 14.6H16.7C16.8657 14.6 17 14.4657 17 14.3C17 14.1343 16.8657 14 16.7 14ZM7.3 13C6.58203 13 6 13.582 6 14.3C6 15.018 6.58203 15.6 7.3 15.6H16.7C17.418 15.6 18 15.018 18 14.3C18 13.582 17.418 13 16.7 13H7.3Z" stroke="currentColor" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7 10.0547H7.3C7.13431 10.0547 7 10.189 7 10.3547C7 10.5204 7.13431 10.6547 7.3 10.6547H16.7C16.8657 10.6547 17 10.5204 17 10.3547C17 10.189 16.8657 10.0547 16.7 10.0547ZM7.3 9.05469C6.58203 9.05469 6 9.63672 6 10.3547C6 11.0727 6.58203 11.6547 7.3 11.6547H16.7C17.418 11.6547 18 11.0727 18 10.3547C18 9.63672 17.418 9.05469 16.7 9.05469H7.3Z" stroke="currentColor" stroke-width="0.5"/>
</Icon>
  );

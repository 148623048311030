import { Icon } from '@chakra-ui/react';

export const ObjectiveWithoutPadding = (props) => (
  <Icon viewBox="0 0 16 16" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M16 8C16 8.22309 15.9909 8.44404 15.973 8.66251L14.6511 8.45914C14.6614 8.30743 14.6667 8.15433 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667C8.15433 14.6667 8.30743 14.6614 8.45914 14.6511L8.66251 15.973C8.44404 15.9909 8.22309 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
      fill="currentColor"
    />
    <path
      d="M8 12C8.0171 12 8.03418 11.9999 8.05123 11.9997L7.84548 10.6623C6.44463 10.5822 5.33333 9.42089 5.33333 8C5.33333 6.52724 6.52724 5.33333 8 5.33333C9.42089 5.33333 10.5822 6.44463 10.6623 7.84548L11.9997 8.05123C11.9999 8.03418 12 8.0171 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
      fill="currentColor"
    />
    <path
      d="M8 8L10.7814 16L11.4377 11.9902C11.4846 11.704 11.7078 11.4799 11.9927 11.4333L16 10.7765L8 8Z"
      fill="currentColor"
    />
  </Icon>
);

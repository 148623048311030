import { Icon } from '@chakra-ui/react';

export const ShareIcon = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M20 6.5C20 7.88071 18.8807 9 17.5 9C16.7101 9 16.0057 8.63362 15.5475 8.06153L8.9999 11.4777L9 11.5C9 11.7021 8.97602 11.8986 8.93076 12.0867L15.6109 15.8625C16.0693 15.3341 16.7456 15 17.5 15C18.8807 15 20 16.1193 20 17.5C20 18.8807 18.8807 20 17.5 20C16.1193 20 15 18.8807 15 17.5C15 17.3669 15.0104 17.2362 15.0304 17.1087L8.25585 13.2796C7.80441 13.7251 7.18431 14 6.5 14C5.11929 14 4 12.8807 4 11.5C4 10.1193 5.11929 9 6.5 9C7.37388 9 8.14303 9.44837 8.58996 10.1276L15.015 6.77541C15.0051 6.68497 15 6.59308 15 6.5C15 5.11929 16.1193 4 17.5 4C18.8807 4 20 5.11929 20 6.5Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const HyperLink = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M16.2105 8.16051H13.6842C13.426 8.16051 13.2132 8.37333 13.2132 8.63156C13.2132 8.88979 13.426 9.10262 13.6842 9.10262H16.2105C17.8049 9.10262 19.1079 10.4056 19.1079 12C19.1079 13.5944 17.8049 14.8974 16.2105 14.8974H13.6842C13.426 14.8974 13.2132 15.1102 13.2132 15.3684C13.2132 15.6266 13.426 15.8395 13.6842 15.8395H16.2105C18.3298 15.8395 20.05 14.1193 20.05 12C20.05 9.8807 18.3298 8.16051 16.2105 8.16051ZM9.00263 12C9.00263 12.2582 9.21545 12.471 9.47368 12.471H14.5263C14.7845 12.471 14.9974 12.2582 14.9974 12C14.9974 11.7418 14.7845 11.5289 14.5263 11.5289H9.47368C9.21545 11.5289 9.00263 11.7418 9.00263 12ZM10.3158 14.8974H7.78947C6.19507 14.8974 4.89211 13.5944 4.89211 12C4.89211 10.4056 6.19507 9.10262 7.78947 9.10262H10.3158C10.574 9.10262 10.7868 8.88979 10.7868 8.63156C10.7868 8.37333 10.574 8.16051 10.3158 8.16051H7.78947C5.67019 8.16051 3.95 9.8807 3.95 12C3.95 14.1193 5.67019 15.8395 7.78947 15.8395H10.3158C10.574 15.8395 10.7868 15.6266 10.7868 15.3684C10.7868 15.1102 10.574 14.8974 10.3158 14.8974Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Search = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M19.7833 18.6157L16.7971 15.6606C19.0634 12.8387 18.8501 8.68548 16.264 6.10309C14.9043 4.74539 13.1181 4 11.1984 4C9.27879 4 7.46593 4.74543 6.10612 6.10309C4.74646 7.4608 4 9.24465 4 11.188C4 13.1314 4.74651 14.9151 6.10612 16.2729C7.46579 17.6041 9.25222 18.376 11.1984 18.376C12.8247 18.376 14.4245 17.8168 15.6773 16.8053L18.6367 19.7604C18.7966 19.9201 18.9834 20 19.1967 20C19.41 20 19.6233 19.9201 19.7567 19.7604C20.0765 19.4408 20.0765 18.9352 19.7833 18.6156L19.7833 18.6157ZM16.7971 11.188C16.7971 12.6788 16.2106 14.0898 15.1442 15.1281C14.0779 16.1664 12.6914 16.7787 11.1984 16.7787C9.70546 16.7787 8.29242 16.193 7.25264 15.1281C6.21267 14.0633 5.59952 12.6788 5.59952 11.188C5.59952 9.69719 6.18606 8.28619 7.25245 7.24791C8.31899 6.20944 9.67865 5.59717 11.1984 5.59717C12.6914 5.59717 14.1044 6.18286 15.1442 7.24772C16.184 8.31272 16.7971 9.67042 16.7971 11.188Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Clone = (props) => (
  <Icon viewBox="0 0 20 20" boxSize="16px" color="icon.g1" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.167 5.83398H7.50033C7.04009 5.83398 6.66699 6.20708 6.66699 6.66732V15.0007C6.66699 15.4609 7.04009 15.834 7.50033 15.834H14.167C14.6272 15.834 15.0003 15.4609 15.0003 15.0007V6.66732C15.0003 6.20708 14.6272 5.83398 14.167 5.83398ZM7.50033 5.00065C6.57985 5.00065 5.83366 5.74684 5.83366 6.66732V15.0007C5.83366 15.9211 6.57985 16.6673 7.50033 16.6673H14.167C15.0875 16.6673 15.8337 15.9211 15.8337 15.0007V6.66732C15.8337 5.74684 15.0875 5.00065 14.167 5.00065H7.50033Z"
      fill="currentColor"
    />
    <path
      d="M4.58366 13.334C4.35354 13.334 4.16699 13.1474 4.16699 12.9173V5.83398C4.16699 4.45327 5.28628 3.33398 6.66699 3.33398H11.2503C11.4804 3.33398 11.667 3.52053 11.667 3.75065C11.667 3.98077 11.4804 4.16732 11.2503 4.16732H6.66699C5.74652 4.16732 5.00033 4.91351 5.00033 5.83398V12.9173C5.00033 13.1474 4.81378 13.334 4.58366 13.334Z"
      fill="currentColor"
    />
    <path
      d="M8.33366 7.91732C8.33366 7.6872 8.52021 7.50065 8.75033 7.50065H12.917C13.1471 7.50065 13.3337 7.6872 13.3337 7.91732C13.3337 8.14744 13.1471 8.33398 12.917 8.33398H8.75033C8.52021 8.33398 8.33366 8.14744 8.33366 7.91732Z"
      fill="currentColor"
    />
    <path
      d="M8.33366 10.4173C8.33366 10.1872 8.52021 10.0007 8.75033 10.0007H12.917C13.1471 10.0007 13.3337 10.1872 13.3337 10.4173C13.3337 10.6474 13.1471 10.834 12.917 10.834H8.75033C8.52021 10.834 8.33366 10.6474 8.33366 10.4173Z"
      fill="currentColor"
    />
    <path
      d="M8.33366 12.9173C8.33366 12.6872 8.52021 12.5007 8.75033 12.5007H12.917C13.1471 12.5007 13.3337 12.6872 13.3337 12.9173C13.3337 13.1474 13.1471 13.334 12.917 13.334H8.75033C8.52021 13.334 8.33366 13.1474 8.33366 12.9173Z"
      fill="currentColor"
    />
  </Icon>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntentValuesType } from 'Deer/intent/types/IntentCommonTypes';
import { useSelector } from 'react-redux';

export type SelectedInitiativeType = {
  text: string;
  id: string;
  enabled: () => boolean;
};

export type InitiativeDateRange = { label?: string; startDate: Date; endDate: Date };

export type InitiativeIntentType = {
  label: string;
  onClick: () => void;
  visible: () => boolean;
  value: {
    myData?: IntentValuesType;
  };
  initiativeTypes: SelectedInitiativeType[];
  selectedInitiativeType: SelectedInitiativeType;
  selectedDateRange?: InitiativeDateRange;
  applicableLayoutValues?: string[];
  showLayoutDropdown?: boolean;
  category: 'Manage' | 'Analyze';
  isDefault?: boolean;
};

const initialState: {
  selectedIntent: null | InitiativeIntentType;
} = {
  selectedIntent: null,
};

export const initiativeIntentSlice = createSlice({
  name: 'initiativeIntent',
  initialState,
  reducers: {
    setSelectedInitiativeIntent: (state, action: PayloadAction<InitiativeIntentType>) => {
      const newState = { ...state };
      newState.selectedIntent = action.payload;
      return newState;
    },
    setSelectedInitiativeType: (state, action: PayloadAction<SelectedInitiativeType>) => {
      state.selectedIntent.selectedInitiativeType = action.payload;
    },
    setInitiativeDateRange: (state, action: PayloadAction<InitiativeDateRange>) => {
      state.selectedIntent.selectedDateRange = action.payload;
    },
  },
});

export const { setSelectedInitiativeIntent, setSelectedInitiativeType, setInitiativeDateRange } =
  initiativeIntentSlice.actions;

export default initiativeIntentSlice.reducer;

export const useSelectInitiativeSelectedIntent = () =>
  useSelector((state: { initiativeIntent: typeof initialState }) => state.initiativeIntent.selectedIntent);

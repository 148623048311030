import { Icon } from '@chakra-ui/react';

export const HomeIconSelected = (props) => (
  <Icon viewBox="0 0 24 25" {...props}>
    <path
      d="M18.3659 9.542L18.3667 9.54251C18.7575 9.80757 19.0002 10.2614 19 10.7561V10.7566V18.0499C19 18.4411 18.8476 18.8123 18.5827 19.0828C18.3184 19.3524 17.9645 19.5 17.6 19.5H6.40003C6.03552 19.5 5.68163 19.3524 5.41734 19.0828C5.15242 18.8123 5 18.4411 5 18.0499L5 10.7566L5 10.7562C4.99977 10.2614 5.24258 9.80756 5.63335 9.54249L5.63407 9.542L11.2331 5.73689C11.2333 5.73677 11.2335 5.73665 11.2337 5.73653C11.4627 5.58141 11.7292 5.5 12 5.5C12.271 5.5 12.5376 5.58148 12.7666 5.73672C12.7667 5.73677 12.7668 5.73683 12.7668 5.73688L18.3659 9.542Z"
      stroke="#104F95"
      strokeWidth="2"
    />
    <path
      d="M10 15.5C10 14.3954 10.8954 13.5 12 13.5C13.1046 13.5 14 14.3954 14 15.5V19.5H10V15.5Z"
      stroke="#104F95"
      strokeWidth="2"
    />
  </Icon>
);

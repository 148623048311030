import { Icon } from '@chakra-ui/react';

export const Star = (props) => (
  <Icon viewBox="0 0 38 38" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M19.0001 24.0599L22.8043 26.3608C23.501 26.7824 24.3535 26.1591 24.1701 25.3708L23.1618 21.0441L26.526 18.1291C27.1401 17.5974 26.8101 16.5891 26.0035 16.5249L21.576 16.1491L19.8435 12.0608C19.5318 11.3183 18.4685 11.3183 18.1568 12.0608L16.4243 16.1399L11.9968 16.5158C11.1901 16.5799 10.8601 17.5883 11.4743 18.1199L14.8385 21.0349L13.8301 25.3616C13.6468 26.1499 14.4993 26.7733 15.196 26.3516L19.0001 24.0599Z"
      fill="#666666"
    />
    <rect x="0.5" y="0.5" width="37" height="37" rx="3.5" stroke="#CCCCCC" fill="transparent" />
  </Icon>
);

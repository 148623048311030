import { OPEN_REVIEW_CALIBRATION_MODAL, CLOSE_REVIEW_CALIBRATION_MODAL } from 'actions/actionTypes';

export const initialState = {
  isOpen: false,
  onClose: () => {},
  onSelect: () => {},
  currentState: null,
};

const reviewCalibrationModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REVIEW_CALIBRATION_MODAL:
      return {
        isOpen: true,
        onClose: action.payload.onClose,
        onSelect: action.payload.onSelect,
        currentState: action.payload.currentState,
      };

    case CLOSE_REVIEW_CALIBRATION_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reviewCalibrationModalReducer;

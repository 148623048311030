import { Icon } from '@chakra-ui/react';

export const Alignment = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8.90821 15.2624C8.63207 15.2624 8.40821 15.0385 8.40821 14.7624V11.2624C8.40821 10.9862 8.63207 10.7624 8.90821 10.7624C9.18435 10.7624 9.40821 10.9862 9.40821 11.2624V14.7624C9.40821 15.0385 9.18435 15.2624 8.90821 15.2624Z"
      fill="currentColor"
    />
    <path
      d="M8.73066 15.0893C8.49749 14.8936 8.49535 14.5356 8.72616 14.3372L9.82459 13.3928C10.0079 13.2352 10.2783 13.2336 10.4634 13.389C10.6966 13.5846 10.6987 13.9427 10.4679 14.1411L9.36948 15.0854C9.18622 15.243 8.9158 15.2446 8.73066 15.0893Z"
      fill="currentColor"
    />
    <path
      d="M9.08576 15.066C9.31893 14.8704 9.32107 14.5123 9.09026 14.3139L7.99183 13.3696C7.80856 13.212 7.53815 13.2104 7.35301 13.3657C7.11984 13.5614 7.1177 13.9194 7.3485 14.1178L8.44694 15.0622C8.63021 15.2197 8.90062 15.2214 9.08576 15.066Z"
      fill="currentColor"
    />
    <path
      d="M8.90821 8.6134C8.63207 8.6134 8.40821 8.83726 8.40821 9.1134V11.7624C8.40821 12.0385 8.63207 12.2624 8.90821 12.2624C9.18435 12.2624 9.40821 12.0385 9.40821 11.7624V9.1134C9.40821 8.83726 9.18435 8.6134 8.90821 8.6134Z"
      fill="currentColor"
    />
    <path
      d="M8.73066 8.7865C8.49749 8.98216 8.49535 9.34019 8.72616 9.53862L9.82459 10.483C10.0079 10.6405 10.2783 10.6421 10.4634 10.4868C10.6966 10.2911 10.6987 9.93311 10.4679 9.73468L9.36948 8.79033C9.18622 8.63277 8.9158 8.63115 8.73066 8.7865Z"
      fill="currentColor"
    />
    <path
      d="M9.08576 8.80976C9.31893 9.00541 9.32107 9.36345 9.09026 9.56188L7.99183 10.5062C7.80856 10.6638 7.53815 10.6654 7.35301 10.5101C7.11984 10.3144 7.1177 9.95636 7.3485 9.75793L8.44694 8.81359C8.63021 8.65603 8.90062 8.65441 9.08576 8.80976Z"
      fill="currentColor"
    />
    <path
      d="M10.9082 8.93787C10.9082 8.66172 11.1321 8.43787 11.4082 8.43787H16.4082C16.6843 8.43787 16.9082 8.66172 16.9082 8.93787C16.9082 9.21401 16.6843 9.43787 16.4082 9.43787H11.4082C11.1321 9.43787 10.9082 9.21401 10.9082 8.93787Z"
      fill="currentColor"
    />
    <path
      d="M10.9082 11.9379C10.9082 11.6617 11.1321 11.4379 11.4082 11.4379H16.4082C16.6843 11.4379 16.9082 11.6617 16.9082 11.9379C16.9082 12.214 16.6843 12.4379 16.4082 12.4379H11.4082C11.1321 12.4379 10.9082 12.214 10.9082 11.9379Z"
      fill="currentColor"
    />
    <path
      d="M10.9082 14.9379C10.9082 14.6617 11.1321 14.4379 11.4082 14.4379H16.4082C16.6843 14.4379 16.9082 14.6617 16.9082 14.9379C16.9082 15.214 16.6843 15.4379 16.4082 15.4379H11.4082C11.1321 15.4379 10.9082 15.214 10.9082 14.9379Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0425 4.93787H6.04248C5.4902 4.93787 5.04248 5.38558 5.04248 5.93787V17.9379C5.04248 18.4902 5.4902 18.9379 6.04248 18.9379H18.0425C18.5948 18.9379 19.0425 18.4902 19.0425 17.9379V5.93787C19.0425 5.38558 18.5948 4.93787 18.0425 4.93787ZM6.04248 3.93787C4.93791 3.93787 4.04248 4.8333 4.04248 5.93787V17.9379C4.04248 19.0424 4.93791 19.9379 6.04248 19.9379H18.0425C19.147 19.9379 20.0425 19.0424 20.0425 17.9379V5.93787C20.0425 4.8333 19.147 3.93787 18.0425 3.93787H6.04248Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const ErrorOutline = (props) => (
  <Icon viewBox="0 0 24 25" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12 7.5C12.55 7.5 13 7.95 13 8.5V12.5C13 13.05 12.55 13.5 12 13.5C11.45 13.5 11 13.05 11 12.5V8.5C11 7.95 11.45 7.5 12 7.5ZM11.99 2.5C6.47 2.5 2 6.98 2 12.5C2 18.02 6.47 22.5 11.99 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 11.99 2.5ZM12 20.5C7.58 20.5 4 16.92 4 12.5C4 8.08 7.58 4.5 12 4.5C16.42 4.5 20 8.08 20 12.5C20 16.92 16.42 20.5 12 20.5ZM13 17.5H11V15.5H13V17.5Z"
      fill="currentColor"
    />
  </Icon>
);

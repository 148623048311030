import { Icon } from '@chakra-ui/react';

export const Calculator = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M7.8583 11L9 9.8606L10.1417 11L11 10.1434L9.8583 8.99597L11 7.85657L10.1417 7L9 8.1394L7.8583 7L7 7.85657L8.1417 8.99597L7 10.1434L7.8583 11Z"
      fill="currentColor"
    />
    <path d="M17 7H13V8.5H17V7Z" fill="currentColor" />
    <path d="M17 9.5H13V11H17V9.5Z" fill="currentColor" />
    <path d="M7 15.5V14H11V15.5H7Z" fill="currentColor" />
    <path
      d="M14.4545 17H15.5455V15.5454H17V14.4546H15.5455V13H14.4545V14.4546H13V15.5454H14.4545V17Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6ZM6 7C6 6.44772 6.44772 6 7 6H17C17.5523 6 18 6.44772 18 7V17C18 17.5523 17.5523 18 17 18H7C6.44772 18 6 17.5523 6 17V7Z"
      fill="currentColor"
    />
  </Icon>
);

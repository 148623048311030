import {
  TASK_UPDATE,
  TASK_ADD_TO,
  TASK_REMOVE_TO,
  TASK_ENABLE_EDITING,
  TASK_DISABLE_EDITING,
  TASKS_REDUCER_ADD,
  TASKS_REDUCER_UPDATE,
  TASKS_REDUCER_REMOVE,
  TASKS_REDUCER_REMOVE_NEW_TASK,
  TASK_UPDATE_TEAM,
  TASK_TOGGLE_COMPLETION,
  TASK_SET_EDITING,
} from 'actions/task';

import { getMentionCount } from 'services/helpers/post';
import { toggleCompletion } from 'services/helpers/task';

export function findTaskIndex({ tasks = [], id, key }) {
  const findKey = id ? 'uuid' : 'key';
  const keyy = id || key;
  let index = tasks.findIndex((task) => task[findKey] === keyy);
  // find index using key if unable to find using id
  if (index < 0 && id && key) {
    index = tasks.findIndex((task) => task.key === key);
  }
  return index;
}

function updateToIdsOnEmployeeAdd({ toIds, editorState, toAddEmployee }) {
  let updatedToIds = [...toIds];
  const index = toIds.findIndex((employee) => employee.id === toAddEmployee.id);
  if (index === -1) {
    const mentions = getMentionCount(editorState);
    // this is the only mention that exists, so we need to override toIds and just insert the current one
    if (mentions === 0) updatedToIds = [];
    updatedToIds = [...updatedToIds, { ...toAddEmployee }];
  }
  return updatedToIds;
}

// if the employee doesn't exist then only the employee would be added
function addEmployeeToTask(task, toAddEmployee) {
  const updatedToIds = updateToIdsOnEmployeeAdd({
    toIds: task.toIds,
    editorState: task.editorState,
    toAddEmployee,
  });

  return { ...task, toIds: updatedToIds };
}

export function taskReducer(state, action) {
  switch (action.type) {
    case TASK_UPDATE: {
      return { ...state, ...action.data.task };
    }
    case TASK_ADD_TO: {
      return addEmployeeToTask(state, action.data.to);
    }
    case TASK_REMOVE_TO: {
      const newState = { ...state };
      const toIds = state.toIds.filter((employee) => employee.id !== action.data.toId);
      newState.toIds = toIds;
      return newState;
    }
    case TASK_ENABLE_EDITING: {
      return { ...state, isEditing: true };
    }
    case TASK_DISABLE_EDITING: {
      const task = action.data.task || {};
      return { ...state, ...task, isEditing: false };
    }
    case TASK_TOGGLE_COMPLETION: {
      return toggleCompletion(state);
    }
    case TASK_UPDATE_TEAM: {
      // if this is not a new task, the exisiting team should become the removed team
      // backend requires this on the backend
      const removedTeam = state.id && state.teams[0];
      // add team to the teams object
      const newTeam = action.data?.team;
      return { ...state, removedTeam, teams: [newTeam], teamId: newTeam.id };
    }
    case TASK_SET_EDITING: {
      return { ...state, isEditing: action.data.isEditing };
    }
    default:
      return state;
  }
}

export default function tasksReducer(state = [], action) {
  switch (action.type) {
    case TASKS_REDUCER_ADD: {
      return action.addType === 'append' ? [...state, ...action.tasks] : [...action.tasks, ...state];
    }
    case TASKS_REDUCER_UPDATE: {
      return [...action.tasks];
    }
    case TASKS_REDUCER_REMOVE: {
      const { taskId, key } = action.data;
      // either a taskId or key should be present to find the task in the array
      if (!taskId && !key) return state;
      if (taskId) return state.filter((task) => task.id !== taskId);
      return state.filter((task) => task.key !== key);
    }

    case TASKS_REDUCER_REMOVE_NEW_TASK: {
      return state.filter((task) => !!task.uuid);
    }

    default: {
      const taskIndex = findTaskIndex({
        tasks: state,
        id: action.data?.taskId,
        key: action.data?.key,
      });
      const task = state[taskIndex];
      if (task) {
        const newTask = taskReducer(task, action);
        const newState = [...state];

        newState[taskIndex] = newTask;
        return newState;
      }
      return state;
    }
  }
}

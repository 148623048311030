import { Icon } from '@chakra-ui/react';

export const Description = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <rect x="4.5" y="5" width="15" height="14" rx="1.5" stroke="currentColor" />
    <path
      d="M8 9C8 8.72386 8.22386 8.5 8.5 8.5H15.5C15.7761 8.5 16 8.72386 16 9C16 9.27614 15.7761 9.5 15.5 9.5H8.5C8.22386 9.5 8 9.27614 8 9Z"
      fill="currentColor"
    />
    <path
      d="M8 12C8 11.7239 8.22386 11.5 8.5 11.5H15.5C15.7761 11.5 16 11.7239 16 12C16 12.2761 15.7761 12.5 15.5 12.5H8.5C8.22386 12.5 8 12.2761 8 12Z"
      fill="currentColor"
    />
    <path
      d="M8 15C8 14.7239 8.22386 14.5 8.5 14.5H13.5C13.7761 14.5 14 14.7239 14 15C14 15.2761 13.7761 15.5 13.5 15.5H8.5C8.22386 15.5 8 15.2761 8 15Z"
      fill="currentColor"
    />
  </Icon>
);


import { Icon } from '@chakra-ui/react';
export const CollapseAllList = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
<path d="M9 4.49998L11.8284 7.35901L14.6569 4.49998H9Z" fill="currentColor"/>
<path d="M9 19.5L11.8284 16.641L14.6569 19.5H9Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7 13.7H7.3C7.13431 13.7 7 13.8343 7 14C7 14.1657 7.13431 14.3 7.3 14.3H16.7C16.8657 14.3 17 14.1657 17 14C17 13.8343 16.8657 13.7 16.7 13.7ZM7.3 12.7C6.58203 12.7 6 13.282 6 14C6 14.718 6.58203 15.3 7.3 15.3H16.7C17.418 15.3 18 14.718 18 14C18 13.282 17.418 12.7 16.7 12.7H7.3Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7 9.69998H7.3C7.13431 9.69998 7 9.8343 7 9.99998C7 10.1657 7.13431 10.3 7.3 10.3H16.7C16.8657 10.3 17 10.1657 17 9.99998C17 9.8343 16.8657 9.69998 16.7 9.69998ZM7.3 8.69998C6.58203 8.69998 6 9.28201 6 9.99998C6 10.718 6.58203 11.3 7.3 11.3H16.7C17.418 11.3 18 10.718 18 9.99998C18 9.28201 17.418 8.69998 16.7 8.69998H7.3Z" fill="currentColor"/>
</Icon>
  );

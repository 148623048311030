import { meshModulesPathValues, type MeshModulesPathValue } from './modules';
import { ALL_REPORTS_PATHS, COMPANY_PATHS, DIRECT_REPORTS_PATHS, MY_DATA_PATHS, VIEW_PATHS } from './types/routes';

export const DEER_PREFIX = '/app';

export const staticRoutes = {
  home: '/',
  profile: `${DEER_PREFIX}/employee/:employeeId`,
  login: '/login',
  visierLogin: '/connect-visier',
  company: {
    settings: {
      index: `${DEER_PREFIX}/company/settings`,
      competencies: `${DEER_PREFIX}/company/settings/competencies`,
      plugins: {
        index: `${DEER_PREFIX}/company/settings/plugins`,
        hris: `${DEER_PREFIX}/company/settings/plugins/hris`,
        slack: `${DEER_PREFIX}/company/settings/plugins/slack`,
        msteams: `${DEER_PREFIX}/company/settings/plugins/msteams`,
        salesforce: `${DEER_PREFIX}/company/settings/plugins/salesforce`,
        jira: `${DEER_PREFIX}/company/settings/plugins/jira`,
      },
      platform: {
        index: `${DEER_PREFIX}/company/settings/platform`,
        organization: `${DEER_PREFIX}/company/settings/platform/organization`,
        employees: `${DEER_PREFIX}/company/settings/platform/employees`,
        'function-grade': `${DEER_PREFIX}/company/settings/platform/function-grade`,
        access: `${DEER_PREFIX}/company/settings/platform/access`,
        modules: `${DEER_PREFIX}/company/settings/platform/modules`,
        notifications: `${DEER_PREFIX}/company/settings/platform/notifications`,
      },
    },
    analytics: {
      index: `${DEER_PREFIX}/company/analytics`,
      reports: `${DEER_PREFIX}/company/analytics/reports`,
      outcomesDashboard: `${DEER_PREFIX}/company/analytics/outcomes-dashboard`,
    },
  },
  'my-data': {
    'my-settings': {
      index: `${DEER_PREFIX}/my-data/my-settings`,
      calendar: `${DEER_PREFIX}/my-data/my-settings/calendar`,
      notifications: `${DEER_PREFIX}/my-data/my-settings/notifications`,
      knowledgeHub: `${DEER_PREFIX}/my-data/my-settings/knowledge-hub`,
    },
  },
  team: {
    allReports: {
      profile: `${DEER_PREFIX}/team/all-reports/employee/:employeeId`,
    },
    directReports: {
      profile: `${DEER_PREFIX}/team/direct-reports/employee/:employeeId`,
    },
  },
};

export const staticRoutesWithSidebar = [
  staticRoutes.company.settings.plugins.index,
  staticRoutes.company.settings.plugins.hris,
  staticRoutes.company.settings.plugins.slack,
  staticRoutes.company.settings.plugins.msteams,
  staticRoutes.company.settings.plugins.salesforce,
  staticRoutes.company.settings.plugins.jira,
  staticRoutes.company.settings.platform.modules,
  staticRoutes.company.settings.platform.index,
  staticRoutes.company.settings.platform.organization,
  staticRoutes.company.settings.platform.employees,
  staticRoutes.company.settings.platform['function-grade'],
  staticRoutes.company.settings.platform.access,
  staticRoutes['my-data']['my-settings'].calendar,
  staticRoutes['my-data']['my-settings'].notifications,
  staticRoutes.company.settings.platform.notifications,
  staticRoutes.company.settings.competencies,
  staticRoutes.team.allReports.profile,
  staticRoutes.team.directReports.profile,
  staticRoutes.company.analytics.lifecycleInsights,
  staticRoutes.company.analytics.index,
  staticRoutes.company.analytics.reports,
  staticRoutes.company.analytics.outcomesDashboard,
];

export type L1_VALUES = (typeof L1_VALUES)[keyof typeof L1_VALUES];

export const L1_VALUES = {
  myData: 'my-data',
  team: 'team',
  company: 'company',
} as const;

type L1_PATHS = Record<keyof typeof L1_VALUES, string>;

export const L1_PATHS: L1_PATHS = {
  myData: `${DEER_PREFIX}/${L1_VALUES.myData}/`,
  team: `${DEER_PREFIX}/${L1_VALUES.team}/`,
  company: `${DEER_PREFIX}/${L1_VALUES.company}/`,
} as const;

export const L1_TEAM_PATHS = {
  directReports: `${DEER_PREFIX}/${L1_VALUES.team}/direct-reports/`,
  allReports: `${DEER_PREFIX}/${L1_VALUES.team}/all-reports/`,
  view: `${DEER_PREFIX}/${L1_VALUES.team}/view/`,
} as const;

export const L1_TEAM_VALUES = {
  directReports: 'direct-reports',
  allReports: 'all-reports',
  view: 'view',
} as const;

export const L2_VALUES = meshModulesPathValues.reduce(
  (acc, module) => {
    // eslint-disable-next-line no-param-reassign
    acc[module] = module;
    return acc;
  },
  {} as Record<MeshModulesPathValue, MeshModulesPathValue>
);

type L2_PATHS = {
  [L in L1_VALUES]: L extends 'team'
    ? Record<keyof typeof L1_TEAM_PATHS, Omit<Record<MeshModulesPathValue, string>, 'feedback'>>
    : Omit<Record<MeshModulesPathValue, string>, 'feedback'>;
};

export const L2_PATHS: L2_PATHS = {
  'my-data': {
    develop: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.develop}/`,
    goal: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.goal}/`,
    oneOnOne: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.oneOnOne}/`,
    review: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.review}/`,
    engagement: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.engagement}/`,
    initiative: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.initiative}/`,
    overview: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.overview}/`,
  },
  team: {
    allReports: {
      develop: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.develop}/`,
      goal: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.goal}/`,
      oneOnOne: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.oneOnOne}/`,
      review: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.review}/`,
      engagement: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.engagement}/`,
      initiative: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.initiative}/`,
      employee: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.employee}/`,
      overview: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.overview}/`,
    },
    directReports: {
      develop: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.develop}/`,
      goal: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.goal}/`,
      oneOnOne: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.oneOnOne}/`,
      review: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.review}/`,
      engagement: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.engagement}/`,
      initiative: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.initiative}/`,
      employee: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.employee}/`,
      overview: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.overview}/`,
    },
    view: {
      goal: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.goal}/`,
      review: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.review}/`,
    },
  },
  company: {
    overview: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.overview}/`,
    develop: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.develop}/`,
    goal: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/`,
    oneOnOne: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.oneOnOne}/`,
    review: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.review}/`,
    engagement: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.engagement}/`,
    initiative: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.initiative}/`,
    lifecycle: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.lifecycle}/`,
  },
};

// Record<L1_VALUES, Omit<Record<MeshModulesPathValue, Record<string, string>>
type L3_PATHS = {
  [L in L1_VALUES]: L extends 'my-data'
    ? MY_DATA_PATHS
    : L extends 'team'
      ? {
          allReports: ALL_REPORTS_PATHS;
          directReports: DIRECT_REPORTS_PATHS;
          view: VIEW_PATHS;
        }
      : L extends 'company'
        ? COMPANY_PATHS
        : Record<string, string>;
};

// feedback module is now merged into develop it seems so omit it from L3_PATHS
export const L3_PATHS: L3_PATHS = {
  'my-data': {
    goal: {
      myGoals: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.goal}/my-goals/`,
      // myTeam: `/${L1_VALUES.myData}/${L2_VALUES.goal}/my-team/`,
      // myDepartment: `/${L1_VALUES.myData}/${L2_VALUES.goal}/my-department/`,
      myCompany: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.goal}/my-company/`,
      individualsGoals: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.goal}/individuals-goals/`,
      checkIn: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.goal}/check-in/`,
      upload: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.goal}/upload/`,
    },
    develop: {
      competencies: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.develop}/competencies/`,
      feedback: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.develop}/feedback/`,
      organisationPraise: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.develop}/organisation-praise/`,
    },
    oneOnOne: {
      myOneOnOne: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.oneOnOne}/my-one-on-one/`,
      instancePage: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.oneOnOne}/:masterId/:instanceId/`,
      legacyPage: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.oneOnOne}/legacy/id/:instanceId/`,
      masterPage: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.oneOnOne}/:masterId/`,
    },
    review: {
      myReviews: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.review}/my-reviews/`,
    },
    engagement: {
      mySurveys: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.engagement}/my-surveys/`,
      submittedSurvey: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.engagement}/submittedSurvey/:surveyId`,
    },
    initiative: {
      myInitiatives: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.initiative}/my-initiatives/`,
    },
    overview: {
      myDataOverview: `${DEER_PREFIX}/${L1_VALUES.myData}/${L2_VALUES.overview}`,
    },
  },
  team: {
    allReports: {
      goal: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.goal}/manage/`,
        // insights: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.goal}/insights/`,
        embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.goal}/insights/`,
        individualsGoals: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.goal}/individuals-goals/`,
        checkIn: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.goal}/check-in/`,
        upload: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.goal}/upload/`,
      },
      develop: {
        // manage: `/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.develop}/manage/`,
        insights: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.develop}/insights/`,
        embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.develop}/embedded-analytics/`,
      },
      oneOnOne: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.oneOnOne}/manage/`,
        insights: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.oneOnOne}/team-1-1/`,
        instancePage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.oneOnOne}/:masterId/:instanceId/`,
        embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.oneOnOne}/insights/`,
        legacyPage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.oneOnOne}/legacy/id/:instanceId/`,
        masterPage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.oneOnOne}/:masterId/`,
      },
      review: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.review}/manage/`,
        embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.review}/insights/`,
        completionStatus: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.review}/completion-status/`,
        calibratedResults: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.review}/calibrated-results/`,
      },
      engagement: {
        insights: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.engagement}/insights/`,
      },
      employee: {
        profilePage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.employee}/:employeeId`,
      },
      overview: {
        mySkipTeamOverview: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.allReports}/${L2_VALUES.overview}`,
      },
    },
    directReports: {
      goal: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.goal}/manage/`,
        // insights: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.goal}/insights/`,
        embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.goal}/insights/`,
        individualsGoals: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.goal}/individuals-goals/`,
        checkIn: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.goal}/check-in/`,
        upload: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.goal}/upload/`,
      },
      develop: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.develop}/manage/`,
        feedbackForMyTeam: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.develop}/feedback-for-my-team/`,
        insights: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.develop}/insights/`,
        embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.develop}/embedded-analytics/`,
      },
      oneOnOne: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.oneOnOne}/manage/`,
        instancePage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.oneOnOne}/:masterId/:instanceId/`,
        embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.oneOnOne}/insights/`,
        legacyPage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.oneOnOne}/legacy/id/:instanceId/`,
        masterPage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.oneOnOne}/:masterId/`,
      },
      review: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.review}/manage/`,
        embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.review}/insights/`,
        completionStatus: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.review}/completion-status/`,
        calibratedResults: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.review}/calibrated-results/`,
        actionPlan: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.review}/action-plan/`,
        actionPlanEdit: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.review}/action-plan/edit`,
      },
      engagement: {
        insights: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.engagement}/insights/`,
      },
      employee: {
        profilePage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.employee}/:employeeId`,
      },
      overview: {
        myTeamOverview: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.directReports}/${L2_VALUES.overview}`,
      },
    },
    view: {
      goal: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.goal}/manage/`,
        checkIn: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.goal}/checkIn/`,
        upload: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.goal}/upload/`,
        individualsGoals: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.goal}/individuals-goals/`,
      },
      review: {
        manage: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.review}/manage/`,
        calibrationView: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.review}/calibrationView/`,
        completionStatus: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.review}/completion-status/`,
        calibratedResults: `${DEER_PREFIX}/${L1_VALUES.team}/${L1_TEAM_VALUES.view}/${L2_VALUES.review}/calibrated-results/`,
      },
    },
  },
  company: {
    overview: {
      myCompanyOverview: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.overview}`,
    },
    goal: {
      manage: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/manage/`,
      // insights: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/insights/`,
      // reports: `/${L1_VALUES.company}/${L2_VALUES.goal}/reports/`,
      archived: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/archived/`,
      embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/embedded-analytics/`,
      settings: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/settings/`,
      individualsGoals: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/individuals-goals/`,
      checkIn: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/check-in/`,
      upload: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.goal}/upload/`,
    },
    develop: {
      insights: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.develop}/insights/`,
      // reports: `/${L1_VALUES.company}/${L2_VALUES.develop}/reports/`,
      embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.develop}/embedded-analytics/`,
      settings: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.develop}/settings/`,
      competencyLibrary: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.develop}/competency-library/`,
      competencySettings: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.develop}/competency-settings/`,
    },
    oneOnOne: {
      insights: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.oneOnOne}/insights/`,
      // reports: `/${L1_VALUES.company}/${L2_VALUES.oneOnOne}/reports/`,
      // calender: `/${L1_VALUES.company}/${L2_VALUES.oneOnOne}/calender/`,
      embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.oneOnOne}/embedded-analytics/`,
      settings: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.oneOnOne}/settings/`,
    },
    review: {
      manage: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.review}/manage/`,
      calibrationView: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.review}/calibrationView/`,
      // insights: `/${L1_VALUES.company}/${L2_VALUES.review}/insights/`,
      // reports: `/${L1_VALUES.company}/${L2_VALUES.review}/reports/`,
      embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.review}/embedded-analytics/`,
      settings: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.review}/settings/`,
      completionStatus: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.review}/completion-status/`,
      calibratedResults: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.review}/calibrated-results/`,
    },
    engagement: {
      // insights: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.engagement}/insights/`,
      // reports: `/${L1_VALUES.company}/${L2_VALUES.engagement}/reports/`,
      embeddedAnalytics: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.engagement}/embedded-analytics/`,
      settings: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.engagement}/settings/`,
    },
    lifecycle: {
      hire: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.lifecycle}/hire/`,
      retire: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.lifecycle}/retire/`,
      onboard: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.lifecycle}/onboard/`,
      progress: `${DEER_PREFIX}/${L1_VALUES.company}/${L2_VALUES.lifecycle}/progress/`,
    },
  },
};

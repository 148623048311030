import { GLOBAL_GOALS_MAP_ADD_GOALS, GLOBAL_GOALS_MAP_DELETE_GOAL, GLOBAL_GOALS_MAP_UPDATE_GOAL } from './actionTypes';

export const addGoalToGlobalGoalsMap = ({ goal }) => ({
  type: GLOBAL_GOALS_MAP_ADD_GOALS,
  payload: { goals: [goal] },
});

export const addGoalsToGlobalGoalsMap = ({ goals = [] }) => ({
  type: GLOBAL_GOALS_MAP_ADD_GOALS,
  payload: { goals },
});

export const deleteGoalFromGlobalGoalsMap = ({ goalId }) => ({
  type: GLOBAL_GOALS_MAP_DELETE_GOAL,
  payload: { goalId },
});

export const updateGoalInGlobalGoalsMap = ({ goal }) => ({
  type: GLOBAL_GOALS_MAP_UPDATE_GOAL,
  payload: { goal },
});

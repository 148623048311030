import { Icon } from '@chakra-ui/react';

export const OrderedList = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M9.05263 7.7895H19.1579C19.6211 7.7895 20 7.41055 20 6.94739C20 6.48424 19.6211 6.10529 19.1579 6.10529H9.05263C8.58947 6.10529 8.21053 6.48424 8.21053 6.94739C8.21053 7.41055 8.58947 7.7895 9.05263 7.7895ZM19.1579 16.2106H9.05263C8.58947 16.2106 8.21053 16.5895 8.21053 17.0527C8.21053 17.5158 8.58947 17.8948 9.05263 17.8948H19.1579C19.6211 17.8948 20 17.5158 20 17.0527C20 16.5895 19.6211 16.2106 19.1579 16.2106ZM19.1579 11.1579H9.05263C8.58947 11.1579 8.21053 11.5369 8.21053 12C8.21053 12.4632 8.58947 12.8421 9.05263 12.8421H19.1579C19.6211 12.8421 20 12.4632 20 12C20 11.5369 19.6211 11.1579 19.1579 11.1579ZM6.10526 15.3684H4.42105C4.18526 15.3684 4 15.5537 4 15.7895C4 16.0253 4.18526 16.2106 4.42105 16.2106H5.68421V16.6316H5.26316C5.02737 16.6316 4.84211 16.8169 4.84211 17.0527C4.84211 17.2884 5.02737 17.4737 5.26316 17.4737H5.68421V17.8948H4.42105C4.18526 17.8948 4 18.08 4 18.3158C4 18.5516 4.18526 18.7369 4.42105 18.7369H6.10526C6.34105 18.7369 6.52632 18.5516 6.52632 18.3158V15.7895C6.52632 15.5537 6.34105 15.3684 6.10526 15.3684ZM4.42105 6.10529H4.84211V8.21055C4.84211 8.44634 5.02737 8.6316 5.26316 8.6316C5.49895 8.6316 5.68421 8.44634 5.68421 8.21055V5.68424C5.68421 5.44845 5.49895 5.26318 5.26316 5.26318H4.42105C4.18526 5.26318 4 5.44845 4 5.68424C4 5.92003 4.18526 6.10529 4.42105 6.10529ZM6.10526 10.3158H4.42105C4.18526 10.3158 4 10.5011 4 10.7369C4 10.9727 4.18526 11.1579 4.42105 11.1579H5.51579L4.10105 12.8084C4.03368 12.8842 4 12.9853 4 13.0779V13.2632C4 13.499 4.18526 13.6842 4.42105 13.6842H6.10526C6.34105 13.6842 6.52632 13.499 6.52632 13.2632C6.52632 13.0274 6.34105 12.8421 6.10526 12.8421H5.01053L6.42526 11.1916C6.49263 11.1158 6.52632 11.0148 6.52632 10.9221V10.7369C6.52632 10.5011 6.34105 10.3158 6.10526 10.3158Z"
      fill="currentColor"
    />
  </Icon>
);

import { getDateFromEpoch } from 'constants/helper';

export default class Media {
  static TYPES = {
    image: 'IMAGE',
    gif: 'GIF',
  };

  constructor({ id, createdDate, updatedDate, url, type } = {}) {
    this.id = id;
    this.createdDate = createdDate;
    this.updatedDate = updatedDate;
    this.url = url;
    this.type = type;
  }

  static fromJSON(json) {
    const media = new Media({
      id: json.uuid,
      type: json.type,
      url: json.url,
      createdDate: getDateFromEpoch(json.createdTS),
      updatedDate: getDateFromEpoch(json.updatedTS),
    });
    return media;
  }
}

import { convertSearchUiDataToApiBody, makeSearchAutoCompleteBody } from 'services/helpers/search';

import { endpoints } from '../constants/endpoints';
import { sendRequest } from './sendRequest';

export const recentListApi = () => {
  const url = endpoints.search.recentList;
  return sendRequest({ url, method: 'GET' });
};

export const frequentListApi = () => sendRequest({ url: endpoints.search.frequentList, method: 'GET' });

export const tagsListApi = () => sendRequest({ url: endpoints.search.tagsList, method: 'GET' });

// submit search or getSearchResults Api can be called anything
export const submitSearchApi = (data = []) => {
  const body = convertSearchUiDataToApiBody(data);
  return sendRequest({ url: endpoints.search.submit, method: 'POST', body });
};

// backend requires widgetType which tells where you are calling this autocomplete api call from
export const searchAutoCompleteApi = ({ tagType, query, widgetType, teamId, employeeIds, empId, ...otherProps }) => {
  const body = makeSearchAutoCompleteBody({ tagType, query, teamId, employeeIds, ...otherProps });
  return sendRequest({
    url: endpoints.search.autoComplete,
    method: 'POST',
    body,
    queryParams: { widgetType, emp_id: empId },
    credentials: 'include',
  });
};

// backend requires widgetType which tells where you are calling this autocomplete api call from
export const searchAutoCompleteV1Api = ({
  text,
  tagTypeSet,
  widgetType,
  employeeIds,
  cohort,
  empId,
  timePeriod: timeBoundDetails,
}) => {
  const getBody = () => {
    if (cohort) {
      return {
        text,
        tagTypeSet,
        employeeIds,
        cohort,
      };
    }
    return {
      text,
      tagTypeSet,
      employeeIds,
      ...(timeBoundDetails ? { timeBoundDetails } : {}),
    };
  };
  return sendRequest({
    url: endpoints.search.autoCompleteV1,
    method: 'POST',
    body: getBody(),
    queryParams: { widgetType, emp_id: empId },
    credentials: 'include',
  });
};

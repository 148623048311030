import { Icon } from '@chakra-ui/react';

export const MyTeamFeedback = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_413_362)">
      <path
        d="M13 10.5V10H11.5C11.22 10 11 9.78 11 9.5V5.5C11 5.22 11.22 5 11.5 5H18.5C18.78 5 19 5.22 19 5.5V9.5C19 9.78 18.78 10 18.5 10H15.91C15.51 10 15.13 10.16 14.85 10.44L13.85 11.44C13.54 11.75 13 11.53 13 11.09V10.5V10.5Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M15 8.79001L15.31 8.10001L16 7.79001L15.31 7.48001L15 6.79001L14.69 7.48001L14 7.79001L14.69 8.10001L15 8.79001Z"
        fill="currentColor"
      />
      <path
        d="M6.7 17.1C7.2 17.1 7.7 16.8 7.9 16.3C7.7 15.9 7.6 15.4 7.6 15V14.6C7.4 14.4 7.1 14.2 6.7 14.2C6 14.2 5.4 14.8 5.4 15.6C5.3 16.5 5.9 17.1 6.7 17.1ZM6 19.2C6 18.7 6.2 18.3 6.5 17.9C5.5 17.9 4 18.4 4 19.3V20H6V19.2ZM10.1 16.7C11 16.7 11.7 16 11.7 15C11.7 14.1 11 13.3 10.1 13.3C9.2 13.3 8.5 14 8.5 15C8.5 15.9 9.2 16.7 10.1 16.7ZM10.1 17.5C9.1 17.5 7 18.1 7 19.2V20H13.2V19.2C13.2 18.1 11.1 17.5 10.1 17.5ZM13.7 17.9C14 18.3 14.2 18.7 14.2 19.2V20H16.2V19.3C16.1 18.4 14.6 17.9 13.7 17.9ZM12.6 15C12.6 15.5 12.5 15.9 12.3 16.3C12.5 16.8 13 17.1 13.5 17.1C14.2 17.1 14.8 16.5 14.8 15.7C14.8 14.9 14.2 14.3 13.5 14.3C13.2 14.3 12.9 14.4 12.6 14.7V15Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_413_362">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </Icon>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { roundToNearestMinutes } from 'date-fns';
import { employeeMiniProfile } from 'interfaces/EmployeeMiniProfile';
import { useSelector } from 'react-redux';
import { z } from 'zod';

const DURATION_OPTIONS = [
  {
    label: '30mins',
    value: 30,
  },
  {
    label: '45mins',
    value: 45,
  },
  {
    label: '60mins',
    value: 60,
  },
];

const initialState = {
  isOpen: false,
  excludedParticipantIds: [],
  employeeId: null,
  employeeMiniProfile: {},
  startDateTime: roundToNearestMinutes(new Date(), { nearestTo: 30, roundingMethod: 'ceil' }),
  duration: DURATION_OPTIONS[0].value,
  frequency: null,
  participantId: null,
  isCalendarSynced: false,
  relation: null,
};

type employeeMiniProfileType = z.infer<typeof employeeMiniProfile>;

export type CreateOneOnOneDrawerPayloadType = {
  excludedParticipantIds: string[];
  employeeId: string | null;
  employeeMiniProfile: employeeMiniProfileType;
  startDateTime: Date;
  duration: number;
  frequency: string | null;
  participantId: number | null;
  isCalendarSynced: boolean;
  relation: string | null;
};

export const CreateOneOnOneDrawerSlice = createSlice({
  name: 'CreateOneOnOneDrawerSlice',
  initialState,
  reducers: {
    openCreateOneOnOneDrawer: (state, action: PayloadAction<CreateOneOnOneDrawerPayloadType>) => {
      state.isOpen = true;
      state.excludedParticipantIds = action.payload.excludedParticipantIds;
      state.employeeId = action.payload.employeeId;
      state.employeeMiniProfile = action.payload.employeeMiniProfile;
    },
    closeCreateOneOnOneDrawer: (state) => {
      state.isOpen = false;
      state.excludedParticipantIds = [];
      state.employeeId = null;
      state.employeeMiniProfile = {};
      state.startDateTime = roundToNearestMinutes(new Date(), {
        nearestTo: 30,
        roundingMethod: 'ceil',
      });
      state.duration = DURATION_OPTIONS[0].value;
      state.frequency = null;
      state.isCalendarSynced = false;
      state.participantId = null;
      state.relation = null;
    },
    updateDateTime: (state, action: PayloadAction<Date>) => {
      state.startDateTime = action.payload;
    },
    updateDuration: (state, action: PayloadAction<number>) => {
      state.duration = action.payload;
    },
    updateFrequency: (state, action: PayloadAction<string | null>) => {
      state.frequency = action.payload;
    },
    updateParticipantId: (state, action: PayloadAction<number | null>) => {
      state.participantId = action.payload;
    },
    updateCalendarSync: (state, action: PayloadAction<boolean>) => {
      state.isCalendarSynced = action.payload;
    },
    updateRelation: (state, action: PayloadAction<string | null>) => {
      state.relation = action.payload;
    },
  },
});

export const {
  openCreateOneOnOneDrawer,
  closeCreateOneOnOneDrawer,
  updateDateTime,
  updateDuration,
  updateFrequency,
  updateParticipantId,
  updateCalendarSync,
  updateRelation,
} = CreateOneOnOneDrawerSlice.actions;

export default CreateOneOnOneDrawerSlice.reducer;

export const useCreateOneOnOneDrawerData = () => {
  const isOpen: boolean = useSelector((state: any) => state.CreateOneOnOneDrawerSlice.isOpen);
  const excludedParticipantIds: string[] = useSelector(
    (state: any) => state.CreateOneOnOneDrawerSlice.excludedParticipantIds
  );
  const employeeId: string | null = useSelector((state: any) => state.CreateOneOnOneDrawerSlice.employeeId);
  const employeeMiniProfile: employeeMiniProfileType = useSelector(
    (state: any) => state.CreateOneOnOneDrawerSlice.employeeMiniProfile
  );

  const startDateTime: Date = useSelector((state: any) => state.CreateOneOnOneDrawerSlice.startDateTime);

  const duration: string | null = useSelector((state: any) => state.CreateOneOnOneDrawerSlice.duration);

  const frequency: string | null = useSelector((state: any) => state.CreateOneOnOneDrawerSlice.frequency);

  const isCalendarSynced: boolean = useSelector((state: any) => state.CreateOneOnOneDrawerSlice.isCalendarSynced);

  const participantId: number | null = useSelector((state: any) => state.CreateOneOnOneDrawerSlice.participantId);

  const relation: string | null = useSelector((state: any) => state.CreateOneOnOneDrawerSlice.relation);
  return {
    isOpen,
    excludedParticipantIds,
    employeeId,
    employeeMiniProfile,
    startDateTime,
    duration,
    frequency,
    isCalendarSynced,
    participantId,
    relation,
  };
};

import { Icon } from '@chakra-ui/react';

export const CollapseAll = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.995 6.1914L9.53502 3.7314C9.14502 3.3414 8.51502 3.3414 8.12502 3.7314C7.73502 4.1214 7.73502 4.7514 8.12502 5.1414L11.295 8.3214C11.685 8.7114 12.315 8.7114 12.705 8.3214L15.875 5.1514C16.265 4.7614 16.265 4.1314 15.875 3.7414C15.485 3.3514 14.855 3.3514 14.465 3.7414L11.995 6.1914Z"
      fill="currentColor"
    />
    <path
      d="M12.005 17.8086L14.465 20.2686C14.855 20.6586 15.485 20.6586 15.875 20.2686C16.265 19.8786 16.265 19.2486 15.875 18.8586L12.705 15.6786C12.315 15.2886 11.685 15.2886 11.295 15.6786L8.12502 18.8586C7.73502 19.2486 7.73502 19.8786 8.12502 20.2686C8.51502 20.6586 9.14502 20.6586 9.53502 20.2686L12.005 17.8086Z"
      fill="currentColor"
    />
  </Icon>
);

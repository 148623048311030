import { Icon } from '@chakra-ui/react';

export const MyCompanyReviews = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M19.9 8.91519C20.1 8.71456 20 8.41362 19.7 8.41362L16.2 7.91205C16.1 7.91205 16 7.81174 16 7.71143L14.3 4.20046C14.2 3.99983 13.9 3.99983 13.8 4.20046L12.1 7.5108C12.1 7.61111 12 7.71143 11.9 7.71143L8.20005 8.31331C7.90005 8.31331 7.80005 8.61425 8.00005 8.81487L9.20005 10.0186H12L11.8 9.81801L12.2 9.7177C12.6 9.7177 13 9.51707 13.3 9.21613C13.5 9.0155 13.7 8.81487 13.9 8.51393L14 8.31331L14.2 8.61425C14.3 8.91519 14.5 9.11582 14.7 9.31644C15 9.61738 15.4 9.7177 15.8 9.81801L16.2 9.91832L15.8 10.3196C15.6 10.5202 15.4 10.8211 15.3 11.1221C15.3 11.2224 15.2 11.3227 15.2 11.423C15.7 11.724 16 12.3258 16 13.028V15.2349L17.3 15.9371C17.5 16.0374 17.8 15.8368 17.7 15.6362L17.1 11.8243C17.1 11.724 17.1 11.6237 17.2 11.5233L19.9 8.91519Z"
      fill="currentColor"
    />
    <path
      d="M13.7 12.0249H12C11.9 11.5234 11.4 11.0218 10.7 11.0218H8.3C7.6 11.0218 7.2 11.5234 7 12.0249H5.3C4.5 12.0249 4 12.5265 4 13.329V20.05H15V13.329C15 12.5265 14.5 12.0249 13.7 12.0249ZM7 18.0437H6V17.0406H7V18.0437ZM7 15.0343H6V14.0312H7V15.0343ZM10 18.0437H9V17.0406H10V18.0437ZM10 15.0343H9V14.0312H10V15.0343ZM13 18.0437H12V17.0406H13V18.0437ZM13 15.0343H12V14.0312H13V15.0343Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const OutlineEyeSmall = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12 8.45455C14.7564 8.45455 17.2145 10.0036 18.4145 12.4545C17.2145 14.9055 14.7564 16.4545 12 16.4545C9.24364 16.4545 6.78545 14.9055 5.58545 12.4545C6.78545 10.0036 9.24364 8.45455 12 8.45455ZM12 7C8.36364 7 5.25818 9.26182 4 12.4545C5.25818 15.6473 8.36364 17.9091 12 17.9091C15.6364 17.9091 18.7418 15.6473 20 12.4545C18.7418 9.26182 15.6364 7 12 7ZM12 10.6364C13.0036 10.6364 13.8182 11.4509 13.8182 12.4545C13.8182 13.4582 13.0036 14.2727 12 14.2727C10.9964 14.2727 10.1818 13.4582 10.1818 12.4545C10.1818 11.4509 10.9964 10.6364 12 10.6364ZM12 9.18182C10.1964 9.18182 8.72727 10.6509 8.72727 12.4545C8.72727 14.2582 10.1964 15.7273 12 15.7273C13.8036 15.7273 15.2727 14.2582 15.2727 12.4545C15.2727 10.6509 13.8036 9.18182 12 9.18182Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Bold = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M14.7642 11.82C15.5956 11.2457 16.1785 10.3029 16.1785 9.42857C16.1785 7.49143 14.6785 6 12.7499 6H8.2499C7.77848 6 7.39276 6.38571 7.39276 6.85714V17.1429C7.39276 17.6143 7.77848 18 8.2499 18H13.2042C14.9785 18 16.5985 16.5514 16.607 14.7686C16.6156 13.4571 15.8785 12.3343 14.7642 11.82ZM9.96419 8.14286H12.5356C13.247 8.14286 13.8213 8.71714 13.8213 9.42857C13.8213 10.14 13.247 10.7143 12.5356 10.7143H9.96419V8.14286ZM12.9642 15.8571H9.96419V13.2857H12.9642C13.6756 13.2857 14.2499 13.86 14.2499 14.5714C14.2499 15.2829 13.6756 15.8571 12.9642 15.8571Z"
      fill="currentColor"
    />
  </Icon>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntentValuesType } from 'Deer/intent/types/IntentCommonTypes';
import { useSelector } from 'react-redux';

export type LifecycleInsightsIntentType = {
  label: string;
  onClick: () => void;
  visible: () => boolean;
  value: {
    company?: IntentValuesType;
  };
  category: 'HIRE' | 'ONBOARD' | 'DEVELOP' | 'PROGRESS' | 'RETIRE';
};

export const lifecycleInsightsIntentSlice = createSlice({
  name: 'lifecycleInsightsIntent',
  initialState: {
    selectedIntent: null as null | LifecycleInsightsIntentType,
  },
  reducers: {
    setSelectedLifecycleInsightsIntent: (state, action: PayloadAction<LifecycleInsightsIntentType>) => {
      const newState = { ...state };
      newState.selectedIntent = action.payload;
      return newState;
    },
  },
});

export const { setSelectedLifecycleInsightsIntent } = lifecycleInsightsIntentSlice.actions;

export default lifecycleInsightsIntentSlice.reducer;

export const useSelectLifecycleInsightsIntent = () =>
  useSelector(
    (state: { lifecycleInsightsIntent: { selectedIntent: LifecycleInsightsIntentType } }) =>
      state.lifecycleInsightsIntent.selectedIntent
  );

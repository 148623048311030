import { Icon } from '@chakra-ui/react';

export const Activities = (props) => (
  <Icon viewBox="0 0 22 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M7 7.49988C7 7.22374 7.22386 6.99988 7.5 6.99988H13.5C13.7761 6.99988 14 7.22374 14 7.49988C14 7.77602 13.7761 7.99988 13.5 7.99988H7.5C7.22386 7.99988 7 7.77602 7 7.49988Z"
      fill="currentColor"
    />
    <path
      d="M7 10.4999C7 10.2237 7.22386 9.99988 7.5 9.99988H13.5C13.7761 9.99988 14 10.2237 14 10.4999C14 10.776 13.7761 10.9999 13.5 10.9999H7.5C7.22386 10.9999 7 10.776 7 10.4999Z"
      fill="currentColor"
    />
    <path
      d="M7 13.4999C7 13.2237 7.22386 12.9999 7.5 12.9999H10.5C10.7761 12.9999 11 13.2237 11 13.4999C11 13.776 10.7761 13.9999 10.5 13.9999H7.5C7.22386 13.9999 7 13.776 7 13.4999Z"
      fill="currentColor"
    />
    <path
      d="M7 16.4999C7 16.2237 7.22386 15.9999 7.5 15.9999H9.5C9.77614 15.9999 10 16.2237 10 16.4999C10 16.776 9.77614 16.9999 9.5 16.9999H7.5C7.22386 16.9999 7 16.776 7 16.4999Z"
      fill="currentColor"
    />
    <path
      d="M4 5.99988C4 4.89531 4.89543 3.99988 6 3.99988H15C16.1046 3.99988 17 4.89531 17 5.99988V12.332C16.6575 12.2002 16.2945 12.1047 15.9167 12.0508V5.99988C15.9167 5.44759 15.469 4.99988 14.9167 4.99988H6.08333C5.53105 4.99988 5.08333 5.44759 5.08333 5.99988V17.9999C5.08333 18.5522 5.53105 18.9999 6.08333 18.9999H11.4939C11.8271 19.3934 12.2324 19.7323 12.6914 19.9999H6C4.89543 19.9999 4 19.1044 4 17.9999V5.99988Z"
      fill="currentColor"
    />
    <path
      d="M14 16.4998C14 16.2237 14.2239 15.9998 14.5 15.9998H15.5C15.7761 15.9998 16 16.2237 16 16.4998C16 16.776 15.7761 16.9998 15.5 16.9998H14.5C14.2239 16.9998 14 16.776 14 16.4998Z"
      fill="currentColor"
    />
    <path
      d="M14.5 16.9998C14.2239 16.9998 14 16.776 14 16.4998V14.9998C14 14.7236 14.2239 14.4998 14.5 14.4998C14.7761 14.4998 15 14.7236 15 14.9998V16.4999C15 16.776 14.7761 16.9998 14.5 16.9998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4997 19.9992C16.4326 19.9992 17.9995 18.4323 17.9995 16.4995C17.9995 14.5666 16.4326 12.9998 14.4997 12.9998C12.5669 12.9998 11 14.5666 11 16.4995C11 18.4323 12.5669 19.9992 14.4997 19.9992ZM14.5 18.9998C15.8807 18.9998 17 17.8805 17 16.4998C17 15.119 15.8807 13.9998 14.5 13.9998C13.1193 13.9998 12 15.119 12 16.4998C12 17.8805 13.1193 18.9998 14.5 18.9998Z"
      fill="currentColor"
    />
  </Icon>
);

import { TOGGLE_GROUPBY_MANAGER_CLICKTHROUGH } from 'actions/actionTypes';

const initialState = {
  isClickThroughTableAvailable: false,
};

export default function oneOnOne(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_GROUPBY_MANAGER_CLICKTHROUGH: {
      console.log('first', action);
      return { ...state, isClickThroughTableAvailable: action.isAvailable };
    }
    default:
      return state;
  }
}

import { Icon } from '@chakra-ui/react';

export const FeedbackIconDualTone = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M7 18.9155V12.7436C7 12.5177 6.77964 12.5258 6.56003 12.5258H4.41412C4.19451 12.5258 4 12.6872 4 12.9131V19.0318C4 19.2577 4.19451 19.4578 4.41412 19.4578H6.56003C6.77964 19.4352 7 19.1414 7 18.9155Z"
      fill="#999999"
    />
    <path
      d="M13.7041 9.07453L13.2686 10.389H14.6534H18.5779C18.6388 10.4019 18.7467 10.4415 18.8342 10.5202C18.8786 10.5601 18.9158 10.608 18.9435 10.6695C18.9654 10.7181 18.9879 10.7896 18.996 10.8934L17.4609 18.5217C17.3975 18.8098 17.1344 19 16.9122 19H12.131C11.7885 19 11.442 18.9723 11.0943 18.9303C11.0939 18.9302 11.0936 18.9302 11.0933 18.9301L10.0349 18.7995L9.99407 18.7913L9.78903 18.7503C9.61923 18.7163 9.39221 18.6711 9.16369 18.6257C8.9714 18.5876 8.77714 18.5493 8.61471 18.5176L8.63433 13.0304C8.64239 13.0197 8.65055 13.0091 8.65863 12.9988C9.13048 12.698 9.47211 12.3538 9.70177 12.0744C9.82955 11.919 9.92507 11.7808 9.99129 11.6763C10.0245 11.6239 10.0506 11.5796 10.0699 11.5454C10.0744 11.5374 10.0786 11.5299 10.0824 11.523L13.9313 5.31422L13.9439 5.29387L13.9556 5.27294C13.9942 5.20342 14.0256 5.16165 14.0723 5.11246C14.1928 5.00204 14.3732 4.97254 14.5056 5.02682C14.6338 5.08549 14.7442 5.22005 14.7773 5.39864L14.7825 5.42655C14.7825 5.43515 14.7826 5.44508 14.7826 5.45775C14.7824 5.45911 14.7821 5.46092 14.7817 5.46326C14.7803 5.47215 14.778 5.48474 14.7736 5.50681L14.7663 5.54369L14.757 5.59047L14.745 5.65209L14.5188 6.39271L14.5187 6.39271L14.5176 6.39648L14.0345 8.00037L14.0321 8.00817L14.0299 8.01601L13.8821 8.53719L13.7041 9.07453ZM14.7386 5.78133C14.7386 5.78485 14.7382 5.77845 14.7386 5.76425V5.78133ZM8.73769 12.9044C8.73803 12.904 8.73809 12.9039 8.73783 12.9042L8.73769 12.9044Z"
      stroke="#666666"
      strokeWidth="2"
    />
  </Icon>
);

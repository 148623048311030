import { Icon } from '@chakra-ui/react';

export const Archive = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g id="Property 1=Default">
      <path
        id="Vector"
        d="M19.5911 5.98222L18.3556 4.48889C18.1156 4.18667 17.7511 4 17.3333 4H6.66667C6.24889 4 5.88444 4.18667 5.63556 4.48889L4.40889 5.98222C4.15111 6.28444 4 6.68444 4 7.11111V18.2222C4 19.2 4.8 20 5.77778 20H18.2222C19.2 20 20 19.2 20 18.2222V7.11111C20 6.68444 19.8489 6.28444 19.5911 5.98222ZM6.88 5.77778H17.12L17.84 6.64H6.16889L6.88 5.77778ZM5.77778 18.2222V8.44444H18.2222V18.2222H5.77778ZM13.2889 10.2222H10.7111V12.8889H8.44444L12 16.4444L15.5556 12.8889H13.2889V10.2222Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

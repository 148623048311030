import { Icon } from '@chakra-ui/react';

export const InsightsIconDualTone = (props) => (
  <Icon viewBox="0 0 24 25" {...props}>
    <g clipPath="url(#clip0_86_12)">
      <path d="M4 17.96H20V19.96H4V17.96Z" fill="#666666" />
      <path d="M5.5 10.96H8.5V16.96H5.5V10.96Z" fill="#999999" />
      <path d="M10.5 12.96H13.5V16.96H10.5V12.96Z" fill="#999999" />
      <path d="M15.5 11.96H18.5V16.96H15.5V11.96Z" fill="#999999" />
      <path
        d="M18.3201 5.78L17.4901 10.63L15.5101 10.29L15.7601 8.85L11.4801 11.97L8.95009 8.94L6.70009 10.44L5.59009 8.77L9.34009 6.28L11.8201 9.25L15.0201 6.92L13.2301 6.42L13.7701 4.5L18.3201 5.78Z"
        fill="#999999"
      />
    </g>
    <defs>
      <clipPath id="clip0_86_12">
        <rect width="16" height="15.46" fill="white" transform="translate(4 4.5)" />
      </clipPath>
    </defs>
  </Icon>
);

import { SET_GMAIL_OUTLOOK_APP } from 'actions/actionTypes';

export const initialState = {
  isGmailOutlookApp: false,
};

export default function gmailOutlookApp(state = initialState, action) {
  switch (action.type) {
    case SET_GMAIL_OUTLOOK_APP:
      const newState = { ...initialState, isGmailOutlookApp: true };
      return newState;
    default:
      return state;
  }
}

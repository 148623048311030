import { Icon } from '@chakra-ui/react';

export const PrivateObjective = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M11.5 11.5L12.5861 16.5L13.0783 13.4926C13.1134 13.278 13.2809 13.11 13.4945 13.075L16.5 12.5824L11.5 11.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7349 14.2583C16.582 14.4795 16.5559 14.7613 16.5559 15.1875H18.1039C18.1039 14.7634 18.1033 14.4809 17.9497 14.2583C17.8072 14.0518 17.6115 13.9347 17.3337 13.9347C17.0404 13.9347 16.8754 14.0548 16.7349 14.2583ZM16.0237 13.7014C16.3207 13.2715 16.7823 13 17.375 13C17.9596 13 18.4166 13.2747 18.7111 13.7016C18.9946 14.1124 19.1178 14.6451 19.1178 15.1875H19.125C19.6082 15.1875 20 15.5793 20 16.0625V19.125C20 19.6083 19.6082 20 19.125 20H15.625C15.1418 20 14.75 19.6083 14.75 19.125V16.0625C14.75 15.5793 15.1418 15.1875 15.625 15.1875H15.6192C15.6192 14.6472 15.7389 14.1135 16.0237 13.7014ZM15.7309 16.3278L15.7381 18.874H18.9125L18.9053 16.3278H15.7309Z"
      fill="currentColor"
    />
    <path
      d="M20 12C20 12.2231 19.9909 12.444 19.973 12.6625L18.6511 12.4591C18.6614 12.3074 18.6667 12.1543 18.6667 12C18.6667 8.3181 15.6819 5.33333 12 5.33333C8.3181 5.33333 5.33333 8.3181 5.33333 12C5.33333 15.6819 8.3181 18.6667 12 18.6667C12.1543 18.6667 12.3074 18.6614 12.4591 18.6511L12.6625 19.973C12.444 19.9909 12.2231 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      fill="currentColor"
    />
    <path
      d="M12 16C12.0171 16 12.0342 15.9999 12.0512 15.9997L11.8455 14.6623C10.4446 14.5822 9.33333 13.4209 9.33333 12C9.33333 10.5272 10.5272 9.33333 12 9.33333C13.4209 9.33333 14.5822 10.4446 14.6623 11.8455L15.9997 12.0512C15.9999 12.0342 16 12.0171 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
      fill="currentColor"
    />
  </Icon>
);

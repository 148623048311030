/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EmbeddedAnalyticsState = {
  embeddedAnalyticsStatus: 'LIVE' | 'SETUP' | 'SANDBOX' | 'PENDING' | 'FAILED';
};

const initialState: EmbeddedAnalyticsState = {
  embeddedAnalyticsStatus: 'PENDING',
};

export const EmbeddedAnalyticsPanelSlice = createSlice({
  name: 'EmbeddedAnalyticsPanelSlice',
  initialState,
  reducers: {
    updateEmbeddedAnalyticsStatus(state, action: PayloadAction<EmbeddedAnalyticsState['embeddedAnalyticsStatus']>) {
      console.log('updateEmbeddedAnalyticsStatus action.payload', action.payload);

      state.embeddedAnalyticsStatus = action.payload;
    },
  },
});

export const { updateEmbeddedAnalyticsStatus } = EmbeddedAnalyticsPanelSlice.actions;

export default EmbeddedAnalyticsPanelSlice.reducer;

import { Icon } from '@chakra-ui/react';

export const AnonymousSmiley = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <rect width="24" height="24" rx="12" fill="#CFD7DF" />
    <path
      d="M11.9917 3.66699C7.39175 3.66699 3.66675 7.39199 3.66675 12.0003C3.66675 16.6087 7.39175 20.3337 11.9917 20.3337C16.5917 20.3337 20.3334 16.6087 20.3334 12.0003C20.3334 7.39199 16.6001 3.66699 11.9917 3.66699ZM12.0001 18.667C8.31675 18.667 5.33341 15.6837 5.33341 12.0003C5.33341 8.31699 8.31675 5.33366 12.0001 5.33366C15.6834 5.33366 18.6667 8.31699 18.6667 12.0003C18.6667 15.6837 15.6834 18.667 12.0001 18.667ZM12.8334 10.2837L13.7167 11.167L14.6001 10.2837L15.4834 11.167L16.3667 10.2837L14.6001 8.51699L12.8334 10.2837ZM9.40008 10.2837L10.2834 11.167L11.1667 10.2837L9.40008 8.51699L7.63341 10.2837L8.51675 11.167L9.40008 10.2837ZM12.0001 16.5837C13.9417 16.5837 15.5917 15.367 16.2584 13.667H7.74175C8.40841 15.367 10.0584 16.5837 12.0001 16.5837Z"
      fill="#333333"
    />
  </Icon>
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  isConnected: false,
};

export const visierAuthSlice = createSlice({
  name: 'visierAuth',
  initialState,
  reducers: {
    setIsVisierConnected: (
      state,
      action: PayloadAction<{
        isConnected: boolean;
      }>
    ) => {
      const newState = { ...state };
      newState.isConnected = action.payload.isConnected;
      return newState;
    },
  },
});

export const { setIsVisierConnected } = visierAuthSlice.actions;

export default visierAuthSlice.reducer;

export const useIsVisierConnected = () =>
  useSelector((state: { visierAuth: typeof initialState }) => state.visierAuth.isConnected);

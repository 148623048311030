import { Icon } from '@chakra-ui/react';

export const UnorderedList = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M5.2973 10.7027C4.57946 10.7027 4 11.2821 4 12C4 12.7178 4.57946 13.2973 5.2973 13.2973C6.01514 13.2973 6.59459 12.7178 6.59459 12C6.59459 11.2821 6.01514 10.7027 5.2973 10.7027ZM5.2973 5.51349C4.57946 5.51349 4 6.09295 4 6.81079C4 7.52862 4.57946 8.10808 5.2973 8.10808C6.01514 8.10808 6.59459 7.52862 6.59459 6.81079C6.59459 6.09295 6.01514 5.51349 5.2973 5.51349ZM5.2973 15.8919C4.57946 15.8919 4 16.48 4 17.1892C4 17.8984 4.58811 18.4865 5.2973 18.4865C6.00649 18.4865 6.59459 17.8984 6.59459 17.1892C6.59459 16.48 6.01514 15.8919 5.2973 15.8919ZM8.75676 18.054H19.1351C19.6108 18.054 20 17.6648 20 17.1892C20 16.7135 19.6108 16.3243 19.1351 16.3243H8.75676C8.28108 16.3243 7.89189 16.7135 7.89189 17.1892C7.89189 17.6648 8.28108 18.054 8.75676 18.054ZM8.75676 12.8648H19.1351C19.6108 12.8648 20 12.4757 20 12C20 11.5243 19.6108 11.1351 19.1351 11.1351H8.75676C8.28108 11.1351 7.89189 11.5243 7.89189 12C7.89189 12.4757 8.28108 12.8648 8.75676 12.8648ZM7.89189 6.81079C7.89189 7.28646 8.28108 7.67565 8.75676 7.67565H19.1351C19.6108 7.67565 20 7.28646 20 6.81079C20 6.33511 19.6108 5.94592 19.1351 5.94592H8.75676C8.28108 5.94592 7.89189 6.33511 7.89189 6.81079Z"
      fill="currentColor"
    />
  </Icon>
);

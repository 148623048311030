import { Icon } from '@chakra-ui/react';

export const File = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M8 9.5C8 9.22386 8.22386 9 8.5 9H15.5C15.7761 9 16 9.22386 16 9.5C16 9.77614 15.7761 10 15.5 10H8.5C8.22386 10 8 9.77614 8 9.5Z"
      fill="currentColor"
    />
    <path
      d="M8 12.5C8 12.2239 8.22386 12 8.5 12H15.5C15.7761 12 16 12.2239 16 12.5C16 12.7761 15.7761 13 15.5 13H8.5C8.22386 13 8 12.7761 8 12.5Z"
      fill="currentColor"
    />
    <path
      d="M8 15.5C8 15.2239 8.22386 15 8.5 15H15.5C15.7761 15 16 15.2239 16 15.5C16 15.7761 15.7761 16 15.5 16H8.5C8.22386 16 8 15.7761 8 15.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 19H17C17.5523 19 18 18.5523 18 18V8.48062L13.6492 5H7C6.44772 5 6 5.44772 6 6V18C6 18.5523 6.44772 19 7 19ZM14 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V8L14 4Z"
      fill="currentColor"
    />
  </Icon>
);

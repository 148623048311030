import { createSlice } from '@reduxjs/toolkit';
import history from 'meshHistory';

import { OKR_TYPES } from 'constants/ProjectConstants';
import { getObjectiveDetailsRoute, getTaskDetailsRoute } from 'constants/helper';

const initialState = {
  onUpdate: null,
  onDelete: null,
};

export const okrDrawerControlSlice = createSlice({
  name: 'okrPanelKebabMenuActionsSlice',
  initialState,
  reducers: {
    openOKRPanel: (state, action) => {
      state.onDelete = action.payload.onDelete;
      state.onUpdate = action.payload.onUpdate;
    },
    closeOKRPanel: () => initialState,
  },
});

const { openOKRPanel, closeOKRPanel: hideOKRDetails } = okrDrawerControlSlice.actions;

const showOKRDetails = (payload) => (dispatch) => {
  /* read defaults based on access control from store and pass */

  const { id, type, onDelete, onUpdate, scrollToId = '' } = payload;

  if (type === OKR_TYPES.OBJECTIVE) {
    history.push(
      getObjectiveDetailsRoute({
        location: history.location,
        objectivePId: id,
        scrollToId,
      })
    );
  } else {
    history.push(
      getTaskDetailsRoute({
        location: history.location,
        ids: [id],
        type,
        scrollToId,
      })
    );
  }

  dispatch(
    openOKRPanel({
      onDelete,
      onUpdate,
    })
  );
};

export { showOKRDetails, hideOKRDetails };
export default okrDrawerControlSlice.reducer;

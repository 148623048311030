import { Icon } from '@chakra-ui/react';

export const Publish = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M17.8333 5.33398H6.16667C5.24167 5.33398 4.5 6.08398 4.5 7.00065V17.0007C4.5 17.9173 5.25 18.6673 6.16667 18.6673H8.66667C9.125 18.6673 9.5 18.2923 9.5 17.834C9.5 17.3757 9.125 17.0007 8.66667 17.0007H6.16667V8H17.8333V17.0007H15.3333C14.875 17.0007 14.5 17.3757 14.5 17.834C14.5 18.2923 14.875 18.6673 15.3333 18.6673H17.8333C18.75 18.6673 19.5 17.9173 19.5 17.0007V7.00065C19.5 6.08398 18.7583 5.33398 17.8333 5.33398ZM11.7083 10.6257L9.38333 12.9507C9.11667 13.2173 9.3 13.6673 9.675 13.6673H11.1667V17.834C11.1667 18.2923 11.5417 18.6673 12 18.6673C12.4583 18.6673 12.8333 18.2923 12.8333 17.834V13.6673H14.325C14.7 13.6673 14.8833 13.2173 14.6167 12.959L12.2917 10.634C12.1333 10.4673 11.8667 10.4673 11.7083 10.6257Z"
      fill="currentColor"
    />
  </Icon>
);

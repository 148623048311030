import { Icon } from '@chakra-ui/react';

export const GreenFilledCheck = (props) => (
  <Icon viewBox="0 0 12 12" boxSize="16px" color="icon.g1" {...props}>
    <circle cx="6" cy="6" r="5.5" fill="white" stroke="#EBEFF3" />
    <path
      d="M6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM4.374 8.574L2.22 6.42C1.986 6.186 1.986 5.808 2.22 5.574C2.454 5.34 2.832 5.34 3.066 5.574L4.8 7.302L8.928 3.174C9.162 2.94 9.54 2.94 9.774 3.174C10.008 3.408 10.008 3.786 9.774 4.02L5.22 8.574C4.992 8.808 4.608 8.808 4.374 8.574Z"
      fill="#44812F"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const AddPerson = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM11 8C11 9.1 10.1 10 9 10C7.9 10 7 9.1 7 8C7 6.9 7.9 6 9 6C10.1 6 11 6.9 11 8ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18ZM3 18C3.2 17.29 6.3 16 9 16C11.69 16 14.78 17.28 15 18H3Z"
      fill="currentColor"
    />
    <path
      d="M19 7C18.4477 7 18 7.44772 18 8V10H16C15.4477 10 15 10.4477 15 11C15 11.5523 15.4477 12 16 12H18V14C18 14.5523 18.4477 15 19 15C19.5523 15 20 14.5523 20 14V12H22C22.5523 12 23 11.5523 23 11C23 10.4477 22.5523 10 22 10H20V8C20 7.44772 19.5523 7 19 7Z"
      fill="currentColor"
    />
  </Icon>
);

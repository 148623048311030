import Media from 'models/media';

export function getTextFromEditorState(editorState) {
  const text = editorState.getCurrentContent().getPlainText('\u0001');
  return removeHashTagFromText(text).trim();
}

function removeHashTagFromText(string) {
  return string.replace(/#\w*/, '');
}

export function selectPostData(state) {
  return state.post.data || {};
}

export function selectEditorState(state) {
  return selectPostData(state).editorState;
}

export function selectVisibility(state) {
  return selectPostData(state).visibility;
}

export function selectVisibilityTypeFromPost(post) {
  return post.visibility;
}

export function selectIsVisibilityUpdatedByUser(state) {
  return state.post.meta.isVisibilityUpdatedByUser;
}

export function selectImage(media = []) {
  return media.filter((m) => m.type === Media.TYPES.image);
}

export function selectGifs(media = []) {
  return media.filter((m) => m.type === Media.TYPES.gif);
}

export function selectPostId(state) {
  return state.post.id;
}

export function selectIsUpdating(state) {
  return selectPostData(state).isUpdating;
}

export function selectUploadedGifs(state) {
  return selectPostData(state).gifs;
}

export function selectUploadedImages(state) {
  return selectPostData(state).images;
}

// tagRecommendations
export function selectTagRecommendations(state) {
  return state.post.tagRecommendations;
}

// sentiment
export function selectPostSentiment(state) {
  return selectPostData(state).sentiment;
}

import { Icon } from '@chakra-ui/react';

export const MyDefaultOneOnOneLatest = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M18.5 4H12.5C11.67 4 11 4.67 11 5.5V6H15.5C16.88 6 18 7.12 18 8.5V11H18.5C19.33 11 20 10.33 20 9.5V5.5C20 4.67 19.33 4 18.5 4Z"
      fill="currentColor"
    />
    <path
      d="M15.5 7H9.5C8.67 7 8 7.67 8 8.5V10.44C8.77 10.44 9.46 10.75 10 11.24V9H15V12H13.91C13.25 12 12.61 12.27 12.14 12.73L11.99 12.88V12H10.6C10.88 12.48 11.04 13.03 11.04 13.63C11.04 14.23 10.87 14.79 10.59 15.27C10.69 15.35 10.8 15.42 10.92 15.47C11.11 15.55 11.3 15.58 11.5 15.58C11.89 15.58 12.27 15.43 12.56 15.14L13.56 14.14C13.65 14.05 13.78 13.99 13.91 13.99H15.5C16.33 13.99 17 13.32 17 12.49V8.5C17 7.67 16.33 7 15.5 7Z"
      fill="currentColor"
    />
    <path
      d="M12 20H4V18.85C4 18.49 4.15 18.16 4.46 17.86C4.72 17.6 5.08 17.38 5.53 17.19C6.44 16.8 7.43 16.66 8.01 16.66C8.59 16.66 9.57 16.8 10.49 17.19C10.94 17.38 11.3 17.61 11.56 17.86C11.86 18.16 12.02 18.49 12.02 18.85V20H12ZM8 15.83C6.87 15.83 5.95 14.85 5.95 13.64C5.95 12.43 6.87 11.45 8 11.45C9.13 11.45 10.05 12.43 10.05 13.64C10.05 14.85 9.13 15.83 8 15.83Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const Collapse = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g clipPath="url(#clip0_260_281)">
      <rect width="24" height="24" rx="4" fill="none" />
      <path d="M11 13L5.5 18.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M5 13L11 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M11 13L11 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M13 11L18.5 5.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M19 11L13 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M13 11L13 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_260_281">
        <rect width="24" height="24" fill="none" />
      </clipPath>
    </defs>
  </Icon>
);

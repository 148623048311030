import { Icon } from '@chakra-ui/react';

export const ArrowCircleUp = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22ZM11 12V15C11 15.55 11.45 16 12 16C12.55 16 13 15.55 13 15V12H14.79C15.24 12 15.46 11.46 15.14 11.15L12.35 8.36C12.15 8.16 11.84 8.16 11.64 8.36L8.85 11.15C8.54 11.46 8.76 12 9.21 12H11Z"
      fill="currentColor"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const ChangeWeightage = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <g id="Property 1=Default">
      <path
        id="Vector"
        d="M19.7648 8.06442L19.7149 8.01453C19.3906 7.69021 18.8584 7.70684 18.559 8.04779L16.7462 10.0852C15.0414 8.16421 12.6714 7 9.99373 7C7.91478 7 5.9772 7.72347 4.38057 8.91263C3.98973 9.20369 3.94815 9.77748 4.2891 10.1184L4.33899 10.1683C4.61341 10.4427 5.05415 10.4926 5.36183 10.2598C6.68405 9.2619 8.27236 8.66316 9.99373 8.66316C12.2723 8.66316 14.2265 9.71095 15.6235 11.3575L13.2286 14.0518L10.4927 11.3159C10.1684 10.9916 9.64447 10.9916 9.32016 11.3159L4.23089 16.4135C3.9232 16.7212 3.9232 17.2284 4.23089 17.5361L4.35562 17.6609C4.66331 17.9685 5.17057 17.9685 5.47826 17.6609L9.90226 13.2285L12.6049 15.9312C12.9458 16.2721 13.4947 16.2555 13.8107 15.8979L16.5965 12.7712C17.1121 13.7025 17.4946 14.7586 17.6942 15.873C17.7607 16.2638 18.085 16.5549 18.4842 16.5549H18.559C19.058 16.5549 19.4322 16.0975 19.3407 15.6069C19.0746 14.0684 18.5257 12.6631 17.769 11.449L19.7981 9.16211C20.0808 8.84611 20.0642 8.36379 19.7648 8.06442Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

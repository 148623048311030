import { Icon } from '@chakra-ui/react';

export const ReviewsIconHover = (props) => (
  <Icon viewBox="0 0 25 25" {...props}>
    <path
      d="M5.85706 6.5C5.85706 5.94772 6.30477 5.5 6.85706 5.5H18.8571C19.4093 5.5 19.8571 5.94772 19.8571 6.5V18.5C19.8571 19.0523 19.4093 19.5 18.8571 19.5H6.85706C6.30477 19.5 5.85706 19.0523 5.85706 18.5V6.5Z"
      stroke="#333333"
      strokeWidth="2"
    />
    <path
      d="M16.7692 11.8418C16.9356 11.6663 16.8524 11.4032 16.6304 11.3446L14.2999 10.9937C14.2443 10.9937 14.2166 10.9645 14.189 10.906L13.1067 8.65363C12.9958 8.44879 12.7183 8.44879 12.6351 8.65363L11.5807 10.8764C11.553 10.935 11.5251 10.9641 11.4698 10.9641L9.08369 11.315C8.8617 11.3442 8.77848 11.6368 8.94492 11.8122L10.6374 13.5378C10.6651 13.567 10.6929 13.6255 10.6929 13.6841L10.2768 16.1702C10.2492 16.4043 10.4711 16.5797 10.6653 16.4628L12.7462 15.293C12.8017 15.2638 12.8294 15.2638 12.8849 15.293L15.0214 16.4628C15.2157 16.5797 15.4375 16.4043 15.4098 16.1702L15.0214 13.7135C15.0214 13.6549 15.0214 13.6258 15.0769 13.5672L16.7692 11.8418Z"
      fill="#333333"
    />
  </Icon>
);

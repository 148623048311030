import { captureException } from '@sentry/browser';
import { convertToRaw, ContentState, EditorState, convertFromRaw, Modifier, SelectionState } from 'draft-js';
import Employee from 'models/employee';

import { SEARCH_TAG_TYPES } from 'constants/ProjectConstants';

import { debouncedAttributePrediction } from 'services/post';

// import { debouncedVisibilityPrediction } from 'services/post';

const rawState1 = {
  blocks: [
    {
      key: '23ich',
      text: 'hello @Sakshi Arora #LearningAndApplication I am okay +123',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 6,
          length: 13,
          key: 0,
        },
        {
          offset: 20,
          length: 23,
          key: 1,
        },
      ],
      data: {},
    },
  ],
  entityMap: {
    0: {
      type: 'mention',
      mutability: 'IMMUTABLE',
      data: {
        mention: {
          id: 'g3rfds324',
          name: 'Sakshi Arora',
          title: 'Senior Developer',
          avatar: 'https://d933oy9uoq0en.cloudfront.net/datamonk/Sakshi.jpeg',
        },
      },
    },
    1: {
      type: '#mention',
      mutability: 'IMMUTABLE',
      data: {
        mention: {
          id: '34dsafas',
          name: 'LearningAndApplication',
        },
      },
    },
  },
};

export function getRawEditorState(editorState) {
  return convertToRaw(editorState.getCurrentContent());
}

export function areContentOfEditorStatesDifferent(editorState1, editorState2) {
  return JSON.stringify(editorState1.getCurrentContent()) !== JSON.stringify(editorState2.getCurrentContent());
}

function getaMacroKeyArrayFromTagType(tagType) {
  switch (tagType) {
    case SEARCH_TAG_TYPES.employee:
      return ['employees'];
    default:
      return ['demographics', tagType];
  }
}

function getReplacementForMention({ text, body, entity, entityData }) {
  const { tagType, group, employee } = entityData.data.mention;
  const isEmployeeTagType = tagType === SEARCH_TAG_TYPES.employee;
  if (!isEmployeeTagType && !body.macros.demographics[tagType]) {
    body.macros.demographics[tagType] = {};
  }
  const dataObject = isEmployeeTagType ? employee : group;
  // const mention = entityData.data.mention;
  const id = isEmployeeTagType ? dataObject.id : dataObject.uuid;
  // This will return the key array of where we need to add the data in the body
  const macroKeyArray = getaMacroKeyArrayFromTagType(tagType);
  let bodyMacroObject = body.macros;
  for (const key of macroKeyArray) {
    bodyMacroObject = bodyMacroObject[key];
  }
  if (!isEmployeeTagType) {
    bodyMacroObject[id] = { ...group }; // the whole group data is required at the backend
  } else {
    bodyMacroObject[id] = { uuid: id, metadata: { displayName: dataObject.displayName } }; // in case of employee tagtype, only id is needed at the backend
  }
  const from = text.substring(entity.offset, entity.offset + entity.length);
  const to =
    tagType === SEARCH_TAG_TYPES.employee
      ? `@{{employees.${id}.metadata.displayName}}`
      : `@{{demographics.${tagType}.${id}.displayName}}`;
  return { from, to };
}

export function doesEditorStateContainContent({ editorState }) {
  return editorState.getCurrentContent().hasText();
}

export function convertRawStateToApiObject(rawState) {
  const body = {
    text: '',
    macros: {
      employees: {},
      demographics: {},
      attributes: { orgTag: {}, money: null },
    },
  };
  // let text = rawState.getPlainText('\u0001');
  const resultText = [];
  rawState.blocks.forEach((block) => {
    const replacements = [];
    let { text } = block;
    block.entityRanges.forEach((entity) => {
      const entityData = rawState.entityMap[entity.key];
      if (entityData.type === 'mention') {
        const { from, to } = getReplacementForMention({ text, body, entity, entityData });
        replacements.push({ from, to });
      } else if (entityData.type === '#mention') {
        // in some cases I am keeping the uuid key and in some cases I am keeping the id key
        // so handling both cases
        const id = entityData.data.mention.uuid || entityData.data.mention.id;
        body.macros.attributes.orgTag[id] = { uuid: id, name: entityData.data.mention.name };
        // const index = body.macros.attributes.orgTag.length - 1;
        const from = text.substring(entity.offset, entity.offset + entity.length);
        const to = `#{{attributes.orgTag.${id}.name}}`;
        replacements.push({ from, to });
      }
    });
    replacements.forEach((replacement) => {
      text = text.replace(replacement.from, replacement.to);
    });
    resultText.push(text);
  });

  body.text = resultText.join('\n');

  return body;
}

// TODO: It just straight away makes any text like +1324 into money
// Should it really happen like this?
function makeMoneyApiReady(body) {
  const { text } = body;
  body.text = text.replace(/\+([\d]+)/g, (match, groupMatch1) => {
    body.macros.attributes.money = Number(groupMatch1);
    return `+{{attributes.money}}`;
  });
}

export const getEntitiesByLogic = ({ editorState, findEntity }) => {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          const type = entity.getType();
          const entityData = entity.getData();
          const entityKey = character.getEntity();
          if (
            findEntity &&
            findEntity({
              type,
              mention: entityData.mention,
              entityKey,
            })
          ) {
            selectedEntity = {
              entityKey,
              blockKey: block.getKey(),
              entity,
              type,
              entityData,
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });
  return entities;
};

export const getEntities = ({ editorState, entityType = null, name }) => {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (entityType && entity.getType() === entityType && entity.getData().mention.name === name) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });
  return entities;
};

export function getAllReplaceableEntities(editorState) {
  return getEntitiesByLogic({
    editorState,
    findEntity: ({ type }) => type === '#mention' || type === 'mention',
  });
}

export function replaceTextOfAllEntities(editorState, body) {
  const entities = getAllReplaceableEntities(editorState);
  entities.forEach((entity) => {
    editorState = replaceEntityText(editorState, entity, body);
  });
  return editorState;
}

export function replaceEntityText(editorState, entityData, body) {
  const entity = getEntitiesByLogic({
    editorState,
    findEntity: ({ entityKey }) => entityKey === entityData.entityKey,
  })[0];
  if (!entity) return editorState;
  /* 
     this is commented out because it wasn't working for multiple blocks
     which happens in the case of new line
     We want to be able to replace text even when there is nothing selected,
     hence just getting selection from editorState won't work
  */
  // const selectionState = editorState.getSelection();
  const selectionState = SelectionState.createEmpty(entity.blockKey);
  const entitySelection = selectionState.merge({
    anchorOffset: entity.start,
    focusOffset: entity.end,
  });
  const contentState = editorState.getCurrentContent();
  let replaceText;
  if (entity.type === 'mention') {
    const id = entity.entityData.mention.employee.id || entity.entityData.mention.employee.uuid;
    replaceText = `@{{employees.${id}.metadata.displayName}}`;
    const { employee } = entity.entityData.mention;
    employee.uuid = employee.id;
    body.macros.employees[id] = {
      uuid: employee.uuid,
      metadata: {
        displayName: employee?.metadata?.displayName,
      },
    };
  } else if (entity.type === '#mention') {
    const id = entity.entityData.mention.id || entity.entityData.mention.uuid;
    replaceText = `#{{attributes.orgTag.${id}.name}}`;
    const tag = entity.entityData.mention;
    tag.uuid = tag.uuid || tag.id;
    body.macros.attributes.orgTag[id] = tag;
  }
  const newContentState = Modifier.replaceText(contentState, entitySelection, replaceText, null, entity.entityKey);
  return EditorState.createWithContent(newContentState);
}

// this function converts editorstate of draft js into api body for creating a comment or a post
// media is an array of media object
export function getApiBodyFromEditorStateAndMedia(editorState, media, useMoney = false, sentiment) {
  // const rawState = convertToRaw(editorState.getCurrentContent());
  const body = {
    text: '',
    macros: {
      employees: {},
      demographics: {},
      attributes: { orgTag: {}, money: null, sentiment },
    },
  };
  editorState = replaceTextOfAllEntities(editorState, body);

  body.text = editorState.getCurrentContent().getPlainText('\n');
  // const rawState1 = convertToRaw(editorState.getCurrentContent());
  // const body = convertRawStateToApiObject(rawState);
  body.media = media ? media.map((data) => ({ type: data.type, url: data.url, uuid: data.uuid })) : [];
  if (useMoney) makeMoneyApiReady(body);
  return body;
}

export function createEditorStateFromText(text, macros) {
  const contentState = ContentState.createFromText(text);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
}

// this will get stringified content state
export function getEditorStateFromStringifiedRawState({ stringifiedRawState }) {
  if (!stringifiedRawState) return EditorState.createEmpty();
  const rawState = JSON.parse(stringifiedRawState);
  const contentState = convertFromRaw(rawState);
  return EditorState.createWithContent(contentState);
}

export function getStringifiedRawContentStateFromEditorState({ editorState }) {
  if (!editorState) return null;
  const rawContentState = getRawEditorState(editorState);
  return JSON.stringify(rawContentState);
}

export function areTwoEditorStatesEqual(editorState1, editorState2) {
  return JSON.stringify(editorState1.getCurrentContent()) === JSON.stringify(editorState2.getCurrentContent());
}

export function isAnyMentionPresent(editorState) {
  const rawContent = convertToRaw(editorState.getCurrentContent());
  Object.values(rawContent.entityMap).forEach((entity) => {
    if (entity.type === 'mention') return true;
  });
  return false;
}

export function getMentionCount(editorState) {
  let count = 0;
  const rawContent = convertToRaw(editorState.getCurrentContent());
  Object.values(rawContent.entityMap).forEach((entity) => {
    if (entity.type === 'mention') count++;
  });
  return count;
}

// TODO: make sure that this is only called when delete or backspace is used
export function checkIfMentionWasRemoved({ oldEditorState, newEditorState }) {
  const oldRawContent = convertToRaw(oldEditorState.getCurrentContent());
  const newRawContent = convertToRaw(newEditorState.getCurrentContent());
  // length of the data inside their entityMaps is not same, some entity was removed
  if (Object.keys(newRawContent.entityMap).length !== Object.keys(oldRawContent.entityMap).length) {
    // checking whether the entity removed was mention
    const oldEntitiesKeys = Object.keys(oldRawContent.entityMap);
    const newEntitiesKeys = Object.keys(newRawContent.entityMap);
    // oldEntities array - newEntities array
    const removedEntityKey = getDifferenceOfTwoEntitiyArrays(oldEntitiesKeys, newEntitiesKeys)[0];
    if (!removedEntityKey) return null;
    if (oldRawContent.entityMap[removedEntityKey].type === 'mention') {
      const entitiesPresent = Object.values(newRawContent.entityMap);
      const mentionsPresent = entitiesPresent.filter((entity) => entity.type === 'mention');
      return { removedMentionEntity: oldRawContent.entityMap[removedEntityKey], mentionsPresent };
    }
    return null;
    // if the type of entity that you get after subtraction is mention, then you removed a mention
  }
  return null;
}

function getDifferenceOfTwoEntitiyArrays(arrayOne, arrayTwo) {
  return arrayOne.filter((arrayOneEntry) => !arrayTwo.some((arrayTwoEntry) => arrayTwoEntry === arrayOneEntry));
}

export function convertEmployeeIntoMentionData(employee) {
  return {
    employee: { id: employee.id, name: employee.displayName },
    tagType: SEARCH_TAG_TYPES.employee,
    id: employee.id,
    name: employee.displayName,
  };
}
export function getAllMentionEntities(editorState) {
  return getEntitiesByLogic({
    editorState,
    findEntity: ({ type }) => type === 'mention',
  });
}

export function deleteAllMentionEntities(editorState) {
  let newEditorState = EditorState.createWithContent(editorState.getCurrentContent());
  const entities = getAllMentionEntities(newEditorState);
  entities.forEach((entityData) => {
    const entity = getEntitiesByLogic({
      editorState: newEditorState,
      findEntity: ({ entityKey }) => entityKey === entityData.entityKey,
    })[0];
    if (entity)
      newEditorState = removeEntity({
        editorState: newEditorState,
        start: entity.start,
        end: entity.end,
      });
  });
  return newEditorState;
}

// TODO: check if this works or not
export function removeEntity({ editorState, start, end, selectionState }) {
  selectionState = selectionState || editorState.getSelection();
  const entitySelection = selectionState.merge({
    anchorOffset: start,
    focusOffset: end,
  });
  const contentState = editorState.getCurrentContent();
  const contentStateWithoutEntity = Modifier.applyEntity(contentState, entitySelection, null);
  // const newContentState = Modifier.replaceText(
  //   contentStateWithoutEntity,
  //   entitySelection,
  //   replaceText,
  //   null,
  //   entity.entityKey
  // );
  const contentStateWithoutBlock = Modifier.removeRange(contentStateWithoutEntity, entitySelection, 'forward');
  const newEditorState = EditorState.createWithContent(contentStateWithoutBlock);
  return newEditorState;
}

class EntityRange {
  constructor(offset = 0, length = 0, key = 0) {
    this.offset = offset;
    this.length = length;
    this.key = key;
  }
}
class Block {
  constructor({ text, entityRanges = [] } = {}) {
    // this.key = generateKey(5);
    this.text = text;
    this.type = 'unstyled';
    this.depth = 0;
    this.inlineStyleRanges = [];
    this.entityRanges = entityRanges;
    this.data = {};
  }
}

export function convertTextMacrosToEditorState(text, macros) {
  const stringifiedContentState = convertTextMacrosToStringifiedContentState(text, macros);
  const editorState = getEditorStateFromStringifiedRawState({
    stringifiedRawState: stringifiedContentState,
  });
  return editorState;
}

export function convertTextMacrosToStringifiedContentState(text, macros) {
  try {
    function getTextToReplaceWithUsingKeys(macros, keys) {
      let textToReplaceWith = { ...macros };
      for (let i = 0; i < keys.length; i++) {
        // adding null check here. if there is some key in text whose corresponding macro entry is missing
        // then return undefined as replacement text. Sending undefined would help calling function determine what to
        // do with non-present values vs empty string tags.
        textToReplaceWith = textToReplaceWith?.[keys[i]];
      }
      return textToReplaceWith;
    }
    const blocks = [];
    const entityMap = {};
    const displayEntityPattern = new RegExp(/(?:(.?){{(.*?)}})/m);
    const newText = text;

    // we need separate blocks per new line
    const linesText = newText.split('\n');
    linesText.forEach((lineText) => {
      const entityRanges = [];
      // while an entity still exists, continue
      while (displayEntityPattern.test(lineText)) {
        lineText = lineText.replace(displayEntityPattern, (match, char, keysGroupMatch, offset, ...params) => {
          // // Since we get custom date in text as well and it also uses macros, we need to add this check
          // const isReplaceableEntity = ['@', '#', '+'].includes(char);
          // if (!isReplaceableEntity) return match;
          const keys = keysGroupMatch.split('.');
          const textToReplaceWith = getTextToReplaceWithUsingKeys(macros, keys);
          // setting replacement text as empty without the char, so that user need not to know there
          // is missing tag here. User will still see empty tag ( if there is corresponding value in macros)
          const replacement = textToReplaceWith !== undefined ? `${char}${textToReplaceWith}` : '';
          const isMentionOrTagEntity = textToReplaceWith !== undefined && ['@', '#'].includes(char);
          if (isMentionOrTagEntity) {
            // getting data according to mention type in this section to push in entityMap
            const employeeMacros = macros.employees;
            const hashtagMacros = macros.attributes.orgTag;
            // generating our own entity key according the number of keys already present inside entityMap
            const entityKey = Object.keys(entityMap).length;
            // defining an entity range with start, end and entityKey
            const entityRange = new EntityRange(offset, replacement.length, entityKey);
            const isMention = char === '@';
            // const isHashtagMention = char === '#';
            const type = char === '@' ? 'mention' : '#mention';
            // keys[1] and keys[2] contain id of that particular entity
            const data = isMention ? employeeMacros[keys[1]] : hashtagMacros[keys[2]];
            let mention = {};
            if (isMention) {
              mention.employee = new Employee(null, data);
              mention.name = textToReplaceWith;
              mention.tagType = 'EMPLOYEE_NAME';
            } else {
              mention = data;
            }
            entityMap[entityKey.toString()] = {
              type,
              mutability: 'IMMUTABLE',
              data: {
                mention,
              },
            };
            entityRanges.push(entityRange);
          }
          return replacement;
        });
      }
      const blockData = new Block({ text: lineText, entityRanges });
      blocks.push(blockData);
    });

    const rawContentState = {
      blocks,
      entityMap,
    };
    return JSON.stringify(rawContentState);
  } catch (err) {
    console.error('An error occured while generating content state', err);
    captureException(err);
    return null;
  }
}

export function updateEditorStatePredictTagsAndVisbility({
  editorState,
  newEditorState,
  setEditorState,
  visibilityManuallyUpdated,
  onAttributePredictionSuccess,
  onVisibilityPredictionSuccess,
}) {
  setEditorState(newEditorState);
  if (!areContentOfEditorStatesDifferent(editorState, newEditorState)) return;
  const { text, macros } = getApiBodyFromEditorStateAndMedia(newEditorState);
  debouncedAttributePrediction({
    text,
    macros,
    onSuccess: (tags) => {
      onAttributePredictionSuccess(tags);
    },
    onError: () => {},
  });
  // if (!visibilityManuallyUpdated)
  //   debouncedVisibilityPrediction({
  //     text,
  //     onSuccess: visibility => {
  //       onVisibilityPredictionSuccess(visibility);
  //     }
  //   });
}

// convert text, macros to text
export function getTextFromTextMacros(text, macros) {
  const editorState = convertTextMacrosToEditorState(text, macros);
  return editorState.getCurrentContent().getPlainText('\n');
}

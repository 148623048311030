import { Icon } from '@chakra-ui/react';

export const Calendar = (props) => (
  <Icon viewBox="0 0 18 18" boxSize="16px" color="icon.g1" {...props}>
    <rect x="3.5" y="5" width="11" height="9.5" rx="0.5" stroke="currentColor" fill="transparent" />
    <path d="M3.75 7.125H14.625" stroke="currentColor" />
    <path d="M6 4.5V3" stroke="currentColor" />
    <path d="M12 4.5V3" stroke="currentColor" />
    <rect x="5.75" y="9.5" width="1.25" height="1.25" fill="currentColor" stroke="currentColor" />
  </Icon>
);

import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const initiativeSlice = createSlice({
  name: 'initiativeSlice',
  initialState,
  reducers: {
    updateInitiativeDetails: (state, action) => ({ ...state, ...action.payload }),
    closeInitiativeSidePanel: (state, action) => ({}),
  },
});

// Action creators are generated for each case reducer function
export const { updateInitiativeDetails, closeInitiativeSidePanel } = initiativeSlice.actions;

export default initiativeSlice.reducer;

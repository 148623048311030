import React from 'react';

import styled from 'styled-components';
import { ImageSizesCSS } from 'styles/common';

import { getColorFromKey } from 'constants/helper';

export default function BadgeContainer({
  children,
  iconColor = '#222222',
  backgroundColor = 'secondary',
  opacity = 1,
  size = 'small',
}) {
  return (
    <Container size={size} opacity={opacity} iconColor={iconColor} backgroundColor={backgroundColor}>
      {children}
    </Container>
  );
}

const Container = styled.span`
  opacity: ${(props) => props.opacity};
  #Container polygon {
    fill: ${(props) => getColorFromKey(props.backgroundColor)} !important;
  }
  #Icon {
    fill: ${(props) => getColorFromKey(props.iconColor)};
  }
  svg {
    ${(props) => props.size && ImageSizesCSS[props.size]};
  }
`;

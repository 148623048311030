import { getDateFromEpoch } from 'constants/helper';

import Employee from './employee';
import Objective from './objective';
import Task from './task1';

class Deliverable {
  constructor(props = {}) {
    const { id, description = '' } = props;
    this.employee = null;
    this.creator = null;
    this.objective = null;
    this.description = description || '';
    this.percentageComplete = 0;
    this.endDate = null;
    this.id = id;
    this.createdDate = null;
    this.updatedDate = null;
    this.tasks = [];
    this.keyResultType = null;
    this.keyResultTarget = null;
    this.keyResultActual = null;
    this.statusType = null;
    this.members = [];
    this.coach = null;
    this.latestUpdationStatus = '';
  }

  fromJSON = (json = {}) => {
    // TODO: parse id
    this.id = json.id;
    this.employee = new Employee(null, json.employee);
    this.description = json.description;
    this.creator = Employee.getEmployeeObjectFromRes(json.creator);
    this.createdDate = json.createdTimestamp ? getDateFromEpoch(json.createdTimestamp) : null;
    this.endDate = json.endDateTimestamp ? getDateFromEpoch(json.endDateTimestamp) : null;
    this.updatedDate = json.updatedTimestamp ? getDateFromEpoch(json.updatedTimestamp) : null;
    this.percentageComplete = json.percentageComplete;
    this.objective = json.objective ? Objective.getObjectFromRes(json.objective) : null;
    this.keyResultActual = json.keyResultActual;
    this.keyResultTarget = json.keyResultTarget;
    this.keyResultType = json.keyResultType;
    this.statusType = json.statusType;
    this.latestUpdationStatus = json.latestUpdationStatus;
    if (json.tasks) {
      json.tasks.forEach((task) => {
        const object = new Task(null, task);
        this.tasks.push(object);
      });
    }
    if (json.members) {
      json.members.forEach((member) => {
        const emp = new Employee(null, member.employee);
        this.members.push({ employee: emp, score: member.score });
      });
    }
    if (json.coach) {
      this.coach = new Employee(null, json.coach);
    }
  };

  static getObjectFromRes(res = {}) {
    const deliver = new Deliverable();
    deliver.fromJSON(res);
    return deliver;
  }

  getStatus() {
    switch (this.statusType) {
      case 'ONTRACK':
        return 'On Track';
      case 'COMPLETED':
        return 'Complete';
      default:
        return 'Pending';
    }
  }
}

export default Deliverable;

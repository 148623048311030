import { Icon } from '@chakra-ui/react';

export const ManagerRabbit1 = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M18.9345 18.424L18.1659 17.843C18.1802 17.731 18.1946 17.619 18.1946 17.5C18.1946 17.381 18.1874 17.269 18.1659 17.157L18.9273 16.576C18.9919 16.52 19.0135 16.429 18.9704 16.352L18.2521 15.141C18.209 15.064 18.1156 15.036 18.0294 15.064L17.1387 15.414C16.9519 15.274 16.7508 15.155 16.5281 15.071L16.3916 14.147C16.3844 14.063 16.3054 14 16.2192 14H14.7826C14.6964 14 14.6174 14.063 14.603 14.147L14.4665 15.071C14.251 15.162 14.0427 15.274 13.856 15.414L12.9652 15.064C12.8862 15.036 12.7928 15.064 12.7426 15.141L12.0242 16.352C11.9811 16.429 11.9955 16.52 12.0673 16.576L12.8288 17.157C12.8144 17.269 12.8072 17.381 12.8072 17.5C12.8072 17.619 12.8144 17.731 12.8288 17.843L12.0673 18.424C12.0027 18.48 11.9811 18.571 12.0242 18.648L12.7426 19.859C12.7857 19.936 12.879 19.964 12.9652 19.936L13.856 19.586C14.0427 19.726 14.2439 19.845 14.4665 19.929L14.603 20.853C14.6174 20.937 14.6892 21 14.7826 21H16.2192C16.3054 21 16.3844 20.937 16.3988 20.853L16.5353 19.929C16.7508 19.838 16.9591 19.726 17.1387 19.586L18.0366 19.936C18.1156 19.964 18.209 19.936 18.2593 19.859L18.9776 18.648C19.0207 18.571 18.9991 18.48 18.9345 18.424ZM15.5009 18.55C14.9047 18.55 14.4234 18.081 14.4234 17.5C14.4234 16.919 14.9047 16.45 15.5009 16.45C16.0971 16.45 16.5784 16.919 16.5784 17.5C16.5784 18.081 16.0971 18.55 15.5009 18.55Z"
      stroke="#666666"
      fill="transparent"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5173 12.1846C14.3721 11.8265 14.0441 11.5619 13.6491 11.5067L13.1954 12.6945C12.6371 12.9902 12.131 13.3715 11.6947 13.8209L12.7753 10.9917C12.8893 10.6933 13.1757 10.4961 13.4952 10.4961C14.4554 10.4961 15.2652 11.1398 15.5168 12.0192C15.1747 12.0464 14.8408 12.1024 14.5173 12.1846ZM10.3417 20.0009H5.82765C4.81827 20.0009 4 19.1826 4 18.1732V12.4165C4 11.3559 4.85979 10.4961 5.9204 10.4961C6.26917 10.4961 6.58087 10.7138 6.70094 11.0412L9.14258 17.7001L9.17063 17.7766L9.29543 18.117L9.38672 18.3659C9.48368 18.6304 9.85678 18.6329 9.95727 18.3698L10.0049 18.245C10.0296 18.8585 10.1463 19.4483 10.3417 20.0009ZM5.82765 19.0009H8.59506C8.53677 18.914 8.48704 18.8171 8.44785 18.7102L5.80523 11.5032C5.35126 11.5599 5 11.9472 5 12.4165V18.1732C5 18.6303 5.37055 19.0009 5.82765 19.0009Z"
      fill="#666666"
    />
    <path d="M8.71436 13.142L9.85721 16.5706L11.0001 13.142L9.85721 11.4277L8.71436 13.142Z" fill="#666666" />
    <circle cx="9.85728" cy="7.42857" r="2.92857" stroke="#666666" fill="transparent" />
  </Icon>
);

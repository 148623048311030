import { FEED_TYPES } from 'services/helpers/feed';

import { endpoints } from '../constants/endpoints';
import { sendRequest } from './sendRequest';

// TODO: Remove hardcoded data
export const attributesPredictionApi = (text, type, macros = {}) => {
  const url = endpoints.post.attributePrediction;
  return sendRequest({
    url,
    method: 'POST',
    body: {
      text,
      type,
      macros,
    },
    credentials: 'include',
  });
};

export function createPostApi(body, feedbackType, feedback_visibility) {
  const url = endpoints.post.create;

  const queryParams = {};
  if (feedbackType) {
    queryParams.feedback_type = feedbackType;
    queryParams.feedback_visibility = feedback_visibility;
  }

  return sendRequest({
    url,
    method: 'POST',
    body,
    queryParams,
    credentials: 'include',
  });
}

export function editPostApi(postId, body, feedbackType, feedback_visibility) {
  body.type = FEED_TYPES.post;
  const url = endpoints.post.edit(postId);

  const queryParams = {};
  if (feedbackType) {
    queryParams.feedback_type = feedbackType;
    queryParams.feedback_visibility = feedback_visibility;
  }

  return sendRequest({
    url,
    method: 'PUT',
    body,
    queryParams,
    credentials: 'include',
  });
}

export function createCommentApi({ feedId, commentBody, feedType, talkingPointQuestionId, isKudos = false }) {
  if (!feedId) throw new Error('Please provide a post id');
  const url = endpoints.post.comments.create(feedId);
  const queryParams = {
    type: feedType,
  };
  if (isKudos) queryParams.feedback_type = 'KUDOS';
  if (talkingPointQuestionId) {
    queryParams.talkingPointQuestionId = talkingPointQuestionId;
  }
  return sendRequest({
    url,
    method: 'POST',
    body: commentBody,
    queryParams,
    credentials: 'include',
  });
}

export function editCommentApi({ commentId, feedId, commentBody, feedType, talkingPointQuestionId, isKudos = false }) {
  if (!feedId) throw new Error('Please provide a post id');
  const url = endpoints.post.comments.edit(feedId, commentId);
  const queryParams = {
    type: feedType,
  };
  if (talkingPointQuestionId) {
    queryParams.talkingPointQuestionId = talkingPointQuestionId;
  }
  if (isKudos) queryParams.feedback_type = 'KUDOS';
  return sendRequest({
    url,
    method: 'PUT',
    body: commentBody,
    queryParams,
    credentials: 'include',
  });
}

export function getCommentsApi({ feedId, offset = 0, limit = 10, type = 'POST' }) {
  const url = endpoints.post.comments.get(feedId);
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      offset,
      limit,
      type,
    },
  });
}

// attachment should be a fileObject
// type can be IMAGE or GIF
export function uploadPostMediaApi({ attachment, type = 'IMAGE' }) {
  const url = endpoints.mediaUpload;
  const data = new FormData();
  data.append('attachment', attachment);
  data.append('mediaType', type);
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    body: data,
    isMultiPart: true,
  });
}

export function visibilityPredictionApi({ text }) {
  const url = endpoints.post.visibilityPrediction;

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    body: { text },
  });
}

import { Icon } from '@chakra-ui/react';

export const CheckBox = (props) => (
  <Icon viewBox="0 0 32 32" boxSize="16px" color="icon.g1" {...props}>
    <path
      d="M12.7946 19.875L9.32457 16.405C8.93457 16.015 8.30457 16.015 7.91457 16.405C7.52457 16.795 7.52457 17.425 7.91457 17.815L12.0946 21.995C12.4846 22.385 13.1146 22.385 13.5046 21.995L24.0846 11.415C24.4746 11.025 24.4746 10.395 24.0846 10.005C23.6946 9.615 23.0646 9.615 22.6746 10.005L12.7946 19.875Z"
      fill="currentColor"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="currentColor" />
  </Icon>
);
